import constants from './constants';

export const defaultState = {
  ui: {
    isDeleting: false,
    isSubmitting: false,
  },
};

function stakeholder(state = defaultState, action) {
  switch (action.type) {
    case constants.DELETE_STAKEHOLDERS:
      return {
        ...state,
        ui: { ...state.ui, isDeleting: true },
      };
    case constants.DELETE_STAKEHOLDERS_DONE:
      return {
        ...state,
        ui: { ...state.ui, isDeleting: false },
      };
    case constants.DELETE_STAKEHOLDER:
      return {
        ...state,
        ui: { ...state.ui, isDeleting: true },
      };
    case constants.DELETE_STAKEHOLDER_DONE:
      return {
        ...state,
        ui: { ...state.ui, isDeleting: false },
      };
    case constants.CREATE_STAKEHOLDER:
      return {
        ...state,
        ui: { ...state.ui, isSubmitting: true },
      };
    case constants.CREATE_STAKEHOLDER_DONE:
    case constants.CREATE_STAKEHOLDER_FAIL:
      return {
        ...state,
        ui: { ...state.ui, isSubmitting: false },
      };
    case constants.UPDATE_STAKEHOLDER:
      return {
        ...state,
        ui: { ...state.ui, isSubmitting: true },
      };
    case constants.UPDATE_STAKEHOLDER_DONE:
    case constants.UPDATE_STAKEHOLDER_FAIL:
      return {
        ...state,
        ui: { ...state.ui, isSubmitting: false },
      };
    default:
      return state;
  }
}

export default stakeholder;
