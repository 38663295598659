const prefix = 'INDIVIDUAL_ASSET_SET';

export default {
  FETCH_INDIVIDUAL_ASSET_SET: `${prefix}/FETCH_INDIVIDUAL_ASSET_SET`,
  FETCH_MULTIPLE_INDIVIDUAL_ASSET_SETS: `${prefix}/FETCH_MULTIPLE_INDIVIDUAL_ASSET_SETS`,
  RECEIVE_INDIVIDUAL_ASSET_SET_DONE: `${prefix}/RECEIVE_INDIVIDUAL_ASSET_SET_DONE`,
  RECEIVE_INDIVIDUAL_ASSET_SET_FAIL: `${prefix}/RECEIVE_INDIVIDUAL_ASSET_SET_FAIL`,
  RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_DONE: `${prefix}/RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_DONE`,
  RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_FAIL: `${prefix}/RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_FAIL`,
  SEARCH_INDIVIDUAL_ASSET_SET: `${prefix}/SEARCH_INDIVIDUAL_ASSET_SET`,
  RESET_INDIVIDUAL_ASSET_SET: `${prefix}/RESET_INDIVIDUAL_ASSET_SET`,
};
