const PREFIX = 'TAGS';

const constants = {
  FETCH_ALL_COMPANY_TAGS: `${PREFIX}/FETCH_ALL_COMPANY_TAGS`,
  FETCH_ALL_COMPANY_TAGS_DONE: `${PREFIX}/FETCH_ALL_COMPANY_TAGS_DONE`,
  CREATE_NEW_TAG: `${PREFIX}/CREATE_NEW_TAG`,
  CREATE_NEW_TAG_DONE: `${PREFIX}/CREATE_NEW_TAG_DONE`,
  BULK_TAG_RECORDS: `${PREFIX}/BULK_TAG_RECORDS`,
  BULK_TAG_RECORDS_DONE: `${PREFIX}/BULK_TAG_RECORDS_DONE`,
  UPDATE_TAG_RECORDS: `${PREFIX}/UPDATE_TAG_RECORDS`,
  UPDATE_TAG_RECORDS_DONE: `${PREFIX}/UPDATE_TAG_RECORDS_DONE`,
  FETCH_TAGS_BY_RECORD_IDS: `${PREFIX}/FETCH_TAGS_BY_RECORD_IDS`,
  FETCH_TAGS_BY_RECORD_IDS_DONE: `${PREFIX}/FETCH_TAGS_BY_RECORD_IDS_DONE`,
};

export default constants;
