import { combineReducers } from 'redux';
import { map, difference } from 'lodash';

import { updateDataItemArrayPayload, loadingUpdateItem, updateDataItem } from 'utils/reducer';
import advancedSearch from 'redux/advancedSearch/reducers';
import { getEnabledCards } from 'components/Grm2/utils';

import constants from './constants';
import initialState from './initialState';
import { stateHandler } from './utils/reducers';

const grmLookupKeyName = 'cards';

function grm(state = initialState, action) {
  const {
    overrideProps,
    appendValuesToArrayProps,
    updateObjectProps,
  } = stateHandler(state);

  switch (action.type) {
    case constants.SET_CARDS:
      return overrideProps({ cards: action.payload });

    case constants.RESET_CARD_STATUS:
      return overrideProps({
        hasNewCard: false,
        hasEditedCard: false,
      });

    case constants.SET_HAS_NEW_CARD:
      return overrideProps({ hasNewCard: action.payload });

    case constants.SET_HAS_EDITED_CARD:
      return overrideProps({ hasEditedCard: action.payload });

    case constants.SET_CARDS_LINK_PREVIEW:
      return overrideProps({ cardsLinkPreview: action.payload });

    case constants.SET_PAGINATION:
      return overrideProps({ pagination: action.payload });

    case constants.SET_SORT:
      return overrideProps({ sort: action.payload });

    case constants.SET_FILTERS:
      return overrideProps({ filters: action.payload });

    case constants.SET_CUSTOM_FIELDS:
      return overrideProps({ customFields: action.payload });

    case constants.START_LOADING:
      return updateObjectProps({ ui: { isLoading: true } });

    case constants.STOP_LOADING:
      return updateObjectProps({ ui: { isLoading: false } });

    case constants.START_LOADING_CUSTOM_FIELDS:
      return updateObjectProps({ ui: { isLoadingCustomFields: true } });

    case constants.STOP_LOADING_CUSTOM_FIELDS:
      return updateObjectProps({ ui: { isLoadingCustomFields: false } });

    case constants.START_LOADING_LINKS_PREVIEW:
      return updateObjectProps({ ui: { isLoadingLinksPreview: true } });

    case constants.STOP_LOADING_LINKS_PREVIEW:
      return updateObjectProps({ ui: { isLoadingLinksPreview: false } });

    case constants.SET_SELECTED:
      return overrideProps({ selectedCardIds: action.payload });

    case constants.SELECT_CARDS:
      return appendValuesToArrayProps(
        { selectedCardIds: map(getEnabledCards(state.cards), 'id') },
        { removeDuplicates: true }
      );

    case constants.UNSELECT_CARDS:
      return overrideProps({
        selectedCardIds: difference(state.selectedCardIds, action.payload),
      });

    case constants.RESET_SELECTED_CARDS: {
      return overrideProps({ selectedCardIds: [] });
    }

    case constants.SET_TIMELINE_DEFAULT_FILTERS: {
      return overrideProps({ timelineDefaultFilters: action.payload });
    }

    case constants.SHOW_DASHBOARD: {
      return updateObjectProps({
        analytics: {
          ui: {
            showDashboard: true,
          },
        },
      });
    }

    case constants.HIDE_DASHBOARD: {
      return updateObjectProps({
        analytics: {
          ui: {
            showDashboard: false,
          },
        },
      });
    }

    case constants.SET_IS_TASKS_CONTEXT: {
      return overrideProps({ isTasksContext: true });
    }

    case constants.SET_NAMESPACE: {
      return overrideProps({ namespace: action.payload });
    }

    case constants.SET_ADVANCED_SEARCH:
      return overrideProps({ withAdvancedSearch: true });

    case constants.RESET_DATA:
      return initialState;

    case constants.FETCH_MY_FEED_DOCUMENTS:
      return updateObjectProps({ ui: { isLoadingMyFeedDocuments: true } });

    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, grmLookupKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };

    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return updateObjectProps({ ui: { isLoadingMyFeedDocuments: false } });

    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, grmLookupKeyName),
      };

    default:
      return state;
  }
}

export default combineReducers({
  grm,
  advancedSearch,
});
