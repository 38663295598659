import isEmpty from 'lodash/isEmpty';

import { getValue } from 'utils/transformers/advancedSearch';
import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import { STRATEGY_SLUG } from '../constants';

import { transformOptionsForBE, transformSearchesForBE, transformSearchesForUI } from './utils';

/** Advanced search actions */
export function loadCommitteesRecentSearches(params, legislatorData) {
  const recentSearch = { ...params, strategy_slug: STRATEGY_SLUG };

  return dispatch => dispatch(loadRecentSearches(
    recentSearch,
    data => transformSearchesForUI(data, legislatorData)),
  );
}

export function loadCommitteesSavedSearches(params, legislatorData) {
  const savedSearch = { ...params, strategy_slug: STRATEGY_SLUG };

  return loadSavedSearches(
    savedSearch,
    data => transformSearchesForUI(data, legislatorData),
  );
}

export function saveAdvancedSearchItem(data = {}, legislatorData, isSaved = true) {
  const advancedSearchData = {
    id: data.id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: STRATEGY_SLUG,
      search_params: transformSearchesForBE(data),
      saved: isSaved,
    },
  };

  if (!isSaved) {
    return onSaveRecentItem(
      advancedSearchData,
      params => loadCommitteesRecentSearches(params, legislatorData),
    );
  }

  return onSaveItem(
    advancedSearchData,
    params => loadCommitteesRecentSearches(params, legislatorData),
  );
}

export function removeAdvancedSearchItem(id, legislatorData = {}) {
  return onRemoveItem(
    id,
    params => loadCommitteesSavedSearches(params, legislatorData)
  );
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, legislatorData = {}, isClickedItem = false } = payload;

    if (!isClickedItem && !isEmpty(transformOptionsForBE(searchData))) {
      return dispatch(saveAdvancedSearchItem(searchData, legislatorData, false));
    }

    return null;
  };
}
