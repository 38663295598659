import { map, omit, find } from 'lodash';

import { combineReducers } from 'redux';

import {
  FETCH_SEARCHES,
  RECEIVE_SEARCHES,
  FETCH_SEARCHES_RESULTS,
  DELETE_SEARCHES_ITEM,
  RECEIVE_SEARCHES_RESULT,
  RECEIVE_SEARCHES_RESULTS,
  REFRESH_SEARCHES_RESULTS,
  SET_ENRICHMENT,
  RESET,
} from './constants';

export const defaultState = {
  results: {},
  searches: [],
  ui: {
    isSearchesLoading: true,
    isResultsInProgress: false,
  },
};

function lookup(state = defaultState, action) {
  switch (action.type) {
    case FETCH_SEARCHES:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSearchesLoading: true,
        },
      };
    case RECEIVE_SEARCHES:
      return {
        ...state,
        searches: action.payload,
        ui: {
          ...state.ui,
          isSearchesLoading: false,
        },
      };
    case FETCH_SEARCHES_RESULTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isResultsInProgress: true,
        },
      };
    case DELETE_SEARCHES_ITEM:
      return {
        ...state,
        searches: state.searches.filter(({ id }) => action.payload.id !== id),
      };
    case REFRESH_SEARCHES_RESULTS:
      return {
        ...state,
      };
    case RECEIVE_SEARCHES_RESULT:
      return {
        ...state,
        searches: [
          ...state.searches.map(({ ...props }) => {
            if (action.payload && action.payload.id && (action.payload.id === props.id)) {
              return {
                ...props,
                ...omit(action.payload, ['createdDate', 'lastRunDate']),
              };
            }

            return props;
          }),
        ],
        ui: {
          ...state.ui,
          isResultsInProgress: false,
        },
      };
    case RECEIVE_SEARCHES_RESULTS:
      return {
        ...state,
        searches: map(state.searches, ({ id, ...restItem }) => {
          const item = find(action.payload, ({ id: receivedId }) => id === receivedId) || {};
          const transformedItem = omit(item, ['createdDate', 'lastRunDate']);

          return { ...restItem, ...transformedItem, id };
        }),
        ui: {
          ...state.ui,
          isResultsInProgress: false,
        },
      };
    case SET_ENRICHMENT:
      return {
        ...state,
        searches: [
          ...state.searches.map(({ ...props }) => {
            const { payload } = action;
            if (payload && payload.id && (payload.id === props.id)) {
              return {
                ...props,
                isEnrichmentRequested: payload.value,
              };
            }

            return props;
          }),
        ],
      };
    case RESET:
      return defaultState;
    default:
      return state;
  }
}

export default combineReducers({
  lookup,
});
