import { omitBy, isEmpty, map } from 'lodash';

import { NOTE_CARD_TYPE } from 'components/Grm2/constants';

import { transformCardLinks } from './cardLinks';

export const transformCardForBE = (card = {}) => ({
  card: omitBy({
    title: card.title,
    description: card.description,
    date: card.eventDate,
    tag_names: map(card.tags, 'label'),
    card_links: transformCardLinks(card),
    custom_fields_answers: card.customFieldsAnswers,
    uploads: card.uploads,
    user_ids: card.creator,
    interaction_type: card.interactionType,
    card_type: NOTE_CARD_TYPE,
  }, isEmpty),
});
