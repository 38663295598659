import { orderBy } from 'lodash';

import constants from '../constants';
import { fetchStakeholderProfile } from './data';

export function deleteStakeholderTag(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_STAKEHOLDER_TAG });

    const { stakeholderId, tagId, deleteNotifications } = payload;

    const url = `/api_web/stakeholder-management/stakeholders/${stakeholderId}/tags/${tagId}`;
    const ajax = $.ajax({ url, type: 'DELETE' });

    function onSuccess(response) {
      dispatch({
        type: constants.DELETE_STAKEHOLDER_TAG_DONE,
        payload: response.data,
      });

      deleteNotifications.getSuccess();
      dispatch(fetchStakeholderProfile({ stakeholderId }));
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(deleteNotifications.getFailed);
  };
}

export function sortStakeholderTagByColumn(items, columnName, sortDirection) {
  return (dispatch) => {
    dispatch({ type: constants.SORT_STAKEHOLDER_TAG_DATA });

    items = orderBy(
      items,
      [columnName],
      [sortDirection]
    );

    return dispatch({
      type: constants.SORT_STAKEHOLDER_TAG_DATA_DONE,
      payload: items,
    });
  };
}
