const PREFIX = 'MONEYBALL_CLUSTERBUCK_FIRSTQUEUE_PROFILE';

const constants = {
  FETCH_INDIVIDUAL: `${PREFIX}/FETCH_INDIVIDUAL`,
  RECEIVE_INDIVIDUAL_DONE: `${PREFIX}/RECEIVE_INDIVIDUAL_DONE`,
  FETCH_INDIVIDUAL_CONTRIBUTIONS: `${PREFIX}/FETCH_INDIVIDUAL_CONTRIBUTIONS`,
  RECEIVE_INDIVIDUAL_CONTRIBUTIONS_DONE: `${PREFIX}/RECEIVE_INDIVIDUAL_CONTRIBUTIONS_DONE`,
  FETCH_INDIVIDUAL_RECEIPT: `${PREFIX}/FETCH_INDIVIDUAL_RECEIPT`,
  RECEIVE_INDIVIDUAL_RECEIPT_DONE: `${PREFIX}/RECEIVE_INDIVIDUAL_RECEIPT_DONE`,
  SET_ELECTION_CYCLES: `${PREFIX}/SET_ELECTION_CYCLES`,
  SELECT_ELECTION_CYCLE: `${PREFIX}/SELECT_ELECTION_CYCLE`,
  MERGE_DATA_REQUEST: `${PREFIX}/MERGE_DATA_REQUEST`,
  MERGE_DATA_SUCCESS: `${PREFIX}/MERGE_DATA_SUCCESS`,
};

export default constants;
