import { combineReducers } from 'redux';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';
import exportReport from 'redux/exportReport/reducers';
import { updateDataItemArrayPayload, updateDataItem } from 'utils/reducer';

import constants from '../constants';
import { loadingUpdateItem } from './utils/reducersUtils';
import defaultState from './defaultState';

function getLoadingActions(isLoading) {
  return {
    isLoadingTags: isLoading,
    isLoadingPositions: isLoading,
    isLoadingSubscriptions: isLoading,
  };
}

const fetchRegulations = ({ state }) => ({
  ...state,
  sort: {},
  ui: { ...state.ui, ...getLoadingActions(true), isLoadingData: true, toResetSelectedItems: false },
});

const receiveRegulations = ({ state, action }) => ({
  ...state,
  regulations: action.payload,
  paginator: action.paginator,
  sort: action.sort,
  ui: {
    ...state.ui,
    isLoadingData: false,
    isInitialLoading: false,
    toResetSelectedItems: action.toResetSelectedItems,
  },
});

const regulationLookupKeyName = 'regulations';

function regulationsLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_REGULATIONS:
      return fetchRegulations({ state, action });
    case constants.RECEIVE_REGULATIONS:
      return receiveRegulations({ state, action });
    case constants.RESET_REGULATIONS:
      return defaultState;
    case constants.SET_REGULATIONS:
      return {
        ...state,
        regulations: action.payload,
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, regulationLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.BULK_TRACK_DATA:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: true },
      };
    case constants.BULK_TRACK_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: false },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, regulationLookupKeyName),
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.BULK_UPDATE_DATA:
      return loadingUpdateItem(state, action);
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, regulationLookupKeyName),
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, regulationLookupKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: true },
      };
    case constants.BULK_SHARE_TO_MY_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, regulationLookupKeyName),
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.LOAD_POSITIONS:
      return updateDataItemArrayPayload(
        loadingUpdateItem(state, { ...state.ui, isLoadingPositions: action.isLoadingPositions }),
        action,
        regulationLookupKeyName,
      );
    default:
      return state;
  }
}

export default combineReducers({
  regulationsLookup,
  advancedSearch,
  appliedFilters: appliedSearchFilters,
  exportReport,
});
