const PREFIX = 'GRM2';

const constants = {
  SET_NAMESPACE: 'SET_NAMESPACE',
  SET_CARDS: 'SET_CARDS',
  SET_CARDS_LINK_PREVIEW: 'SET_CARDS_LINK_PREVIEW',
  SET_PAGE: 'SET_PAGE',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_SORT: 'SET_SORT',
  SET_FILTERS: 'SET_FILTERS',
  SET_SELECTED: 'SET_SELECTED',
  SET_CUSTOM_FIELDS: 'SET_CUSTOM_FIELDS',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  START_LOADING_CUSTOM_FIELDS: 'START_LOADING_CUSTOM_FIELDS',
  STOP_LOADING_CUSTOM_FIELDS: 'STOP_LOADING_CUSTOM_FIELDS',
  START_LOADING_LINKS_PREVIEW: 'START_LOADING_LINKS_PREVIEW',
  STOP_LOADING_LINKS_PREVIEW: 'STOP_LOADING_LINKS_PREVIEW',
  SELECT_CARDS: 'SELECT_CARDS',
  UNSELECT_CARDS: 'UNSELECT_CARDS',
  RESET_SELECTED_CARDS: 'RESET_SELECTED_CARDS',
  EXPORT_STRATEGY_SLUG: 'grm_v2_cards',
  SET_TIMELINE_DEFAULT_FILTERS: 'SET_TIMELINE_DEFAULT_FILTERS',
  SHOW_DASHBOARD: 'SHOW_DASHBOARD',
  HIDE_DASHBOARD: 'HIDE_DASHBOARD',
  RESET_CARD_STATUS: 'RESET_CARD_STATUS',
  SET_HAS_NEW_CARD: 'SET_HAS_NEW_CARD',
  SET_HAS_EDITED_CARD: 'SET_HAS_EDITED_CARD',
  SET_ADVANCED_SEARCH: 'SET_ADVANCED_SEARCH',
  SET_IS_TASKS_CONTEXT: 'SET_IS_TASKS_CONTEXT',
  RESET_DATA: 'RESET_DATA',
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
};

export const TASK_STATUSES = ['open', 'inProgress', 'closed'];

export const DOCUMENT_TYPE = 'grm_v2_card';

export const ADVANCED_SEARCH_SLUG = 'activity';

export const MY_FEED_CARD_LIMIT = 100;

export default constants;
