import { isEmpty, values } from 'lodash';

import {
  fetchLinksPreview,
  getCardsUrlToPreview,
  getUpdatedLinksPreview,
  transformPreviewDataToFE,
} from 'components/core/LinkPreview';

import constants from '../constants';

export const fetchLinkPreview = response => (
  (dispatch) => {
    const cardsLink = getCardsUrlToPreview(response);
    const urls = values(cardsLink);

    const fetchLinksAction = () => fetchLinksPreview(urls)
      .then(({ data }) => {
        const linksPreview = transformPreviewDataToFE(data);
        const updatedCardsLinkPreview = getUpdatedLinksPreview({}, { cardsLink, linksPreview });

        return dispatch({
          type: constants.SET_CARDS_LINK_PREVIEW_DONE,
          payload: updatedCardsLinkPreview,
        });
      })
      .catch(() => dispatch({
        type: constants.SET_CARDS_LINK_PREVIEW_DONE,
        payload: {},
      }));

    dispatch({
      type: constants.SET_CARDS_LINK_PREVIEW_INIT,
      payload: cardsLink,
    });

    return isEmpty(urls) ? response : fetchLinksAction();
  }
);
