import { isEmpty, compact, capitalize } from 'lodash';

import dataParties from 'components/Moneyball/utils/dataParties';
import dataCommitteeTypes from 'components/Moneyball/utils/dataCommitteeTypes';
import dataSourceTypes from 'components/Moneyball/utils/dataSourceTypes';
import dataElectionTypes from 'components/Moneyball/utils/dataElectionTypes';
import { getAmountValue, getDateValue } from 'redux/moneyball/utils/index';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import {
  RECEIVED,
  DISBURSED,
  INDIVIDUAL,
  COMMITTEE,
  VENDOR,
  BOTH,
  ALL,
} from 'components/Moneyball/utils/constants';

// TODO: replace with utils file when commits from stakeholder directory will be merged

function getTypeValue(items, collection) {
  if (isEmpty(items)) return [];

  return items.map((item) => {
    const { label } = (collection && collection.find(i => i.value === item)) || {};

    return label;
  }).filter(Boolean);
}

function objectValuesToArr(items) {
  if (isEmpty(items)) return null;
  return items.map(({ value }) => value).filter(Boolean) || [];
}

export function getUrl(type, id) {
  return `/moneyball_v2/${type}s/${id}`;
}

export function formatZipCode(zipCode) {
  return zipCode && zipCode.substr(0, 5);
}

export function getValue(key, value, allowEmpty = false) {
  if (!allowEmpty && isEmpty(value)) return null;
  return { [key]: value };
}

export function getAmountsValueByType(amount_donated, amount_received) {
  const amountsType = amount_donated ? DISBURSED : RECEIVED;

  return {
    amountLabel: `${capitalize(amountsType)} Amounts`,
    amountValue: amount_donated
      ? getAmountValue(amount_donated)
      : getAmountValue(amount_received),
  };
}

export function formatDates(date = {}) {
  const { start, end } = date;
  const startDate = start || null;
  const endDate = end || null;

  return { startDate, endDate };
}

export function formatAmounts(amount = {}) {
  const { start, end } = amount;
  const fromValue = start || '';
  const toValue = end || '';

  return { fromValue, toValue };
}

export function formatAmountsValueByType(amount_donated, amount_received) {
  const amountsType = amount_donated ? DISBURSED : RECEIVED;
  let fromValue;
  let toValue;

  if (amountsType === DISBURSED) {
    const { start, end } = amount_donated || {};
    fromValue = start || '';
    toValue = end || '';
  }

  if (amountsType === RECEIVED) {
    const { start, end } = amount_received || {};
    fromValue = start || '';
    toValue = end || '';
  }

  return { fromValue, toValue, type: amountsType };
}

export function formatSelectValueForFE(items) {
  if (isEmpty(items)) return [];
  return items.map(item => ({ label: item.value, value: item.id }));
}

export function formatSelectValueToArrForBE(items) {
  return objectValuesToArr(items);
}

export function formatSelectTypeToValForFE(items) {
  if (isEmpty(items)) return null;

  return items.map(({ type, checked }) => ({
    value: type,
    ...(checked && {
      checked: [
        ...checked,
      ],
    }),
  })).filter(item => !isEmpty(item.value));
}

export function formatReceivedTypesForBE(items) {
  if (isEmpty(items)) return null;

  const formatData = ({ value, checked }) => {
    const names = {
      state: 'state_code',
    };

    if (!isEmpty(checked)) {
      return checked.reduce((collection, current) => ([
        ...collection,
        {
          type: value,
          [names[value]]: current.value,
        },
      ]), []);
    }

    return [{ type: value }];
  };

  return items.reduce((collection, item) => [...collection, ...formatData(item)], []);
}

export function formatSelectValueToObjForBE(items) {
  if (isEmpty(items)) return null;
  return items.map(item => ({ id: item.value, value: item.label }));
}

export function formatReceivedAmountsForBE(type, value) {
  return type === RECEIVED ? value : null;
}

export function formatDisbursedAmountsForBE(type, value) {
  return type === DISBURSED ? value : null;
}

export function formatContributorType(type) {
  return type && type !== BOTH && [type];
}

export function sourceSelectValueForBE(items) {
  if (isEmpty(items)) return [];
  return items.map(item => item);
}

export function formatTypesForSaveBE(items) {
  if (isEmpty(items)) return [];

  return items.map(({ value, checked }) => ({
    type: value,
    ...(checked && { checked }),
  }));
}

export function getCommitteeTypeValue(items) {
  return getTypeValue(items, dataCommitteeTypes);
}

export function getPartiesTypeValue(items) {
  return getTypeValue(items, dataParties);
}

export function getPluralLabel(label, items) {
  const { length } = items || [];

  if (length > 1) {
    return `${label}(s)`;
  }

  return label;
}

export function getCommitteeTypeFilterValue(items) {
  if (isEmpty(items)) return [];

  return items.map(({ type, checked }) => {
    const { label } = dataSourceTypes.find(i => i.value === type) || {};

    if (!isEmpty(checked)) {
      const checkedValues = objectValuesToArr(checked).join(', ');

      if (checkedValues.length > 2) {
        return `${label}s (${checkedValues})`;
      }

      return `${label} (${checkedValues})`;
    }

    return label;
  }).filter(Boolean);
}

export function getElectionTypeFilterValue(items) {
  if (isEmpty(items)) return [];

  return items.map(({ type }) => {
    const { label } = dataElectionTypes.find(i => i.value === type) || {};
    return label;
  }).filter(Boolean);
}

export function getContributorType(contributor = [], toCapitalize = false) {
  const contributorType = !isEmpty(contributor) ? contributor[0] : BOTH;

  return toCapitalize ? capitalize(contributorType) : contributorType;
}

export function getRecipientType(recipient = {}, toCapitalize = false) {
  const { super_committees, super_vendors, super_individuals } = recipient;
  let result;

  if (!isEmpty(super_committees) && !isEmpty(super_individuals)) result = ALL;
  if (!result && !isEmpty(super_individuals)) result = INDIVIDUAL;
  if (!result && !isEmpty(super_committees)) result = COMMITTEE;
  if (!result && !isEmpty(super_vendors)) result = VENDOR;

  return toCapitalize ? capitalize(result) : result;
}

export function getContributionDonorInfo({ superCommDonor, superIndDonor, superCorpDonor }) {
  if (!isEmpty(superCommDonor)) {
    const { id, name } = superCommDonor;

    return {
      id,
      name,
      url: getUrl('committee', id),
    };
  }

  if (!isEmpty(superIndDonor)) {
    const { id, last_name, first_name } = superIndDonor;
    const name = [last_name, first_name].filter(Boolean).join(', ');

    return {
      id,
      name,
      url: getUrl('individual', id),
    };
  }

  if (!isEmpty(superCorpDonor)) {
    const { id, name } = superCorpDonor;

    return {
      id,
      name,
      url: getUrl('corporation', id),
    };
  }

  return {};
}

export function getContributionRecipientInfo(super_committee_recipient) {
  if (!isEmpty(super_committee_recipient)) {
    const { id, name, party_full } = super_committee_recipient;

    return {
      id,
      recipient: name,
      recipientUrl: getUrl('committee', id),
      recipientParty: party_full,
    };
  }

  return {};
}

export function getDisbursedRecipientInfo(recipient = {}) {
  const { super_committee, super_individual, super_vendor } = recipient;
  const { id: superCommitteeId, name: superCommitteeName } = super_committee || {};
  const { id: superIndividualId, name: superIndividualName } = super_individual || {};
  const { id: superVendorId, name: superVendorName } = super_vendor || {};

  let disbursedToId;
  let disbursedToName;
  let disbursedToUrl;

  if (super_committee) {
    disbursedToId = superCommitteeId;
    disbursedToName = superCommitteeName;
    disbursedToUrl = `/moneyball_v2/committees/${superCommitteeId}`;
  }

  if (super_individual) {
    disbursedToId = superIndividualId;
    disbursedToName = superIndividualName;
    disbursedToUrl = `/moneyball_v2/individuals/${superIndividualId}`;
  }

  if (super_vendor) {
    disbursedToId = superVendorId;
    disbursedToName = superVendorName;
    disbursedToUrl = `/moneyball_v2/vendors/${superVendorId}`;
  }

  return {
    id: disbursedToId,
    recipient: disbursedToName,
    recipientUrl: disbursedToUrl,
  };
}

export function getLabelForUI(items) {
  if (isEmpty(items)) return null;
  return items.map(({ label }) => label);
}

export function transformReceiptContributions({
  committee_donor,
  individual_donor,
  corporation_donor,
  committee_recipient,
  receipt,
}) {
  const { name: donorName } = individual_donor || committee_donor || corporation_donor || {};

  const {
    name: recipientName,
    address_city,
    address_state_code,
    address_zip_code,
    treasurer_name,
    type_full,
  } = committee_recipient || {};

  const {
    contribution_amount,
    contribution_date,
    source_type_full,
    election_type_full,
    election_cycle,
    is_pdf_available,
  } = receipt || {};

  return {
    spender: donorName,
    recipient: recipientName,
    recipientAddress: [
      address_city,
      [address_state_code, formatZipCode(address_zip_code)].filter(Boolean).join(' '),
    ].filter(Boolean).join(', '),
    recipientTreasurer: treasurer_name,
    recipientCommitteeType: type_full,
    amount: contribution_amount,
    date: contribution_date,
    sourceType: source_type_full,
    electionType: election_type_full,
    electionCycle: election_cycle,
    hasPdf: is_pdf_available,
  };
}

export function transformReceiptDisbursements({
  spender_name,
  recipient_name,
  disbursement_amount,
  disbursement_date,
  disbursement_description,
  election_cycle,
  is_pdf_available,
}) {
  return {
    spender: spender_name,
    recipient: recipient_name,
    amount: disbursement_amount,
    date: disbursement_date,
    description: disbursement_description,
    electionCycle: election_cycle,
    hasPdf: is_pdf_available,
  };
}

export function transformContributionSearchesForFront({
  query,
  queryType,
  contribution_date,
  contribution_amount,
  contributor_types,
  ids,
  types,
  names,
  employers,
  occupations,
  streets,
  cities,
  states,
  zip_codes,
  source_types,
  election_types,
}) {
  return {
    filters: compact([
      getDisplayItem('Searched', query),
      getDisplayItem('Searched type', queryType),
      getDisplayItem('Dates', getDateValue(contribution_date)),
      getDisplayItem('Amounts', getAmountValue(contribution_amount)),
      getDisplayItem('Contributor Type', getContributorType(contributor_types, true)),
      getDisplayItem('Non-individual name(s)', getLabelForUI(ids)),
      getDisplayItem('Committee type(s)', getCommitteeTypeValue(types)),
      getDisplayItem('Individual Name(s)', names),
      getDisplayItem('Employer(s)', employers),
      getDisplayItem('Occupation(s)', occupations),
      getDisplayItem('Street name(s)', streets),
      getDisplayItem('Cities', cities),
      getDisplayItem('State(s)', states),
      getDisplayItem('Zip code(s)', zip_codes),
      getDisplayItem(getPluralLabel('Source type', source_types), getCommitteeTypeFilterValue(source_types)),
      getDisplayItem(getPluralLabel('Election type', election_types), getElectionTypeFilterValue(election_types)),
    ]),
    options: {
      ...getValue('searchValue', query),
      ...getValue('searchType', queryType && queryType.toLowerCase()),
      options: {
        ...getValue('dates', formatDates(contribution_date)),
        ...getValue('amounts', formatAmounts(contribution_amount)),
        ...getValue('contributorType', getContributorType(contributor_types)),
        ...getValue('contributorNonIndividual', ids),
        ...getValue('contributorCommitteeTypes', types),
        ...getValue('contributorIndividuals', formatSelectValueForFE(names)),
        ...getValue('contributorEmployers', formatSelectValueForFE(employers)),
        ...getValue('contributorOccupations', formatSelectValueForFE(occupations)),
        ...getValue('contributorStreet', formatSelectValueForFE(streets)),
        ...getValue('contributorCity', formatSelectValueForFE(cities)),
        ...getValue('contributorStates', formatSelectValueForFE(states)),
        ...getValue('contributorZip', formatSelectValueForFE(zip_codes)),
        ...getValue('sourceTypes', formatSelectTypeToValForFE(source_types)),
        ...getValue('electionTypes', formatSelectTypeToValForFE(election_types)),
      },
    },
  };
}
