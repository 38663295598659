import isEmpty from 'lodash/isEmpty';

import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { fetchSearch, fetchReport } from 'redux/moneyball/utils';
import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';
import { getValue } from 'utils/transformers/advancedSearch';

import { constants } from './constants';
import {
  transformCandidates,
  transformOptionsForBE,
  transformSearchesForBE,
  transformSearchesForFE,
} from './utils';

export function initAppliedSearchFilters(emptyState, { searchType }) {
  return handleAppliedFilters({ ...emptyState, ...(searchType && { searchType }) });
}

export function loadCandidatesSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadCandidatesRecentSearches() {
  return (dispatch) => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveCandidatesAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformSearchesForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadCandidatesRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeCandidatesAdvancedSearch(id) {
  return onRemoveItem(id, loadCandidatesSavedSearches);
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, customData = {}, isClickedItem = false } = payload;

    if (!isClickedItem && !isEmpty(transformOptionsForBE(searchData))) {
      return dispatch(saveCandidatesAdvancedSearch(searchData, customData, false));
    }

    return null;
  };
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map((item) => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/super_candidates/export`;
  const data = JSON.stringify({
    super_candidate_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'name',
      'url',
      'office_sought',
      'party',
      'state_code',
      'district',
      'total_amount_received',
      'source_type',
      'source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function searchCandidates(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CANDIDATES });

    return dispatch(
      fetchSearch({
        payload,
        url: `${gon.moneyballApiV2}/api/v1/super_candidates/lookup`,
        actionType: constants.RECEIVE_CANDIDATES_DONE,
        transformResponsePayload: transformCandidates,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformSearchesForBE,
        saveRecentAdvancedSearch: saveCandidatesAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return (dispatch) => dispatch(resetAppliedFilters());
}
