import { fetchTags } from 'components/core/DocumentContainer/api';

import constants from '../constants';

export const onTagsSaved = ({ tags }) => (dispatch) => {
  dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
};

export const fetchDocumentTags = (apiUrl, documentId) => dispatch => (
  fetchTags(apiUrl, documentId)
    .then(tags => dispatch(onTagsSaved({ tags })))
);
