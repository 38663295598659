import { omitBy, isNil } from 'lodash';

import { RECENT } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getValue } from 'utils/transformers/advancedSearch';

function transformRecentOptionsForBE({ options }) {
  const {
    date: { startDate, endDate } = {},
    keywords,
    itemType,
    state,
    tags,
    priority,
    position,
    emailFrequency,
    trackedItems,
    sharedItems,
    savedItems,
    removedItems,
    readItems,
  } = options || {};

  return omitBy({
    ...getValue('date_from', startDate),
    ...getValue('date_to', endDate),
    ...getValue('subscription_tokens', keywords),
    ...getValue('document_types', itemType),
    ...getValue('states', state),
    ...getValue('tags', tags),
    ...getValue('priorities', priority),
    ...getValue('positions', position),
    ...getValue('frequency', emailFrequency),
    ...getValue('tracked_items', trackedItems),
    ...getValue('shared_items', sharedItems),
    ...getValue('saved_only', savedItems),
    ...getValue('removed_only', removedItems),
    ...getValue('read_only', readItems),
  }, isNil);
}

function transformTrackedOptionsForBE({ options }) {
  const {
    dateAdded: { startDate: addedStartDate, endDate: addedEndDate } = {},
    lastUpdate: { startDate: updatedStartDate, endDate: updatedEndDate } = {},
    itemType,
    state,
    priority,
    position,
    emailFrequency,
    savedItems,
    removedItems,
    status,
    tags,
    federalBillStatus,
    stateBillStatus,
  } = options || {};

  return omitBy({
    ...getValue('last_update_from', updatedStartDate),
    ...getValue('last_update_to', updatedEndDate),
    ...getValue('document_types', itemType),
    ...getValue('states', state),
    ...getValue('priorities', priority),
    ...getValue('positions', position),
    ...getValue('date_added_from', addedStartDate),
    ...getValue('date_added_to', addedEndDate),
    ...getValue('tags', tags),
    ...getValue('frequency', emailFrequency),
    ...getValue('saved_only', savedItems),
    ...getValue('show_active_only', status),
    ...getValue('removed_only', removedItems),
    ...getValue('federal_bill_status', federalBillStatus),
    ...getValue('state_bill_status', stateBillStatus),
  }, isNil);
}

export function transformOptionsForBE({ type, searchData }) {
  return type === RECENT
    ? transformRecentOptionsForBE(searchData)
    : transformTrackedOptionsForBE(searchData);
}

function transformRecentOptionsForUI({ search_params }) {
  const {
    date_from,
    date_to,
    subscription_tokens,
    document_types,
    states,
    tags,
    priorities,
    positions,
    frequency,
    tracked_items,
    tracked_items_only,
    exclude_tracked_items,
    shared_items,
    show_only_shared_documents,
    exclude_shared_documents,
    saved_only,
    removed_only,
    read_only,
  } = search_params || {};

  return omitBy({
    ...getValue('date', {
      ...getValue('startDate', date_from),
      ...getValue('endDate', date_to),
    }),
    ...getValue('keywords', subscription_tokens),
    ...getValue('itemType', document_types),
    ...getValue('state', states),
    ...getValue('tags', tags),
    ...getValue('priority', priorities),
    ...getValue('position', positions),
    ...getValue('emailFrequency', frequency),
    ...getValue('savedItems', saved_only),
    ...getValue('removedItems', removed_only),
    ...getValue('readItems', read_only),
    ...getValue('trackedItems', tracked_items || tracked_items_only || exclude_tracked_items),
    ...getValue('sharedItems', shared_items
      || show_only_shared_documents
      || exclude_shared_documents),
  }, isNil);
}

function transformTrackedOptionsForUI({ search_params }) {
  const {
    last_update_from,
    last_update_to,
    document_types,
    states,
    priorities,
    positions,
    date_added_from,
    date_added_to,
    tags,
    frequency,
    saved_only,
    show_active_only,
    removed_only,
    federal_bill_status,
    state_bill_status,
  } = search_params || {};

  return omitBy({
    ...getValue('dateAdded', {
      ...getValue('startDate', date_added_from),
      ...getValue('endDate', date_added_to),
    }),
    ...getValue('lastUpdate', {
      ...getValue('startDate', last_update_from),
      ...getValue('endDate', last_update_to),
    }),
    ...getValue('itemType', document_types),
    ...getValue('state', states),
    ...getValue('priority', priorities),
    ...getValue('position', positions),
    ...getValue('emailFrequency', frequency),
    ...getValue('savedItems', saved_only),
    ...getValue('removedItems', removed_only),
    ...getValue('status', show_active_only),
    ...getValue('tags', tags),
    ...getValue('federalBillStatus', federal_bill_status),
    ...getValue('stateBillStatus', state_bill_status),
  }, isNil);
}

export function transformOptionsForUI({ type, searchData }) {
  return type === RECENT
    ? transformRecentOptionsForUI(searchData)
    : transformTrackedOptionsForUI(searchData);
}
