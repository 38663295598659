const keyword = 'STATE_BILL_PROFILE';
export const BILL_SLUG = 'bill';

const constants = {
  FETCH_PROFILE: `${keyword}/FETCH_PROFILE`,
  RECEIVE_PROFILE_DONE: `${keyword}/RECEIVE_PROFILE_DONE`,
  PROFILE_NOT_FOUND: `${keyword}/PROFILE_NOT_FOUND`,
  SET_PROFILE_SUBSCRIPTIONS: `${keyword}/SET_PROFILE_SUBSCRIPTIONS`,
  FETCH_BILL_TIMELINE: `${keyword}/FETCH_BILL_TIMELINE`,
  RECEIVE_BILL_TIMELINE_DONE: `${keyword}/RECEIVE_BILL_TIMELINE_DONE`,
  FETCH_BILLS_COMPARISON: `${keyword}/FETCH_BILLS_COMPARISON`,
  RECEIVE_BILLS_COMPARISON_DONE: `${keyword}/RECEIVE_BILLS_COMPARISON_DONE`,
  FETCH_ROLL_CALL_VOTES: `${keyword}/FETCH_ROLL_CALL_VOTES`,
  RECEIVE_ROLL_CALL_VOTES: `${keyword}/RECEIVE_ROLL_CALL_VOTES`,
  FETCH_AMENDMENTS: `${keyword}/FETCH_AMENDMENTS`,
  RECEIVE_AMENDMENTS: `${keyword}/RECEIVE_AMENDMENTS`,
  FETCH_ACTIVE_VOICES: `${keyword}/FETCH_ACTIVE_VOICES`,
  RECEIVE_ACTIVE_VOICES: `${keyword}/RECEIVE_ACTIVE_VOICES`,
  FETCH_SPONSORS_AND_COSPONSORS: `${keyword}/FETCH_SPONSORS_AND_COSPONSORS`,
  RECEIVE_SPONSORS_AND_COSPONSORS: `${keyword}/RECEIVE_SPONSORS_AND_COSPONSORS`,
  FETCH_TAGS: `${keyword}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${keyword}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${keyword}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${keyword}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${keyword}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${keyword}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${keyword}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${keyword}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  UPDATE_PROFILE_MY_FEED: `${keyword}/UPDATE_PROFILE_MY_FEED`,
  FETCH_SPONSORS_ERROR: `${keyword}/FETCH_SPONSORS_ERROR`,
  FETCH_TIMELINE_ERROR: `${keyword}/FETCH_TIMELINE_ERROR`,
  FETCH_PROFILE_ERROR: `${keyword}/FETCH_PROFILE_ERROR`,
};

export default constants;
