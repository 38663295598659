import { isEmpty } from 'lodash';

import constants from 'redux/dashboardV2/feedPage/constants';
import {
  getFilteredTags,
  transformTagsForBE,
  transformRecordsForTag,
  saveTagsAndUpdateRecords,
} from 'redux/tags/utils';
import {
  bulkTagRecordsNoDispatch,
  fetchTagsByRecordIdsNoDispatch,
  saveTags,
} from 'redux/tags/actions';

export function fetchTags(response, isRecent) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_TAGS });

    fetchTagsByRecordIdsNoDispatch(response, isRecent)
      .then(tags => dispatch({ type: constants.LOAD_TAGS_DONE, payload: tags }))
      .catch(() => dispatch({ type: constants.LOAD_TAGS_DONE, payload: [] }));

    return response;
  };
}

export function bulkSaveTags({ values, selectedItems, onSuccess, onFailure, isRecent }) {
  return (dispatch) => {
    if (isEmpty(values)) {
      return Promise.resolve();
    }

    dispatch({ type: constants.BULK_UPDATE_EVENTS, isLoadingTags: true });

    const { tags } = values || {};
    const { newTags, existingTags } = getFilteredTags(tags);

    function saveTagsForRecord(responseNewTags) {
      return bulkTagRecordsNoDispatch({
        tagIds: transformTagsForBE(existingTags, responseNewTags),
        records: transformRecordsForTag(selectedItems),
      })
        .then(() => dispatch(fetchTags(selectedItems, isRecent)))
        .then(onSuccess)
        .catch(onFailure)
        .finally(() => dispatch({ type: constants.BULK_UPDATE_EVENTS, isLoadingTags: false }));
    }

    return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
  };
}

export function onSaveTags(props) {
  return dispatch => dispatch(saveTags(props, constants));
}
