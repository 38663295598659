import { map, compact, find } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';

const isSocialMediaPost = documentType => documentType === DOCUMENT_TYPES.SOCIAL_MEDIA_POST;

export function getPosts(data) {
  return compact(map(data, ({ documentType, descriptionNode }) => {
    if (!isSocialMediaPost(documentType)) {
      return null;
    }

    const { data: post } = descriptionNode || {};

    return post;
  }));
}

export function updateDataPosts({ data, replies }) {
  return map(data, (item) => {
    const { documentType, descriptionNode } = item;

    if (!isSocialMediaPost(documentType)) {
      return item;
    }

    const { data: post } = descriptionNode || {};
    const { id } = post || {};
    const updatedPost = find(replies, { id });

    return updatedPost
      ? { ...item, descriptionNode: { ...descriptionNode, data: updatedPost } }
      : item;
  });
}
