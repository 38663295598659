const constants = {
  UPLOAD_FILES: 'ROYAL_FILE_UPLOADER/UPLOAD_FILES',
  COMPLETE_UPLOADS: 'ROYAL_FILE_UPLOADER/COMPLETE_UPLOADS',
  RECEIVE_ERRORS: 'ROYAL_FILE_UPLOADER/RECEIVE_ERRORS',
  FETCH_FILES: 'ROYAL_FILE_UPLOADER/FETCH_FILES',
  RECEIVE_FILES: 'ROYAL_FILE_UPLOADER/RECEIVE_FILES',
  DELETE_FILES: 'ROYAL_FILE_UPLOADER/DELETE_FILES',
  DELETE_FILE: 'ROYAL_FILE_UPLOADER/DELETE_FILE',
};

export function uploadFiles(payload) {
  return (dispatch) => {
    dispatch({ type: constants.UPLOAD_FILES, payload });

    const url = '/api_web/file_uploads';
    const method = 'POST';

    const asyncRequests = payload.files.map(async (file) => {
      const data = new FormData();
      data.append('file', file);
      data.append('strategy_slug', payload.strategySlug);
      data.append('required_inputs', JSON.stringify(payload.requiredInputs));

      const ajax = await $.ajax({
        data,
        method,
        url,
        cache: false,
        contentType: false,
        processData: false,
      });

      return { ...ajax, name: file.name };
    });

    return Promise.all(asyncRequests).then(files =>
      dispatch({ type: constants.COMPLETE_UPLOADS, payload: files })
    ).catch(errors =>
      dispatch({ type: constants.RECEIVE_ERRORS, payload: errors.responseJSON })
    );
  };
}

export function fetchFiles(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_FILES, payload });

    const method = 'GET';
    const cleanFileIds = payload.fileUploadIds.filter(fileUploadId => !!fileUploadId);

    const asyncRequests = cleanFileIds.map((fileUploadId) => {
      const url = `/api_web/file_uploads/${fileUploadId}`;
      const ajax = $.ajax({
        method,
        url,
      });

      return Promise.resolve(ajax);
    });

    return Promise.all(asyncRequests).then(files =>
      dispatch({ type: constants.RECEIVE_FILES, payload: files })
    ).catch(errors =>
      dispatch({ type: constants.RECEIVE_ERRORS, payload: errors.responseJSON })
    );
  };
}

export function deleteFiles(payload) {
  return { type: constants.DELETE_FILES, payload };
}

export function deleteFile(payload) {
  return { type: constants.DELETE_FILE, payload };
}


export const defaultState = {
  files: [],
  fileErrors: [],
  ui: {
    uploadingFiles: false,
    loadingFiles: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.UPLOAD_FILES:
      return {
        ...state,
        ui: { ...state.ui, uploadingFiles: true },
        files: [],
        fileErrors: [],
      };
    case constants.COMPLETE_UPLOADS:
      return {
        ...state,
        ui: { ...state.ui, uploadingFiles: false },
        files: action.payload,
        fileErrors: [],
      };
    case constants.RECEIVE_ERRORS:
      return {
        ...state,
        ui: { ...state.ui, uploadingFiles: false },
        files: [],
        fileErrors: action.payload.file,
      };
    case constants.FETCH_FILES:
      return {
        ...state,
        ui: { ...state.ui, loadingFiles: true },
        files: [],
        fileErrors: [],
      };
    case constants.RECEIVE_FILES:
      return {
        ...state,
        ui: { ...state.ui, loadingFiles: false },
        files: action.payload,
      };
    case constants.DELETE_FILES:
      return {
        ...state,
        ui: { ...state.ui, uploadingFiles: false },
        files: [],
      };
    case constants.DELETE_FILE:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.payload),
      };
    default:
      return state;
  }
}
