import { combineReducers } from 'redux';

import {
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  UPDATE_TRANSACTOR,
  UPDATE_DATA,
  CREATE_LIST_START,
  CREATE_LIST_DONE,
  GET_LIST_START,
  GET_LIST_DONE,
  UPDATE_ISTEMPLATE,
  RESET,
} from './constants';

export const defaultState = {
  error: false,
  title: '',
  description: '',
  isTemplate: false,
  transactor: '',
  data: {},
  ui: {
    isLoading: false,
  },
};

function builder(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_ISTEMPLATE:
      return {
        ...state,
        isTemplate: action.payload,
      };
    case UPDATE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case UPDATE_TRANSACTOR:
      return {
        ...state,
        transactor: action.payload.transactor,
        data: action.payload.data,
      };
    case UPDATE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case CREATE_LIST_START:
      return {
        ...state,
        ui: {
          isLoading: true,
        },
      };
    case CREATE_LIST_DONE:
      return {
        ...state,
        ui: {
          isLoading: false,
        },
      };
    case GET_LIST_START:
      return {
        ...state,
        error: false,
        ui: {
          isLoading: true,
        },
      };
    case GET_LIST_DONE:
      return {
        ...state,
        ...action.payload,
        error: action.error,
        ui: {
          isLoading: false,
        },
      };
    case RESET:
      return defaultState;
    default:
      return state;
  }
}

export default combineReducers({
  builder,
});
