import { responseTrackingTypes, SUBSCRIBABLE_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';

export const BASE_URL = '/api_web/discovery';
export const BASE_URL_SUBSCRIPTIONS = `${BASE_URL}/subscriptions`;
export const BASE_URL_TRACKINGS = `${BASE_URL_SUBSCRIPTIONS}/trackings`;
export const BASE_FEEDS_URL = `${BASE_URL}/feeds`;
export const BASE_FEEDS_MANAGER_URL = `${BASE_URL}/manager/feeds`;

export const KEYWORD_TYPE = 'keyword';
export const STAFFER_OFFICE_TYPE = 'staffer_office';
export const COMMITTEE_TYPE = 'committee';
export const STATE_COMMITTEE_TYPE = 'state_committee';
export const STATE_STAFFER_OFFICE_TYPE = 'state_staffer_office';
export const STATE_COMMITTEES_TYPE = 'state_committees';
export const STATE_COMMITTEE_STAFFER_TYPE = 'state_committee_staffer';
export const FEDERAL_COMMITTEES_TYPE = 'federal_committees';
export const WORKSPACE_TYPE = 'workspace';
export const SHOW_ONLY_SHARED_DOCS = 'show_only_shared_documents';
export const EXCLUDE_SHARED_DOCS = 'exclude_shared_documents';
export const SHOW_ALL = 'show_all';

export const TRACKED_ITEMS_ONLY = 'tracked_items_only';
export const EXCLUDE_TRACKED_ITEMS = 'exclude_tracked_items';

export const REMOVED_FREQUENCY = 'Removed';

export const TRACK_SUBSCRIPTION_FREQUENCIES = {
  [SUBSCRIBABLE_TYPES.FEDERAL_BILL]: 'Bill updates',
  [SUBSCRIBABLE_TYPES.STATE_BILL]: 'Bill updates',
  [SUBSCRIBABLE_TYPES.STATE_COMMITTEE]: 'Hearing updates',
  [responseTrackingTypes.STATE_COMMITTEE_STAFFER]: 'Staffer updates',
  [responseTrackingTypes.FEDERAL_BILL]: 'Bill updates',
  [responseTrackingTypes.STATE_BILL]: 'Bill updates',
  [SUBSCRIBABLE_TYPES.FEDERAL_REGISTER_REGULATION]: 'Regulation updates',
  [responseTrackingTypes.FEDERAL_REGULATION]: 'Regulation updates',
  [SUBSCRIBABLE_TYPES.STATE_REGULATION]: 'Regulation updates',
  [responseTrackingTypes.STATE_REGULATION]: 'Regulation updates',
  [SUBSCRIBABLE_TYPES.FEDERAL_STAFFER_OFFICE]: 'Staffer updates',
  [SUBSCRIBABLE_TYPES.STATE_STAFFER_OFFICE]: 'Staffer updates',
  [responseTrackingTypes.FEDERAL_LEGISLATOR_STAFFER]: 'Staffer updates',
  [responseTrackingTypes.FEDERAL_LEGISLATOR]: 'Staffer updates',
  [responseTrackingTypes.STATE_LEGISLATOR_STAFFER]: 'Staffer updates',
  [responseTrackingTypes.STATE_LEGISLATOR]: 'Staffer updates',
  [SUBSCRIBABLE_TYPES.COMMITTEE]: 'Hearing updates',
  [responseTrackingTypes.FEDERAL_COMMITTEE_HEARING]: 'Hearing updates',
  [responseTrackingTypes.STATE_COMMITTEE_HEARING]: 'Hearing updates',
};

export const KEYWORD_SECTION = 'keyword';
export const BILLS_REGULATIONS_SECTION = 'bills_regulations';
export const COMMITTEE_SECTION = 'committee';
export const LEGISLATOR_SECTION = 'legislator';
export const NEWSFEED_SECTION = 'newsfeed';
export const WORKSPACE_SECTION = 'workspace';
