import PropTypes from 'prop-types';

import { workspaceShape } from 'redux/events/utils/shapes';
import { stringNumberType } from 'utils/shapes';

export const subscriptionShape = PropTypes.shape({
  id: stringNumberType,
  token: PropTypes.string.isRequired,
  frequency: PropTypes.string,
  isSaved: PropTypes.bool,
  priority: PropTypes.string,
  title: PropTypes.string,
  subscribableType: PropTypes.string,
  subscribableData: PropTypes.shape({
    model_id: stringNumberType,
    model_type: PropTypes.string,
  }),
  isDisabled: PropTypes.bool,
  sendWeekAheadEmail: PropTypes.bool,
  isTracking: PropTypes.bool,
});

export const trackingShape = PropTypes.shape({
  feed: workspaceShape.isRequired,
  subscription: subscriptionShape,
  subscriptions: PropTypes.arrayOf(subscriptionShape),
});
