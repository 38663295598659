import map from 'lodash/map';

import notifications from 'components/Dashboard/utils/notifications';
import { notifySharedToMyFeed } from 'components/Dashboard/common/UserNotifications';
import { BASE_URL } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getMyFeed } from 'components/Dashboard/utils';
import { NEWS_STORY_ITEM_TYPE } from 'utils/constants/itemTypes';

import constants from '../constants';

const myFeed = getMyFeed();

export function fetchMyFeedDocuments(ids) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_MY_FEED_DOCUMENTS });

    const url = '/api_web/news/events/check';
    const ajax = $.post(url, { document_ids: ids });

    function onSuccess(response) {
      dispatch({
        type: constants.RECEIVE_MY_FEED_DOCUMENTS_DONE,
        payload: map(response, recordId => (
          { recordId, sharings: [myFeed] }
        )),
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE });

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function shareToMyFeed(id) {
  return (dispatch) => {
    dispatch({ type: constants.SHARE_TO_MY_FEED });

    const url = '/api_web/news/shared_documents';
    const data = { record_ids: [id] };

    const ajax = $.post(url, data);

    function onSuccess(response) {
      const [subscription] = response || [];
      const { last_event_token: token } = subscription || {};
      const href = token ? `${BASE_URL}/feed/recent/${token}` : '';

      dispatch({
        type: constants.SHARE_TO_MY_FEED_DONE,
        payload: { recordId: id, sharings: [myFeed] },
      });

      notifySharedToMyFeed({ itemType: NEWS_STORY_ITEM_TYPE, href });
    }

    function onFailure(error) {
      dispatch({ type: constants.SHARE_TO_MY_FEED_FAILURE });

      notifications.generalFailedNotification();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}
