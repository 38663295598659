import { getValue, formatZipCode } from 'redux/moneyball/utils/transformers';
import { formatGlobalDate } from 'utils/date';

export function transformVendors(vendors = []) {
  return vendors.map((vendorItem) => {
    const { vendor, total_received, last_disbursement_date } = vendorItem;
    const { id, zip_code, ...restProps } = vendor || {};

    return ({
      ...restProps,
      id,
      url: `/moneyball_v2/vendors/${id}`,
      amount: total_received,
      date: formatGlobalDate(last_disbursement_date),
      zip_code: formatZipCode(zip_code),
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue } = data;

  return {
    ...getValue('query', searchValue),
  };
}
