import { GLOBAL_SEARCH_URL } from 'components/GlobalSearch/utils/constants';

import { transformPayloadForBE, transformResponseForUI } from '../transformers';
import constants from '../constants';

const { addNotification } = window.app.GlobalNotifier;

const onFetchSuccess = (dispatch, isInfiniteLoad) => response => dispatch({
  type: constants.RECEIVE_RESULTS_DONE,
  payload: transformResponseForUI(response),
  isInfiniteLoad,
});

const onFetchError = dispatch => () => {
  addNotification({
    message: 'Could not load results. Please try again.',
    level: 'error',
  });

  dispatch({
    type: constants.RECEIVE_RESULTS_ERROR,
  });
};

export function fetchResults({ searchTerm, searchParams = {}, page, isInfiniteLoad }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_RESULTS, isInfiniteLoad });
    const requestData = transformPayloadForBE({ searchTerm, searchParams, page });
    const url = GLOBAL_SEARCH_URL;
    const method = 'POST';

    const ajax = $.ajax({
      url,
      method,
      data: requestData,
    });

    return Promise.resolve(ajax)
      .then(onFetchSuccess(dispatch, isInfiniteLoad))
      .catch(onFetchError(dispatch));
  };
}
