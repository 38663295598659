import constants from './constants';

export const defaultState = {
  showAlert: false,
  ui: {
    isLoadingAlert: false,
    isDismissingAlert: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_ALERT:
      return {
        ...state,
        ui: { ...state.ui, isLoadingAlert: true },
      };
    case constants.FETCH_ALERT_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingAlert: false },
        showAlert: action.payload,
      };
    case constants.FETCH_ALERT_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingAlert: false },
      };
    case constants.DISMISS_ALERT:
      return {
        ...state,
        ui: { ...state.ui, isDismissingAlert: true },
      };
    case constants.DISMISS_ALERT_DONE:
      return {
        ...state,
        showAlert: defaultState.showAlert,
        ui: { ...state.ui, isDismissingAlert: false },
      };
    case constants.DISMISS_ALERT_FAILURE:
      return {
        ...defaultState,
        ui: { ...defaultState.ui, isDismissingAlert: false },
      };
    case constants.RESET_ALERT:
      return defaultState;
    default:
      return state;
  }
}
