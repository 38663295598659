import isEmpty from 'lodash/isEmpty';

import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';
import { getValue } from 'utils/transformers/advancedSearch';

import constants from '../constants';
import { transformOptionsForBE, transformSearchesForUI } from '../utils/transformers';

export function loadStakeholdersRecentSearches(params) {
  const recentSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return dispatch => dispatch(loadRecentSearches(
    recentSearch,
    data => transformSearchesForUI(data))
  );
}

export function loadStakeholdersSavedSearches(params) {
  const savedSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return loadSavedSearches(
    savedSearch,
    data => transformSearchesForUI(data)
  );
}

export function saveAdvancedSearchItem(data = {}, customData, isSaved = true) {
  const { id, name } = data;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: transformOptionsForBE(data),
      saved: isSaved,
    },
  };

  if (!isSaved) {
    return onSaveRecentItem(
      advancedSearchData,
      params => loadStakeholdersRecentSearches(params, customData)
    );
  }

  return onSaveItem(
    advancedSearchData,
    params => loadStakeholdersRecentSearches(params, customData)
  );
}

export function removeAdvancedSearchItem(id) {
  return onRemoveItem(
    id,
    params => loadStakeholdersSavedSearches(params));
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, isClickedItem = false } = payload;

    if (!isClickedItem && !isEmpty(transformOptionsForBE(searchData))) {
      return dispatch(saveAdvancedSearchItem(searchData, null, false));
    }

    return null;
  };
}

export { setLastSearchId } from 'redux/advancedSearch/actions';
