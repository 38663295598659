import map from 'lodash/map';

import { transformSubscription } from 'redux/subscriptions/utils/transformers';

import constants from './constants';
import * as api from './api';

export function saveTrackItems(data) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_TRACK_ITEMS });

    const { payload, handleSuccess, handleFailure, feedToken } = data || {};

    return api.saveTrackItems(feedToken, payload)
      .then((response) => {
        const { data: subscriptions } = response || {};

        dispatch({ type: constants.SAVE_TRACK_ITEMS_DONE });
        handleSuccess(map(subscriptions, transformSubscription));
      })
      .catch((error) => {
        dispatch({ type: constants.SAVE_TRACK_ITEMS_DONE });
        handleFailure(error);
      });
  };
}
