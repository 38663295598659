import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import onePageReport from './one-page-report';
import dashboardV2 from './dashboardV2';
import dashboardDiscoveryKeywordManager from './dashboard-discovery-keyword-manager';
import dashboardTrackingCenter from './dashboard-tracking-center';
import districtMaps from './district-maps';
import dismissibleAlert from './dismissibleAlert/reducers';
import documentView from './documentView/reducers';
import HillDayCalendarEventBuilder from './hill-day-calendar-event-builder';
import HillDayCalendarEventCalendar from './hill-day-calendar-event-calendar';
import actionCenterManager from './action-center-manager';
import grassrootsCampaigns from './grassroots-campaigns';
import grassrootsCampaignBuilder from './grassrootsCampaignBuilder/reducer';
import grassrootsSettings from './grassroots-settings';
import grassrootsReports from './grassrootsReports/reducer';
import grassrootsPortal from './grassrootsPortal/reducer';
import grm2 from './grm2/reducers';
import legislatorDistances from './legislator-distances';
import legislatorLookup from './legislator-lookup';
import royalFileUploader from './royal-file-uploader';
import selectAll from './select-all';
import stateLegWatchlist from './stateLegWatchlist/reducers';
import royalCosponsorsOracle from './royal-cosponsors-oracle';
import federal from './federal';
import state from './state';
import local from './local';
import moneyball from './moneyball';
import news from './news';
import rolodex from './rolodex';
import customFields from './customFields/reducers';
import fileUpload from './fileUpload/reducer';
import stakeholder from './stakeholder';
import serviceTerms from './service-terms/reducer';
import lobbyAlertsPortal from './lobbyAlertsPortal/reducer';
import donorLookupPortal from './donorLookupPortal/reducer';
import publicRecords from './publicRecords/reducers';
import bills from './bills/reducers';
import votingRecord from './votingRecord/reducers';
import committeesLookup from './state/committeesLookup/reducers';
import staffers from './staffers/reducers';
import organization from './organization';
import socialMedia from './socialMedia';
import globalSearch from './globalSearch/reducers';
import notifications from './notifications/reducers';
import tracking from './tracking/reducers';

// Combine reducers
const reducers = combineReducers({
  dashboardV2,
  dashboardDiscoveryKeywordManager,
  dashboardTrackingCenter,
  districtMaps,
  dismissibleAlert,
  documentView,
  HillDayCalendarEventBuilder,
  HillDayCalendarEventCalendar,
  form,
  actionCenterManager,
  grassrootsCampaigns,
  grassrootsCampaignBuilder,
  grassrootsSettings,
  grassrootsReports,
  grassrootsPortal,
  grm2,
  legislatorDistances,
  legislatorLookup,
  onePageReport,
  royalFileUploader,
  royalCosponsorsOracle,
  selectAll,
  stateLegWatchlist,
  federal,
  state,
  local,
  moneyball,
  news,
  rolodex,
  customFields,
  fileUpload,
  stakeholder,
  serviceTerms,
  lobbyAlertsPortal,
  donorLookupPortal,
  committeesLookup,
  publicRecords,
  bills,
  votingRecord,
  staffers,
  organization,
  notifications,
  socialMedia,
  globalSearch,
  tracking,
});

// Define redux middlewares
const middlewares = [thunk];
const storeParams = [];

// Log if we are in development mode
if (gon.env === 'development') {
  /** Uncomment this when debug is needed */
  // middlewares.push(logger({ timestamp: false }));

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    storeParams.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

storeParams.unshift(applyMiddleware(...middlewares));

// Make store with middlewares
const makeStore = compose(
  ...storeParams,
)(createStore);

const store = makeStore(reducers, {});

export const prepopulatedStore = prepopulatedState => makeStore(reducers, prepopulatedState);

export default store;
