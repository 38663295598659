const PREFIX = 'LOCAL';

export const API_URL = '/api_web/local/documents/lookup';
export const STRATEGY_SLUG = 'local';
export const STRATEGY_SLUG_EXPORT = 'town_intel';

const constants = {
  FETCH_DOCUMENTS: `${PREFIX}/FETCH_DOCUMENTS`,
  RECEIVE_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_DOCUMENTS_DONE`,
  RESET_DOCUMENTS: `${PREFIX}/RESET_DOCUMENTS`,
  SET_DOCUMENTS: `${PREFIX}/SET_DOCUMENTS`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  BULK_UPDATE_DATA: `${PREFIX}/BULK_UPDATE_DATA`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  BULK_SHARE_TO_MY_FEED: `${PREFIX}/BULK_SHARE_TO_MY_FEED`,
  BULK_SHARE_TO_MY_FEED_DONE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_DONE`,
  BULK_SHARE_TO_MY_FEED_FAILURE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_FAILURE`,
};

export default constants;
