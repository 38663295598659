import componentConstants from '../components/legislator-distances/constants';

const constants = {
  RECEIVE_RESULTS: 'LEGISLATOR_DISTANCES/RECEIVE_RESULTS',
  FETCH_RESULTS: 'LEGISLATOR_DISTANCES/FETCH_RESULTS',
  FETCH_FAILED: 'LEGISLATOR_DISTANCES/FETCH_FAILED',
};

const getUrlByStrategy = ({ strategy, form }) => {
  const legislatorId = form.legislator;
  const prefix = '/api_web/similarity_graph';
  const urlHash = {
    [componentConstants.LEGISLATOR_DISTANCES]: `${prefix}/federal_legislators/${legislatorId}/cosponsored_bills`,
    [componentConstants.STATE_LEGISLATOR_DISTANCES]: `${prefix}/state_legislators/${legislatorId}/cosponsored_bills`,
  };

  if (!urlHash[strategy]) {
    // eslint-disable-next-line no-console
    console.error(`No api url supplied for strategy ${strategy}`);
  }

  return urlHash[strategy] || null;
};

export function search(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_RESULTS });
    const url = getUrlByStrategy(payload);
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
      data: { form: payload.form },
    });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: constants.RECEIVE_RESULTS, payload: result })
      )
      .catch(() =>
        dispatch({ type: constants.FETCH_FAILED })
      );
  };
}

export const defaultState = {
  links: [],
  nodes: [],
  everSearched: false,
  ui: {
    loading: false,
    loadingForm: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.RECEIVE_RESULTS:
      return {
        ...state,
        links: action.payload.links,
        nodes: action.payload.nodes,
        legislator: action.payload.legislator,
        everSearched: true,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case constants.FETCH_RESULTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case constants.FETCH_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    default:
      return state;
  }
}
