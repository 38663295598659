import {
  reduce,
  uniqWith,
  isEqual,
  isEmpty,
  map,
  toString,
  find,
  some,
} from 'lodash';

import {
  transformNewTagsForUI,
} from 'redux/tags/utils';
import { pushUniq } from 'utils/array';

import { applyCardChanges } from './card';

const isPresentTag = (items, item) => some(items, ['value', item.value]);

export const getUpdatedCardByTags = (cards, tags, cardId) => {
  const cardToBeUpdated = find(cards, ['id', cardId]);

  return {
    ...cardToBeUpdated,
    tags: transformNewTagsForUI(tags),
  };
};

export const getUpdatedCardsByTags = (cards, data) => reduce(data, (result, item) => {
  const { record_id, tag_id, tag_name } = item || {};
  const tags = {
    data: [{ id: tag_id, name: tag_name }],
  };

  const cardToBeUpdated = find(result, ['id', +record_id]);
  if (isEmpty(cardToBeUpdated)) {
    return result;
  }

  const tagsForUi = transformNewTagsForUI(tags);
  const newTags = pushUniq(cardToBeUpdated.tags, tagsForUi[0], isPresentTag);

  const updatedCard = {
    ...cardToBeUpdated,
    tags: newTags,
  };

  return applyCardChanges(result, updatedCard);
}, cards);

export const transformRecordsForBe = (data) => {
  const formattedRecords = map(data, recordId => toString(recordId));

  return uniqWith(formattedRecords, isEqual);
};

export function transformBulkTagsRecordsForBE(payload) {
  const { records, tagIds } = payload || {};

  return {
    card_ids: records,
    tag_ids: tagIds,
  };
}

export const getUniqRecordsId = records => reduce(
  records,
  (result, { record_id }) => pushUniq(result, record_id),
  []
);

export const tagMayCauseInconsistency = ({ tags, keywordLookup }) => (
  !isEmpty(tags) || !isEmpty(keywordLookup)
);
