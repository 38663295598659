import { combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import constants from './constants';

export const defaultState = {
  error: false,
  committee: {},
  committeesData: [],
  committeesDataError: false,
  committeesReceipt: {},
  committeesReceiptError: false,
  electionCyclesData: [],
  electionCycle: null,
  paginator: {},
  sort: {},
  ui: {
    isInitialLoading: true,
    isCommitteeLoading: true,
    isCommitteesDataLoading: true,
    isCommitteesReceiptLoading: true,
    isLoadingReport: false,
    toResetSelectedItems: true,
  },
};

function profile(state = defaultState, action) {
  const initialLoadingStatus = !!(
    state.ui.isInitialLoading &&
    state.committeesData.length &&
    !isEmpty(state.committee)
  );

  switch (action.type) {
    case constants.FETCH_COMMITTEE:
      return {
        ...state,
        error: false,
        committee: {},
        ui: {
          ...state.ui,
          isCommitteeLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_COMMITTEE_DONE:
      return {
        ...state,
        error: action.error,
        committee: action.payload,
        ui: {
          ...state.ui,
          isCommitteeLoading: false,
          toResetSelectedItems: true,
          isInitialLoading: initialLoadingStatus,
        },
      };
    case constants.FETCH_COMMITTEES_DATA:
      return {
        ...state,
        committeesDataError: false,
        sort: {},
        ui: {
          ...state.ui,
          isCommitteesDataLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_COMMITTEES_DATA_DONE:
      return {
        ...state,
        committeesDataError: action.error,
        committeesData: action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isCommitteesDataLoading: false,
          isInitialLoading: initialLoadingStatus,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.FETCH_COMMITTEES_RECEIPT:
      return {
        ...state,
        committeesReceiptError: false,
        ui: {
          ...state.ui,
          isCommitteesReceiptLoading: true,
        },
      };
    case constants.RECEIVE_COMMITTEES_RECEIPT_DONE:
      return {
        ...state,
        committeesReceipt: action.payload,
        committeesReceiptError: action.error,
        ui: {
          ...state.ui,
          isCommitteesReceiptLoading: false,
        },
      };
    case constants.SET_ELECTION_CYCLES:
      return {
        ...state,
        electionCyclesData: action.payload,
      };
    case constants.SELECT_ELECTION_CYCLE:
      return {
        ...state,
        electionCycle: action.payload,
      };
    case constants.SUBMIT_EXPORT_REPORT_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: true,
        },
      };
    case constants.SUBMIT_EXPORT_REPORT_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: false,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  appliedSearchFilters,
  advancedSearch,
  profile,
});
