import React from 'react';

import Sponsor from 'components/Dashboard/common/Sponsor';
import { responseEventTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getSource } from 'redux/dashboardV2/feedPage/utils';
import {
  FEDERAL_COMMITTEE_ITEM_TYPE,
  FEDERAL_BILL_ITEM_TYPE,
  FEDERAL_REGULATION_ITEM_TYPE,
} from 'utils/constants/itemTypes';

import {
  getHearingStatus,
  getHearingNotificationTitle,
  getTitle,
  getTitleUrl,
  displayDescription,
  formatDescription,
  getPositionDescription,
  getStafferItemType,
} from '../utils';
import { SPONSOR_NODE, HEARING_NODE, LINK_NODE } from './descriptionNodeTypes';

function transformBillAction({
  isNotification = false,
  createdAt,
  event_data: {
    action,
    bill_id,
    bill_display_id,
    bill_display_title,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = FEDERAL_BILL_ITEM_TYPE;
  const title = getTitle(bill_display_id, bill_display_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/federal/bills');
  const actionType = 'Bill action';

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: actionType,
      description: action,
      itemType,
      itemTitle: bill_display_id,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType,
    itemType,
    title,
    createdAt,
    description: action,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformBillCosponsor({
  isNotification = false,
  createdAt,
  event_data: {
    bill_id,
    bill_display_id,
    bill_display_title,
    cosponsor_id,
    cosponsor_name,
    cosponsor_party_short,
    cosponsor_party_slug,
    cosponsor_come_from,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = FEDERAL_BILL_ITEM_TYPE;
  const title = getTitle(bill_display_id, bill_display_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/federal/bills');
  const infoText = 'is now a cosponsor';

  const descriptionData = {
    url: getTitleUrl(cosponsor_id, '/federal/legislators'),
    label: cosponsor_name,
    partyShort: cosponsor_party_short,
    partySlug: cosponsor_party_slug,
    comeFrom: cosponsor_come_from,
    infoText,
    isExternal: false,
  };

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'Bill cosponsor added',
      description: <Sponsor data={descriptionData} withLink={false} />,
      itemType,
      itemTitle: bill_display_id,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'Cosponsors added',
    itemType,
    title,
    createdAt,
    descriptionNode: {
      type: SPONSOR_NODE,
      data: descriptionData,
    },
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformBillText({
  isNotification = false,
  createdAt,
  event_data: {
    bill_id,
    bill_display_id,
    bill_display_title,
    text_version,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = 'Federal bill';
  const title = getTitle(bill_display_id, bill_display_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/federal/bills');

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'Bill action',
      description: text_version,
      itemType,
      itemTitle: bill_display_id,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'New draft',
    itemType: FEDERAL_BILL_ITEM_TYPE,
    title,
    createdAt,
    description: text_version,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformBillHearing({
  isNotification = false,
  createdAt,
  event_data: {
    bill_id,
    bill_display_id,
    bill_display_title,
    hearing_subject,
    hearing_location,
    action_type,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = FEDERAL_BILL_ITEM_TYPE;
  const title = getTitle(bill_display_id, bill_display_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/federal/bills');

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: getHearingNotificationTitle(action_type, 'Bill'),
      description: hearing_subject,
      itemType,
      itemTitle: bill_display_id,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: getHearingStatus(action_type),
    itemType,
    title,
    createdAt,
    descriptionNode: {
      type: HEARING_NODE,
      data: {
        subject: hearing_subject,
        location: hearing_location,
      },
    },
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformBillPosition({
  isNotification = false,
  createdAt,
  event_data: {
    bill_id,
    bill_display_id,
    bill_display_title,
    new_user_position_type,
    user_full_name,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = FEDERAL_BILL_ITEM_TYPE;
  const title = getTitle(bill_display_id, bill_display_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/federal/bills');
  const description = getPositionDescription({
    position: new_user_position_type,
    userFullName: user_full_name,
    isNotification,
  });

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'Position update',
      description,
      itemType,
      itemTitle: bill_display_id,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'Position changed',
    itemType,
    createdAt,
    title,
    description,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformCommitteeHearing({
  isNotification = false,
  createdAt,
  event_data: {
    action_type,
    committee_id,
    committee_name: title,
    hearing_subject,
    hearing_location,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = FEDERAL_COMMITTEE_ITEM_TYPE;
  const titleUrl = getTitleUrl(committee_id, '/federal/committees');

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: getHearingNotificationTitle(action_type, 'Committee'),
      description: hearing_subject,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: getHearingStatus(action_type),
    itemType,
    title,
    createdAt,
    descriptionNode: {
      type: HEARING_NODE,
      data: {
        subject: hearing_subject,
        location: hearing_location,
      },
    },
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformRegulationPosition({
  isNotification = false,
  createdAt,
  event_data: {
    user_full_name,
    regulation_title: title,
    regulation_id,
    new_user_position_type,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = FEDERAL_REGULATION_ITEM_TYPE;
  const titleUrl = getTitleUrl(regulation_id, '/federal/regulations');
  const description = getPositionDescription({
    position: new_user_position_type,
    userFullName: user_full_name,
    isNotification,
  });

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'Position update',
      description,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'Position changed',
    itemType,
    createdAt,
    title,
    description,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformPublicSubmission({
  isNotification = false,
  createdAt,
  event_data: {
    document_type: documentType,
    regulation_title: title,
    regulation_url: titleUrl,
    text,
  } = {},
  feed,
}) {
  const itemType = 'Federal regulation';

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'New public submission',
      description: text,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'New public submission',
    itemType: FEDERAL_REGULATION_ITEM_TYPE,
    title,
    createdAt,
    description: text,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function getBaseStaffer({
  isNotification,
  isDescriptionVisible,
  createdAt,
  afterLabel,
  event_data: {
    staffer_id,
    staffer_name,
    come_from: comeFrom,
    office_name: title,
    office_url: titleUrl,
    party_short: partyShort,
    document_type: documentType,
  } = {},
  feed,
}) {
  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      ...(isDescriptionVisible && {
        description: `${staffer_name} ${afterLabel}`,
      }),
      itemType: 'Congressional Staffers',
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    itemType: getStafferItemType(documentType),
    title,
    titleUrl,
    createdAt,
    ...(isDescriptionVisible && {
      descriptionNode: {
        type: LINK_NODE,
        data: {
          url: getTitleUrl(staffer_id, '/federal/congressional_staffers'),
          label: staffer_name,
          isExternal: false,
          afterLabel,
        },
      },
    }),
    source: getSource({ documentType, title, titleUrl, partyShort, comeFrom }),
  };
}

function transformStafferJoin({ event_data, createdAt, feed, isNotification = false }) {
  const { staffer_name, staffer_title, office_name } = event_data || {};
  const isDescriptionVisible = displayDescription([staffer_name, staffer_title, office_name]);

  const baseStaffer = getBaseStaffer({
    event_data,
    createdAt,
    isDescriptionVisible,
    afterLabel: formatDescription(['has joined the', office_name, 'as a', staffer_title]),
    isNotification,
    feed,
  });

  if (isNotification) {
    return {
      ...baseStaffer,
      title: 'Staffer hired',
    };
  }

  return {
    ...baseStaffer,
    actionType: 'New staffer',
  };
}

function transformStafferLeave({ event_data, createdAt, feed, isNotification = false }) {
  const { staffer_name, office_name } = event_data || {};
  const isDescriptionVisible = displayDescription([staffer_name, office_name]);

  const baseStaffer = getBaseStaffer({
    event_data,
    createdAt,
    isDescriptionVisible,
    afterLabel: formatDescription(['has left the', office_name]),
    isNotification,
    feed,
  });

  if (isNotification) {
    return {
      ...baseStaffer,
      title: 'Staffer exit',
    };
  }

  return {
    ...baseStaffer,
    actionType: 'Staffer exit',
  };
}

function transformStafferTitleChange({ event_data, createdAt, feed, isNotification = false }) {
  const { staffer_name, staffer_title, staffer_previous_title, office_name } = event_data || {};

  const baseStaffer = getBaseStaffer({
    event_data,
    createdAt,
    isDescriptionVisible: displayDescription([
      staffer_name,
      staffer_title,
      staffer_previous_title,
      office_name,
    ]),
    afterLabel: formatDescription([
      'has transitioned from',
      staffer_previous_title,
      'to',
      staffer_title,
      'on',
      office_name,
    ]),
    feed,
    isNotification,
  });

  if (isNotification) {
    return {
      ...baseStaffer,
      title: 'Staffer update',
    };
  }

  return {
    ...baseStaffer,
    actionType: 'Staffer update',
  };
}

export const FEDERAL_RECENT_TYPE = {
  [responseEventTypes.FEDERAL_BILL_ACTION]: transformBillAction,
  [responseEventTypes.FEDERAL_BILL_COSPONSOR]: transformBillCosponsor,
  [responseEventTypes.FEDERAL_BILL_TEXT]: transformBillText,
  [responseEventTypes.FEDERAL_BILL_HEARING]: transformBillHearing,
  [responseEventTypes.FEDERAL_BILL_USER_POSITION]: transformBillPosition,
  [responseEventTypes.FEDERAL_COMMITTEE_HEARING]: transformCommitteeHearing,
  [responseEventTypes.FEDERAL_REGULATION_USER_POSITION]: transformRegulationPosition,
  [responseEventTypes.FEDERAL_REGULATION_PUBLIC_SUBMISSION]: transformPublicSubmission,
  [responseEventTypes.FEDERAL_STAFFER_JOIN]: transformStafferJoin,
  [responseEventTypes.FEDERAL_STAFFER_LEAVE]: transformStafferLeave,
  [responseEventTypes.FEDERAL_STAFFER_TITLE_CHANGE]: transformStafferTitleChange,
};
