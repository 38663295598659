import { omitBy, isEmpty } from 'lodash';

import { fetchTags, fetchIsShared } from 'components/core/DocumentContainer/api';
import { currentDate } from 'utils/date';
import { submitExportReport } from 'redux/exportReport/actions';
import { queryStringify } from 'utils/query-string';
import { downloadBlob, getBlob } from 'utils/fileExport';

import constants, { ADDED_TO_API_URL } from './constants';
import { transformDocumentForUI, transformRegulationForUI } from './utils';

export function fetchDocument(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DOCUMENT });

    const { id } = payload;
    const url = `/api_web/state/regulation_documents/${id}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_DOCUMENT_DONE,
        payload: transformDocumentForUI(response),
      }))
      .catch((error) => {
        window.Sentry && window.Sentry.captureMessage(error);

        return dispatch({
          type: constants.RECEIVE_DOCUMENT_DONE,
          payload: transformDocumentForUI({}),
        });
      });
  };
}

function getFirstDocumentFromResponse(dispatch) {
  return (response) => {
    const { payload } = response || {};
    const { documents } = payload || {};

    if (isEmpty(documents)) {
      return dispatch({ type: constants.RECEIVE_DOCUMENT_DONE, payload: {} });
    }

    return dispatch(fetchDocument({ id: documents[0].id }));
  };
}

export const onTagsSaved = ({ tags }) => (dispatch) => {
  dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
};

export const fetchRegulationTags = (apiUrl, documentId) => dispatch => (
  fetchTags(apiUrl, documentId)
    .then(tags => dispatch(onTagsSaved({ tags })))
);

export const onRegulationShared = sharings => (dispatch) => {
  dispatch({
    type: constants.RECEIVE_IS_SHARED_DONE,
    payload: sharings,
  });
};

export const fetchIsRegulationShared = (apiUrl, documentId) => dispatch => (
  fetchIsShared(apiUrl, documentId)
    .then(response => dispatch(onRegulationShared(response)))
);

export function fetchRegulationDetails(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_REGULATION_DETAILS });

    const { document_id: documentId, regulation_id, slug } = payload || {};
    const params = queryStringify(omitBy({
      regulation_id,
      slug,
    }, isEmpty));

    const url = `/api_web/state/regulation_documents/${documentId}/general_info?${params}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_REGULATION_DETAILS_DONE,
        payload: transformRegulationForUI(response),
      }))
      .then(getFirstDocumentFromResponse(dispatch))
      .then(() => Promise.all([
        dispatch(fetchRegulationTags(ADDED_TO_API_URL, documentId)),
        dispatch(fetchIsRegulationShared(ADDED_TO_API_URL, documentId)),
      ]))
      .catch((error) => {
        window.Sentry && window.Sentry.captureMessage(error);

        return dispatch({
          type: constants.RECEIVE_REGULATION_DETAILS_DONE,
          payload: transformRegulationForUI({}),
        });
      });
  };
}

export function onExportDocument(payload = {}) {
  function onSuccessCustom(responseData) {
    return downloadBlob(
      getBlob(responseData),
      `State regulation document ${currentDate()}.pdf`,
    );
  }

  const ajaxProps = {
    dataType: 'native',
    xhrFields: {
      responseType: 'blob',
    },
  };

  return submitExportReport({ ...payload, onSuccessCustom }, ajaxProps);
}
