const prefix = 'TRACKING';

const actionTypes = {
  FETCH_SUBSCRIPTIONS: `${prefix}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${prefix}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_SUBSCRIPTIONS_ERROR: `${prefix}/RECEIVE_SUBSCRIPTIONS_ERROR`,
  UPDATE_SUBSCRIPTION: `${prefix}/UPDATE_SUBSCRIPTION`,
};

export const TRACKABLE_SLUGS = [
  'federal_bill',
  'state_bill',
  'federal_register_regulation',
  'state_regulation',
];

export default actionTypes;
