import updateStakeholdersAppliedItems from '../utils/updateStakeholdersAppliedItems';
import constants from '../constants';

function getListsApplyingRequest({ ids, lists }) {
  const data = {
    list_params: {
      lists,
      stakeholder_ids: ids,
    },
  };

  const url = '/api_web/stakeholder-management/lists';
  const request = $.post(url, data);

  return request;
}

function getBulkListsApplyingRequest({ lists, lastSearchId }) {
  const data = {
    bulk_params: {
      lists,
      search_id: lastSearchId,
    },
  };

  const url = '/api_web/stakeholder-management/bulk_lists';
  const request = $.post(url, data);

  return request;
}

export function submitAppliedLists(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.SUBMIT_STAKEHOLDER_LISTS });

    const {
      ids = [],
      lastSearchId,
      lists = [],
      toggleOpen,
      onClear,
      notifications = {},
    } = payload;

    const isBulkAction = ids.length === 0;

    const request = isBulkAction ?
      getBulkListsApplyingRequest({ lastSearchId, lists }) :
      getListsApplyingRequest({ ids, lists });

    function onSuccess(response) {
      const { stakeholders } = getState().stakeholder.stakeholderLookup.stakeholderLookup;

      const allStakeholdersIds = stakeholders.map(({ id }) => id);
      const updatedStakeholdersIds = isBulkAction ? allStakeholdersIds : ids;

      const updatedStakeholders = updateStakeholdersAppliedItems({
        collection: 'lists',
        appliedItems: response,
        stakeholders,
        updatedStakeholdersIds,
      });

      dispatch({
        type: constants.SUBMIT_STAKEHOLDER_LISTS_DONE,
        payload: updatedStakeholders,
      });

      notifications.getSuccess();
      toggleOpen();
      onClear();
    }

    function onFailure(error) {
      dispatch({ type: constants.SUBMIT_STAKEHOLDER_LISTS_FAIL });
      notifications.getFailed();
      throw error;
    }

    return Promise.resolve(request)
      .then(onSuccess)
      .catch(onFailure);
  };
}
