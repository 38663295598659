import { transformLegislatorForUI } from './position';

export default function transformHistoricalPositionForUI(position = {}) {
  const {
    staffable_id,
    staffable_type,
    position_type,
    start_date,
    end_date,
    legislator,
    ...restProps
  } = position;

  return {
    ...restProps,
    staffableId: staffable_id,
    staffableType: staffable_type,
    positionType: position_type,
    startDate: start_date,
    endDate: end_date,
    legislator: transformLegislatorForUI(legislator),
  };
}
