const PREFIX = 'PUBLIC_RECORDS';

const constants = {
  FETCH_PUBLIC_RECORDS: `${PREFIX}/FETCH_PUBLIC_RECORDS`,
  RECEIVE_PUBLIC_RECORDS_DONE: `${PREFIX}/RECEIVE_PUBLIC_RECORDS_DONE`,
  SET_PUBLIC_RECORDS: `${PREFIX}/SET_PUBLIC_RECORDS`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
};

export const FEDERAL_LEGISLATOR_SLUG = 'federal_legislator';
export const STATE_LEGISLATOR_SLUG = 'state_legislator';
export const FEDERAL_COMMITTEE_SLUG = 'federal_committee';
export const FEDERAL_BILL_SLUG = 'federal_bill';
export const STATE_BILL_SLUG = 'state_bill';
export const STATE_EXECUTIVE_SLUG = 'state_executive';

export default constants;
