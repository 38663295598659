import { isNil, isEmpty, omitBy, isNumber, toString, map } from 'lodash';
import moment from 'moment';

import { getBillsCongressValue, getRelationshipStatus } from 'redux/bills/utils';

export function transformBillsForBE(payload = {}) {
  const { tags, congress, allCongressOptions, ...restProps } = payload;

  return {
    search_form: omitBy({
      ...getRelationshipStatus(restProps),
      match_congress: getBillsCongressValue({ allCongressOptions, congress }),
      tag_ids: map(tags, 'value'),
    }, isEmpty),
  };
}

export function transformBill({ bill = {}, cosponsorsCount = 10, ...restPayload }) {
  const {
    id,
    cosponsors = [],
    display_bill_id,
    display_title,
    overview,
    last_action = { text: '', date: '' },
    sponsor = {},
    status_full,
    introduced_at,
    score_rank,
  } = omitBy(bill, isNil);

  const { itemType, documentType } = restPayload;

  const slicedCosponsors = isNumber(cosponsorsCount) && cosponsorsCount > 0
    ? cosponsors.slice(0, cosponsorsCount)
    : cosponsors;

  return {
    id,
    billId: display_bill_id,
    billUrl: !isEmpty(id) && `/federal/bills/${id}`,
    snippet: overview,
    billTitle: display_title,
    lastAction: last_action && last_action.text,
    lastActionDate: last_action && last_action.date && moment(last_action.date),
    status: status_full,
    introducedAt: introduced_at,
    documentType,
    itemType,
    recordId: id,
    withTrack: true,
    similarityRank: toString(score_rank),
    sponsors: [{
      id: sponsor.id,
      name: sponsor.name,
      partyShort: sponsor.party_short,
      partySlug: sponsor.party_slug,
      url: `/federal/legislators/${sponsor.id}`,
    }],
    allCosponsorsSize: !isEmpty(cosponsors) ? cosponsors.length : 0,
    cosponsors: slicedCosponsors.map((cosponsor = {}) => {
      if (isEmpty(cosponsor) || isEmpty(cosponsor.name)) return null;

      return {
        id: cosponsor.id,
        name: cosponsor.name,
        partyShort: cosponsor.party_short,
        partySlug: cosponsor.party_slug,
        url: `/federal/legislators/${cosponsor.id}`,
      };
    }),
  };
}

export function transformBillsForUI({ billsData = {}, cosponsorsCount = 10, ...restProps }) {
  const { data } = billsData;

  if (isEmpty(data)) {
    return { allItems: [] };
  }

  return { allItems: data.map(item => transformBill({ bill: item, cosponsorsCount, ...restProps })) };
}
