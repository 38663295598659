import * as api from 'redux/dashboardV2/common/subscriptions/api';

import constants from './constants';

export function fetchDefaultSubscription(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DEFAULT_SUBSCRIPTION });

    function onSuccess(frequency) {
      dispatch({
        type: constants.LOAD_DEFAULT_SUBSCRIPTION_DONE,
        payload: frequency,
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.LOAD_DEFAULT_SUBSCRIPTION_FAILED });

      throw error;
    }

    return Promise.resolve(api.fetchDefaultFrequency(payload))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updateDefaultSubscription(payload = {}) {
  const { frequency } = payload || {};

  return (dispatch) => {
    dispatch({ type: constants.UPDATE_DEFAULT_SUBSCRIPTION });

    function onSuccess() {
      dispatch({
        type: constants.UPDATE_DEFAULT_SUBSCRIPTION_DONE,
        payload: frequency,
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.UPDATE_DEFAULT_SUBSCRIPTION_FAILED });

      throw error;
    }

    return Promise.resolve(api.updateDefaultFrequency(payload))
      .then(onSuccess)
      .catch(onFailure);
  };
}
