import constants from '../constants';

export const setSort = newSort => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;

    const sort = {
      ...state.sort,
      ...newSort,
    };

    dispatch({ type: constants.SET_SORT, payload: sort });
  }
);
