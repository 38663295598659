import { handleFailureNotification } from '../utils';
import { transformTimeSeries, transformTimeSeriesParams } from './utils';
import constants from './constants';

export function fetchTimeSeries(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_TIME_SERIES });

    const { url = '/api_web/news/time_series' } = payload;
    const ajax = $.get(url, transformTimeSeriesParams(payload));

    function onSuccess(response) {
      dispatch({
        type: constants.RECEIVE_TIME_SERIES_DONE,
        payload: transformTimeSeries(response.data),
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_TIME_SERIES_FAILURE });

      handleFailureNotification(error);

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function resetTimeSeries() {
  return dispatch => dispatch({ type: constants.RESET_TIME_SERIES });
}
