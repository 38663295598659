import Notifications from 'components/News/utils/Notifications';

import { transformTrendsParams, transformTrendsForUI } from './utils';
import constants from './constants';

export function fetchClusterTrends(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CLUSTER_INFO });

    const url = '/api_web/news/trends';
    const ajax = $.get(url, transformTrendsParams(payload));

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_CLUSTER_INFO_DONE,
        payload: transformTrendsForUI(response.data),
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_CLUSTER_INFO_FAILURE });

      Notifications.defaultFetchDataFailed();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}
