import isEmpty from 'lodash/isEmpty';

import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import constants from '../../constants';
import { transformRegulationForUI } from '../utils';
import { fetchSubscriptions } from '../subscriptions';
import { fetchPositions } from '../positions';
import { fetchTags } from '../tags';
import getRegulationsData from './getRegulationsData';

const fetchRegulations = (payload = {}) => (dispatch) => {
  dispatch({ type: constants.FETCH_REGULATIONS });

  const { searchData = {}, clearAll, toResetSelectedItems, documentType } = payload;
  const { isSearch, url, data, sortData } = getRegulationsData(payload);

  const ajax = $.post(url, data);

  function handleSuccess(response) {
    const currentPayload = (isEmpty(response) || isEmpty(response.data)) ? [] : response.data;
    const paginator = transformPaginator(response);
    const { current } = paginator;

    return dispatch({
      type: constants.RECEIVE_REGULATIONS,
      payload: transformRegulationForUI(currentPayload, current),
      paginator,
      toResetSelectedItems: isSearch || toResetSelectedItems,
      sort: transformSort(sortData),
    });
  }

  function saveAppliedFiltersToProps() {
    if (clearAll) {
      return dispatch(resetAppliedFilters());
    }

    return dispatch(handleAppliedFilters(searchData));
  }

  function onFailure(error) {
    throw error;
  }

  return Promise.resolve(ajax)
    .then(handleSuccess)
    .then(payloadData => dispatch(fetchTags(payloadData)))
    .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
    .then(payloadData => dispatch(fetchPositions(payloadData, documentType)))
    .then(payloadData => dispatch(fetchSubscriptions(payloadData)))
    .then(saveAppliedFiltersToProps)
    .catch(onFailure);
};

export default fetchRegulations;
