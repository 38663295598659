import { isEmpty, isNil, omitBy } from 'lodash';

import { transformOptionsForBE } from '../utils';

import { API_URL } from '../../constants';

function getCommitteesData(payload) {
  const { searchData = {}, appliedFilters, clearAll, sort, direction, page } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData };

  return {
    url: API_URL,
    data,
    sortData,
    isSearch,
  };
}

export default getCommitteesData;
