export function transformStakeholderToUI({
  first_name,
  last_name,
  middle_name,
  facebook_profile,
  twitter_username,
  party_code,
  custom_fields,
  addresses,
  primary_phone,
  smart_lists,
  ...restProps
}) {
  return {
    ...restProps,
    firstName: first_name,
    lastName: last_name,
    middleName: middle_name,
    facebookProfile: facebook_profile,
    twitterName: twitter_username,
    partyCode: party_code,
    customFields: custom_fields,
    phone: primary_phone,
    addresses: addresses && addresses.map(({
      without_complement,
      congressional_district,
      state_upper_district,
      state_lower_district,
      is_partial,
      ...restAddressProps
    }) => ({
      ...restAddressProps,
      address: without_complement,
      congressional: congressional_district,
      stateUpper: state_upper_district,
      stateLower: state_lower_district,
      isPartial: is_partial,
    })),
    smartLists: smart_lists && smart_lists.map(
      ({ id, title }) => ({
        id,
        name: title,
      }),
    ),
  };
}
