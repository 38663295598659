import { cloneDeep, some } from 'lodash';

function updateStakeholdersAppliedItems({ collection, appliedItems, stakeholders, updatedStakeholdersIds }) {
  const oldStakeholders = cloneDeep(stakeholders);

  const updatedStakeholders = oldStakeholders.map((iteratedStakeholder) => {
    const { id, [collection]: iteratedStakeholderCollection } = iteratedStakeholder;

    if (updatedStakeholdersIds.includes(id)) {
      appliedItems.forEach((appliedItem) => {
        const isItemNew = !some(iteratedStakeholderCollection, appliedItem);

        if (isItemNew) {
          iteratedStakeholder[collection].push(appliedItem);
        }
      });
    }

    return iteratedStakeholder;
  });

  return updatedStakeholders;
}

export default updateStakeholdersAppliedItems;
