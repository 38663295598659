import { combineReducers } from 'redux';

import { updateDataItemArrayPayload, updateDataItem, loadingUpdateItem } from 'utils/reducer';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import constants from './constants';

const defaultState = {
  data: [],
  paginator: {},
  ui: {
    isLoadingPosts: true,
    isLoadingTags: false,
    isLoadingMyFeedDocuments: false,
  },
};

const feedLookupKeyName = 'data';

function feed(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_POSTS:
      return {
        ...state,
        data: action.isInfinite ? state.data : [],
        ui: { ...state.ui, isLoadingPosts: true },
      };
    case constants.RECEIVE_POSTS_DONE:
      return {
        ...state,
        data: action.isInfinite ? [...state.data, ...action.payload] : action.payload,
        paginator: action.paginator,
        ui: { ...state.ui, isLoadingPosts: false },
      };
    case constants.RECEIVE_POSTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingPosts: false },
      };
    case constants.FETCH_TAGS:
    case constants.UPDATE_TAG_ITEM:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, feedLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.UPDATE_TAG_ITEM_DONE:
      return {
        ...updateDataItem(state, action, feedLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, feedLookupKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, feedLookupKeyName),
      };
    case constants.BULK_SHARE_TO_MY_FEED_DONE:
      return updateDataItemArrayPayload(state, action, feedLookupKeyName);
    default:
      return state;
  }
}

export default combineReducers({
  feed,
  appliedSearchFilters,
  advancedSearch,
});
