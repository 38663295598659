import moment from 'moment';
import { isEmpty, capitalize } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { GLOBAL_DATE_FORMAT, MONTH_SHORT_DAY_YEAR_FORMAT } from 'utils/constants/date';
import { customError } from 'utils/customError';
import { STATE_REGULATION_ITEM_TYPE } from 'utils/constants/itemTypes';

function formatDayYearDate(date, format = MONTH_SHORT_DAY_YEAR_FORMAT) {
  if (isEmpty(date)) {
    return '';
  }

  return moment(date).format(format);
}

function getAgenciesList(agencies) {
  if (isEmpty(agencies)) {
    return '';
  }

  return agencies.map(agency => capitalize(agency.title)).join(' - ');
}

export function transformRelevantLinks(relevantLinks) {
  if (isEmpty(relevantLinks)) {
    return [];
  }

  const {
    register_online_url: registerOnlineUrl,
    administrative_code_url: administrativeCodeUrl,
    manual_url: manualUrl,
  } = relevantLinks;

  return [
    { value: 'Online Register', href: registerOnlineUrl, isExternal: true },
    { value: 'Administrative code', href: administrativeCodeUrl, isExternal: true },
    { value: 'Administrative monthly', href: manualUrl, isExternal: true },
  ];
}

function getPeriodDate(commentsOpen, commentsClose, id) {
  if (isEmpty(commentsOpen) && isEmpty(commentsClose)) {
    return null;
  }

  // What we display when open exist and close null
  if (isEmpty(commentsClose)) {
    return null;
  }

  const currentDate = moment().format(GLOBAL_DATE_FORMAT);
  const daysRemaining = moment(commentsClose).diff(currentDate, 'days');
  const daysLabel = daysRemaining === 1 ? 'day' : 'days';

  const isCloseInFuture = daysRemaining > 0;
  const formatCloseDate = formatDayYearDate(commentsClose);

  if (isEmpty(commentsOpen)) {
    return {
      value: isCloseInFuture
        ? `Closes ${formatCloseDate} (Closes in ${daysRemaining} ${daysLabel})`
        : `Closed ${formatCloseDate}`,
    };
  }

  /** Comments Open in future */
  if (moment(commentsOpen).diff(commentsClose) > 0) {
    customError(`[State regulations ${id}] Comments open date is passed comments close date`);
    return null;
  }

  /** Is the same day */
  if (moment(commentsOpen).diff(commentsClose) === 0) {
    return { value: formatDayYearDate(commentsOpen) };
  }

  const betweenDates = `${formatDayYearDate(commentsOpen)} - ${formatDayYearDate(commentsClose)}`;
  const isOpenInPast = moment(currentDate).diff(commentsOpen) >= 0;

  if (isCloseInFuture && isOpenInPast) {
    return { value: betweenDates, closes: `(Closes in ${daysRemaining} ${daysLabel})` };
  }

  return { value: betweenDates };
}

function getRegisterLabel(proposedIssue, proposedVolume, date, label) {
  if (isEmpty(date)) {
    return null;
  }

  const formattedDate = formatDayYearDate(date);

  if ((isEmpty(proposedIssue) && isEmpty(proposedVolume)) || isEmpty(label)) {
    return { value: formattedDate };
  }

  return { date: formattedDate, value: label, isValueLabel: true };
}

export function transformProfileForUI(data = {}) {
  const {
    id,
    title,
    state,
    agencies,
    proposed_regulation,
    comments_open,
    comments_closed,
    last_action,
    code_citation,
    relevant_links,
    text_snippet,
  } = data;
  const { alpha2, name: stateFull } = state || {};
  const {
    published_date: lastActionDate,
    label: lastActionLabel,
    url: lastActionUrl,
    register_issue_number: lastIssue,
    register_volume_number: lastVolume,
  } = last_action || {};

  const {
    label: proposedValue,
    url: proposedUrl,
    published_date: proposedDate,
    register_issue_number: proposedIssue,
    register_volume_number: proposedVolume,
  } = proposed_regulation || {};
  const {
    administrative_code_url: administrativeCodeUrl,
  } = relevant_links || {};

  return {
    profile: {
      id,
      displayTitle: title,
      state: alpha2,
      stateFull,
      name: title,
      recordId: id,
      documentType: DOCUMENT_TYPES.STATE_REGULATION,
      itemType: STATE_REGULATION_ITEM_TYPE,
    },
    keyDetails: {
      agencies: getAgenciesList(agencies),
      stateFull,
      codeCitation: {
        value: code_citation,
        href: administrativeCodeUrl,
        isExternal: true,
      },
      latestRegulationText: {
        ...getRegisterLabel(proposedIssue, proposedVolume, proposedDate, proposedValue),
        href: proposedUrl,
        isExternal: true,
      },
      latestPublication: {
        ...getRegisterLabel(lastIssue, lastVolume, lastActionDate, lastActionLabel),
        href: lastActionUrl,
        isExternal: true,
      },
      commentsOpen: comments_open,
      commentsClosed: comments_closed,
      commentsPeriod: getPeriodDate(comments_open, comments_closed, id),
      officialSources: transformRelevantLinks(relevant_links),
      textSnippet: text_snippet,
    },
  };
}
