import { transformDataLookupRequest } from 'redux/moneyball/pacRankingPages/lookup/utils';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';

export function fetchLookupData({ transaction, filters, all }) {
  const endpoints = {
    disbursements: `${gon.moneyballApiV2}/api/v1/pac_ranking/lookup/disbursements`,
    receipts: `${gon.moneyballApiV2}/api/v1/pac_ranking/lookup/receipts`,
  };

  const ajax = $.ajax({
    type: 'POST',
    url: endpoints[transaction],
    contentType: REQUEST_CONTENT_TYPE,
    data: transformDataLookupRequest({ ...filters, all }),
  });

  return Promise.resolve(ajax);
}
