import { get, map } from 'lodash';

import {
  FEDERAL_EXECUTIVE_SLUG,
  LOCAL_EXECUTIVE_SLUG,
  STATE_EXECUTIVE_SLUG,
  STATE_STAFFER_EXECUTIVE_SLUG,
} from 'utils/constants/featureSlugs';

const localExecutiveTransformer = (advancedFilters) => {
  const {
    executives = [],
    branches = [],
    titles = [],
    parties = [],
    states = [],
    counties = [],
    cities = [],
    zip_codes = [],
    electionDates = [],
    tags = [],
  } = advancedFilters;

  return {
    user_ids: map(executives, 'value'),
    branches: map(branches, 'label'),
    titles: map(titles, 'label'),
    parties,
    states: map(states, 'value'),
    counties: map(counties, 'value'),
    cities: map(cities, 'value'),
    zip_codes: map(zip_codes, 'value'),
    election_dates: map(electionDates, 'value'),
    tag_ids: map(tags, 'value'),
  };
};

const federalExecutiveTransformer = (advancedFilters) => {
  const {
    executives = [],
    organizations = [],
    titles = [],
    parties = [],
    tags = [],
  } = advancedFilters;

  return {
    user_ids: map(executives, 'value'),
    organizations: map(organizations, 'label'),
    titles: map(titles, 'label'),
    parties,
    tag_ids: map(tags, 'value'),
  };
};

const stateExecutiveTransformer = (advancedFilters) => {
  const {
    executives = [],
    states = [],
    organizations = [],
    titles = [],
    parties = [],
    tags = [],
  } = advancedFilters;

  return {
    user_ids: map(executives, 'value'),
    states: map(states, 'value'),
    organizations: map(organizations, 'label'),
    titles: map(titles, 'label'),
    parties,
    tag_ids: map(tags, 'value'),
  };
};

const stateStafferExecutiveTransformer = (advancedFilters) => {
  const {
    staffers = [],
    executives = [],
    states = [],
    titles = [],
    parties = [],
    tags = [],
  } = advancedFilters;

  return {
    user_ids: map(staffers, 'value'),
    executive_ids: map(executives, 'value'),
    states: map(states, 'value'),
    titles: map(titles, 'label'),
    parties,
    tag_ids: map(tags, 'value'),
  };
};

const transformAdvancedFiltersForBE = advancedFilters => ({
  [FEDERAL_EXECUTIVE_SLUG]: federalExecutiveTransformer(get(advancedFilters, FEDERAL_EXECUTIVE_SLUG, {})),
  [LOCAL_EXECUTIVE_SLUG]: localExecutiveTransformer(get(advancedFilters, LOCAL_EXECUTIVE_SLUG, {})),
  [STATE_EXECUTIVE_SLUG]: stateExecutiveTransformer(get(advancedFilters, STATE_EXECUTIVE_SLUG, {})),
  [STATE_STAFFER_EXECUTIVE_SLUG]: stateStafferExecutiveTransformer(get(advancedFilters, STATE_STAFFER_EXECUTIVE_SLUG, {})),
});

export default transformAdvancedFiltersForBE;
