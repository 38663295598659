import { combineReducers } from 'redux';

import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';
import exportReport from 'redux/exportReport/reducers';
import { updateDataItemArrayPayload, updateDataItem } from 'utils/reducer';

import { loadingUpdateItem } from './utils/reducersUtils';
import constants from './constants';

export const defaultState = {
  defaultSubscription: NONE_ID,
  committees: [],
  paginator: {},
  sort: {},
  ui: {
    isLoadingData: true,
    isLoadingTags: true,
    isLoadingSubscriptions: false,
    isSavingSubscriptions: true,
    toResetSelectedItems: true,
    isInitialLoading: true,
    isLoadingMyFeedDocuments: true,
    isSharingItems: false,
  },
};

const committeeLookupKeyName = 'committees';

function committeeLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_COMMITTEES:
      return {
        ...state,
        sort: defaultState.sort,
        ui: {
          ...state.ui,
          isLoadingData: true,
          toResetSelectedItems: false,
        },
      };
    case constants.SET_COMMITTEES:
      return {
        ...state,
        committees: action.payload,
      };
    case constants.RECEIVE_COMMITTEES_DONE:
      return {
        ...state,
        committees: action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isLoadingData: false,
          toResetSelectedItems: action.toResetSelectedItems,
          isInitialLoading: false,
        },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, committeeLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.BULK_TRACK_DATA:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: true },
      };
    case constants.BULK_TRACK_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: false },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, committeeLookupKeyName),
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.BULK_UPDATE_DATA:
      return loadingUpdateItem(state, action);
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, committeeLookupKeyName),
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.BULK_SHARE_TO_MY_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: true },
      };
    case constants.BULK_SHARE_TO_MY_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, committeeLookupKeyName),
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.RESET_COMMITTEES:
      return defaultState;
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, committeeLookupKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    default:
      return state;
  }
}

export default combineReducers({
  appliedFilters: appliedSearchFilters,
  committeeLookup,
  advancedSearch,
  exportReport,
});
