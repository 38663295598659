import sortDirections from 'utils/constants/sortDirections';
import { ACTIVITY_PAGE_NAMESPACE } from 'components/Grm2/events/constants';

const initialState = {
  filters: {
    keywordLookup: '',
    people: [],
    creator: [],
    assignee: [],
    bills: [],
    regulations: [],
    committees: [],
    documents: [],
    activityTypes: [],
    priorityTypes: [],
    statusTypes: [],
    interactionTypes: [],
    tags: [],
    eventDate: {
      startDate: null,
      endDate: null,
    },
    dueDate: {
      startDate: null,
      endDate: null,
    },
    creationDate: {
      startDate: null,
      endDate: null,
    },
    attachments: false,
  },
  hasNewCard: false,
  hasEditedCard: false,
  timelineDefaultFilters: [],
  selectedCardIds: [],
  cards: [],
  cardsLinkPreview: {},
  customFields: [],
  pagination: {
    currentPage: undefined,
    totalPages: 1,
    totalCount: 0,
    perPage: 10,
  },
  sort: {
    direction: sortDirections.DESC,
    sort: 'created_at',
  },
  ui: {
    isLoading: true,
    isLoadingCustomFields: true,
    isLoadingMyFeedDocuments: true,
    isLoadingLinksPreview: true,
  },
  analytics: {
    ui: {
      showDashboard: false,
    },
  },
  isTasksContext: false,
  namespace: ACTIVITY_PAGE_NAMESPACE,
  withAdvancedSearch: false,
};

export default initialState;
