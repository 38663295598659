import { transformPost } from 'redux/socialMedia/feed/utils/transformers';
import { SOCIAL_MEDIA_POST_ITEM_TYPE } from 'utils/constants/itemTypes';

import { TWEET_NODE } from './descriptionNodeTypes';

export function transformTweet({ event_data, createdAt }) {
  const {
    user,
    document_url: titleUrl,
    user_full_name: userName,
  } = event_data || {};
  const { name } = user || {};

  return {
    itemType: SOCIAL_MEDIA_POST_ITEM_TYPE,
    withSharedBy: true,
    descriptionNode: {
      type: TWEET_NODE,
      data: transformPost(event_data),
    },
    title: name,
    titleUrl,
    createdAt,
    userName,
  };
}
