import constants from '../../constants';

export { default as resetRegulations } from './resetRegulations';
export { default as fetchRegulations } from './fetchRegulations';
export { default as getRegulationsData } from './getRegulationsData';

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
