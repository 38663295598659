import { isNull, omitBy, isEmpty } from 'lodash';

export function transformStaffableForUI(staffable = {}) {
  if (isEmpty(staffable)) {
    return null;
  }

  const {
    id,
    external_id,
    state_id,
    district_id,
    district_number,
    party_code,
    photo_url,
    role_code,
    full_name,
    first_name,
    last_name,
    middle_name,
    suffix,
    nickname,
    created_at,
    updated_at,
  } = staffable;

  return omitBy({
    id,
    externalId: external_id,
    stateId: state_id,
    districtId: district_id,
    districtNumber: district_number,
    partyCode: party_code,
    photoUrl: photo_url,
    roleCode: role_code,
    fullName: full_name,
    firstName: first_name,
    lastName: last_name,
    middleName: middle_name,
    suffix,
    nickname,
    createdAt: created_at,
    updatedAt: updated_at,
  }, isNull);
}

export function transformLegislatorForUI(legislator = {}) {
  if (isEmpty(legislator)) {
    return null;
  }
  const {
    id,
    display_name,
    universal_moniker,
    come_from,
    party,
    party_short,
    party_slug,
  } = legislator;

  return omitBy({
    id,
    displayName: display_name,
    universalMoniker: universal_moniker,
    comeFrom: come_from,
    party,
    partyShort: party_short,
    partySlug: party_slug,
  }, isNull);
}

export default function transformPositionForUI(position = {}) {
  const {
    id,
    staffer_id,
    staffable,
    office_type,
    employment_status,
    start_date,
    position_titles,
    legislator,
    committee,
    state,
  } = position;

  return omitBy({
    id,
    stafferId: staffer_id,
    staffable: transformStaffableForUI(staffable),
    positionTitles: position_titles,
    officeType: office_type,
    employmentStatus: employment_status,
    startDate: start_date,
    legislator: transformLegislatorForUI(legislator),
    committee,
    state,
  }, isNull);
}
