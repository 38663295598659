import { isEmpty, isNil, omitBy } from 'lodash';

import {
  transformPaginator,
  transformSort,
  transformTableColumns,
} from 'utils/transformers/paginator';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import getDistrictFilters from 'components/Stakeholder/common/utils/getDistrictFilters';

import { transformOptionsForBE, transformStakeholdersForUI } from '../utils/transformers';
import constants from '../constants';

export function getStakeholdersData(payload) {
  const {
    searchData = {},
    appliedFilters,
    clearAll,
    sort = 'id',
    direction = 'desc',
    page,
    perPage,
  } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const url = '/api_web/stakeholder-management/stakeholders/lookup';
  const sortData = omitBy({ sort, direction, page, per_page: perPage }, isNil);
  const data = { ...searchForm, ...sortData };

  return { isSearch, url, data, sortData };
}

export function fetchStakeholdersMapData(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAKEHOLDERS_MAP });

    const { searchData = {}, clearAll } = payload;
    const url = '/api_web/stakeholder-management/stakeholders/map';
    const { data } = getStakeholdersData(payload);

    const ajax = $.post(url, data);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_STAKEHOLDERS_MAP_DONE,
        payload: response,
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    function onFail(error) {
      dispatch({ type: constants.RECEIVE_STAKEHOLDERS_MAP_FAIL });
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(saveAppliedFiltersToProps)
      .catch(onFail);
  };
}

function fetchStakeholdersTableData(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, clearAll } = payload;
    dispatch({ type: constants.FETCH_STAKEHOLDERS });

    const { isSearch, url, data, sortData } = getStakeholdersData(payload);

    const ajax = $.post(url, data);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_STAKEHOLDERS_DONE,
        payload: transformStakeholdersForUI(response.data),
        paginator: transformPaginator(response),
        toResetSelectedItems: isSearch,
        tableColumns: transformTableColumns(response.table_columns),
        sort: transformSort(sortData),
        isResetFilters: clearAll,
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    function onFail(error) {
      dispatch({ type: constants.RECEIVE_STAKEHOLDERS_FAIL });
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(saveAppliedFiltersToProps)
      .catch(onFail);
  };
}

export function fetchStakeholders(payload = {}) {
  return (dispatch) => {
    const { isTablePageView = true } = payload;

    if (!isTablePageView) {
      return dispatch(fetchStakeholdersMapData(payload));
    }

    return dispatch(fetchStakeholdersTableData(payload));
  };
}

export function fetchDistrictStakeholdersMetadata({
  isFederalSenator,
  state,
  stateName,
  districtName,
  districtId,
  chamberCategory,
}) {
  return (dispatch) => {
    dispatch({
      type: constants.FETCH_DISTRICT_STAKEHOLDERS_METADATA,
    });

    const searchFilters = getDistrictFilters({
      isFederalSenator,
      state,
      stateName,
      districtName,
      districtId,
      chamberCategory,
    });

    const { url, data } = getStakeholdersData({ searchData: searchFilters, perPage: 1 });

    const request = $.post(url, data);

    const onFail = (error) => {
      dispatch({ type: constants.DISTRICT_STAKEHOLDERS_METADATA_FAIL });

      throw error;
    };

    const onSuccess = (response) => {
      const { totalCount, total: pages } = transformPaginator(response);

      dispatch({
        type: constants.RECEIVE_DISTRICT_STAKEHOLDERS_METADATA,
        payload: {
          totalCount,
          pages,
          state,
          districtId,
        },
      });
    };

    return Promise.resolve(request)
      .then(onSuccess)
      .catch(onFail);
  };
}

export function resetState() {
  return dispatch => dispatch({
    type: constants.RESET_STATE,
  });
}
