import isEmpty from 'lodash/isEmpty';

import { getValue } from 'utils/transformers/advancedSearch';
import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import { transformOptionsForBE, transformSearchesForBE, transformSearchesForUI } from '../utils';
import constants from '../constants';

/** Advanced search actions */
export function loadCommitteesRecentSearches(params, committeeData) {
  const recentSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return dispatch => dispatch(loadRecentSearches(
    recentSearch,
    data => transformSearchesForUI(data, committeeData)),
  );
}

export function loadCommitteesSavedSearches(params, committeeData) {
  const savedSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return loadSavedSearches(
    savedSearch,
    data => transformSearchesForUI(data, committeeData),
  );
}

export function saveAdvancedSearchItem(data = {}, committeeData, isSaved = true) {
  const advancedSearchData = {
    id: data.id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: transformSearchesForBE(data),
      saved: isSaved,
    },
  };

  if (!isSaved) {
    return onSaveRecentItem(
      advancedSearchData,
      params => loadCommitteesRecentSearches(params, committeeData),
    );
  }

  return onSaveItem(
    advancedSearchData,
    params => loadCommitteesRecentSearches(params, committeeData),
  );
}

export function removeAdvancedSearchItem(id, committeeData = {}) {
  return onRemoveItem(
    id,
    params => loadCommitteesSavedSearches(params, committeeData));
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, committeeData = {}, isClickedItem = false } = payload;

    if (!isClickedItem && !isEmpty(transformOptionsForBE(searchData))) {
      return dispatch(saveAdvancedSearchItem(searchData, committeeData, false));
    }

    return null;
  };
}
