import isEmpty from 'lodash/isEmpty';

import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { fetchReport, fetchSearch, fetchSearchReceipt } from 'redux/moneyball/utils';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { getValue } from 'utils/transformers/advancedSearch';
import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import { transformReceiptDisbursements } from '../../utils/transformers';
import constants from './constants';
import {
  transformDisbursements,
  transformOptionsForBE,
  transformSearchesForBE,
  transformSearchesForFE,
} from './utils';

export function initAppliedSearchFilters(emptyState, { searchType }) {
  return handleAppliedFilters({ ...emptyState, ...(searchType && { searchType }) });
}

export function loadDisbursementsSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadDisbursementsRecentSearches() {
  return (dispatch) => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveDisbursementsAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformSearchesForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadDisbursementsRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeDisbursementsAdvancedSearch(id) {
  return onRemoveItem(id, loadDisbursementsSavedSearches);
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map((item) => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/disbursements/export`;
  const data = JSON.stringify({
    disbursement_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'spender_name',
      'spender_url',
      'amount',
      'disbursement_date',
      'disbursement_description',
      'conduit_committee_name',
      'recipient_name',
      'recipient_url',
      'recipient_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'source_type',
      'filing_url',
      'spender_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function searchDisbursementsReceipt({ id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DISBURSEMENTS_RECEIPT });

    return dispatch(
      fetchSearchReceipt({
        url: `${gon.moneyballApiV2}/api/v1/disbursements/${id}`,
        transformResponse: transformReceiptDisbursements,
        actionType: constants.RECEIVE_DISBURSEMENTS_RECEIPT_DONE,
      }),
    );
  };
}

export function searchDisbursements(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DISBURSEMENTS });

    return dispatch(
      fetchSearch({
        payload,
        url: `${gon.moneyballApiV2}/api/v1/disbursements/lookup`,
        actionType: constants.RECEIVE_DISBURSEMENTS_DONE,
        transformResponsePayload: transformDisbursements,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformSearchesForBE,
        saveRecentAdvancedSearch: saveDisbursementsAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return (dispatch) => dispatch(resetAppliedFilters());
}
