import get from 'lodash/get';

import { ITEM_TYPES } from 'redux/dashboardV2/common/subscriptions/utils/constants';
import { BASE_FEEDS_URL, KEYWORD_TYPE } from 'redux/dashboardV2/constants';

const getBaseUrl = feedToken => `${BASE_FEEDS_URL}/${feedToken}`;

const getTrackedItemUrl = (feedToken, subscription) => {
  const { type, documentType, subscribableType } = subscription;
  const itemType = type || subscribableType;

  const trackedItemType = ITEM_TYPES[itemType] || ITEM_TYPES[documentType];
  const baseUrl = getBaseUrl(feedToken);

  return `${baseUrl}/subscriptions/trackings/${trackedItemType}`;
};

const getKeywordUrl = (feedToken) => {
  const baseUrl = getBaseUrl(feedToken);

  return `${baseUrl}/keywords`;
};

export const getSubscriptionUrl = (feedToken, subscription = {}) => {
  const type = subscription.type || subscription.subscribableType;
  const isKeyword = type === KEYWORD_TYPE;

  const urlGetter = isKeyword ? getKeywordUrl : getTrackedItemUrl;

  return urlGetter(feedToken, subscription);
};

export const getSubscriptionUrlWithId = (feedToken, subscription = {}) => {
  const { modelId, token, subscribableData } = subscription;
  const subscribableId = modelId || get(subscribableData, 'model_id');
  const trackedItemId = subscribableId || token;

  const baseUrl = getSubscriptionUrl(feedToken, subscription);

  return `${baseUrl}/${trackedItemId}`;
};
