import { isEmpty, merge } from 'lodash';

import { getValue, transformReceiptContributions } from 'redux/moneyball/utils/transformers';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { currentDate } from 'utils/date';
import { DOCX_ID } from 'utils/constants/export';
import {
  slugify,
  fetchReport,
  fetchSearch,
  fetchSearchReceipt,
  fetchSearchKeyDetails,
  onSelectElectionCycle,
  onSetElectionCycles,
} from 'redux/moneyball/utils';
import {
  loadSavedSearches,
  loadRecentSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformIndividual,
  transformContributions,
  transformSearchesForFE,
  transformOptionsForBE,
  transformOptionsSearchForBE,
} from './utils';
import constants from './constants';

export function setElectionCycles(cycles) {
  return onSetElectionCycles(cycles, constants.SET_ELECTION_CYCLES);
}

export function onSelectCycle(cycle) {
  return onSelectElectionCycle(cycle, constants.SELECT_ELECTION_CYCLE);
}

export function loadIndividualSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadIndividualRecentSearches() {
  return dispatch => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveIndividualAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformOptionsSearchForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadIndividualRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeIndividualAdvancedSearch({ id }) {
  return onRemoveItem(id, loadIndividualSavedSearches);
}

export function submitExportOnePage({ id, combineIds, exportNotifications }) {
  const url = `${gon.moneyballApiV2}/api/v1/super_individuals/one_page_report.${DOCX_ID}`;
  const data = JSON.stringify({
    super_individual_ids: id === 'combine' ? combineIds : [id],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_ONE_PAGE_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        dispatch({
          type: constants.SUBMIT_EXPORT_ONE_PAGE_REPORT_DONE,
        });
      });
  };
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map(item => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/receipts/export`;
  const data = JSON.stringify({
    receipt_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'contributor_name',
      'contributor_url',
      'amount',
      'receipt_date',
      'election_type',
      'conduit',
      'recipient_name',
      'recipient_url',
      'recipient_type',
      'recipient_party',
      'source_type',
      'filing_url',
      'contributor_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function submitExportLookup({
  id,
  title,
  searchParams,
  exportOption,
  appliedFilters,
  exportNotifications,
}) {
  const { ids } = searchParams;
  const exportIds = id !== 'combine' ? [id] : ids;
  const exportLookupFilters = transformOptionsForBE(appliedFilters);
  const url = `${gon.moneyballApiV2}/api/v1/receipts/export`;
  const data = JSON.stringify({
    lookup: merge(
      exportLookupFilters,
      {
        contributor: {
          super_individuals: {
            ids: exportIds,
          },
        },
      },
    ),
    format_type: exportOption,
    dimensions: [
      'contributor_name',
      'contributor_url',
      'amount',
      'receipt_date',
      'election_type',
      'conduit',
      'recipient_name',
      'recipient_url',
      'recipient_type',
      'recipient_party',
      'source_type',
      'filing_url',
      'contributor_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({
      url,
      data,
      contentType: REQUEST_CONTENT_TYPE,
      customFileName: `${slugify([title, currentDate(), 'receipts'].join('-'))}.${exportOption}`,
    })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function fetchIndividual(id, params) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUAL });

    function getDefaultParams() {
      return {
        url: `${gon.moneyballApiV2}/api/v1/super_individuals/${id}`,
        data: params,
        type: 'GET',
      };
    }

    function getCombinedParams() {
      const { ids } = params;

      return {
        url: `${gon.moneyballApiV2}/api/v1/super_individuals/combine`,
        data: JSON.stringify({ super_individual_ids: ids }),
        type: 'POST',
      };
    }

    const { url, type, data } = id !== 'combine' ? getDefaultParams() : getCombinedParams();

    return dispatch(
      fetchSearchKeyDetails({
        url,
        type,
        payload: data,
        contentType: REQUEST_CONTENT_TYPE,
        actionType: constants.RECEIVE_INDIVIDUAL_DONE,
        transformResponse: transformIndividual,
      }),
    );
  };
}

export function searchIndividualContributionsReceipt({ id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUAL_RECEIPT });

    return dispatch(
      fetchSearchReceipt({
        url: `${gon.moneyballApiV2}/api/v1/receipts/${id}`,
        transformResponse: transformReceiptContributions,
        actionType: constants.RECEIVE_INDIVIDUAL_RECEIPT_DONE,
      }),
    );
  };
}

export function searchIndividualContributions(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUAL_CONTRIBUTIONS });

    const { id, ids, ...restPayload } = payload;
    const formattedPayload = {
      ...restPayload,
      contributor: {
        super_individuals: {
          ids: id === 'combine' ? ids : [id],
        },
      },
    };

    return dispatch(
      fetchSearch({
        payload: formattedPayload,
        url: `${gon.moneyballApiV2}/api/v1/receipts/lookup`,
        actionType: constants.RECEIVE_INDIVIDUAL_CONTRIBUTIONS_DONE,
        transformResponsePayload: transformContributions,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformOptionsSearchForBE,
        saveRecentAdvancedSearch: saveIndividualAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return dispatch => dispatch(resetAppliedFilters());
}
