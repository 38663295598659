import {
  getValue,
  getContributionDonorInfo,
  getContributionRecipientInfo,
  transformContributionSearchesForFront,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
  formatReceivedTypesForBE,
  formatTypesForSaveBE,
  sourceSelectValueForBE,
  formatContributorType,
  formatZipCode,
} from 'redux/moneyball/utils/transformers';

export function transformCandidate(candidate = {}) {
  const { address_zip_code, candidates, ...restCandidate } = candidate || {};
  const formattedCandidates = candidates && {
    candidates: candidates.map(
      ({ committees, ...restCandidates }) => ({
        ...restCandidates,
        committees: committees && committees.map(
          ({ address_zip_code: committeeAddressZipCode, ...restCommittees }) => ({
            ...restCommittees,
            address_zip_code: formatZipCode(committeeAddressZipCode),
          })
        ),
      })
    ),
  };

  return {
    ...restCandidate,
    ...formattedCandidates,
    address_zip_code: formatZipCode(address_zip_code),
  };
}

export function transformContributions(contributions = []) {
  return contributions.map(({
    receipt,
    super_individual_donor: superIndDonor,
    super_committee_donor: superCommDonor,
    super_corporation_donor: superCorpDonor,
    super_committee_recipient,
    super_committee_conduit,
    ...restProps
  }) => {
    const donorInfo = getContributionDonorInfo({ superCommDonor, superIndDonor, superCorpDonor });
    const recipientInfo = getContributionRecipientInfo(super_committee_recipient);
    const { id: conduitId, name: conduitName } = super_committee_conduit || {};
    const { name, url } = donorInfo || {};
    const {
      is_pdf_available,
      filing_source_url,
      contribution_date,
      contribution_amount,
      source_type_full,
      election_type_full,
      donor_name,
      id,
    } = receipt || {};

    const donorNameProps = name ? { name, url } : { name: donor_name };

    return ({
      ...restProps,
      ...donorInfo,
      ...donorNameProps,
      ...recipientInfo,
      id,
      contribution_date,
      source_type_full,
      election_type_full,
      conduitName,
      amount: contribution_amount,
      conduit: {
        conduitId,
        conduitUrl: `/moneyball_v2/committees/${conduitId}`,
      },
      receipts: {
        id,
        is_pdf_available,
        filing_source_url,
      },
    });
  });
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const {
      query,
      contribution_date,
      contribution_amount,
      contributor_types,
      contributor,
      source_types,
      election_types,
    } = search_params || {};

    const { super_committees, super_individuals } = contributor || {};
    const { ids, types } = super_committees || {};
    const {
      names,
      employers,
      occupations,
      streets,
      cities,
      states,
      zip_codes,
    } = super_individuals || {};

    return {
      id,
      title: name || 'Applied filters:',
      ...transformContributionSearchesForFront({
        contribution_amount,
        contribution_date,
        contributor_types,
        contributor,
        ids,
        types,
        names,
        employers,
        occupations,
        streets,
        cities,
        states,
        zip_codes,
        query,
        source_types,
        election_types,
      }),
    };
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    dates,
    amounts,
    contributorType,
    contributorNonIndividual,
    contributorCommitteeTypes,
    contributorIndividuals,
    contributorEmployers,
    contributorOccupations,
    contributorStreet,
    contributorCity,
    contributorStates,
    contributorZip,
    electionTypes,
    sourceTypes,
  } = options || data;
  const { startDate, endDate } = dates || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('contribution_date', {
      ...getValue('start', startDate),
      ...getValue('end', endDate),
    }),
    ...getValue('contribution_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('contributor_types', formatContributorType(contributorType)),
    ...getValue('contributor', {
      ...getValue('super_committees', {
        ...getValue('ids', formatSelectValueToArrForBE(contributorNonIndividual)),
        ...getValue('types', contributorCommitteeTypes),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToArrForBE(contributorIndividuals)),
        ...getValue('employers', formatSelectValueToArrForBE(contributorEmployers)),
        ...getValue('occupations', formatSelectValueToArrForBE(contributorOccupations)),
        ...getValue('streets', formatSelectValueToArrForBE(contributorStreet)),
        ...getValue('cities', formatSelectValueToArrForBE(contributorCity)),
        ...getValue('states', formatSelectValueToArrForBE(contributorStates)),
        ...getValue('zip_codes', formatSelectValueToArrForBE(contributorZip)),
      }),
    }),
    ...getValue('source_types', formatReceivedTypesForBE(sourceTypes)),
    ...getValue('election_types', formatSelectValueToArrForBE(electionTypes)),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformOptionsSearchForBE(data = {}) {
  const { options } = data;
  const {
    contributorNonIndividual,
    contributorCommitteeTypes,
    contributorIndividuals,
    contributorEmployers,
    contributorOccupations,
    contributorStreet,
    contributorCity,
    contributorStates,
    contributorZip,
    electionTypes,
    sourceTypes,
  } = options || data;
  const baseOptions = transformOptionsForBE(data);

  return {
    ...baseOptions,
    ...getValue('contributor', {
      ...getValue('super_committees', {
        ...getValue('ids', sourceSelectValueForBE(contributorNonIndividual)),
        ...getValue('types', contributorCommitteeTypes),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToObjForBE(contributorIndividuals)),
        ...getValue('employers', formatSelectValueToObjForBE(contributorEmployers)),
        ...getValue('occupations', formatSelectValueToObjForBE(contributorOccupations)),
        ...getValue('streets', formatSelectValueToObjForBE(contributorStreet)),
        ...getValue('cities', formatSelectValueToObjForBE(contributorCity)),
        ...getValue('states', formatSelectValueToObjForBE(contributorStates)),
        ...getValue('zip_codes', formatSelectValueToObjForBE(contributorZip)),
      }),
    }),
    ...getValue('source_types', formatTypesForSaveBE(sourceTypes)),
    ...getValue('election_types', formatTypesForSaveBE(electionTypes)),
  };
}
