import { filter, groupBy, map, uniq, isEmpty } from 'lodash';

import positionsApi from 'redux/dashboardV2/common/positions/api';

import { transformPositionsResponseForUI, transformPosition } from '../utils/transformers/positionTransformers';
import constants from '../constants';

export function fetchPositionsByType({ recordIds, recordType }) {
  return (dispatch) => {
    const onSuccess = (response) => {
      dispatch({
        payload: transformPosition(response),
        type: constants.LOAD_POSITIONS_DONE,
      });
    };

    const onFailure = error => new Error(error);

    return positionsApi.fetchPositionsByType({ recordIds, recordType })
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchPositions({ data }) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_POSITIONS_INIT });

    const filteredPositions = filter(data, 'withPosition');
    const groupedItems = groupBy(filteredPositions, 'positionRecordType');
    const formattedValues = map(groupedItems, (value, key) => ({
      record_type: key,
      record_ids: uniq(map(value, 'recordId')),
    }));

    if (isEmpty(formattedValues)) {
      return dispatch({
        payload: [],
        type: constants.LOAD_POSITIONS_FINISHED,
      });
    }

    const onSuccess = (response) => {
      dispatch({
        payload: transformPositionsResponseForUI(response),
        type: constants.LOAD_POSITIONS_FINISHED,
      });
    };

    const onFailure = (error) => {
      dispatch({
        payload: [],
        type: constants.LOAD_POSITIONS_FINISHED,
      });

      return new Error(error);
    };

    return positionsApi.fetchAllPositions({ search_form: formattedValues })
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updatePositionOrTrack({ id, position, isTracking }) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_POSITION_TRACK, id, position, isTracking });
  };
}
