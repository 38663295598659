import {
  map,
  groupBy,
  reduce,
  filter,
  toString,
  difference,
  uniqWith,
  isEqual,
  isEmpty,
} from 'lodash';

import { postJson } from 'utils/api';

import { BASE_URL } from './constants';

export function transformNewTagsForBE({ tags }) {
  return {
    tags: map(tags, ({ label }) => ({ name: label })),
  };
}

export function transformNewTagsForUI({ data }) {
  return map(data, ({ name, id }) => ({ label: name, value: id }));
}

export function transformRecordsForTag(data) {
  const formattedRecords = map(data, ({ documentType, recordId }) => ({
    id: toString(recordId),
    type: documentType,
  }));

  return uniqWith(formattedRecords, isEqual);
}

export function transformBulkTagsRecordsForBE(payload) {
  /** Record format: { id, type } */
  const { records, tagIds } = payload || {};

  return {
    records,
    tag_ids: tagIds,
  };
}

export function transformTagsByRecordIdsForUI(payload) {
  const { data } = payload || {};

  return reduce(data, (result, item) => {
    const { record_tags, record_type } = item || {};

    const formatData = map(record_tags, (recordTag) => {
      const { record_id, tags } = recordTag || {};

      return {
        recordType: record_type,
        recordId: record_id,
        tags: map(tags, ({ id, name }) => ({ value: id, label: name })),
      };
    }) || [];

    result.push(...formatData);

    return result;
  }, []);
}

export function transformFetchRecordIdsForBE(payload) {
  const groupedValues = groupBy(payload, 'documentType');
  const formattedValues = map(groupedValues, (value, key) => ({
    record_type: key,
    record_ids: map(value, ({ recordId, id }) => toString(recordId || id)),
  }));

  return formattedValues;
}

export function getFilteredTags(tags) {
  const newTags = filter(tags, ['isNew', true]);

  return {
    newTags,
    existingTags: difference(tags, newTags),
  };
}

export function transformTagsForBE(existingTags, newTags) {
  return [
    ...map(newTags, ({ value }) => toString(value)),
    ...map(existingTags, ({ value }) => toString(value)),
  ];
}

export function createTagsNoDispatch(payload) {
  const data = transformNewTagsForBE(payload);
  const ajax = postJson(BASE_URL, data);

  return Promise.resolve(ajax)
    .then(response => transformNewTagsForUI(response));
}

export function saveTagsAndUpdateRecords({ newTags, saveTagsForRecord }) {
  if (isEmpty(newTags)) {
    return saveTagsForRecord();
  }

  return createTagsNoDispatch({ tags: newTags })
    .then(saveTagsForRecord);
}
