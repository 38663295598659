import map from 'lodash/map';

const addCurrentSuffix = versions => (
  map(versions, ({ label, ...rest }, index) => ({
    ...rest,
    label: index === versions.length - 1 ? `${label} (Current)` : label,
  }))
);

const transformVersions = versions => (
  map(versions, ({ id, version_name }) => ({
    value: id,
    label: version_name,
  }))
);

export function transformTextVersionsForUI(payload) {
  const { bill_versions = [], companion_versions = [] } = (payload && payload.data) || {};

  return {
    textVersions: [
      {
        label: 'Companion bills',
        options: transformVersions(companion_versions),
      },
      {
        label: 'Versions of this bill',
        options: addCurrentSuffix(transformVersions(bill_versions)),
      },
    ],
  };
}
