import { flatten, isEmpty } from 'lodash';

import { postJson, putJson } from 'utils/api';
import { BASE_URL, BASE_FEEDS_URL, KEYWORD_TYPE } from 'redux/dashboardV2/constants';
import { getMyFeedToken, isMyFeed } from 'components/Dashboard/utils';

import {
  transformNestedSubscriptionsForUI,
  transformSubscriptionForBE,
  transformSubscriptionsForUI,
  buildBulkUpdatePayloadForBE,
} from './transformers';
import notifications from './notifications';
import { formatTokens, getSubscriptionData } from './utils';
import { getSubscriptionUrl, getSubscriptionUrlWithId } from './utils/url';

const onFailure = (error) => {
  notifications.defaultError();
  throw error;
};

export const fetchKeywords = (feedToken) => {
  const url = `${BASE_FEEDS_URL}/${feedToken}/keywords/lookup`;

  const ajax = $.post(url);

  return Promise.resolve(ajax)
    .then(transformSubscriptionsForUI);
};

const fetchAllSubscriptions = (feedToken) => {
  const url = `${BASE_FEEDS_URL}/${feedToken}/trackings/all`;

  const ajax = $.post(url);

  return Promise.resolve(ajax)
    .then(transformNestedSubscriptionsForUI);
};

const fetchWorkspacesSubscriptions = (feedToken) => {
  if (!isMyFeed(feedToken)) return Promise.resolve([]);

  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/workspaces/lookup`;

  const ajax = $.post(url);

  return Promise.resolve(ajax)
    .then(transformSubscriptionsForUI);
};

export const fetchSubscriptionsAndKeywords = feedToken => (
  Promise.all([
    fetchKeywords(feedToken),
    fetchAllSubscriptions(feedToken),
    fetchWorkspacesSubscriptions(feedToken),
  ])
    .then(flatten)
    .catch(onFailure)
);

export const fetchSubscription = (feedToken, subscriptionData) => {
  const url = getSubscriptionUrlWithId(feedToken, subscriptionData);

  return Promise.resolve($.get(url));
};

export const deleteSubscription = (feedToken, subscription) => {
  const type = subscription.type || subscription.subscribableType;
  const isKeyword = type === KEYWORD_TYPE;
  const baseUrl = getSubscriptionUrlWithId(feedToken, subscription);

  const finalUrl = isKeyword ? `${baseUrl}/disable` : baseUrl;
  const reqMethod = isKeyword ? 'POST' : 'DELETE';

  const ajax = $.ajax({ url: finalUrl, type: reqMethod });

  return Promise.resolve(ajax);
};

export const updateSubscription = (feedToken, subscription) => {
  const url = getSubscriptionUrlWithId(feedToken, subscription);

  const ajax = putJson(url, transformSubscriptionForBE(subscription));

  return Promise.resolve(ajax);
};

const updateCommonSubscriptions = ({ feedToken, tokens, ...bulkActionData }) => {
  if (isEmpty(tokens)) { return Promise.resolve(); }

  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/bulk_update`;
  const requestData = buildBulkUpdatePayloadForBE({ tokens, ...bulkActionData });

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};

const updateWorkspaceSubscriptions = ({ feedToken, workspaceTokens, ...bulkActionData }) => {
  if (isEmpty(workspaceTokens)) { return Promise.resolve(); }

  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/workspaces/bulk_update`;
  const requestData = buildBulkUpdatePayloadForBE({ ...bulkActionData, tokens: workspaceTokens });

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};

export const updateSubscriptions = bulkActionData => (
  Promise.all([
    updateCommonSubscriptions(bulkActionData),
    updateWorkspaceSubscriptions(bulkActionData),
  ]).then(flatten)
);

const deleteWorkspaceSubscriptions = ({ workspaceTokens, feedToken }) => {
  if (isEmpty(workspaceTokens)) { return Promise.resolve(); }

  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/workspaces/bulk_disable`;
  const requestData = { tokens: formatTokens(workspaceTokens) };

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};

const deleteCommonSubscriptions = ({ feedToken, tokens }) => {
  if (isEmpty(tokens)) { return Promise.resolve(); }

  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/bulk_disable`;
  const requestData = { tokens: formatTokens(tokens) };

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};

export const deleteSubscriptions = ({ feedToken, tokens, workspaceTokens }) => (
  Promise.all([
    deleteCommonSubscriptions({ feedToken, tokens }),
    deleteWorkspaceSubscriptions({ workspaceTokens, feedToken }),
  ]).then(flatten)
);

export const fetchDefaultFrequency = () => {
  const url = `${BASE_URL}/my_feed/settings`;

  const ajax = $.get(url);

  return Promise.resolve(ajax)
    .then(({ frequency }) => frequency)
    .catch(onFailure);
};

export const updateDefaultFrequency = (frequency) => {
  const url = `${BASE_URL}/my_feed/settings`;
  const requestData = { frequency };

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};

export const resetSubscriptionsSettings = () => {
  const url = `${BASE_URL}/my_feed/subscriptions/reset_frequency`;

  const ajax = $.post(url);

  return Promise.resolve(ajax);
};

export const createSubscription = (feedToken, subscription = {}) => {
  const url = getSubscriptionUrl(feedToken, subscription);
  const data = getSubscriptionData(subscription);
  const ajax = postJson(url, data);

  return Promise.resolve(ajax);
};

export const bulkShareToMyFeed = (data) => {
  const feedToken = getMyFeedToken();
  const url = `${BASE_FEEDS_URL}/${feedToken}/shared_documents`;

  const ajax = postJson(url, data);

  return Promise.resolve(ajax);
};
