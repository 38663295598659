import { isNil, omitBy } from 'lodash';

import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { ROW_PLACEHOLDER as PAGE_SIZE } from 'components/Federal/CommitteeProfile/utils';

import constants from '../constants';
import {
  transformStaffersForUI,
} from '../utils';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchStaffers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFERS });

    const { sort, direction, page, id } = payload;
    const sortData = omitBy({ sort, direction, page }, isNil);

    const url = `/api_web/federal/committees/${id}/staffers`;
    const ajax = $.get(url, { ...payload, ...PER_PAGE });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_STAFFERS_DONE,
        payload: {
          ...transformStaffersForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(sortData),
        },
      }));
  };
}
