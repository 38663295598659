import { map, compact, isEmpty, sortBy } from 'lodash';

import { DOCUMENT_TYPES, responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getDisplayItem, mapBackendValues } from 'redux/advancedSearch/utils';
import {
  getLabelFromItem,
  getValue,
  transformSelectValues,
} from 'utils/transformers/advancedSearch';
import { FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE } from 'utils/constants/itemTypes';

function transformOptionsForUI(data = {}) {
  const { id, search_params } = data;
  const {
    full_text,
    legislators,
    include_historical,
    only_first_term,
    only_elected,
    show_only_tracked_items,
    caucuses,
    parties,
    committees,
    chambers,
    state_alpha2s,
    tag_ids,
  } = search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', full_text),
      ...getValue('legislator', legislators),
      ...getValue('historicalLegislator', Boolean(include_historical)),
      ...getValue('firstTermLegislator', Boolean(only_first_term)),
      ...getValue('electedLegislator', Boolean(only_elected)),
      ...getValue('trackedItems', Boolean(show_only_tracked_items)),
      ...getValue('committee', committees),
      ...getValue('caucus', caucuses),
      ...getValue('party', parties),
      ...getValue('chamber', chambers),
      ...getValue('stateDelegation', state_alpha2s),
      ...getValue('tags', tag_ids),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}, legislatorsProps = {}) {
  const { name, search_params } = data;
  const {
    full_text,
    include_historical,
    only_first_term,
    only_elected,
    show_only_tracked_items,
    legislators,
    caucuses,
    parties,
    committees,
    chambers,
    state_alpha2s,
    tag_ids,
  } = search_params || {};

  const filters = [
    getDisplayItem('Searched', full_text),
    getDisplayItem('Legislator', getLabelFromItem(legislators)),
    getDisplayItem('Include historical legislators', include_historical && 'Yes'),
    getDisplayItem('Show first-term legislators', only_first_term && 'Yes'),
    getDisplayItem('Show legislators-elect', only_elected && 'Yes'),
    getDisplayItem('Show only federal legislators I subscribe to', show_only_tracked_items && 'Yes'),
    getDisplayItem('Committee', getLabelFromItem(committees)),
    getDisplayItem('Caucus', getLabelFromItem(caucuses)),
    getDisplayItem('Party', mapBackendValues(parties, legislatorsProps.parties)),
    getDisplayItem('Chamber', mapBackendValues(chambers, legislatorsProps.chambers)),
    getDisplayItem('State', getLabelFromItem(state_alpha2s)),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    historicalLegislator,
    firstTermLegislator,
    electedLegislator,
    trackedItems,
    committee,
    caucus,
    party,
    chamber,
    stateDelegation,
    tags,
  } = options || {};

  return {
    ...getValue('full_text', searchValue),
    ...getValue('ids', transformSelectValues(legislator)),
    ...getValue('include_historical', historicalLegislator),
    ...getValue('only_first_term', firstTermLegislator),
    ...getValue('only_elected', electedLegislator),
    ...getValue('show_only_tracked_items', trackedItems),
    ...getValue('committee_ids', transformSelectValues(committee)),
    ...getValue('caucus_ids', transformSelectValues(caucus)),
    ...getValue('parties', party),
    ...getValue('chambers', chamber),
    ...getValue('state_alpha2s', transformSelectValues(stateDelegation)),
    ...getValue('tag_ids', transformSelectValues(tags)),
  };
}

export function transformSearchesForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    historicalLegislator,
    firstTermLegislator,
    electedLegislator,
    trackedItems,
    committee,
    caucus,
    party,
    chamber,
    stateDelegation,
    tags,
  } = options || {};

  return {
    ...getValue('full_text', searchValue),
    ...getValue('ids', transformSelectValues(legislator)),
    ...getValue('include_historical', historicalLegislator),
    ...getValue('only_first_term', firstTermLegislator),
    ...getValue('only_elected', electedLegislator),
    ...getValue('show_only_tracked_items', trackedItems),
    ...getValue('committee_ids', transformSelectValues(committee)),
    ...getValue('caucus_ids', transformSelectValues(caucus)),
    ...getValue('parties', party),
    ...getValue('chambers', chamber),
    ...getValue('state_alpha2s', transformSelectValues(stateDelegation)),
    ...getValue('tag_ids', tags),
  };
}

export function transformSearchesForUI(data, legislatorsProps) {
  return map(data, item => ({
    ...getDisplayDataForRecentSearches(item, legislatorsProps),
    ...transformOptionsForUI(item),
  }));
}

/** Subcommittee transformer utils */
function getTitle(data) {
  return data ? `(${data})` : '';
}

function getSubcommittee(subcommittee = {}) {
  const { name, title } = subcommittee;

  return name ? `${name} ${getTitle(title)}` : '';
}

function getCommittee(committeesData = []) {
  const sortedCommittees = sortBy(committeesData, 'name');

  return map(sortedCommittees, (committee = {}) => {
    const { name = '', title, subcommittees = [] } = committee;

    if (isEmpty(name)) {
      return null;
    }

    const sortedSubcommittees = sortBy(subcommittees, subcommittee => subcommittee.name);

    return {
      name: `${name} ${getTitle(title)}`,
      items: compact(map(sortedSubcommittees, getSubcommittee)),
    };
  });
}

export function transformLegislatorsForUI(legislators = []) {
  return map(legislators, ({
    id,
    full_name,
    chamber,
    district,
    party,
    party_short,
    party_slug,
    state,
    universal_moniker,
    committee_memberships,
    email,
  }) => ({
    id,
    name: universal_moniker || full_name,
    chamber,
    party,
    state,
    district,
    partyShort: party_short,
    partySlug: party_slug,
    committee: getCommittee(committee_memberships),
    email,
    recordId: id,
    documentType: DOCUMENT_TYPES.FEDERAL_LEGISLATOR,
    trackingType: responseTrackingTypes.FEDERAL_LEGISLATOR_STAFFER,
    itemType: FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    withTrack: true,
  }));
}

export function getExportInfo(data = {}) {
  const { searchValue } = data;
  const {
    legislator,
    committee,
    caucus,
    party,
    chamber,
    stateDelegation,
    historicalLegislator,
    firstTermLegislator,
    electedLegislator,
    tags,
    trackedItems,
  } = data.options || {};

  return {
    'strategy_inputs[full_text]': searchValue || undefined,
    ...(!isEmpty(legislator) && { 'strategy_inputs[ids]': transformSelectValues(legislator) }),
    'strategy_inputs[caucus_ids]': transformSelectValues(caucus),
    'strategy_inputs[parties]': party,
    'strategy_inputs[chambers]': chamber,
    'strategy_inputs[state_alpha2s]': transformSelectValues(stateDelegation),
    'strategy_inputs[committee_ids]': transformSelectValues(committee),
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    'strategy_inputs[show_only_tracked_items]': Boolean(trackedItems),
    ...(historicalLegislator && { 'strategy_inputs[include_historical]': historicalLegislator }),
    ...(firstTermLegislator && { 'strategy_inputs[only_first_term]': firstTermLegislator }),
    ...(electedLegislator && { 'strategy_inputs[only_elected]': electedLegislator }),
  };
}
