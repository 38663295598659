const PREFIX = 'MONEYBALL_INDIVIDUAL_LOOKUP';

const constants = {
  STRATEGY_SLUG: 'moneyball_individual_lookup',
  FETCH_INDIVIDUALS: `${PREFIX}/FETCH_INDIVIDUALS`,
  RECEIVE_INDIVIDUALS_DONE: `${PREFIX}/RECEIVE_INDIVIDUALS_DONE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
  CHECK_CLUSTERBUCK_INIT: `${PREFIX}/CHECK_CLUSTERBUCK_INIT`,
  CHECK_CLUSTERBUCK_DONE: `${PREFIX}/CHECK_CLUSTERBUCK_DONE`,
  SUBMIT_TO_CLUSTERBUCK_INIT: `${PREFIX}/SUBMIT_TO_CLUSTERBUCK_INIT`,
  SUBMIT_TO_CLUSTERBUCK_DONE: `${PREFIX}/SUBMIT_TO_CLUSTERBUCK_DONE`,
};

export default constants;
