import moment from 'moment';

import { MONTH_DAY_YEAR_DATE_FORMAT } from 'utils/constants/date';

export function transformCompanyInfoForBE(companyInfo) {
  const {
    name,
    file,
    biography,
    companyScorecardsPublic,
    forceLogoDelete,
    quickLinks = [],
  } = companyInfo;

  const data = new FormData();
  data.append('company[name]', name);
  data.append('company[biography]', biography);
  data.append('company[company_scorecards_public]', companyScorecardsPublic);

  quickLinks.forEach(({ label, url }) => {
    data.append('company[quick_links][][label]', label);
    data.append('company[quick_links][][url]', url);
  });

  file && data.append('company[file]', file);
  forceLogoDelete && data.append('force_logo_delete', forceLogoDelete);

  return data;
}

export function transformCompanyInfoForUI(companyInfo) {
  const {
    plan_type,
    total_users,
    api_token,
    api_docs_url,
    plan_expires,
    biography,
    company_scorecards_public,
    logo,
    datasets_count,
    quick_links,
    ...restCompanyInfo
  } = companyInfo;
  const { file_url } = logo || {};

  return {
    planType: plan_type,
    totalUsers: total_users,
    apiToken: api_token,
    apiDocsUrl: api_docs_url,
    planExpires: moment(plan_expires).format(MONTH_DAY_YEAR_DATE_FORMAT),
    companyScorecardsPublic: company_scorecards_public,
    logo: file_url,
    biography,
    datasetsCount: datasets_count,
    quickLinks: quick_links,
    ...restCompanyInfo,
  };
}

export function receiveSavedItem({ companyInfo, savedItem, itemType }) {
  const items = companyInfo[itemType] || [];
  const { id: savedItemId } = savedItem;
  const itemsDiff = items.filter(({ id }) => id !== savedItemId);

  if (itemsDiff.length !== items.length) {
    return {
      ...companyInfo,
      [itemType]: [savedItem].concat(itemsDiff),
    };
  }

  return {
    ...companyInfo,
    [itemType]: [savedItem].concat(items),
  };
}
