import { map, compact, isEmpty, filter } from 'lodash';

import {
  getItemValue,
  getLabelFromItem,
  getMapDisplayItem,
  getMapSelectValue, getRangeLabel,
  getValue,
  transformSelectValues,
  transformArraySelectValues,
} from 'utils/transformers/advancedSearch';
import {
  GUAM,
  VIRGIN_ISLANDS,
  AMERICAN_SAMOA,
  PUERTO_RICO,
  NORTHERN_MARIANA_ISLANDS,
} from 'utils/constants/states';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import { valueOrNull } from 'utils/forms/valueOrEmpty';

import { transformBill } from './transformers';

function transformOptionsForUI(data = {}, dataProps = {}) {
  const { id, search_params } = data;
  const {
    main_search_keywords,
    states,
    primary_sponsor_ids,
    cosponsor_ids,
    committee_ids,
    status_types,
    chamber,
    different_text,
    type,
    introduced_from,
    last_action_from,
    last_action_to,
    tag_ids,
    user_positions,
    show_only_tracked_items,
  } = search_params || {};
  const { legislationTypes, chambers } = dataProps;
  const since = introduced_from ? { value: introduced_from, label: introduced_from } : null;
  const lastActionDate = {
    start: valueOrNull(last_action_from),
    end: valueOrNull(last_action_to),
  };

  return {
    id,
    options: {
      ...getValue('searchValue', main_search_keywords),
      ...getValue('states', states),
      ...getValue('billStatuses', status_types),
      ...getValue('since', since),
      ...getValue('sponsors', primary_sponsor_ids),
      ...getValue('cosponsors', cosponsor_ids),
      ...getValue('committees', committee_ids),
      ...getValue('legislationType', getMapSelectValue(legislationTypes, type)),
      ...getValue('chamber', getMapSelectValue(chambers, chamber)),
      ...getValue('textExcludes', different_text),
      ...getValue('lastActionDate', lastActionDate),
      ...getValue('tags', tag_ids),
      ...getValue('position', user_positions),
      ...getValue('showOnlyTrackedItems', Boolean(show_only_tracked_items)),
    },
  };
}

export function responseStatesTransformers({ response }) {
  const { data: stateData } = response || {};
  const statesToExclude = [
    GUAM,
    VIRGIN_ISLANDS,
    AMERICAN_SAMOA,
    PUERTO_RICO,
    NORTHERN_MARIANA_ISLANDS,
  ];

  return filter(stateData, ({ value }) => !statesToExclude.includes(value));
}

function getDisplayDataForRecentSearches(data = {}, dataProps = {}) {
  const { name, search_params } = data;

  const { legislationTypes, chambers } = dataProps;

  const {
    main_search_keywords,
    states,
    primary_sponsor_ids,
    cosponsor_ids,
    committee_ids,
    status_types,
    chamber,
    different_text,
    type,
    introduced_from,
    last_action_from,
    last_action_to,
    tag_ids,
    user_positions,
    show_only_tracked_items,
  } = search_params;

  const filters = [
    getDisplayItem('Searched', main_search_keywords),
    getDisplayItem('States', getLabelFromItem(responseStatesTransformers({ response: { data: states } }))),
    getDisplayItem('Text Excludes', different_text),
    getDisplayItem('Sponsor', getLabelFromItem(primary_sponsor_ids)),
    getDisplayItem('Cosponsor', getLabelFromItem(cosponsor_ids)),
    getDisplayItem('Committee', getLabelFromItem(committee_ids)),
    getDisplayItem('Since', introduced_from),
    getDisplayItem('Bill Status', getLabelFromItem(status_types)),
    getDisplayItem('Bill Legislation Type', getMapDisplayItem(legislationTypes, type)),
    getDisplayItem('Bill Chamber', getMapDisplayItem(chambers, chamber)),
    getDisplayItem('Last Action Range', getRangeLabel(last_action_from, last_action_to)),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
    getDisplayItem('Position', getLabelFromItem(user_positions)),
    getDisplayItem('Show only state bills I subscribe to', show_only_tracked_items && 'Yes'),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    states,
    sponsors,
    cosponsors,
    committees,
    since,
    billStatuses,
    textExcludes,
    chamber,
    legislationType,
    lastActionDate,
    tags,
    position,
    showOnlyTrackedItems,
  } = options || {};

  return {
    ...getValue('main_search_keywords', searchValue),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('primary_sponsor_ids', transformSelectValues(sponsors && sponsors.filter(sponsor => sponsor.type === 'state_legislator'))),
    ...getValue('committee_sponsor_ids', transformSelectValues(sponsors && sponsors.filter(sponsor => sponsor.type === 'state_committee'))),
    ...getValue('cosponsor_ids', transformSelectValues(cosponsors)),
    ...getValue('committee_ids', transformArraySelectValues(committees)),
    ...getValue('different_text', textExcludes),
    ...getValue('introduced_from', getItemValue(since)),
    ...getValue('status_types', transformSelectValues(billStatuses)),
    ...getValue('chamber', getItemValue(chamber)),
    ...getValue('type', getItemValue(legislationType)),
    ...getValue('last_action_from', lastActionDate && lastActionDate.start),
    ...getValue('last_action_to', lastActionDate && lastActionDate.end),
    ...getValue('tag_ids', transformSelectValues(tags)),
    ...getValue('user_positions', transformSelectValues(position)),
    ...getValue('show_only_tracked_items', Boolean(showOnlyTrackedItems)),
  };
}

export function transformSearchesForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    states,
    sponsors,
    cosponsors,
    committees,
    since,
    billStatuses,
    textExcludes,
    chamber,
    legislationType,
    lastActionDate,
    tags,
    position,
    showOnlyTrackedItems,
  } = options || {};

  return {
    ...getValue('main_search_keywords', searchValue),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('primary_sponsor_ids', transformSelectValues(sponsors && sponsors.filter(sponsor => sponsor.type === 'state_legislator'))),
    ...getValue('committee_sponsor_ids', transformSelectValues(sponsors && sponsors.filter(sponsor => sponsor.type === 'state_committee'))),
    ...getValue('cosponsor_ids', transformSelectValues(cosponsors)),
    ...getValue('committee_ids', transformArraySelectValues(committees)),
    ...getValue('different_text', textExcludes),
    ...getValue('introduced_from', getItemValue(since)),
    ...getValue('status_types', transformSelectValues(billStatuses)),
    ...getValue('chamber', getItemValue(chamber)),
    ...getValue('type', getItemValue(legislationType)),
    ...getValue('last_action_from', lastActionDate && lastActionDate.start),
    ...getValue('last_action_to', lastActionDate && lastActionDate.end),
    ...getValue('tag_ids', tags),
    ...getValue('user_positions', position),
    ...getValue('show_only_tracked_items', Boolean(showOnlyTrackedItems)),
  };
}

export function transformBillsForUI(bills = [], page = '1') {
  return map(bills, (bill, index) => transformBill(bill, index, page));
}

export function transformSearchesForUI(data = [], dataProps = {}) {
  return map(data, item => ({
    ...getDisplayDataForRecentSearches(item, dataProps),
    ...transformOptionsForUI(item, dataProps),
  }));
}

export function getExportInfo(data = {}) {
  const { searchValue } = data || {};
  const {
    states,
    sponsors,
    cosponsors,
    committees,
    since,
    billStatuses,
    textExcludes,
    chamber,
    legislationType,
    lastActionDate,
    tags,
    showOnlyTrackedItems,
  } = data.options || {};

  return {
    'strategy_inputs[main_search_keywords]': searchValue || undefined,
    'strategy_inputs[states]': transformSelectValues(states),
    'strategy_inputs[primary_sponsor_ids]': transformSelectValues(sponsors),
    'strategy_inputs[cosponsor_ids]': transformSelectValues(cosponsors),
    'strategy_inputs[committee_ids]': transformSelectValues(committees),
    'strategy_inputs[different_text]': textExcludes,
    'strategy_inputs[introduced_from]': getItemValue(since),
    'strategy_inputs[status_types]': transformSelectValues(billStatuses),
    'strategy_inputs[chamber]': getItemValue(chamber),
    'strategy_inputs[type]': getItemValue(legislationType),
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    'strategy_inputs[last_action_from]': lastActionDate && lastActionDate.start,
    'strategy_inputs[last_action_to]': lastActionDate && lastActionDate.end,
    'strategy_inputs[show_only_tracked_items]': Boolean(showOnlyTrackedItems),
  };
}

function transformBillData(data) {
  if (isEmpty(data)) {
    return [];
  }

  return data;
}

export function transformGraphForUI(graphData = {}) {
  const { data } = graphData || {};
  const { introduced_over_time, top_cosponsors, top_sponsors, top_states } = data || {};

  return {
    introducedOverTime: transformBillData(introduced_over_time),
    topCosponsors: transformBillData(top_cosponsors),
    topSponsors: transformBillData(top_sponsors),
    topStates: transformBillData(top_states),
  };
}
