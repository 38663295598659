import { error } from 'components/core/Notification';

import constants from '../constants';
import { fetchSummary } from './summary';
import { fetchDocumentTags } from './tags';
import { fetchIsDocumentShared } from './sharedDocument';

const onSuccess = dispatch => () => {
  dispatch({ type: constants.RECEIVE_DETAILS_DONE });
};

const onError = dispatch => () => {
  error('Could not load document summary. Please try again.');
  dispatch({ type: constants.RECEIVE_DETAILS_ERROR });
};

export function fetchDetails(documentType, documentId) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DETAILS });

    const { apiUrl, tagsApiUrl, myFeedApiUrl } = documentType;

    return Promise.all([
      dispatch(fetchSummary(apiUrl, documentId)),
      dispatch(fetchDocumentTags(tagsApiUrl, documentId)),
      dispatch(fetchIsDocumentShared(myFeedApiUrl, documentId)),
    ])
      .then(onSuccess(dispatch))
      .catch(onError(dispatch));
  };
}
