import { compact, isEmpty } from 'lodash';

import { getDisplayItem } from 'redux/advancedSearch/utils';
import {
  getValue,
  transformSelectValues,
  formatSelectValue,
  getLabelFromItem,
} from 'utils/transformers/advancedSearch';
import { transformAgencyStaffer } from './transformers';

function transformOptionsForUI(data = {}) {
  const { id } = data;
  const {
    keyword,
    ids,
    titles,
    department_names,
    parent_organization_names,
    organization_names,
  } = data.search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', keyword),
      ...getValue('specificContact', (ids)),
      ...getValue('title', formatSelectValue(titles)),
      ...getValue('departmentName', formatSelectValue(department_names)),
      ...getValue('parentOrganizationName', formatSelectValue(parent_organization_names)),
      ...getValue('organizationName', formatSelectValue(organization_names)),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}) {
  const { name } = data;
  const {
    keyword,
    ids,
    titles,
    department_names,
    parent_organization_names,
    organization_names,
  } = data.search_params;

  const filters = [
    getDisplayItem('Searched', keyword),
    getDisplayItem('Specific Contact', getLabelFromItem(ids)),
    getDisplayItem('Title', titles),
    getDisplayItem('Department Name', department_names),
    getDisplayItem('Parent Organization Name', parent_organization_names),
    getDisplayItem('Organization Name', organization_names),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue } = data;
  const {
    specificContact,
    title,
    departmentName,
    parentOrganizationName,
    organizationName,
  } = data.options || {};

  return {
    ...getValue('keyword', searchValue),
    ...getValue('ids', transformSelectValues(specificContact)),
    ...getValue('titles', transformSelectValues(title)),
    ...getValue('department_names', transformSelectValues(departmentName)),
    ...getValue('parent_organization_names', transformSelectValues(parentOrganizationName)),
    ...getValue('organization_names', transformSelectValues(organizationName)),
  };
}

export function transformOptionsForSave(data = {}) {
  const { searchValue } = data;
  const {
    specificContact,
    title,
    departmentName,
    parentOrganizationName,
    organizationName,
  } = data.options || {};

  return {
    ...getValue('keyword', searchValue),
    ...getValue('ids', specificContact),
    ...getValue('titles', transformSelectValues(title)),
    ...getValue('department_names', transformSelectValues(departmentName)),
    ...getValue('parent_organization_names', transformSelectValues(parentOrganizationName)),
    ...getValue('organization_names', transformSelectValues(organizationName)),
  };
}

export function transformSearchesForUI(data) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item),
    ...transformOptionsForUI(item),
  }));
}

export function transformAgencyStaffersForUI(agencyStaffers = [], page = '1') {
  return agencyStaffers.map((agencyStaffer, index) => transformAgencyStaffer(agencyStaffer, index, page));
}

export function getExportInfo(data = {}) {
  const { searchValue } = data || {};
  const {
    specificContact,
    title,
    departmentName,
    parentOrganizationName,
    organizationName,
  } = data.options || {};

  return {
    'strategy_inputs[keyword]': searchValue || undefined,
    ...(!isEmpty(specificContact) && { 'strategy_inputs[ids]': transformSelectValues(specificContact) }),
    'strategy_inputs[titles]': transformSelectValues(title),
    'strategy_inputs[department_names]': transformSelectValues(departmentName),
    'strategy_inputs[parent_organization_names]': transformSelectValues(parentOrganizationName),
    'strategy_inputs[organization_names]': transformSelectValues(organizationName),
  };
}

export function getExportSortInfo(data = {}) {
  const { direction, sort } = data;

  return {
    'strategy_inputs[order_column]': sort,
    'strategy_inputs[order_mode]': direction,
  };
}
