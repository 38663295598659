import { isEmpty, pickBy, values } from 'lodash';

export function formatSmartSearchObjectForBE(object) {
  if (isEmpty(object)) return null;

  const { gt, lt, lat, lon, value, radius } = object;
  const receiptAmount = pickBy({ gt, lt });
  const zipWithinRange = pickBy({
    zip_code: value,
    radius,
    lon,
    lat,
  });

  return Object.keys(receiptAmount).length === 1 && values(receiptAmount).toString()
    || Object.keys(receiptAmount).length > 1 && receiptAmount
    || Object.keys(zipWithinRange).length && zipWithinRange;
}
