import { mapValues, isObjectLike, isArray, uniq } from 'lodash';

function overrideProps(state, propsValues) {
  return {
    ...state,
    ...propsValues,
  };
}

function appendValuesToArrayProps(state, propsValues, { removeDuplicates = false } = {}) {
  return {
    ...state,
    ...mapValues(propsValues, (value, key) => {
      const newValue = [
        ...state[key],
        ...value,
      ];
      return removeDuplicates ? uniq(newValue) : newValue;
    }),
  };
}

function updateObjectProps(state, propsValues) {
  return {
    ...state,
    ...mapValues(propsValues, (value, key) => (
      isObjectLike(value) && !isArray(value)
        ? updateObjectProps(state[key], value)
        : value
    )),
  };
}

export function stateHandler(state) {
  return {
    overrideProps: propsValues => overrideProps(state, propsValues),
    appendValuesToArrayProps: (propsValues, options) => appendValuesToArrayProps(state, propsValues, options),
    updateObjectProps: (propsValues, options) => updateObjectProps(state, propsValues, options),
  };
}
