import { combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import constants from './constants';

export const defaultState = {
  error: false,
  vendor: {},
  vendorReceipt: {},
  vendorReceiptError: false,
  vendorDisbursements: [],
  vendorDisbursementsError: false,
  electionCyclesData: [],
  electionCycle: null,
  paginator: {},
  sort: {},
  ui: {
    isInitialLoading: true,
    isVendorLoading: true,
    isVendorDisbursementsLoading: true,
    isVendorReceiptLoading: true,
    toResetSelectedItems: true,
  },
};

function profile(state = defaultState, action) {
  const initialLoadingStatus = !!(
    state.ui.isInitialLoading &&
    state.vendorDisbursements.length &&
    !isEmpty(state.vendor)
  );

  switch (action.type) {
    case constants.FETCH_VENDOR:
      return {
        ...state,
        error: false,
        vendor: {},
        ui: {
          ...state.ui,
          isVendorLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_VENDOR_DONE:
      return {
        ...state,
        error: action.error,
        vendor: action.payload,
        ui: {
          ...state.ui,
          isVendorLoading: false,
          toResetSelectedItems: true,
          isInitialLoading: initialLoadingStatus,
        },
      };
    case constants.FETCH_VENDOR_DISBURSEMENTS:
      return {
        ...state,
        vendorDisbursementsError: false,
        sort: {},
        ui: {
          ...state.ui,
          isVendorDisbursementsLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_VENDOR_DISBURSEMENTS_DONE:
      return {
        ...state,
        vendorDisbursementsError: action.error,
        vendorDisbursements: action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isVendorDisbursementsLoading: false,
          isInitialLoading: initialLoadingStatus,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.FETCH_VENDOR_RECEIPT:
      return {
        ...state,
        vendorReceiptError: false,
        ui: {
          ...state.ui,
          isVendorReceiptLoading: true,
        },
      };
    case constants.RECEIVE_VENDOR_RECEIPT_DONE:
      return {
        ...state,
        vendorReceipt: action.payload,
        vendorReceiptError: action.error,
        ui: {
          ...state.ui,
          isVendorReceiptLoading: false,
        },
      };
    case constants.SET_ELECTION_CYCLES:
      return {
        ...state,
        electionCyclesData: action.payload,
      };
    case constants.SELECT_ELECTION_CYCLE:
      return {
        ...state,
        electionCycle: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  appliedSearchFilters,
  advancedSearch,
  profile,
});
