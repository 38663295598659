import { formatMoney } from 'utils/formatters';

import { DEFAULT_PER_PAGE } from '../constants';

function transform(items) {
  return items.map(({ super_individual_donor: donor, receipt, super_committee_recipient: recipient }) => ({
    name: `${donor.first_name} ${donor.last_name}`,
    employer: donor.employer,
    occupation: donor.occupation,
    date: receipt.contribution_date,
    amount: `$${formatMoney(receipt.contribution_amount)}`,
    recipient: recipient.name,
  }));
}

export default function ({ action, state }) {
  const { items, pagination } = action.payload;
  const { page_number, total_count } = pagination;

  return {
    ...state,
    donors: transform(items),
    pagination: {
      current: page_number,
      totalCount: total_count,
      perPage: DEFAULT_PER_PAGE,
      total: Math.ceil(total_count / DEFAULT_PER_PAGE),
    },
    ui: {
      ...state.ui,
      loading: false,
      noResultsFound: total_count === 0,
    },
  };
}
