import moment from 'moment';
import { groupBy, get } from 'lodash';

import { DAY_DATE_FORMAT } from 'utils/constants/date';

function transformEventsData(events) {
  return events.map(event => ({
    id: event.id,
    addToCalendarUrl: event.calendar_export_url,
    committeeId: get(event.committee, 'id'),
    committeeType: get(event.committee, 'type'),
    committeeName: get(event.committee, 'name'),
    committeeUrl: get(event.committee, 'url'),
    scheduledDate: event.scheduled_at,
    title: event.title,
    location: event.location,
    url: event.url,
    isChecked: false,
  }));
}

export function groupResponseByDate(response) {
  const groupedData = groupBy(response.data, item => moment(item.scheduled_at).format(DAY_DATE_FORMAT));
  const formatData = [];

  Object.keys(groupedData).forEach(item => formatData.push({
    date: item,
    events: transformEventsData(groupedData[item]),
  }));

  return formatData;
}
