import stafferConstants from 'redux/staffers/common/constants';

const PREFIX = 'ROLODEX_STAFFER';

const constants = {
  ...stafferConstants(PREFIX),
  FETCH_STAFFER_DATA: `${PREFIX}/FETCH_STAFFER_DATA`,
  FETCH_STAFFER_DATA_DONE: `${PREFIX}/FETCH_STAFFER_DATA_DONE`,
  PROFILE_NOT_FOUND: `${PREFIX}/PROFILE_NOT_FOUND`,
  FETCH_COMMITTEE_OFFICE_DATA: `${PREFIX}/FETCH_COMMITTEE_OFFICE_DATA`,
  FETCH_COMMITTEE_OFFICE_DATA_DONE: `${PREFIX}/FETCH_COMMITTEE_OFFICE_DATA_DONE`,
  FETCH_LEGISLATOR_DATA: `${PREFIX}/FETCH_LEGISLATOR_DATA`,
  FETCH_LEGISLATOR_DATA_DONE: `${PREFIX}/FETCH_LEGISLATOR_DATA_DONE`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
};

export default constants;
