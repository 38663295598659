export default function ({ state }) {
  return {
    ...state,
    ui: {
      ...state.ui,
      success: true,
      loading: false,
    },
  };
}
