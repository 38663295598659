import { DAY_YEAR_DATE_FORMAT } from 'utils/constants/date';
import moment from 'moment';
import { SORT_BY_TYPES } from '../constants';

export function getSortParams(sort) {
  const options = {
    [SORT_BY_TYPES.NEWEST_TO_OLDEST]: { sort: 'created_at', direction: 'desc' },
    [SORT_BY_TYPES.OLDEST_TO_NEWEST]: { sort: 'created_at', direction: 'asc' },
    [SORT_BY_TYPES.A_TO_Z]: { sort: 'title.raw', direction: 'asc' },
    [SORT_BY_TYPES.Z_TO_A]: { sort: 'title.raw', direction: 'desc' },
  };

  return options[sort] || {};
}

function transformSmartList(smartList) {
  const {
    id,
    title,
    description,
    created_at,
    updated_at,
    count,
    is_processing,
    ...restProps
  } = smartList;

  return {
    id,
    title,
    description,
    createdAt: moment(created_at).format(DAY_YEAR_DATE_FORMAT),
    updatedAt: moment(updated_at).format(DAY_YEAR_DATE_FORMAT),
    stakeholdersCount: count,
    isProcessing: is_processing,
    ...restProps,
  };
}

export function transformSmartListsForUI(smartLists) {
  return smartLists.map(transformSmartList);
}

export function transformDataForFront({ title, description, request_data: data }) {
  const { transactor } = data;

  return {
    title,
    description,
    isTemplate: false,
    transactor,
    data,
  };
}

export function transformDataForBack({ title, description, data }) {
  return {
    title,
    description,
    request_data: data,
  };
}
