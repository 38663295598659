import moment from 'moment/moment';
import { isEmpty, map } from 'lodash';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

export function transformAmendmentsForUI(amendments = {}) {
  const { data = [] } = amendments;

  return {
    allItems: map(data, ({ proposed, sponsor, ...restProps }) => {
      const { party_short, party_slug, ...sponsorProps } = sponsor || {};

      return {
        ...restProps,
        proposed: proposed && moment(proposed).format(GLOBAL_DATE_FORMAT),
        sponsor: {
          ...sponsorProps,
          partyShort: party_short,
          partySlug: party_slug,
        },
      };
    }),
  };
}

export function transformAmendmentsForBE(payload) {
  const { id, ...restProps } = payload || {};

  return {
    ...restProps,
    search_form: {
      bill_id: id,
    },
  };
}
