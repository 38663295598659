const prefix = 'STAKEHOLDER_PROFILE';

export default {
  FETCH_STAKEHOLDER_PROFILE: `${prefix}/FETCH_STAKEHOLDER_PROFILE`,
  FETCH_STAKEHOLDER_PROFILE_FAIL: `${prefix}/FETCH_STAKEHOLDER_PROFILE_FAIL`,
  RECEIVE_STAKEHOLDER_PROFILE_DONE: `${prefix}/RECEIVE_STAKEHOLDER_PROFILE_DONE`,
  RECEIVE_STAKEHOLDER_PROFILE_FAIL: `${prefix}/RECEIVE_STAKEHOLDER_PROFILE_FAIL`,
  RELOAD_STAKEHOLDER_PROFILE: `${prefix}/RELOAD_STAKEHOLDER_PROFILE`,
  DELETE_STAKEHOLDER_TAG: `${prefix}/DELETE_STAKEHOLDER_TAG`,
  DELETE_STAKEHOLDER_TAG_DONE: `${prefix}/DELETE_STAKEHOLDER_TAG_DONE`,
  DELETE_STAKEHOLDER_LIST: `${prefix}/DELETE_STAKEHOLDER_LIST`,
  DELETE_STAKEHOLDER_LIST_DONE: `${prefix}/DELETE_STAKEHOLDER_LIST_DONE`,
  SORT_STAKEHOLDER_SMART_LIST_DATA: `${prefix}/SORT_STAKEHOLDER_SMART_LIST_DATA`,
  SORT_STAKEHOLDER_SMART_LIST_DATA_DONE: `${prefix}/SORT_STAKEHOLDER_SMART_LIST_DATA_DONE`,
  SORT_STAKEHOLDER_LIST_DATA: `${prefix}/SORT_STAKEHOLDER_LIST_DATA`,
  SORT_STAKEHOLDER_LIST_DATA_DONE: `${prefix}/SORT_STAKEHOLDER_LIST_DATA_DONE`,
  SORT_STAKEHOLDER_TAG_DATA: `${prefix}/SORT_STAKEHOLDER_TAG_DATA`,
  SORT_STAKEHOLDER_TAG_DATA_DONE: `${prefix}/SORT_STAKEHOLDER_TAG_DATA_DONE`,
  FETCH_STAKEHOLDER_CONTRIBUTIONS: `${prefix}/FETCH_STAKEHOLDER_CONTRIBUTIONS`,
  FETCH_STAKEHOLDER_CONTRIBUTIONS_FAIL: `${prefix}/FETCH_STAKEHOLDER_CONTRIBUTIONS_FAIL`,
  FETCH_STAKEHOLDER_CONTRIBUTIONS_DONE: `${prefix}/FETCH_STAKEHOLDER_CONTRIBUTIONS_DONE`,
  FETCH_STAKEHOLDER_MATCHING_INDIVIDUALS: `${prefix}/FETCH_STAKEHOLDER_MATCHING_INDIVIDUALS`,
  FETCH_STAKEHOLDER_MATCHING_INDIVIDUALS_FAIL: `${prefix}/FETCH_STAKEHOLDER_MATCHING_INDIVIDUALS_FAIL`,
  FETCH_STAKEHOLDER_MATCHING_INDIVIDUALS_DONE: `${prefix}/FETCH_STAKEHOLDER_MATCHING_INDIVIDUALS_DONE`,
  FETCH_CONTRIBUTIONS_LAST_SYNC: `${prefix}/FETCH_CONTRIBUTIONS_LAST_SYNC`,
  FETCH_CONTRIBUTIONS_LAST_SYNC_FAIL: `${prefix}/FETCH_CONTRIBUTIONS_LAST_SYNC_FAIL`,
  FETCH_CONTRIBUTIONS_LAST_SYNC_DONE: `${prefix}/FETCH_CONTRIBUTIONS_LAST_SYNC_DONE`,
  RESET_DATA: `${prefix}/RESET_DATA`,
  UPDATE_STAKEHOLDER_PROFILE_DESCRIPTION: `${prefix}/UPDATE_STAKEHOLDER_PROFILE_DESCRIPTION`,
};
