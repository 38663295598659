import { map, pick } from 'lodash';

import updateStakeholdersAppliedItems from '../utils/updateStakeholdersAppliedItems';
import constants from '../constants';

function getTagsApplyingRequest({ ids, tags }) {
  const data = {
    tag_params: {
      stakeholder_ids: ids,
      tags,
    },
  };

  const url = '/api_web/stakeholder-management/tags';
  const request = $.post(url, data);

  return request;
}

function getBulkTagsApplyingRequest({ tags, lastSearchId }) {
  const data = {
    bulk_params: {
      search_id: lastSearchId,
      tags,
    },
  };

  const url = '/api_web/stakeholder-management/bulk_tags';
  const request = $.post(url, data);

  return request;
}

export function submitAppliedTags(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.SUBMIT_APPLIED_TAGS });

    const {
      ids = [],
      lastSearchId,
      tags = [],
      toggleOpen,
      onClear,
      notifications = {},
    } = payload;

    const isBulkAction = ids.length === 0;

    const request = isBulkAction
      ? getBulkTagsApplyingRequest({ lastSearchId, tags })
      : getTagsApplyingRequest({ ids, tags });

    function onSuccess(response) {
      const { stakeholders } = getState().stakeholder.stakeholderLookup.stakeholderLookup;
      const newTags = map(response, tag => pick(tag, ['id', 'name']));

      const allStakeholdersIds = stakeholders.map(({ id }) => id);
      const updatedStakeholdersIds = isBulkAction ? allStakeholdersIds : ids;

      const updatedStakeholders = updateStakeholdersAppliedItems({
        collection: 'tags',
        appliedItems: newTags,
        stakeholders,
        updatedStakeholdersIds,
      });

      dispatch({
        type: constants.SUBMIT_APPLIED_TAGS_DONE,
        payload: updatedStakeholders,
      });

      notifications.getSuccess();
      toggleOpen();
      onClear();
    }

    function onFailure(error) {
      dispatch({ type: constants.SUBMIT_APPLIED_TAGS_FAIL });
      notifications.getFailed();
      throw error;
    }

    return Promise.resolve(request)
      .then(onSuccess)
      .catch(onFailure);
  };
}
