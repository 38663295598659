const PREFIX = 'DASHBOARD_MYFEED';

const constants = {
  SET_SEEN: `${PREFIX}/SET_SEEN`,
  SET_LAST_VISIT: `${PREFIX}/SET_LAST_VISIT`,
  SET_CREATED_TO: `${PREFIX}/SET_CREATED_TO`,
  UPDATE_EVENT_INIT: `${PREFIX}/UPDATE_EVENT_INIT`,
  UPDATE_EVENT_KEYWORD: `${PREFIX}/UPDATE_EVENT_KEYWORD`,
  UPDATE_EVENT: `${PREFIX}/UPDATE_EVENT`,
  REMOVE_EVENT: `${PREFIX}/REMOVE_EVENT`,
  LOAD_UNDO_EVENT: `${PREFIX}/LOAD_UNDO_EVENT`,
  UNDO_EVENT: `${PREFIX}/UNDO_EVENT`,
  LOAD_DATA_INIT: `${PREFIX}/LOAD_DATA_INIT`,
  LOAD_DATA_DONE: `${PREFIX}/LOAD_DATA_DONE`,
  LOADING_DATA_FINISH: `${PREFIX}/LOADING_DATA_FINISH`,
  LOAD_KEYWORDS_INIT: `${PREFIX}/LOAD_KEYWORDS_INIT`,
  LOAD_KEYWORDS_DONE: `${PREFIX}/LOAD_KEYWORDS_DONE`,
  LOAD_POSITIONS_INIT: `${PREFIX}/LOAD_POSITIONS_INIT`,
  LOAD_POSITIONS_DONE: `${PREFIX}/LOAD_POSITIONS_DONE`,
  UPDATE_POSITION_TRACK: `${PREFIX}/UPDATE_POSITION_TRACK`,
  LOAD_RECENT_ITEM_SUBSCRIPTION: `${PREFIX}/LOAD_RECENT_ITEM_SUBSCRIPTION`,
  LOAD_ITEMS_SUBSCRIPTIONS_INIT: `${PREFIX}/LOAD_ITEMS_SUBSCRIPTIONS_INIT`,
  LOAD_ITEMS_SUBSCRIPTIONS_FINISHED: `${PREFIX}/LOAD_ITEMS_SUBSCRIPTIONS_FINISHED`,
  LOAD_POSITIONS_FINISHED: `${PREFIX}/LOAD_POSITIONS_FINISHED`,
  LOAD_TAGS: `${PREFIX}/LOAD_TAGS`,
  LOAD_TAGS_DONE: `${PREFIX}/LOAD_TAGS_DONE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  SAVE_TAGS_ITEM: `${PREFIX}/SAVE_TAGS_ITEM`,
  UPDATE_TAG_ITEM: `${PREFIX}/UPDATE_TAG_ITEM`,
  UPDATE_TAG_ITEM_DONE: `${PREFIX}/UPDATE_TAG_ITEM_DONE`,
  BULK_REMOVE_EVENTS: `${PREFIX}/BULK_REMOVE_EVENTS`,
  BULK_REMOVE_EVENTS_DONE: `${PREFIX}/BULK_REMOVE_EVENTS_DONE`,
  BULK_UPDATE_EVENTS: `${PREFIX}/BULK_UPDATE_EVENTS`,
  BULK_UPDATE_EVENTS_DONE: `${PREFIX}/BULK_UPDATE_EVENTS_DONE`,
  UPDATE_TRACK_COMMITTEE_INIT: `${PREFIX}/UPDATE_TRACK_COMMITTEE_INIT`,
  UPDATE_TRACK_COMMITTEE: `${PREFIX}/UPDATE_TRACK_COMMITTEE`,
  SET_CARDS_LINK_PREVIEW_INIT: `${PREFIX}/SET_CARDS_LINK_PREVIEW_INIT`,
  SET_CARDS_LINK_PREVIEW_DONE: `${PREFIX}/SET_CARDS_LINK_PREVIEW_DONE`,
  RESET: `${PREFIX}/RESET`,
};

export default constants;
