const PREFIX = 'NEWS_STORY_FEED';

const constants = {
  FETCH_GENERAL_FEEDS: `${PREFIX}/FETCH_GENERAL_FEEDS`,
  RECEIVE_GENERAL_FEEDS_DONE: `${PREFIX}/RECEIVE_GENERAL_FEEDS_DONE`,
  RECEIVE_GENERAL_FEEDS_FAILURE: `${PREFIX}/RECEIVE_GENERAL_FEEDS_FAILURE`,
  FETCH_PERSONAL_FEEDS: `${PREFIX}/FETCH_PERSONAL_FEEDS`,
  RECEIVE_PERSONAL_FEEDS_DONE: `${PREFIX}/RECEIVE_PERSONAL_FEEDS_DONE`,
  RECEIVE_PERSONAL_FEEDS_FAILURE: `${PREFIX}/RECEIVE_PERSONAL_FEEDS_FAILURE`,
  DELETE_FEED: `${PREFIX}/DELETE_FEED`,
  DELETE_FEED_DONE: `${PREFIX}/DELETE_FEED_DONE`,
  DELETE_FEED_FAILURE: `${PREFIX}/DELETE_FEED_FAILURE`,
  CREATE_FEED_DONE: `${PREFIX}/CREATE_FEED_DONE`,
  CREATE_FEED_FAILURE: `${PREFIX}/CREATE_FEED_FAILURE`,
  UPDATE_FEED_DONE: `${PREFIX}/UPDATE_FEED_DONE`,
  UPDATE_FEED_FAILURE: `${PREFIX}/UPDATE_FEED_FAILURE`,
  SUBSCRIBE_TO_FEED: `${PREFIX}/SUBSCRIBE_TO_FEED`,
  SUBSCRIBE_TO_FEED_DONE: `${PREFIX}/SUBSCRIBE_TO_FEED_DONE`,
  SUBSCRIBE_TO_FEED_FAILURE: `${PREFIX}/SUBSCRIBE_TO_FEED_FAILURE`,
};

export default constants;
