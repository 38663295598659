import { isEmpty, map, sortBy as sortByAsc } from 'lodash';

import { getValue } from 'redux/moneyball/utils/transformers';
import { currencyFormat } from 'components/Moneyball/utils';

function transformFilters({
  sector,
  industry,
  category,
  cycle,
  subtype,
  sortBy,
  pageSize,
  page,
  all,
}) {
  const { value: sectorId } = sector || {};
  const { value: industryId } = industry || {};
  const { value: categoryId } = category || {};
  const years = cycle && cycle.map(({ value }) => value).filter(Boolean);
  const organizationTypeIds = subtype && subtype.map(({ value }) => value).filter(Boolean);

  return JSON.stringify({
    ...all && ({ all }),
    ...page && ({ page }),
    ...pageSize && ({ page_size: pageSize }),
    ...sectorId && ({ sector_id: sectorId }),
    ...industryId && ({ industry_id: industryId }),
    ...categoryId && ({ category_id: categoryId }),
    ...getValue('years', years),
    ...getValue('organization_type_ids', organizationTypeIds),
    ...getValue('sort_by', sortBy),
  });
}

export function transformDataRequest(filters) {
  return transformFilters(filters);
}

export function transformDataResponse([totals, graph]) {
  const { total_pacs, total_by_type, total_disbursements_by_party } = totals || {};
  const { republican, democrat } = total_disbursements_by_party || {};
  const { receipts, disbursements } = total_by_type || {};
  const sortedGraph = sortByAsc(graph, ['year']);

  return {
    totals: {
      receipts: currencyFormat(receipts, 0),
      disbursements: currencyFormat(disbursements, 0),
      toRepublicans: currencyFormat(republican, 0),
      toDemocrats: currencyFormat(democrat, 0),
      contributingPACS: total_pacs,
    },
    graph: isEmpty(sortedGraph) ? null : {
      labels: sortedGraph.map(({ year }) => year),
      values: {
        other: sortedGraph.map(({ contributed_to_others }) => contributed_to_others),
        republican: sortedGraph.map(({ contributed_to_republicans }) => contributed_to_republicans),
        democratic: sortedGraph.map(({ contributed_to_democrats }) => contributed_to_democrats),
        received: sortedGraph.map(({ total_received }) => total_received),
      },
    },
  };
}

export function transformDataLookupRequest(filters) {
  return transformFilters(filters);
}

export function transformDataLookupResponse({ items = [] } = {}) {
  return map(items, ({
    total_democrats,
    total_republicans,
    end_cash_on_hands,
    average,
    total,
    ...restProps
  }) => ({
    ...restProps,
    toRepublicans: currencyFormat(total_republicans, 0),
    toDemocrats: currencyFormat(total_democrats, 0),
    endCoH: currencyFormat(end_cash_on_hands, 0),
    average: currencyFormat(average, 0),
    total: currencyFormat(total, 0),
  }));
}

export function transformFiltersResponse([industries, organizationTypes]) {
  return {
    sectors: industries,
    subtypes: organizationTypes,
  };
}
