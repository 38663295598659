const PREFIX = 'STATE_LEGISLATOR';

const constants = {
  FETCH_LEGISLATOR: `${PREFIX}/FETCH_LEGISLATOR`,
  RECEIVE_LEGISLATOR_DONE: `${PREFIX}/RECEIVE_LEGISLATOR`,
  PROFILE_NOT_FOUND: `${PREFIX}/PROFILE_NOT_FOUND`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  UPDATE_PROFILE_MY_FEED: `${PREFIX}/UPDATE_PROFILE_MY_FEED`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  RESET_LEGISLATOR_DATA: `${PREFIX}/RESET_LEGISLATOR_DATA`,
};

export default constants;
