import { isFunction } from 'lodash';

import { FEDERAL_TRACKED_TYPE } from './federalTransformers';
import { STATE_TRACKED_TYPE } from './stateTransformers';

const ALL_ITEMS = {
  ...FEDERAL_TRACKED_TYPE,
  ...STATE_TRACKED_TYPE,
  undefined: () => {},
};

export function transformTrackedItem({ item_type, ...restProps }) {
  const allItemsFunction = ALL_ITEMS[item_type];

  return isFunction(allItemsFunction) ? allItemsFunction(restProps) : {};
}
