import { fetchMapBox } from 'components/map/helpers';
import mapConstants from 'components/map/constants';

const constants = {
  FETCH_DISTRICT_ID: 'DISTRICT_MAPS/FETCH_DISTRICT_ID',
  FETCH_DISTRICT_META: 'DISTRICT_MAPS/FETCH_DISTRICT_META',
  RECEIVE_DISTRICT_ID: 'DISTRICT_MAPS/RECEIVE_DISTRICT_ID',
  RECEIVE_DISTRICT_META: 'DISTRICT_MAPS/RECEIVE_DISTRICT_META',
  RECEIVE_DISTRICT_NOT_FOUND: 'DISTRICT_MAPS/RECEIVE_DISTRICT_NOT_FOUND',
  FETCHING_DISTRICT_INFO: 'DISTRICTS/FETCHING_DISTRICT_INFO',
  RECEIVE_DISTRICT_INFO: 'DISTRICTS/RECEIVE_DISTRICT_INFO',
  ERROR_FETCHING_DISTRICT_INFO: 'DISTRICTS/ERROR_FETCHING_DISTRICT_INFO',
};

const chamberSlugs = {
  cd: null,
  sldu: 'senate',
  sldl: 'house',
};

export function receiveDistrictMeta(payload) {
  return { type: constants.RECEIVE_DISTRICT_META, payload };
}

export function receiveDistrictNotFound(payload) {
  return { type: constants.RECEIVE_DISTRICT_NOT_FOUND, payload };
}

export function fetchDistrictMeta(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DISTRICT_META, payload });

    const features = payload.features;
    const type = mapConstants.CONGRESSIONAL_DISTRICTS;
    const targetFeature = features.find(f => f.properties.type === type);

    if (!targetFeature) {
      return dispatch(receiveDistrictNotFound({}));
    }

    const properties = targetFeature.properties;
    const geoId = properties.geoId;

    if (!geoId) {
      return dispatch(receiveDistrictNotFound({}));
    }

    // api_web_cosponsor_oracle_district_status_path
    return $.ajax({
      url: `/api_web/cosponsor_oracle/district_statuses/${geoId}`,
      method: 'GET',
    })
    .then(result =>
      dispatch(receiveDistrictMeta(result))
    ).fail(() =>
      dispatch(receiveDistrictNotFound({}))
    );
  };
}

export function fetchDistrictId(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DISTRICT_ID, payload });
    fetchMapBox(payload.coordinates.join())
      .then(result => dispatch(fetchDistrictMeta(result)));
  };
}

// Fetch district info by geoId
function fetchingDistrictInfo() {
  return { type: constants.FETCHING_DISTRICT_INFO };
}

function receiveDistrictInfo(geoId, district, type) {
  return {
    type: constants.RECEIVE_DISTRICT_INFO,
    payload: { geoId, district, type },
  };
}

function errorFetchingDistrictInfo() {
  return { type: constants.ERROR_FETCHING_DISTRICT_INFO };
}

export function fetchDistrictInfo(geoId, type) {
  return (dispatch, getState) => {
    const chamber = chamberSlugs[type];
    const { districtsInfo } = getState().districtMaps;

    if (districtsInfo[type] && districtsInfo[type][geoId]) {
      return Promise.resolve(districtsInfo[type][geoId]);
    }

    dispatch(fetchingDistrictInfo());

    const url = `/api_web/districts/${geoId}`;
    const request = $.ajax({
      url,
      type: 'GET',
      data: chamber ? { chamber_slug: chamber } : null,
    });

    return Promise.resolve(request)
      .then((district) => {
        dispatch(receiveDistrictInfo(geoId, district, type));
      })
      .catch((error) => {
        dispatch(errorFetchingDistrictInfo());
        throw error;
      });
  };
}


const defaultState = {
  district: {},
  districtsInfo: {},
  ui: {
    loadingDistrict: false,
    loadedDistrict: false,
    isFetchingDistrictInfo: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_DISTRICT_ID:
      return {
        ...state,
        district: {},
        ui: {
          ...state.ui,
          loadingDistrict: true,
          loadedDistrict: false,
        },
      };
    case constants.FETCH_DISTRICT_META:
      return {
        ...state,
        district: {},
        ui: {
          ...state.ui,
          loadingDistrict: true,
          loadedDistrict: false,
        },
      };
    case constants.RECEIVE_DISTRICT_META:
      return {
        ...state,
        district: action.payload,
        ui: {
          ...state.ui,
          loadingDistrict: false,
          loadedDistrict: true,
        },
      };
    case constants.RECEIVE_DISTRICT_NOT_FOUND:
      return {
        ...state,
        district: {},
        ui: {
          ...state.ui,
          loadingDistrict: false,
          loadedDistrict: true,
        },
      };
    // fetch district info
    case constants.FETCHING_DISTRICT_INFO:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetchingDistrictInfo: true,
        },
      };
    case constants.ERROR_FETCHING_DISTRICT_INFO:
      return {
        ...state,
        ui: {
          ...state.ui,
          isFetchingDistrictInfo: false,
        },
      };
    case constants.RECEIVE_DISTRICT_INFO:
      return {
        ...state,
        districtsInfo: {
          ...state.districtsInfo,
          [action.payload.type]: {
            ...state.districtsInfo[action.payload.type],
            [action.payload.geoId]: action.payload.district,
          },
        },
        ui: {
          ...state.ui,
          isFetchingDistrictInfo: false,
        },
      };
    default:
      return state;
  }
}
