import { isEmpty, pickBy, identity } from 'lodash';
import moment from 'moment/moment';

import { CSV_ID } from 'utils/constants/export';
import { submitExportReport } from 'redux/exportReport/actions';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

import { STRATEGY_SLUG_BULK_EXPORT } from '../constants';
import { getExportInfo, getExportSortInfo } from './utils';

export function submitExport(payload = {}) {
  const { searchData, sort } = payload;
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: STRATEGY_SLUG_BULK_EXPORT,
    'strategy_inputs[file_extension]': CSV_ID,
    ...pickBy(getExportInfo(searchForm), identity),
    ...pickBy(getExportSortInfo(sort), identity),
  };

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `State regulations ${moment().format(GLOBAL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({ data, onSuccessCustom });
}
