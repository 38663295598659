const PREFIX = 'MONEYBALL_SMARTSEARCH_LOOKUP';

export const API_URL = '/api_web/moneyball_v2';

export const STRATEGY_SLUG = 'moneyball_searches_lookup';

export const FETCH_SEARCHES = `${PREFIX}/FETCH_SEARCHES`;
export const RECEIVE_SEARCHES = `${PREFIX}/RECEIVE_SEARCHES`;

export const FETCH_SEARCHES_RESULTS = `${PREFIX}/FETCH_SEARCHES_RESULTS`;
export const DELETE_SEARCHES_ITEM = `${PREFIX}/DELETE_SEARCHES_ITEM`;
export const RESET_SEARCHES_TIMERS = `${PREFIX}/RESET_SEARCHES_TIMERS`;
export const RECEIVE_SEARCHES_RESULT = `${PREFIX}/RECEIVE_SEARCHES_RESULT`;
export const RECEIVE_SEARCHES_RESULTS = `${PREFIX}/RECEIVE_SEARCHES_RESULTS`;
export const REFRESH_SEARCHES_RESULTS = `${PREFIX}/REFRESH_SEARCHES_RESULTS`;
export const SET_ENRICHMENT = `${PREFIX}/SET_ENRICHMENT`;
export const RESET = `${PREFIX}/RESET`;
