import { RECIPIENT_TYPE } from 'components/GrassrootsPortal/constants';
import { TWITTER_LINK } from 'utils/constants/socialMedia';

function findLegislatorMatcherMessage(legislator) {
  const { matcher, chamber_slug } = legislator;

  return matcher.matcher_messages.find(message => message.chamber_slug === chamber_slug);
}

export function legislatorToRecipient(legislator) {
  return {
    ...legislator,
    matcherMessage: findLegislatorMatcherMessage(legislator),
  };
}

function findCustomRecipientMatcherMessage(customRecipient) {
  return customRecipient.matcher.matcher_messages[0];
}

function twitterLink(twitterScreenName) {
  if (!twitterScreenName) {
    return twitterScreenName;
  }

  return `${TWITTER_LINK}/${twitterScreenName}`;
}

function facebookAccount(facebookUrl) {
  if (!facebookUrl) {
    return facebookUrl;
  }

  return facebookUrl.split('/').slice(-1)[0];
}

function socialInfo(customRecipient) {
  const { twitter_screen_name, facebook_url } = customRecipient;

  return {
    twitter_screen_name,
    twitter_link: twitterLink(twitter_screen_name),
    facebook_account: facebookAccount(facebook_url),
    facebook_link: facebook_url,
  };
}

export function customRecipientToRecipient(customRecipient) {
  return {
    ...customRecipient,
    full_name: customRecipient.title_name,
    addressable_moniker: customRecipient.title_name,
    recipient_type: RECIPIENT_TYPE.CUSTOM_RECIPIENT,
    chamber_slug: null,
    matcherMessage: findCustomRecipientMatcherMessage(customRecipient),
    ...socialInfo(customRecipient),
  };
}
