import { combineReducers } from 'redux';

import storyFeed from './news/reducers';
import cluster from './cluster/reducers';
import analytics from './common/analytics/reducers';
import newsFeed from './common/reducers';

export default combineReducers({
  newsFeed,
  storyFeed,
  cluster,
  analytics,
});
