import { DELETE_SMART_LIST, DELETE_SMART_LIST_DONE } from '../constants';

export function deleteSmartList(payload = {}) {
  return (dispatch) => {
    dispatch({ type: DELETE_SMART_LIST });

    const {
      id,
      toggleOpen,
      notifications = {},
    } = payload;

    const url = `/api_web/stakeholder-management/smart_lists/${id}`;
    const request = $.ajax({
      url,
      type: 'DELETE',
    });

    function onSuccess() {
      setTimeout(() => {
        dispatch({
          type: DELETE_SMART_LIST_DONE,
          payload: { id },
        });
        notifications.success();
        toggleOpen();
      }, 2000);
    }

    function onFailure() {
      notifications.failure();
      toggleOpen();
    }

    return Promise.resolve(request)
      .then(onSuccess)
      .catch(onFailure);
  };
}
