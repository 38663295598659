import { map, toString, includes, unionBy, isNil, size, compact } from 'lodash';

export * from './subscriptionReducer';
export * from './linkPreviewReducer';
export * from './positionReducer';
export * from './tagsReducer';

/** UNDO EVENTS */
export function loadUndoEvent(state) {
  return {
    ...state,
    isLoadingUndo: true,
  };
}

export function undoEvent(state, action) {
  const { data } = state || {};
  const { id: actionId } = action || {};

  function setUndoFalse(id) {
    return id === actionId ? { isUndo: false } : {};
  }

  return {
    ...state,
    isLoadingUndo: false,
    data: map(data, ({ id, ...restProps }) => ({ ...restProps, ...setUndoFalse(id), id })),
  };
}

export function setIsOldData(data) {
  return map(data, item => ({ ...item, isNewData: false }));
}

export function removeEvent(state, payload) {
  const { data } = state || {};
  const { id: actionId } = payload || {};

  return {
    ...state,
    data: map(data, ({ id, ...restProps }) => {
      if (id === actionId) {
        return { ...restProps, id, isUndo: true };
      }

      return { ...restProps, id };
    }),
  };
}

export function updateDataItem(state, action) {
  const { ui, data } = state || {};
  const { recordId, modelId } = action.payload || {};

  if (modelId) {
    return {
      ...state,
      data: map(data, item => ({
        ...item,
        ...(modelId === item.modelId && action.payload),
      })),
    };
  }

  return {
    ...state,
    ui: {
      ...ui,
      isLoadingSingleTag: false,
    },
    data: map(data, item => ({
      ...item,
      ...(item.recordId === recordId && action.payload),
    })),
  };
}

export function updateTagItemLoading(state) {
  const { ui } = state || {};

  return {
    ...state,
    ui: {
      ...ui,
      isLoadingSingleTag: true,
    },
  };
}

export function loadEvent(state, payload) {
  const { data } = state || {};
  const { id: eventId } = payload || {};

  return {
    ...state,
    data: map(data, (item) => {
      const { id } = item || {};
      const updatedItem = id === eventId ? payload : {};

      return ({ ...item, ...updatedItem });
    }),
  };
}

export function loadingBulkRemoveEvents(state) {
  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingBulkRemove: true,
    },
  };
}

export function bulkRemoveItems(state, action) {
  const { data } = state || {};
  const { tokens } = action || {};

  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingBulkRemove: false,
    },
    data: map(data, ({ id, ...restProps }) => {
      if (includes(tokens, toString(id))) {
        return { ...restProps, id, isUndo: true };
      }

      return { ...restProps, id };
    }),
  };
}

export function loadingBulkUpdateItems(state, action) {
  const {
    isLoadingSaving,
    isLoadingPriority,
    isLoadingTags,
    isLoadingRead,
    isLoadingUnread,
  } = action || {};

  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingBulkSaving: isLoadingSaving || false,
      isLoadingBulkPriority: isLoadingPriority || false,
      isLoadingBulkRead: isLoadingRead || false,
      isLoadingBulkUnread: isLoadingUnread || false,
      isLoadingBulkTags: isLoadingTags || false,
    },
  };
}

export function bulkUpdateItems(state, action) {
  const { data } = state || {};
  const { tokens, priority, isSaved, isRead } = action || {};

  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingBulkSaving: false,
      isLoadingBulkPriority: false,
      isLoadingBulkRead: false,
      isLoadingBulkUnread: false,
      isLoadingBulkTags: false,
    },
    data: map(data, (item) => {
      const { id } = item || {};

      if (includes(tokens, toString(id))) {
        return {
          ...item,
          ...(!isNil(isRead) && { isRead }),
          ...(isSaved && { isSaved }),
          ...(priority && { priority }),
        };
      }

      return item;
    }),
  };
}

export function updateEvent(state, payload) {
  const { data, ...restStateProps } = state || {};
  const { id, frequency } = payload || {};

  return {
    ...restStateProps,
    data: map(data, (item) => {
      const { id: itemId, subscription = {} } = item;
      const updatedSubscription = isNil(frequency) ? subscription : { ...subscription, frequency };
      const updatedItem = itemId === id
        ? { ...payload, subscription: updatedSubscription }
        : {};

      return ({ ...item, ...updatedItem });
    }),
  };
}

export function updateEventKeyword(state, { isLoadingSubscription, modelId, frequency }) {
  const { data, ...restStateProps } = state || {};

  return {
    ...restStateProps,
    data: map(data, (item) => {
      const { subscription = {} } = item;
      const { token } = subscription;
      const itemPayload = {
        isLoadingSubscription,
        subscription: {
          ...subscription,
          frequency,
        },
        frequency,
      };

      return ({ ...item, ...token === modelId && itemPayload });
    }),
  };
}

function getTrackCommitteeIds({ stafferData, hearingData }) {
  const { modelId: stafferId } = stafferData || {};
  const { modelId: hearingId } = hearingData || {};

  return compact([toString(stafferId), toString(hearingId)]);
}

export function updateTrackCommitteeInit(state, payload) {
  const { data } = state || {};
  const ids = getTrackCommitteeIds(payload);

  return {
    ...state,
    data: map(data, (item) => {
      const { recordId } = item || {};
      const updatedItem = ids.includes(recordId) ? { isLoadingSubscription: true } : {};

      return ({ ...item, ...updatedItem });
    }),
  };
}

export function updateTrackCommittee(state, payload) {
  const { data, ...restStateProps } = state || {};
  const { updateSubscriptions } = payload || {};
  const ids = getTrackCommitteeIds(payload);

  return {
    ...restStateProps,
    data: map(data, (item) => {
      const { recordId, listSubscriptions } = item || {};
      const list = unionBy(updateSubscriptions, listSubscriptions, 'type');

      const updatedItem = includes(ids, toString(recordId)) ? {
        isLoadingSubscription: false,
        isTracking: size(list) > 0,
        listSubscriptions: list,
      } : {};

      return ({ ...item, ...updatedItem });
    }),
  };
}
