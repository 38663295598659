import { combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import constants from './constants';

export const defaultState = {
  error: false,
  candidate: {},
  candidateReceipt: {},
  candidateReceiptError: false,
  candidateContributions: [],
  candidateContributionsError: false,
  electionCyclesData: [],
  electionCycle: null,
  paginator: {},
  sort: {},
  ui: {
    isInitialLoading: true,
    isCandidateLoading: true,
    isContributionsLoading: true,
    isCandidateReceiptsLoading: true,
    isLoadingReport: false,
    toResetSelectedItems: true,
  },
};

function profile(state = defaultState, action) {
  const initialLoadingStatus = !!(
    state.ui.isInitialLoading &&
    state.candidateContributions.length &&
    !isEmpty(state.candidate)
  );

  switch (action.type) {
    case constants.FETCH_CANDIDATE:
      return {
        ...state,
        error: false,
        candidate: {},
        ui: {
          ...state.ui,
          isCandidateLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_CANDIDATE_DONE:
      return {
        ...state,
        error: action.error,
        candidate: action.payload,
        ui: {
          ...state.ui,
          isCandidateLoading: false,
          toResetSelectedItems: true,
          isInitialLoading: initialLoadingStatus,
        },
      };
    case constants.FETCH_CANDIDATE_CONTRIBUTIONS:
      return {
        ...state,
        candidateContributionsError: false,
        sort: {},
        ui: {
          ...state.ui,
          isContributionsLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_CANDIDATE_CONTRIBUTIONS_DONE:
      return {
        ...state,
        candidateContributionsError: action.error,
        candidateContributions: action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isContributionsLoading: false,
          isInitialLoading: initialLoadingStatus,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.FETCH_CANDIDATE_RECEIPT:
      return {
        ...state,
        candidateReceiptError: false,
        ui: {
          ...state.ui,
          isCandidateReceiptsLoading: true,
        },
      };
    case constants.RECEIVE_CANDIDATE_RECEIPT_DONE:
      return {
        ...state,
        candidateReceipt: action.payload,
        candidateReceiptError: action.error,
        ui: {
          ...state.ui,
          isCandidateReceiptsLoading: false,
        },
      };
    case constants.SET_ELECTION_CYCLES:
      return {
        ...state,
        electionCyclesData: action.payload,
      };
    case constants.SELECT_ELECTION_CYCLE:
      return {
        ...state,
        electionCycle: action.payload,
      };
    case constants.SUBMIT_EXPORT_REPORT_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: true,
        },
      };
    case constants.SUBMIT_EXPORT_REPORT_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: false,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  appliedSearchFilters,
  advancedSearch,
  profile,
});
