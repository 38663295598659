import { delay, head } from 'lodash';

import { transformPaginator } from 'utils/transformers/paginator';
import Notifications from 'components/core/CustomFields/utils/notifications';

import constants from './constants';
import {
  transformAllCustomFields,
  transformCustomField,
  reorderCustomFields,
  transformPositionsForBE,
} from './utils';

// Temporary fix to elastic search delete and create issue
const afterChangeDelay = 1000;
const delayResponse = callback => response => delay(() => callback(response), afterChangeDelay);

const getSearchForm = (enabled, productSlug) => ({
  search_form: {
    enabled,
    product_slugs: [productSlug],
  },
});

export function fetchCustomFields(params = {
  search_form: {
    enabled: true,
  },
}) {
  return (dispatch) => {
    dispatch({
      type: constants.FETCH_CUSTOM_FIELDS,
      isLoadingCustomFields: true,
    });

    const { enabled, product_slugs } = params.search_form;
    const ajax = $.get('/api_web/custom_fields/fields', params);

    return Promise.resolve(ajax).then(response => dispatch({
      type: constants.FETCH_CUSTOM_FIELDS_DONE,
      payload: {
        ...transformAllCustomFields({
          ...response,
          isEnabled: enabled,
          paginator: transformPaginator(response),
        }),
        availableIn: head(product_slugs),
      },
      isLoadingCustomFields: false,
    }));
  };
}

export function getCustomFieldByToken(token) {
  return (dispatch) => {
    dispatch({
      type: constants.GET_CUSTOM_FIELD_BY_TOKEN,
      isLoadingCustomFieldByToken: true,
    });

    const url = `/api_web/custom_fields/fields/${token}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax).then(response => dispatch({
      type: constants.GET_CUSTOM_FIELD_BY_TOKEN_DONE,
      payload: transformCustomField(response),
      isLoadingCustomFieldByToken: false,
    }));
  };
}

export function createCustomField(payload = {}, onResetForm) {
  return (dispatch) => {
    dispatch({
      type: constants.CREATE_CUSTOM_FIELDS,
      isSavingCustomField: true,
    });

    const ajax = $.ajax({
      type: 'POST',
      data: JSON.stringify(payload),
      contentType: 'application/json',
      url: '/api_web/custom_fields/fields',
    });

    function onSuccess() {
      dispatch({
        type: constants.CREATE_CUSTOM_FIELDS_DONE,
        isSavingCustomField: false,
      });

      if (onResetForm) onResetForm();

      const productSlug = head(payload.custom_field.product_slugs);

      return dispatch(fetchCustomFields(getSearchForm(true, productSlug)));
    }

    return Promise.resolve(ajax)
      .then(delayResponse(onSuccess));
  };
}

export function updateCustomField(token, payload = {}, toggleModal, isEnabled) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_CUSTOM_FIELDS,
      isUpdatingCustomField: true,
    });

    const url = `/api_web/custom_fields/fields/${token}`;
    const data = JSON.stringify({ custom_field: payload });

    const ajax = $.ajax({
      url,
      type: 'PUT',
      data,
      contentType: 'application/json',
    });

    function onSuccess() {
      if (toggleModal) toggleModal();

      dispatch({
        type: constants.UPDATE_CUSTOM_FIELDS_DONE,
        isUpdatingCustomField: false,
      });

      return dispatch(fetchCustomFields(getSearchForm(isEnabled, head(payload.product_slugs))));
    }

    function onFailure(error) {
      dispatch({
        type: constants.UPDATE_CUSTOM_FIELDS_DONE,
        isUpdatingCustomField: false,
      });

      return Promise.reject(error);
    }

    return Promise.resolve(ajax)
      .then(delayResponse(onSuccess))
      .catch(onFailure);
  };
}

export function toggleCustomFieldStatus(payload = {}, onClearItems) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_CUSTOM_FIELDS,
      isUpdatingCustomField: true,
    });

    const url = '/api_web/custom_fields/fields/bulk_enable';

    const ajax = $.post(url, payload);

    function onSuccess() {
      if (onClearItems) onClearItems();

      dispatch({
        type: constants.UPDATE_CUSTOM_FIELDS_DONE,
        isUpdatingCustomField: false,
      });

      const isEnabled = payload.custom_fields.enabled;

      return dispatch(fetchCustomFields(getSearchForm(isEnabled, payload.availableIn)));
    }

    return Promise.resolve(ajax)
      .then(delayResponse(onSuccess));
  };
}

export function resetLoadingFields() {
  return dispatch => dispatch({ type: constants.RESET_LOADING_CUSTOM_FIELDS });
}

export function updatePosition({ destination, source, isEnabled, availableIn }) {
  return (dispatch, getState) => {
    dispatch({ type: constants.UPDATE_POSITION });

    const { allCustomFields } = getState().customFields;
    const updatedCustomFields = reorderCustomFields({
      customFields: allCustomFields,
      destination,
      source,
    });

    dispatch({
      type: constants.UPDATE_POSITION_SYNC_DONE,
      payload: updatedCustomFields,
    });

    const url = '/api_web/custom_fields/fields/sort';
    const data = transformPositionsForBE({ isEnabled, availableIn, updatedCustomFields });
    const ajax = $.post(url, data);

    const onSuccess = () => dispatch({ type: constants.UPDATE_POSITION_DONE });

    const onFailure = (err) => {
      dispatch({
        type: constants.UPDATE_POSITION_FAILURE,
        payload: allCustomFields,
      });
      Notifications.customFieldFailed();

      throw err;
    };

    Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}
