import { map, omitBy, isNil, includes, filter } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getValue } from 'utils/transformers/advancedSearch';

import { PER_PAGE } from '../constants';
import { POST_TYPES, MEDIA_TWEET } from './constants';

function transformUserEntity(entity) {
  if (!entity) {
    return null;
  }

  const {
    id,
    party_slug,
    party_short,
    chamber_slug,
    state_alpha2,
    full_name,
    universal_moniker,
    profile_photo,
  } = entity;

  return {
    ...getValue('id', id),
    ...getValue('partySlug', party_slug),
    ...getValue('partyShort', party_short),
    ...getValue('chamberSlug', chamber_slug),
    ...getValue('stateAlpha2', state_alpha2),
    ...getValue('fullName', full_name),
    ...getValue('universalMoniker', universal_moniker),
    ...getValue('profilePhoto', profile_photo),
  };
}

function transformPostUser(user) {
  const {
    account_id,
    account_screen_name,
    profile_photo,
    entity_type,
    entity_id,
    entity,
    id,
    name,
    lists,
  } = user || {};

  return {
    ...getValue('id', id),
    ...getValue('name', name),
    ...getValue('lists', lists),
    ...getValue('accountId', account_id),
    ...getValue('accountScreenName', account_screen_name),
    ...getValue('profilePhoto', profile_photo),
    ...getValue('entityType', entity_type),
    ...getValue('entityId', entity_id),
    entity: transformUserEntity(entity),
  };
}

export function transformRequestData({ searchValue, options, page, ids }) {
  const {
    date: { startDate, endDate } = {},
    state,
    name,
    list,
    // party,
    // chamber,
    caucus,
    committee,
    tags,
    type,
  } = options || {};

  return {
    per_page: PER_PAGE,
    ...getValue('page', page),
    ...getValue('search_form', {
      ...getValue('keyword', searchValue),
      ...getValue('posted_after', startDate),
      ...getValue('posted_before', endDate),
      ...getValue('state_alpha2s', map(state, 'value')),
      ...getValue('user_names', map(name, 'value')),
      ...getValue('list_ids', map(list, 'value')),
      // ...getValue('party_slugs', party),
      // ...getValue('chamber_slugs', chamber),
      ...getValue('caucus_ids', map(caucus, 'value')),
      ...getValue('committee_ids', map(committee, 'value')),
      ...getValue('external_ids', ids),
      ...getValue('tag_ids', map(tags, 'value')),
      ...getValue('post_types', filter(type, postType => postType !== MEDIA_TWEET)),
      ...(includes(type, MEDIA_TWEET) && { include_media: true }),
    }),
  };
}

function transformMedia(media = []) {
  return map(media, ({ type, photo_url, preview_url, video_url }) => ({
    type,
    ...getValue('photoUrl', photo_url),
    ...getValue('previewUrl', preview_url),
    ...getValue('videoUrl', video_url),
  }));
}

function transformUserInfo({ screen_name, name, profile_image_url }) {
  return {
    ...getValue('name', name),
    ...getValue('screenName', screen_name),
    ...getValue('profileImageUrl', profile_image_url),
  };
}

function transformCommonProps({ media, body, id, user_info }) {
  return {
    body,
    id: Number(id),
    media: transformMedia(media),
    userInfo: transformUserInfo(user_info),
  };
}

function transformReplyTweet({ reply_to: replyTo }) {
  const { user_screen_name, tweet_id } = replyTo || {};

  return {
    replyTo: {
      userScreenName: user_screen_name,
      tweetId: tweet_id,
    },
  };
}

function transformQuotedTweet(quotedTweet = {}) {
  const { tweet_id: tweetId, tweet_url: tweetUrl } = quotedTweet;

  return { tweetId, tweetUrl };
}

function transformQuoteTweet({ quote_tweet: quoteTweet, quoted_tweet: quotedTweet }) {
  return {
    ...(!isNil(quoteTweet) && { quoteTweet: transformCommonProps(quoteTweet) }),
    ...(!isNil(quotedTweet) && { quotedTweet: transformQuotedTweet(quotedTweet) }),
  };
}

function transformRetweet({
  retweet = {},
  ...restProps
}) {
  const { quote_tweet } = retweet;

  return {
    retweet: transformCommonProps(retweet),
    ...transformQuoteTweet({ ...restProps, quote_tweet }),
  };
}

export function transformPost({
  external_token,
  posted_at,
  social_network,
  external_link,
  url,
  user,
  id,
  body,
  token,
  media,
  post_type,
  ...restProps
}) {
  const type = post_type || POST_TYPES.TWEET;
  const { [type]: postTransformer } = {
    [POST_TYPES.REPLY_TWEET]: transformReplyTweet,
    [POST_TYPES.QUOTE_TWEET]: transformQuoteTweet,
    [POST_TYPES.RETWEET]: transformRetweet,
    [POST_TYPES.QUOTE_RETWEET]: transformRetweet,
  };

  return {
    type,
    id: Number(id),
    recordId: id,
    user: transformPostUser(user),
    documentType: DOCUMENT_TYPES.SOCIAL_MEDIA_POST,
    tags: [],
    ...getValue('externalToken', external_token),
    ...getValue('postedAt', posted_at),
    ...getValue('socialNetwork', social_network),
    ...getValue('externalLink', external_link || url),
    ...getValue('body', body),
    ...getValue('token', token),
    ...getValue('media', transformMedia(media)),
    ...(postTransformer && postTransformer(restProps)),
  };
}

export function transformPosts(posts) {
  return map(posts, transformPost);
}

export function transformOptionsForBE({ options }) {
  const {
    date: { startDate, endDate } = {},
    state,
    name,
    list,
    // party,
    // chamber,
    caucus,
    committee,
    tags,
    type,
  } = options || {};

  return omitBy({
    ...getValue('date_from', startDate),
    ...getValue('date_to', endDate),
    ...getValue('states', state),
    ...getValue('names', name),
    ...getValue('lists', list),
    // ...getValue('parties', party),
    // ...getValue('chambers', chamber),
    ...getValue('caucuses', caucus),
    ...getValue('committees', committee),
    ...getValue('tags', tags),
    ...getValue('post_types', type),
  }, isNil);
}

export function transformOptionsForUI({ searchData }) {
  const { search_params: searchParams } = searchData || {};
  const {
    date_from,
    date_to,
    states,
    names,
    lists,
    // parties,
    // chambers,
    caucuses,
    committees,
    tags,
    post_types,
  } = searchParams || {};

  return omitBy({
    ...getValue('date', {
      ...getValue('startDate', date_from),
      ...getValue('endDate', date_to),
    }),
    ...getValue('state', states),
    ...getValue('name', names),
    ...getValue('list', lists),
    // ...getValue('party', parties),
    // ...getValue('chamber', chambers),
    ...getValue('caucus', caucuses),
    ...getValue('committee', committees),
    ...getValue('tags', tags),
    ...getValue('type', post_types),
  }, isNil);
}
