import { isNil, omitBy } from 'lodash';

import { PAGE_SIZE } from 'components/core/Profile/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import { transformBillsForBE, transformBillsForUI } from '../transformers';
import { getBillsUrl } from '../utils';
import constants from '../constants';
import { fetchSubscriptions } from './subscriptions';
import { fetchTags } from './tags';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchBills(payload = {}, customTransformers = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILLS });
    const {
      transformForBE = transformBillsForBE,
      transformForUI = transformBillsForUI,
    } = customTransformers;
    const { id, sort, direction, page, slug, documentType, itemType } = payload;
    const sortData = omitBy({ sort, direction, page }, isNil);
    const billsData = transformForBE(payload);

    const { url, method } = getBillsUrl(slug, id);
    const ajax = $.ajax({
      url,
      method: method || 'POST',
      data: { ...billsData, ...sortData, ...PER_PAGE },
    });

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_BILLS_DONE,
        payload: {
          ...transformForUI({ billsData: response, cosponsorsCount: 10, documentType, itemType }),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(payload),
        },
      });
    }

    function onFailure(error) {
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(({ payload: { allItems } }) => dispatch(fetchTags({ payload: allItems }, documentType)))
      .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
      .then(payloadData => dispatch(fetchSubscriptions(payloadData, documentType)))
      .catch(onFailure);
  };
}

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
