import constants from '../constants';
import {
  transformCommitteeMembersForUI,
  transformSubcommitteesForUI,
} from '../utils';

function fetchMembers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEE_MEMBERS });
    const { id } = payload;

    const url = `/api_web/federal/committees/${id}/members`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_COMMITTEE_MEMBERS_DONE,
        payload: transformCommitteeMembersForUI(response.data),
      }));
  };
}

function fetchSubcommittees(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_SUBCOMMITTEES });
    const { id } = payload;

    const url = `/api_web/federal/committees/${id}/subcommittees`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_SUBCOMMITTEES_DONE,
        payload: transformSubcommitteesForUI(response.data),
      }));
  };
}

export function loadOverview(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_OVERVIEW });

    const asyncRequests = [
      dispatch(fetchMembers(payload)),
      dispatch(fetchSubcommittees(payload)),
    ];

    return Promise.all(asyncRequests)
      .then(() => dispatch({ type: constants.LOAD_OVERVIEW_DONE }));
  };
}
