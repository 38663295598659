import { isEmpty, omitBy, pickBy, identity, map } from 'lodash';
import moment from 'moment/moment';

import { CSV_ID, EXPORT_EMAIL_LIMIT } from 'utils/constants/export';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { FULL_DATE_FORMAT } from 'utils/constants/date';
import { submitExportReport } from 'redux/exportReport/actions';

import { transformAgencyStaffer } from '../utils/transformers';
import { getExportInfo, getExportSortInfo } from '../utils';
import constants from '../constants';
import { getAgencyStaffersData } from './data';

export function submitExport(payload = {}) {
  const { items, searchData, exportOption, sort } = payload;
  const exportIds = items && items.map(item => item.id);
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: constants.STRATEGY_SLUG_FOR_EXPORT,
    'strategy_inputs[ids]': exportIds,
    'strategy_inputs[file_extension]': exportOption || CSV_ID,
    ...omitBy(getExportInfo(searchForm), isEmpty),
    ...pickBy(getExportSortInfo(sort), identity),
  };

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `Federal Agency Staffers ${moment().format(FULL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({
    ...payload,
    data,
    onSuccessCustom,
  });
}

export function getAllEmails(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.FETCH_AGENCY_STAFFERS_EMAILS });

    const { appliedFilters } = getState().federal.agencyStaffersLookup.appliedFilters;

    const { url, data } = getAgencyStaffersData({ ...payload, searchData: appliedFilters });
    const ajax = $.post(url, { ...data, per_page: EXPORT_EMAIL_LIMIT });

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_AGENCY_STAFFERS_EMAILS_DONE,
        payload: map(response.data, transformAgencyStaffer),
      });
    }

    return Promise.resolve(ajax).then(onSuccess);
  };
}
