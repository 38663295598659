const PREFIX = 'COMMITTEES';
export const API_URL = '/api_web/state/committees/lookup';
export const STRATEGY_SLUG = 'state_committee';
export const STRATEGY_SLUG_FOR_EXPORT = 'state_committees';

const constants = {
  FETCH_COMMITTEES: `${PREFIX}/FETCH_COMMITTEES`,
  RECEIVE_COMMITTEES: `${PREFIX}/RECEIVE_COMMITTEES`,
  RESET_COMMITTEES: `${PREFIX}/RESET_COMMITTEES`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  BULK_UPDATE_DATA: `${PREFIX}/BULK_UPDATE_DATA`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  BULK_TRACK_DATA: `${PREFIX}/BULK_TRACK_DATA`,
  BULK_TRACK_DATA_DONE: `${PREFIX}/BULK_TRACK_DATA_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  BULK_SHARE_TO_MY_FEED: `${PREFIX}/BULK_SHARE_TO_MY_FEED`,
  BULK_SHARE_TO_MY_FEED_DONE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_DONE`,
  BULK_SHARE_TO_MY_FEED_FAILURE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_FAILURE`,
  COMMITTEE_LOOKUP_NAME: 'committees',
};

export default constants;
