export function transformTrackedItemForBE(payload) {
  const {
    frequency,
    isSaved,
    priority,
    modelId,
    token,
    sendWeekAheadEmail,
    withWeekAheadEmail,
  } = payload || {};

  return {
    frequency,
    priority,
    is_saved: isSaved,
    token: modelId || token,
    ...(withWeekAheadEmail && { send_week_ahead_email: sendWeekAheadEmail }),
  };
}
