import moment from 'moment';
import { filter, isEmpty, lowerCase, map, omit, pick, uniqBy } from 'lodash';

import { handleUpdateAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { downloadBlob } from 'utils/fileExport';

import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import Notifications from 'components/Moneyball/utils/Notifications';
import { currencyFormat } from 'components/Moneyball/utils';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

export function getAmountValue(amount = {}) {
  const { start, end } = amount;

  if (isEmpty(start) && isEmpty(end)) return null;
  if (!start) return `<${currencyFormat(end)}`;
  if (!end) return `>${currencyFormat(start)}`;

  return `${currencyFormat(start)}-${currencyFormat(end)}`;
}

export function getDateValue(date = {}) {
  const { start, end } = date;

  if (isEmpty(start) && isEmpty(end)) return null;

  const formattedStartDate = moment(start).format(GLOBAL_DATE_FORMAT);
  const formattedEndDate = moment(end).format(GLOBAL_DATE_FORMAT);

  if (!start) return `to ${formattedEndDate}`;
  if (!end) return `from ${formattedStartDate}`;

  return `${formattedStartDate}-${formattedEndDate}`;
}

export function transformSort(props) {
  const {
    sort,
    direction,
    sort_column,
    sort_direction,
  } = props || {};

  const sortParam = sort || sort_column;
  const directionParam = direction || sort_direction;

  if (isEmpty(sortParam) || isEmpty(directionParam)) {
    return { toReset: true };
  }

  return {
    sort: sortParam,
    direction: directionParam,
    toReset: false,
  };
}

export function transformPaginator({ pagination }) {
  const { is_estimated, page_number, total_count, page_size, total_pages } = pagination || {};

  return {
    isEstimated: is_estimated,
    perPage: page_size,
    current: page_number,
    totalItems: total_count,
    total: total_pages,
  };
}

export function isSearchPayload({ searchData, clearAll }) {
  return Boolean(!isEmpty(searchData) || clearAll);
}

export function fetchSearch({
  url,
  payload,
  actionType,
  transformSearchToQuery,
  transformAdvancedSearchToQuery,
  transformResponsePayload,
  saveRecentAdvancedSearch,
}) {
  return (dispatch) => {
    const {
      searchData = {},
      appliedFilters,
      clearAll,
      sort,
      sort_column,
      sort_direction,
      direction,
      page,
      perPage,
      ...restParams
    } = payload;
    const isSearch = isSearchPayload(payload);
    const searchForm = isSearch ? searchData : appliedFilters;
    const lookupForm = {
      page_size: 100,
      ...pick(appliedFilters, ['sort_direction', 'sort_column', 'page_size', 'page']),
      ...((sort_direction || direction) && { sort_direction: sort_direction || direction }),
      ...((sort_column || sort) && { sort_column: sort_column || sort }),
      ...(perPage && { page_size: perPage }),
      ...(page && { page }),
    };

    /**
     * @TODO: Remove this omit and replace by object destructuring that picks up
     * only attributes needed by this function. The following pattern causes the
     * search to break if we send new parameters to search function in PageLookup.
     */
    const data = JSON.stringify({
      ...omit(restParams, [
        'cluster',
        'searchParams',
        'receiptId',
        'searchType',
        'dataProps',
        'isClickedItem',
        'isAdvancedSearchOpen',
        'isSearchClick',
        'isSortColumn',
      ]),
      ...transformSearchToQuery(searchForm),
      ...lookupForm,
    });

    const ajax = $.ajax({
      url,
      data,
      type: 'POST',
      contentType: REQUEST_CONTENT_TYPE,
    });

    function onSuccess(response) {
      return dispatch({
        error: false,
        type: actionType,
        toResetSelectedItems: isSearch,
        payload: transformResponsePayload(response.items),
        paginator: transformPaginator(response),
        sort: transformSort(payload),
      });
    }

    function onFailure(error) {
      dispatch({
        error: true,
        type: actionType,
        toResetSelectedItems: isSearch,
        payload: [],
        paginator: {},
        sort: {
          toReset: isSearch,
        },
      });

      Notifications.defaultFetchDataFailed();
      throw error;
    }

    function saveAppliedFiltersToProps() {
      !clearAll
        ? dispatch(handleUpdateAppliedFilters({ ...searchForm, ...lookupForm }))
        : dispatch(resetAppliedFilters());
    }

    function saveAdvancedSearchQueries() {
      if (transformAdvancedSearchToQuery && saveRecentAdvancedSearch) {
        const advancedSearchData = transformAdvancedSearchToQuery(searchData);
        if (!isEmpty(advancedSearchData)) dispatch(saveRecentAdvancedSearch(advancedSearchData));
      }
    }

    function onFinally() {
      saveAppliedFiltersToProps();
      saveAdvancedSearchQueries();
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure)
      .finally(onFinally);
  };
}

export function fetchSearchKeyDetails({
  url,
  payload,
  actionType,
  contentType,
  transformResponse,
  type = 'GET',
}) {
  return (dispatch) => {
    const ajax = $.ajax({ url, type, contentType, data: payload });

    function onSuccess(response) {
      return dispatch({
        type: actionType,
        error: false,
        payload: transformResponse(response),
      });
    }

    function onFailure(error) {
      dispatch({
        type: actionType,
        error: true,
        payload: {},
      });

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchSearchReceipt({
  url,
  actionType,
  transformResponse,
}) {
  return (dispatch) => {
    const ajax = $.get(url);

    function onSuccess(response) {
      return dispatch({
        error: false,
        type: actionType,
        payload: transformResponse(response),
      });
    }

    function onFailure(error) {
      dispatch({
        error: true,
        type: actionType,
        payload: {},
      });

      Notifications.defaultFetchDataFailed();
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function onSetElectionCycles(cycles, type) {
  return dispatch => (
    dispatch({
      payload: cycles,
      type,
    })
  );
}

export function onSelectElectionCycle(selectedCycle, type) {
  return dispatch => (
    dispatch({
      payload: selectedCycle,
      type,
    })
  );
}

export function exportBlob(fileName, data) {
  const blob = new Blob([data], { type: 'octet/stream' });

  downloadBlob(blob, fileName);
}

export function fetchReport({ url, data, contentType, method = 'POST', customFileName }) {
  function parseFileName(contentDisposition) {
    return contentDisposition && contentDisposition.split('filename=')[1];
  }

  function handleOnSuccess({ fileName, blob }) {
    exportBlob(customFileName || fileName, blob);
  }

  const request = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('Content-type', contentType);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const contentDisposition = xhr.getResponseHeader('Content-Disposition');
      const fileName = parseFileName(contentDisposition);

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({ fileName, blob: xhr.response });
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(data);
  });

  return Promise.resolve(request).then(handleOnSuccess);
}

export function getUniqueValues(prop, originalValue, values) {
  const mappedData = map(values, prop);
  const filteredData = filter(mappedData, Boolean);
  const changedValues = filter(filteredData, item => lowerCase(item) !== lowerCase(originalValue));
  const uniqueValues = uniqBy(changedValues, lowerCase);

  if (isEmpty(originalValue)) {
    const [firstValue, ...restValues] = uniqueValues;

    return {
      value: firstValue,
      info: restValues,
    };
  }

  return {
    value: originalValue,
    info: uniqueValues,
  };
}

export function slugify(data) {
  return data && data.toLowerCase()
    .replace(/[.,]/g, '') // remove dots and comma
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes and replace by -
}
