import { size, map } from 'lodash';

import {
  notifyAddTags,
  notifyAddTagsMultiple,
} from 'components/Dashboard/common/UserNotifications';
import notifications from 'components/Dashboard/utils/notifications';
import {
  getFilteredTags,
  transformTagsForBE,
  saveTagsAndUpdateRecords,
} from 'redux/tags/utils';
import { NEWS_STORY_ITEM_TYPE } from 'utils/constants/itemTypes';

import constants, { BASE_TAG_URL } from '../constants';
import { transformTags, transformTag } from '../utils';

export function fetchTags(ids) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_TAGS });

    const url = `${BASE_TAG_URL}/lookup`;
    const data = { record_ids: ids };
    const ajax = $.post(url, data);

    function onSuccess(response) {
      dispatch({
        type: constants.RECEIVE_TAGS_DONE,
        payload: transformTags(response),
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_TAGS_FAILURE });

      notifications.generalFailedNotification();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function bulkSaveTags({ tags, ids }) {
  return (dispatch) => {
    dispatch({ type: constants.BULK_SAVE_TAGS });

    const { newTags, existingTags } = getFilteredTags(tags);
    const url = `${BASE_TAG_URL}/bulk_create`;

    function saveTagsForRecord(responseNewTags) {
      const data = {
        story_ids: ids,
        tag_ids: transformTagsForBE(existingTags, responseNewTags),
      };

      const ajax = $.post(url, data);

      function onSuccess() {
        dispatch({ type: constants.BULK_SAVE_TAGS_DONE });

        const itemsLength = size(ids);

        itemsLength === 1
          ? notifyAddTags({ itemType: NEWS_STORY_ITEM_TYPE })
          : notifyAddTagsMultiple(itemsLength);
      }

      function onFailure(error) {
        dispatch({ type: constants.BULK_SAVE_TAGS_FAILURE });

        notifications.generalFailedNotification();

        throw error;
      }

      return Promise.resolve(ajax)
        .then(() => dispatch(fetchTags(ids)))
        .then(onSuccess)
        .catch(onFailure);
    }

    return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
  };
}

export function updateTags({ tags, id }) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_TAGS });

    const { newTags, existingTags } = getFilteredTags(tags);
    const url = `${BASE_TAG_URL}/update/${id}`;

    function saveTagsForRecord(responseNewTags) {
      const data = { tag_ids: transformTagsForBE(existingTags, responseNewTags) };
      const ajax = $.ajax({
        method: 'PUT',
        data,
        url,
      });

      function onSuccess(response) {
        const { data: responseTags } = response || {};

        dispatch({
          type: constants.UPDATE_TAGS_DONE,
          payload: { recordId: id, tags: map(responseTags, transformTag) },
        });

        notifyAddTags({ itemType: NEWS_STORY_ITEM_TYPE });
      }

      function onFailure(error) {
        dispatch({ type: constants.BULK_SAVE_TAGS_FAILURE });

        notifications.generalFailedNotification();

        throw error;
      }

      return Promise.resolve(ajax)
        .then(onSuccess)
        .catch(onFailure);
    }

    return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
  };
}
