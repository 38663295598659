import { isEmpty, orderBy } from 'lodash';

import isNotFoundError from 'utils/http/isNotFoundError';
import { sortStafferByColumn as sortStaffer } from 'redux/staffers/common/actions';
import { fetchProfileTags } from 'redux/tags/actions';

import constants from './constants';
import { transformStaffMembers, transformStaffersData } from './utils';

function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function fetchStafferData(payload = {}, getStafferProfileFailed) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFER_DATA });

    const { id } = payload;
    const url = `/api_web/rolodex/staffers/${id}`;
    const ajax = $.get(url);

    const onSuccess = response => dispatch({
      type: constants.FETCH_STAFFER_DATA_DONE,
      payload: transformStaffersData(response),
      isLegislator: !isEmpty(response.position.legislator),
    });

    const onError = (error) => {
      getStafferProfileFailed && getStafferProfileFailed();

      isNotFoundError(error) && dispatch({ type: constants.PROFILE_NOT_FOUND });

      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(() => dispatch(fetchTags(payload)))
      .catch(onError);
  };
}

export function fetchCommitteeOffice(id, getCommitteeFailed) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEE_OFFICE_DATA });

    const url = `/api_web/rolodex/committees/${id}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.FETCH_COMMITTEE_OFFICE_DATA_DONE,
        payload: transformStaffMembers(response),
      }))
      .catch((error) => {
        getCommitteeFailed && getCommitteeFailed();
        throw error;
      });
  };
}

export function fetchLegislator(id, getLegislatorFailed) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LEGISLATOR_DATA });

    const url = `/api_web/rolodex/legislators/${id}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.FETCH_LEGISLATOR_DATA_DONE,
        payload: transformStaffMembers(response),
      }))
      .catch((error) => {
        getLegislatorFailed && getLegislatorFailed();
        throw error;
      });
  };
}

export function sortStafferByColumn(stafferKey, columnName, sortDirection) {
  return (dispatch, getState) => {
    const { stafferData } = getState().rolodex.stafferProfile;

    return dispatch(sortStaffer({
      stafferKey,
      columnName,
      sortDirection,
      stafferData,
      actionTypes: constants,
    }));
  };
}

export function sortCommitteeOfficeByColumn(committeeKey, columnName, sortDirection) {
  return (dispatch, getState) => {
    dispatch({ type: constants.SORT_STAFFER_DATA });
    const { committeeOffice } = getState().rolodex.stafferProfile;

    const sortedCommitteeOfficeData = {
      ...committeeOffice,
      [committeeKey]: orderBy(
        committeeOffice[committeeKey],
        [columnName],
        [sortDirection]
      ),
    };

    return dispatch({
      type: constants.SORT_STAFFER_DATA_DONE,
      committeeOffice: sortedCommitteeOfficeData,
    });
  };
}

export function sortLegislatorByColumn(legislatorKey, columnName, sortDirection) {
  return (dispatch, getState) => {
    dispatch({ type: constants.SORT_STAFFER_DATA });
    const { legislator } = getState().rolodex.stafferProfile;

    const sortedLegislatorData = {
      ...legislator,
      [legislatorKey]: orderBy(
        legislator[legislatorKey],
        [columnName],
        [sortDirection]
      ),
    };

    return dispatch({
      type: constants.SORT_STAFFER_DATA_DONE,
      legislator: sortedLegislatorData,
    });
  };
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}
