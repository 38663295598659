import { omitBy, isNil, get, map } from 'lodash';

import { getValue } from 'utils/transformers/advancedSearch';
import { camelizeKeys } from 'utils/object';

export function transformOptionsForBE(options) {
  const {
    name,
    createdAt,
    updatedAt,
    subscribers,
    subscriptionStatus,
  } = options || {};

  return omitBy({
    ...getValue('tokens', map(name, 'value')),
    ...getValue('created_from', get(createdAt, 'startDate')),
    ...getValue('created_to', get(createdAt, 'endDate')),
    ...getValue('updated_from', get(updatedAt, 'startDate')),
    ...getValue('updated_to', get(updatedAt, 'endDate')),
    ...getValue('subscribed_user_ids', map(subscribers, 'value')),
    ...getValue('subscription_status', subscriptionStatus),
  }, isNil);
}

export function buildSearchFormForBE({ options, page }) {
  const searchForm = transformOptionsForBE(options);

  return { search_form: searchForm, page };
}

export function transformOptionsForUI({ searchData }) {
  const { search_params: searchParams } = searchData || {};

  return camelizeKeys(searchParams);
}

const transformUserForUI = ({ id, fullName, email, profileImg }) => ({
  id,
  name: fullName,
  email,
  photoUrl: profileImg,
});

export function transformWorkspaceForUI(workspace) {
  const { subscription } = workspace;
  const { users } = subscription || {};

  return {
    ...workspace,
    subscription: {
      ...subscription,
      users: map(users, transformUserForUI),
    },
  };
}

export const transformWorkspacesForUI = ({ data, pagination }) => ({
  feed: map(data, transformWorkspaceForUI),
  pagination,
});
