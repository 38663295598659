import isEmpty from 'lodash/isEmpty';

import { COMMITTEE_TYPE, LEGISLATOR_TYPE } from 'utils/constants';

export function transformOfficeForBE(offices, isExport = false) {
  if (isEmpty(offices)) {
    return null;
  }

  const legislator_ids = [];
  const committee_ids = [];

  offices.forEach(({ type, value }) => {
    type === LEGISLATOR_TYPE && legislator_ids.push(value);
    type === COMMITTEE_TYPE && committee_ids.push(value);
  });

  if (isExport) {
    return {
      'strategy_inputs[office][legislator_ids]': legislator_ids,
      'strategy_inputs[office][committee_ids]': committee_ids,
    };
  }

  return {
    legislator_ids,
    committee_ids,
  };
}
