import { partial } from 'lodash';

import { responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { ROW_PLACEHOLDER as PAGE_SIZE } from 'components/Federal/CommitteeProfile/utils';
import { getCommitteeAction } from 'redux/dashboardV2/feedPage/actions';
import {
  fetchProfileCommitteeSubscriptions,
  updateCommitteeSubscriptions,
} from 'redux/subscriptions/actions';
import { fetchProfileDocument, updateProfileShare } from 'redux/events/actions';
import { transformFetchRecordIdsForBE } from 'redux/tags/utils';
import { fetchProfileTags } from 'redux/tags/actions';

import constants from '../constants';
import { transformProfileForUI } from '../utils';

export { fetchStaffers } from './staffers';
export { loadOverview } from './overview';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}

export function fetchSubscriptions(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_SUBSCRIPTIONS });

    const { id, documentType: defaultDocumentType } = payload;

    const data = transformFetchRecordIdsForBE([
      { documentType: responseTrackingTypes.FEDERAL_COMMITTEE_HEARING, id },
      { documentType: responseTrackingTypes.FEDERAL_COMMITTEE_STAFFER, id },
    ]);

    return dispatch(
      fetchProfileCommitteeSubscriptions({
        data,
        defaultDocumentType,
        actionTypes: constants,
      })
    );
  };
}

export function updateSubscriptions(payload) {
  return dispatch => dispatch(
    updateCommitteeSubscriptions({
      isProfile: true,
      action: partial(getCommitteeAction, partial.placeholder, true),
      actionTypes: constants,
      payload,
    })
  );
}

export function onUpdateTrackings({ trackings }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: trackings,
  });
}

export function fetchCommittee(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEE });
    const { id } = payload;

    const url = `/api_web/federal/committees/${id}`;
    const ajax = $.get(url);

    const onSuccess = (response) => {
      dispatch({
        type: constants.RECEIVE_COMMITTEE_DONE,
        payload: transformProfileForUI(response.data),
      });
    };

    const onFailure = (error) => {
      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(() => dispatch(fetchTags(payload)))
      .then(() => dispatch(fetchProfileDocument(payload, constants)))
      .then(() => dispatch(fetchSubscriptions(payload)))
      .catch(onFailure);
  };
}

export function resetCommitteeData() {
  return dispatch => dispatch({ type: constants.RESET_COMMITTEE });
}

export function updateProfileMyFeed() {
  return (dispatch) => {
    dispatch(updateProfileShare(constants));
  };
}
