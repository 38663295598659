import { combineReducers } from 'redux';

import { initialValues } from 'components/Dashboard/KeywordSearch/utils/constants';

import defaultSubscription from '../common/defaultSubscription/reducers';
import constants from './constants';

export const defaultState = {
  searchKeyword: initialValues,
  ui: {
    isLoadingKeyword: true,
    isLoadingSaving: false,
    isFetchError: false,
  },
};

function keywordSearch(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_KEYWORD:
      return {
        ...state,
        ui: { ...state.ui, isLoadingKeyword: true },
      };
    case constants.RECEIVE_KEYWORD_SUCCESS:
      return {
        ...state,
        ui: { ...state.ui, isFetchError: false, isLoadingKeyword: false },
        searchKeyword: action.payload,
      };
    case constants.RECEIVE_KEYWORD_FAILED:
      return {
        ...state,
        ui: { ...state.ui, isFetchError: true, isLoadingKeyword: false },
        searchKeyword: action.payload,
      };
    case constants.SAVE_KEYWORD:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSaving: true },
      };
    case constants.SAVE_KEYWORD_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSaving: false },
      };
    case constants.RESET_KEYWORD_FORM:
      return {
        ...defaultState,
        ui: { ...defaultState.ui, isLoadingKeyword: action.isLoadingKeyword },
      };
    default:
      return state;
  }
}

export default combineReducers({
  keywordSearch,
  defaultSubscription,
});
