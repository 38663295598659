import { isEmpty } from 'lodash';
import moment from 'moment/moment';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import {
  MONTH_SHORT_DAY_YEAR_FORMAT,
  MONTH_DAY_YEAR_DATE_FORMAT,
} from 'utils/constants/date';
import { BILL_STATUS } from 'utils/constants/billStatus';
import { STATE_BILL_ITEM_TYPE } from 'utils/constants/itemTypes';

import { transformSponsorsAndCosponsorsForUI } from './sponsorsAndCosponsors';
import { transformHearings } from './hearings';
import { transformSasts } from './sast';

function transformCommittee(committee) {
  if (!isEmpty(committee)) {
    return { href: `/state/committees/${committee.id}`, value: committee.name };
  }
  return { href: null, value: 'Not in committee' };
}

export function transformSession(session) {
  if (!session) return null;

  const {
    name,
    helper_text: helperText,
    display_notes: notes,
    starts_on: startsOn,
    ends_on: endsOn,
    is_projected: isProjected,
  } = session;

  const projectedDate = isProjected && startsOn && endsOn
    ? `(Projected: ${moment(startsOn).format(MONTH_DAY_YEAR_DATE_FORMAT)} to ${moment(endsOn).format(MONTH_DAY_YEAR_DATE_FORMAT)})`
    : null;

  const numberOfdays = moment(endsOn).diff(moment(), 'days') + 1;
  const daysLeftInSession = numberOfdays > 0 ? `${numberOfdays} ${numberOfdays === 1 ? 'day' : 'days'} left` : null;

  const daysLeft = isProjected
    ? daysLeftInSession
    : 'Session adjourned';

  return {
    name,
    helperText,
    projectedDate,
    notes,
    daysLeft,
  };
}

export function transformKeyDetails(payload) {
  const {
    id,
    url,
    last_action = {},
    last_text_version = {},
    committee_hearings,
    session,
    status,
    description,
    display_id,
    bill_number,
    state,
    title,
    committee,
    introduced_on,
    sasts,
  } = payload;

  const { date: lastActionDate, action: lastAction } = last_action || {};
  const { alpha2, name: stateName } = state || {};

  const getBillText = () => {
    if (isEmpty(last_text_version)) {
      return {};
    }

    return {
      href: last_text_version.url,
      value: 'View',
      isExternal: true,
    };
  };

  const getStateBill = () => ({
    href: url,
    value: 'State bill website',
    isExternal: true,
  });

  const getFormattedDate = currentDate => (
    currentDate && moment(currentDate).format(MONTH_SHORT_DAY_YEAR_FORMAT)
  );

  return {
    id,
    documentType: DOCUMENT_TYPES.STATE_BILL,
    itemType: STATE_BILL_ITEM_TYPE,
    recordId: id,
    withTrack: true,
    keyDetails: {
      billName: title,
      displayBillId: display_id,
      billNumber: bill_number,
      billUrl: id && `/state/bills/${id}`,
      hearings: transformHearings(committee_hearings),
      state: { alpha2, name: stateName },
      billStatus: BILL_STATUS[status] || status,
      billText: getBillText(),
      committee: transformCommittee(committee),
      stateBill: getStateBill(),
      lastAction: {
        date: getFormattedDate(lastActionDate),
        action: lastAction,
      },
      session: transformSession(session),
      description,
      introducedOn: getFormattedDate(introduced_on),
      sasts: transformSasts(sasts),
    },
  };
}

export function transformProfileForUI(responses) {
  const [
    keyDetails,
    sponsorsAndCosponsors,
  ] = responses;

  return {
    ...transformKeyDetails(keyDetails),
    ...transformSponsorsAndCosponsorsForUI(sponsorsAndCosponsors),
  };
}
