import constants from './constants';

export const defaultState = {
  appliedFilters: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.SET_APPLIED_FILTERS:
      return {
        ...state,
        appliedFilters: action.payload,
      };
    case constants.UPDATE_APPLIED_FILTERS:
      return {
        ...state,
        appliedFilters: {
          ...state.appliedFilters,
          ...action.payload,
        },
      };
    case constants.RESET_APPLIED_FILTERS:
      return {
        ...state,
        appliedFilters: {},
      };
    default:
      return state;
  }
}
