import constants from './constants';

export const defaultState = {
  ui: {
    isLoadingExports: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.SUBMIT_EXPORT_REPORT_LOADING:
      return {
        ...state,
        ui: { ...state.ui, isLoadingExports: true },
      };
    case constants.SUBMIT_EXPORT_REPORT_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingExports: false },
      };
    default:
      return state;
  }
}
