import constants from './constants';

const DEFAULT_STATE = {
  person: {},
  tags: [],
  ui: {
    isLoadingProfile: true,
    isLoadingTags: true,
    isProfileNotFound: false,
  },
};

function executiveProfile(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_EXECUTIVE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingProfile: true },
      };
    case constants.RECEIVE_EXECUTIVE_DONE:
      return {
        ...state,
        person: action.payload,
        ui: { ...state.ui, isLoadingProfile: false },
      };
    case constants.PROFILE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, isProfileNotFound: true },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    default:
      return state;
  }
}

export default executiveProfile;
