import { isArray, filter, find, map, includes, toString, isNil } from 'lodash';

function isSameRecord(recordId, subscriptionRecordId) {
  return toString(recordId) === toString(subscriptionRecordId);
}

function findFederalCommitteeSubscription({ itemSubscription, recordId, trackRecordType }) {
  const {
    recordId: subscriptionRecordId,
    trackRecordType: subscriptionType,
  } = itemSubscription || {};

  return isSameRecord(recordId, subscriptionRecordId) && includes(trackRecordType, subscriptionType);
}

function belongsToItem({ itemSubscription, recordId, trackRecordType, searchId }) {
  const {
    recordId: subscriptionRecordId,
    trackRecordType: subscriptionType,
    searchId: subscriptionSearchId,
  } = itemSubscription || {};
  const isSameSearch = !isNil(searchId) && toString(searchId) === toString(subscriptionSearchId);

  return (isSameRecord(recordId, subscriptionRecordId) || isSameSearch)
        && trackRecordType === subscriptionType;
}

function getSubscriptions({ recordId, trackRecordType, subscriptions, searchId }) {
  if (isArray(trackRecordType)) {
    const listSubscriptions = filter(subscriptions, itemSubscription => findFederalCommitteeSubscription({
      itemSubscription,
      recordId,
      trackRecordType,
    }));

    return { listSubscriptions };
  }

  return find(subscriptions, itemSubscription => belongsToItem({
    itemSubscription,
    recordId,
    trackRecordType,
    searchId,
  })) || {};
}

function mergeTrackWithSubscriptions(data, subscriptions) {
  return map(data, (item) => {
    const { recordId, trackRecordType, searchId } = item || {};
    const formattedSubscriptions = getSubscriptions({
      recordId,
      trackRecordType,
      subscriptions,
      searchId,
    });

    return { ...item, ...formattedSubscriptions, isLoadingTracking: false };
  });
}

export function loadSubscriptions(state, action) {
  const { data } = state || {};

  return {
    ...state,
    data: mergeTrackWithSubscriptions(data, action.payload),
  };
}
