const PREFIX = 'STAKEHOLDER_MANAGEMENT';

const constants = {
  DELETE_STAKEHOLDERS: `${PREFIX}/DELETE_STAKEHOLDERS`,
  DELETE_STAKEHOLDERS_DONE: `${PREFIX}/DELETE_STAKEHOLDERS_DONE`,
  DELETE_STAKEHOLDER: `${PREFIX}/DELETE_STAKEHOLDER`,
  DELETE_STAKEHOLDER_DONE: `${PREFIX}/DELETE_STAKEHOLDER_DONE`,
  CREATE_STAKEHOLDER: `${PREFIX}/CREATE_STAKEHOLDER`,
  CREATE_STAKEHOLDER_FAIL: `${PREFIX}/CREATE_STAKEHOLDER_FAIL`,
  CREATE_STAKEHOLDER_DONE: `${PREFIX}/CREATE_STAKEHOLDER_DONE`,
  UPDATE_STAKEHOLDER: `${PREFIX}/UPDATE_STAKEHOLDER`,
  UPDATE_STAKEHOLDER_FAIL: `${PREFIX}/UPDATE_STAKEHOLDER_FAIL`,
  UPDATE_STAKEHOLDER_DONE: `${PREFIX}/UPDATE_STAKEHOLDER_DONE`,
};

export default constants;
