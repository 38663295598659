import { bulkSaveLookupTags, fetchLookupTags } from 'redux/tags/actions';
import constants from 'redux/local/lookup/constants';

export function fetchTags(payload = {}, documentType) {
  return dispatch => dispatch(fetchLookupTags(payload, documentType, constants));
}

export function bulkSaveTags(payload) {
  return dispatch => dispatch(bulkSaveLookupTags(payload, constants));
}
