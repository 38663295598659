import { submitExportReport } from 'redux/exportReport/actions';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { FULL_DATE_FORMAT } from 'utils/constants/date';
import moment from 'moment/moment';

import { transformFiltersForBE } from '../transformers';
import constants from '../constants';

export function submitExport(payload = {}) {
  const { cardIds, filters } = payload;

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `Activity Export - ${moment().format(FULL_DATE_FORMAT)}.csv`,
    );
  }

  const strategyInputs = JSON.stringify({
    ...transformFiltersForBE(filters),
    ids: cardIds,
  });

  const data = {
    strategy_slug: constants.EXPORT_STRATEGY_SLUG,
    strategy_inputs: strategyInputs,
  };

  return submitExportReport({ data, onSuccessCustom });
}
