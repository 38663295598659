import { omitBy, isEmpty } from 'lodash';

import { getBlob, downloadBlob } from 'utils/fileExport';

export function getExportSortData(sortData = {}) {
  const data = {
    'strategy_inputs[sort]': sortData.sort,
    'strategy_inputs[direction]': sortData.direction,
  };

  return omitBy(data, isEmpty);
}

export function exportCSVBlob(data, fileName) {
  const blob = (data instanceof Blob)
    ? data
    : getBlob(data);

  downloadBlob(blob, fileName);
}
