export function loadingUpdateItem(state, action) {
  const { isLoadingSubscriptions, isLoadingTags } = action || {};

  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingSubscriptions: isLoadingSubscriptions || false,
      isLoadingTags: isLoadingTags || false,
    },
  };
}
