import {
  FETCH_REGULATION,
  FETCH_LEGISLATORS,
  FETCH_CAMPAIGN,
  RECEIVE_CAMPAIGN,
  STOP_LOADING,
  TEMPORARY_SAVE_FORM_SUBMISSION,
  EDIT_FORM_SUBMISSION,
  RECEIVE_LEGISLATORS,
  RECEIVE_REGULATION,
  SAVE_CUSTOM_REGULATION_COMMENT,
  SAVE_CUSTOM_MESSAGE,
  SAVE_FORM_SUBMISSION,
  SAVE_MESSAGES,
  SET_PREVIEW_MODE,
  OPEN_MESSAGE_EDITOR,
  CLOSE_MESSAGE_EDITOR,
  RECEIVE_FORM_SUBMISSION,
  FETCH_CUSTOM_COLORS,
  RECEIVE_CUSTOM_COLORS,
  FETCH_CUSTOM_RECIPIENTS,
  RECEIVE_CUSTOM_RECIPIENTS,
  CREATE_RECIPIENTS,
} from './constants';

import defaultState from './defaultState';
import {
  legislatorToRecipient,
  customRecipientToRecipient,
} from './recipientBuilder';

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCH_REGULATION:
    case FETCH_LEGISLATORS:
    case FETCH_CAMPAIGN:
    case FETCH_CUSTOM_RECIPIENTS:
    case FETCH_CUSTOM_COLORS:
    case SAVE_MESSAGES:
    case SAVE_FORM_SUBMISSION:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case STOP_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case TEMPORARY_SAVE_FORM_SUBMISSION:
      return {
        ...state,
        formSubmission: action.payload,
      };
    case EDIT_FORM_SUBMISSION:
      return {
        ...state,
        formSubmission: {
          ...state.formSubmission,
          ...action.payload,
        },
      };
    case RECEIVE_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
      };
    case RECEIVE_LEGISLATORS:
      return {
        ...state,
        legislators: action.payload,
      };
    case RECEIVE_REGULATION:
      return {
        ...state,
        regulation: action.payload,
      };
    case RECEIVE_CUSTOM_RECIPIENTS:
      return {
        ...state,
        customRecipients: action.payload,
      };
    case CREATE_RECIPIENTS:
      return {
        ...state,
        recipients: [
          ...state.legislators.map(legislatorToRecipient),
          ...state.customRecipients.map(customRecipientToRecipient),
        ],
      };
    case SAVE_CUSTOM_REGULATION_COMMENT:
    case SAVE_CUSTOM_MESSAGE:
      return {
        ...state,
        formSubmission: {
          ...state.formSubmission,
          ...action.payload.formSubmission,
        },
      };
    case SET_PREVIEW_MODE:
      return {
        ...state,
        previewMode: true,
      };
    case OPEN_MESSAGE_EDITOR:
      return {
        ...state,
        ui: {
          ...state.ui,
          messageEditorsOpened: state.ui.messageEditorsOpened + 1,
        },
      };
    case CLOSE_MESSAGE_EDITOR:
      return {
        ...state,
        ui: {
          ...state.ui,
          messageEditorsOpened: state.ui.messageEditorsOpened - 1,
        },
      };

    case RECEIVE_FORM_SUBMISSION:
      return {
        ...state,
        formSubmission: {
          ...state.formSubmission,
          ...action.payload,
        },
      };
    case RECEIVE_CUSTOM_COLORS:
      return {
        ...state,
        customColors: {
          backgroundColor: action.payload.primary_color,
          color: action.payload.secondary_color,
        },
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    default:
      return state;
  }
}
