import compact from 'lodash/compact';

import {
  getValue,
  getLabelForUI,
  getCommitteeTypeValue,
  sourceSelectValueForBE,
  getContributionRecipientInfo,
  formatSelectValueToArrForBE,
  formatReceivedTypesForBE,
  formatSelectTypeToValForFE,
  getCommitteeTypeFilterValue,
  getElectionTypeFilterValue,
  formatTypesForSaveBE,
  formatAmounts,
  formatZipCode,
  formatDates,
  getPluralLabel,
} from 'redux/moneyball/utils/transformers';
import { getDateValue, getAmountValue } from 'redux/moneyball/utils';
import { getDisplayItem } from 'redux/advancedSearch/utils';

export function transformIndividual(individualItem = {}) {
  const {
    individuals,
    super_individual,
    super_individuals,
    ...restProps
  } = individualItem;

  const formattedSuperIndividual = super_individual && {
    super_individual: {
      ...super_individual,
      zip_code: formatZipCode(super_individual.zip_code),
    },
  };

  const formattedSuperIndividuals = super_individuals && {
    super_individuals: super_individuals.map(
      ({ super_individual: superIndividualItem, ...superIndividualsProps }) => ({
        ...superIndividualsProps,
        super_individual: {
          ...superIndividualItem,
          zip_code: formatZipCode(superIndividualItem.zip_code),
        },
      }),
    ),
  };

  const formattedIndividuals = individuals && {
    individuals: individuals.map(
      ({ individual, ...individualsProps }) => ({
        ...individualsProps,
        individual: {
          ...individual,
          zip_code: formatZipCode(individual.zip_code),
        },
      }),
    ),
  };

  return {
    ...restProps,
    ...formattedIndividuals,
    ...formattedSuperIndividual,
    ...formattedSuperIndividuals,
  };
}

export function transformContributions(contributions = []) {
  return contributions.map(({
    receipt,
    super_committee_recipient,
    super_committee_conduit,
    ...restProps
  }) => {
    const recipientInfo = getContributionRecipientInfo(super_committee_recipient);
    const { id: conduitId, name: conduitName } = super_committee_conduit || {};
    const {
      is_pdf_available,
      filing_source_url,
      contribution_date,
      contribution_amount,
      source_type_full,
      election_type_full,
      id,
    } = receipt || {};

    return ({
      ...restProps,
      ...recipientInfo,
      id,
      contribution_date,
      source_type_full,
      election_type_full,
      conduitName,
      amount: contribution_amount,
      conduit: {
        conduitId,
        conduitUrl: `/moneyball_v2/committees/${conduitId}`,
      },
      receipts: {
        id,
        is_pdf_available,
        filing_source_url,
      },
    });
  });
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const {
      query,
      contribution_date,
      contribution_amount,
      super_committee_recipients,
      source_types,
      election_types,
    } = search_params || {};

    const { ids, types } = super_committee_recipients || {};

    return ({
      id,
      title: name || 'Applied filters:',
      filters: compact([
        getDisplayItem('Searched', query),
        getDisplayItem('Dates', getDateValue(contribution_date)),
        getDisplayItem('Amounts', getAmountValue(contribution_amount)),
        getDisplayItem('Recipient name(s)', getLabelForUI(ids)),
        getDisplayItem('Committee Type(s)', getCommitteeTypeValue(types)),
        getDisplayItem(getPluralLabel('Source type', source_types), getCommitteeTypeFilterValue(source_types)),
        getDisplayItem(getPluralLabel('Election type', election_types), getElectionTypeFilterValue(election_types)),
      ]),
      data: {
        ...getValue('searchValue', query),
        options: {
          ...getValue('dates', formatDates(contribution_date)),
          ...getValue('amounts', formatAmounts(contribution_amount)),
          ...getValue('sourceTypes', formatSelectTypeToValForFE(source_types)),
          ...getValue('electionTypes', formatSelectTypeToValForFE(election_types)),
          ...getValue('recipientNames', ids),
          ...getValue('committeeTypes', types),
        },
      },
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    dates,
    amounts,
    recipientNames,
    committeeTypes,
    electionTypes,
    sourceTypes,
  } = options || data;
  const { startDate, endDate } = dates || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('contribution_date', {
      ...getValue('start', startDate),
      ...getValue('end', endDate),
    }),
    ...getValue('contribution_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('super_committee_recipients', {
      ...getValue('ids', formatSelectValueToArrForBE(recipientNames)),
      ...getValue('types', committeeTypes),
    }),
    ...getValue('source_types', formatReceivedTypesForBE(sourceTypes)),
    ...getValue('election_types', formatSelectValueToArrForBE(electionTypes)),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformOptionsSearchForBE(data = {}) {
  const { options } = data;
  const baseOptions = transformOptionsForBE(data);
  const {
    recipientNames,
    committeeTypes,
    electionTypes,
    sourceTypes,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('source_types', formatTypesForSaveBE(sourceTypes)),
    ...getValue('election_types', formatTypesForSaveBE(electionTypes)),
    ...getValue('super_committee_recipients', {
      ...getValue('ids', sourceSelectValueForBE(recipientNames)),
      ...getValue('types', committeeTypes),
    }),
  };
}
