import { PRODUCT_SLUGS } from 'components/core/CustomFields/utils/constants';

import constants from './constants';

const defaultState = {
  ui: {
    isLoadingCustomFields: false,
    isLoadingCustomFieldByToken: false,
    isSavingCustomField: false,
    isUpdatingCustomField: false,
    resetTable: false,
    isEnabled: true,
    isUpdatingPositions: false,
    availableIn: PRODUCT_SLUGS.GRM,
  },
  allCustomFields: [],
  customField: {},
};

export default function customFields(state = defaultState, { type, payload }) {
  switch (type) {
    case constants.FETCH_CUSTOM_FIELDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingCustomFields: true,
        },
      };
    case constants.FETCH_CUSTOM_FIELDS_DONE:
      return {
        ...state,
        paginator: payload.paginator,
        allCustomFields: payload.data,
        ui: {
          ...state.ui,
          isEnabled: payload.isEnabled,
          availableIn: payload.availableIn,
          isLoadingCustomFields: false,
        },
      };
    case constants.CREATE_CUSTOM_FIELDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingCustomField: true,
          resetTable: true,
        },
      };
    case constants.CREATE_CUSTOM_FIELDS_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingCustomField: false,
          resetTable: false,
        },
      };
    case constants.UPDATE_CUSTOM_FIELDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingCustomField: true,
          resetTable: true,
        },
      };
    case constants.UPDATE_CUSTOM_FIELDS_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingCustomField: false,
          resetTable: false,
        },
      };
    case constants.GET_CUSTOM_FIELD_BY_TOKEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingCustomFieldByToken: true,
        },
      };
    case constants.GET_CUSTOM_FIELD_BY_TOKEN_DONE:
      return {
        ...state,
        customField: payload,
        ui: {
          ...state.ui,
          isLoadingCustomFieldByToken: false,
        },
      };
    case constants.RESET_LOADING_CUSTOM_FIELDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingCustomField: false,
          isUpdatingCustomField: false,
        },
      };
    case constants.UPDATE_POSITION_SYNC_DONE:
      return {
        ...state,
        allCustomFields: payload,
      };
    case constants.UPDATE_POSITION:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingPositions: true,
        },
      };
    case constants.UPDATE_POSITION_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUpdatingPositions: false,
        },
      };
    case constants.UPDATE_POSITION_FAILURE:
      return {
        ...state,
        allCustomFields: payload,
        ui: {
          ...state.ui,
          isUpdatingPositions: false,
        },
      };
    default:
      return state;
  }
}
