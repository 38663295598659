import moment from 'moment';
import { map, groupBy, startCase, get } from 'lodash';

import { DAY_DATE_FORMAT } from 'utils/constants/date';

const transformBill = ({ bill_number, ...bill }) => ({
  billNumber: bill_number,
  ...bill,
});

function transformEventsData(events) {
  return map(events, event => ({
    id: event.id,
    addToCalendarUrl: event.calendar_export_url,
    committeeId: get(event.committee, 'id'),
    committeeType: get(event.committee, 'type'),
    committeeName: get(event.committee, 'name'),
    committeeUrl: get(event.committee, 'url'),
    scheduledDate: event.scheduled_at,
    state: event.state.alpha2,
    chamber: startCase(event.chamber),
    bills: map(event.bills, transformBill),
    title: event.title,
    location: event.location,
    url: event.url,
    isChecked: false,
  }));
}

export function groupResponseByDate(response) {
  const formatData = [];
  const groupedData = groupBy(
    response.data, item => moment(item.scheduled_at).format(DAY_DATE_FORMAT)
  );

  Object.keys(groupedData).forEach(item => formatData.push({
    events: transformEventsData(groupedData[item]),
    date: item,
  }));

  return formatData;
}
