import { isEmpty, isArray, isObject, isString } from 'lodash';

import { DEFAULT_PER_PAGE } from './constants';

export function transformSavedSearchPaginator(data) {
  return {
    current: data.current_page,
    total: data.total_pages,
    perPage: DEFAULT_PER_PAGE,
    totalCount: data.total_count,
  };
}

export function getValue(key, value) {
  if (isEmpty(value)) {
    return null;
  }

  /** Parse select format to: { value, label } when array values are string */
  if (isArray(value)) {
    return {
      [key]: value.map(item => (
        isString(item) ? { value: item, label: item } : item
      )),
    };
  }

  return { [key]: value };
}

export function transformArray(values) {
  return values.map((item) => {
    if (isObject(item)) {
      return { label: item.value || '' };
    }

    return { label: item };
  });
}

export function getDisplayItem(label, value) {
  if (isEmpty(value)) {
    return undefined;
  }

  if (isArray(value)) {
    return { label, value: transformArray(value) };
  }

  return { label, value };
}


export function getDisplayRangeItem(label, start, end) {
  if (!isEmpty(start) && !isEmpty(end)) {
    return {
      label: `${label} between`,
      value: `${start} and ${end}`,
    };
  }

  if (!isEmpty(start)) {
    return {
      label: `${label} greater than`,
      value: start,
    };
  }

  if (!isEmpty(end)) {
    return {
      label: `${label} less than`,
      value: end,
    };
  }

  return undefined;
}

export function getDisplayData(title, filters, data) {
  return {
    title,
    filters,
    data,
  };
}

export function mapBackendValues(state, values) {
  if (isEmpty(state) || isEmpty(values)) {
    return null;
  }

  return state.map(value => values[value]);
}

export function loadAdvancedSearch(id) {
  const url = ['/api_web/advanced_search/searches', id].join('/');
  const request = $.get(url);

  return Promise.resolve(request);
}
