import { isEmpty, omitBy, isNil } from 'lodash';
import moment from 'moment/moment';

import { CSV_ID, EXPORT_EMAIL_LIMIT } from 'utils/constants/export';
import { submitExportReport } from 'redux/exportReport/actions';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import {
  loadSavedSearches,
  loadRecentSearches,
  onSaveItem,
  onRemoveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';
import { getValue } from 'utils/transformers/advancedSearch';
import { FULL_DATE_FORMAT } from 'utils/constants/date';

import constants from './constants';
import {
  transformOptionsForBE,
  transformSearchesForUI,
  transformStaffersForUI,
  getExportInfo,
} from './utils';

export function submitExport(payload) {
  const { items, searchData, exportOption, sort } = payload || {};
  const { sort: orderColumn, direction } = sort || {};

  const exportIds = items && items.map((item) => item.id);
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: constants.STRATEGY_SLUG_FOR_EXPORT,
    'strategy_inputs[staffer_ids]': exportIds,
    'strategy_inputs[file_extension]': exportOption || CSV_ID,
    'strategy_inputs[order_column]': orderColumn,
    'strategy_inputs[order_mode]': direction,
    ...omitBy(getExportInfo(searchForm), isEmpty),
  };

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `Congressional Staffers ${moment().format(FULL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({ ...payload, data, onSuccessCustom });
}

export function loadStaffersRecentSearches(params, stafferData) {
  const recentSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return (dispatch) => dispatch(loadRecentSearches(
    recentSearch,
    (data) => transformSearchesForUI(data, stafferData),
  ));
}

export function loadStaffersSavedSearches(params, stafferData) {
  const savedSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return loadSavedSearches(
    savedSearch,
    (data) => transformSearchesForUI(data, stafferData),
  );
}

export function saveStaffersAdvancedSearch(data = {}, stafferData, isSaved = true) {
  const advancedSearchData = {
    id: data.id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: transformOptionsForBE(data),
      saved: isSaved,
    },
  };

  if (!isSaved) {
    return onSaveRecentItem(
      advancedSearchData,
      (params) => loadStaffersRecentSearches(params, stafferData),
    );
  }

  return onSaveItem(
    advancedSearchData,
    (params) => loadStaffersRecentSearches(params, stafferData),
  );
}

export function removeStaffersAdvancedSearch(id, stafferData = {}) {
  return onRemoveItem(
    id,
    (params) => loadStaffersSavedSearches(params, stafferData),
  );
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, stafferData = {}, isClickedItem = false } = payload;

    if (!isClickedItem && !isEmpty(transformOptionsForBE(searchData))) {
      return dispatch(saveStaffersAdvancedSearch(searchData, stafferData, false));
    }

    return null;
  };
}

function getStaffersData(payload) {
  const { searchData = {}, appliedFilters, clearAll, sort, direction, page } = payload;
  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const url = '/api_web/rolodex/staffers/lookup';
  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData };

  return { isSearch, url, data, sortData };
}

export function fetchStaffers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFERS });

    const { searchData = {}, clearAll } = payload;
    const { isSearch, url, data, sortData } = getStaffersData(payload);
    const ajax = $.post(url, data);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_STAFFERS_DONE,
        payload: transformStaffersForUI(response.data),
        paginator: transformPaginator(response),
        toResetSelectedItems: isSearch,
        sort: transformSort(sortData),
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(saveAppliedFiltersToProps);
  };
}

export function getAllEmails(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.FETCH_STAFFERS_EMAILS });
    const { appliedFilters } = getState().rolodex.stafferLookup.appliedFilters;

    const { url, data } = getStaffersData({ ...payload, searchData: appliedFilters });
    const ajax = $.post(url, { ...data, per_page: EXPORT_EMAIL_LIMIT });

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_STAFFERS_EMAILS_DONE,
        payload: transformStaffersForUI(response.data),
      });
    }

    return Promise.resolve(ajax).then(onSuccess);
  };
}
