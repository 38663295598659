import { isEmpty, map, find, omitBy } from 'lodash';

import { INTERACTION_TYPES } from 'components/Grm2/constants';

import { transformTaskStatusForFeed } from './tasks';

export const transformFiltersFromAdvancedSearch = filters => omitBy({
  keywordLookup: filters.lookup_keyword,
  people: filters.people,
  bills: filters.bills,
  committees: filters.committees,
  regulations: filters.regulations,
  creator: filters.creator,
  assignee: filters.assignee,
  statusTypes: filters.statuses && map(filters.statuses, transformTaskStatusForFeed),
  priorityTypes: filters.priorities,
  dueDate: filters.due_date && {
    startDate: filters.due_date.from || null,
    endDate: filters.due_date.to || null,
  },
  activityTypes: filters.card_types,
  interactionTypes: map(filters.interaction_types, value => find(INTERACTION_TYPES, ['value', value])),
  tags: filters.tags,
  creationDate: filters.creation_date && {
    startDate: filters.creation_date.from || null,
    endDate: filters.creation_date.to || null,
  },
  eventDate: filters.event_date && {
    startDate: filters.event_date.from || null,
    endDate: filters.event_date.to || null,
  },
  attachments: filters.with_attachments === 'true',
}, value => (!value && isEmpty(value)));
