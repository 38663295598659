import { isEmpty, compact } from 'lodash';

import { getDisplayItem } from 'redux/advancedSearch/utils';
import {
  getLabelFromItem,
  getValue,
  getItemValue,
  transformSelectValues,
  getMapSelectValue,
  formatSelectValue,
  getMapDisplayItem,
} from 'utils/transformers/advancedSearch';
import { transformBill } from './transformers';

function getSinceCongressValue({ congressOptions, sinceCongress }) {
  const value = getItemValue(sinceCongress);

  if (isEmpty(congressOptions)) {
    return value;
  }

  const [firstCongress] = Object.keys(congressOptions);

  return value || firstCongress;
}

function transformOptionsForUI(data = {}, dataProps = {}) {
  const { id, search_params } = data;
  const {
    main_search_keywords,
    bill_numbers,
    sponsors,
    cosponsors,
    committees,
    congress,
    status_types,
    bill_types,
    subjects,
    different_text,
    show_only_appropriations,
    show_only_tracked_items,
    tag_ids,
    user_positions,
  } = search_params || {};
  const { billTypes: billTypesOptions, congressOptions } = dataProps;

  return {
    id,
    options: {
      ...getValue('searchValue', main_search_keywords),
      ...getValue('billNumbers', formatSelectValue(bill_numbers)),
      ...getValue('sponsors', sponsors),
      ...getValue('cosponsors', cosponsors),
      ...getValue('committees', committees),
      ...getValue('textExcludes', different_text),
      ...getValue('sinceCongress', getMapSelectValue(congressOptions, congress)),
      ...getValue('billStatuses', status_types),
      ...getValue('billTypes', getMapSelectValue(billTypesOptions, bill_types)),
      ...getValue('subjects', formatSelectValue(subjects)),
      ...getValue('showOnlyAppropriations', Boolean(show_only_appropriations)),
      ...getValue('showOnlyTrackedItems', Boolean(show_only_tracked_items)),
      ...getValue('tags', tag_ids),
      ...getValue('position', user_positions),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}, dataProps = {}) {
  const { name, search_params } = data;
  const { billTypes: billTypesOptions, congressOptions, billStatusOptions } = dataProps;
  const {
    main_search_keywords,
    bill_numbers,
    sponsors,
    cosponsors,
    committees,
    congress,
    status_types,
    bill_types,
    subjects,
    different_text,
    show_only_appropriations,
    show_only_tracked_items,
    tag_ids,
    user_positions,
  } = search_params || {};

  const filters = [
    getDisplayItem('Searched', main_search_keywords),
    getDisplayItem('Bill Numbers', bill_numbers),
    getDisplayItem('Text Excludes', different_text),
    getDisplayItem('Sponsor', getLabelFromItem(sponsors)),
    getDisplayItem('Cosponsor', getLabelFromItem(cosponsors)),
    getDisplayItem('Committee', getLabelFromItem(committees)),
    getDisplayItem('Since Congress', getMapDisplayItem(congressOptions, congress)),
    getDisplayItem('Bill Statuses', getLabelFromItem(status_types, billStatusOptions)),
    getDisplayItem('Bill Type', getMapDisplayItem(billTypesOptions, bill_types)),
    getDisplayItem('Subject', subjects),
    getDisplayItem('Show Only Appropriations', show_only_appropriations && 'Yes'),
    getDisplayItem('Show only federal bills I subscribe to', show_only_tracked_items && 'Yes'),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
    getDisplayItem('Position', getLabelFromItem(user_positions)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}, dataProps = {}) {
  const { congressOptions } = dataProps || {};
  const { searchValue, options } = data;
  const {
    billNumbers,
    sponsors,
    cosponsors,
    committees,
    sinceCongress,
    billStatuses,
    billTypes,
    subjects,
    textExcludes,
    showOnlyAppropriations,
    showOnlyTrackedItems,
    tags,
    position,
  } = options || {};

  return {
    ...getValue('show_only_appropriations', Boolean(showOnlyAppropriations)),
    ...getValue('show_only_tracked_items', Boolean(showOnlyTrackedItems)),
    ...getValue('main_search_keywords', searchValue),
    ...getValue('bill_numbers', transformSelectValues(billNumbers)),
    ...getValue('sponsor_ids', transformSelectValues(sponsors)),
    ...getValue('cosponsors_id', transformSelectValues(cosponsors)),
    ...getValue('committees_ids', transformSelectValues(committees)),
    ...getValue('different_text', textExcludes),
    ...getValue('congress', getSinceCongressValue({ congressOptions, sinceCongress })),
    ...getValue('status_types', transformSelectValues(billStatuses)),
    ...getValue('bill_types', getItemValue(billTypes)),
    ...getValue('subjects', transformSelectValues(subjects)),
    ...getValue('tag_ids', transformSelectValues(tags)),
    ...getValue('user_positions', transformSelectValues(position)),
  };
}

export function transformSearchesForBE(data = {}, dataProps = {}) {
  const { congressOptions } = dataProps || {};
  const { searchValue, options } = data;
  const {
    billNumbers,
    sponsors,
    cosponsors,
    committees,
    sinceCongress,
    billStatuses,
    billTypes,
    subjects,
    textExcludes,
    showOnlyAppropriations,
    showOnlyTrackedItems,
    tags,
    position,
  } = options || {};

  return {
    ...getValue('show_only_appropriations', Boolean(showOnlyAppropriations)),
    ...getValue('show_only_tracked_items', Boolean(showOnlyTrackedItems)),
    ...getValue('main_search_keywords', searchValue),
    ...getValue('bill_numbers', transformSelectValues(billNumbers)),
    ...getValue('sponsor_ids', transformSelectValues(sponsors)),
    ...getValue('cosponsors_id', transformSelectValues(cosponsors)),
    ...getValue('committees_ids', transformSelectValues(committees)),
    ...getValue('different_text', textExcludes),
    ...getValue('congress', getSinceCongressValue({ congressOptions, sinceCongress })),
    ...getValue('status_types', transformSelectValues(billStatuses)),
    ...getValue('bill_types', getItemValue(billTypes)),
    ...getValue('subjects', transformSelectValues(subjects)),
    ...getValue('tag_ids', tags),
    ...getValue('user_positions', position),
  };
}

export function transformSearchesForUI(data, dataProps = {}) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item, dataProps),
    ...transformOptionsForUI(item, dataProps),
  }));
}

export function transformBillsForUI(bills = [], page = '1') {
  return bills.map((bill, index) => transformBill(bill, index, page));
}

function transformBillLegislators(legislators) {
  if (isEmpty(legislators)) {
    return [];
  }

  return legislators;
}

export function transformGraphForUI(graphData = {}) {
  const { data } = graphData || {};
  const { introduced_over_time, top_cosponsors, top_sponsors } = data || {};

  return {
    introducedOverTime: !isEmpty(introduced_over_time) ? introduced_over_time : [],
    topCosponsors: transformBillLegislators(top_cosponsors),
    topSponsors: transformBillLegislators(top_sponsors),
  };
}

export function getExportInfo(data = {}, dataProps = {}) {
  const { searchValue } = data || {};
  const {
    billNumbers,
    sponsors,
    cosponsors,
    committees,
    sinceCongress,
    billStatuses,
    billTypes,
    subjects,
    showOnlyAppropriations,
    tags,
    showOnlyTrackedItems,
  } = data.options || {};
  const { congressOptions } = dataProps || {};

  return {
    'strategy_inputs[main_search_keywords]': searchValue || undefined,
    'strategy_inputs[bill_numbers]': transformSelectValues(billNumbers),
    'strategy_inputs[sponsor_ids]': transformSelectValues(sponsors),
    'strategy_inputs[cosponsors_id]': transformSelectValues(cosponsors),
    'strategy_inputs[committees_ids]': transformSelectValues(committees),
    'strategy_inputs[congress]': getSinceCongressValue({ congressOptions, sinceCongress }),
    'strategy_inputs[status_types]': transformSelectValues(billStatuses),
    'strategy_inputs[bill_types]': getItemValue(billTypes),
    'strategy_inputs[subjects]': transformSelectValues(subjects),
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    'strategy_inputs[show_only_appropriations]': Boolean(showOnlyAppropriations),
    'strategy_inputs[show_only_tracked_items]': Boolean(showOnlyTrackedItems),
  };
}
