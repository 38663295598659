const keyword = 'AGENCY_STAFFER_PROFILE';
const constants = {
  FETCH_PROFILE: `${keyword}/FETCH_PROFILE`,
  RECEIVE_PROFILE_DONE: `${keyword}/RECEIVE_PROFILE_DONE`,
  PROFILE_NOT_FOUND: `${keyword}/PROFILE_NOT_FOUND`,
  FETCH_TAGS: `${keyword}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${keyword}/RECEIVE_TAGS_DONE`,
};

export default constants;
