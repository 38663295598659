import { DEFAULT_CUSTOM_COLORS } from 'components/grassroots-campaign-builder/constants';

import {
  RESET_STATE,
  SET_NEW_CAMPAIGN_TYPE,
  FETCH_REGULATION,
  RECEIVE_REGULATION,
  FETCH_CAMPAIGN,
  RECEIVE_CAMPAIGN,
  CAMPAIGN_NOT_FOUND,
  SAVE_CAMPAIGN,
  RECEIVE_RECIPIENT,
  RECEIVE_DELETED_RECIPIENT,
  RECEIVE_MESSAGE,
  SAVE_PTC_MESSAGE,
  RECEIVE_PTC_MESSAGE,
  SAVE_MESSAGES,
  SAVE_THANK_YOU_MESSAGE,
  RECEIVE_THANK_YOU_MESSAGE,
  SAVE_LOGO_CONFIG,
  RECEIVE_LOGO_CONFIG,
  SAVE_SETTINGS,
  FETCH_FORM_INITIAL_VALUES,
  RECEIVE_FORM_INITIAL_VALUES,
} from '../constants';

export {
  saveCustomRecipient,
  deleteCustomRecipient,
  deleteCustomRecipients,
  fetchCustomRecipients,
} from './customRecipients';

export function resetState(payload) {
  return { type: RESET_STATE, payload };
}

export function setNewCampaignType(payload) {
  return { type: SET_NEW_CAMPAIGN_TYPE, payload };
}

export function fetchRegulation(payload) {
  return (dispatch) => {
    const { regulationId } = payload;
    dispatch({ type: FETCH_REGULATION, payload });

    // uses api_web_portal_grassroots_regulation_path
    const url = `/api_web/portal/grassroots/regulations/${regulationId}`;
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax)
      .then(data =>
        Promise.resolve(dispatch({ type: RECEIVE_REGULATION, payload: data }))
      );
  };
}

export function fetchCampaign(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN, payload });

    // uses api_web_grassroots_campaign_path
    const url = `/api_web/grassroots/campaigns/${payload.campaignToken}`;
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax)
      .then(campaign =>
        dispatch({ type: RECEIVE_CAMPAIGN, payload: campaign })
      )
      .catch(() =>
        dispatch({ type: CAMPAIGN_NOT_FOUND })
      );
  };
}

export function saveCampaign(payload) {
  return (dispatch) => {
    dispatch({ type: SAVE_CAMPAIGN, payload });

    const { campaignToken, campaign } = payload;
    const data = { campaign };

    // uses api_web_grassroots_campaigns_path
    let url = '/api_web/grassroots/campaigns';
    let method = 'POST';

    if (campaignToken) {
      url = `${url}/${campaignToken}`;
      method = 'PUT';
    }

    const ajax = $.ajax({
      url,
      method,
      data,
    });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_CAMPAIGN, payload: result })
      );
  };
}

function saveRecipient({ matcher, campaignToken }) {
  return (dispatch) => {
    // uses api_web_grassroots_campaign_matchers_path
    let url = `/api_web/grassroots/campaigns/${campaignToken}/matchers`;
    let method = 'POST';
    const data = { matcher };

    if (matcher.id) {
      url = `${url}/${matcher.id}`;
      method = 'PUT';
    }

    const ajax = $.ajax({
      url,
      method,
      data,
    });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_RECIPIENT, payload: result })
      );
  };
}


function deleteRecipient({ matcher, campaignToken }) {
  return (dispatch) => {
    const url = `/api_web/grassroots/campaigns/${campaignToken}/matchers/${matcher.id}/disable`;
    const method = 'POST';

    const ajax = $.ajax({ url, method });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_DELETED_RECIPIENT, payload: result })
      );
  };
}

export function saveRecipients(payload) {
  return (dispatch) => {
    const { campaignToken, matchers } = payload;

    const asyncRequests = matchers.map((matcher) => {
      if (matcher.disable) {
        return dispatch(
          deleteRecipient({ matcher, campaignToken })
        );
      }

      return dispatch(
        saveRecipient({ matcher, campaignToken })
      );
    });

    return Promise.all(asyncRequests);
  };
}

function saveMessage({ campaignToken, emailMessage }) {
  return (dispatch) => {
    // uses api_web_grassroots_campaign_matcher_messages_path
    let url = `/api_web/grassroots/campaigns/${campaignToken}/matcher_messages`;
    let method = 'POST';
    const data = { matcher_message: emailMessage };

    if (emailMessage.id) {
      url = `${url}/${emailMessage.id}`;
      method = 'PUT';
    }

    const ajax = $.ajax({
      url,
      method,
      data,
    });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_MESSAGE, payload: result })
      );
  };
}

export function savePTCMessage(payload) {
  return (dispatch) => {
    dispatch({ type: SAVE_PTC_MESSAGE, payload });

    const { campaignToken, ptc_config } = payload;

    const url = `/api_web/grassroots/campaigns/${campaignToken}/ptc_config`;
    const method = 'PUT';
    const data = { ptc_config };

    const ajax = $.ajax({ url, method, data });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_PTC_MESSAGE, payload: result })
      );
  };
}

export function saveMessages(payload) {
  return (dispatch) => {
    dispatch({ type: SAVE_MESSAGES, payload });

    const { campaignToken, campaign, matcher_messages } = payload;

    const messageRequests = matcher_messages.map(
      emailMessage => dispatch(saveMessage({ campaignToken, emailMessage }))
    );

    return Promise.all(messageRequests)
      .then(() => dispatch(saveCampaign({ campaignToken, campaign })));
  };
}

export function saveThankYouMessage(payload) {
  return (dispatch) => {
    dispatch({ type: SAVE_THANK_YOU_MESSAGE, payload });

    const { campaignToken, thank_you_config } = payload;

    // uses api_web_grassroots_campaign_thank_you_config_path
    const url = `/api_web/grassroots/campaigns/${campaignToken}/thank_you_config`;
    const method = 'PUT';
    const data = { thank_you_config };

    const ajax = $.ajax({
      url,
      method,
      data,
    });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_THANK_YOU_MESSAGE, payload: result })
      );
  };
}

export function saveLogoConfig(payload) {
  return (dispatch) => {
    dispatch({ type: SAVE_LOGO_CONFIG, payload });

    const { campaignToken, logo_config } = payload;

    // uses api_web_grassroots_campaign_logo_config_path
    const url = `/api_web/grassroots/campaigns/${campaignToken}/logo_config`;
    const method = 'PUT';
    const data = { logo_config };

    const ajax = $.ajax({
      url,
      method,
      data,
    });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_LOGO_CONFIG, payload: result })
      );
  };
}

export function saveSettings(payload) {
  return (dispatch) => {
    dispatch({ type: SAVE_SETTINGS, payload });

    const { file_upload_id } = payload.logo_config;
    const asyncRequests = [
      dispatch(saveCampaign(payload)),
      file_upload_id && dispatch(saveLogoConfig(payload)),
    ];

    return Promise.all(asyncRequests);
  };
}

export function fetchFormInitialValues(campaign) {
  const getColors = (companyConfig) => {
    if (campaign.primary_color) {
      return {
        primary_color: campaign.primary_color,
        secondary_color: campaign.secondary_color,
      };
    }

    if (companyConfig.primary_color) {
      return {
        primary_color: companyConfig.primary_color,
        secondary_color: companyConfig.secondary_color,
      };
    }

    return DEFAULT_CUSTOM_COLORS;
  };

  const getLogo = (companyConfig) => {
    if (campaign.logo_config && campaign.logo_config.file_upload_id) {
      return campaign.logo_config;
    }

    if (companyConfig.file_upload_id) {
      return {
        file_upload_id: companyConfig.file_upload_id,
        logo: companyConfig.logo,
      };
    }

    return {
      file_upload_id: null,
      logo: null,
    };
  };

  return async (dispatch) => {
    dispatch({ type: FETCH_FORM_INITIAL_VALUES });

    const ajax = $.get('/api_web/grassroots/company_config');

    return Promise.resolve(ajax)
      .then((companyConfig) => {
        const initialValues = {
          ...campaign,
          ...getColors(companyConfig),
          logo_config: getLogo(companyConfig),
        };

        dispatch({ type: RECEIVE_FORM_INITIAL_VALUES, payload: initialValues });
      });
  };
}
