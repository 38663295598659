import constants from '../constants';
import { transformSummaryForUI } from '../utils';

const onSuccess = dispatch => (response) => {
  dispatch({
    type: constants.RECEIVE_SUMMARY_DONE,
    payload: transformSummaryForUI(response),
  });
};

export const fetchSummary = (apiUrl, documentId) => (dispatch) => {
  const url = `/api_web/external_documents/documents/${apiUrl}/${documentId}`;
  const ajax = $.get(url);

  return Promise.resolve(ajax)
    .then(onSuccess(dispatch));
};
