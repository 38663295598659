import isEmpty from 'lodash/isEmpty';

import { getValue } from 'utils/transformers/advancedSearch';
import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformOptionsForBE,
  transformSearchesForUI,
  transformOptionsForSave,
} from '../utils/transformers';

const STRATEGY_SLUG = 'STATE_EXECUTIVE_LOOKUP';

/** Advanced search actions */
export function loadExecutivesRecentSearches(params) {
  const recentSearch = { ...params, strategy_slug: STRATEGY_SLUG };

  return dispatch => dispatch(loadRecentSearches(
    recentSearch,
    transformSearchesForUI,
  ));
}

export function loadExecutivesSavedSearches(params) {
  const savedSearch = { ...params, strategy_slug: STRATEGY_SLUG };

  return loadSavedSearches(
    savedSearch,
    transformSearchesForUI,
  );
}

export function saveAdvancedSearchItem(data = {}, _, isSaved = true) {
  const advancedSearchData = {
    id: data.id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: STRATEGY_SLUG,
      search_params: transformOptionsForSave(data),
      saved: isSaved,
    },
  };

  if (!isSaved) {
    return onSaveRecentItem(
      advancedSearchData,
      loadExecutivesRecentSearches,
    );
  }

  return onSaveItem(
    advancedSearchData,
    loadExecutivesRecentSearches,
  );
}

export function removeAdvancedSearchItem(id) {
  return onRemoveItem(id, loadExecutivesSavedSearches);
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, isClickedItem = false } = payload;
    const isSaved = false;

    if (!isClickedItem && !isEmpty(transformOptionsForBE(searchData))) {
      return dispatch(saveAdvancedSearchItem(searchData, null, isSaved));
    }

    return null;
  };
}
