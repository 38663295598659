import { combineReducers } from 'redux';

import advancedSearch from 'redux/advancedSearch/reducers';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import exportReport from 'redux/exportReport/reducers';

import constants from './constants';

export const defaultState = {
  staffers: [],
  paginator: {},
  sort: {},
  emails: '',
  ui: {
    isInitialLoading: true,
    isLoadingStaffers: true,
    isLoadingAllEmails: false,
    toResetSelectedItems: true,
  },
};

function stafferLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_STAFFERS:
      return {
        ...state,
        sort: defaultState.sort,
        emails: defaultState.email,
        ui: { ...state.ui, isLoadingStaffers: true, toResetSelectedItems: false },
      };
    case constants.RECEIVE_STAFFERS_DONE:
      return {
        ...state,
        staffers: action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isLoadingStaffers: false,
          isInitialLoading: false,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.FETCH_STAFFERS_EMAILS:
      return {
        ...state,
        emails: defaultState.emails,
        ui: { ...state.ui, isLoadingAllEmails: true },
      };
    case constants.RECEIVE_STAFFERS_EMAILS_DONE:
      return {
        ...state,
        emails: action.payload,
        ui: { ...state.ui, isLoadingAllEmails: false },
      };
    default:
      return state;
  }
}

export default combineReducers({
  stafferLookup,
  advancedSearch,
  appliedFilters: appliedSearchFilters,
  exportReport,
});
