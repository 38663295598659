const PREFIX = 'STATE_EXECUTIVE';

const constants = {
  FETCH_EXECUTIVE: `${PREFIX}/FETCH_EXECUTIVE`,
  RECEIVE_EXECUTIVE_DONE: `${PREFIX}/RECEIVE_EXECUTIVE_DONE`,
  PROFILE_NOT_FOUND: `${PREFIX}/PROFILE_NOT_FOUND`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
};

export default constants;
