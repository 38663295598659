import { RECIPIENT_TYPE } from 'components/GrassrootsPortal/constants';
import {
  CAMPAIGN_REGULATION_TYPE,
  CAMPAIGN_LEGISLATOR_TYPE,
} from 'components/grassroots-campaign-builder/constants';

import {
  SAVE_MESSAGES,
} from '../constants';
import { receiveError } from './utils';

function sendSingleMessage({ content, formSubmission, matcher, matcherMessage, recipientId, recipientType }) {
  const url = '/api_web/portal/grassroots/messages';
  const method = 'POST';

  const data = {
    message: {
      content,
      grassroots_form_submission_id: formSubmission.id,
      grassroots_matcher_id: matcher.id,
      recipient_id: recipientId,
      grassroots_matcher_message_id: matcherMessage.id,
      recipient_type: recipientType,
    },
  };

  const ajax = $.ajax({ method, url, data });

  return Promise.resolve(ajax)
    .catch(() => {
      // do nothing, for user always it went right
      // all back-end errors are handled in sentry
    });
}

function sendRecipientMessage({ formSubmission, recipient }) {
  const { id, matcher, recipient_type, matcherMessage } = recipient;

  const customMessage = formSubmission.custom_messages && formSubmission.custom_messages[id];
  const content = customMessage || matcherMessage.content;

  return sendSingleMessage({
    content,
    matcher,
    matcherMessage,
    formSubmission,
    recipientId: id,
    recipientType: recipient_type,
  });
}

function sendRecipientsMessages({ formSubmission, recipients }) {
  return Promise.all(
    recipients.map(recipient => sendRecipientMessage({ recipient, formSubmission }))
  );
}

function sendRegulationComment({ campaign, formSubmission, regulation }) {
  const matcher = campaign.matchers[0];
  const customComment = formSubmission.custom_comment;
  const matcherMessage = matcher.matcher_messages[0];
  const content = customComment || matcherMessage.content;

  return sendSingleMessage({
    content,
    matcher,
    formSubmission,
    matcherMessage,
    recipientId: regulation.id,
    recipientType: RECIPIENT_TYPE.FEDERAL_REGISTER_REGULATION,
    chamberSlug: null,
  });
}

function sendMessages(state) {
  const { campaign_type } = state.campaign;

  const sendFunction = {
    [CAMPAIGN_REGULATION_TYPE]: sendRegulationComment,
    [CAMPAIGN_LEGISLATOR_TYPE]: sendRecipientsMessages,
  }[campaign_type];

  return sendFunction(state);
}

export function saveMessages({ campaign, regulation, recipients }) {
  return dispatch => (formSubmission) => {
    dispatch({ type: SAVE_MESSAGES });

    const state = {
      campaign,
      regulation,
      recipients,
      formSubmission,
    };

    return sendMessages(state)
      .then(() => formSubmission)
      .catch(receiveError(dispatch));
  };
}
