import { isEmpty } from 'lodash';

import { onSaveRecentItem } from 'redux/advancedSearch/actions';
import { fetchMyFeedDocuments } from 'redux/events/actions';
import {
  fetchLookupTags,
  bulkSaveLookupTags,
  saveTags,
} from 'redux/tags/actions';

import { handleUpdateAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { bulkShareLookupToMyFeed } from 'redux/subscriptions/actions';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import notifications from 'components/SocialMedia/utils/notifications';
import { transformPaginator } from 'utils/transformers/paginator';

import { fetchPosts, fetchReplies } from './utils/api';
import {
  transformPost,
  transformPosts,
  transformOptionsForBE,
} from './utils/transformers';
import constants from './constants';

export { setLastSearchId } from 'redux/advancedSearch/actions';

export function fetchTags(payload = {}, documentType) {
  return dispatch => dispatch(fetchLookupTags(payload, documentType, constants));
}

export function bulkSaveTags(payload) {
  return dispatch => dispatch(bulkSaveLookupTags(payload, constants));
}

export function bulkShareToMyFeed(payload) {
  return dispatch => dispatch(bulkShareLookupToMyFeed({
    ...payload,
    documentType: DOCUMENT_TYPES.SOCIAL_MEDIA_POST,
  }, constants));
}

export function onSaveTags(props) {
  return dispatch => dispatch(saveTags(props, constants));
}

export function fetchData({ searchData, isInfinite }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_POSTS, isInfinite });
    dispatch(handleUpdateAppliedFilters(searchData));

    function onSuccess(response) {
      const { posts } = response || {};

      function onFetchRepliesSuccess(payload) {
        return dispatch({
          type: constants.RECEIVE_POSTS_DONE,
          payload,
          paginator: transformPaginator(response),
          isInfinite,
        });
      }

      return fetchReplies(transformPosts(posts))
        .then(onFetchRepliesSuccess);
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_POSTS_FAILURE });
      notifications.generalFailedNotification();

      throw error;
    }

    return fetchPosts(searchData)
      .then(onSuccess)
      .then(payloadData => dispatch(fetchTags(payloadData, DOCUMENT_TYPES.SOCIAL_MEDIA_POST)))
      .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
      .catch(onFailure);
  };
}

export function fetchSinglePost(token) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_POSTS });

    const url = `/api_web/social_media/posts/${token}`;
    const documentType = DOCUMENT_TYPES.SOCIAL_MEDIA_POST;
    const ajax = $.get(url);

    function onSuccess(response) {
      function onFetchRepliesSuccess(payload) {
        return dispatch({
          type: constants.RECEIVE_POSTS_DONE,
          payload,
        });
      }

      return fetchReplies([transformPost(response)])
        .then(onFetchRepliesSuccess);
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_POSTS_FAILURE });
      notifications.generalFailedNotification();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(payloadData => dispatch(fetchTags(payloadData, documentType)))
      .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
      .catch(onFailure);
  };
}

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const data = transformOptionsForBE(payload);

    if (isEmpty(data)) {
      return null;
    }

    const advancedSearchData = {
      advanced_search: {
        strategy_slug: 'social_media_posts',
        search_params: data,
        saved: false,
      },
    };

    return dispatch(onSaveRecentItem(advancedSearchData));
  };
}
