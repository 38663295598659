import {
  API_URL,
  SUBMIT_LOBBY_ALERTS,
  SUBMIT_LOBBY_ALERTS_DONE,
  SUBMIT_LOBBY_ALERTS_ERROR,
} from '../constants';

export function submitLobbyAlerts(params) {
  return (dispatch) => {
    dispatch({ type: SUBMIT_LOBBY_ALERTS });

    const ajax = $.post(API_URL, params);

    return Promise.resolve(ajax)
      .then(response =>
        dispatch({ type: SUBMIT_LOBBY_ALERTS_DONE, payload: response })
      )
      .catch((error) => {
        dispatch({ type: SUBMIT_LOBBY_ALERTS_ERROR, payload: error });
        throw error;
      });
  };
}
