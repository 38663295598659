import size from 'lodash/size';

import isNotFoundError from 'utils/http/isNotFoundError';
import * as subscriptionsApi from 'redux/dashboardV2/common/subscriptions/api';
import * as workspacesApi from 'redux/dashboardV2/common/workspaces/api';

import notifications from './utils/notifications';
import constants from './constants';

export const fetchWorkspace = token => (dispatch) => {
  dispatch({ type: constants.FETCH_WORKSPACE });

  const onSuccess = (workspace) => {
    dispatch({ type: constants.RECEIVE_WORKSPACE_DONE, payload: workspace });
  };

  const onFailure = (error) => {
    isNotFoundError(error) && dispatch({ type: constants.WORKSPACE_NOT_FOUND });
  };

  return workspacesApi.fetchWorkspace(token)
    .then(onSuccess)
    .catch(onFailure);
};

export const updateWorkspace = ({ token, initialName, name }) => (dispatch) => {
  dispatch({ type: constants.UPDATE_WORKSPACE });

  const onSuccess = () => {
    dispatch({ type: constants.UPDATE_WORKSPACE_DONE, payload: { name } });
  };

  const onFailure = (error) => {
    isNotFoundError(error) && dispatch({ type: constants.NAME_ALREADY_TAKEN });
  };

  return workspacesApi.updateWorkspace({ token, initialName, name })
    .then(onSuccess)
    .catch(onFailure);
};

export const updateWorkspaceSubscription = (frequency, isTracking) => (dispatch) => {
  dispatch({ type: constants.UPDATE_WORKSPACE_SUBSCRIPTION, payload: { frequency, isTracking } });
};

export const fetchSubscriptions = token => (dispatch) => {
  dispatch({ type: constants.FETCH_SUBSCRIPTIONS });

  const onSuccess = (subscriptions) => {
    dispatch({ type: constants.RECEIVE_SUBSCRIPTIONS_DONE, payload: subscriptions });
  };

  return subscriptionsApi.fetchSubscriptionsAndKeywords(token)
    .then(onSuccess);
};

export const deleteSubscription = (feedToken, subscription) => (dispatch) => {
  dispatch({ type: constants.DELETE_SUBSCRIPTION });

  const { token } = subscription;

  const onSuccess = () => {
    dispatch({ type: constants.DELETE_SUBSCRIPTION_DONE, payload: token });
  };

  return subscriptionsApi.deleteSubscription(feedToken, subscription)
    .then(onSuccess);
};

export const updateSubscription = (feedToken, subscription) => (dispatch) => {
  const onSuccess = () => {
    dispatch({ type: constants.UPDATE_SUBSCRIPTION_DONE, payload: subscription });
  };

  return subscriptionsApi.updateSubscription(feedToken, subscription)
    .then(onSuccess);
};

export const updateSubscriptions = (feedToken, bulkActionData = {}) => (
  (dispatch) => {
    dispatch({ type: constants.UPDATE_SUBSCRIPTIONS });

    const {
      tokens,
      workspaceTokens,
      frequencyLabel,
    } = bulkActionData;

    const successNotification = notifications.subscriptionsUpdated({
      itemsCount: size(tokens) + size(workspaceTokens),
      frequency: frequencyLabel,
    });

    const onSuccess = () => {
      const payload = { ...bulkActionData, tokens: [...tokens, ...workspaceTokens] };
      dispatch({ type: constants.UPDATE_SUBSCRIPTIONS_DONE, payload });
      successNotification();
    };

    return subscriptionsApi.updateSubscriptions({ ...bulkActionData, feedToken })
      .then(onSuccess);
  }
);

export const deleteSubscriptions = (feedToken, { tokens, workspaceTokens }) => (
  (dispatch) => {
    dispatch({ type: constants.DELETE_SUBSCRIPTIONS });

    const itemsCount = size(tokens) + size(workspaceTokens);
    const successNotification = notifications.subscriptionsDeleted(itemsCount);

    const onSuccess = () => {
      const payload = [...tokens, ...workspaceTokens];
      dispatch({ type: constants.DELETE_SUBSCRIPTIONS_DONE, payload });
      successNotification();
    };

    return subscriptionsApi.deleteSubscriptions({ feedToken, tokens, workspaceTokens })
      .then(onSuccess);
  }
);

export const updateCommitteeSubscription = (feedToken, subscription) => (
  (dispatch) => {
    const onSuccess = () => {
      dispatch({ type: constants.UPDATE_COMMITTEE_SUBSCRIPTION_DONE, payload: subscription });
    };

    return subscriptionsApi.createSubscription(feedToken, subscription)
      .then(onSuccess);
  }
);

export const deleteCommitteeSubscription = (feedToken, subscription) => (dispatch) => {
  const onSuccess = () => {
    dispatch({ type: constants.DELETE_COMMITTEE_SUBSCRIPTION_DONE, payload: subscription });
  };

  return subscriptionsApi.deleteSubscription(feedToken, subscription)
    .then(onSuccess);
};

export const fetchDefaultFrequency = () => (dispatch) => {
  dispatch({ type: constants.FETCH_DEFAULT_FREQUENCY });

  const onSuccess = (defaultFrequency) => {
    dispatch({ type: constants.FETCH_DEFAULT_FREQUENCY_DONE, payload: defaultFrequency });
  };

  return subscriptionsApi.fetchDefaultFrequency()
    .then(onSuccess);
};

export const updateDefaultFrequency = frequency => (dispatch) => {
  dispatch({ type: constants.UPDATE_DEFAULT_FREQUENCY });

  const onSuccess = () => {
    dispatch({ type: constants.UPDATE_DEFAULT_FREQUENCY_DONE, payload: frequency });
  };

  return subscriptionsApi.updateDefaultFrequency(frequency)
    .then(onSuccess);
};

export const resetSubscriptionsSettings = () => (dispatch) => {
  dispatch({ type: constants.RESET_SUBSCRIPTIONS_SETTINGS });

  const onSuccess = () => {
    dispatch({ type: constants.RESET_SUBSCRIPTIONS_SETTINGS_DONE });
  };

  return subscriptionsApi.resetSubscriptionsSettings()
    .then(onSuccess);
};
