import { isEmpty, isObject, map, omitBy, get } from 'lodash';

import { isMyFeed } from 'components/Dashboard/utils';

function getIds(values) {
  return { ids: map(values, 'value') };
}

function getData(itemData, globalFrequency) {
  const { values, frequency } = itemData || {};

  if (isEmpty(values)) {
    return null;
  }

  return {
    ...getIds(values),
    frequency: frequency || globalFrequency,
  };
}

export const getWithWorkspaces = (data, isMyFeedToken) => !(isEmpty(data) || isMyFeedToken);

function getCommitteeHearings({ itemData, globalFrequency, isMyFeedToken }) {
  const { isHearing, frequency: itemDataFrequency, hearingData, values, workspacesData } = itemData || {};
  const withWorkspaces = getWithWorkspaces(workspacesData, isMyFeedToken);
  const isCommitteeHearing = withWorkspaces ? workspacesData.isHearing : isHearing;

  if (isEmpty(values) || !isCommitteeHearing) {
    return null;
  }

  const workspacesSendEmail = get(workspacesData, 'hearingData.sendWeekAheadEmail');
  const workspacesFrequency = get(workspacesData, 'hearingData.frequency');
  const frequencyHearingData = isObject(itemDataFrequency) ? itemDataFrequency : hearingData;
  const { hearings, frequency, sendWeekAheadEmail } = frequencyHearingData || {};
  const sendEmail = withWorkspaces ? workspacesSendEmail : sendWeekAheadEmail;
  const subscriptionFrequency = withWorkspaces ? workspacesFrequency : hearings || frequency;

  return {
    ...getIds(values),
    frequency: subscriptionFrequency || globalFrequency,
    send_week_ahead_email: sendEmail || false,
  };
}

function getCommitteeStaffers({ itemData, globalFrequency, isMyFeedToken }) {
  const { isStaffer, frequency: itemDataFrequency, stafferData, values, workspacesData } = itemData || {};
  const withWorkspaces = getWithWorkspaces(workspacesData, isMyFeedToken);
  const isCommitteeStaffer = withWorkspaces ? workspacesData.isStaffer : isStaffer;

  if (isEmpty(values) || !isCommitteeStaffer) {
    return null;
  }

  const workspacesFrequency = get(workspacesData, 'stafferData.frequency');
  const frequencyStafferData = isObject(itemDataFrequency) ? itemDataFrequency : stafferData;
  const { staffer, frequency } = frequencyStafferData || {};
  const subscriptionFrequency = withWorkspaces ? workspacesFrequency : staffer || frequency;

  return {
    ...getIds(values),
    frequency: subscriptionFrequency || globalFrequency,
  };
}

export function transformTrackItemsForBE(payload, feedToken) {
  const {
    globalFrequency,
    federalBills,
    stateBills,
    federalRegulations,
    stateRegulations,
    stateCommittees,
    federalCommittees,
    federalLegislators,
    stateLegislators,
    newsfeeds,
    feedTokens,
  } = payload || {};

  const isMyFeedToken = isMyFeed(feedToken);
  const federalCommitteeData = { itemData: federalCommittees, globalFrequency, isMyFeedToken };
  const stateCommitteeData = { itemData: stateCommittees, globalFrequency, isMyFeedToken };

  return omitBy({
    federal_bills: getData(federalBills, globalFrequency),
    state_bills: getData(stateBills, globalFrequency),
    federal_regulations: getData(federalRegulations, globalFrequency),
    state_regulations: getData(stateRegulations, globalFrequency),
    state_committee_hearings: getCommitteeHearings(stateCommitteeData),
    state_committee_staffers: getCommitteeStaffers(stateCommitteeData),
    federal_committee_hearings: getCommitteeHearings(federalCommitteeData),
    federal_committee_staffers: getCommitteeStaffers(federalCommitteeData),
    federal_legislator_staffers: getData(federalLegislators, globalFrequency),
    state_legislator_staffers: getData(stateLegislators, globalFrequency),
    news_searches: getData(newsfeeds, globalFrequency),
    feed_tokens: feedTokens,
  }, isEmpty);
}
