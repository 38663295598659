const SMART_LISTS_BUILDER_PREFIX = 'SM_SMART_LISTS_BUILDER';
export const UPDATE_IS_TEMPLATE = `${SMART_LISTS_BUILDER_PREFIX}/UPDATE_IS_TEMPLATE`;
export const UPDATE_TITLE = `${SMART_LISTS_BUILDER_PREFIX}/UPDATE_TITLE`;
export const UPDATE_DESCRIPTION = `${SMART_LISTS_BUILDER_PREFIX}/UPDATE_DESCRIPTION`;
export const UPDATE_TRANSACTOR = `${SMART_LISTS_BUILDER_PREFIX}/UPDATE_TRANSACTOR`;
export const UPDATE_DATA = `${SMART_LISTS_BUILDER_PREFIX}/UPDATE_DATA`;
export const GET_LIST_START = `${SMART_LISTS_BUILDER_PREFIX}/GET_LIST_START`;
export const GET_LIST_DONE = `${SMART_LISTS_BUILDER_PREFIX}/GET_LIST_DONE`;
export const CREATE_LIST_START = `${SMART_LISTS_BUILDER_PREFIX}/CREATE_LIST_START`;
export const CREATE_LIST_DONE = `${SMART_LISTS_BUILDER_PREFIX}/CREATE_LIST_DONE`;
export const CREATE_LIST_FAIL = `${SMART_LISTS_BUILDER_PREFIX}/CREATE_LIST_FAIL`;
export const RESET = `${SMART_LISTS_BUILDER_PREFIX}/RESET`;

const STAKEHOLDER_SMART_LIST_PREFIX = 'STAKEHOLDER_SMART_LIST';
export const FETCH_SMART_LISTS = `${STAKEHOLDER_SMART_LIST_PREFIX}/FETCH_SMART_LISTS`;
export const RECEIVE_SMART_LISTS_DONE = `${STAKEHOLDER_SMART_LIST_PREFIX}/RECEIVE_SMART_LISTS_DONE`;
export const DELETE_SMART_LIST = `${STAKEHOLDER_SMART_LIST_PREFIX}/DELETE_SMART_LIST`;
export const DELETE_SMART_LIST_DONE = `${STAKEHOLDER_SMART_LIST_PREFIX}/DELETE_SMART_LIST_DONE`;

export const SORT_BY_TYPES = {
  NEWEST_TO_OLDEST: 'newestToOldest',
  OLDEST_TO_NEWEST: 'oldestToNewest',
  A_TO_Z: 'aToZ',
  Z_TO_A: 'zToA',
};
