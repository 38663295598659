// TODO: Add Feed constants later
const prefix = 'DASHBOARD_V2_FEED';

const constants = {
  FETCH_DEFAULT_SUBSCRIPTION: `${prefix}/FETCH_DEFAULT_SUBSCRIPTION`,
  LOAD_DEFAULT_SUBSCRIPTION_DONE: `${prefix}/LOAD_DEFAULT_SUBSCRIPTION_DONE`,
  LOAD_DEFAULT_SUBSCRIPTION_FAILED: `${prefix}/LOAD_DEFAULT_SUBSCRIPTION_FAILED`,
  UPDATE_DEFAULT_SUBSCRIPTION: `${prefix}/UPDATE_DEFAULT_SUBSCRIPTION`,
  UPDATE_DEFAULT_SUBSCRIPTION_DONE: `${prefix}/UPDATE_DEFAULT_SUBSCRIPTION_DONE`,
  UPDATE_DEFAULT_SUBSCRIPTION_FAILED: `${prefix}/UPDATE_DEFAULT_SUBSCRIPTION_FAILED`,
};

export default constants;
