import map from 'lodash/map';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { updateDataItemArrayPayload } from 'utils/reducer';

import constants from '../constants';

export function loadingUpdateItem(state, action) {
  const { isLoadingTags } = action || {};

  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingTags: isLoadingTags || false,
    },
  };
}

export function getFederalSubscription(state, action) {
  const { payload } = action || {};
  const currentPayload = map(payload, item => ({
    ...item,
    documentType: DOCUMENT_TYPES.FEDERAL_LEGISLATOR,
  }));
  const actionUpdated = { ...action, payload: currentPayload };

  return {
    ...updateDataItemArrayPayload(state, actionUpdated, constants.LEGISLATOR_LOOKUP_NAME),
    ui: { ...state.ui, isLoadingSubscriptions: false },
  };
}
