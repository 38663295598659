import stafferConstants from 'redux/staffers/common/constants';

const keyword = 'STAFFERS_PROFILE';

const constants = {
  ...stafferConstants(keyword),
  FETCH_STAFFER: `${keyword}/FETCH_STAFFER`,
  RECEIVE_STAFFER_DONE: `${keyword}/RECEIVE_STAFFER_DONE`,
  PROFILE_NOT_FOUND: `${keyword}/PROFILE_NOT_FOUND`,
  FETCH_OFFICE_STAFFERS: `${keyword}/FETCH_OFFICE_STAFFERS`,
  RECEIVE_OFFICE_STAFFERS_DONE: `${keyword}/RECEIVE_OFFICE_STAFFERS_DONE`,
  FETCH_TAGS: `${keyword}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${keyword}/RECEIVE_TAGS_DONE`,
};

export default constants;
