const PREFIX = 'ROLODEX_STAFFER';

const constants = {
  STRATEGY_SLUG: 'rolodex_staffer',
  STRATEGY_SLUG_FOR_EXPORT: 'rolodex_staffers',
  FETCH_STAFFERS: `${PREFIX}/FETCH_STAFFERS`,
  RECEIVE_STAFFERS_DONE: `${PREFIX}/RECEIVE_STAFFERS_DONE`,
  FETCH_STAFFERS_EMAILS: `${PREFIX}/FETCH_STAFFERS_EMAILS`,
  RECEIVE_STAFFERS_EMAILS_DONE: `${PREFIX}/RECEIVE_STAFFERS_EMAILS_DONE`,
};

export default constants;
