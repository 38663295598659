import isNotFoundError from 'utils/http/isNotFoundError';
import { camelizeKeys } from 'utils/object';
import Notifications from 'components/State/AgencyStafferProfile/utils/Notifications';
import { fetchProfileTags } from 'redux/tags/actions';

import { transformStaffer } from './utils/transformers';
import constants from './constants';

function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function fetchStaffer(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFER });
    const { id } = payload;

    const url = '/api_web/executive_profile';
    const ajax = $.get(
      url,
      {
        id,
        profile_slug: 'state_staffer_executive',
      }
    );

    const onSuccess = (staffer) => {
      dispatch({
        type: constants.RECEIVE_STAFFER_DONE,
        payload: staffer,
      });
    };

    const onFailure = (error) => {
      Notifications.defaultFetchDataFailed();

      isNotFoundError(error) && dispatch({ type: constants.PROFILE_NOT_FOUND });

      throw error;
    };

    return Promise.resolve(ajax)
      .then(({ data }) => camelizeKeys(data))
      .then(transformStaffer)
      .then(onSuccess)
      .then(() => dispatch(fetchTags(payload)))
      .catch(onFailure);
  };
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}
