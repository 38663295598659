import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { STATE_EXECUTIVE_ITEM_TYPE } from 'utils/constants/itemTypes';
import { transformExecutive } from 'utils/transformers/profile/executive';
import { camelizeKeys } from 'utils/object';
import { STATE_ROLE } from 'utils/transformers/profile/constants';

export function transformStateExecutive({ data }) {
  const executive = camelizeKeys(data);

  return {
    ...transformExecutive(executive, STATE_ROLE),
    itemType: STATE_EXECUTIVE_ITEM_TYPE,
    documentType: DOCUMENT_TYPES.STATE_EXECUTIVE,
  };
}
