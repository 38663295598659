const PREFIX = 'MONEYBALL_CORPORATION_PROFILE';

const constants = {
  STRATEGY_SLUG: 'moneyball_corporation_profile',
  FETCH_CORPORATION: `${PREFIX}/FETCH_CORPORATION`,
  RECEIVE_CORPORATION_DONE: `${PREFIX}/RECEIVE_CORPORATION_DONE`,
  FETCH_CORPORATION_CONTRIBUTIONS: `${PREFIX}/FETCH_CORPORATION_CONTRIBUTIONS`,
  RECEIVE_CORPORATION_CONTRIBUTIONS_DONE: `${PREFIX}/RECEIVE_CORPORATION_CONTRIBUTIONS_DONE`,
  FETCH_CORPORATION_RECEIPT: `${PREFIX}/FETCH_CORPORATION_RECEIPT`,
  RECEIVE_CORPORATION_RECEIPT_DONE: `${PREFIX}/RECEIVE_CORPORATION_RECEIPT_DONE`,
  SET_ELECTION_CYCLES: `${PREFIX}/SET_ELECTION_CYCLES`,
  SELECT_ELECTION_CYCLE: `${PREFIX}/SELECT_ELECTION_CYCLE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
