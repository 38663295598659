import { isEmpty, compact } from 'lodash';

import {
  COSPONSOR_BILLS,
  SPONSOR_BILLS,
} from 'components/core/Profile/components/Cards/Bills/utils/billTypes';

import {
  FEDERAL_LEGISLATOR_SLUG,
  STATE_LEGISLATOR_SLUG,
  FEDERAL_COMMITTEE_SLUG,
  STATE_COMMITTEE_SLUG,
  FEDERAL_BILL_SIMILAR_BILLS_SLUG,
  STATE_BILL_SIMILAR_BILLS_SLUG,
} from '../constants';
import { FEDERAL_BILL_SLUG, STATE_BILL_SLUG } from '../../publicRecords/constants';

const DEFAULT_LIMIT = 10;

export function getRelationshipStatus({ id, billRelationship }) {
  if (billRelationship === SPONSOR_BILLS.value) {
    return { sponsor_ids: [id] };
  }

  if (billRelationship === COSPONSOR_BILLS.value) {
    return { cosponsors_id: [id] };
  }

  return { legislator_ids: [id] };
}

export function getCommittees(committees, getCustomProps) {
  return committees.map((committee = {}) => {
    if (isEmpty(committee) || isEmpty(committee.name)) return null;

    return {
      id: committee.id,
      name: committee.name,
      ...(getCustomProps && getCustomProps(committee)),
    };
  });
}

export function getLegislators(legislators, getCustomProps) {
  return legislators.map((legislator = {}) => {
    if (isEmpty(legislator) || (isEmpty(legislator.name) && isEmpty(legislator.universal_moniker))) return null;

    return {
      id: legislator.id || legislator.legislator_id,
      name: legislator.name || legislator.universal_moniker,
      partyShort: legislator.party_short,
      partySlug: legislator.party_slug,
      ...(getCustomProps && getCustomProps(legislator)),
    };
  });
}

export function getSlicedLegislators(legislators, getCustomProps, limit = DEFAULT_LIMIT) {
  if (isEmpty(legislators)) {
    return [];
  }

  const slicedLegislators = getLegislators(legislators.slice(0, limit), getCustomProps);

  return compact(slicedLegislators);
}

export function getSlicedCommittees(committees, getCustomProps, limit = DEFAULT_LIMIT) {
  if (isEmpty(committees)) {
    return [];
  }

  const slicedCommittees = getCommittees(committees.slice(0, limit), getCustomProps);

  return compact(slicedCommittees);
}

export function getSlicedSponsors(committees, legislators, getCustomProps) {
  if (isEmpty(committees) && isEmpty(legislators)) {
    return [];
  }

  const slicedCommittees = getSlicedCommittees(committees, getCustomProps);
  const sliceLimit = DEFAULT_LIMIT - slicedCommittees.length;
  const slicedLegislators = sliceLimit > 0 ? getSlicedLegislators(legislators, getCustomProps, sliceLimit) : [];

  return [...slicedCommittees, ...slicedLegislators];
}

export function getBillsCongressValue({ allCongressOptions = [], congress = '' }) {
  if (isEmpty(congress) && isEmpty(allCongressOptions)) {
    return '';
  }

  if (isEmpty(congress)) {
    return allCongressOptions.map(congressOption => congressOption && congressOption.value);
  }

  return [congress];
}

export function getBillsUrl(slug, id) {
  const billsUrls = {
    [FEDERAL_LEGISLATOR_SLUG]: { url: '/api_web/federal/bills/lookup' },
    [STATE_LEGISLATOR_SLUG]: { url: '/api_web/state/bills/lookup' },
    [STATE_COMMITTEE_SLUG]: {
      url: `/api_web/state/committees/${id}/bills`,
      method: 'GET',
    },
    [FEDERAL_COMMITTEE_SLUG]: {
      url: '/api_web/federal/bills/lookup',
    },
    [FEDERAL_BILL_SIMILAR_BILLS_SLUG]: { url: '/api_web/federal/similar_bills/lookup' },
    [STATE_BILL_SIMILAR_BILLS_SLUG]: { url: '/api_web/state/similar_bills/lookup' },
  };

  if (!billsUrls[slug]) {
    throw Error('The bill slug must be valid');
  }

  return billsUrls[slug];
}

export function getSubscriptionBillsUrl(slug) {
  const subscriptionsUrls = {
    [FEDERAL_LEGISLATOR_SLUG]: { url: '/api_web/federal/bills/subscriptions' },
    [STATE_LEGISLATOR_SLUG]: { url: '/api_web/state/bills/subscriptions' },
    [FEDERAL_BILL_SIMILAR_BILLS_SLUG]: { url: '/api_web/federal/bills/subscriptions' },
    [STATE_BILL_SIMILAR_BILLS_SLUG]: { url: '/api_web/state/bills/subscriptions' },
    [FEDERAL_BILL_SLUG]: { url: '/api_web/federal/bills/subscriptions' },
    [STATE_BILL_SLUG]: { url: '/api_web/state/bills/subscriptions' },
    [STATE_COMMITTEE_SLUG]: { url: '/api_web/state/bills/subscriptions' },
    [FEDERAL_COMMITTEE_SLUG]: { url: '/api_web/federal/bills/subscriptions' },
  };

  if (!subscriptionsUrls[slug]) {
    return '';
  }

  return subscriptionsUrls[slug];
}
