import { isEmpty, omitBy } from 'lodash';
import moment from 'moment/moment';

import { CSV_ID } from 'utils/constants/export';
import { submitExportReport } from 'redux/exportReport/actions';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

import { STRATEGY_SLUG_EXPORT } from '../constants';
import { getExportInfo } from './utils';

export function submitExport(payload = {}) {
  const { items, searchData, dataProps } = payload;
  const exportIds = items && items.map(item => item.id);
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: STRATEGY_SLUG_EXPORT,
    'strategy_inputs[ids]': exportIds,
    'strategy_inputs[file_extension]': CSV_ID,
    ...omitBy(getExportInfo(searchForm, dataProps), isEmpty),
  };

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `Local Export ${moment().format(GLOBAL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({ data, onSuccessCustom });
}
