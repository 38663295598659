import { mapboxApiEndpoint, mapTypeCodes } from 'components/core/Map/utils/constants';
import {
  geocodeLegislatorAddress,
  getFetchLegislatorsUrl,
} from 'redux/legislator-lookup';
import mapConstants from 'components/map/constants';
import constants from './constants';

const getKwIdByFilterType = (properties, filterType) => {
  if (filterType === mapConstants.STATE_LEGISLATION_LOWER) {
    return properties.state_lower_district_kw_id;
  }

  if (filterType === mapConstants.STATE_LEGISLATION_UPPER) {
    return properties.state_upper_district_kw_id;
  }

  return properties.congressional_district_kw_id;
};

export function fetchMapBox(coordinates) {
  const ajax = $.get(
    `${mapboxApiEndpoint}/${coordinates}.json`,
    { radius: 0, access_token: gon.mapboxAccessToken }
  );

  return Promise.resolve(ajax);
}

const fetchLegislators = (address, filterType) => (dispatch) => {
  dispatch({ type: constants.FETCH_LEGISLATORS });

  const kwId = getKwIdByFilterType(address, filterType);

  if (!kwId) {
    dispatch({ type: constants.RECEIVE_LEGISLATORS_FAIL });

    return [];
  }

  const ajax = $.get(getFetchLegislatorsUrl(kwId, filterType));

  const onSuccess = (response = {}) => {
    dispatch({
      type: constants.RECEIVE_LEGISLATORS_FAIL,
      payload: response,
    });

    return response.legislators || [];
  };

  return Promise.resolve(ajax).then(onSuccess);
};

export function fetchDistrict(coordinates) {
  const data = coordinates.join();

  return fetchMapBox(data);
}

export function fetchAllLegislatorsByAddress(payload = {}) {
  return (dispatch, getState) => {
    const { address, districts } = payload;

    const getLegislators = () => Promise.all(districts.map(
      district => dispatch(fetchLegislators(address, mapTypeCodes[district])),
    ));

    const onSuccess = () => getLegislators()
      .then(([congressionalLegislators, stateUpperLegislators, stateLowerLegislators]) => {
        dispatch({
          type: constants.RECEIVE_ALL_LEGISLATORS_DONE,
          payload: {
            [mapTypeCodes.congressional]: congressionalLegislators,
            [mapTypeCodes.stateUpper]: stateUpperLegislators,
            [mapTypeCodes.stateLower]: stateLowerLegislators,
          },
        });
      });

    return address && geocodeLegislatorAddress(address, dispatch, getState)
      .then(onSuccess);
  };
}
