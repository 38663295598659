import constants from './constants';

const baseUrl = '/api_web/dismissible_alerts';

export function fetchAlert(type) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_ALERT });

    const url = `${baseUrl}/${type}`;
    const ajax = $.get(url);

    function onSuccess(result) {
      const showAlert = !result.data;

      dispatch({ type: constants.FETCH_ALERT_DONE, payload: showAlert });
    }

    function onFailure(error) {
      dispatch({ type: constants.FETCH_ALERT_FAILURE });

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function dismissAlert(type) {
  return (dispatch) => {
    dispatch({ type: constants.DISMISS_ALERT });

    const data = { alert_type: type };
    const ajax = $.post(baseUrl, data);

    function onSuccess() {
      dispatch({ type: constants.DISMISS_ALERT_DONE });
    }

    function onFailure(error) {
      dispatch({ type: constants.DISMISS_ALERT_FAILURE });

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function resetData() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_ALERT });
  };
}
