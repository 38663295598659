import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import {
  STAFFER_OFFICE_TYPE,
  STATE_STAFFER_OFFICE_TYPE,
  COMMITTEE_TYPE,
  FEDERAL_COMMITTEES_TYPE,
  STATE_COMMITTEE_STAFFER_TYPE,
  WORKSPACE_TYPE,
  STATE_COMMITTEE_TYPE,
  STATE_COMMITTEES_TYPE,
} from 'redux/dashboardV2/constants';

export const ITEM_TYPES = {
  [DOCUMENT_TYPES.FEDERAL_BILL]: 'federal_bills',
  [DOCUMENT_TYPES.FEDERAL_LEGISLATOR]: 'federal_legislator_staffers',
  [DOCUMENT_TYPES.FEDERAL_REGISTER_REGULATION]: 'federal_regulations',
  [DOCUMENT_TYPES.STATE_BILL]: 'state_bills',
  [DOCUMENT_TYPES.STATE_COMMITTEE]: 'state_committee_staffers',
  [DOCUMENT_TYPES.STATE_LEGISLATOR]: 'state_legislator_staffers',
  [DOCUMENT_TYPES.STATE_REGULATION]: 'state_regulations',
  [DOCUMENT_TYPES.NEWS_SEARCH]: 'news_searches',
  [STAFFER_OFFICE_TYPE]: 'federal_committee_staffers',
  [COMMITTEE_TYPE]: 'federal_committee_hearings',
  [FEDERAL_COMMITTEES_TYPE]: 'federal_committees',
  federal_committee_staffer: 'federal_committee_staffers',
  [STATE_STAFFER_OFFICE_TYPE]: 'state_committee_staffers',
  [STATE_COMMITTEE_STAFFER_TYPE]: 'state_committee_staffers',
  state_committee: 'state_committee_staffers',
  bill: 'federal_bills',
  federal_bill: 'federal_bills',
  federal_legislator: 'federal_legislator_staffers',
  federal_legislator_staffer: 'federal_legislator_staffers',
  federal_committee_hearing: 'federal_committee_hearings',
  federal_regulation: 'federal_regulations',
  federal_register_regulation: 'federal_regulations',
  federal_legislator_bill: 'federal_legislator_bills',
  state_bill: 'state_bills',
  state_legislator: 'state_legislator_staffers',
  state_legislator_staffer: 'state_legislator_staffers',
  state_regulation: 'state_regulations',
  news_search: 'news_searches',
  [WORKSPACE_TYPE]: 'workspaces',
  [STATE_COMMITTEE_TYPE]: 'state_committee_hearings',
  [STATE_COMMITTEES_TYPE]: 'state_committees',
};
