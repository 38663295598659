export function loadingUpdateItem(state, action) {
  const { isLoadingTags } = action || {};

  return {
    ...state,
    ui: {
      ...state.ui,
      isLoadingTags: isLoadingTags || false,
    },
  };
}

export function fetchCommittees({ state }) {
  return {
    ...state,
    sort: {},
    ui: { ...state.ui, isLoadingData: true, toResetSelectedItems: false },
  };
}

export function receiveCommittees({ state, action }) {
  return {
    ...state,
    committees: action.payload,
    paginator: action.paginator,
    sort: action.sort,
    ui: {
      ...state.ui,
      isLoadingData: false,
      isInitialLoading: false,
      isLoadingSubscriptions: false,
      isSavingSubscriptions: true,
      toResetSelectedItems: action.toResetSelectedItems,
    },
  };
}
