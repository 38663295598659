const PREFIX = 'GRASSROOTS_REPORTS';

export const BASE_URL = '/api_web/grassroots/statistics';

export const FETCH_CAMPAIGN = `${PREFIX}/FETCH_CAMPAIGN`;
export const RECEIVE_CAMPAIGN = `${PREFIX}/RECEIVE_CAMPAIGN`;
export const CAMPAIGN_NOT_FOUND = `${PREFIX}/CAMPAIGN_NOT_FOUND`;

export const FETCH_STATISTICS = `${PREFIX}/STATISTICS`;
export const RECEIVE_STATISTICS_LOADING = `${PREFIX}/STATISTICS_LOADING`;

export const RECEIVE_STATISTICS_ADVOCATES_COUNT = `${PREFIX}/STATISTICS_ADVOCATES_COUNT`;
export const RECEIVE_STATISTICS_MESSAGES_COUNT = `${PREFIX}/STATISTICS_MESSAGES_COUNT`;
export const RECEIVE_STATISTICS_MESSAGES_SENT = `${PREFIX}/RECEIVE_STATISTICS_MESSAGES_SENT`;
export const RECEIVE_STATISTICS_TOP_STATES = `${PREFIX}/RECEIVE_STATISTICS_TOP_STATES`;
export const RECEIVE_STATISTICS_EDITED_MESSAGES = `${PREFIX}/RECEIVE_STATISTICS_EDITED_MESSAGES`;

export const RECEIVE_STATISTICS_CUSTOM_RECIPIENTS = `${PREFIX}/RECEIVE_STATISTICS_CUSTOM_RECIPIENTS`;
export const RECEIVE_STATISTICS_FEDERAL_RECIPIENTS = `${PREFIX}/RECEIVE_STATISTICS_FEDERAL_RECIPIENTS`;
export const RECEIVE_STATISTICS_STATE_RECIPIENTS = `${PREFIX}/RECEIVE_STATISTICS_STATE_RECIPIENTS`;
export const RECEIVE_STATISTICS_SENDERS = `${PREFIX}/RECEIVE_STATISTICS_SENDERS`;
export const RECEIVE_STATISTICS_REGULATIONS = `${PREFIX}/RECEIVE_STATISTICS_REGULATIONS`;
export const FETCHING_TABLE_STATISTICS = `${PREFIX}/FETCHING_TABLE_STATISTICS`;
export const FETCHED_TABLE_STATISTICS = `${PREFIX}/FETCHED_TABLE_STATISTICS`;

export const ADD_CHART_TO_PRINT = `${PREFIX}/ADD_CHART_TO_PRINT`;
export const SET_DATE_RANGE = `${PREFIX}/SET_DATE_RANGE`;

export const PER_PAGE = 25;
