import { omitBy, isEmpty, map } from 'lodash';

import {
  FEDERAL_LEGISLATOR_SLUG,
  STATE_LEGISLATOR_SLUG,
  FEDERAL_COMMITTEE_SLUG,
  FEDERAL_BILL_SLUG,
  STATE_BILL_SLUG,
  STATE_EXECUTIVE_SLUG,
} from '../constants';

export function getPublicRecordsUrl(slug) {
  const publicRecordsUrls = {
    [FEDERAL_LEGISLATOR_SLUG]: {
      url: '/api_web/federal/public_records/lookup',
      strategySlug: 'federal_legislator_public_record',
    },
    [STATE_LEGISLATOR_SLUG]: {
      url: '/api_web/state/public_records/lookup',
      strategySlug: 'state_legislator_public_record',
    },
    [FEDERAL_COMMITTEE_SLUG]: {
      url: '/api_web/federal/public_records/lookup',
      strategySlug: 'federal_committee_public_record',
    },
    [FEDERAL_BILL_SLUG]: {
      url: '/api_web/federal/public_records/lookup',
      strategySlug: 'federal_bill_public_record',
    },
    [STATE_BILL_SLUG]: {
      url: '/api_web/state/bills/public_records',
      strategySlug: 'state_legislation_bill_public_record',
    },
    [STATE_EXECUTIVE_SLUG]: {
      url: '/api_web/state/executives/public_records/lookup',
      strategySlug: 'state_legislation_executive_public_record',
    },
  };

  if (!publicRecordsUrls[slug]) {
    throw Error('The public record slug must be valid');
  }

  return publicRecordsUrls[slug];
}

export function transformPublicRecordsForUI({ response: { data } = {}, transformRecords }) {
  const transformedRecords = transformRecords && transformRecords(data) || data;

  return {
    types: [],
    records: transformedRecords,
  };
}

export function transformPublicRecordsForBE(searchData = {}) {
  const { id, tags, keywordSearch, rangeDate = {}, selectedTypes = [] } = searchData;

  return omitBy({
    legislator_id: id,
    full_text: keywordSearch,
    date_from: rangeDate.startDate,
    date_to: rangeDate.endDate,
    collections: [...selectedTypes],
    tag_ids: map(tags, 'value'),
  }, isEmpty);
}
