import { combineReducers } from 'redux';

import hearingCalendar from './hearingCalendars/reducers';
import legislatorLookup from './legislatorLookup/reducers';
import committeeLookup from './committeeLookup/reducers';
import profile from './profile/reducers';
import committeeProfile from './committeeProfile/reducers';
import billProfile from './billProfile/reducers';
import billLookup from './billLookup/reducers';
import agencyStaffersLookup from './agencyStaffersLookup/reducers';
import agencyStafferProfile from './agencyStafferProfile/reducers';

export default combineReducers({
  hearingCalendar,
  legislatorLookup,
  committeeLookup,
  committeeProfile,
  profile,
  billProfile,
  billLookup,
  agencyStaffersLookup,
  agencyStafferProfile,
});
