import { compact, isEmpty, isNil, toString, capitalize } from 'lodash';

import emptyState from 'components/State/Regulations/Lookup/components/AdvancedOptions/utils/emptyState';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { REGULATION_DOCUMENT } from 'components/State/Regulations/Lookup/utils/constants';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import {
  getLabelFromItem,
  getValue,
  transformSelectValues,
} from 'utils/transformers/advancedSearch';
import { formatGlobalDate } from 'utils/date';
import { STATE_REGULATION_ITEM_TYPE } from 'utils/constants/itemTypes';
import { valueOrNull } from 'utils/forms/valueOrEmpty';

function formatResponseDate(fromDate, toDate) {
  return {
    start: valueOrNull(fromDate),
    end: valueOrNull(toDate),
  };
}

function getDaysLabel(numberOfDays) {
  if (numberOfDays < 2) {
    return `${numberOfDays} day`;
  }

  return `${numberOfDays} days`;
}

function transformOptionsForUI(data = {}) {
  const { id, search_params } = data;
  const {
    lookup_keyword,
    states,
    agencies,
    show_only_open_comments,
    comment_period_days,
    last_action_date_from,
    last_action_date_to,
    enabled_agency_notices,
    enabled_state_notices,
    tag_ids,
    user_positions,
  } = search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', lookup_keyword),
      ...getValue('states', states),
      ...getValue('agencies', agencies),
      ...getValue('isOpenCommentPeriod', show_only_open_comments),
      ...getValue('commentPeriodDays', comment_period_days),
      ...getValue('lastActionDate', formatResponseDate(last_action_date_from, last_action_date_to)),
      ...{ isAgencyNotice: Boolean(enabled_agency_notices) },
      ...{ isStateNotice: Boolean(enabled_state_notices) },
      ...getValue('tags', tag_ids),
      ...getValue('position', user_positions),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}) {
  const { name, search_params } = data;
  const {
    lookup_keyword,
    states,
    agencies,
    show_only_open_comments,
    comment_period_days,
    last_action_date_from,
    last_action_date_to,
    enabled_agency_notices,
    enabled_state_notices,
    tag_ids,
    user_positions,
  } = search_params || {};

  function getOpenCommentsText() {
    if (!show_only_open_comments) {
      return null;
    }

    if (isNil(comment_period_days)) {
      return 'open only';
    }

    return `open (closing in ${getDaysLabel(comment_period_days)}) only`;
  }

  function getYesNoLabel(checkValue) {
    return checkValue ? 'Yes' : 'No';
  }

  const filters = [
    getDisplayItem('Searched', lookup_keyword),
    getDisplayItem('States', getLabelFromItem(states)),
    getDisplayItem('Agencies', getLabelFromItem(agencies)),
    getDisplayItem('Comment period', getOpenCommentsText(agencies)),
    getDisplayItem('Last action from', last_action_date_from),
    getDisplayItem('Last action to', last_action_date_to),
    getDisplayItem('Include Agency notices', getYesNoLabel(enabled_agency_notices)),
    getDisplayItem('Include State notices', getYesNoLabel(enabled_state_notices)),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
    getDisplayItem('Position', getLabelFromItem(user_positions)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options, ids } = data || {};
  const {
    states,
    agencies,
    isOpenCommentPeriod,
    commentPeriodDays,
    lastActionDate,
    isStateNotice,
    isAgencyNotice,
    tags,
    position,
  } = options || {};

  /** Add default state when no filters are applied */
  const enabledAgencyNotice = isEmpty(data) ? emptyState.options.isAgencyNotice : isAgencyNotice;
  const enabledStateNotice = isEmpty(data) ? emptyState.options.isStateNotice : isStateNotice;

  return {
    ...getValue('lookup_keyword', searchValue),
    ...getValue('ids', ids),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('agencies', transformSelectValues(agencies)),
    ...getValue('show_only_open_comments', isOpenCommentPeriod),
    ...getValue('comment_period_days', commentPeriodDays > 0 && toString(commentPeriodDays)),
    ...getValue('last_action_date_from', lastActionDate && formatGlobalDate(lastActionDate.start)),
    ...getValue('last_action_date_to', lastActionDate && formatGlobalDate(lastActionDate.end)),
    ...getValue('tag_ids', transformSelectValues(tags)),
    ...getValue('user_positions', transformSelectValues(position)),
    enabled_agency_notices: enabledAgencyNotice,
    enabled_state_notices: enabledStateNotice,
  };
}

export function transformSearchesForBE(data = {}) {
  const { searchValue, options, ids } = data || {};
  const {
    states,
    agencies,
    isOpenCommentPeriod,
    commentPeriodDays,
    lastActionDate,
    isStateNotice,
    isAgencyNotice,
    tags,
    position,
  } = options || {};

  /** Add default state when no filters are applied */
  const enabledAgencyNotice = isEmpty(data) ? emptyState.options.isAgencyNotice : isAgencyNotice;
  const enabledStateNotice = isEmpty(data) ? emptyState.options.isStateNotice : isStateNotice;

  return {
    ...getValue('lookup_keyword', searchValue),
    ...getValue('ids', ids),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('agencies', transformSelectValues(agencies)),
    ...getValue('show_only_open_comments', isOpenCommentPeriod),
    ...getValue('comment_period_days', commentPeriodDays > 0 && toString(commentPeriodDays)),
    ...getValue('last_action_date_from', lastActionDate && formatGlobalDate(lastActionDate.start)),
    ...getValue('last_action_date_to', lastActionDate && formatGlobalDate(lastActionDate.end)),
    ...getValue('tag_ids', tags),
    ...getValue('user_positions', position),
    enabled_agency_notices: enabledAgencyNotice,
    enabled_state_notices: enabledStateNotice,
  };
}

export function transformSearchesForUI(data, regulationProps) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item, regulationProps),
    ...transformOptionsForUI(item),
  }));
}

function getRegisterLabel(registerField = {}) {
  if (isEmpty(registerField)) {
    return {};
  }

  const {
    published_date: date,
    label,
    register_issue_number: introducedIssue,
    register_volume_number: introducedVolume,
    url,
  } = registerField;

  if (isEmpty(date)) {
    return { url };
  }

  if ((isEmpty(introducedIssue) && isEmpty(introducedVolume)) || isEmpty(label)) {
    return { date, url };
  }

  return { date, label, url };
}

export function transformRegulationForUI(regulations = [], page = 1) {
  return regulations.map(({
    id,
    title,
    url,
    last_action,
    last_action_date,
    overview,
    comments_open,
    comments_closed,
    agencies,
    state,
    summary,
    code_citation,
    register_online_url,
    relevant_links,
    record_type,
  }, index) => {
    const { body: summaryText } = summary || {};
    const { administrative_code_url: administrativeCodeUrl } = relevant_links || {};
    const isRegulationDocument = record_type === REGULATION_DOCUMENT;
    const documentType = isRegulationDocument
      ? DOCUMENT_TYPES.STATE_REGULATION_DOCUMENT
      : DOCUMENT_TYPES.STATE_REGULATION;

    return {
      id,
      index,
      page,
      title,
      url,
      recordId: id,
      recordType: record_type,
      textSnippet: overview,
      lastActionDate: last_action_date,
      agencies: !isEmpty(agencies) && agencies.map(agency => capitalize(agency.title)),
      state: !isEmpty(state) && state.alpha2,
      lastAction: getRegisterLabel(last_action),
      stateRegisterUrl: register_online_url,
      commentsOpenDate: comments_open,
      commentsClosedDate: comments_closed,
      summary: summaryText,
      codeCitation: {
        value: code_citation,
        href: administrativeCodeUrl,
      },
      documentType,
      itemType: STATE_REGULATION_ITEM_TYPE,
      withTrack: !isRegulationDocument,
    };
  });
}

export function getExportInfo(data = {}) {
  const { searchValue } = data;
  const {
    states,
    agencies,
    isOpenCommentPeriod,
    commentPeriodDays,
    lastActionDate,
    isAgencyNotice,
    isStateNotice,
    tags,
  } = data.options || {};

  return {
    'strategy_inputs[lookup_keyword]': searchValue,
    'strategy_inputs[states]': transformSelectValues(states),
    'strategy_inputs[agencies]': transformSelectValues(agencies),
    'strategy_inputs[show_only_open_comments]': isOpenCommentPeriod,
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    'strategy_inputs[comment_period_days]': commentPeriodDays > 0 && toString(commentPeriodDays),
    'strategy_inputs[last_action_date_from]': lastActionDate && lastActionDate.start,
    'strategy_inputs[last_action_date_to]': lastActionDate && lastActionDate.end,
    'strategy_inputs[enabled_agency_notices]': isAgencyNotice,
    'strategy_inputs[enabled_state_notices]': isStateNotice,
  };
}

export function getExportSortInfo(data = {}) {
  const { direction, sort } = data;

  return {
    'strategy_inputs[order_column]': sort,
    'strategy_inputs[order_mode]': direction,
  };
}
