import React from 'react';

import Sponsor from 'components/Dashboard/common/Sponsor';
import { responseEventTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getSource } from 'redux/dashboardV2/feedPage/utils';
import {
  STATE_BILL_ITEM_TYPE,
  STATE_REGULATION_ITEM_TYPE,
  STATE_REGULATION_AGENCY_ITEM_TYPE,
  STATE_REGULATION_NOTICE_ITEM_TYPE,
  STATE_COMMITTEE_ITEM_TYPE,
} from 'utils/constants/itemTypes';

import {
  getTitle,
  getTitleUrl,
  getStateRegulationUrl,
  displayDescription,
  formatDescription,
  getPositionDescription,
  getStafferItemType,
  getHearingNotificationTitle,
  getHearingStatus,
} from '../utils';
import { SPONSOR_NODE, LINK_NODE, HEARING_NODE } from './descriptionNodeTypes';

function transformBillAction({
  isNotification = false,
  createdAt,
  event_data: {
    bill_id,
    bill_display_id,
    bill_title,
    state_alpha2,
    action_title,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = getTitle(state_alpha2, STATE_BILL_ITEM_TYPE);
  const title = getTitle(bill_display_id, bill_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/state/bills');
  const actionType = 'Bill action';

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: actionType,
      description: action_title,
      itemType,
      itemTitle: bill_display_id,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType,
    itemType,
    title,
    createdAt,
    description: action_title,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformBillSponsor({
  isNotification = false,
  createdAt,
  event_data: {
    bill_id,
    bill_display_id,
    bill_title,
    state_alpha2,
    sponsor_id,
    sponsor_name,
    sponsor_party_short,
    sponsor_party_slug,
    sponsor_come_from,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = getTitle(state_alpha2, STATE_BILL_ITEM_TYPE);
  const title = getTitle(bill_display_id, bill_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/state/bills');
  const infoText = 'is now a sponsor';

  const descriptionData = {
    url: getTitleUrl(sponsor_id, '/state/legislators'),
    label: sponsor_name,
    isExternal: false,
    partyShort: sponsor_party_short,
    partySlug: sponsor_party_slug,
    comeFrom: sponsor_come_from,
    infoText,
  };

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'Bill cosponsor added',
      description: <Sponsor data={descriptionData} withLink={false} />,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'Cosponsors added',
    itemType,
    title,
    createdAt,
    descriptionNode: {
      type: SPONSOR_NODE,
      data: descriptionData,
    },
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformBillPosition({
  isNotification = false,
  createdAt,
  event_data: {
    state_alpha2,
    user_full_name,
    new_user_position_type,
    bill_id,
    bill_title,
    bill_display_id,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = getTitle(state_alpha2, STATE_BILL_ITEM_TYPE);
  const title = getTitle(bill_display_id, bill_title, false, ' - ');
  const titleUrl = getTitleUrl(bill_id, '/state/bills');
  const description = getPositionDescription({
    position: new_user_position_type,
    userFullName: user_full_name,
    isNotification,
  });

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'Position update',
      description,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'Position changed',
    itemType,
    createdAt,
    title,
    description,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function getBaseStateRegulation({
  isNotification,
  createdAt,
  event_data: {
    regulation_title,
    document_text_snippet,
  } = {},
  feed,
}) {
  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      itemTitle: regulation_title,
      description: document_text_snippet,
      feedToken,
    };
  }

  return {
    title: regulation_title,
    createdAt,
    description: document_text_snippet,
  };
}

function transformStateRegulation({ isNotification = false, createdAt, event_data, feed }) {
  const {
    regulation_id,
    state_alpha2,
    regulation_title: title,
    document_type: documentType,
  } = event_data || {};

  const baseRegulation = getBaseStateRegulation({ event_data, createdAt, isNotification });
  const itemType = getTitle(state_alpha2, STATE_REGULATION_ITEM_TYPE);
  const titleUrl = getStateRegulationUrl({ regulation_id });
  const actionType = 'Regulatory documents published';

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      ...baseRegulation,
      title: actionType,
      itemType,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    ...baseRegulation,
    itemType,
    actionType,
    titleUrl,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformStateAgencyDocument({ isNotification = false, createdAt, event_data, feed }) {
  const { state_alpha2, regulation_title: title, document_type: documentType } = event_data || {};

  const baseRegulation = getBaseStateRegulation({ event_data, createdAt, isNotification });
  const itemType = getTitle(state_alpha2, STATE_REGULATION_AGENCY_ITEM_TYPE);
  const titleUrl = getStateRegulationUrl(event_data);
  const actionType = 'State agency regulatory notice published';

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      ...baseRegulation,
      title: actionType,
      itemType,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    ...baseRegulation,
    actionType,
    itemType,
    titleUrl,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformStateNoticeDocument({ isNotification = false, createdAt, event_data, feed }) {
  const { state_alpha2, regulation_title: title, document_type: documentType } = event_data || {};

  const baseRegulation = getBaseStateRegulation({ event_data, createdAt, isNotification });
  const itemType = getTitle(state_alpha2, STATE_REGULATION_NOTICE_ITEM_TYPE);
  const titleUrl = getStateRegulationUrl(event_data);
  const actionType = 'State regulatory notice published';

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      ...baseRegulation,
      title: actionType,
      itemType,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    ...baseRegulation,
    actionType,
    itemType,
    titleUrl,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformCommitteeHearing({
  isNotification = false,
  createdAt,
  event_data: {
    action_type,
    committee_id,
    committee_name: title,
    hearing_subject,
    hearing_location,
    document_type: documentType,
  } = {},
  feed,
}) {
  const itemType = STATE_COMMITTEE_ITEM_TYPE;
  const titleUrl = getTitleUrl(committee_id, '/state/committees');

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: getHearingNotificationTitle(action_type, 'Committee'),
      description: hearing_subject,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: getHearingStatus(action_type),
    itemType,
    title,
    createdAt,
    descriptionNode: {
      type: HEARING_NODE,
      data: {
        subject: hearing_subject,
        location: hearing_location,
      },
    },
    source: getSource({ documentType, title, titleUrl }),
  };
}

function transformRegulationPosition({ isNotification = false, createdAt, event_data, feed }) {
  const {
    regulation_title: title,
    state_alpha2,
    new_user_position_type,
    user_full_name,
    document_type: documentType,
  } = event_data || {};

  const itemType = getTitle(state_alpha2, STATE_REGULATION_ITEM_TYPE);
  const titleUrl = getStateRegulationUrl(event_data);
  const description = getPositionDescription({
    position: new_user_position_type,
    userFullName: user_full_name,
    isNotification,
  });

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'Position update',
      description,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    titleUrl,
    actionType: 'Position changed',
    itemType,
    createdAt,
    title,
    description,
    source: getSource({ documentType, title, titleUrl }),
  };
}

function getBaseStaffer({
  event_data,
  createdAt,
  isDescriptionVisible,
  afterLabel,
  isNotification,
  feed,
}) {
  const {
    staffer_id,
    staffer_name,
    state_alpha2,
    office_name: title,
    office_url: titleUrl,
    party_short: partyShort,
    document_type: documentType,
    come_from: comeFrom,
  } = event_data || {};

  const itemType = getTitle(state_alpha2, getStafferItemType(documentType));

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      ...(isDescriptionVisible && {
        description: `${staffer_name} ${afterLabel}`,
      }),
      itemTitle: title,
      url: titleUrl,
      itemType,
      feedToken,
    };
  }

  return {
    ...(isDescriptionVisible && {
      descriptionNode: {
        type: LINK_NODE,
        data: {
          url: getTitleUrl(staffer_id, '/state/staffers'),
          label: staffer_name,
          isExternal: false,
          afterLabel,
        },
      },
    }),
    itemType,
    titleUrl,
    title,
    createdAt,
    source: getSource({ documentType, title, titleUrl, partyShort, comeFrom }),
  };
}

function transformStafferJoin({ event_data, createdAt, feed, isNotification = false }) {
  const { staffer_name, staffer_title, office_name } = event_data || {};
  const isDescriptionVisible = displayDescription([staffer_name, office_name, staffer_title]);

  const baseStaffer = getBaseStaffer({
    event_data,
    createdAt,
    isDescriptionVisible,
    afterLabel: formatDescription(['has joined the', office_name, 'as a', staffer_title]),
    isNotification,
    feed,
  });

  if (isNotification) {
    return {
      ...baseStaffer,
      title: 'Staffer hired',
    };
  }

  return {
    ...baseStaffer,
    actionType: 'New staffer',
  };
}

function transformStafferLeave({ event_data, createdAt, feed, isNotification = false }) {
  const { staffer_name, office_name } = event_data || {};
  const isDescriptionVisible = displayDescription([staffer_name, office_name]);

  const baseStaffer = getBaseStaffer({
    event_data,
    createdAt,
    isDescriptionVisible,
    afterLabel: formatDescription(['has joined the', office_name]),
    isNotification,
    feed,
  });

  if (isNotification) {
    return {
      ...baseStaffer,
      title: 'Staffer exit',
    };
  }

  return {
    ...baseStaffer,
    actionType: 'Staffer exit',
  };
}

function transformStafferTitleChange({ event_data, createdAt, feed, isNotification = false }) {
  const { staffer_name, office_name, staffer_title, staffer_previous_title } = event_data || {};
  const isDescriptionVisible = displayDescription([
    staffer_name,
    staffer_previous_title,
    staffer_title,
    office_name,
  ]);

  const baseStaffer = getBaseStaffer({
    event_data,
    createdAt,
    isDescriptionVisible,
    afterLabel: formatDescription([
      'has transitioned from',
      staffer_previous_title,
      'to',
      staffer_title,
      'on',
      office_name,
    ]),
    feed,
    isNotification,
  });

  if (isNotification) {
    return {
      ...baseStaffer,
      title: 'Staffer update',
    };
  }

  return {
    ...baseStaffer,
    actionType: 'Staffer update',
  };
}

export const STATE_RECENT_TYPE = {
  [responseEventTypes.STATE_BILL_ACTION]: transformBillAction,
  [responseEventTypes.STATE_BILL_SPONSOR]: transformBillSponsor,
  [responseEventTypes.STATE_BILL_USER_POSITION]: transformBillPosition,
  [responseEventTypes.STATE_COMMITTEE_HEARING]: transformCommitteeHearing,
  [responseEventTypes.STATE_REGULATION_PUBLICATION]: transformStateRegulation,
  [responseEventTypes.STATE_REGULATION_STATE_AGENCY_NOTICE]: transformStateAgencyDocument,
  [responseEventTypes.STATE_REGULATION_STATE_NOTICE]: transformStateNoticeDocument,
  [responseEventTypes.STATE_REGULATION_USER_POSITION]: transformRegulationPosition,
  [responseEventTypes.STATE_STAFFER_JOIN]: transformStafferJoin,
  [responseEventTypes.STATE_STAFFER_LEAVE]: transformStafferLeave,
  [responseEventTypes.STATE_STAFFER_TITLE_CHANGE]: transformStafferTitleChange,
};
