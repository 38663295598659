import map from 'lodash/map';

import { transformLegislator } from './transformLegislator';

function withCommitteeSuffix(name) {
  if (name.toLowerCase().includes('committee')) {
    return name;
  }

  return `${name} Committee`;
}

function transformCommittee(committee) {
  return {
    ...committee,
    name: withCommitteeSuffix(committee.name),
    url: `/state/committees/${committee.committee_id}`,
  };
}

function transformSponsor(sponsor) {
  const { record, record_type } = sponsor;

  if (record_type === 'legislator') {
    return transformLegislator({
      ...record,
      id: record.legislator_id,
    });
  }

  if (record_type === 'committee') {
    return transformCommittee({
      ...record,
      id: record.committee_id,
    });
  }

  return null;
}

export function transformSponsorsAndCosponsorsForUI(payload) {
  const { data } = payload || {};
  const {
    total_republican,
    total_democratic,
    total_other,
    sponsors = [],
    cosponsors = [],
  } = data || {};

  return {
    republican: {
      numCosponsors: total_republican,
      partyShort: 'R',
      partySlug: 'republican',
    },
    democratic: {
      numCosponsors: total_democratic,
      partyShort: 'D',
      partySlug: 'democratic',
    },
    others: {
      numCosponsors: total_other,
      partyShort: 'O',
      partySlug: 'others',
    },
    allItems: [
      ...map(sponsors, sponsor => ({
        ...transformSponsor(sponsor),
        isSponsor: true,
      })),
      ...map(cosponsors, cosponsor => ({
        ...transformSponsor(cosponsor),
        isCosponsor: true,
      })),
    ],
  };
}
