import constants from 'redux/local/lookup/constants';

export { default as resetDocuments } from './resetDocuments';
export { default as fetchDocuments } from './fetchDocuments';
export { default as getDocumentsData } from './getDocumentsData';

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
