import constants from './constants';

const defaultState = {
  stafferData: {},
  committeeOffice: {},
  legislator: {},
  isLegislator: null,
  tags: [],
  ui: {
    isLoadingStaffer: false,
    isLoadingCommittee: false,
    isLoadingLegislator: false,
    isLoadingTags: true,
    isProfileNotFound: false,
  },
};

export default function stafferProfile(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_STAFFER_DATA:
      return {
        ...state,
        stafferData: {},
        ui: { ...state.ui, isLoadingStaffer: true },
      };
    case constants.FETCH_STAFFER_DATA_DONE:
      return {
        ...state,
        stafferData: action.payload,
        isLegislator: action.isLegislator,
        ui: { ...state.ui, isLoadingStaffer: false },
      };
    case constants.PROFILE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, isProfileNotFound: true },
      };
    case constants.FETCH_LEGISLATOR_DATA:
      return {
        ...state,
        legislator: {},
        ui: { ...state.ui, isLoadingLegislator: true },
      };
    case constants.FETCH_LEGISLATOR_DATA_DONE:
      return {
        ...state,
        legislator: action.payload,
        ui: { ...state.ui, isLoadingLegislator: false },
      };
    case constants.FETCH_COMMITTEE_OFFICE_DATA:
      return {
        ...state,
        committeeOffice: {},
        ui: { ...state.ui, isLoadingCommittee: true },
      };
    case constants.FETCH_COMMITTEE_OFFICE_DATA_DONE:
      return {
        ...state,
        committeeOffice: action.payload,
        ui: { ...state.ui, isLoadingCommittee: false },
      };
    case constants.SORT_STAFFER_DATA:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingStaffer: true,
          isLoadingCommittee: true,
          isLoadingLegislator: true,
        },
      };
    case constants.SORT_STAFFER_DATA_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingStaffer: false,
          isLoadingCommittee: false,
          isLoadingLegislator: false,
        },
        ...(action.stafferData && { stafferData: action.stafferData }),
        ...(action.committeeOffice && { committeeOffice: action.committeeOffice }),
        ...(action.legislator && { legislator: action.legislator }),
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    default:
      return state;
  }
}
