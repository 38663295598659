import { head, isEmpty, isNil, omitBy, map } from 'lodash';

import { RECENT, EXPORT_LIMIT } from 'components/Dashboard/common/FeedPage/utils/constants';

import { formatTokens } from 'redux/dashboardV2/common/subscriptions/utils';
import * as subscriptionsApi from 'redux/dashboardV2/common/subscriptions/api';
import { notifyLoadPageFailure } from 'components/Dashboard/common/UserNotifications';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { handleUpdateAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { BASE_FEEDS_URL } from 'redux/dashboardV2/constants';
import * as api from 'redux/dashboardV2/feedPage/api';
import { fetchReplies } from 'redux/socialMedia/feed/utils/api';
import isForbiddenError from 'utils/http/isForbiddenError';
import isNotFoundError from 'utils/http/isNotFoundError';
import { isMyFeed } from 'components/Dashboard/utils';
import { tryCatch } from 'utils/async';

import { isFirstPage, getIsLoadingActions } from '../utils';
import {
  transformRecentDataRequest,
  transformTrackedDataRequest,
  transformDataResponse,
} from '../utils/transformers';
import notifications from '../notifications';
import constants from '../constants';
import { fetchPositions } from './position';
import { fetchItemsTracking } from './tracking';
import { fetchLinkPreview } from './linksPreview';
import { fetchTags } from './tags';
import {
  onResetLastVisit,
  setCreatedAt,
  onUpdateLastVisit,
} from './lastVisit';
import { getPosts, updateDataPosts } from './socialMediaPosts';

export * from './tags';
export * from './keywords';
export * from './bulkActions';
export * from './trackCommittee';
export * from './advancedSearch';

export function fetchData({
  isInfinite,
  type,
  searchData,
  appliedFilters,
  clearAll,
  withLastVisit,
  resetLastVisit,
  createdAt,
  sortData,
  enableInAppNotifications,
  feedToken,
}) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_DATA_INIT, payload: type, isInfinite });
    resetLastVisit && onResetLastVisit(dispatch);

    const isRecent = type === RECENT;
    const recentLookupForm = isRecent && {};
    const searchForm = { perPage: EXPORT_LIMIT, sortData, ...(searchData || appliedFilters) };

    const data = isRecent
      ? transformRecentDataRequest({ ...recentLookupForm, ...searchForm, createdAt })
      : transformTrackedDataRequest({ ...searchForm });

    const baseUrl = `${BASE_FEEDS_URL}/${feedToken}`;
    const recentUrl = `${baseUrl}/events/lookup`;
    const trackedUrl = `${baseUrl}/trackings/lookup`;
    const url = isRecent ? recentUrl : trackedUrl;

    const includeLastVisitRequest = withLastVisit && isFirstPage(data);

    const saveAppliedFiltersToProps = () => {
      clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleUpdateAppliedFilters({ ...searchForm }));
    };

    const onSuccess = async (response, visitData) => {
      !isEmpty(visitData) && dispatch({
        type: constants.SET_LAST_VISIT,
        payload: visitData,
      });

      setCreatedAt(dispatch, response);
      const payloadData = transformDataResponse(response, type);
      const sort = omitBy(sortData, isNil);
      const posts = getPosts(payloadData);

      const [replies, error] = await tryCatch(fetchReplies(posts));

      if (error) {
        !isForbiddenError(error) && window.Sentry && window.Sentry.captureMessage(error);
      }

      const payload = isEmpty(replies)
        ? payloadData
        : updateDataPosts({ data: payloadData, replies });

      dispatch({
        type: constants.LOAD_DATA_DONE,
        payload,
        paginator: transformPaginator(response),
        sort: transformSort(sort),
        error: false,
        isInfinite,
      });

      return payload;
    };

    const onFetchPosition = (response) => {
      dispatch(fetchPositions({ data: response }));
      return response;
    };

    const onFetchTrackedItems = (response) => {
      isRecent && dispatch(fetchItemsTracking(feedToken, response));
      return response;
    };

    const fetchLastVisit = () => (
      includeLastVisitRequest ? api.fetchSeen(feedToken) : Promise.resolve()
    );

    const updateLastVisit = (response) => {
      const { id: latestEventToken } = head(response) || {};
      const shouldUpdate = includeLastVisitRequest && latestEventToken;
      shouldUpdate && dispatch(onUpdateLastVisit(feedToken, latestEventToken));

      return response;
    };

    const onFailure = (error) => {
      dispatch({
        type: constants.LOAD_DATA_DONE,
        payload: [],
        error: true,
        isInfinite,
      });

      !isNotFoundError(error) && notifyLoadPageFailure({
        itemTitle: isMyFeed(feedToken) ? 'My feed' : null,
      });

      throw error;
    };

    const onFinally = () => {
      dispatch({ type: constants.LOADING_DATA_FINISH });
      enableInAppNotifications && enableInAppNotifications();
      saveAppliedFiltersToProps();
    };

    const onLastVisitError = (error) => {
      if (isNotFoundError(error)) {
        onResetLastVisit(dispatch);

        return Promise.resolve({});
      }

      throw error;
    };

    const requests = [
      api.fetchData({ url, data }).catch(onFailure),
      fetchLastVisit().catch(onLastVisitError),
    ];

    return Promise.all(requests).then(([response, lastVisit]) => onSuccess(response, lastVisit))
      .then(onFetchPosition)
      .then(onFetchTrackedItems)
      .then(response => dispatch(fetchTags(response, isRecent)))
      .then(updateLastVisit)
      .then(response => dispatch(fetchLinkPreview(response)))
      .finally(onFinally);
  };
}

export function updateEvent({ feedToken, id, priority, isSaved, isRead, actionType }) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_EVENT_INIT,
      payload: getIsLoadingActions(id, actionType, true),
    });

    const onSuccess = () => dispatch({
      type: constants.UPDATE_EVENT,
      payload: {
        ...getIsLoadingActions(id, actionType),
        priority,
        isSaved,
        isRead,
      },
    });

    const onFailure = (error) => {
      dispatch({
        type: constants.UPDATE_EVENT_INIT,
        payload: getIsLoadingActions(id, actionType),
      });

      notifications.defaultSubmitDataFailed();
      throw error;
    };

    const data = { priority, is_saved: Boolean(isSaved), is_read: Boolean(isRead) };
    return Promise.resolve(api.updateEvent({ feedToken, id, data }))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchSingleData({ feedToken, id, type }) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_DATA_INIT, payload: type });
    const isRecent = type === RECENT;

    const url = isRecent
      ? `${BASE_FEEDS_URL}/${feedToken}/events/${id}`
      : `${BASE_FEEDS_URL}/${feedToken}/trackings/lookup`;

    const method = isRecent
      ? 'GET'
      : 'POST';

    const data = isRecent ? {} : { search_form: { subscription_ids: [id] } };

    const onSuccess = (response) => {
      const payloadData = isRecent
        ? transformDataResponse({ data: [response] }, type)
        : transformDataResponse(response, type);

      dispatch({
        type: constants.LOAD_DATA_DONE,
        payload: payloadData,
        error: false,
      });

      return Promise.resolve(payloadData);
    };

    const onFailure = (error) => {
      throw error;
    };

    const onFinally = () => {
      dispatch({ type: constants.LOADING_DATA_FINISH });
    };

    const onFetchPosition = (response) => {
      dispatch(fetchPositions({ data: response }));
      return response;
    };

    const onFetchTrackedItems = (response) => {
      isRecent && dispatch(fetchItemsTracking(feedToken, response));
      return response;
    };

    return Promise.resolve(api.fetchData({ url, method, data }))
      .then(onSuccess)
      .then(onFetchPosition)
      .then(onFetchTrackedItems)
      .then(response => dispatch(fetchTags(response, isRecent)))
      .catch(onFailure)
      .finally(onFinally);
  };
}

export function undoEvent(feedToken, id) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_UNDO_EVENT });

    const onSuccess = () => dispatch({
      type: constants.UNDO_EVENT,
      id,
    });

    const onFailure = (error) => {
      notifications.defaultSubmitDataFailed();
      throw error;
    };

    return Promise.resolve(api.undoEvent(feedToken, id))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function undoSubscription(feedToken, id) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_UNDO_EVENT });

    const onSuccess = () => dispatch({
      type: constants.UNDO_EVENT,
      id,
    });

    const onFailure = (error) => {
      notifications.defaultSubmitDataFailed();
      throw error;
    };

    const tokens = formatTokens([id]);
    const requests = map(tokens, token => api.undoSubscription(feedToken, token));

    return Promise.all(requests)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updateTrackedItem({
  feedToken,
  token,
  isKeywordSearch,
  isNewsFeed,
  id,
  type,
  actionType,
  modelId,
  priority,
  isSaved,
  frequency,
  sendWeekAheadEmail,
  withWeekAheadEmail,
  modelSlugs,
  keyword,
  listSubscriptions,
}) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_EVENT_INIT,
      payload: getIsLoadingActions(id, actionType, true),
    });

    const onSuccess = () => dispatch({
      type: isKeywordSearch || isNewsFeed ? constants.UPDATE_EVENT_KEYWORD : constants.UPDATE_EVENT,
      payload: {
        ...isKeywordSearch && { modelId },
        ...isNewsFeed && { modelId: token },
        ...getIsLoadingActions(id, actionType),
        ...(listSubscriptions && {
          listSubscriptions: map(listSubscriptions, subscription => ({
            ...subscription,
            content: frequency,
            frequency,
            sendWeekAheadEmail,
          })),
        }),
        priority,
        isSaved,
        frequency,
        sendWeekAheadEmail,
      },
    });

    const onFailure = (error) => {
      dispatch({
        type: constants.UPDATE_EVENT_INIT,
        payload: getIsLoadingActions(id, actionType),
      });
      notifications.defaultSubmitDataFailed();

      throw error;
    };

    const subscription = {
      modelId,
      token,
      type,
      priority,
      frequency,
      isSaved,
      sendWeekAheadEmail,
      withWeekAheadEmail,
      keyword,
      modelSlugs,
    };

    return subscriptionsApi.updateSubscription(feedToken, subscription)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function removeEventUI({ id, isTracking }) {
  return dispatch => dispatch({
    type: constants.REMOVE_EVENT,
    payload: { id, isTracking },
  });
}

export function updateDataItem(item) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload: item,
  });
}

export function reset() {
  return (dispatch) => {
    dispatch(resetAppliedFilters());
    dispatch({ type: constants.RESET });
  };
}
