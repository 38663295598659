export const ADDED_TO_API_URL = 'state_regulation_document';

export const DOCUMENT_API_URL = '/api_web/state/regulations/profile';
export const REGULATION_DETAILS_API_URL = '/api_web/state/regulations/profile';

const PREFIX = 'STATE_REGULATION';
export const FULL_TEXT = 'full';
export const NOTICE_TEXT = 'notice';

const constants = {
  FETCH_DOCUMENT: `${PREFIX}/FETCH_DOCUMENT`,
  RECEIVE_DOCUMENT_DONE: `${PREFIX}/RECEIVE_DOCUMENT_DONE`,
  FETCH_REGULATION_DETAILS: `${PREFIX}/FETCH_REGULATION_DETAILS`,
  RECEIVE_REGULATION_DETAILS_DONE: `${PREFIX}/RECEIVE_REGULATION_DETAILS_DONE`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  RECEIVE_IS_SHARED_DONE: `${PREFIX}/RECEIVE_IS_SHARED_DONE`,
};

export default constants;
