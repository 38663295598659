import { camelizeKeys } from 'utils/object';

export const fetchAgencyStaffer = (id) => {
  const url = '/api_web/executive_profile';
  const params = {
    id,
    profile_slug: 'federal_executive',
  };
  const ajax = $.get(url, params);

  return Promise.resolve(ajax)
    .then(camelizeKeys)
    .then(({ data }) => data);
};
