import { transformCompanyInfoForUI, transformCompanyInfoForBE } from '../transformers';
import constants from '../constants';

export function fetchCompanyInfo() {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMPANY_INFO });

    const url = '/api_web/company-subscription/institutional-data/company_info';
    const method = 'GET';

    const ajax = $.ajax({ url, method });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_COMPANY_INFO_DONE,
        payload: transformCompanyInfoForUI(response),
      }));
  };
}

export function updateCompanyInfo(companyInfo) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_COMPANY_INFO });

    const url = '/api_web/company-subscription/institutional-data/company_update';
    const method = 'PUT';
    const data = transformCompanyInfoForBE(companyInfo);

    const ajax = $.ajax({
      url,
      method,
      data,
      contentType: false,
      processData: false,
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.UPDATE_COMPANY_INFO_DONE,
        payload: transformCompanyInfoForUI(response),
      }))
      .catch((error) => {
        dispatch({ type: constants.UPDATE_COMPANY_INFO_ERROR });
        throw error;
      });
  };
}
