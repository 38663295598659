import isEmpty from 'lodash/isEmpty';

import { onSaveRecentItem } from 'redux/advancedSearch/actions';

import {
  transformOptionsForBE,
} from '../utils/transformers/advancedSearch';

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { type, searchData = {} } = payload;
    const data = transformOptionsForBE({ searchData, type });

    if (isEmpty(data)) {
      return null;
    }

    const advancedSearchData = {
      advanced_search: {
        strategy_slug: type,
        search_params: data,
        saved: false,
      },
    };

    return dispatch(onSaveRecentItem(advancedSearchData));
  };
}
