const PREFIX = 'MONEYBALL_COMMITTEE_PROFILE';

const constants = {
  STRATEGY_SLUG: 'moneyball_committee_profile',
  FETCH_COMMITTEE: `${PREFIX}/FETCH_COMMITTEE`,
  RECEIVE_COMMITTEE_DONE: `${PREFIX}/RECEIVE_COMMITTEE_DONE`,
  FETCH_COMMITTEES_DATA: `${PREFIX}/FETCH_COMMITTEES_DATA`,
  RECEIVE_COMMITTEES_DATA_DONE: `${PREFIX}/RECEIVE_COMMITTEES_DATA_DONE`,
  FETCH_COMMITTEES_RECEIPT: `${PREFIX}/FETCH_COMMITTEES_RECEIPT`,
  RECEIVE_COMMITTEES_RECEIPT_DONE: `${PREFIX}/RECEIVE_COMMITTEES_RECEIPT_DONE`,
  SET_ELECTION_CYCLES: `${PREFIX}/SET_ELECTION_CYCLES`,
  SELECT_ELECTION_CYCLE: `${PREFIX}/SELECT_ELECTION_CYCLE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
