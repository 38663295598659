import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import Notifications from 'components/Moneyball/utils/Notifications';
import { trackMoneyBallSmartSearchCreate, trackMoneyBallSmartSearchUpdate } from 'utils/tracking';

import {
  UPDATE_ISTEMPLATE,
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  UPDATE_TRANSACTOR,
  UPDATE_DATA,
  GET_LIST_START,
  GET_LIST_DONE,
  CREATE_LIST_START,
  CREATE_LIST_DONE,
  RESET,
} from './constants';
import { transformDataForFront, transformDataForBack } from './utils';

export function updateIsTemplate(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ISTEMPLATE,
      payload: event.target.checked,
    });
  };
}

export function updateTitle({ payload }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TITLE,
      payload,
    });
  };
}

export function updateDescription({ payload }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DESCRIPTION,
      payload,
    });
  };
}

export function updateTransactor({ payload }) {
  return (dispatch, getState) => {
    const { transactor, data } = getState().moneyball.smartSearchBuilder.builder;

    dispatch({
      type: UPDATE_TRANSACTOR,
      payload: {
        transactor: payload,
        data: payload === transactor ? data : {
          transactor: payload,
          statement: {
            id: `statementgroup-${new Date().getTime()}`,
            type: 'statements_group',
            operator: {
              label: 'And',
              value: 'and',
            },
            statements: [],
          },
        },
      },
    });
  };
}

export function updateData({ payload }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DATA,
      payload,
    });
  };
}

export function getList({ id, copy }) {
  return (dispatch) => {
    dispatch({ type: GET_LIST_START });

    const ajax = $.ajax({
      url: `/api_web/moneyball_v2/smart_searches/${id}`,
      type: 'GET',
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: GET_LIST_DONE,
        error: false,
        payload: transformDataForFront({ ...response, copy }),
      }))
      .catch(() => {
        dispatch({
          type: GET_LIST_DONE,
          error: true,
          payload: {
            title: '',
            description: '',
            isTemplate: false,
            transactor: '',
            data: {},
          },
        });
      });
  };
}

export function submitList({ id, payload, onSuccess }) {
  return (dispatch) => {
    dispatch({ type: CREATE_LIST_START });

    const { isTemplate } = payload || {};
    const { govpredictAdmin } = gon.currentUser;
    const type = (id && !isTemplate || id && (govpredictAdmin && isTemplate)) ? 'PUT' : 'POST';
    const urlSuffix = (id && !isTemplate || id && (govpredictAdmin && isTemplate)) ? `/${id}` : '';

    if (type === 'POST') {
      trackMoneyBallSmartSearchCreate();
    } else {
      trackMoneyBallSmartSearchUpdate();
    }

    const ajax = $.ajax({
      type,
      url: `/api_web/moneyball_v2/smart_searches${urlSuffix}`,
      contentType: REQUEST_CONTENT_TYPE,
      data: transformDataForBack({ payload }),
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: CREATE_LIST_DONE,
        payload: response,
      }))
      .then(() => {
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      })
      .catch(Notifications.defaultSubmitDataFailed);
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: RESET,
    });
  };
}
