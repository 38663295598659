import { forEach, values, includes } from 'lodash';

import { EXECUTIVE_PERSON_TYPES, EXECUTIVE_PERSON } from 'redux/grm2/utils/constants';

const isExecutivePerson = type => includes(values(EXECUTIVE_PERSON_TYPES), type);

export const getCardLinkType = type => (isExecutivePerson(type) ? EXECUTIVE_PERSON : type);

export const transformCardLinks = (card) => {
  const fields = ['people', 'bills', 'committees', 'documents', 'hillDayActivity', 'regulations'];
  const cardLinks = [];

  forEach(fields, (field) => {
    const fieldValues = card[field] || [];

    forEach(fieldValues, ({ value, type }) => {
      cardLinks.push({
        record_id: value,
        record_type: getCardLinkType(type),
      });
    });
  });

  return cardLinks;
};

export const toCardLinks = ({ type, value }) => ({
  type: getCardLinkType(type),
  id: value,
});
