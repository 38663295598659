const PREFIX = 'STAKEHOLDER_MANAGEMENT';

const constants = {
  FETCH_ALL_LEGISLATORS: `${PREFIX}/FETCH_ALL_LEGISLATORS`,
  RECEIVE_ALL_LEGISLATORS_DONE: `${PREFIX}/RECEIVE_ALL_LEGISLATORS_DONE`,
  RECEIVE_LEGISLATORS_DONE: `${PREFIX}/RECEIVE_LEGISLATORS_DONE`,
  FETCH_LEGISLATORS: `${PREFIX}/FETCH_LEGISLATORS`,
  RECEIVE_LEGISLATORS_FAIL: `${PREFIX}/RECEIVE_LEGISLATORS_FAIL`,
  FETCH_DISTRICT: `${PREFIX}/FETCH_DISTRICT`,
  RECEIVE_DISTRICT_FAIL: `${PREFIX}/RECEIVE_DISTRICT_FAIL`,
};

export default constants;
