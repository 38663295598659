export function transformDataSetForUI(dataSet) {
  const {
    total_rows,
    updated_at,
    is_rows_aggregate,
    ...restData
  } = dataSet;

  return {
    totalRows: String(total_rows),
    updatedAt: updated_at,
    isRowsAggregate: is_rows_aggregate,
    ...restData,
  };
}

export function transformPaginationForUI(pagination) {
  const {
    current_page,
    next_page,
    per_page,
    prev_page,
    total_count,
    total_pages,
  } = pagination;

  return {
    currentPage: current_page,
    nextPage: next_page,
    pageSize: per_page,
    prevPage: prev_page,
    totalCount: total_count,
    totalPages: total_pages,
  };
}
