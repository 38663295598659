import constants from '../constants';
import {
  transformCommitteeMembersForUI,
} from '../utils';

function fetchMembers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEE_MEMBERS });
    const { id } = payload;

    const url = `/api_web/state/committees/${id}/members`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_COMMITTEE_MEMBERS_DONE,
        payload: transformCommitteeMembersForUI(response.data),
      }));
  };
}

export function loadOverview(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_OVERVIEW });

    const asyncRequests = [
      dispatch(fetchMembers(payload)),
    ];

    return Promise.all(asyncRequests)
      .then(() => dispatch({ type: constants.LOAD_OVERVIEW_DONE }));
  };
}
