import { map, find, isEmpty } from 'lodash';

export function updatePostsReplies(posts, replies) {
  function getUpdatedItem(item) {
    const { replyTo } = item;

    if (isEmpty(replyTo)) {
      return item;
    }

    const { tweetId } = replyTo;
    const reply = find(replies, ({ externalToken }) => externalToken === tweetId);

    return {
      ...item,
      ...(reply && { reply }),
    };
  }

  return isEmpty(replies) ? posts : map(posts, item => getUpdatedItem(item));
}
