import {
  SET_NEW_CAMPAIGN_TYPE,
  FETCH_CAMPAIGN,
  RECEIVE_CAMPAIGN,
  CAMPAIGN_NOT_FOUND,
  FETCH_REGULATION,
  RECEIVE_REGULATION,
  STOP_LOADING,
  RECEIVE_RECIPIENT,
  RECEIVE_DELETED_RECIPIENT,
  RECEIVE_MESSAGE,
  RECEIVE_PTC_MESSAGE,
  RECEIVE_THANK_YOU_MESSAGE,
  RECEIVE_LOGO_CONFIG,
  RESET_STATE,
  FETCH_FORM_INITIAL_VALUES,
  RECEIVE_FORM_INITIAL_VALUES,
  SAVE_CUSTOM_RECIPIENT,
  FAILED_SAVE_CUSTOM_RECIPIENT,
  DELETE_CUSTOM_RECIPIENTS,
  DELETED_CUSTOM_RECIPIENTS,
  RECEIVE_DELETED_CUSTOM_RECIPIENT,
  FETCH_CUSTOM_RECIPIENTS,
  RECEIVE_CUSTOM_RECIPIENTS,
  RECEIVE_PAGINATION,
} from './constants';

import defaultState from './defaultState';

export default function (state = defaultState, action) {
  switch (action.type) {
    case SET_NEW_CAMPAIGN_TYPE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          campaign_type: action.payload.campaignType,
        },
      };
    case FETCH_CAMPAIGN:
      return {
        ...state,
        campaign: {
          ...defaultState.campaign,
          token: action.payload.campaignToken,
        },
        ui: { ...state.ui, loading: true },
      };
    case RECEIVE_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
        ui: { ...state.ui, loading: false },
      };
    case CAMPAIGN_NOT_FOUND:
      return {
        ...state,
        ui: {
          ...state.ui,
          campaignNotFound: true,
          loading: false,
        },
      };
    case FETCH_REGULATION:
      return {
        ...state,
        regulation: {
          ...defaultState.regulation,
          id: action.regulationId,
        },
        ui: { ...state.ui, loading: true },
      };
    case RECEIVE_REGULATION:
      return {
        ...state,
        regulation: action.payload,
        ui: { ...state.ui, loading: false },
      };
    case STOP_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case RECEIVE_RECIPIENT: {
      let updated = false;

      let updatedMatchers = state.campaign.matchers.map(
        (matcher) => {
          if (matcher.id !== action.payload.id) {
            return matcher;
          }

          updated = true;
          return action.payload;
        }
      );

      if (!updated) {
        updatedMatchers = [...updatedMatchers, action.payload];
      }

      return {
        ...state,
        campaign: {
          ...state.campaign,
          matchers: updatedMatchers,
        },
      };
    }
    case RECEIVE_DELETED_RECIPIENT:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          matchers: state.campaign.matchers.filter(
            matcher => matcher.id !== action.payload.id
          ),
        },
      };

    case FETCH_CUSTOM_RECIPIENTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingCustomRecipients: true,
        },
      };

    case RECEIVE_CUSTOM_RECIPIENTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingCustomRecipients: false,
        },
        custom_recipients: action.payload,
      };

    case SAVE_CUSTOM_RECIPIENT:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingCustomRecipients: true,
        },
      };

    case FAILED_SAVE_CUSTOM_RECIPIENT:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingCustomRecipients: false,
        },
      };

    case DELETE_CUSTOM_RECIPIENTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingCustomRecipients: true,
        },
      };

    case DELETED_CUSTOM_RECIPIENTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          updatingCustomRecipients: false,
        },
      };

    case RECEIVE_DELETED_CUSTOM_RECIPIENT:
      return {
        ...state,
        custom_recipients: state.custom_recipients.filter(
          recipient => recipient.id !== action.payload.id
        ),
        ui: {
          ...state.ui,
          updatingCustomRecipients: false,
        },
      };

    case RECEIVE_MESSAGE: {
      let updated = false;

      let updatedMessages = state.campaign.matcher_messages.map(
        (message) => {
          if (message.id !== action.payload.id) {
            return message;
          }

          updated = true;
          return action.payload;
        }
      );

      if (!updated) {
        updatedMessages = [...updatedMessages, action.payload];
      }

      return {
        ...state,
        campaign: {
          ...state.campaign,
          matcher_messages: updatedMessages,
        },
      };
    }
    case RECEIVE_PTC_MESSAGE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          ptc_config: action.payload,
        },
      };
    case RECEIVE_THANK_YOU_MESSAGE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          thank_you_config: action.payload,
        },
      };
    case RECEIVE_LOGO_CONFIG:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          logo_config: action.payload,
        },
      };
    case FETCH_FORM_INITIAL_VALUES:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingForm: true,
        },
      };
    case RECEIVE_FORM_INITIAL_VALUES:
      return {
        ...state,
        initialValues: action.payload,
        ui: {
          ...state.ui,
          loadingForm: false,
        },
      };
    case RECEIVE_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case RESET_STATE:
      return defaultState;
    default:
      return state;
  }
}
