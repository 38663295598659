import { map, toString, flatten, keys, head, get } from 'lodash';

export function transformDocumentIdsForBE(documentIds = []) {
  return map(documentIds, id => toString(id));
}

export function transformMyFeedDocuments(data, documentTypes) {
  const documents = map(data, (typeDocuments, index) => {
    const documentType = documentTypes[index];

    return map(typeDocuments, (document) => {
      const [recordId] = keys(document);

      return {
        documentType,
        recordId,
        sharings: document[recordId],
      };
    });
  });

  return flatten(documents);
}

export function transformDocumentsForProfile(data, id) {
  const sharings = get(head(data), id, []);

  return sharings;
}
