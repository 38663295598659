const BASE_POSITION_URL = '/api_web/user_positions';

function fetchAllPositions(payload) {
  const url = `${BASE_POSITION_URL}/lookup`;
  const ajax = $.ajax({
    url,
    method: 'POST',
    data: JSON.stringify(payload),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax).then(response => response);
}

function fetchPosition({ recordId, recordType }) {
  const url = `${BASE_POSITION_URL}/${recordType}/${recordId}`;
  const ajax = $.get(url);

  return Promise.resolve(ajax)
    .then(({ position_type }) => position_type);
}

function fetchPositionsByType({ recordIds, recordType }) {
  const url = `${BASE_POSITION_URL}/${recordType}`;
  const data = { record_ids: recordIds };
  const ajax = $.ajax({
    method: 'GET',
    url,
    data,
  });

  return Promise.resolve(ajax);
}

function deletePosition({ recordId, recordType }) {
  const url = `${BASE_POSITION_URL}/${recordType}/${recordId}`;
  const ajax = $.ajax({ method: 'DELETE', url });

  return Promise.resolve(ajax);
}

function updatePosition({ recordId, recordType, position }) {
  const url = `${BASE_POSITION_URL}/apply`;
  const data = {
    record_id: recordId,
    record_type: recordType,
    position_type: position,
  };

  const ajax = $.post(url, data);

  return Promise.resolve(ajax);
}

export default {
  fetchPosition,
  fetchAllPositions,
  fetchPositionsByType,
  deletePosition,
  updatePosition,
};
