import { map, isEmpty } from 'lodash';

export function transformLargestEmployersForUI(largestEmployersData = {}) {
  const { data = [] } = largestEmployersData;

  if (isEmpty(data)) {
    return { allItems: [] };
  }

  return {
    allItems: map(data, ({
      address,
      description,
      employees,
      phone,
      title,
      web_site,
    }) => ({
      employer: title,
      description,
      employees,
      phone,
      address,
      web: web_site,
    })),
  };
}
