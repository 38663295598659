import { combineReducers } from 'redux';

import myFeed from './feedPage/reducers';
import keywordSearch from './keywordSearch/reducers';
import trackItems from './trackItems/reducers';
import workspaces from './workspaces/reducers';
import workspaceSettings from './workspaceSettings/reducers';

export default combineReducers({
  myFeed,
  keywordSearch,
  trackItems,
  workspaces,
  workspaceSettings,
});
