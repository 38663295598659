const PREFIX = 'DOCUMENT_VIEW';

const constants = {
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  RECEIVE_IS_SHARED_DONE: `${PREFIX}/RECEIVE_IS_SHARED_DONE`,
  FETCH_DETAILS: `${PREFIX}/FETCH_DETAILS`,
  RECEIVE_DETAILS_DONE: `${PREFIX}/RECEIVE_DETAILS_DONE`,
  RECEIVE_SUMMARY_DONE: `${PREFIX}/RECEIVE_SUMMARY_DONE`,
  RECEIVE_DETAILS_ERROR: `${PREFIX}/RECEIVE_DETAILS_ERROR`,
  EXPORT_DOCUMENT: `${PREFIX}/EXPORT_DOCUMENT`,
  EXPORT_DOCUMENT_DONE: `${PREFIX}/EXPORT_DOCUMENT_DONE`,
};

export default constants;
