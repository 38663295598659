import { TRACKABLE_SLUGS } from './constants';

export const isTrackableRecordType = ({ recordType }) => TRACKABLE_SLUGS.includes(recordType);

const mergeSubscriptionDocType = (baseSubscriptions, newSubscriptions) => (memo, key) => {
  const baseSubscriptionsObject = baseSubscriptions[key];
  const newSubscriptionsObject = newSubscriptions[key] || {};

  return {
    ...memo,
    [key]: {
      ...baseSubscriptionsObject,
      ...newSubscriptionsObject,
    },
  };
};

export const mergeSubscriptions = (baseSubscriptions, newSubscriptions) => {
  const reduceMethod = mergeSubscriptionDocType(baseSubscriptions, newSubscriptions);

  const mergedSubscriptions = Object.keys(baseSubscriptions).reduce(reduceMethod, {});

  return {
    ...newSubscriptions,
    ...mergedSubscriptions,
  };
};

export const isNotFetched = fetchedRecords => ({ id, recordType }) => {
  const recordTypeRecords = fetchedRecords[recordType] || [];

  return !recordTypeRecords.includes(id);
};
