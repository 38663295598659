const PREFIX = 'MONEYBALL_COMMITTEE_LOOKUP';

const constants = {
  STRATEGY_SLUG: 'moneyball_committee_lookup',
  FETCH_COMMITTEES: `${PREFIX}/FETCH_COMMITTEES`,
  RECEIVE_COMMITTEES_DONE: `${PREFIX}/RECEIVE_COMMITTEES_DONE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
