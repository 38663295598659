import constants from './constants';

const defaultState = {
  apiEvents: [],
  events: [],
  calendarData: [],
  checkedEvents: [],
  selectedCommittees: [],
  ui: {
    isLoadingEvents: false,
    isLoadingCalendar: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_EVENTS:
      return {
        ...state,
        apiEvents: [],
        events: [],
        ui: { ...state.ui, isLoadingEvents: true },
      };
    case constants.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        apiEvents: action.apiEvents,
        ui: { ...state.ui, isLoadingEvents: false },
      };
    case constants.FETCH_EVENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingEvents: false },
      };
    case constants.CHECK_EVENTS:
      return {
        ...state,
        checkedEvents: action.checkedEvents,
      };
    case constants.SELECT_CALENDAR_DATE:
      return {
        ...state,
        selectedDate: action.selectedDate,
        checkedEvents: [],
      };
    case constants.FILTER_EVENTS:
      return {
        ...state,
        selectedCommittees: action.selectedCommittees,
        selectedStates: action.selectedStates,
        events: action.payload,
      };
    case constants.SET_MONTHS_AHEAD:
      return {
        ...state,
        monthsAheadToFetch: action.payload,
      };
    default:
      return state;
  }
}
