import { currentDate } from 'utils/date';
import { downloadBlob, getBlob } from 'utils/fileExport';

import constants from '../constants';

export function onExportDocument(payload = {}) {
  return (dispatch) => {
    const { data, exportNotifications, documentType } = payload;
    const { url } = data || {};
    const { name } = documentType || {};
    const { getSuccess, getFailed } = exportNotifications || {};

    dispatch({ type: constants.EXPORT_DOCUMENT });

    function onSuccess(responseData) {
      getSuccess();

      return downloadBlob(
        getBlob(responseData),
        `${name} document ${currentDate()}.pdf`,
      );
    }

    const ajaxProps = {
      dataType: 'native',
      xhrFields: {
        responseType: 'blob',
      },
    };

    const ajax = $.ajax({
      url,
      ...ajaxProps,
    });

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(getFailed)
      .finally(() => dispatch({ type: constants.EXPORT_DOCUMENT_DONE }));
  };
}
