import { isEmpty, isNil, omitBy } from 'lodash';
import moment from 'moment/moment';

import { CSV_ID, EXPORT_EMAIL_LIMIT } from 'utils/constants/export';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { FULL_DATE_FORMAT } from 'utils/constants/date';

import { submitExportReport } from '../../../exportReport/actions';
import { getExportInfo, transformStaffersForUI } from '../utils';
import constants from '../constants';
import { getStaffersData } from './data';

export function getAllEmails(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.FETCH_STAFFERS_EMAILS });
    const { appliedFilters } = getState().state.stafferLookup.appliedFilters;

    const { url, data } = getStaffersData({ ...payload, searchData: appliedFilters });
    const ajax = $.post(url, { ...data, per_page: EXPORT_EMAIL_LIMIT });

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_STAFFERS_EMAILS_DONE,
        payload: transformStaffersForUI(response.data),
      });
    }

    return Promise.resolve(ajax).then(onSuccess);
  };
}

export function submitExport(payload = {}) {
  const { items, searchData, exportOption } = payload;
  const exportIds = items && items.map(item => item.id);
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: constants.STRATEGY_SLUG_FOR_EXPORT,
    'strategy_inputs[ids]': exportIds,
    'strategy_inputs[file_extension]': exportOption || CSV_ID,
    ...omitBy(getExportInfo(searchForm), isNil),
  };


  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `State Staffers ${moment().format(FULL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({ ...payload, data, onSuccessCustom });
}
