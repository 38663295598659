import { isNull, omitBy } from 'lodash';

function transformOfficeStafferForUI(officeStaffer) {
  const {
    full_name,
  } = officeStaffer;

  return omitBy({
    ...officeStaffer,
    fullName: full_name,
  }, isNull);
}

export function transformPaginationForUI(pagination) {
  const {
    current_page,
    next_page,
    prev_page,
    total_pages,
    total_count,
  } = pagination;

  return ({
    currentPage: current_page,
    nextPage: next_page,
    prevPage: prev_page,
    totalPages: total_pages,
    totalCount: total_count,
  });
}

export function transformOfficeStaffersForUI(response = {}) {
  const { data, pagination } = response;

  return omitBy({
    officeStaffers: data.map(staffer => transformOfficeStafferForUI(staffer)),
    pagination: transformPaginationForUI(pagination),
  }, isNull);
}
