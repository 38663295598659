import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { getLookupRequestParams } from 'utils/lookup';

import { transformAgencyStaffersForUI, transformOptionsForBE } from '../utils';
import constants from '../constants';

export function fetchAgencyStaffers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_AGENCY_STAFFERS });
    const url = '/api_web/federal/agency_staffers/lookup';

    const { searchData = {}, clearAll, toResetSelectedItems } = payload;
    const dataPayload = { url, ...payload };
    const { isSearch, data, sortData } = getLookupRequestParams(dataPayload, transformOptionsForBE);
    const ajax = $.post(url, data);

    function onSuccess(response) {
      const currentPayload = (isEmpty(response) || isEmpty(response.data)) ? [] : response.data;

      return dispatch({
        type: constants.RECEIVE_AGENCY_STAFFERS_DONE,
        payload: transformAgencyStaffersForUI(currentPayload),
        paginator: transformPaginator(response),
        toResetSelectedItems: isSearch || toResetSelectedItems,
        sort: transformSort(sortData),
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(saveAppliedFiltersToProps);
  };
}

export function resetAgencyStaffers() {
  return dispatch => (
    dispatch({
      type: constants.RESET_AGENCY_STAFFERS,
    })
  );
}

export function getAgencyStaffersData(payload) {
  const {
    searchData = {},
    appliedFilters,
    clearAll,
    sort,
    direction,
    page,
    perPage,
  } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const url = '/api_web/federal/agency_staffers/lookup';
  const sortData = omitBy({ sort, direction, page, per_page: perPage }, isNil);
  const data = { ...searchForm, ...sortData };

  return { isSearch, url, data, sortData };
}
