import partial from 'lodash/partial';

import { responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { ROW_PLACEHOLDER as PAGE_SIZE } from 'components/State/CommitteeProfile/utils';
import { fetchProfileDocument, updateProfileShare } from 'redux/events/actions';
import { getCommitteeAction } from 'redux/dashboardV2/feedPage/actions/trackCommittee';
import {
  fetchProfileCommitteeSubscriptions,
  updateCommitteeSubscriptions,
} from 'redux/subscriptions/actions';
import { transformFetchRecordIdsForBE } from 'redux/tags/utils';
import { fetchProfileTags } from 'redux/tags/actions';
import { getMyFeedToken } from 'components/Dashboard/utils';

import { transformProfileForUI } from '../utils';
import constants from '../constants';

export { loadOverview } from './overview';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}

export function onUpdateTrackings({ trackings }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: trackings,
  });
}

export function fetchSubscriptions(payload = {}) {
  return (dispatch) => {
    const { id, documentType: defaultDocumentType } = payload;

    const data = transformFetchRecordIdsForBE([
      { documentType: responseTrackingTypes.STATE_COMMITTEE_HEARING, id },
      { documentType: responseTrackingTypes.STATE_COMMITTEE_STAFFER, id },
    ]);

    return dispatch(
      fetchProfileCommitteeSubscriptions({
        data,
        defaultDocumentType,
        actionTypes: constants,
        feedToken: getMyFeedToken(),
      })
    );
  };
}

export function updateSubscriptions(payload) {
  return dispatch => dispatch(
    updateCommitteeSubscriptions({
      isProfile: true,
      action: partial(getCommitteeAction, partial.placeholder, false),
      actionTypes: constants,
      payload,
    })
  );
}

export function fetchCommittee(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEE });
    const { id } = payload;

    const ajax = $.get(`/api_web/state/committees/${id}`);

    const onSuccess = response => dispatch({
      type: constants.RECEIVE_COMMITTEE_DONE,
      payload: transformProfileForUI(response.data),
    });

    const onFailure = (error) => {
      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(() => dispatch(fetchTags(payload)))
      .then(() => dispatch(fetchProfileDocument(payload, constants)))
      .then(() => dispatch(fetchSubscriptions(payload)))
      .catch(onFailure);
  };
}

export function resetCommitteeData() {
  return dispatch => dispatch({ type: constants.RESET_COMMITTEE });
}

export function updateProfileMyFeed() {
  return (dispatch) => {
    dispatch(updateProfileShare(constants));
  };
}
