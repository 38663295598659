import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import { loadingUpdateItem, updateDataItem, updateDataItemArrayPayload } from 'utils/reducer';

import constants from './constants';

const DEFAULT_STATE = {
  defaultSubscription: NONE_ID,
  paginator: {},
  records: [],
  ui: {
    isLoading: true,
    isLoadingTags: true,
    isLoadingSubscriptions: true,
    isLoadingMyFeedDocuments: true,
  },
};

const recordsKeyName = 'records';

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_PUBLIC_RECORDS:
      return {
        ...state,
        paginator: state.paginator,
        ui: { ...state.ui, isLoading: true },
      };
    case constants.RECEIVE_PUBLIC_RECORDS_DONE:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, isLoading: false },
      };
    case constants.SET_PUBLIC_RECORDS:
      return {
        ...state,
        ...action.payload,
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, recordsKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, recordsKeyName),
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, recordsKeyName),
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, recordsKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    default:
      return state;
  }
}
