import isEmpty from 'lodash/isEmpty';

import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { getValue } from 'redux/moneyball/utils/transformers';
import Notifications from 'components/Moneyball/utils/Notifications';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { fetchSearch, fetchReport } from 'redux/moneyball/utils';
import { getCurrentUserName } from 'components/Moneyball/utils';
import {
  loadSavedSearches,
  loadRecentSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformIndividuals,
  transformSearchesForFE,
  transformOptionsSearchForBE,
  transformOptionsForBE,
} from './utils';
import constants from './constants';

export function initAppliedSearchFilters(emptyState, { searchType }) {
  return handleAppliedFilters({ ...emptyState, ...(searchType && { searchType }) });
}

export function loadIndividualSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadIndividualRecentSearches() {
  return (dispatch) => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveIndividualAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformOptionsSearchForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadIndividualRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeIndividualAdvancedSearch(id) {
  return onRemoveItem(id, loadIndividualSavedSearches);
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map((item) => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/super_individuals/export`;
  const data = JSON.stringify({
    super_individual_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'name',
      'url',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'total_amount_contributed',
      'is_restricted',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function searchIndividuals(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUALS });

    return dispatch(
      fetchSearch({
        payload,
        url: `${gon.moneyballApiV2}/api/v1/super_individuals/lookup`,
        actionType: constants.RECEIVE_INDIVIDUALS_DONE,
        transformResponsePayload: transformIndividuals,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformOptionsSearchForBE,
        saveRecentAdvancedSearch: saveIndividualAdvancedSearch,
      }),
    );
  };
}

export function checkClusterbuck({ items = [] }) {
  return (dispatch) => {
    dispatch({ type: constants.CHECK_CLUSTERBUCK_INIT });

    const onSuccess = ({ cluster_exists }) => {
      dispatch({
        type: constants.CHECK_CLUSTERBUCK_DONE,
        allow: !cluster_exists,
      });
    };

    const onFailure = (error) => {
      dispatch({
        type: constants.CHECK_CLUSTERBUCK_DONE,
        allow: false,
      });

      Notifications.defaultSubmitDataFailed();
      throw error;
    };

    const ajax = $.ajax({
      type: 'POST',
      contentType: REQUEST_CONTENT_TYPE,
      url: `${gon.moneyballApiV2}/api/v1/clusterbuck/cluster_exists`,
      data: JSON.stringify({ super_individual_ids: items }),
    });

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function sendToClusterbuck({
  items = [],
  toggleClusterbuckModal,
}) {
  return (dispatch) => {
    dispatch({ type: constants.SUBMIT_TO_CLUSTERBUCK_INIT });

    const onSuccess = () => {
      dispatch({ type: constants.SUBMIT_TO_CLUSTERBUCK_DONE, allow: false });
      toggleClusterbuckModal();
    };

    const onFailure = (error) => {
      dispatch({ type: constants.SUBMIT_TO_CLUSTERBUCK_DONE, allow: true });
      Notifications.defaultSubmitDataFailed();
      throw error;
    };

    const ajax = $.ajax({
      type: 'POST',
      contentType: REQUEST_CONTENT_TYPE,
      url: `${gon.moneyballApiV2}/api/v1/clusterbuck/send`,
      data: JSON.stringify({ super_individual_ids: items, reviewer_name: getCurrentUserName() }),
    });

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function reset() {
  return (dispatch) => dispatch(resetAppliedFilters());
}
