import { isEmpty, isArray, groupBy, filter, map, reduce, uniq } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import * as trackingsApi from 'redux/dashboardV2/common/trackings/api';

import constants from '../constants';

function transformSubscriptionResponse(response) {
  const { data } = response || {};

  return reduce(data, (result, item) => {
    const { record_type: recordType, subscriptions } = item || {};
    const isNewsfeed = recordType === DOCUMENT_TYPES.NEWS_SEARCH;

    const formattedData = map(subscriptions, ({ record_id: recordId, subscription }) => {
      const {
        subscribable_data: subscribableData,
        subscribable_type: subscribableType,
        frequency,
      } = subscription || {};
      const { send_week_ahead_email, model_id } = subscribableData || {};

      return {
        modelId: model_id,
        type: subscribableType,
        isTracking: !isEmpty(frequency),
        sendWeekAheadEmail: send_week_ahead_email,
        trackRecordType: recordType,
        frequency,
        ...(isNewsfeed ? { searchId: recordId } : { recordId }),
      };
    }) || [];

    result.push(...formattedData);

    return result;
  }, []);
}

function formatTrackRecords(items) {
  const getTrackRecord = (trackRecordType, record) => {
    if (isArray(trackRecordType)) {
      return map(trackRecordType, type => ({ ...record, trackRecordType: type }));
    }

    return [{ ...record, trackRecordType }];
  };

  return reduce(items, (result, item) => {
    const { trackRecordType, ...restTrackRecord } = item || {};
    const formatData = getTrackRecord(trackRecordType, restTrackRecord);

    result.push(...formatData);

    return result;
  }, []);
}

export function fetchItemsTracking(feedToken, data) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_ITEMS_SUBSCRIPTIONS_INIT });

    const filteredTrack = filter(data, 'withTrack');
    const formattedTrack = formatTrackRecords(filteredTrack);
    const groupedItems = groupBy(formattedTrack, 'trackRecordType');

    const formattedValues = map(groupedItems, (value, key) => {
      const isNewsfeed = key === DOCUMENT_TYPES.NEWS_SEARCH;
      const idKey = isNewsfeed ? 'searchId' : 'recordId';

      return {
        record_type: key,
        record_ids: uniq(map(value, idKey)),
      };
    });

    if (isEmpty(formattedValues)) {
      return dispatch({
        payload: [],
        type: constants.LOAD_ITEMS_SUBSCRIPTIONS_FINISHED,
      });
    }

    const onSuccess = (response) => {
      dispatch({
        payload: transformSubscriptionResponse(response),
        type: constants.LOAD_ITEMS_SUBSCRIPTIONS_FINISHED,
      });
    };

    const onFailure = (error) => {
      dispatch({
        payload: [],
        type: constants.LOAD_ITEMS_SUBSCRIPTIONS_FINISHED,
      });

      return new Error(error);
    };

    return trackingsApi.fetchTrackingsBulk(formattedValues, feedToken)
      .then(onSuccess)
      .catch(onFailure);
  };
}
