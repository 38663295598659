import { transformBillsAndVotesForUI } from 'redux/votingRecord/utils';

export function transformRollCallVotesForUI(rollCallVotesData = {}) {
  return transformBillsAndVotesForUI(rollCallVotesData);
}

export function transformRollCallVotesForBE(payload) {
  const { id, ...restProps } = payload || {};

  return {
    ...restProps,
    search_form: {
      bill_id: id,
    },
  };
}
