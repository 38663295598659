import { groupBy, size, map, flatMap } from 'lodash';

import {
  fetchLookupSubscriptions,
  bulkTrackLookupItems,
  fetchDefaultSubscription,
} from 'redux/subscriptions/actions';
import notifications from 'components/Dashboard/utils/notifications';
import { notifySharedToMyFeedMultiple } from 'components/Dashboard/common/UserNotifications';
import { transformSharedDocuments } from 'redux/subscriptions/utils/transformers';
import {
  bulkShareToMyFeed as bulkShareToMyFeedApi,
} from 'redux/dashboardV2/common/subscriptions/api';

import constants from '../constants';

export function fetchSubscriptions(payload = {}) {
  return (dispatch) => {
    dispatch(fetchDefaultSubscription(constants));
    dispatch(fetchLookupSubscriptions(payload, null, constants));
  };
}

export function bulkTrackItems(payload) {
  return dispatch => dispatch(bulkTrackLookupItems(payload, constants));
}

export function bulkShareToMyFeed(selectedItems) {
  return (dispatch) => {
    dispatch({ type: constants.BULK_SHARE_TO_MY_FEED });

    const selectedGroups = groupBy(selectedItems, 'documentType');
    const documentTypes = Object.keys(selectedGroups);
    const requests = map(selectedGroups, (groupedItems, groupType) => {
      const data = {
        record_type: groupType,
        record_ids: map(groupedItems, 'id'),
      };

      return bulkShareToMyFeedApi(data);
    });

    function onSuccess(responses) {
      const sharedItems = flatMap(map(responses, (response, index) => (
        transformSharedDocuments(response, documentTypes[index]))));

      dispatch({
        type: constants.BULK_SHARE_TO_MY_FEED_DONE,
        payload: sharedItems,
      });

      notifySharedToMyFeedMultiple(size(sharedItems));
    }

    function onFailure(error) {
      dispatch({ type: constants.BULK_SHARE_TO_MY_FEED_FAILURE });
      notifications.generalFailedNotification();

      throw error;
    }

    return Promise.all(requests)
      .then(onSuccess)
      .catch(onFailure);
  };
}
