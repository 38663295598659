import { isNull, omitBy } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getOfficeStaffer } from 'redux/staffers/common/utils';
import { STATE_STAFFER_ITEM_TYPE } from 'utils/constants/itemTypes';

import transformPositionForUI from './position';
import transformHistoricalPositionForUI from './historicalPosition';

export function transformProfileForUI(profileData = {}) {
  const {
    full_name,
    chamber_category,
    chamber_name,
    twitter_account,
    facebook_account,
    linkedin_account,
    office_type,
    gender_title,
    position,
    historical_positions,
    state_office_id,
    ...restProps
  } = profileData;

  return omitBy({
    ...restProps,
    fullName: full_name,
    chamberCategory: chamber_category,
    chamberName: chamber_name,
    twitterAccount: twitter_account,
    facebookAccount: facebook_account,
    linkedInAccount: linkedin_account,
    officeType: office_type,
    genderTitle: gender_title,
    position: transformPositionForUI(position),
    stateOfficeId: state_office_id,
    historicalPositions: getOfficeStaffer(historical_positions).map(transformHistoricalPositionForUI),
    itemType: STATE_STAFFER_ITEM_TYPE,
    documentType: DOCUMENT_TYPES.STATE_STAFFER,
  }, isNull);
}
