import { combineReducers } from 'redux';
import stakeholder from 'redux/stakeholder/common/reducers';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import customFields from 'redux/customFields/reducers';
import legislatorLookup from 'redux/legislator/lookup/reducers';

import constants from './constants';

const defaultState = {
  stakeholderProfile: {},
  stakeholderContributions: {},
  lastSyncAt: null,
  smartLists: [],
  lists: [],
  tags: [],
  customFieldsAnswers: {},
  ui: {
    isLoadingSmartLists: true,
    isLoadingLists: true,
    isLoadingTags: true,
    isDeletingStakeholderTag: false,
    isDeletingStakeholderList: false,
    errorLoadingStakeholder: false,
    isLoadingStakeholderProfile: true,
    isLoadingContributions: false,
  },
};

function stakeholderProfile(state = defaultState, { type, payload }) {
  switch (type) {
    case constants.FETCH_STAKEHOLDER_PROFILE:
      return {
        ...state,
        stakeholderProfile: {},
        ui: { ...state.ui, isLoadingStakeholderProfile: true },
      };
    case constants.RECEIVE_STAKEHOLDER_PROFILE_DONE:
      return {
        ...state,
        stakeholderProfile: {
          ...state.stakeholderProfile,
          ...payload.profile,
        },
        smartLists: payload.profile.smartLists || defaultState.smartLists,
        lists: payload.profile.lists,
        tags: payload.profile.tags,
        customFieldsAnswers: payload.customFieldsAnswers,
        ui: {
          ...state.ui,
          isLoadingStakeholderProfile: false,
          isLoadingTags: false,
          isLoadingSmartLists: false,
          isLoadingLists: false,
          errorLoadingStakeholder: false,
        },
      };
    case constants.UPDATE_STAKEHOLDER_PROFILE_DESCRIPTION:
      return {
        ...state,
        stakeholderProfile: {
          ...state.stakeholderProfile,
          description: payload.description,
        },
      };
    case constants.RECEIVE_STAKEHOLDER_PROFILE_FAIL:
      return {
        ...state,
        stakeholderProfile: {},
        ui: {
          ...state.ui,
          isLoadingStakeholderProfile: false,
          isLoadingTags: false,
          isLoadingLists: false,
          errorLoadingStakeholder: true,
        },
      };
    case constants.DELETE_STAKEHOLDER_TAG:
      return {
        ...state,
        ui: { ...state.ui, isDeletingStakeholderTag: true },
      };
    case constants.DELETE_STAKEHOLDER_TAG_DONE:
      return {
        ...state,
        ui: { ...state.ui, isDeletingStakeholderTag: false },
      };
    case constants.DELETE_STAKEHOLDER_LIST:
      return {
        ...state,
        ui: { ...state.ui, isDeletingStakeholderList: true },
      };
    case constants.DELETE_STAKEHOLDER_LIST_DONE:
      return {
        ...state,
        ui: { ...state.ui, isDeletingStakeholderList: false },
      };
    case constants.SORT_STAKEHOLDER_SMART_LIST_DATA:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSmartLists: true },
      };
    case constants.SORT_STAKEHOLDER_SMART_LIST_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSmartLists: false },
        ...(payload && { smartLists: payload }),
      };
    case constants.SORT_STAKEHOLDER_LIST_DATA:
      return {
        ...state,
        ui: { ...state.ui, isLoadingLists: true },
      };
    case constants.SORT_STAKEHOLDER_LIST_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingLists: false },
        ...(payload && { lists: payload }),
      };
    case constants.SORT_STAKEHOLDER_TAG_DATA:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.SORT_STAKEHOLDER_TAG_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: false },
        ...(payload && { tags: payload }),
      };
    case constants.FETCH_STAKEHOLDER_CONTRIBUTIONS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingContributions: true,
        },
      };
    case constants.FETCH_STAKEHOLDER_CONTRIBUTIONS_DONE:
      return {
        ...state,
        stakeholderContributions: {
          contributions: payload.items,
          paginator: payload.paginator,
          sort: payload.sort,
        },
        ui: {
          ...state.ui,
          isLoadingContributions: false,
        },
      };
    case constants.FETCH_STAKEHOLDER_CONTRIBUTIONS_FAIL:
      return {
        ...state,
        stakeholderContributions: {
          contributions: payload.items,
          paginator: payload.paginator,
          sort: payload.sort,
        },
        ui: {
          ...state.ui,
          stakeholderContributions: {},
          isLoadingContributions: false,
        },
      };
    case constants.FETCH_CONTRIBUTIONS_LAST_SYNC:
      return {
        ...state,
      };
    case constants.FETCH_CONTRIBUTIONS_LAST_SYNC_DONE:
      return {
        ...state,
        lastSyncAt: payload.lastSyncAt,
      };
    case constants.FETCH_CONTRIBUTIONS_LAST_SYNC_FAIL:
      return {
        ...state,
        lastSyncAt: payload.lastSyncAt,
      };
    case constants.RESET_DATA:
      return defaultState;
    default:
      return state;
  }
}

export default combineReducers({
  stakeholder,
  stakeholderProfile,
  appliedFilters: appliedSearchFilters,
  customFields,
  legislatorLookup,
});
