import { combineReducers } from 'redux';

import agencyStafferProfile from './agencyStafferProfile/reducers';
import legislatorLookup from './legislatorLookup/reducers';
import legislatorProfile from './legislatorProfile/reducers';
import committeesLookup from './committeesLookup/reducers';
import committeeProfile from './committeeProfile/reducers';
import stafferLookup from './stafferLookup/reducers';
import stafferProfile from './stafferProfile/reducers';
import billProfile from './billProfile/reducers';
import billLookup from './billLookup/reducers';
import regulationsLookup from './regulations/lookup/reducers';
import regulationsProfile from './regulations/profile/reducers';
import regulationsDocument from './regulations/document/reducers';
import executiveLookup from './executiveLookup/reducers';
import executiveProfile from './executiveProfile/reducers';
import hearingCalendar from './hearingCalendars/reducers';

export default combineReducers({
  agencyStafferProfile,
  committeesLookup,
  legislatorLookup,
  legislatorProfile,
  committeeProfile,
  stafferLookup,
  stafferProfile,
  billProfile,
  billLookup,
  regulations: combineReducers({
    lookup: regulationsLookup,
    profile: regulationsProfile,
    document: regulationsDocument,
  }),
  executiveLookup,
  executiveProfile,
  hearingCalendar,
});
