import constants from '../../constants';

export { default as resetCommittees } from './resetCommittees';
export { default as fetchCommittees } from './fetchCommittees';
export { default as getCommitteesData } from './getCommitteesData';

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
