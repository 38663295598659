import find from 'lodash/find';

import { responseEventTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getSource } from 'redux/dashboardV2/feedPage/utils';
import {
  getAllFilterProducts,
  FEDERAL_LEGISLATOR,
  STATE_LEGISLATOR,
  STATE_REGULATIONS_SLUG,
  GRM_ACTIVITY_TYPE,
  NEWS_STORY_SLUG,
  SOCIAL_MEDIA_SLUG,
} from 'components/Dashboard/utils/libProducts';
import { CARD_TYPES } from 'components/Grm2/constants';

import { getTitle } from '../utils';
import { transformNewsStory } from './newsTransformers';
import { transformTweet } from './socialMediaTransformers';

function transformSharedDocument({ event_data, createdAt, feed, isNotification = false }) {
  const {
    title,
    description,
    come_from: comeFrom,
    activity_card_type,
    party_short: partyShort,
    document_url: titleUrl,
    document_type: documentType,
    user_full_name: userName,
    state_alpha2: stateAlpha2,
  } = event_data || {};

  if (documentType === NEWS_STORY_SLUG) {
    return transformNewsStory({ event_data, createdAt, isShared: true });
  }

  if (documentType === SOCIAL_MEDIA_SLUG) {
    return transformTweet({ event_data, createdAt });
  }

  const allProducts = [
    ...getAllFilterProducts(),
    { id: FEDERAL_LEGISLATOR, type: 'Federal legislator' },
    { id: STATE_LEGISLATOR, type: 'State legislator' },
    { id: STATE_REGULATIONS_SLUG, type: 'State regulatory document' },
    { id: GRM_ACTIVITY_TYPE, type: 'Activity' },
  ];

  const { type: itemType } = find(allProducts, { id: documentType }) || {};
  const { [activity_card_type]: activityCardType } = {
    note: CARD_TYPES.note.label,
    task: CARD_TYPES.task.label,
    hill_day_note: CARD_TYPES.hill_day_note.label,
    hill_day_attendance: CARD_TYPES.hill_day_attendance.label,
    hill_day_meeting_attendance: CARD_TYPES.hill_day_meeting_attendance.label,
    grassroots_legislation: CARD_TYPES.grassroots_legislation.label,
  };

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: itemType,
      description,
      itemType,
      itemTitle: title,
      url: titleUrl,
      feedToken,
    };
  }

  return {
    withSharedBy: true,
    actionType: itemType,
    itemType: activityCardType || getTitle(stateAlpha2, itemType),
    createdAt,
    title,
    titleUrl,
    description,
    userName,
    source: getSource({ documentType, title, titleUrl, partyShort, comeFrom }),
  };
}

export const GENERAL_RECENT_TYPE = {
  [responseEventTypes.SHARED_DOCUMENT]: transformSharedDocument,
};
