import { combineReducers } from 'redux';

import smartSearchLookup from './smartSearch/lookup/reducers';
import smartSearchBuilder from './smartSearch/builder/reducers';
import committeeLookup from './committeePages/lookup/reducers';
import committeeProfile from './committeePages/profile/reducers';
import candidateProfile from './candidatePages/profile/reducers';
import candidateLookup from './candidatePages/lookup/reducers';
import individualLookup from './individualPages/lookup/reducers';
import individualProfile from './individualPages/profile/reducers';
import corporationLookup from './corporationPages/lookup/reducers';
import corporationProfile from './corporationPages/profile/reducers';
import disbursementLookup from './disbursementPages/lookup/reducers';
import receiptLookup from './receiptPages/lookup/reducers';
import vendorLookup from './vendorPages/lookup/reducers';
import vendorProfile from './vendorPages/profile/reducers';
import pacRankingLookup from './pacRankingPages/lookup/reducers';
import clusterBuckFirstQueueLookup from './clusterbuckPages/firstQueue/lookup/reducers';
import clusterBuckFirstQueueProfile from './clusterbuckPages/firstQueue/profile/reducers';
import clusterBuckSecondQueueLookup from './clusterbuckPages/secondQueue/lookup/reducers';
import clusterBuckSecondQueueProfile from './clusterbuckPages/secondQueue/profile/reducers';

export default combineReducers({
  smartSearchLookup,
  smartSearchBuilder,
  committeeLookup,
  committeeProfile,
  individualLookup,
  individualProfile,
  corporationLookup,
  corporationProfile,
  vendorLookup,
  vendorProfile,
  candidateProfile,
  candidateLookup,
  disbursementLookup,
  receiptLookup,
  pacRankingLookup,
  clusterBuckFirstQueueLookup,
  clusterBuckFirstQueueProfile,
  clusterBuckSecondQueueLookup,
  clusterBuckSecondQueueProfile,
});
