const PREFIX = 'FEDERAL_PROFILE';

const constants = {
  FETCH_PROFILE: `${PREFIX}/FETCH_PROFILE`,
  RECEIVE_PROFILE_DONE: `${PREFIX}/RECEIVE_PROFILE_DONE`,
  RESET_PROFILE: `${PREFIX}/RESET_PROFILE`,
  FETCH_STAFFERS: `${PREFIX}/FETCH_STAFFERS`,
  RECEIVE_STAFFERS_DONE: `${PREFIX}/RECEIVE_STAFFERS_DONE`,
  FETCH_NEWS: `${PREFIX}/FETCH_NEWS`,
  RECEIVE_NEWS_DONE: `${PREFIX}/RECEIVE_NEWS_DONE`,
  FETCH_DISTRICT_INFO: `${PREFIX}/FETCH_DISTRICT_INFO`,
  RECEIVE_DISTRICT_INFO: `${PREFIX}/RECEIVE_DISTRICT_INFO`,
  RESET_DISTRICT_INFO: `${PREFIX}/RESET_DISTRICT_INFO`,
  FETCH_LARGEST_EMPLOYEE: `${PREFIX}/FETCH_LARGEST_EMPLOYEE`,
  RECEIVE_LARGEST_EMPLOYEE_DONE: `${PREFIX}/RECEIVE_LARGEST_EMPLOYEE_DONE`,
  FETCH_LEGISLATOR_WITH_DISTRICT: `${PREFIX}/FETCH_LEGISLATOR_WITH_DISTRICT`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  UPDATE_PROFILE_MY_FEED: `${PREFIX}/UPDATE_PROFILE_MY_FEED`,
};

export default constants;
