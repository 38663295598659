import { combineReducers } from 'redux';

import advancedSearch from 'redux/advancedSearch/reducers';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import exportReport from 'redux/exportReport/reducers';
import { updateDataItem, updateDataItemArrayPayload } from 'utils/reducer';
import { loadingUpdateItem } from 'redux/federal/committeeLookup/utils/reducersUtils';
import { fetchDocuments, receiveDocuments } from 'redux/local/lookup/actions/utils/reducerUtils';

import constants from './constants';

const defaultState = {
  documents: [],
  paginator: {},
  sort: {},
  ui: {
    isLoadingData: true,
    isLoadingTags: true,
    isInitialLoading: true,
    toResetSelectedItems: true,
    isLoadingMyFeedDocuments: true,
    isSharingItems: false,
  },
};

const localLookupKeyName = 'documents';

function localLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_DOCUMENTS:
      return fetchDocuments({ state, action });
    case constants.RECEIVE_DOCUMENTS_DONE:
      return receiveDocuments({ state, action });
    case constants.RESET_DOCUMENTS:
      return defaultState;
    case constants.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, localLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.BULK_UPDATE_DATA:
      return loadingUpdateItem(state, action);
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, localLookupKeyName),
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, localLookupKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: true },
      };
    case constants.BULK_SHARE_TO_MY_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, localLookupKeyName),
        ui: { ...state.ui, isSharingItems: false },
      };
    default:
      return state;
  }
}

export default combineReducers({
  localLookup,
  advancedSearch,
  appliedFilters: appliedSearchFilters,
  exportReport,
});
