import { combineReducers } from 'redux';

import stakeholderLookup from './Lookup/reducers';
import stakeholderProfile from './Profile/reducers';
import stakeholderMerge from './MergeTool/reducers';
import stakeholderSmartLists from './SmartLists/reducers';
import standalone from './standalone/reducers';

export default combineReducers({
  stakeholderLookup,
  stakeholderProfile,
  stakeholderMerge,
  stakeholderSmartLists,
  standalone,
});
