const PREFIX = 'MONEYBALL_VENDOR_PROFILE';

const constants = {
  STRATEGY_SLUG: 'moneyball_vendor_profile',
  FETCH_VENDOR: `${PREFIX}/FETCH_VENDOR`,
  RECEIVE_VENDOR_DONE: `${PREFIX}/RECEIVE_VENDOR_DONE`,
  FETCH_VENDOR_DISBURSEMENTS: `${PREFIX}/FETCH_VENDOR_DISBURSEMENTS`,
  RECEIVE_VENDOR_DISBURSEMENTS_DONE: `${PREFIX}/RECEIVE_VENDOR_DISBURSEMENTS_DONE`,
  FETCH_VENDOR_RECEIPT: `${PREFIX}/FETCH_VENDOR_RECEIPT`,
  RECEIVE_VENDOR_RECEIPT_DONE: `${PREFIX}/RECEIVE_VENDOR_RECEIPT_DONE`,
  SET_ELECTION_CYCLES: `${PREFIX}/SET_ELECTION_CYCLES`,
  SELECT_ELECTION_CYCLE: `${PREFIX}/SELECT_ELECTION_CYCLE`,
};

export default constants;
