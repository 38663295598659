export const STOP_LOADING = 'GRASSROOTS_CAMPAIGN_BUILDER/STOP_LOADING';
export const RESET_STATE = 'GRASSROOTS_CAMPAIGN_BUILDER/RESET_STATE';

export const SET_NEW_CAMPAIGN_TYPE = 'GRASSROOTS_CAMPAIGN_BUILDER/SET_NEW_CAMPAIGN_TYPE';
export const SAVE_CAMPAIGN = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_CAMPAIGN';
export const FETCH_CAMPAIGN = 'GRASSROOTS_CAMPAIGN_BUILDER/FETCH_CAMPAIGN';
export const RECEIVE_CAMPAIGN = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_CAMPAIGN';
export const CAMPAIGN_NOT_FOUND = 'GRASSROOTS_CAMPAIGN_BUILDER/CAMPAIGN_NOT_FOUND';

export const FETCH_REGULATION = 'GRASSROOTS_CAMPAIGN_BUILDER/FETCH_REGULATION';
export const RECEIVE_REGULATION = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_REGULATION';

export const SAVE_RECIPIENTS = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_RECIPIENTS';
export const RECEIVE_RECIPIENT = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_RECIPIENT';
export const RECEIVE_DELETED_RECIPIENT = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_DELETED_RECIPIENT';

export const SAVE_MESSAGES = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_MESSAGES';
export const RECEIVE_MESSAGE = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_MESSAGE';

export const SAVE_PTC_MESSAGE = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_PTC_MESSAGE';
export const RECEIVE_PTC_MESSAGE = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_PTC_MESSAGE';

export const SAVE_THANK_YOU_MESSAGE = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_THANK_YOU_MESSAGE';
export const RECEIVE_THANK_YOU_MESSAGE = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_THANK_YOU_MESSAGE';

export const SAVE_SETTINGS = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_SETTINGS';
export const SAVE_LOGO_CONFIG = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_LOGO_CONFIG';
export const RECEIVE_LOGO_CONFIG = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_LOGO_CONFIG';

export const FETCH_FORM_INITIAL_VALUES = 'GRASSROOTS_CAMPAIGN_BUILDER/FETCH_FORM_INITIAL_VALUES';
export const RECEIVE_FORM_INITIAL_VALUES = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_FORM_INITIAL_VALUES';

export const SAVE_CUSTOM_RECIPIENT = 'GRASSROOTS_CAMPAIGN_BUILDER/SAVE_CUSTOM_RECIPIENT';
export const FAILED_SAVE_CUSTOM_RECIPIENT = 'GRASSROOTS_CAMPAIGN_BUILDER/FAILED_SAVE_CUSTOM_RECIPIENT';
export const DELETE_CUSTOM_RECIPIENTS = 'GRASSROOTS_CAMPAIGN_BUILDER/DELETE_CUSTOM_RECIPIENTS';
export const DELETED_CUSTOM_RECIPIENTS = 'GRASSROOTS_CAMPAIGN_BUILDER/DELETED_CUSTOM_RECIPIENTS';
export const FETCH_CUSTOM_RECIPIENTS = 'GRASSROOTS_CAMPAIGN_BUILDER/FETCH_CUSTOM_RECIPIENTS';

export const RECEIVE_CUSTOM_RECIPIENTS = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_CUSTOM_RECIPIENTS';
export const RECEIVE_DELETED_CUSTOM_RECIPIENT = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_DELETED_CUSTOM_RECIPIENT';

export const RECEIVE_PAGINATION = 'GRASSROOTS_CAMPAIGN_BUILDER/RECEIVE_PAGINATION';
