import moment from 'moment';
import { isEmpty, omitBy, capitalize } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getSlicedLegislators, getSlicedSponsors } from 'redux/bills/utils';
import { GLOBAL_DATE_FORMAT, DAY_YEAR_DATE_FORMAT } from 'utils/constants/date';
import { getUrlWithBase } from 'utils/url';
import { STATE_BILL_ITEM_TYPE } from 'utils/constants/itemTypes';

function getSponsorUrl(sponsor) {
  return { url: sponsor.url };
}

export function transformBill(bill = {}, index, page) {
  const {
    id,
    display_id,
    introduced_on,
    last_action = { action: '', date: '' },
    snippet = '',
    sponsors = [],
    state,
    status_type,
    title,
    url,
    committee,
    committee_sponsors = [],
    cosponsors,
    has_vote,
    text_version_url,
    description,
    tags,
    summary,
  } = omitBy(bill, isEmpty);

  const allSponsors = getSlicedSponsors(committee_sponsors, sponsors, getSponsorUrl);
  const allSponsorsSize = committee_sponsors.length + sponsors.length;

  return {
    index,
    page,
    id,
    committee,
    snippet,
    state: state && state.alpha2,
    billId: display_id,
    billTitle: title,
    billDescription: description,
    billUrl: url,
    lastAction: last_action && last_action.action,
    lastActionDate: last_action && last_action.date && moment(last_action.date).format(GLOBAL_DATE_FORMAT),
    status: status_type,
    dateIntroduced: moment(introduced_on).format(GLOBAL_DATE_FORMAT),
    hasVote: capitalize(has_vote),
    govPredictLink: getUrlWithBase(url),
    billTextLink: text_version_url,
    sponsors: allSponsors,
    allSponsorsSize,
    cosponsors: getSlicedLegislators(cosponsors, getSponsorUrl),
    allCosponsorsSize: !isEmpty(cosponsors) ? cosponsors.length : 0,
    tags,
    billSummary: summary && summary.body,
    billSummaryLastUpdated: summary && moment(summary.updated_at).format(DAY_YEAR_DATE_FORMAT),
    documentType: DOCUMENT_TYPES.STATE_BILL,
    itemType: STATE_BILL_ITEM_TYPE,
    recordId: id,
    withTrack: true,
  };
}
