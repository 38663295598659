import { combineReducers } from 'redux';
import isEmpty from 'lodash/isEmpty';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';

import constants from './constants';

export const defaultState = {
  individual: {},
  contributions: [],
  contributionsError: false,
  contributionsReceipt: {},
  contributionsReceiptError: false,
  electionCyclesData: [],
  electionCycle: null,
  paginator: {},
  sort: {},
  ui: {
    isMerging: false,
    isInitialLoading: true,
    isIndividualLoading: true,
    isContributionsLoading: true,
    isContributionsReceiptLoading: true,
    toResetSelectedItems: true,
  },
};

function profile(state = defaultState, action) {
  const initialLoadingStatus = !!(
    state.ui.isInitialLoading &&
    state.contributions.length &&
    !isEmpty(state.individual)
  );

  switch (action.type) {
    case constants.FETCH_INDIVIDUAL:
      return {
        ...state,
        individual: {},
        ui: {
          ...state.ui,
          isIndividualLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_INDIVIDUAL_DONE:
      return {
        ...state,
        individual: action.payload,
        ui: {
          ...state.ui,
          isIndividualLoading: false,
          toResetSelectedItems: true,
          isInitialLoading: initialLoadingStatus,
        },
      };
    case constants.FETCH_INDIVIDUAL_CONTRIBUTIONS:
      return {
        ...state,
        contributionsError: false,
        sort: {},
        ui: {
          ...state.ui,
          isContributionsLoading: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_INDIVIDUAL_CONTRIBUTIONS_DONE:
      return {
        ...state,
        contributions: action.payload,
        contributionsError: action.error,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isContributionsLoading: false,
          isInitialLoading: initialLoadingStatus,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.FETCH_INDIVIDUAL_RECEIPT:
      return {
        ...state,
        contributionsReceiptError: false,
        ui: {
          ...state.ui,
          isContributionsReceiptLoading: true,
        },
      };
    case constants.RECEIVE_INDIVIDUAL_RECEIPT_DONE:
      return {
        ...state,
        contributionsReceipt: action.payload,
        contributionsReceiptError: action.error,
        ui: {
          ...state.ui,
          isContributionsReceiptLoading: false,
        },
      };
    case constants.SET_ELECTION_CYCLES:
      return {
        ...state,
        electionCyclesData: action.payload,
      };
    case constants.SELECT_ELECTION_CYCLE:
      return {
        ...state,
        electionCycle: action.payload,
      };
    case constants.MERGE_DATA_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isMerging: true,
        },
      };
    case constants.MERGE_DATA_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isMerging: false,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  appliedSearchFilters,
  profile,
});
