const PREFIX = 'DASHBOARD_V2_KEYWORD_SEARCH';

const constants = {
  FETCH_KEYWORD: `${PREFIX}/FETCH_KEYWORD`,
  RECEIVE_KEYWORD_SUCCESS: `${PREFIX}/RECEIVE_KEYWORD_SUCCESS`,
  RECEIVE_KEYWORD_FAILED: `${PREFIX}/RECEIVE_KEYWORD_FAILED`,
  SAVE_KEYWORD: `${PREFIX}/SAVE_KEYWORD`,
  SAVE_KEYWORD_DONE: `${PREFIX}/SAVE_KEYWORD_DONE`,
  RESET_KEYWORD_FORM: `${PREFIX}/RESET_KEYWORD_FORM`,
};

export default constants;
