import { map, get, find, some } from 'lodash';

import { SUBSCRIBABLE_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { isMyFeed } from 'components/Dashboard/utils';
import { getWithWorkspaces } from 'redux/dashboardV2/trackItems/utils';

export const getSubscriptionModelId = ({ subscribableData }) => get(subscribableData, 'model_id');

export const getHasStafferSubscription = subscriptions => (
  some(
    subscriptions,
    ({ subscribableType }) => subscribableType === SUBSCRIBABLE_TYPES.FEDERAL_STAFFER_OFFICE
  )
);

export const getHasHearingsSubscription = subscriptions => (
  some(subscriptions, ({ subscribableType }) => subscribableType === SUBSCRIBABLE_TYPES.COMMITTEE)
);

const getData = (payload, workspace) => {
  const { trackings, isStaffer, isHearing, hearingData, stafferData, workspacesData } = payload;
  const subscriptions = get(
    find(trackings, ({ feed }) => feed.token === workspace.token),
    'subscriptions'
  );

  const withWorkspaces = getWithWorkspaces(workspacesData, isMyFeed(workspace.token));
  const isCommitteeHearing = withWorkspaces ? workspacesData.isHearing : isHearing;
  const isCommitteeStaffer = withWorkspaces ? workspacesData.isStaffer : isStaffer;
  const committeeHearingData = withWorkspaces ? workspacesData.hearingData : hearingData;
  const committeeStafferData = withWorkspaces ? workspacesData.stafferData : stafferData;

  return {
    isStaffer: isCommitteeStaffer,
    isHearing: isCommitteeHearing,
    isPresentStaffers: getHasStafferSubscription(subscriptions),
    isPresentHearings: getHasHearingsSubscription(subscriptions),
    hearingData: committeeHearingData,
    stafferData: committeeStafferData,
  };
};

export const getSubscriptionRequests = (payload, workspacesToTrack, action) => {
  const requests = map(workspacesToTrack, (workspace) => {
    const data = {
      ...getData(payload, workspace),
      feedToken: workspace.token,
    };

    return Promise.all(action(data));
  });

  return Promise.all(requests);
};
