import { includes, isEmpty, map, unionBy } from 'lodash';

export const updateWorkspace = (workspace, { name, frequency }) => ({
  ...workspace,
  ...(name && { name }),
  subscription: {
    ...workspace.subscription,
    ...(frequency && { frequency }),
  },
});

const updateSubscription = (subscription, frequency, sendWeekAheadEmail) => {
  const { listSubscriptions } = subscription;

  const updateItem = item => ({ ...item, frequency, sendWeekAheadEmail, isDisabled: false });

  if (isEmpty(listSubscriptions)) {
    return updateItem(subscription);
  }

  const newListSubscriptions = map(listSubscriptions, updateItem);
  return { ...subscription, listSubscriptions: newListSubscriptions };
};

export const updateSubscriptions = (subscriptions, { tokens, frequency, sendWeekAheadEmail }) => (
  map(subscriptions, subscription => (
    includes(tokens, subscription.token)
      ? updateSubscription(subscription, frequency, sendWeekAheadEmail)
      : subscription
  ))
);

export const updateCommitteeSubscription = (subscriptions, updatedSubscription = {}) => {
  const updateListSubscriptions = subscription => ({
    ...subscription,
    listSubscriptions: unionBy([updatedSubscription], subscription.listSubscriptions, 'type'),
  });

  return map(subscriptions, subscription => (
    subscription.modelId === updatedSubscription.modelId
      ? updateListSubscriptions(subscription)
      : subscription
  ));
};

export const updateAllSubscriptions = (subscriptions, frequency) => (
  map(subscriptions, subscription => updateSubscription(subscription, frequency, subscription.sendWeekAheadEmail))
);
