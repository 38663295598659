import Notifications from 'components/News/utils/Notifications';
import { NO_PRIORITY } from 'components/core/PriorityTooltip/constants';
import { getMyFeedToken } from 'components/Dashboard/utils';
import {
  trackNewsFeedCreated,
  trackNewsFeedUpdated,
  trackNewsFeedRemoved,
  trackNewsSubscribed,
} from 'utils/tracking/news';
import * as subscriptionApi from 'redux/dashboardV2/common/subscriptions/api';

import {
  transformFeedToBE,
  transformFeedForUI,
} from './utils/transformers';
import constants from './constants';

export function fetchGeneralFeeds() {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_GENERAL_FEEDS });

    const url = '/api_web/news/searches/general';
    const ajax = $.get(url);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_GENERAL_FEEDS_DONE,
        payload: response.data,
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_GENERAL_FEEDS_FAILURE });

      Notifications.defaultFetchDataFailed();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchPersonalFeeds() {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_PERSONAL_FEEDS });

    const url = '/api_web/news/searches/personal';
    const ajax = $.get(url);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_PERSONAL_FEEDS_DONE,
        payload: response.data,
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_GENERAL_FEEDS_FAILURE });

      Notifications.defaultFetchDataFailed();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function deleteFeed(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_FEED });

    const { id, onClose } = payload;

    const url = `/api_web/news/searches/${id}`;
    const ajax = $.ajax({
      url,
      type: 'DELETE',
    });

    function onSuccess() {
      dispatch({
        type: constants.DELETE_FEED_DONE,
        payload: { id },
      });

      onClose();
      trackNewsFeedRemoved();
      Notifications.deleteFeedSuccess();
    }

    function onFailure(error) {
      dispatch({ type: constants.DELETE_FEED_FAILURE });
      onClose();

      Notifications.deleteFeedFailed();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function createFeed(payload = {}) {
  return (dispatch) => {
    const {
      title,
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    } = payload;
    const url = '/api_web/news/searches';
    const params = transformFeedToBE(payload);
    const ajax = $.ajax({
      url,
      data: JSON.stringify(params),
      method: 'POST',
      contentType: 'application/json',
    });

    function onSuccess(response) {
      const { id } = response;
      const feedProps = { id, name: title };

      id && dispatch({
        type: constants.CREATE_FEED_DONE,
        payload: feedProps,
      });

      trackNewsFeedCreated();
      Notifications.createFeedSuccess();
      id && handleSuccess && handleSuccess(feedProps);
    }

    function onFailure(error) {
      dispatch({ type: constants.CREATE_FEED_FAILURE });
      Notifications.createFeedFailed();
      handleFailure && handleFailure();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updateFeed(payload = {}) {
  return (dispatch) => {
    const {
      id,
      title,
      onSuccess: handleSuccess,
      onFailure: handleFailure,
    } = payload;
    const url = `/api_web/news/searches/${id}`;
    const params = transformFeedToBE(payload);
    const ajax = $.ajax({
      url,
      method: 'PUT',
      data: JSON.stringify(params),
      contentType: 'application/json',
    });

    function onSuccess() {
      const feedProps = { id, name: title };

      dispatch({
        type: constants.UPDATE_FEED_DONE,
        payload: feedProps,
      });

      trackNewsFeedUpdated();
      Notifications.updateFeedSuccess();
      handleSuccess && handleSuccess(feedProps);
    }

    function onFailure(error) {
      dispatch({ type: constants.UPDATE_FEED_FAILURE });
      Notifications.updateFeedFailed();
      handleFailure && handleFailure();

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchFeedById({ id }) {
  const url = `/api_web/news/searches/${id}`;
  const ajax = $.get(url);

  return Promise.resolve(ajax)
    .then(transformFeedForUI);
}

export function subscribeToFeed(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.SUBSCRIBE_TO_FEED });

    const { id, name, hasSubscription, ...restOptions } = payload;
    const data = { ...restOptions, modelId: id, isSaved: false, priority: NO_PRIORITY };

    const subscriptionFn = hasSubscription
      ? subscriptionApi.updateSubscription
      : subscriptionApi.createSubscription;

    function onSuccess(response) {
      const { frequency, token: responseToken } = response;

      dispatch({
        type: constants.SUBSCRIBE_TO_FEED_DONE,
        payload: { id, subscription: { frequency, token: responseToken } },
      });

      trackNewsSubscribed(id, name, frequency);
    }

    function onFailure(error) {
      dispatch({ type: constants.SUBSCRIBE_TO_FEED_FAILURE });

      Notifications.subscriptionFailed();

      throw error;
    }

    return subscriptionFn(getMyFeedToken(), data)
      .then(onSuccess)
      .catch(onFailure);
  };
}
