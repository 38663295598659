import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { FEDERAL_AGENCY_STAFFER_ITEM_TYPE } from 'utils/constants/itemTypes';
import { transformExecutive } from 'utils/transformers/profile/executive';
import { FEDERAL_ROLE } from 'utils/transformers/profile/constants';

export const transformPersonForUI = person => ({
  ...transformExecutive(person, FEDERAL_ROLE),
  itemType: FEDERAL_AGENCY_STAFFER_ITEM_TYPE,
  documentType: DOCUMENT_TYPES.FEDERAL_AGENCY_STAFFER,
});
