import { isNil, omitBy } from 'lodash';

import { PAGE_SIZE } from 'components/core/Profile/utils/constants';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { fetchProfileTags } from 'redux/tags/actions';
import { fetchDefaultSubscription, fetchProfileSubscriptions } from 'redux/subscriptions/actions';
import { fetchProfileDocument, updateProfileShare } from 'redux/events/actions';
import isNotFoundError from 'utils/http/isNotFoundError';

import {
  transformKeyDetails,
  transformRollCallVotesForUI,
  transformActiveVoicesForBE,
  transformActiveVoicesForUI,
  transformSponsorsAndCosponsorsForUI,
  transformTextVersionsForUI,
  transformAmendmentsForBE,
  transformAmendmentsForUI,
  transformTimeline,
} from './utils';
import constants from './constants';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}

export function fetchSubscriptions(payload) {
  return (dispatch) => {
    dispatch(fetchDefaultSubscription(constants));
    dispatch(fetchProfileSubscriptions({ payload }, constants));
  };
}

export function onUpdateTrackings({ trackings }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: trackings,
  });
}

export function fetchProfile(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_PROFILE });

    const { id } = payload;
    const url = `/api_web/state/bills/${id}`;
    const ajax = $.get(url);

    const onSuccess = response => dispatch({
      type: constants.RECEIVE_PROFILE_DONE,
      payload: transformKeyDetails(response),
    });

    const onFailure = (error) => {
      isNotFoundError(error)
        ? dispatch({ type: constants.PROFILE_NOT_FOUND })
        : dispatch({ type: constants.FETCH_PROFILE_ERROR });
      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(() => {
        dispatch(fetchTags(payload));
        dispatch(fetchSubscriptions(payload));
        dispatch(fetchProfileDocument(payload, constants));
      })
      .catch(onFailure);
  };
}

export function fetchTimeline(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILL_TIMELINE });

    const { id } = payload;
    const url = `/api_web/state/bills/${id}/actions`;
    const ajax = $.get(url);

    const onFailure = (error) => {
      dispatch({ type: constants.FETCH_TIMELINE_ERROR });
      throw error;
    };

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_BILL_TIMELINE_DONE,
        payload: transformTimeline(response),
      })).catch(onFailure);
  };
}

export function fetchBillsComparison(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILLS_COMPARISON });

    const { id } = payload;
    const url = `/api_web/state/bills/${id}/text_versions`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_BILLS_COMPARISON_DONE,
        payload: transformTextVersionsForUI(response),
      }));
  };
}

export function fetchRollCallVotes(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_ROLL_CALL_VOTES });

    const { sort, direction, page } = payload;
    const sortData = omitBy({ sort, direction, page, ...PER_PAGE }, isNil);

    const { id: billId } = payload;
    const url = '/api_web/state/roll_calls/lookup';

    const data = {
      search_form: {
        bill_id: billId,
      },
      ...sortData,
    };

    const ajax = $.post(url, data);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_ROLL_CALL_VOTES,
        payload: {
          ...transformRollCallVotesForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(sortData),
        },
      }));
  };
}

export function fetchActiveVoices(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_ACTIVE_VOICES });

    const { page = 1, perPage = PAGE_SIZE } = payload;
    const sortData = { page, per_page: perPage };

    const data = {
      ...transformActiveVoicesForBE(payload),
      ...sortData,
    };

    const url = '/api_web/royal_cosponsors_oracle/state_legislation/ratings';

    const ajax = $.post(url, data);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_ACTIVE_VOICES,
        payload: {
          ...transformActiveVoicesForUI(response),
          paginator: transformPaginator(response, perPage),
        },
      }));
  };
}

export function fetchSponsorsAndCosponsors(payload = {}) {
  return (dispatch) => {
    const { page = 1, perPage = PAGE_SIZE } = payload;
    const sortData = { page, per_page: perPage };

    const { id: billId } = payload;
    const url = `/api_web/state/bills/${billId}/sponsors`;

    const data = {
      ...sortData,
    };

    const onFailure = (error) => {
      dispatch({ type: constants.FETCH_SPONSORS_ERROR });
      throw error;
    };

    const ajax = $.get(url, data);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_SPONSORS_AND_COSPONSORS,
        payload: {
          ...transformSponsorsAndCosponsorsForUI(response),
          paginator: transformPaginator(response, perPage),
        },
      })).catch(onFailure);
  };
}

export function updateProfileMyFeed() {
  return (dispatch) => {
    dispatch(updateProfileShare(constants));
  };
}

export function fetchAmendments(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_AMENDMENTS });
    const { sort, direction, page } = payload;

    const sortData = omitBy({ sort, direction, page }, isNil);
    const amendmentsData = transformAmendmentsForBE(payload);

    const ajax = $.post('/api_web/state/bill_amendments/lookup', { ...amendmentsData, ...PER_PAGE });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_AMENDMENTS,
        payload: {
          ...transformAmendmentsForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(sortData),
        },
      }));
  };
}
