import {
  isEmpty,
  toString,
  find,
  reduce,
} from 'lodash';

import {
  getFilteredTags,
  transformTagsForBE,
  saveTagsAndUpdateRecords,
} from 'redux/tags/utils';
import ActivityEventsPublisher from 'components/Grm2/events/publisher';

import constants from '../constants';
import {
  transformCardForEvent,
  transformCardsForEvent,
} from '../transformers/toFE';
import {
  getUpdatedCardByTags,
  getUpdatedCardsByTags,
  transformRecordsForBe,
  transformBulkTagsRecordsForBE,
  getUniqRecordsId,
  tagMayCauseInconsistency,
} from '../utils/tag';
import { applyCardChanges } from '../utils/card';

export const updateCardTags = (tags, cardId) => (
  (dispatch, getState) => {
    const { cards, namespace, filters } = getState().grm2.grm;

    const updatedCard = getUpdatedCardByTags(cards, tags, cardId);

    const payload = applyCardChanges(cards, updatedCard);

    ActivityEventsPublisher(namespace).cardUpdated({
      reduxCard: transformCardForEvent(updatedCard).card,
      uiCard: updatedCard,
    });

    dispatch({ type: constants.SET_CARDS, payload });
    tagMayCauseInconsistency(filters) && dispatch({ type: constants.SET_HAS_EDITED_CARD, payload: true });
  }
);

export const updateCardsTags = ({ data }) => (
  (dispatch, getState) => {
    const { cards, namespace, filters } = getState().grm2.grm;

    const payload = getUpdatedCardsByTags(cards, data);

    const updatedCardsId = getUniqRecordsId(data);
    const updatedCards = reduce(
      updatedCardsId,
      (result, updatedCardId) => {
        const updatedCard = find(payload, ['id', +updatedCardId]);
        if (isEmpty(updatedCard)) {
          return result;
        }
        return [
          ...result,
          updatedCard,
        ];
      },
      [],
    );

    ActivityEventsPublisher(namespace).cardsUpdated({
      reduxCards: transformCardsForEvent(updatedCards),
      uiCards: updatedCards,
    });

    dispatch({ type: constants.SET_CARDS, payload });
    tagMayCauseInconsistency(filters) && dispatch({ type: constants.SET_HAS_EDITED_CARD, payload: true });
  }
);

export function bulkTagRecordsNoDispatch(payload) {
  const data = transformBulkTagsRecordsForBE(payload);

  const ajax = $.ajax({
    url: '/api_web/grm_v2/tags/bulk_create',
    method: 'POST',
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax);
}

export function updateTagRecordsForRecordNoDispatch(payload) {
  const { recordId, tagIds } = payload || {};

  const ajax = $.ajax({
    method: 'PUT',
    url: `/api_web/grm_v2/tags/${recordId}`,
    data: { tag_ids: tagIds },
  });

  return Promise.resolve(ajax);
}

export function bulkSaveTags({ cardIds, tags }) {
  return (dispatch) => {
    if (isEmpty(tags)) {
      return Promise.resolve();
    }

    const { newTags, existingTags } = getFilteredTags(tags);

    function saveTagsForRecord(responseNewTags) {
      return bulkTagRecordsNoDispatch({
        tagIds: transformTagsForBE(existingTags, responseNewTags),
        records: transformRecordsForBe(cardIds),
      }).then(response => dispatch(updateCardsTags(response)));
    }

    return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
  };
}

export function onSaveTags({ cardIds, tags }) {
  return (dispatch) => {
    const { newTags, existingTags } = getFilteredTags(tags);
    const [cardId] = cardIds;

    function saveTagsForRecord(responseNewTags) {
      return updateTagRecordsForRecordNoDispatch({
        tagIds: transformTagsForBE(existingTags, responseNewTags),
        recordId: toString(cardId),
      }).then(response => dispatch(updateCardTags(response, cardId)));
    }

    return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
  };
}
