import { orderBy } from 'lodash';

export function sortStafferByColumn({ stafferKey, columnName, sortDirection, stafferData, actionTypes }) {
  return (dispatch) => {
    const { SORT_STAFFER_DATA, SORT_STAFFER_DATA_DONE } = actionTypes;

    dispatch({ type: SORT_STAFFER_DATA });

    const sortedStafferData = {
      ...stafferData,
      [stafferKey]: orderBy(
        stafferData[stafferKey],
        [columnName],
        [sortDirection]
      ),
    };

    return dispatch({
      type: SORT_STAFFER_DATA_DONE,
      stafferData: sortedStafferData,
    });
  };
}
