import { createFileId } from 'components/core/FileUpload/utils';
import {
  createAction,
  ajaxRequestToPromise,
} from 'redux/utils';

import {
  FILES_ADDED,
  DELETE_FILE,
  EMPTY_STATE,
} from './constants';

export const filesAdded = createAction(FILES_ADDED);
export const deleteFile = createAction(DELETE_FILE);
export const emptyState = createAction(EMPTY_STATE);

export function addFiles(files, setProgress, setFailures) {
  const fileList = Array.from(files);

  function parseFilesToUploadFormat(responses) {
    return responses.map((file = {}, index) => ({
      file_upload_id: file.id,
      file_upload_url: file.file_url,
      name: fileList[index].name,
      size: fileList[index].size,
    }));
  }

  return (dispatch) => {
    dispatch(filesAdded(fileList));

    const url = '/api_web/file_uploads';
    const requests = fileList.map((file) => {
      const data = new FormData();
      data.append('file', file);
      data.append('strategy_slug', 'grm_card_document');
      data.append('required_inputs', '{}');

      const request = $.ajax({
        data,
        url,
        method: 'POST',
        cache: false,
        contentType: false,
        processData: false,
        xhr: () => {
          const xhr = new window.XMLHttpRequest();
          xhr.upload.onprogress = (e) => {
            const percentComplete = Math.floor((e.loaded * 100) / e.total);

            // holds the last 5% for the time it takes to send to backend;
            const barPercentageComplete = percentComplete * 0.95;

            setProgress(prevProgress => ({
              ...prevProgress,
              [createFileId(file)]: barPercentageComplete,
            }));
          };

          return xhr;
        },
      });

      return ajaxRequestToPromise(request)
        .catch(() => (
          setFailures(prevFailures => ({
            ...prevFailures,
            [createFileId(file)]: true,
          }))
        ))
        .finally(() => (
          setProgress(prevProgress => ({
            ...prevProgress,
            [createFileId(file)]: 0,
          }))
        ));
    });

    Promise.all(requests)
      .then(parseFilesToUploadFormat)
      .then((uploadedFiles) => {
        dispatch(filesAdded(uploadedFiles));
      });
  };
}
