const defaultState = {
  defaultSubscription: '',
  regulations: [],
  paginator: {},
  sort: {},
  ui: {
    isInitialLoading: true,
    isLoadingData: true,
    isLoadingTags: true,
    isLoadingPositions: true,
    isLoadingSubscriptions: true,
    isSavingSubscriptions: true,
    isLoadingMyFeedDocuments: true,
    isSharingItems: false,
    toResetSelectedItems: true,
  },
};

export default defaultState;
