const keyword = 'GLOBAL_SEARCH';

const constants = {
  FETCH_RESULTS: `${keyword}/FETCH_RESULTS`,
  RECEIVE_RESULTS_DONE: `${keyword}/RECEIVE_RESULTS_DONE`,
  RECEIVE_RESULTS_ERROR: `${keyword}/RECEIVE_RESULTS_ERROR`,
  FETCH_SUMMARY: `${keyword}/FETCH_SUMMARY`,
  RECEIVE_SUMMARY_DONE: `${keyword}/RECEIVE_SUMMARY_DONE`,
  RECEIVE_SUMMARY_ERROR: `${keyword}/RECEIVE_SUMMARY_ERROR`,
};

export default constants;
