const prefix = 'CUSTOM_FIELDS';

const constants = {
  FETCH_CUSTOM_FIELDS: `${prefix}/FETCH_CUSTOM_FIELDS`,
  FETCH_CUSTOM_FIELDS_DONE: `${prefix}/FETCH_CUSTOM_FIELDS_DONE`,
  RECEIVE_CUSTOM_FIELDS: `${prefix}/RECEIVE_CUSTOM_FIELDS`,
  RECEIVE_CUSTOM_FIELDS_DONE: `${prefix}/RECEIVE_CUSTOM_FIELDS_DONE`,
  GET_CUSTOM_FIELD_BY_TOKEN: `${prefix}/GET_CUSTOM_FIELD_BY_TOKEN`,
  GET_CUSTOM_FIELD_BY_TOKEN_DONE: `${prefix}/GET_CUSTOM_FIELD_BY_TOKEN_DONE`,
  CREATE_CUSTOM_FIELDS: `${prefix}/CREATE_CUSTOM_FIELDS`,
  CREATE_CUSTOM_FIELDS_DONE: `${prefix}/CREATE_CUSTOM_FIELDS_DONE`,
  UPDATE_CUSTOM_FIELDS: `${prefix}/UPDATE_CUSTOM_FIELDS`,
  UPDATE_CUSTOM_FIELDS_DONE: `${prefix}/UPDATE_CUSTOM_FIELDS_DONE`,
  RESET_LOADING_CUSTOM_FIELDS: `${prefix}/RESET_LOADING_CUSTOM_FIELDS`,
  UPDATE_POSITION: `${prefix}/UPDATE_POSITION`,
  UPDATE_POSITION_DONE: `${prefix}/UPDATE_POSITION_DONE`,
  UPDATE_POSITION_FAILURE: `${prefix}/UPDATE_POSITION_FAILURE`,
  UPDATE_POSITION_SYNC_DONE: `${prefix}/UPDATE_POSITION_SYNC_DONE`,
};

export default constants;
