import { combineReducers } from 'redux';

import manageOrganization from './manageOrganization/reducers';
import individualAssetSet from './individualAssetSet/reducers';
import institutionalData from './institutionalData/reducers';

export default combineReducers({
  manageOrganization,
  institutionalData,
  individualAssetSet,
});
