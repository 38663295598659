import { isEmpty, map, omitBy } from 'lodash';

export function transformPublicRecordsForBE(searchData = {}) {
  const { id, tags, keywordSearch, rangeDate = {}, selectedTypes = [] } = searchData;

  return {
    legislator_id: id,
    ...omitBy({
      legislator_lookup_keyword: keywordSearch,
      date_from: rangeDate && rangeDate.startDate,
      date_to: rangeDate && rangeDate.endDate,
      collections: [...selectedTypes],
      tag_ids: map(tags, 'value'),
    }, isEmpty),
  };
}
