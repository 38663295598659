import { postJson } from 'utils/api';
import { BASE_FEEDS_URL } from 'redux/dashboardV2/constants';

import { transformTrackItemsForBE } from './utils';

export const saveTrackItems = (feedToken, trackItems) => {
  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/trackings/bulk`;
  const requestData = transformTrackItemsForBE(trackItems);

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};
