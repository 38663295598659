import constants from './constants';

export const defaultState = {
  ui: {
    isSaveTrackItems: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.SAVE_TRACK_ITEMS:
      return {
        ...state,
        ui: { ...state.ui, isSaveTrackItems: true },
      };
    case constants.SAVE_TRACK_ITEMS_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSaveTrackItems: false },
      };
    default:
      return state;
  }
}
