export default function getOfficeStaffersBody({ stateOfficeId, page, perPage, sortData }) {
  return {
    search_form: {
      state_offices: [stateOfficeId],
    },
    page,
    per_page: perPage,
    ...sortData,
  };
}
