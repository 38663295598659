import constants from './constants';

export function setAppliedFilters(payload) {
  return dispatch => (
    dispatch({
      type: constants.SET_APPLIED_FILTERS,
      payload,
    })
  );
}

export function updateAppliedFilters(payload) {
  return dispatch => (
    dispatch({
      type: constants.UPDATE_APPLIED_FILTERS,
      payload,
    })
  );
}

export function resetAppliedFilters() {
  return dispatch => (
    dispatch({
      type: constants.RESET_APPLIED_FILTERS,
    })
  );
}
