import { has, map } from 'lodash';

export function loadLinksPreview(state, payload) {
  const { data } = state || {};

  return {
    ...state,
    data: map(data, item => ({
      ...item,
      ...(has(payload, item.id) && {
        isLoadingLinkPreview: true,
      }),
    })),
  };
}

export function updateLinksPreview(state, payload) {
  const { data } = state || {};

  return {
    ...state,
    data: map(data, item => ({
      ...item,
      ...(has(payload, item.id) && {
        isLoadingLinkPreview: false,
        linkPreview: payload[item.id],
      }),
    })),
  };
}
