import { omitBy, isEmpty, isNil, map } from 'lodash';

import { TASK_CARD_TYPE } from 'components/Grm2/constants';

import { transformTaskStatusForBE } from './tasks';
import { toCardLinks } from './cardLinks';

export const transformFiltersForBE = (filters = {}, timelineDefaultFilters = []) => omitBy({
  lookup_keyword: filters.keywordLookup,
  card_links: [
    ...map(filters.people, toCardLinks),
    ...map(filters.bills, toCardLinks),
    ...map(filters.committees, toCardLinks),
    ...map(filters.documents, toCardLinks),
    ...map(filters.regulations, toCardLinks),
  ],
  timeline_default_card_links: timelineDefaultFilters,
  user_ids: map(filters.creator, ({ value }) => value),
  task: omitBy({
    assignee_ids: map(filters.assignee, 'value'),
    statuses: map(filters.statusTypes, transformTaskStatusForBE),
    priorities: filters.priorityTypes,
    due_date: omitBy({
      from: filters.dueDate && filters.dueDate.startDate,
      to: filters.dueDate && filters.dueDate.endDate,
    }, isEmpty),
  }, isEmpty),
  card_types: filters.activityTypes,
  interaction_types: map(filters.interactionTypes, 'value'),
  tag_ids: map(filters.tags, 'value'),
  creation_date: omitBy({
    from: filters.creationDate && filters.creationDate.startDate,
    to: filters.creationDate && filters.creationDate.endDate,
  }, isEmpty),
  event_date: omitBy({
    from: filters.eventDate && filters.eventDate.startDate,
    to: filters.eventDate && filters.eventDate.endDate,
  }, isEmpty),
  with_attachments: filters.attachments ? 'true' : null,
}, value => isEmpty(value) || isNil(value));

export const transformTaskFiltersForBE = (assigneeId, status, filters) => ({
  ...transformFiltersForBE(filters),
  task: {
    assignee_ids: [assigneeId],
    statuses: [transformTaskStatusForBE(status)],
  },
  card_types: [TASK_CARD_TYPE],
});
