import { transformDataSetForUI, transformPaginationForUI } from './transformers';
import constants from './constants';
import { defaultPagination } from './reducers';

const { addNotification } = window.app.GlobalNotifier;

export function fetchDataSets({ sortData = {}, pagination = defaultPagination }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DATA_SETS });

    const url = '/api_web/company-subscription/institutional-data/data_sets';
    const method = 'GET';

    const { currentPage, pageSize } = pagination;
    const { column, direction } = sortData;
    const sortingParamName = `data_sets[sort][${column}]`;

    const data = {
      [sortingParamName]: direction,
      page: currentPage,
      per_page: pageSize,
    };

    const ajax = $.ajax({ url, method, data });

    return Promise.resolve(ajax)
      .then(({
        data: responseData,
        pagination: responsePagination,
      }) => dispatch({
        type: constants.RECEIVE_DATA_SETS_DONE,
        payload: responseData.map(transformDataSetForUI),
        pagination: transformPaginationForUI(responsePagination),
        sortData,
      }))
      .catch(() => {
        dispatch({ type: constants.RECEIVE_DATA_SETS_ERROR });
        addNotification({
          message: 'Could not load data sets. Please try again.',
          level: 'error',
        });
      });
  };
}
