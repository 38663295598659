import { size, map, compact } from 'lodash';

import {
  getValue,
  formatAmounts,
  formatZipCode,
  formatSelectValueForFE,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
} from 'redux/moneyball/utils/transformers';
import { getAmountValue, getUniqueValues } from 'redux/moneyball/utils';
import { getDisplayItem } from 'redux/advancedSearch/utils';

export function transformIndividuals(individuals = []) {
  return map(individuals, ({ individual = {}, total_donated }) => {
    const {
      id,
      name,
      zip_code,
      state_code,
      street_1,
      street_2,
      occupation,
      is_restricted,
      employer,
      city,
      individuals: individualValues,
    } = individual || {};

    const zipCode = formatZipCode(zip_code);

    const { value: nameValue, info: nameInfo } = getUniqueValues('name', name, individualValues);
    const { value: occupationValue, info: occupationInfo } = getUniqueValues('occupation', occupation, individualValues);
    const { value: employerValue, info: employerInfo } = getUniqueValues('employer', employer, individualValues);
    const { value: streetValue, info: streetInfo } = getUniqueValues('street_1', street_1, individualValues);
    const { value: cityValue, info: cityInfo } = getUniqueValues('city', city, individualValues);
    const { value: stateValue, info: stateInfo } = getUniqueValues('state_code', state_code, individualValues);
    const { value: zipValue, info: zipInfo } = getUniqueValues('zip_code', zipCode, individualValues);

    return ({
      id,
      name: nameValue,
      occupation: occupationValue,
      employer: employerValue,
      street_1: streetValue,
      street_2,
      city: cityValue,
      is_restricted,
      url: `/moneyball_v2/individuals/${id}`,
      state: stateValue,
      amount: total_donated,
      zip_code: zipValue,
      info: size(individualValues) > 1 && {
        name: nameInfo,
        occupation: occupationInfo,
        employer: employerInfo,
        street_1: streetInfo,
        city: cityInfo,
        state: stateInfo,
        zip_code: zipInfo,
      },
    });
  });
}

export function transformSearchesForFE(data = []) {
  return map(data, (item) => {
    const { id, name, search_params } = item || {};
    const {
      query,
      contributed_amount,
      names,
      address_streets,
      address_cities,
      address_state_codes,
      address_zipcodes,
      employers,
      occupations,
    } = search_params || {};

    return ({
      id,
      title: name || 'Applied filters:',
      filters: compact([
        getDisplayItem('Searched', query),
        getDisplayItem('Amounts', getAmountValue(contributed_amount)),
        getDisplayItem('Individual(s)', names),
        getDisplayItem('Street name(s)', address_streets),
        getDisplayItem('Cities', address_cities),
        getDisplayItem('State(s)', address_state_codes),
        getDisplayItem('Zip code(s)', address_zipcodes),
        getDisplayItem('Employer(s)', employers),
        getDisplayItem('Occupation(s)', occupations),
      ]),
      options: {
        ...getValue('searchValue', query),
        options: {
          ...getValue('amounts', formatAmounts(contributed_amount)),
          ...getValue('individuals', formatSelectValueForFE(names)),
          ...getValue('streets', formatSelectValueForFE(address_streets)),
          ...getValue('cities', formatSelectValueForFE(address_cities)),
          ...getValue('states', formatSelectValueForFE(address_state_codes)),
          ...getValue('zipCodes', formatSelectValueForFE(address_zipcodes)),
          ...getValue('employers', formatSelectValueForFE(employers)),
          ...getValue('occupations', formatSelectValueForFE(occupations)),
        },
      },
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    amounts,
    individuals,
    streets,
    cities,
    states,
    zipCodes,
    employers,
    occupations,
  } = options || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('contributed_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('address_streets', formatSelectValueToArrForBE(streets)),
    ...getValue('address_cities', formatSelectValueToArrForBE(cities)),
    ...getValue('address_state_codes', formatSelectValueToArrForBE(states)),
    ...getValue('address_zipcodes', formatSelectValueToArrForBE(zipCodes)),
    ...getValue('names', formatSelectValueToArrForBE(individuals)),
    ...getValue('employers', formatSelectValueToArrForBE(employers)),
    ...getValue('occupations', formatSelectValueToArrForBE(occupations)),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformOptionsSearchForBE(data = {}) {
  const baseOptions = transformOptionsForBE(data);
  const { options } = data;
  const {
    individuals,
    streets,
    cities,
    states,
    zipCodes,
    employers,
    occupations,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('names', formatSelectValueToObjForBE(individuals)),
    ...getValue('address_streets', formatSelectValueToObjForBE(streets)),
    ...getValue('address_cities', formatSelectValueToObjForBE(cities)),
    ...getValue('address_state_codes', formatSelectValueToObjForBE(states)),
    ...getValue('address_zipcodes', formatSelectValueToObjForBE(zipCodes)),
    ...getValue('employers', formatSelectValueToObjForBE(employers)),
    ...getValue('occupations', formatSelectValueToObjForBE(occupations)),
  };
}
