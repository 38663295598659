import {
  FACEBOOK,
  TWITTER,
  LINKEDIN,
  INSTAGRAM,
  YOUTUBE,
} from 'utils/socialMedia/names';

export const LOCAL_EXECUTIVES_URL = '/api_web/local/executives/lookup';

export const EXPORT_STRATEGY_SLUG = 'local_executives';

export const SOCIAL_MEDIA_LIST = [
  {
    type: 'Facebook',
    name: FACEBOOK,
  },
  {
    type: 'Twitter',
    name: TWITTER,
  },
  {
    type: 'LinkedIn',
    name: LINKEDIN,
  },
  {
    type: 'Instagram',
    name: INSTAGRAM,
  },
  {
    type: 'YouTube',
    name: YOUTUBE,
  },
];
