import { isNil, omitBy } from 'lodash';

import { PAGE_SIZE } from 'components/core/Profile/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';

import { transformStaffersForBE, transformStaffersForUI } from './transformers';
import { getStaffersUrl } from './utils';
import constants from './constants';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchStaffers(payload = {}, customTransformers = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFERS });
    const {
      transformForBE = transformStaffersForBE,
      transformForUI = transformStaffersForUI,
    } = customTransformers;

    const { sort, direction, page, id, slug } = payload;

    const staffersData = transformForBE(payload);
    const sortData = omitBy({ sort, direction, page }, isNil);
    const { url, method } = getStaffersUrl(slug, id);

    const ajax = $.ajax({
      url,
      method,
      data: { ...staffersData, ...sortData, ...PER_PAGE },
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_STAFFERS_DONE,
        payload: {
          ...transformForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(payload),
        },
      }));
  };
}
