import { setAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { fetchDocuments } from 'redux/local/lookup/actions/data';

export function handleViewAllClick({ city, state }) {
  return (dispatch, getState) => {
    const { appliedFilters } = getState().local.lookup.appliedFilters;

    const value = JSON.stringify({ city, state });
    const label = `${city}, ${state.toUpperCase()}`;

    const filters = {
      ...appliedFilters,
      options: {
        ...appliedFilters.options,
        locales: [{ value, label }],
      },
    };

    dispatch(setAppliedFilters(filters));

    return dispatch(fetchDocuments({ appliedFilters: filters }));
  };
}
