import { combineReducers } from 'redux';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';
import exportReport from 'redux/exportReport/reducers';
import { updateDataItemArrayPayload, updateDataItem } from 'utils/reducer';

import { loadingUpdateItem } from './utils/reducersUtils';
import constants from './constants';

export const defaultState = {
  defaultSubscription: '',
  bills: [],
  paginator: {},
  sort: {},
  graphs: {},
  ui: {
    isLoadingData: true,
    isLoadingTags: true,
    isLoadingPositions: true,
    isLoadingSubscriptions: true,
    isSavingSubscriptions: true,
    isInitialLoading: true,
    isLoadingGraphData: true,
    isLoadingMyFeedDocuments: true,
    isSharingItems: false,
    toResetSelectedItems: true,
  },
};

const billLookupKeyName = 'bills';

function getLoadingActions(isLoading) {
  return {
    isLoadingTags: isLoading,
    isLoadingPositions: isLoading,
    isLoadingSubscriptions: isLoading,
  };
}

function billLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_BILLS:
      return {
        ...state,
        sort: defaultState.sort,
        ui: {
          ...state.ui,
          ...getLoadingActions(true),
          isLoadingData: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_BILLS_DONE:
      return {
        ...state,
        bills: action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isLoadingData: false,
          toResetSelectedItems: action.toResetSelectedItems,
          isInitialLoading: false,
        },
      };
    case constants.FETCH_BILLS_GRAPH:
      return {
        ...state,
        ui: { ...state.ui, isLoadingGraphData: true },
      };
    case constants.RECEIVE_BILLS_GRAPH_DONE:
      return {
        ...state,
        graphs: action.payload,
        ui: { ...state.ui, isLoadingGraphData: false },
      };
    case constants.SET_BILLS:
      return {
        ...state,
        bills: action.payload,
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, billLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.BULK_TRACK_DATA:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: true },
      };
    case constants.BULK_TRACK_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: false },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, billLookupKeyName),
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.BULK_UPDATE_DATA:
      return loadingUpdateItem(state, action);
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, billLookupKeyName),
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.BULK_SHARE_TO_MY_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: true },
      };
    case constants.BULK_SHARE_TO_MY_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, billLookupKeyName),
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.LOAD_POSITIONS:
      return updateDataItemArrayPayload(
        loadingUpdateItem(state, { ...state.ui, isLoadingPositions: action.isLoadingPositions }),
        action,
        billLookupKeyName,
      );
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, billLookupKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RESET_BILLS:
      return defaultState;
    default:
      return state;
  }
}

export default combineReducers({
  appliedFilters: appliedSearchFilters,
  advancedSearch,
  exportReport,
  billLookup,
});
