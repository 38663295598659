const PREFIX = 'EXPORT_REPORT';

export const LIMIT_SELECTED_ITEMS = 500;

const constants = {
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
