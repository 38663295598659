import { isEmpty, isNil, omitBy } from 'lodash';

import { PER_PAGE } from 'components/Local/Lookup/utils/constants';
import { API_URL } from 'redux/local/lookup/constants';
import { transformOptionsForBE } from 'redux/local/lookup/actions/utils';

function getDocumentsData(payload) {
  const { searchData = {}, appliedFilters, clearAll, sort, direction, page } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData, per_page: PER_PAGE };

  return {
    url: API_URL,
    data,
    sortData,
    isSearch,
  };
}

export default getDocumentsData;
