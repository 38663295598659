import * as api from 'redux/dashboardV2/feedPage/api';
import isNotFoundError from 'utils/http/isNotFoundError';

import { transformKeywordsResponse } from '../utils/transformers/keywordsTransformers';
import constants from '../constants';
import notifications from '../notifications';

export function fetchKeywords(withRemoved = false, token) {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_KEYWORDS_INIT });

    const onSuccess = response => dispatch({
      type: constants.LOAD_KEYWORDS_DONE,
      payload: transformKeywordsResponse(response),
    });

    const onFailure = (error) => {
      dispatch({
        type: constants.LOAD_KEYWORDS_DONE,
        payload: [],
      });

      !isNotFoundError(error) && notifications.defaultFetchDataFailed();

      throw error;
    };

    return api.fetchKeywords(withRemoved, token)
      .then(onSuccess)
      .catch(onFailure);
  };
}
