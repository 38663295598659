const defaultState = {
  campaign: {
    campaign_type: null,
    matchers: [],
    matcher_messages: [],
    ptc_config: {},
    thank_you_config: {},
    social_media_message_enabled: false,
    exclude_washington_dc_delegates: true,
    exclude_us_territories: true,
    use_federal_legislator_contact_form: true,
    logo_config: {},
    messages_customization_option: 'none',
  },
  custom_recipients: [],
  regulation: {},
  pagination: {},
  ui: {
    loading: false,
    loadingForm: true,
    campaignNotFound: false,
    updatingCustomRecipients: false,
  },
};

export default defaultState;
