const PREFIX = 'NEWS_COMMON_ANALYTICS';

const constants = {
  FETCH_TIME_SERIES: `${PREFIX}/FETCH_TIME_SERIES`,
  RECEIVE_TIME_SERIES_DONE: `${PREFIX}/RECEIVE_TIME_SERIES_DONE`,
  RECEIVE_TIME_SERIES_FAILURE: `${PREFIX}/RECEIVE_TIME_SERIES_FAILURE`,
  RESET_TIME_SERIES: `${PREFIX}/RESET_TIME_SERIES`,
};

export default constants;
