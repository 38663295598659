export function transformLegislator(legislator = {}) {
  const {
    id,
    name,
    universal_moniker,
    party_short,
    party_slug,
    photo_path,
    position,
    ...restProps
  } = legislator;

  return {
    ...restProps,
    id,
    name: name || universal_moniker,
    partyShort: party_short,
    partySlug: party_slug,
    avatarUrl: photo_path,
    url: `/federal/legislators/${id}`,
    position,
  };
}
