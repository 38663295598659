import { BASE_URL } from 'redux/dashboardV2/constants';

const prefix = 'NOTIFICATIONS';

const constants = {
  FETCH_NOTIFICATIONS_DONE: `${prefix}/FETCH_NOTIFICATIONS_DONE`,
  CLEAR_NOTIFICATIONS: `${prefix}/CLEAR_NOTIFICATIONS`,
  CLEAR_NOTIFICATIONS_FAILURE: `${prefix}/CLEAR_NOTIFICATIONS_FAILURE`,
  CLEAR_NOTIFICATIONS_DONE: `${prefix}/CLEAR_NOTIFICATIONS_DONE`,
  RESET_NOTIFICATIONS: `${prefix}/RESET_NOTIFICATIONS`,
  TOGGLE_PANEL: `${prefix}/TOGGLE_PANEL`,
  RESET_PANEL: `${prefix}/RESET_PANEL`,
};

export const BASE_NOTIFICATIONS_URL = `${BASE_URL}/my_feed/notifications`;
export const FETCH_INTERVAL = 120000;
export const REQUEST_TIME_LIMIT = 20000;
export const NOTIFICATIONS_COUNT_KEY = 'notificationsCount';

export { BASE_URL };
export default constants;
