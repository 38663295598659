import constants from './constants';

const defaultState = {
  allLegislators: {},
  legislators: [],
  geoCodeResults: {},
  geoId: null,
  ui: {
    isLoadingLegislators: false,
  },
};

function legislatorLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_ALL_LEGISLATORS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingLegislators: true },
      };
    case constants.FETCH_LEGISLATORS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingLegislators: true, searchTouched: true },
      };
    case constants.RECEIVE_ALL_LEGISLATORS_DONE:
      return {
        ...state,
        allLegislators: action.payload,
        ui: { ...state.ui, isLoadingLegislators: false },
      };
    case constants.RECEIVE_LEGISLATORS_DONE:
      return {
        ...state,
        legislators: action.payload.legislators,
        geoId: action.payload.geoId,
        ui: { ...state.ui, isLoadingLegislators: false },
      };
    case constants.RECEIVE_LEGISLATORS_FAIL:
      return {
        ...state,
        legislators: [],
        geoId: null,
        ui: { ...state.ui, isLoadingLegislators: false },
      };
    case constants.FETCH_DISTRICT:
      return {
        ...state,
        ui: { ...state.ui, isLoadingLegislators: true },
      };
    case constants.RECEIVE_DISTRICT_FAIL:
      return {
        ...state,
        legislators: [],
        geoId: null,
        ui: { ...state.ui, isLoadingLegislators: false },
      };
    default:
      return state;
  }
}

export default legislatorLookup;
