import isEmpty from 'lodash/isEmpty';

import { getValue, transformReceiptContributions } from 'redux/moneyball/utils/transformers';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import {
  fetchSearch,
  fetchSearchReceipt,
  fetchReport,
  transformPaginator,
  transformSort,
  isSearchPayload,
} from 'redux/moneyball/utils';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import {
  loadSavedSearches,
  loadRecentSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformReceiptsForFE,
  transformSearchesForFE,
  transformOptionsForBE,
  transformOptionsSearchForBE,
} from './utils';
import constants from './constants';

export function initAppliedSearchFilters(emptyState, { searchType }) {
  return handleAppliedFilters({ ...emptyState, ...(searchType && { searchType }) });
}

export function loadReceiptSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadReceiptRecentSearches() {
  return (dispatch) => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveReceiptAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformOptionsSearchForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadReceiptRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeReceiptAdvancedSearch(id) {
  return onRemoveItem(id, loadReceiptSavedSearches);
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map((item) => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/receipts/export`;
  const data = JSON.stringify({
    receipt_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'contributor_name',
      'contributor_url',
      'contributor_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'amount',
      'receipt_date',
      'election_type',
      'conduit',
      'recipient_name',
      'recipient_url',
      'source_type',
      'filing_url',
      'contributor_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function searchReceipt({ id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_RECEIPT });

    return dispatch(
      fetchSearchReceipt({
        url: `${gon.moneyballApiV2}/api/v1/receipts/${id}`,
        transformResponse: transformReceiptContributions,
        actionType: constants.RECEIVE_RECEIPT_DONE,
      }),
    );
  };
}

function fetchCompanyStakeholdersContributions(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_RECEIPTS });

    const {
      page = 1,
      sort_column: sort,
      sort_direction: direction,
      super_candidate_recipients = {},
    } = payload;
    const { tokens: super_candidates } = super_candidate_recipients;
    const data = {
      search_form: { page, sort, direction, super_candidates },
    };
    const isSearch = isSearchPayload(payload);
    const url = '/api_web/stakeholder-management/company_contributions/lookup';
    const request = $.ajax({ url, data });

    function onSuccess(response) {
      return dispatch({
        error: false,
        type: constants.RECEIVE_RECEIPTS_DONE,
        toResetSelectedItems: isSearch,
        payload: transformReceiptsForFE(response.items),
        paginator: transformPaginator(response),
        sort: {
          ...transformSort(payload),
          toReset: isSearch,
        },
      });
    }

    return Promise.resolve(request)
      .then(onSuccess);
  };
}

export function searchReceipts(payload = {}, stakeholdersContributionsOnly = false) {
  if (stakeholdersContributionsOnly) {
    return fetchCompanyStakeholdersContributions(payload);
  }

  return (dispatch) => {
    dispatch({ type: constants.FETCH_RECEIPTS });

    return dispatch(
      fetchSearch({
        payload,
        url: `${gon.moneyballApiV2}/api/v1/receipts/lookup`,
        actionType: constants.RECEIVE_RECEIPTS_DONE,
        transformResponsePayload: transformReceiptsForFE,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformOptionsSearchForBE,
        saveRecentAdvancedSearch: saveReceiptAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return (dispatch) => dispatch(resetAppliedFilters());
}
