import { MONEYBALL_CANDIDATE_PROFILE_STRATEGY_SLUG } from 'redux/moneyball/candidatePages/profile/constants';
import { MONEYBALL_INDIVIDUAL_PROFILE_STRATEGY_SLUG } from 'redux/moneyball/individualPages/profile/constants';

export const DEFAULT_PER_PAGE = 10;

const PREFIX = 'ADVANCED_SEARCH';
const constants = {
  FETCH_SAVED_SEARCHES_DATA: `${PREFIX}/FETCH_SAVED_SEARCHES_DATA`,
  RECEIVE_SAVED_SEARCHES_DATA_DONE: `${PREFIX}/RECEIVE_SAVED_SEARCHES_DATA_DONE`,
  FETCH_RECENT_SEARCHES_DATA: `${PREFIX}/FETCH_RECENT_SEARCHES_DATA`,
  RECEIVE_RECENT_SEARCHES_DATA_DONE: `${PREFIX}/RECEIVE_RECENT_SEARCHES_DATA_DONE`,
  SAVE_SEARCH_ITEM: `${PREFIX}/SAVE_SEARCH_ITEM`,
  SAVE_RECENT_SEARCH_ITEM: `${PREFIX}/SAVE_RECENT_SEARCH_ITEM`,
  SAVE_RECENT_SEARCH_ITEM_DONE: `${PREFIX}/SAVE_RECENT_SEARCH_ITEM_DONE`,
  SAVE_SEARCH_ITEM_DONE: `${PREFIX}/SAVE_SEARCH_ITEM_DONE`,
  REMOVE_SEARCH_ITEM: `${PREFIX}/REMOVE_SEARCH_ITEM`,
  REMOVE_SEARCH_ITEM_DONE: `${PREFIX}/REMOVE_SEARCH_ITEM_DONE`,
  SET_LAST_SEARCH_ID: `${PREFIX}/SET_LAST_SEARCH_ID`,
};

export const STRATEGY_SLUGS_WITHOUT_LAST_SEARCH_ID = [
  MONEYBALL_CANDIDATE_PROFILE_STRATEGY_SLUG,
  MONEYBALL_INDIVIDUAL_PROFILE_STRATEGY_SLUG,
];

export default constants;
