import { compact, isEmpty, snakeCase } from 'lodash';

import { valueOrNull } from 'utils/forms/valueOrEmpty';
import { getDisplayItem } from 'redux/advancedSearch/utils';

import {
  getValue,
  transformSelectValues,
  getLabelFromItem,
  getDateLabel,
} from 'utils/transformers/advancedSearch';
import {
  getLocationLabelsForRecentSearches,
  getLocationRadius,
  getLocations,
  getLocationType,
  transformSelectLocationValues,
  getLabelFromBoolean,
} from './index';

/** Stakeholders data object structure
* {
 * id,
 * first_name,
 * last_name,
 * middle_name,
 * email,
 * address,
 * organization,
 * party_code,
 * phone,
 * tags,
 * lists,
 * custom_fields,
 * facebook_profile,
 * twitter_username,
* }
*/

const STAKEHOLDER_BASE_URL = gon.isAdvocatesEnabled
  ? '/advocates/'
  : '/stakeholder_management/stakeholder/';

export function transformStakeholdersForUI(stakeholders = []) {
  return stakeholders.map(({
    id,
    ...restProps
  }) => ({
    ...restProps,
    id,
    url: `${STAKEHOLDER_BASE_URL}${id}`,
  }));
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    organization,
    smartLists,
    lists,
    tags,
    creationDate,
    title,
    districts,
    districtType,
    locations,
    locationType,
    isPrimaryLocation,
    locationRadius,
    emailOptedInOnly,
    smsOptedInOnly,
  } = options || {};

  const primaryAddressOnly = !isEmpty(locations) && isPrimaryLocation;

  return {
    ...getValue('term', searchValue),
    ...getValue('smart_lists_ids', transformSelectValues(smartLists)),
    ...getValue('lists', transformSelectValues(lists)),
    ...getValue('tags', transformSelectValues(tags)),
    ...getValue('organization', transformSelectValues(organization)),
    ...getValue('title', transformSelectValues(title)),
    ...getValue(snakeCase(districtType), transformSelectValues(districts)),
    ...getValue('starts_at', creationDate && creationDate.start),
    ...getValue('ends_at', creationDate && creationDate.end),
    ...getValue('locations', transformSelectLocationValues(locations)),
    ...getValue('primary_address_only', primaryAddressOnly),
    ...getValue('zip_codes_radius', getLocationRadius(locationRadius, locations, locationType)),
    ...getValue('email_opted_in_only', emailOptedInOnly),
    ...getValue('sms_opted_in_only', smsOptedInOnly),
  };
}

function transformOptionsForUI(data = {}) {
  const { id, search_params: searchParams } = data;
  const {
    term,
    organization,
    title,
    smart_lists_ids,
    lists,
    tags,
    starts_at,
    ends_at,
    congressional_districts,
    state_upper_districts,
    state_lower_districts,
    zip_codes_radius,
    primary_address_only,
    email_opted_in_only,
    sms_opted_in_only,
  } = searchParams || {};

  const districts = {
    congressionalDistricts: congressional_districts,
    stateUpperDistricts: state_upper_districts,
    stateLowerDistricts: state_lower_districts,
  };

  const districtValues = [].concat(
    ...Object
      .values(districts)
      .filter(district => !isEmpty(district))
  );

  const districtType = Object.keys(districts)
    .filter(district => !isEmpty(districts[district]))[0] || 'none';

  const creationDate = { start: valueOrNull(starts_at), end: valueOrNull(ends_at) };

  return {
    id,
    options: {
      ...getValue('searchValue', term),
      ...getValue('smartLists', smart_lists_ids),
      ...getValue('lists', lists),
      ...getValue('tags', tags),
      ...getValue('organization', organization),
      ...getValue('title', title),
      ...getValue('districts', districtValues),
      ...getValue('districtType', districtType),
      ...getValue('creationDate', creationDate),
      ...getValue('locationRadius', zip_codes_radius),
      ...getValue('isPrimaryLocation', primary_address_only),
      ...getValue('locationType', getLocationType(searchParams)),
      ...getValue('locations', getLocations(searchParams)),
      ...getValue('emailOptedInOnly', email_opted_in_only),
      ...getValue('smsOptedInOnly', sms_opted_in_only),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}) {
  const { name, search_params: searchParams } = data;
  const {
    term,
    smart_lists_ids,
    lists,
    tags,
    organization,
    starts_at,
    ends_at,
    congressional_districts,
    state_upper_districts,
    state_lower_districts,
    title,
    locations,
    zip_codes_radius,
    primary_address_only,
    email_opted_in_only,
    sms_opted_in_only,
  } = searchParams || {};

  const locationParams = {
    locations,
    primaryAddressOnly: primary_address_only,
    locationRadius: zip_codes_radius,
  };

  const filters = [
    getDisplayItem('Searched', term),
    getDisplayItem('Smart List', getLabelFromItem(smart_lists_ids)),
    getDisplayItem('List', getLabelFromItem(lists)),
    getDisplayItem('Tag', getLabelFromItem(tags)),
    getDisplayItem('Organization', getLabelFromItem(organization)),
    getDisplayItem('Title', getLabelFromItem(title)),
    getDisplayItem('Congressional Districts', getLabelFromItem(congressional_districts)),
    getDisplayItem('State Upper Districts', getLabelFromItem(state_upper_districts)),
    getDisplayItem('State Lower Districts', getLabelFromItem(state_lower_districts)),
    getDisplayItem('Date created', getDateLabel(starts_at, ends_at)),
    getDisplayItem('Location', getLocationLabelsForRecentSearches(locationParams)),
    getDisplayItem('Limit to email-eligible stakeholders', getLabelFromBoolean(email_opted_in_only)),
    getDisplayItem('Limit to text-eligible stakeholders', getLabelFromBoolean(sms_opted_in_only)),
  ];
  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformSearchesForUI(data) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item),
    ...transformOptionsForUI(item),
  }));
}

export function getExportInfo(data = {}) {
  const { searchValue = '' } = data;
  const {
    organization,
    smartLists,
    lists,
    tags,
    creationDate,
    districts,
    districtType,
    title,
    locations,
    isPrimaryLocation,
    locationRadius,
  } = data.options || {};

  return {
    'strategy_inputs[term]': searchValue,
    'strategy_inputs[organization]': transformSelectValues(organization),
    'strategy_inputs[title]': transformSelectValues(title),
    'strategy_inputs[smart_lists_ids]': transformSelectValues(smartLists),
    'strategy_inputs[lists]': transformSelectValues(lists),
    'strategy_inputs[tags]': transformSelectValues(tags),
    [`strategy_inputs[${snakeCase(districtType)}]`]: transformSelectValues(districts),
    'strategy_inputs[starts_at]': creationDate && creationDate.start,
    'strategy_inputs[ends_at]': creationDate && creationDate.end,
    'strategy_inputs[locations]': transformSelectLocationValues(locations),
    'strategy_inputs[zip_codes_radius]': locationRadius,
    'strategy_inputs[primary_address_only]': isPrimaryLocation,
  };
}
