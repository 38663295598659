import { isEmpty, omitBy, pickBy, identity, map } from 'lodash';
import moment from 'moment/moment';

import { CSV_ID, EXPORT_EMAIL_LIMIT } from 'utils/constants/export';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { FULL_DATE_FORMAT } from 'utils/constants/date';
import { submitExportReport } from 'redux/exportReport/actions';

import { transformExecutive } from '../utils/transformers';
import { EXPORT_STRATEGY_SLUG } from '../utils/constants';
import { getExportInfo, getExportSortInfo, getExecutivesData } from '../utils';
import constants from '../constants';

export function submitExport(payload = {}) {
  const { items, searchData, exportOption, sort } = payload;
  const exportIds = items && items.map(item => item.id);
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: EXPORT_STRATEGY_SLUG,
    'strategy_inputs[ids]': exportIds,
    'strategy_inputs[file_extension]': exportOption || CSV_ID,
    ...omitBy(getExportInfo(searchForm), isEmpty),
    ...pickBy(getExportSortInfo(sort), identity),
  };

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `Local executives ${moment().format(FULL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({
    ...payload,
    data,
    onSuccessCustom,
  });
}

export function getAllEmails(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.FETCH_LOCAL_EXECUTIVES_EMAILS });

    const { appliedFilters } = getState().local.localExecutiveLookup.appliedFilters;

    const { url, data } = getExecutivesData({ ...payload, searchData: appliedFilters });
    const ajax = $.post(url, { ...data, per_page: EXPORT_EMAIL_LIMIT });

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_LOCAL_EXECUTIVES_EMAILS_DONE,
        payload: map(response.data, transformExecutive),
      });
    }

    return Promise.resolve(ajax).then(onSuccess);
  };
}
