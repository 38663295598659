import { map, reject } from 'lodash';

import { camelizeKeys } from 'utils/object';

const updateSubscriptionUsers = (users, isTracking) => {
  const { userId, firstName, lastName, email, profileImgUrl } = camelizeKeys(gon.currentUser);
  const fullName = `${firstName} ${lastName}`;
  const currentUser = {
    id: userId,
    name: fullName,
    email,
    ...(profileImgUrl && { photoUrl: profileImgUrl }),
  };

  const usersWithoutCurrentUser = reject(users, { id: userId });
  const usersWithCurrentUser = [...usersWithoutCurrentUser, currentUser];

  return isTracking ? usersWithCurrentUser : usersWithoutCurrentUser;
};

export const updateWorkspaceSubscription = (workspace, newFrequency, isTracking) => {
  const { subscription } = workspace;
  const { users } = subscription || {};

  return ({
    ...workspace,
    subscription: {
      ...subscription,
      frequency: newFrequency,
      users: updateSubscriptionUsers(users, isTracking),
    },
  });
};

export const getFeedWithNewFrequency = (feed, payload) => (
  map(feed, workspace => (
    workspace.token === payload.token
      ? updateWorkspaceSubscription(workspace, payload.frequency, payload.isTracking)
      : workspace
  ))
);

export const getFeedWithoutDeletedItem = (feed, token) => reject(feed, { token });
