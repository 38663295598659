const PREFIX = 'FEDERAL_COMMITTEES';

const constants = {
  STRATEGY_SLUG: 'federal_committee',
  STRATEGY_SLUG_FOR_EXPORT: 'federal_committees',
  FETCH_COMMITTEES: `${PREFIX}/FETCH_COMMITTEES`,
  RECEIVE_COMMITTEES_DONE: `${PREFIX}/RECEIVE_COMMITTEES_DONE`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  FETCH_COMMITTEES_EMAILS: `${PREFIX}/FETCH_COMMITTEES_EMAILS`,
  RECEIVE_COMMITTEES_EMAILS_DONE: `${PREFIX}/RECEIVE_COMMITTEES_EMAILS_DONE`,
  RESET_COMMITTEES: `${PREFIX}/RESET_COMMITTEES`,
  SET_COMMITTEES: `${PREFIX}/SET_COMMITTEES`,
  BULK_UPDATE_DATA: `${PREFIX}/BULK_UPDATE_DATA`,
  BULK_TRACK_DATA: `${PREFIX}/BULK_TRACK_DATA`,
  BULK_TRACK_DATA_DONE: `${PREFIX}/BULK_TRACK_DATA_DONE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  BULK_SHARE_TO_MY_FEED: `${PREFIX}/BULK_SHARE_TO_MY_FEED`,
  BULK_SHARE_TO_MY_FEED_DONE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_DONE`,
  BULK_SHARE_TO_MY_FEED_FAILURE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_FAILURE`,
};

export default constants;
