import { map, isEmpty, omitBy } from 'lodash';

/** Staffers section */
export function transformStaffersForBE(payload) {
  const { id, ...restProps } = payload;

  return {
    ...restProps,
    search_form: omitBy({
      legislator_ids: [id],
    }, isEmpty),
  };
}

export function transformStaffersForUI(staffersData = {}) {
  const { data } = staffersData;

  if (isEmpty(data)) {
    return { allItems: [] };
  }

  return {
    allItems: map(data, (staffer = {}) => {
      const { id, alias_name, full_name, title, responsibilities, email, phone, url } = staffer;
      return {
        id,
        name: alias_name || full_name,
        title,
        issues: responsibilities,
        email,
        phone,
        url,
      };
    }),
  };
}
