import { get, map, reduce, findIndex, uniqBy, orderBy } from 'lodash';

import { transformRecentEvent } from 'redux/dashboardV2/feedPage/utils/transformers/recentEvents';
import { getMyFeedToken } from 'components/Dashboard/utils';

function groupWorkspaces(data = []) {
  return reduce(data, (notifications, notification) => {
    const feed = get(notification, 'feed');
    const eventType = get(notification, 'event_type');
    const documentId = get(notification, 'event_data.document_id');
    const matchedId = findIndex(
      notifications,
      (previousItem) => {
        const previousDocumentId = get(previousItem, 'event_data.document_id');
        const previousEventType = get(previousItem, 'event_type');
        const isKeyword = /^keyword/.test(eventType);
        const isEqual = previousEventType === eventType
          && previousDocumentId === documentId;

        return !isKeyword && isEqual;
      }
    );

    // reduce duplicate notification from multiple feeds
    const { workspaces = [], ...currentNotification } = notifications[matchedId] || notification;
    const uniqueWorkspaces = uniqBy(feed ? [...workspaces, feed] : workspaces, 'token');
    const sortedWorkspaces = orderBy(uniqueWorkspaces, ({ token }) => token !== getMyFeedToken());
    const formattedNotification = { ...currentNotification, workspaces: sortedWorkspaces };

    if (matchedId !== -1) {
      notifications[matchedId] = { ...formattedNotification, feed: sortedWorkspaces[0] };

      return notifications;
    }

    return [...notifications, formattedNotification];
  }, []);
}

export function transformResponseData({ data }) {
  const formattedNotifications = groupWorkspaces(data);

  return map(formattedNotifications, ({ token, workspaces, ...restProps }) => ({
    ...(transformRecentEvent({ ...restProps, isNotification: true })),
    workspaces,
    id: token,
  }));
}
