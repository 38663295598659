import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import {
  COMMITTEE_TYPE,
  LEGISLATOR_TYPE,
  OTHER_TYPE,
} from './constants';

export function getOfficeStaffer(congressionalPositions) {
  return map(congressionalPositions, (position = {}) => {
    const {
      committee,
      legislator,
      employment,
    } = position;

    if (!isEmpty(committee) && !isEmpty(committee.name)) {
      return {
        ...position,
        boss: { ...committee, type: COMMITTEE_TYPE },
      };
    }

    if (!isEmpty(legislator) && !isEmpty(legislator.name)) {
      return {
        ...position,
        boss: { ...legislator, type: LEGISLATOR_TYPE },
      };
    }

    if (!isEmpty(employment) && !isEmpty(employment.name)) {
      return {
        ...position,
        boss: { ...employment, type: OTHER_TYPE },
      };
    }

    return position;
  });
}
