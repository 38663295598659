import {
  bulkShareLookupToMyFeed,
  fetchLookupSubscriptions,
  bulkTrackLookupItems,
  fetchDefaultSubscription,
} from 'redux/subscriptions/actions';

import constants from '../constants';

export function bulkShareToMyFeed(payload) {
  return dispatch => dispatch(bulkShareLookupToMyFeed(payload, constants));
}

export function fetchSubscriptions(payload = {}, documentType) {
  return (dispatch) => {
    dispatch(fetchDefaultSubscription(constants));
    dispatch(fetchLookupSubscriptions(payload, documentType, constants));
  };
}

export function bulkTrackItems(payload) {
  return dispatch => dispatch(bulkTrackLookupItems(payload, constants));
}
