import { compact } from 'lodash';

import { getDisplayItem, mapBackendValues } from 'redux/advancedSearch/utils';
import {
  getLabelFromItem,
  formatSelectValue,
  getValue,
  transformSelectValues,
  transformArraySelectValues,
} from 'utils/transformers/advancedSearch';

function transformOptionsForUI(data = {}) {
  const { id } = data;
  const {
    keyword,
    state_offices,
    states,
    titles,
    include_historical,
    office_location_types,
    committee_ids,
    parties,
    chambers,
  } = data.search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', keyword),
      ...getValue('worksFor', state_offices),
      ...getValue('states', states),
      ...getValue('titles', formatSelectValue(titles)),
      ...getValue('includeHistorical', Boolean(include_historical)),
      ...getValue('officeLocations', formatSelectValue(office_location_types)),
      ...getValue('committees', committee_ids),
      ...getValue('party', parties),
      ...getValue('chambers', chambers),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}, stafferData = {}) {
  const { name } = data;
  const {
    keyword,
    state_offices,
    states,
    titles,
    office_location_types,
    committee_ids,
    include_historical,
    parties,
    chambers,
  } = data.search_params;

  const filters = [
    getDisplayItem('Searched', keyword),
    getDisplayItem('Serves', getLabelFromItem(state_offices)),
    getDisplayItem('States', getLabelFromItem(states)),
    getDisplayItem('Titles', titles),
    getDisplayItem('Office Location', office_location_types),
    getDisplayItem('Legislator committee membership', getLabelFromItem(committee_ids)),
    getDisplayItem('Historical staffers', include_historical && 'Yes'),
    getDisplayItem('Party', mapBackendValues(parties, stafferData.parties)),
    getDisplayItem('Chamber', mapBackendValues(chambers, stafferData.chambers)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue } = data;
  const {
    worksFor,
    states,
    titles,
    officeLocations,
    includeHistorical,
    committees,
    party,
    chamber,
  } = data.options || {};

  return {
    ...getValue('keyword', searchValue),
    ...getValue('state_offices', transformSelectValues(worksFor)),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('titles', transformSelectValues(titles)),
    ...getValue('include_historical', includeHistorical),
    ...getValue('office_location_types', transformSelectValues(officeLocations)),
    ...getValue('committee_ids', transformArraySelectValues(committees)),
    ...getValue('parties', party),
    ...getValue('chambers', chamber),
  };
}

export function transformSearchesForUI(data, stafferData) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item, stafferData),
    ...transformOptionsForUI(item),
  }));
}

export function transformStaffersForUI(staffers = []) {
  return staffers.map(({
    id,
    alias_name,
    full_name,
    party,
    boss_name,
    boss_party,
    boss_party_short,
    boss_party_slug,
    boss_url,
    works_for_label,
    title,
    email,
    chamber_category,
    chamber_name,
    office_type,
    office_name,
    party_short,
    party_slug,
    staffable_type,
    staffable_id,
    state_name,
  }) => ({
    id,
    name: full_name || alias_name,
    party,
    partyShort: party_short,
    partySlug: party_slug,
    bossName: boss_name,
    bossParty: boss_party,
    bossPartyShort: boss_party_short,
    bossPartySlug: boss_party_slug,
    bossUrl: boss_url,
    worksForLabel: works_for_label,
    url: `/state/staffers/${id}`,
    title,
    email,
    location: office_type,
    officeName: office_name,
    chamberCategory: chamber_category,
    chamberName: chamber_name,
    staffableType: staffable_type,
    staffableId: staffable_id,
    stateName: state_name,
  }));
}

export function getExportInfo(data = {}) {
  const { searchValue } = data;
  const {
    worksFor,
    states,
    titles,
    officeLocations,
    party,
    chamber,
    committees,
  } = data.options || {};

  return {
    'strategy_inputs[keyword]': searchValue,
    'strategy_inputs[state_offices]': transformSelectValues(worksFor),
    'strategy_inputs[states]': transformSelectValues(states),
    'strategy_inputs[titles]': transformSelectValues(titles),
    'strategy_inputs[office_location_types]': transformSelectValues(officeLocations),
    'strategy_inputs[committee_ids]': transformSelectValues(committees),
    'strategy_inputs[parties]': party,
    'strategy_inputs[chambers]': chamber,
  };
}
