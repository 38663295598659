import { filter, truncate, find } from 'lodash';

import { STATE_BILL_STATUS } from 'components/core/Profile/components/Cards/Bills/utils/billStatus';
import { responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { formatMonthDayYear } from 'utils/date';
import {
  STATE_BILL_ITEM_TYPE,
  STATE_COMMITTEE_ITEM_TYPE,
  STATE_REGULATION_ITEM_TYPE,
  STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE,
} from 'utils/constants/itemTypes';
import { BASE_URL } from 'utils/constants';
import { normalizeUrl } from 'utils/url';

import { getStateRegulationUrl, getTitle, getTitleUrl } from '../utils';
import {
  transformSponsor,
  transformLastAction,
  transformSubscriptions,
  transformSession,
  transformStaffer,
} from './commonTransformers';

function transformBill({ item_data, subscriptions, is_disabled }) {
  const {
    id,
    title,
    cosponsors,
    sponsor,
    status_type,
    introduced_on,
    official_source,
    last_action,
    description,
    session,
    state,
    bill_summary,
  } = item_data || {};
  const { label: statusLabel } = find(STATE_BILL_STATUS, { value: status_type }) || {};
  const { alpha2 } = state || {};

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    category: STATE_BILL_ITEM_TYPE,
    titleUrl: getTitleUrl(id, '/state/bills'),
    title,
    description,
    billSummary: bill_summary,
    status: statusLabel,
    sponsor: transformSponsor(sponsor, '/state/legislators'),
    cosponsors,
    displayTitle: {
      cardTitle: title,
      stateShort: alpha2,
    },
    introducedOn: formatMonthDayYear(introduced_on),
    officialSource: official_source,
    lastAction: transformLastAction(last_action),
    session: transformSession(session),
  };
}

function transformRegulation({ item_data, subscriptions, is_disabled }) {
  const {
    id,
    title,
    agencies,
    state,
    code_citation,
    administrative_code_url,
    official_source,
    last_action,
  } = item_data || {};
  const { alpha2 } = state || {};
  const {
    display_label,
    url: lastActionUrl,
    label: lastActionLabel,
    published_date: lastActionDate,
  } = last_action || {};

  const officialSource = official_source && normalizeUrl(`${BASE_URL}/${official_source}`);

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    category: STATE_REGULATION_ITEM_TYPE,
    titleUrl: getStateRegulationUrl({ regulation_id: id }),
    title,
    officialSource,
    displayTitle: {
      cardTitle: title,
      stateShort: alpha2,
    },
    agency: filter(agencies, Boolean).join(' - '),
    codeCitation: {
      href: administrative_code_url,
      label: truncate(code_citation, { length: 70 }),
    },
    latestPublication: {
      href: lastActionUrl,
      label: display_label,
      lastActionLabel,
      lastActionDate,
    },
  };
}

function transformCommittee({ item_data, send_week_ahead_email, subscriptions, is_disabled }) {
  const {
    id,
    title,
    chair,
    action_date,
    staffer,
    state,
    party_breakdown,
  } = item_data || {};
  const { alpha2 } = state || {};

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    isCommittee: true,
    titleUrl: getTitleUrl(id, '/state/committees'),
    category: STATE_COMMITTEE_ITEM_TYPE,
    state,
    displayTitle: {
      cardTitle: title,
      stateShort: alpha2,
    },
    partyBreakdown: party_breakdown,
    sendWeekAheadEmail: send_week_ahead_email,
    chair: transformSponsor(chair, '/state/legislators'),
    lastActionDate: formatMonthDayYear(action_date),
    staffer: transformStaffer(staffer, '/state/staffers'),
  };
}

function transformLegislator({ item_data, subscriptions, is_disabled }) {
  const {
    id,
    staffer,
    action_date,
    addressable_moniker: title,
    state: { alpha2 } = {},
  } = item_data || {};

  const category = getTitle(alpha2, STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE);

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    category,
    title,
    titleUrl: getTitleUrl(id, '/state/legislators'),
    officeHolder: transformSponsor(item_data, '/state/legislators'),
    staffer: transformStaffer(staffer, '/state/staffers'),
    lastActionDate: formatMonthDayYear(action_date),
  };
}

export const STATE_TRACKED_TYPE = {
  [responseTrackingTypes.STATE_BILL]: transformBill,
  [responseTrackingTypes.STATE_REGULATION]: transformRegulation,
  [responseTrackingTypes.STATE_COMMITTEE_HEARING]: transformCommittee,
  [responseTrackingTypes.STATE_COMMITTEE_STAFFER]: transformCommittee,
  [responseTrackingTypes.STATE_LEGISLATOR_STAFFER]: transformLegislator,
};
