const KEYWORD = 'keyword';

const constants = {
  CREATE_KEYWORD: 'DASHBOARD_DISCOVERY_KEYWORD_MANAGER/CREATE_KEYWORD',
  UPDATE_KEYWORD: 'DASHBOARD_DISCOVERY_KEYWORD_MANAGER/UPDATE_KEYWORD',
  FETCH_KEYWORD: 'DASHBOARD_DISCOVERY_KEYWORD_MANAGER/FETCH_KEYWORD',
  RECEIVE_KEYWORD: 'DASHBOARD_DISCOVERY_KEYWORD_MANAGER/RECEIVE_KEYWORD',
  CLEAR_KEYWORD: 'DASHBOARD_DISCOVERY_KEYWORD_MANAGER/CLEAR_KEYWORD',
  DELETE_KEYWORD: 'DASHBOARD_DISCOVERY_KEYWORD_MANAGER/DELETE_KEYWORD',
};

export function receiveKeyword(payload) {
  return { type: constants.RECEIVE_KEYWORD, payload };
}

export function clearKeyword(payload) {
  return { type: constants.CLEAR_KEYWORD, payload };
}

export function fetchKeyword(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_KEYWORD, payload });

    // uses api_web_royal_events_subscriptions_path
    const url = `/api_web/royal_events/subscriptions/${payload.token}`;
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax)
      .then(result => dispatch(receiveKeyword(result)))
      .catch((error) => {
        dispatch(receiveKeyword({}));

        throw error;
      });
  };
}

export function createKeyword(payload) {
  return (dispatch) => {
    dispatch({ type: constants.CREATE_KEYWORD, payload });

    // uses api_web_royal_events_subscriptions_path
    const url = '/api_web/royal_events/subscriptions';
    const method = 'POST';
    const contentType = 'application/json';
    const data = JSON.stringify({ subscription: payload.subscription });

    const ajax = $.ajax({
      method,
      url,
      data,
      contentType,
    });

    return Promise.resolve(ajax);
  };
}

export function updateKeyword(payload) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_KEYWORD, payload });

    // uses api_web_royal_events_subscription_path
    const { type, token, subscription } = payload || {};
    const url = `/api_web/discovery/subscriptions/trackings/${type}/${token}`;
    const data = JSON.stringify(subscription);
    const contentType = 'application/json';
    const method = 'PUT';

    const ajax = $.ajax({ url, data, contentType, method });

    return Promise.resolve(ajax);
  };
}

export function deleteKeyword(payload) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_KEYWORD, payload });

    // uses api_web_royal_events_subscription_path
    const url = `/api_web/royal_events/subscriptions/${payload.token}`;
    const method = 'DELETE';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax);
  };
}

const defaultState = {
  keyword: {
    subscribable_slug: KEYWORD,
  },
  ui: {
    loading: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_KEYWORD:
      return {
        ...state,
        keyword: defaultState.keyword,
        ui: { ...state.ui, loading: true },
      };
    case constants.RECEIVE_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
        ui: { ...state.ui, loading: false },
      };
    case constants.CLEAR_KEYWORD:
      return {
        ...state,
        keyword: defaultState.keyword,
      };
    case constants.DELETE_KEYWORD:
      return {
        ...state,
        keyword: defaultState.keyword,
      };
    default:
      return state;
  }
}
