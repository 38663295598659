const PREFIX = 'MONEYBALL_DISBURSEMENT_LOOKUP';

const constants = {
  STRATEGY_SLUG: 'moneyball_disbursement_lookup',
  FETCH_DISBURSEMENTS: `${PREFIX}/FETCH_DISBURSEMENTS`,
  RECEIVE_DISBURSEMENTS_DONE: `${PREFIX}/RECEIVE_DISBURSEMENTS_DONE`,
  FETCH_DISBURSEMENTS_RECEIPT: `${PREFIX}/FETCH_DISBURSEMENTS_RECEIPT`,
  RECEIVE_DISBURSEMENTS_RECEIPT_DONE: `${PREFIX}/RECEIVE_DISBURSEMENTS_RECEIPT_DONE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
