const PREFIX = 'MONEYBALL_SMARTSEARCH_BUILDER';

export const UPDATE_ISTEMPLATE = `${PREFIX}/UPDATE_ISTEMPLATE`;
export const UPDATE_TITLE = `${PREFIX}/UPDATE_TITLE`;
export const UPDATE_DESCRIPTION = `${PREFIX}/UPDATE_DESCRIPTION`;
export const UPDATE_TRANSACTOR = `${PREFIX}/UPDATE_TRANSACTOR`;
export const UPDATE_DATA = `${PREFIX}/UPDATE_DATA`;
export const GET_LIST_START = `${PREFIX}/GET_LIST_START`;
export const GET_LIST_DONE = `${PREFIX}/GET_LIST_DONE`;
export const CREATE_LIST_START = `${PREFIX}/CREATE_LIST_START`;
export const CREATE_LIST_DONE = `${PREFIX}/CREATE_LIST_DONE`;
export const RESET = `${PREFIX}/RESET`;
