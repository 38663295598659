const defaultState = {
  campaign: {
    logo: {},
    thank_you_config: {},
    matchers: [],
    company: {},
  },
  formSubmission: {
    custom_messages: {},
    custom_comment: '',
  },
  customColors: {},
  legislators: [],
  customRecipients: [],
  recipients: [],
  regulation: {},
  previewMode: false,
  ui: {
    loading: true,
    landingPage: true,
    previewPage: false,
    thankYouPage: false,
    messageEditorsOpened: 0,
  },
};

export default defaultState;
