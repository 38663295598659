import { isEmpty, map, toString } from 'lodash';

import { postJson } from 'utils/api';

import constants from './constants';
import {
  getFilteredTags,
  saveTagsAndUpdateRecords,
  transformRecordsForTag,
  transformTagsForBE,
  transformNewTagsForUI,
  transformBulkTagsRecordsForBE,
  transformTagsByRecordIdsForUI,
  transformFetchRecordIdsForBE,
  createTagsNoDispatch,
} from './utils';
import { BASE_URL } from './utils/constants';

export function fetchAllCompanyTags() {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_ALL_COMPANY_TAGS });

    const ajax = $.get(BASE_URL);

    return Promise.resolve(ajax)
      .then(response => dispatch({ type: constants.FETCH_ALL_COMPANY_TAGS_DONE, payload: response }))
      .catch(() => dispatch({ type: constants.FETCH_ALL_COMPANY_TAGS_DONE, payload: [] }));
  };
}

export function createNewTags(payload) {
  return (dispatch) => {
    dispatch({ type: constants.CREATE_NEW_TAG });

    return createTagsNoDispatch(payload)
      .catch(() => dispatch({ type: constants.CREATE_NEW_TAG_DONE, payload: [] }));
  };
}

export function bulkTagRecordsNoDispatch(payload) {
  const data = transformBulkTagsRecordsForBE(payload);
  const ajax = $.ajax({
    url: `${BASE_URL}/bulk`,
    method: 'POST',
    data: JSON.stringify(data),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax);
}

export function bulkTagRecords(payload) {
  return (dispatch) => {
    dispatch({ type: constants.BULK_TAG_RECORDS });

    return bulkTagRecordsNoDispatch(payload)
      .then(() => dispatch({ type: constants.BULK_TAG_RECORDS_DONE }))
      .catch(() => dispatch({ type: constants.BULK_TAG_RECORDS_DONE, payload: [] }));
  };
}

export function updateTagRecordsForRecordNoDispatch(payload) {
  const { recordType, recordId, tagIds } = payload || {};

  const ajax = $.ajax({
    method: 'PUT',
    url: `${BASE_URL}/${recordType}/${recordId}`,
    data: { tag_ids: tagIds },
  });

  return Promise.resolve(ajax);
}

export function updateTagRecordsForRecord(payload) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_TAG_RECORDS });

    return updateTagRecordsForRecordNoDispatch(payload)
      .then(response => dispatch({ type: constants.UPDATE_TAG_RECORDS_DONE, payload: response }))
      .catch(() => dispatch({ type: constants.UPDATE_TAG_RECORDS_DONE, payload: [] }));
  };
}

export function fetchTagsByRecordIdsNoDispatch(payload, isRecent) {
  const data = transformFetchRecordIdsForBE(payload, isRecent);

  if (isEmpty(data)) {
    return Promise.resolve([]);
  }

  const ajax = postJson(`${BASE_URL}/lookup`, { search_form: data });

  return Promise.resolve(ajax)
    .then(response => transformTagsByRecordIdsForUI(response))
    .catch(() => []);
}

export function fetchTagsByRecordIds(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_TAGS_BY_RECORD_IDS });

    return fetchTagsByRecordIdsNoDispatch(payload)
      .then(response => dispatch({
        type: constants.FETCH_TAGS_BY_RECORD_IDS_DONE,
        payload: response,
      }))
      .catch(() => dispatch({
        type: constants.FETCH_TAGS_BY_RECORD_IDS_DONE,
        payload: [],
      }));
  };
}

export function fetchLookupTags({ payload: payloadData } = {}, defaultDocumentType, actionTypes) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_TAGS });

    const data = map(payloadData, ({ id, documentType }) => ({
      id,
      documentType: documentType || defaultDocumentType,
    }));

    function onSuccess(response) {
      dispatch({
        type: actionTypes.RECEIVE_TAGS_DONE,
        payload: response,
      });

      return { payload: payloadData };
    }

    function onFailure(error) {
      dispatch({
        type: actionTypes.RECEIVE_TAGS_DONE,
        payload: [],
      });

      throw error;
    }

    return fetchTagsByRecordIdsNoDispatch(data)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function saveTags({ recordId, values, documentType, onSuccess, onFailure }, actionTypes) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_TAG_ITEM, recordId });

    const { tags } = values || {};
    const { newTags, existingTags } = getFilteredTags(tags);

    function saveTagsForRecord(responseNewTags) {
      return updateTagRecordsForRecordNoDispatch({
        tagIds: transformTagsForBE(existingTags, responseNewTags),
        recordType: documentType,
        recordId: toString(recordId),
      }).then(response => dispatch({
        type: actionTypes.UPDATE_TAG_ITEM_DONE,
        payload: {
          recordId,
          tags: transformNewTagsForUI(response),
        },
      }))
        .then(onSuccess)
        .catch(onFailure);
    }

    return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
  };
}

export function bulkSaveLookupTags({ values, selectedItems, onSuccess, onFailure }, actionTypes) {
  return (dispatch) => {
    if (isEmpty(values)) {
      return Promise.resolve();
    }

    dispatch({ type: actionTypes.BULK_UPDATE_DATA, isLoadingTags: true });

    const { tags } = values || {};
    const { newTags, existingTags } = getFilteredTags(tags);

    function saveTagsForRecord(responseNewTags) {
      return bulkTagRecordsNoDispatch({
        tagIds: transformTagsForBE(existingTags, responseNewTags),
        records: transformRecordsForTag(selectedItems),
      })
        .then(() => dispatch(fetchLookupTags({ payload: selectedItems }, null, actionTypes)))
        .then(onSuccess)
        .catch(onFailure)
        .finally(() => dispatch({ type: actionTypes.BULK_UPDATE_DATA, isLoadingTags: false }));
    }

    return saveTagsAndUpdateRecords({ newTags, saveTagsForRecord });
  };
}

export function fetchProfileTags(payload = {}, actionTypes = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_TAGS });

    function onSuccess(response) {
      const [data] = response || [];
      const { tags = [] } = data || {};

      return dispatch({
        type: actionTypes.RECEIVE_TAGS_DONE,
        payload: tags,
      });
    }

    function onFailure(error) {
      dispatch({
        type: actionTypes.RECEIVE_TAGS_DONE,
        payload: [],
      });

      throw error;
    }

    return fetchTagsByRecordIdsNoDispatch([payload])
      .then(onSuccess)
      .catch(onFailure);
  };
}
