import { isEmpty, capitalize, union } from 'lodash';

import { transformRelevantLinks } from 'redux/state/regulations/profile/utils';
import { formatGlobalDate } from 'utils/date';

function mapDocuments(documents) {
  if (isEmpty(documents)) {
    return [];
  }

  return documents.map(({ id, document_type }) => ({
    id,
    actionType: document_type,
  }));
}

export function transformRegulationForUI(data = {}) {
  if (isEmpty(data)) {
    return {};
  }

  const { summary, documents, related_documents, relevant_links } = data || {};
  const { id, title, state, agency_titles, publication_date } = summary || {};
  const { name: stateName } = state || {};

  return {
    id,
    title,
    state: stateName,
    agency: capitalize(agency_titles),
    publicationDate: formatGlobalDate(publication_date),
    quickReferences: transformRelevantLinks(relevant_links),
    documents: mapDocuments(union(documents, related_documents)),
  };
}

export function transformDocumentForUI(data = {}) {
  return data || {};
}
