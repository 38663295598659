const constants = {
  FETCH_RATINGS: 'ROYAL_COSPONSORS_ORACLE/FETCH_RATINGS',
  RECEIVE_RATINGS: 'ROYAL_COSPONSORS_ORACLE/RECEIVE_RATINGS',
};

export function receiveRatings(payload) {
  return {
    type: constants.RECEIVE_RATINGS,
    payload,
  };
}

export function fetchRatings(legislationLevel, payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_RATINGS, legislationLevel, payload });

    const { model_slugs, ...attributes } = payload;

    let url;

    switch (legislationLevel) {
      case 'federal':
        // uses: api_web_royal_cosponsors_oracle_federal_legislation_ratings_path
        url = '/api_web/royal_cosponsors_oracle/federal_legislation/ratings';
        break;
      case 'state':
        // uses: api_web_royal_cosponsors_oracle_state_legislation_ratings_path
        url = '/api_web/royal_cosponsors_oracle/state_legislation/ratings';
        break;
      default:
        throw TypeError(`Legislation level: ${legislationLevel} not found`);
    }

    const method = 'POST';

    const data = {
      attributes,
      model_slugs,
    };

    const ajax = $.ajax({
      url,
      data,
      method,
    });

    return Promise.resolve(ajax)
      .then(result => dispatch(receiveRatings(result)));
  };
}

const defaultState = {
  ratings: [],
  ui: {
    loading: false,
  },
  searchForm: {},
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_RATINGS:
      return {
        ...state,
        ui: { ...state.ui, loading: true },
        searchForm: action.payload,
      };
    case constants.RECEIVE_RATINGS:
      return {
        ...state,
        ratings: action.payload.ratings,
        ui: { ...state.ui, loading: false },
      };
    default:
      return state;
  }
}
