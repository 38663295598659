import { transformStakeholderToUI } from '../../common/utils';
import constants from '../constants';
import { isMoneyballPermitted } from '../utils';
import { fetchStakeholderContributions } from './contributions';

export function fetchStakeholderProfile(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAKEHOLDER_PROFILE });

    const { stakeholderId } = payload;

    const url = `/api_web/stakeholder-management/stakeholders/${stakeholderId}`;

    const ajax = $.get(url);

    function onSuccess(response) {
      const stakeholderData = response;
      const stakeholder = transformStakeholderToUI(stakeholderData);

      const payloadData = {
        profile: {
          ...stakeholder,
        },
        customFieldsAnswers: stakeholder.customFields,
      };

      isMoneyballPermitted(stakeholder) && dispatch(fetchStakeholderContributions(stakeholder));

      return dispatch({
        type: constants.RECEIVE_STAKEHOLDER_PROFILE_DONE,
        payload: payloadData,
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_STAKEHOLDER_PROFILE_FAIL });
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updateStakeholderDescription(payload = {}) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_STAKEHOLDER_PROFILE_DESCRIPTION,
      payload,
    });
  };
}
