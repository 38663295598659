import { isNil, isString, omitBy } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getPhoneLink } from 'utils/socialMedia/links';
import { FACEBOOK_LINK, INSTAGRAM_LINK, TWITTER_LINK } from 'utils/constants/socialMedia';
import { FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE } from 'utils/constants/itemTypes';
import { isExternal } from 'utils/url';

const SENATOR_ROLE = 'sen';
/** Key Details & Committee section */

function serving_label(is_active, is_elected, serving_since, serving_until) {
  if (is_active) {
    return `Serving since ${serving_since}`;
  }

  if (is_elected) {
    return `Will serve since ${serving_since}`;
  }

  return `Served from ${serving_since}-${serving_until}`;
}

function getSocialMediaUrl(base, url) {
  if (isExternal(url)) {
    return url;
  }

  return `${base}/${url}`;
}

export function transformProfileForUI(profileData = {}) {
  const {
    id,
    biography,
    facebook,
    homepage,
    instagram,
    office,
    party_slug,
    party_short,
    phone,
    photo_path,
    chamber,
    serving_since,
    serving_until,
    twitter,
    role,
    state,
    state_name,
    fec,
    is_active,
    is_elected,
    universal_moniker,
    caucuses_memberships,
    committee_memberships,
    current_leadership_role,
    state_id,
    district_id,
    full_biography,
  } = profileData;

  const homepageLink = homepage && {
    value: homepage,
    href: homepage,
  };

  const twitterLink = twitter && {
    value: twitter,
    href: getSocialMediaUrl(TWITTER_LINK, twitter),
  };

  const facebookLink = facebook && {
    value: facebook,
    href: getSocialMediaUrl(FACEBOOK_LINK, facebook),
  };

  const instagramLink = instagram && {
    value: instagram,
    href: getSocialMediaUrl(INSTAGRAM_LINK, instagram),
  };

  return omitBy({
    legislator: {
      id,
      chamber,
      fecTokens: fec,
      state,
      stateId: state_id,
      districtId: district_id,
      stateName: state_name,
      isSenator: role === SENATOR_ROLE,
      currentLeadershipRole: current_leadership_role,
      party: {
        id: party_short,
        slug: party_slug,
        moniker: universal_moniker,
        avatarUrl: photo_path,
      },
    },
    keyDetails: {
      homepage: homepageLink,
      phone: phone && isString(phone) && { href: getPhoneLink(phone), value: phone },
      twitter: twitterLink,
      facebook: facebookLink,
      instagram: instagramLink,
      office: { value: office },
      isCurrent: is_active,
      isElected: is_elected,
      serving: serving_label(is_active, is_elected, serving_since, serving_until),
      biography: {
        description: biography,
        fullBiography: full_biography,
      },
    },
    recordId: id,
    documentType: DOCUMENT_TYPES.FEDERAL_LEGISLATOR,
    itemType: FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    committees: omitBy({
      data: committee_memberships,
      caucuses: caucuses_memberships,
    }, isNil),
  }, isNil);
}
