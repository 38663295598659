import appliedFiltersConstants from 'redux/appliedSearchFilters/constants';

import constants from '../../constants';

const resetDocuments = () => (dispatch) => {
  dispatch({ type: appliedFiltersConstants.RESET_APPLIED_FILTERS });

  return dispatch({ type: constants.RESET_DOCUMENTS });
};

export default resetDocuments;
