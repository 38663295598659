// TODO: Refactor and move to redux/legislator/lookup when legislators' lookup will be refactored.
import { fetchMapBox } from 'components/map/helpers';
import mapConstants from 'components/map/constants';

const STATE_LEGISLATION_LOWER_LOOKUP_URL = '/api_web/portal/state_legislators/lookup_kw/house';
const STATE_LEGISLATION_UPPER_LOOKUP_URL = '/api_web/portal/state_legislators/lookup_kw/senate';
const CONGRESSIONAL_DISTRICTS_LOOKUP_URL = '/api_web/portal/legislators/lookup_kw';

const constants = {
  FETCH_LEGISLATORS: 'LEGISLATOR_LOOKUP/FETCH_LEGISLATORS',
  RECEIVE_LEGISLATORS: 'LEGISLATOR_LOOKUP/RECEIVE_LEGISLATORS',
  RECEIVE_LEGISLATORS_NOT_FOUND: 'LEGISLATOR_LOOKUP/RECEIVE_LEGISLATORS_NOT_FOUND',
  FETCH_DISTRICT_ID: 'LEGISLATOR_LOOKUP/FETCH_DISTRICT_ID',
  RESET_LEGISLATORS: 'LEGISLATOR_LOOKUP/RESET_LEGISLATORS',
  RECEIVE_GEOCODE_RESULT: 'LEGISLATOR_LOOKUP/RECEIVE_GEOCODE_RESULT',
  ERROR_GEOCODING_ADDRESS: 'LEGISLATOR_LOOKUP/ERROR_GEOCODING_ADDRESS',
  GEOCODE_ADDRESS: 'LEGISLATOR_LOOKUP/GEOCODE_ADDRESS',
};

export function receiveLegislators(payload) {
  return { type: constants.RECEIVE_LEGISLATORS, payload };
}

export function receiveLegislatorsNotFound(payload) {
  return { type: constants.RECEIVE_LEGISLATORS_NOT_FOUND, payload };
}

export const getFetchLegislatorsUrl = (kwId, filterType) => {
  if (filterType === mapConstants.STATE_LEGISLATION_LOWER) {
    return `${STATE_LEGISLATION_LOWER_LOOKUP_URL}/${kwId}`;
  }

  if (filterType === mapConstants.STATE_LEGISLATION_UPPER) {
    return `${STATE_LEGISLATION_UPPER_LOOKUP_URL}/${kwId}`;
  }

  return `${CONGRESSIONAL_DISTRICTS_LOOKUP_URL}/${kwId}`;
};

export function resetLegislators() {
  return { type: constants.RESET_LEGISLATORS };
}

export function fetchLegislators(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LEGISLATORS, payload });

    const { features, filterType } = payload;
    const targetFeature = features.find(f => f.properties.type === filterType);

    if (!targetFeature) {
      return dispatch(receiveLegislators({ legislators: [] }));
    }

    const { properties } = targetFeature;
    const { kwDistrictId } = properties || {};

    if (!kwDistrictId) {
      return dispatch(receiveLegislators({ legislators: [], kwDistrictId }));
    }

    return $.ajax({ url: getFetchLegislatorsUrl(kwDistrictId, filterType), method: 'GET' })
      .then(result => dispatch(receiveLegislators({ ...result, kwDistrictId })))
      .fail(() => dispatch(receiveLegislatorsNotFound({})));
  };
}

export function fetchDistrictId(payload) {
  const { filterType } = payload;
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DISTRICT_ID, payload });
    fetchMapBox(payload.coordinates.join())
      .then(result => dispatch(fetchLegislators({ ...result, filterType })));
  };
}

export function geocodeAddress() {
  return { type: constants.GEOCODE_ADDRESS };
}

export function errorGeocodingAddress() {
  return { type: constants.ERROR_GEOCODING_ADDRESS };
}

export function receiveGeocodeResult({ address, result }) {
  return { type: constants.RECEIVE_GEOCODE_RESULT, payload: { address, result } };
}

export function geocodeLegislatorAddress(address, dispatch, getState) {
  const { legislatorLookup } = getState();
  const { geoCodeResults } = legislatorLookup;

  dispatch(geocodeAddress());

  if (geoCodeResults[address]) {
    dispatch(receiveGeocodeResult({ address, result: geoCodeResults[address] }));
    return Promise.resolve(geoCodeResults[address]);
  }

  const request = $.ajax({
    url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json`,
    data: {
      access_token: gon.mapboxAccessToken,
    },
    type: 'GET',
  });

  return Promise.resolve(request)
    .then(({ features }) => {
      if (!features.length) {
        throw new Error('Can\'t geocode address');
      }

      const { center: result } = features[0];
      dispatch(receiveGeocodeResult({ address, result }));

      return result;
    })
    .catch((error) => {
      dispatch(errorGeocodingAddress());
      throw error;
    });
}

export function getLegislatorsByAddress(payload = {}) {
  const { address, type } = payload;

  return (dispatch, getState) => geocodeLegislatorAddress(address, dispatch, getState)
    .then((coordinates) => {
      dispatch(fetchDistrictId({ coordinates, filterType: type }));
    })
    .catch((error) => {
      throw error;
    });
}

const defaultState = {
  legislators: [],
  geoCodeResults: {},
  kwDistrictId: null,
  ui: {
    loadingLegislators: false,
    geocodingAddress: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_LEGISLATORS:
      return {
        ...state,
        ui: { ...state.ui, loadingLegislators: true, searchTouched: true },
      };
    case constants.RECEIVE_LEGISLATORS:
      return {
        ...state,
        legislators: action.payload.legislators,
        kwDistrictId: action.payload.kwDistrictId,
        ui: { ...state.ui, loadingLegislators: false },
      };
    case constants.RECEIVE_LEGISLATORS_NOT_FOUND:
      return {
        ...state,
        legislators: [],
        kwDistrictId: null,
        ui: { ...state.ui, loadingLegislators: false },
      };
    case constants.FETCH_DISTRICT_ID:
      return {
        ...state,
        ui: { ...state.ui, loadingLegislators: true },
      };
    case constants.RESET_LEGISLATORS:
      return {
        ...state,
        legislators: [],
        kwDistrictId: null,
      };
    // Geocode address
    case constants.GEOCODE_ADDRESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          geocodingAddress: true,
        },
      };
    case constants.ERROR_GEOCODING_ADDRESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          geocodingAddress: false,
        },
      };
    case constants.RECEIVE_GEOCODE_RESULT:
      return {
        ...state,
        geoCodeResults: {
          ...state.geoCodeResults,
          [action.payload.address]: action.payload.result,
        },
        ui: {
          ...state.ui,
          geocodingAddress: false,
        },
      };
    default:
      return state;
  }
}
