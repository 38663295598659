const keyword = 'VOTING_RECORD';

const constants = {
  FETCH_BILLS: `${keyword}/FETCH_BILLS`,
  RECEIVE_BILLS_DONE: `${keyword}/RECEIVE_BILLS_DONE`,
};

export const FEDERAL_LEGISLATOR_SLUG = 'federal_legislator';
export const STATE_LEGISLATOR_SLUG = 'state_legislator';

export default constants;
