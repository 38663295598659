import { orderBy } from 'lodash';

import constants from '../constants';

export function sortStakeholderSmartListByColumn(items, columnName, sortDirection) {
  return (dispatch) => {
    dispatch({ type: constants.SORT_STAKEHOLDER_SMART_LIST_DATA });
    items = orderBy(
      items,
      [columnName],
      [sortDirection]
    );

    return dispatch({
      type: constants.SORT_STAKEHOLDER_SMART_LIST_DATA_DONE,
      payload: items,
    });
  };
}
