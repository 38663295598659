import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { fetchSearch } from 'redux/moneyball/utils';

import { transformVendors, transformOptionsForBE } from './utils';
import constants from './constants';

export function searchVendors(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_VENDORS });

    return dispatch(
      fetchSearch({
        payload,
        url: `${gon.moneyballApiV2}/api/v1/super_vendors/lookup`,
        actionType: constants.RECEIVE_VENDORS_DONE,
        transformResponsePayload: transformVendors,
        transformSearchToQuery: transformOptionsForBE,
      }),
    );
  };
}

export function reset() {
  return (dispatch) => dispatch(resetAppliedFilters());
}
