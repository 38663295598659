const PREFIX = 'MONEYBALL_CLUSTERBUCK_SECONDQUEUE_LOOKUP';

const constants = {
  FETCH_DATA_REQUEST: `${PREFIX}/FETCH_DATA_REQUEST`,
  FETCH_DATA_SUCCESS: `${PREFIX}/FETCH_DATA_SUCCESS`,
  DELETE_DATA_REQUEST: `${PREFIX}/DELETE_DATA_REQUEST`,
  DELETE_DATA_SUCCESS: `${PREFIX}/DELETE_DATA_SUCCESS`,
};

export default constants;
