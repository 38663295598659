import compact from 'lodash/compact';

import {
  getValue,
  getDisbursedRecipientInfo,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
  formatSelectValueForFE,
  formatAmounts,
  formatZipCode,
  formatDates,
} from 'redux/moneyball/utils/transformers';
import { getDateValue, getAmountValue } from 'redux/moneyball/utils';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import { formatGlobalDate } from 'utils/date';

export function transformVendor(vendorItem = {}) {
  const { vendors, super_vendor, ...restProps } = vendorItem;

  const formattedSuperVendor = super_vendor && {
    super_vendor: {
      ...super_vendor,
      zip_code: formatZipCode(super_vendor.zip_code),
    },
  };

  const formattedVendors = vendors && {
    vendors: vendors.map(
      ({ vendor, ...vendorProps }) => ({
        ...vendorProps,
        vendor: {
          ...vendor,
          zip_code: formatZipCode(vendor.zip_code),
        },
      }),
    ),
  };

  return {
    ...restProps,
    ...formattedVendors,
    ...formattedSuperVendor,
  };
}

export function transformDisbursements(disbursements = []) {
  return disbursements.map((disbursementItem) => {
    const { disbursement, spender, recipient } = disbursementItem;
    const { id, is_pdf_available, date, spender_name, ...restDisbursementProps } = disbursement || {};
    const { id: spenderId, name: spenderName } = spender || {};
    const { state_code, ...restRecipientProps } = recipient || {};
    const recipientInfo = getDisbursedRecipientInfo(restRecipientProps);

    return ({
      ...restDisbursementProps,
      ...recipientInfo,
      state_code,
      id: spenderId,
      name: spenderName || spender_name,
      url: spenderName && `/moneyball_v2/committees/${spenderId}`,
      date: formatGlobalDate(date),
      receipts: {
        id,
        is_pdf_available,
      },
    });
  });
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const { query, spender, disbursement_date, disbursement_amount } = search_params || data;
    const { ids: spenderIds } = spender || {};

    return {
      id,
      title: name || 'Applied filters:',
      filters: compact([
        getDisplayItem('Searched', query),
        getDisplayItem('Dates', getDateValue(disbursement_date)),
        getDisplayItem('Amounts', getAmountValue(disbursement_amount)),
        getDisplayItem('Spender(s)', spenderIds),
      ]),
      data: {
        ...getValue('searchValue', query),
        options: {
          ...getValue('dates', formatDates(disbursement_date)),
          ...getValue('amounts', formatAmounts(disbursement_amount)),
          ...getValue('spender', formatSelectValueForFE(spenderIds)),
        },
      },
    };
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const { amounts, dates, spender } = options || data;
  const { startDate, endDate } = dates || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('disbursement_date', {
      ...getValue('start', startDate),
      ...getValue('end', endDate),
    }),
    ...getValue('disbursement_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('spender', {
      ...getValue('ids', formatSelectValueToArrForBE(spender)),
    }),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformOptionsSearchForBE(data = {}) {
  const baseOptions = transformOptionsForBE(data);
  const { options } = data;
  const { spender } = options || data;

  return {
    ...baseOptions,
    ...getValue('spender', {
      ...getValue('ids', formatSelectValueToObjForBE(spender)),
    }),
  };
}
