import { slice, head, last, map } from 'lodash';

export function transformCustomField({
  field_type,
  editable,
  description,
  question,
  options,
  product_slugs,
  ...restProps
}) {
  return {
    ...restProps,
    fieldType: {
      value: field_type,
    },
    fieldDescription: description,
    isEditable: editable,
    question,
    options: options.map(value => ({
      value,
    })),
    productSlugs: map(product_slugs, slug => ({ label: slug, value: slug })),
  };
}

export function transformAllCustomFields(response = { data: [], isEnabled: true, paginator: {} }) {
  const { data, isEnabled, paginator } = response;

  return {
    data: data.map(({
      field_type,
      editable,
      created_at,
      product_slugs,
      ...restProps
    }) => ({
      ...restProps,
      fieldType: field_type,
      createdAt: created_at,
      isEditable: editable,
      actions: {
        enabled: restProps.enabled,
        token: restProps.token,
        editable,
      },
      productSlugs: {
        value: product_slugs,
        token: restProps.token,
      },
    })),
    isEnabled,
    paginator,
  };
}

const getDelimiters = ({ destination, source, isLiftingUp }) => {
  if (isLiftingUp) {
    return [destination.index, source.index];
  }

  return [source.index, destination.index];
};

const reorderSlice = (changedSlice, isLiftingUp) => {
  if (isLiftingUp) {
    return [last(changedSlice), ...slice(changedSlice, 0, -1)];
  }

  return [...slice(changedSlice, 1), head(changedSlice)];
};

export function reorderCustomFields({ customFields, destination, source }) {
  const isLiftingUp = destination.index < source.index;
  const [start, end] = getDelimiters({ destination, source, isLiftingUp });

  const headingSlice = slice(customFields, 0, start);
  const changedSlice = slice(customFields, start, end + 1);
  const trailingSlice = slice(customFields, end + 1);

  const updatedSlice = reorderSlice(changedSlice, isLiftingUp);

  return [...headingSlice, ...updatedSlice, ...trailingSlice];
}

export function transformPositionsForBE({ isEnabled, availableIn, updatedCustomFields }) {
  return {
    custom_fields: {
      enabled: isEnabled,
      product_slug: availableIn,
      tokens: map(updatedCustomFields, 'token'),
    },
  };
}
