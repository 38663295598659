import { isEmpty, groupBy, find, join, includes, forEach, map } from 'lodash';
import moment from 'moment';

import isNotFoundError from 'utils/http/isNotFoundError';
import Notifications from 'components/News/utils/Notifications';
import { DEFAULT_SORT } from 'components/core/Sort/utils/constants';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';

import { PER_PAGE } from '../constants';

export function formatPercentage(value = 0) {
  return `${(value * 100).toFixed(1)}%`;
}

export function trimWhitespace(text = '') {
  const whitespaceRegex = /[\s\r?\n\t]+/gm;

  return text ? text.replace(whitespaceRegex, ' ') : '';
}

function formatShortTime(date) {
  const validDate = date || undefined;
  const values = moment(validDate).fromNow(true).split(' ');
  const [time, unit] = values;
  const numericTime = time.replace(/(?:(a|an)+)/g, '1');

  return `${numericTime}${unit[0]}`;
}

function getFeedData(payload) {
  const clusterGroups = groupBy(payload, ({ clusterIds }) => (
    !isEmpty(clusterIds) ? clusterIds : 'withoutCluster'
  ));

  let feedData = [];
  let addedClusters = [];

  forEach(payload, (story) => {
    const { clusterIds, id } = story;
    const [clusterId] = clusterIds || [];
    const isEmptyClusterIds = isEmpty(clusterIds);
    const isClusterIdAdded = includes(addedClusters, clusterId);

    if (!isEmptyClusterIds && !isClusterIdAdded) {
      const clusterKeyName = find(
        Object.keys(clusterGroups), clusterKey => includes(clusterKey, clusterId)
      );
      const clusterData = clusterGroups[clusterKeyName] || {};

      addedClusters = [...addedClusters, clusterId];
      feedData = [...feedData, { id, clusterId, data: clusterData }];
    }

    if (isEmptyClusterIds) {
      feedData = [...feedData, story];
    }
  });

  return feedData;
}

function getNewsSummary({ sentences = [], summary = {} }) {
  // TODO: Remove summary usage after changes to news serializer in favor on sentences
  const { sentences: defaultSentences } = summary || {};
  const summarySentences = defaultSentences || sentences;
  const summaryText = join(summarySentences, ' ');

  return trimWhitespace(summaryText);
}

export function transformStory(data) {
  const {
    id,
    title,
    source: {
      logo_url,
      ...restSourceProps
    },
    sentiment = {},
    published_at,
    author,
    clusters,
    entities,
    image_url,
    url,
    external_token: externalToken,
  } = data;

  const { polarity, score } = sentiment || {};

  return {
    id: externalToken || id,
    title,
    author,
    mentions: entities,
    clusterIds: clusters,
    href: url,
    imageUrl: image_url,
    publishedAt: {
      raw: published_at,
      full: moment(published_at).fromNow(),
      short: formatShortTime(published_at),
    },
    summary: getNewsSummary(data),
    sentiment: {
      percentage: formatPercentage(score),
      type: polarity,
    },
    source: {
      ...restSourceProps,
      logoUrl: logo_url,
    },
    documentType: DOCUMENT_TYPES.NEWS_STORY,
  };
}

export function transformNewsFeedForUI(data = [], shouldGroupClusters = true) {
  const transformedData = data.map(transformStory).filter(({ title }) => !isEmpty(title));

  return shouldGroupClusters
    ? getFeedData(transformedData)
    : transformedData;
}

export function transformPaginator(pagination = {}, cursor = '', dataCount = 0) {
  const { current_page, next_page, total_count, total_pages } = pagination;
  const isLastPage = (cursor === next_page) || (dataCount < PER_PAGE);

  return {
    perPage: PER_PAGE,
    nextPage: next_page,
    isLastPage,
    current: current_page,
    total: total_pages,
    totalCount: total_count,
  };
}

export function transformNewsParams(payload = {}) {
  const {
    id,
    mention,
    cluster,
    cursor,
    sortData,
    perPage = PER_PAGE,
  } = payload;
  const { sort: sortValue } = sortData || {};

  return {
    cursor,
    per_page: perPage,
    sort_by: sortValue || DEFAULT_SORT.value,
    config_id: id,
    entities_title_text: mention,
    clusters: [cluster],
  };
}

export function transformNewsLookupParams(payload = {}) {
  const {
    page,
    perPage = PER_PAGE,
    keywordSearch,
  } = payload;

  return {
    page,
    per_page: perPage,
    search_form: {
      lookup_keyword: keywordSearch,
    },
    sort: 'published_at',
    direction: 'desc',
  };
}

function removeIgnoredKeyword(str) {
  const splittedStr = str.split(' ');

  return splittedStr
    .filter(word => word[0] !== '-')
    .join(' ');
}

export function extractKeywords(keywords) {
  const separatorRegexp = /[|+]+|w\/[0-9]*/;

  const formattedKeywords = keywords.replace(/["*()]/g, '');
  const keywordsArray = formattedKeywords.split(separatorRegexp);

  return keywordsArray
    .filter(str => !/~/.test(str))
    .map(str => removeIgnoredKeyword(str))
    .map(str => str.trim());
}

export function getFeedKeywords(feed) {
  const { search_params } = feed;
  const { include_keywords: includeKeywords } = search_params;

  if (includeKeywords) {
    return extractKeywords(includeKeywords);
  }

  return [];
}

export function handleFailureNotification(error) {
  isNotFoundError(error)
    ? Notifications.feedNotFound()
    : Notifications.defaultFetchDataFailed();
}

export function transformTag({ name, id }) {
  return {
    label: name,
    value: id,
  };
}

export function transformTags(response) {
  const { data } = response || {};

  return map(data, (item) => {
    const { record_id: recordId, tags } = item;

    return {
      recordId,
      tags: map(tags, transformTag),
    };
  });
}
