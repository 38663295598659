import { map, isEmpty, omitBy } from 'lodash';

/** Breaking news section */
export function transformNewsForUI(newsData = []) {
  if (isEmpty(newsData)) {
    return {
      allItems: [],
    };
  }

  return {
    allItems: map(newsData, (item = {}) => {
      const { published_date, title, source_name, link } = item;

      return {
        type: source_name,
        released: published_date,
        description: title,
        url: link,
      };
    }),
  };
}

export function transformNewsfeedForBE(payload) {
  const { id, keywordSearch } = payload;

  return omitBy({
    legislator_id: id,
    keywords: keywordSearch,
  }, isEmpty);
}
