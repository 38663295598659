import { responseEventTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { formatString } from 'components/Dashboard/common/FeedPage/utils';
import { transformStory } from 'redux/news/common/utils';
import { NEWS_STORY_ITEM_TYPE, NEWSFEED_ITEM_TYPE } from 'utils/constants/itemTypes';

import { NEWS_STORY_NODE } from './descriptionNodeTypes';

export function transformNewsStory({
  event_data,
  createdAt,
  isShared = false,
  feed,
  isNotification = false,
}) {
  const {
    search_id: searchId,
    search_title: searchTitle,
    user_full_name: userName,
    clusters,
    story_title: title,
    url,
  } = event_data;

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: 'News article published',
      description: title,
      itemType: NEWSFEED_ITEM_TYPE,
      itemTitle: searchTitle,
      url: `/news?selectedFeedId=${searchId}`,
      customBodyUrl: url,
      feedToken,
    };
  }

  return {
    descriptionNode: {
      type: NEWS_STORY_NODE,
      data: {
        ...transformStory(event_data),
        title,
      },
    },
    createdAt,
    userName,
    title,
    titleUrl: url,
    clusterIds: clusters,
    withTags: false,
    withCallToActions: !isShared,
    withCreationDate: false,
    itemType: NEWS_STORY_ITEM_TYPE,
    withEditNewsfeed: !isShared,
    withSharedBy: isShared,
    ...(!isShared && {
      source: [{ label: 'Tracked newsfeed', content: formatString(searchTitle) }],
    }),
  };
}

export const NEWS_STORY_RECENT_TYPE = {
  [responseEventTypes.NEWS_STORY]: transformNewsStory,
};
