import {
  isEmpty,
  isString,
  sortedUniqBy,
  upperFirst,
  toString,
  truncate,
  reject,
  map,
} from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getPhoneLink, getDirectMailLink } from 'utils/socialMedia/links';
import { TWITTER_LINK } from 'utils/constants/socialMedia';
import { STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE } from 'utils/constants/itemTypes';

function getAddress(address, city, state) {
  if (isEmpty(address)) {
    return '';
  }

  if (isEmpty(city) || isEmpty(state)) {
    return { value: address };
  }

  return { value: `${address}, ${city}, ${state}` };
}

function getOffices(offices) {
  return map(offices, ({ title, phones, fax, address_1, address_2, city, state_alpha2 }) => ({
    title,
    phones: phones && map(phones, phone => ({ value: phone, href: phone && getPhoneLink(phone) })),
    fax,
    faxLink: fax && getPhoneLink(fax),
    address: reject([
      getAddress(address_1, city, state_alpha2),
      getAddress(address_2),
    ], isEmpty),
  }));
}

function getFaxes(offices) {
  const faxes = map(offices, ({ fax }) => fax).filter(Boolean);

  return map(faxes, value => ({ href: getPhoneLink(value), value }));
}

function getWebsites(websites) {
  return map(websites, (site) => {
    const textToDisplay = truncate(site, { length: 34, separator: /,?\.* +/ });

    return {
      href: site,
      value: textToDisplay,
      isExternal: true,
    };
  });
}

function getTwitterMedias(twitter) {
  return twitter && twitter.map(media => ({
    href: `${TWITTER_LINK}/${media}`,
    value: media,
    isExternal: true,
  }));
}

function getFacebookMedias(facebook, name) {
  return facebook && facebook.map((href, index) => {
    const value = facebook.length > 1 ? `${name} ${index + 1}` : name;

    return { href, value, isExternal: true };
  });
}

export function transformProfileForUI(legislator) {
  const {
    id,
    emails,
    facebook,
    display_name,
    homepage,
    party_slug,
    party_short,
    phone_number,
    avatar_url,
    chamber,
    chamber_category,
    serving_since,
    serving_until,
    twitter,
    role,
    state,
    universal_moniker,
    district,
    committees,
    website,
    offices,
    is_active,
    full_biography,
    description,
    current_leadership_role_title,
  } = legislator;

  const currentState = !isEmpty(state) && !isEmpty(state.name) ? {
    ...(!isEmpty(state.alpha2) && { href: `/state/states/${state.alpha2}` }),
    value: state.name,
    alpha2: state.alpha2,
  } : {};

  const serving_label = is_active
    ? `Serving since ${serving_since}`
    : `Served from ${serving_since}-${serving_until}`;

  const getPhone = () => {
    if (!(phone_number && isString(phone_number))) {
      return null;
    }

    return { href: getPhoneLink(phone_number), value: phone_number };
  };

  return {
    profile: {
      id: toString(id),
      chamber: upperFirst(chamber),
      chamberCategory: chamber_category,
      role,
      party: {
        id: party_short,
        slug: party_slug,
        moniker: universal_moniker,
        avatarUrl: avatar_url,
      },
    },
    keyDetails: {
      homepage,
      emails: emails && emails.map(email => ({ href: getDirectMailLink(email), value: email })),
      phone: getPhone(),
      websites: getWebsites(website),
      district: district ? district.number : '',
      districtInfo: district,
      twitter: getTwitterMedias(twitter),
      facebook: getFacebookMedias(facebook, display_name),
      offices: getOffices(offices),
      faxes: getFaxes(offices),
      serving: serving_label,
      servingSince: serving_since,
      state: currentState,
      districtMap: district,
      isCurrent: is_active,
      biography: {
        description,
        fullBiography: full_biography,
      },
    },
    recordId: id,
    documentType: DOCUMENT_TYPES.STATE_LEGISLATOR,
    itemType: STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    currentLeadershipRole: current_leadership_role_title,
    committeeAssignments: sortedUniqBy(committees, committee => committee.name),
  };
}
