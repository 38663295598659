import constants from './constants';

const DEFAULT_STATE = {
  timeSeries: [],
  ui: {
    isLoadingTimeSeries: false,
  },
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_TIME_SERIES:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTimeSeries: true },
      };
    case constants.RECEIVE_TIME_SERIES_DONE:
      return {
        ...state,
        timeSeries: action.payload,
        ui: { ...state.ui, isLoadingTimeSeries: false },
      };
    case constants.RECEIVE_TIME_SERIES_FAILURE:
    case constants.RESET_TIME_SERIES:
      return {
        ...state,
        timeSeries: DEFAULT_STATE.timeSeries,
        ui: { ...state.ui, isLoadingTimeSeries: false },
      };
    default:
      return state;
  }
}
