import { isNil, omitBy } from 'lodash';

import { PAGE_SIZE } from 'components/core/Profile/utils/constants';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { fetchDefaultSubscription, fetchProfileSubscriptions } from 'redux/subscriptions/actions';
import { fetchProfileTags } from 'redux/tags/actions';
import { fetchProfileDocument, updateProfileShare } from 'redux/events/actions';

import constants from './constants';
import {
  transformProfileForUI,
  transformStaffersForBE,
  transformStaffersForUI,
  transformNewsForUI,
  transformNewsfeedForBE,
  transformDistrictInfoForUI,
  transformLargestEmployersForUI,
} from './utils';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}

export function onUpdateTrackings({ trackings }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: trackings,
  });
}

export function fetchSubscriptions(payload) {
  return (dispatch) => {
    dispatch(fetchDefaultSubscription(constants));
    dispatch(fetchProfileSubscriptions({ payload }, constants));
  };
}

export function fetchProfile(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_PROFILE });
    const { id, isKwProfile } = payload;

    const legislatorsPath = isKwProfile ? `kw/${id}` : id;
    const url = `/api_web/federal/legislators/${legislatorsPath}`;
    const ajax = $.get(url);

    const onSuccess = (response) => {
      const profile = transformProfileForUI(response);

      dispatch({
        type: constants.RECEIVE_PROFILE_DONE,
        payload: profile,
      });

      return { ...payload, id: profile.recordId };
    };

    const onFailure = (error) => {
      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(async (profileData) => {
        await dispatch(fetchTags(profileData));
        await dispatch(fetchProfileDocument(profileData, constants));
        await dispatch(fetchSubscriptions(profileData));

        return profileData;
      })
      .catch(onFailure);
  };
}

/** Staffers actions */
export function fetchStaffers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFERS });

    const { sort, direction, page } = payload;
    const sortData = omitBy({ sort, direction, page }, isNil);
    const staffersData = transformStaffersForBE(payload);

    const url = '/api_web/federal/staffers/lookup';
    const ajax = $.post(url, { ...staffersData, ...PER_PAGE });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_STAFFERS_DONE,
        payload: {
          ...transformStaffersForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(sortData),
        },
      }));
  };
}

/** Breaking news actions */
export function fetchBreakingNews(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_NEWS });

    const searchQuery = transformNewsfeedForBE(payload);

    const url = '/api_web/federal/newsfeed/lookup';
    const ajax = $.post(url, { ...searchQuery, ...PER_PAGE });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_NEWS_DONE,
        payload: {
          ...transformNewsForUI(response),
        },
      }));
  };
}

/** District info actions */
export function fetchDistrictInfo(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DISTRICT_INFO });
    const { id } = payload;

    const url = `/api_web/federal/legislators/${id}/district_info`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_DISTRICT_INFO,
        payload: transformDistrictInfoForUI(response),
      }));
  };
}

/** Largest employers actions */
export function getLargestEmployersUrl(id) {
  return `/api_web/federal/legislators/${id}/employers`;
}

export function fetchLargestEmployers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LARGEST_EMPLOYEE });
    const { id } = payload;

    const ajax = $.get(getLargestEmployersUrl(id));

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_LARGEST_EMPLOYEE_DONE,
        payload: transformLargestEmployersForUI(response),
      }));
  };
}

export function resetDistrictInfo() {
  return dispatch => dispatch({ type: constants.RESET_DISTRICT_INFO });
}

export function resetProfileData() {
  return dispatch => dispatch({ type: constants.RESET_PROFILE });
}

export function loadLegislatorWithDistrict(payload) {
  return async (dispatch) => {
    dispatch({ type: constants.FETCH_LEGISLATOR_WITH_DISTRICT, payload });

    const profileData = await dispatch(fetchProfile(payload));

    return dispatch(fetchDistrictInfo(profileData));
  };
}

export function updateProfileMyFeed() {
  return (dispatch) => {
    dispatch(updateProfileShare(constants));
  };
}
