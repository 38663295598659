import { isEmpty, isNil, omitBy } from 'lodash';

import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import { transformLegislatorsForUI, transformOptionsForBE } from '../utils';
import constants from '../constants';
import { fetchTags } from './tags';
import { fetchSubscriptions } from './subscriptions';

export function getLegislatorsData(payload) {
  const { searchData = {}, appliedFilters, clearAll, sort, direction, page } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const url = '/api_web/federal/legislators/lookup';
  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData };

  return { isSearch, url, data, sortData };
}

export function fetchLegislators(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LEGISLATORS });

    const { searchData = {}, clearAll, documentType, trackingType } = payload;
    const { isSearch, url, data, sortData } = getLegislatorsData(payload);
    const ajax = $.post(url, data);

    function onSuccess(response) {
      const currentPayload = (isEmpty(response) || isEmpty(response.data)) ? [] : response.data;

      return dispatch({
        type: constants.RECEIVE_LEGISLATORS_DONE,
        payload: transformLegislatorsForUI(currentPayload),
        paginator: transformPaginator(response),
        toResetSelectedItems: isSearch,
        sort: transformSort(sortData),
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    function onFailure(error) {
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then((payloadData) => {
        dispatch(fetchSubscriptions(payloadData, trackingType));

        return payloadData;
      })
      .then((payloadData) => {
        dispatch(fetchTags(payloadData, documentType));
        dispatch(fetchMyFeedDocuments(payloadData, constants));
        saveAppliedFiltersToProps();
      })
      .catch(onFailure);
  };
}

export function resetLegislators() {
  return dispatch => (
    dispatch({
      type: constants.RESET_LEGISLATORS,
    })
  );
}

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
