import { isEmpty, map, filter, get, head, compact, forEach, groupBy, flatten } from 'lodash';

import { getMyFeedToken } from 'components/Dashboard/utils';
import { notifySharedToMyFeedMultiple } from 'components/Dashboard/common/UserNotifications';
import { fetchTrackingsBulk, bulkTrackItems } from 'redux/dashboardV2/common/trackings/api';
import { bulkShareToMyFeed } from 'redux/dashboardV2/common/subscriptions/api';
import * as subscriptionsApi from 'redux/dashboardV2/common/subscriptions/api';
import notifications from 'components/Dashboard/utils/notifications';
import { getSubscriptionsData } from 'redux/dashboardV2/feedPage/actions';
import { getWorkspacesToTrack } from 'components/core/TrackButton/utils';
import { transformFetchRecordIdsForBE } from 'redux/tags/utils';

import {
  transformSubscription,
  transformSubscriptions,
  transformCommitteeSubscriptions,
  transformCommitteeProfileSubscriptions,
  transformSharedDocuments,
  transformTracking,
} from './utils/transformers';
import { getSubscriptionRequests, getSubscriptionModelId } from './utils';

export function fetchLookupSubscriptions(
  { payload: payloadData } = {},
  defaultDocumentType,
  actionTypes = {},
) {
  return (dispatch) => {
    const trackableItems = filter(payloadData, 'withTrack');

    if (isEmpty(trackableItems)) {
      return Promise.resolve();
    }

    dispatch({ type: actionTypes.FETCH_SUBSCRIPTIONS });

    const payload = map(trackableItems, ({ id, documentType, trackingType }) => ({
      id,
      documentType: trackingType || documentType || defaultDocumentType,
    }));

    const data = transformFetchRecordIdsForBE(payload);

    function onSuccess({ data: responseData } = {}) {
      const [lookupSubscriptions] = responseData || [];
      const { record_type, subscriptions } = lookupSubscriptions || {};

      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: transformSubscriptions(subscriptions, record_type),
      });
    }

    function onFailure(error) {
      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: [],
      });

      throw error;
    }

    return Promise.resolve(fetchTrackingsBulk(data))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchLookupCommitteeSubscriptions({
  data,
  defaultDocumentType,
  actionTypes = {},
}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_SUBSCRIPTIONS });

    function onSuccess({ data: responseData } = {}) {
      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: transformCommitteeSubscriptions(responseData, defaultDocumentType),
      });
    }

    function onFailure(error) {
      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: [],
      });

      throw error;
    }

    return Promise.resolve(fetchTrackingsBulk(data))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchDefaultSubscription(actionTypes = {}) {
  return (dispatch) => {
    function onSuccess(frequency) {
      dispatch({
        type: actionTypes.RECEIVE_DEFAULT_SUBSCRIPTION_DONE,
        payload: frequency,
      });
    }

    function onFailure(error) {
      throw error;
    }

    return Promise.resolve(subscriptionsApi.fetchDefaultFrequency())
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchProfileSubscriptions({ payload: payloadData } = {}, actionTypes = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_SUBSCRIPTIONS });
    const { id, trackingType } = payloadData;
    const data = [{ record_type: trackingType, record_ids: [id] }];

    function onSuccess({ data: responseData }) {
      const subscriptions = get(head(responseData), 'subscriptions');
      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: map(subscriptions, transformTracking),
      });
    }

    function onFailure(error) {
      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: [],
      });

      throw error;
    }

    return Promise.resolve(fetchTrackingsBulk(data))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchProfileCommitteeSubscriptions({
  data,
  defaultDocumentType,
  actionTypes = {},
}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_SUBSCRIPTIONS });

    function onSuccess({ data: responseData } = {}) {
      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: transformCommitteeProfileSubscriptions(responseData, defaultDocumentType),
      });
    }

    function onFailure(error) {
      dispatch({
        type: actionTypes.RECEIVE_SUBSCRIPTIONS_DONE,
        payload: [],
      });

      throw error;
    }

    return Promise.resolve(fetchTrackingsBulk(data))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updateCommitteeSubscriptions({
  isProfile,
  payload,
  action,
  actionTypes = {},
}) {
  return (dispatch) => {
    const { workspaces } = payload;
    const workspacesToTrack = getWorkspacesToTrack(workspaces, payload);

    const onSuccess = (responses) => {
      let recordId = '';
      const trackings = map(responses, (responseData, index) => {
        const subscriptionsData = compact(getSubscriptionsData(responseData));
        const subscriptions = map(subscriptionsData, transformSubscription);
        recordId = recordId || getSubscriptionModelId(head(subscriptions));

        return {
          feed: workspacesToTrack[index],
          subscriptions,
        };
      });

      dispatch({
        type: isProfile ? actionTypes.RECEIVE_SUBSCRIPTIONS_DONE : actionTypes.UPDATE_DATA_ITEM,
        payload: isProfile ? trackings : { recordId, trackings },
      });
    };

    const onFailure = (error) => {
      notifications.generalFailedNotification();

      throw error;
    };

    return getSubscriptionRequests(payload, workspacesToTrack, action)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function bulkTrackLookupItems(payload, actionTypes = {}, customFetchSubscriptions) {
  return (dispatch) => {
    dispatch({ type: actionTypes.BULK_TRACK_DATA });

    const { data, selectedItems, documentType, onSuccess, onFailure } = payload || {};
    const fetchSubscriptions = customFetchSubscriptions || fetchLookupSubscriptions;
    const selectedFeeds = getWorkspacesToTrack(data.workspaces, data);
    const workspaceTokens = map(selectedFeeds, 'token');

    function handleSuccess(responses) {
      dispatch({ type: actionTypes.BULK_TRACK_DATA_DONE });
      dispatch(fetchSubscriptions({ payload: selectedItems }, documentType, actionTypes));

      if (onSuccess) {
        const subscriptions = flatten(map(responses, 'data'));
        const subscriptionsByGroup = groupBy(subscriptions, 'feed_token');

        forEach(selectedFeeds, (selectedFeed) => {
          const subscriptionGroup = subscriptionsByGroup[selectedFeed.token];

          if (!isEmpty(subscriptionGroup)) {
            onSuccess(
              map(subscriptionGroup, transformSubscription),
              selectedFeed.token,
              selectedFeed.name
            );
          }
        });
      }
    }

    function handleFailure(error) {
      onFailure && onFailure(error);

      window.Sentry && window.Sentry.captureMessage(error);

      return {};
    }

    const requests = (() => {
      if (isEmpty(workspaceTokens)) {
        return [];
      }

      const [firstToken, ...restTokens] = workspaceTokens;

      const callApi = (requestData, token) => bulkTrackItems(requestData, token)
        .catch(handleFailure);

      // separate requests because the transformer handles data differently for private and shared feeds
      if (firstToken === getMyFeedToken()) {
        const [workspaceToken, ...feedTokens] = restTokens;

        return [
          callApi(data, firstToken),
          ...(workspaceToken ? [callApi({ ...data, feedTokens }, workspaceToken)] : []),
        ];
      }

      return [callApi({ ...data, feedTokens: restTokens }, firstToken)];
    })();

    return Promise.all(requests)
      .then(handleSuccess)
      .finally(() => dispatch({ type: actionTypes.BULK_TRACK_DATA_DONE }));
  };
}

export function bulkShareLookupToMyFeed(payload, actionTypes = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.BULK_SHARE_TO_MY_FEED });
    const { selectedItemsSize, selectedItemsIds, documentType } = payload || {};
    const data = { record_ids: selectedItemsIds, record_type: documentType };

    function onSuccess(response) {
      notifySharedToMyFeedMultiple(selectedItemsSize);

      dispatch({
        type: actionTypes.BULK_SHARE_TO_MY_FEED_DONE,
        payload: transformSharedDocuments(response, documentType),
      });
    }

    function onFailure(error) {
      dispatch({ type: actionTypes.BULK_SHARE_TO_MY_FEED_FAILURE });
      notifications.generalFailedNotification();

      throw error;
    }

    return bulkShareToMyFeed(data)
      .then(onSuccess)
      .catch(onFailure);
  };
}
