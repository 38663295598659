import { omitBy, isEmpty } from 'lodash';

import { emptyState } from 'components/News/common/NewFeedModal/utils';

const transformRichSelectValue = ({ value, type, label }) => (
  { name: label, label, id: value, type }
);

const transformSelectValue = ({ value, label }) => ({ id: value, name: label });

const transformRichSelectValueToUI = ({ name, label, type, id }) => (
  { label: label || name, value: id, type }
);

const transformSelectValueToUI = ({ id, name }) => ({ value: id, label: name });

function transformExcludeToBE(excludeFields = {}) {
  const {
    keyword,
    keywordType,
    newsOutlets,
    policyArea,
  } = excludeFields;

  return {
    exclude_keywords: keyword,
    exclude_keywords_type: keyword ? keywordType : '',
    exclude_sources: newsOutlets.map(transformSelectValue),
    exclude_categories: policyArea.map(transformSelectValue),
  };
}

function transformIncludeToBE(includeFields = {}) {
  const {
    keyword,
    keywordType,
    newsOutlets,
    policyArea,
    people,
    bills,
    authors,
    committees,
    countriesOrigin,
    statesOrigin,
  } = includeFields;

  return {
    include_keywords: keyword,
    include_keywords_type: keyword ? keywordType : '',
    bills: bills.map(transformRichSelectValue),
    committees: committees.map(transformRichSelectValue),
    persons: people.map(transformRichSelectValue),
    authors: authors.map(transformSelectValue),
    include_sources: newsOutlets.map(transformSelectValue),
    include_categories: policyArea.map(transformSelectValue),
    source_location_countries: countriesOrigin.map(transformSelectValue),
    source_location_states: statesOrigin.map(transformSelectValue),
  };
}

export function transformFeedToBE(feedData = {}) {
  const { include, exclude, title } = feedData;

  return {
    news_search: {
      name: title,
      search_params: omitBy({
        ...transformExcludeToBE(exclude),
        ...transformIncludeToBE(include),
      }, isEmpty),
    },
  };
}

function transformIncludeToUI(searchParams = {}) {
  const {
    include_keywords,
    include_keywords_type,
    bills = [],
    authors = [],
    committees = [],
    persons = [],
    include_sources = [],
    include_categories = [],
    source_location_countries = [],
    source_location_states = [],
  } = searchParams;

  return {
    keyword: include_keywords,
    keywordType: include_keywords_type,
    bills: bills.map(transformRichSelectValueToUI),
    committees: committees.map(transformRichSelectValueToUI),
    people: persons.map(transformRichSelectValueToUI),
    authors: authors.map(transformSelectValueToUI),
    newsOutlets: include_sources.map(transformSelectValueToUI),
    policyArea: include_categories.map(transformSelectValueToUI),
    countriesOrigin: source_location_countries.map(transformSelectValueToUI),
    statesOrigin: source_location_states.map(transformSelectValueToUI),
  };
}

function transformExcludeToUI(searchParams = {}) {
  const {
    exclude_keywords,
    exclude_keywords_type,
    exclude_sources = [],
    exclude_categories = [],
  } = searchParams;

  return {
    keyword: exclude_keywords,
    keywordType: exclude_keywords_type,
    newsOutlets: exclude_sources.map(transformSelectValueToUI),
    policyArea: exclude_categories.map(transformSelectValueToUI),
  };
}

export function transformFeedForUI(data = {}) {
  const { name, search_params } = data;

  const includeParams = transformIncludeToUI(search_params);
  const { countriesOrigin } = includeParams;

  return {
    title: name,
    include: {
      ...emptyState.include,
      ...omitBy(includeParams, isEmpty),
      countriesOrigin,
    },
    exclude: {
      ...emptyState.exclude,
      ...omitBy(transformExcludeToUI(search_params), isEmpty),
    },
  };
}
