import { ADD_CHART_TO_PRINT } from '../constants';

export function addChartToPrint(chartId, chart) {
  return {
    type: ADD_CHART_TO_PRINT,
    payload: {
      chartId,
      chart,
    },
  };
}
