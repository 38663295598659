import constants from './constants';

const defaultState = {
  content: '',
  serviceTermId: '',
  termType: '',
  ui: {
    loading: false,
    acceptTermsLoading: false,
  },
};

export default function (state = defaultState, { type, payload }) {
  switch (type) {
    case constants.LOAD_SERVICE_TERMS_STARTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case constants.LOAD_SERVICE_TERMS_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case constants.LOAD_SERVICE_TERMS_DONE:
      return {
        ...state,
        content: payload.data.content,
        serviceTermId: payload.data.service_term_id,
        termType: payload.data.term_type,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case constants.ACCEPT_SERVICE_TERMS_STARTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          acceptTermsLoading: true,
        },
      };
    case constants.ACCEPT_SERVICE_TERMS_FAIL:
    case constants.ACCEPT_SERVICE_TERMS_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          acceptTermsLoading: false,
        },
      };
    default:
      return state;
  }
}
