import { isNil, isBoolean, isNumber, isEmpty, forEach, compact, includes } from 'lodash';

import {
  FILTER_READ_HIDE_READ,
  FILTER_READ_HIDE_UNREAD,
  SUBSCRIPTION_ACTION,
  PRIORITY_ACTION,
  DOCUMENT_TYPES,
  SAVE_ACTION,
  READ_ACTION,
} from 'components/Dashboard/common/FeedPage/utils/constants';
import {
  TRACKED_STATE_REGULATION,
  STATE_REGULATIONS_SLUG,
  TRACKED_FEDERAL_COMMITTEE_STAFFER,
  TRACKED_FEDERAL_COMMITTEE_HEARING,
  TRACKED_STATE_COMMITTEE_STAFFER,
  TRACKED_STATE_COMMITTEE_HEARING,
} from 'components/Dashboard/utils/libProducts';
import {
  EXCLUDE_SHARED_DOCS,
  EXCLUDE_TRACKED_ITEMS,
  SHOW_ONLY_SHARED_DOCS,
  TRACKED_ITEMS_ONLY,
} from 'redux/dashboardV2/constants';

export function isFirstPage({ page }) {
  return isNil(page) || page === 1;
}

export function getValue(key, value) {
  if (isBoolean(value)) {
    return { [key]: value };
  }

  if (isNumber(value)) {
    return { [key]: value };
  }

  if (isEmpty(value)) {
    return null;
  }

  return { [key]: value };
}

export function formatTrackedItems(value) {
  const { [value]: formattedValue } = {
    [TRACKED_ITEMS_ONLY]: { [TRACKED_ITEMS_ONLY]: true },
    [EXCLUDE_TRACKED_ITEMS]: { [EXCLUDE_TRACKED_ITEMS]: true },
  };

  return formattedValue;
}

export function formatSharedItems(value) {
  const { [value]: formattedValue } = {
    [SHOW_ONLY_SHARED_DOCS]: { [SHOW_ONLY_SHARED_DOCS]: true },
    [EXCLUDE_SHARED_DOCS]: { [EXCLUDE_SHARED_DOCS]: true },
  };

  return formattedValue;
}

export function formatReadItems(value) {
  const { [value]: formattedValue } = {
    [FILTER_READ_HIDE_UNREAD]: true,
    [FILTER_READ_HIDE_READ]: false,
  };

  return formattedValue;
}

export function formatBooleanItem(data) {
  return isEmpty(data) ? undefined : true;
}

export function getItemTypes(itemTypes) {
  /** Include state regulation documents when state regulation is checked */
  if (!isEmpty(itemTypes) && itemTypes.includes(TRACKED_STATE_REGULATION)) {
    return [...itemTypes, STATE_REGULATIONS_SLUG];
  }

  return itemTypes;
}

export function getTrackedItemTypes(itemTypes = []) {
  return compact([
    ...itemTypes,
    /** Include federal committee hearings documents when federal committees is checked */
    includes(itemTypes, TRACKED_FEDERAL_COMMITTEE_STAFFER) && TRACKED_FEDERAL_COMMITTEE_HEARING,
    /** Include state committee hearings documents when state committees is checked */
    includes(itemTypes, TRACKED_STATE_COMMITTEE_STAFFER) && TRACKED_STATE_COMMITTEE_HEARING,
  ]);
}

export function getRecordTypesIds({ data }) {
  const recordTypeIds = {
    state_bill: [],
    federal_bill: [],
    federal_regulation: [],
    state_regulation: [],
  };

  const pushItem = (item) => {
    const { positionRecordType, recordId } = item;
    positionRecordType && recordTypeIds[positionRecordType].push(recordId);
  };

  forEach(data, pushItem);

  return recordTypeIds;
}

export function getIsLoadingActions(id, actionType, isLoading = false) {
  const isPriorityAction = actionType === PRIORITY_ACTION;
  const isSaveAction = actionType === SAVE_ACTION;
  const isMarkAsReadAction = actionType === READ_ACTION;
  const isSubscriptionAction = actionType === SUBSCRIPTION_ACTION;

  return {
    id,
    ...isPriorityAction && { isLoadingPriority: isLoading },
    ...isSaveAction && { isLoadingSave: isLoading },
    ...isMarkAsReadAction && { isLoadingRead: isLoading },
    ...isSubscriptionAction && { isLoadingSubscription: isLoading },
  };
}

export function getSource({ documentType, title, titleUrl: href, partyShort, comeFrom }) {
  const { [documentType]: source = [] } = {
    [DOCUMENT_TYPES.STATE_BILL]: [{
      label: 'Tracked bill',
      content: {
        title,
        href,
      },
    }],
    [DOCUMENT_TYPES.FEDERAL_BILL]: [{
      label: 'Tracked bill',
      content: {
        title,
        href,
      },
    }],
    [DOCUMENT_TYPES.STATE_REGULATION]: [{
      label: 'Tracked regulation',
      content: {
        title,
        href,
      },
    }],
    [DOCUMENT_TYPES.STATE_COMMITTEE]: [{
      label: 'Tracked committee',
      content: {
        title,
        href,
      },
    }],
    [DOCUMENT_TYPES.STATE_LEGISLATOR]: [{
      label: 'Tracked legislator',
      content: {
        title,
        href,
        partyShort,
        comeFrom,
      },
    }],
    [DOCUMENT_TYPES.FEDERAL_REGISTER_REGULATION]: [{
      label: 'Tracked regulation',
      content: {
        title,
        href,
      },
    }],
    [DOCUMENT_TYPES.FEDERAL_COMMITTEE]: [{
      label: 'Tracked committee',
      content: {
        title,
        href,
      },
    }],
    [DOCUMENT_TYPES.STATE_LEGISLATOR]: [{
      label: 'Tracked legislator',
      content: {
        title,
        href,
        partyShort,
        comeFrom,
      },
    }],
    [DOCUMENT_TYPES.FEDERAL_LEGISLATOR]: [{
      label: 'Tracked legislator',
      content: {
        title,
        href,
        partyShort,
        comeFrom,
      },
    }],
  };

  return source;
}
