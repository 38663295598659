import { DEFAULT_PAGE_SIZE, DEFAULT_INITIAL_PAGE } from 'components/core/Paginator/constants';

import constants from './constants';

export const defaultPagination = {
  currentPage: DEFAULT_INITIAL_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  totalCount: 0,
  totalPages: 0,
};

export const defaultState = {
  assetSetName: '',
  individualAssetSets: [],
  pagination: defaultPagination,
  sortData: {},
  ui: {
    isLoading: true,
    isLoadingMultipleIndividualAssetSets: false,
  },
};

function individualAssetSet(state = defaultState, action) {
  switch (action.type) {
    case constants.SEARCH_INDIVIDUAL_ASSET_SET:
    case constants.FETCH_INDIVIDUAL_ASSET_SET:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      };
    case constants.FETCH_MULTIPLE_INDIVIDUAL_ASSET_SETS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingMultipleIndividualAssetSets: true,
        },
      };
    case constants.RECEIVE_INDIVIDUAL_ASSET_SET_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case constants.RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_FAIL:
    case constants.RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingMultipleIndividualAssetSets: false,
        },
      };
    case constants.RECEIVE_INDIVIDUAL_ASSET_SET_DONE:
      return {
        ...state,
        assetSetName: action.dataset,
        individualAssetSets: action.payload,
        pagination: action.pagination,
        sortData: action.sortData,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case constants.RESET_INDIVIDUAL_ASSET_SET:
      return defaultState;

    default:
      return state;
  }
}

export default individualAssetSet;
