import { combineReducers } from 'redux';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';
import exportReport from 'redux/exportReport/reducers';
import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import { updateDataItemArrayPayload, updateDataItem } from 'utils/reducer';

import { loadingUpdateItem, getFederalSubscription } from './utils/reducersUtils';
import constants from './constants';

export const defaultState = {
  legislators: [],
  defaultSubscription: NONE_ID,
  paginator: {},
  sort: {},
  emails: '',
  ui: {
    isLoadingData: true,
    isLoadingTags: true,
    isLoadingSubscriptions: true,
    isSavingSubscriptions: true,
    isLoadingAllEmails: false,
    toResetSelectedItems: true,
    isInitialLoading: true,
    isLoadingMyFeedDocuments: true,
    isSharingItems: false,
  },
};

function legislatorLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_LEGISLATORS:
      return {
        ...state,
        sort: defaultState.sort,
        emails: defaultState.email,
        ui: { ...state.ui, isLoadingData: true, toResetSelectedItems: false },
      };
    case constants.RECEIVE_LEGISLATORS_DONE:
      return {
        ...state,
        legislators: action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isLoadingData: false,
          toResetSelectedItems: action.toResetSelectedItems,
          isInitialLoading: false,
        },
      };
    case constants.FETCH_LEGISLATORS_EMAILS:
      return {
        ...state,
        emails: defaultState.emails,
        ui: { ...state.ui, isLoadingAllEmails: true },
      };
    case constants.RECEIVE_LEGISLATORS_EMAILS_DONE:
      return {
        ...state,
        emails: action.payload,
        ui: { ...state.ui, isLoadingAllEmails: false },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, constants.LEGISLATOR_LOOKUP_NAME),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.BULK_UPDATE_DATA:
      return loadingUpdateItem(state, action);
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, constants.LEGISLATOR_LOOKUP_NAME),
      };
    case constants.BULK_SHARE_TO_MY_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: true },
      };
    case constants.BULK_SHARE_TO_MY_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, constants.LEGISLATOR_LOOKUP_NAME),
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.BULK_TRACK_DATA:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: true },
      };
    case constants.BULK_TRACK_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: false },
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return getFederalSubscription(state, action);
    case constants.RESET_LEGISLATORS:
      return defaultState;
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, constants.LEGISLATOR_LOOKUP_NAME),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    default:
      return state;
  }
}

export default combineReducers({
  legislatorLookup,
  advancedSearch,
  appliedFilters: appliedSearchFilters,
  exportReport,
});
