import { isEmpty } from 'lodash';

import {
  getValue,
  transformReceiptContributions,
  transformReceiptDisbursements,
} from 'redux/moneyball/utils/transformers';
import { DISBURSEMENTS, REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { currentDate } from 'utils/date';
import {
  slugify,
  fetchSearch,
  fetchSearchReceipt,
  fetchSearchKeyDetails,
  onSetElectionCycles,
  onSelectElectionCycle,
  fetchReport,
} from 'redux/moneyball/utils';
import {
  loadSavedSearches,
  loadRecentSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformCommittee,
  transformContributions,
  transformDisbursements,
  transformSearchesForFE,
  transformContributionOptionsForBE,
  transformDisbursementOptionsForBE,
  transformContributionOptionsSearchForBE,
  transformDisbursementOptionsSearchForBE,
} from './utils';
import constants from './constants';

export function initAppliedSearchFilters(emptyState, { searchType }) {
  return handleAppliedFilters({ ...emptyState, ...(searchType && { searchType }) });
}

export function setElectionCycles(cycles) {
  return onSetElectionCycles(cycles, constants.SET_ELECTION_CYCLES);
}

export function onSelectCycle(cycle) {
  return onSelectElectionCycle(cycle, constants.SELECT_ELECTION_CYCLE);
}

export function loadCommitteeSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadCommitteeRecentSearches() {
  return dispatch => dispatch(loadRecentSearches(
    {
      strategy_slug: constants.STRATEGY_SLUG,
    },
    transformSearchesForFE,
  ));
}

export function saveCommitteeAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformContributionOptionsSearchForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadCommitteeRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeCommitteeAdvancedSearch({ id }) {
  return onRemoveItem(id, loadCommitteeSavedSearches);
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map(item => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/receipts/export`;
  const data = JSON.stringify({
    receipt_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'contributor_name',
      'contributor_url',
      'contributor_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'amount',
      'receipt_date',
      'election_type',
      'conduit',
      'recipient_name',
      'recipient_url',
      'source_type',
      'filing_url',
      'contributor_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function submitExportLookup({
  id,
  title,
  exportOption,
  appliedFilters,
  exportNotifications,
}) {
  const exportLookupFilters = transformContributionOptionsForBE(appliedFilters);
  const url = `${gon.moneyballApiV2}/api/v1/receipts/export`;
  const data = JSON.stringify({
    lookup: {
      ...exportLookupFilters,
      super_committee_recipients: {
        ids: [id],
      },
    },
    format_type: exportOption,
    dimensions: [
      'contributor_name',
      'contributor_url',
      'contributor_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'amount',
      'receipt_date',
      'election_type',
      'conduit',
      'recipient_name',
      'recipient_url',
      'source_type',
      'filing_url',
      'contributor_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({
      url,
      data,
      contentType: REQUEST_CONTENT_TYPE,
      customFileName: `${slugify([title, currentDate(), 'receipts'].join('-'))}.${exportOption}`,
    })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function submitDisbursementsExport({
  items,
  exportOption,
  exportNotifications,
  toggleExportModal,
}) {
  const exportIds = items && items.map(item => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/disbursements/export`;
  const data = JSON.stringify({
    disbursement_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'spender_name',
      'spender_url',
      'amount',
      'disbursement_date',
      'disbursement_description',
      'conduit_committee_name',
      'recipient_name',
      'recipient_url',
      'recipient_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'source_type',
      'filing_url',
      'spender_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function submitDisbursementsExportLookup({
  id,
  title,
  exportOption,
  appliedFilters,
  exportNotifications,
}) {
  const exportLookupFilters = transformDisbursementOptionsForBE(appliedFilters);
  const url = `${gon.moneyballApiV2}/api/v1/disbursements/export`;
  const data = JSON.stringify({
    lookup: {
      ...exportLookupFilters,
      spender: {
        ids: [id],
      },
    },
    format_type: exportOption,
    dimensions: [
      'spender_name',
      'spender_url',
      'amount',
      'disbursement_date',
      'disbursement_description',
      'conduit_committee_name',
      'recipient_name',
      'recipient_url',
      'recipient_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'source_type',
      'filing_url',
      'spender_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({
      url,
      data,
      contentType: REQUEST_CONTENT_TYPE,
      customFileName: `${slugify([title, currentDate(), 'disbursements'].join('-'))}.${exportOption}`,
    })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function fetchCommitteeData(id, params) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEE });

    return dispatch(
      fetchSearchKeyDetails({
        payload: params,
        url: `${gon.moneyballApiV2}/api/v1/super_committees/${id}`,
        actionType: constants.RECEIVE_COMMITTEE_DONE,
        transformResponse: transformCommittee,
      }),
    );
  };
}

export function searchCommitteesReceipt({ id, appliedFilters }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEES_RECEIPT });

    const { searchType } = appliedFilters || {};
    let url = `${gon.moneyballApiV2}/api/v1/receipts/${id}`;
    let transformResponse = transformReceiptContributions;

    if (searchType === DISBURSEMENTS) {
      url = `${gon.moneyballApiV2}/api/v1/disbursements/${id}`;
      transformResponse = transformReceiptDisbursements;
    }

    return dispatch(
      fetchSearchReceipt({
        url,
        transformResponse,
        actionType: constants.RECEIVE_COMMITTEES_RECEIPT_DONE,
      }),
    );
  };
}

export function searchCommitteesData(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_COMMITTEES_DATA });

    const {
      id,
      searchData,
      appliedFilters,
      ...restPayload
    } = payload;

    const { searchType } = searchData || appliedFilters || payload;
    let url = `${gon.moneyballApiV2}/api/v1/receipts/lookup`;
    let transformResponsePayloadBySearchType = transformContributions;
    let transformSearchToQueryBySearchType = transformContributionOptionsForBE;
    let transformAdvancedSearchToQueryBySearchType = transformContributionOptionsSearchForBE;
    let formattedPayload = {
      ...restPayload,
      searchData,
      appliedFilters,
      super_committee_recipients: {
        ids: [id],
      },
    };

    if (searchType === DISBURSEMENTS) {
      url = `${gon.moneyballApiV2}/api/v1/disbursements/lookup`;
      transformResponsePayloadBySearchType = transformDisbursements;
      transformSearchToQueryBySearchType = transformDisbursementOptionsForBE;
      transformAdvancedSearchToQueryBySearchType = transformDisbursementOptionsSearchForBE;
      formattedPayload = {
        ...restPayload,
        searchData,
        appliedFilters,
        spender: {
          ids: [id],
        },
      };
    }

    return dispatch(
      fetchSearch({
        url,
        payload: formattedPayload,
        actionType: constants.RECEIVE_COMMITTEES_DATA_DONE,
        transformResponsePayload: transformResponsePayloadBySearchType,
        transformSearchToQuery: transformSearchToQueryBySearchType,
        transformAdvancedSearchToQuery: transformAdvancedSearchToQueryBySearchType,
        saveRecentAdvancedSearch: saveCommitteeAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return dispatch => dispatch(resetAppliedFilters());
}
