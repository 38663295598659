import { getValue, formatSelectValueToArrForBE } from 'redux/moneyball/utils/transformers';

import { formatSmartSearchObjectForBE } from '../builder/transformers';

export function transformDataForFront(payload = {}) {
  const { title, description, display_data, is_template, copy } = payload;
  const { transactor } = display_data || {};
  const newTitle = copy ? `[Copy] ${title}` : title;

  return {
    title: newTitle,
    description,
    transactor,
    data: display_data,
    isTemplate: is_template,
  };
}

export function transformDataForBack({ payload }) {
  const { govpredictAdmin } = gon.currentUser;
  const { title, description, data, isTemplate } = payload || {};
  const isTemplateForBack = !!(isTemplate && govpredictAdmin);

  const dataForBack = JSON.parse(
    JSON.stringify(data, (name, val) => {
      if (val === null) {
        return undefined;
      }

      if (name === 'id') {
        return undefined;
      }

      let responseValue = val;

      if (name === 'name' && val === 'has_given_total') {
        responseValue = 'has_given';
      }

      if (name === 'options') {
        const { toType, toValue, addons } = val || {};
        const { total_gt, total_lt } = addons || {};
        let { value: toTypeValue } = toType || {};

        // rename has_given_total to has_given
        if (toTypeValue === 'has_given_total') toTypeValue = 'has_given';

        responseValue = toTypeValue !== 'any' ? {
          total_gt,
          total_lt,
          [toTypeValue]: formatSelectValueToArrForBE(toValue),
        } : {
          total_gt,
          total_lt,
        };
      }

      if (name === 'operator') {
        responseValue = val.value;
      }

      if (name === 'value') {
        responseValue = Array.isArray(val)
          ? formatSelectValueToArrForBE(val)
          : formatSmartSearchObjectForBE(val);
      }

      return responseValue;
    }),
  );

  return JSON.stringify({
    ...getValue('smart_search', {
      ...getValue('title', title),
      ...getValue('description', description),
      ...getValue('request_data', dataForBack),
      ...getValue('display_data', data),
      ...getValue('is_template', isTemplateForBack, true),
    }),
  });
}
