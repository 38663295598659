import { find, map, toString } from 'lodash';

function findPosition({ positionItem, recordId, positionRecordType }) {
  const { recordType, recordId: positionRecordId } = positionItem || {};

  return toString(recordId) === toString(positionRecordId)
        && positionRecordType === recordType;
}

function mergeDataWithPosition(data, positions) {
  return map(data, (item) => {
    const { recordId, positionRecordType } = item || {};

    const position = find(positions, positionItem => (
      findPosition({ positionItem, recordId, positionRecordType })
    )) || {};

    return { ...item, ...position, isLoadingPosition: false };
  });
}

export function loadPositions(state, action) {
  const { data } = state || {};
  const { positions } = action.payload || {};

  return {
    ...state,
    data: map(data, (item) => {
      const { position, recordId } = item;

      return {
        ...item,
        position: positions[recordId] || position,
      };
    }),
  };
}

export function loadAllPositionsDone(state, action) {
  const { data } = state || {};

  return {
    ...state,
    data: mergeDataWithPosition(data, action.payload),
  };
}
