const PREFIX = 'MONEYBALL';

const constants = {
  STRATEGY_SLUG: 'moneyball_corporation_lookup',
  FETCH_CORPORATIONS: `${PREFIX}/FETCH_CORPORATIONS`,
  RECEIVE_CORPORATIONS_DONE: `${PREFIX}/RECEIVE_CORPORATIONS_DONE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
