import moment from 'moment';
import { isEmpty, map } from 'lodash';

import { EMPTY_VALUE } from 'utils/constants/emptyData';
import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

const searchItem = ({ status, ...props }) => {
  const { is_template, request_data, created_at, last_run_at, is_contact_enrichment_requested } = props;
  const { transactor } = request_data || {};
  const isEnrichmentEnabled = !is_template && transactor === 'individual_contributor';
  const isCommitteeRecipientsTransactor = transactor === 'committee_recipient';
  const remainingTime = isCommitteeRecipientsTransactor ? 'less than a minute' : 'about 10 mins';
  const { result, status: currentStatus } = status || {};
  const { url } = result || {};
  const hasResults = !isEmpty(result);
  const disabled = !(hasResults && url);
  const defaultProgress = 10;

  let progressCompleted = defaultProgress;

  if (hasResults) {
    const { done = 0, total = 1 } = result;

    progressCompleted = Math.max(Math.min(Math.ceil((done / total) * 100), 100), defaultProgress);
  }

  const types = {
    success: {
      title: 'Complete!',
      titleTheme: 'success',
      percent: 100,
    },
    enqueued: {
      title: 'Processing: about 15 mins left',
      titleTheme: 'pending',
      percent: progressCompleted,
    },
    progress: {
      title: `Processing: ${remainingTime} left`,
      titleTheme: 'pending',
      percent: progressCompleted,
    },
    failure: {
      title: 'Oops, something went wrong. Please edit this search and try again.',
      percent: 0,
      titleTheme: 'failure',
    },
  };

  // search is considered fresh if last update was made not more than 24h ago
  const pastHours = Math.ceil((moment().diff(last_run_at) / 1000) / (60 * 60));
  const hasFreshResults = pastHours <= 24;

  const formatDate = date => (!isEmpty(date) && moment(date).format(GLOBAL_DATE_FORMAT)) || EMPTY_VALUE;
  const progress = (currentStatus && types[currentStatus]) || types.success;

  return {
    ...props,
    status,
    progress,
    disabled,
    description: props.description || EMPTY_VALUE,
    hasFreshResults,
    createdDate: formatDate(created_at),
    lastRunDate: formatDate(last_run_at),
    isEnrichmentRequested: is_contact_enrichment_requested,
    withEnrichment: isEnrichmentEnabled,
  };
};

export const updateItemSearches = (item = {}) => searchItem(item);

export const transformSearches = (items = []) => map(items, searchItem);
