import { isNil, omitBy, reject, overSome } from 'lodash';

import { DOCUMENT_TYPES, responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { STATE_COMMITTEE_ITEM_TYPE } from 'utils/constants/itemTypes';

export function transformProfileForUI(profileData = {}) {
  const {
    id,
    display_name,
    ...restProps
  } = profileData;

  return omitBy({
    ...restProps,
    recordId: id,
    documentType: DOCUMENT_TYPES.STATE_COMMITTEE,
    trackingType: responseTrackingTypes.STATE_COMMITTEE_STAFFER,
    itemType: STATE_COMMITTEE_ITEM_TYPE,
    displayName: display_name,
    url: `/state/committees/${id}`,
    id,
  }, isNil);
}

export function transformCommitteeMembersForUI(members = []) {
  const transformMember = ({
    universal_moniker,
    party_short,
    party_slug,
    ...restProps
  }) => ({
    ...restProps,
    name: universal_moniker,
    partyShort: party_short,
    partySlug: party_slug,
  });

  const byParty = party => member => member.party_slug === party;
  const byRepublicanParty = byParty('republican');
  const byDemocraticParty = byParty('democratic');
  const byOtherParty = overSome(byRepublicanParty, byDemocraticParty);

  return {
    republicans: members.filter(byRepublicanParty).map(transformMember),
    democrats: members.filter(byDemocraticParty).map(transformMember),
    others: reject(members, byOtherParty).map(transformMember),
  };
}
