import { isEmpty, omitBy } from 'lodash';
import moment from 'moment/moment';

import { submitExportReport } from 'redux/exportReport/actions';
import { exportCSVBlob, getExportSortData } from 'redux/exportReport/utils';
import { CSV_ID, EXPORT_EMAIL_LIMIT } from 'utils/constants/export';
import { FULL_DATE_FORMAT } from 'utils/constants/date';

import { getExportInfo, transformStakeholdersForUI } from '../utils/transformers';
import constants from '../constants';
import { getStakeholdersData } from './data';

export function submitExport(payload = {}) {
  const {
    items,
    exportOption,
    direction = 'desc',
    sort = 'id',
    searchData = {},
    sortData,
  } = payload;

  const exportIds = items && items.map(item => item.id);
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: constants.STRATEGY_SLUG_FOR_EXPORT,
    'strategy_inputs[stakeholder_ids]': exportIds,
    'strategy_inputs[file_extension]': exportOption || CSV_ID,
    'strategy_inputs[direction]': direction,
    'strategy_inputs[sort]': sort,
    ...getExportSortData(sortData),
    ...omitBy(getExportInfo(searchForm), isEmpty),
  };

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `Stakeholder Management ${moment().format(FULL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({ ...payload, data, onSuccessCustom });
}

export function getAllEmails(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.FETCH_STAKEHOLDER_EMAILS });
    const { appliedFilters } = getState().stakeholder.stakeholderLookup.appliedFilters;

    const { url, data } = getStakeholdersData({ ...payload, searchData: appliedFilters });
    const ajax = $.post(url, { ...data, per_page: EXPORT_EMAIL_LIMIT });

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_STAKEHOLDER_EMAILS_DONE,
        payload: transformStakeholdersForUI(response.data),
      });
    }

    return Promise.resolve(ajax).then(onSuccess);
  };
}
