const PREFIX = 'STATE_EXECUTIVE_LOOKUP';

const CONSTANTS = {
  FETCH_EXECUTIVES: `${PREFIX}/FETCH_EXECUTIVES`,
  RECEIVE_EXECUTIVES_DONE: `${PREFIX}/RECEIVE_EXECUTIVES_DONE`,
  RESET_EXECUTIVES: `${PREFIX}/RESET_EXECUTIVES`,
  FETCH_EXECUTIVES_EMAILS: `${PREFIX}/FETCH_EXECUTIVES_EMAILS`,
  RECEIVE_EXECUTIVES_EMAILS_DONE: `${PREFIX}/RECEIVE_EXECUTIVES_EMAILS_DONE`,
};

export default CONSTANTS;
