import { combineReducers } from 'redux';

import customFields from 'redux/customFields/reducers';

import constants from './constants';

export const defaultState = {
  stakeholders: [],
  ui: {
    isMergingStakeholders: false,
  },
};

function stakeholderMerge(state = defaultState, action) {
  switch (action.type) {
    case constants.MERGE_STAKEHOLDERS:
      return {
        ...state,
        stakeholders: defaultState.stakeholders,
        ui: { ...state.ui, isMergingStakeholders: true },
      };
    case constants.MERGE_STAKEHOLDERS_DONE:
      return {
        ...state,
        stakeholders: action.payload,
        ui: { ...state.ui, isMergingStakeholders: false },
      };
    case constants.MERGE_STAKEHOLDERS_FAIL:
      return {
        ...state,
        stakeholdersMap: action.payload,
        ui: { ...state.ui, isMergingStakeholders: false },
      };
    default:
      return state;
  }
}

export default combineReducers({
  stakeholderMerge,
  customFields,
});
