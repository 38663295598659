import { combineReducers } from 'redux';
import { concat } from 'lodash';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import {
  getFeedWithNewFrequency,
  getFeedWithoutDeletedItem,
  updateWorkspaceSubscription,
} from './utils';
import constants from './constants';

const DEFAULT_STATE = {
  feed: [],
  workspace: {},
  ui: {
    isLoading: true,
    isInfiniteDisabled: false,
    isLoadingWorkspace: true,
    isInitialLoad: true,
    isDeletingWorkspace: false,
    isNotFound: false,
  },
};

function workspaces(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.ADD_WORKSPACE:
      return {
        ...state,
        feed: concat(action.payload, state.feed),
      };
    case constants.FETCH_WORKSPACES:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
          isInitialLoad: !action.payload.isInfinite,
        },
      };
    case constants.RECEIVE_WORKSPACES_DONE:
      return {
        ...state,
        feed: action.payload.isInfinite ? concat(state.feed, action.payload.feed) : action.payload.feed,
        ui: {
          ...state.ui,
          isLoading: false,
          isInitialLoad: false,
          isInfiniteDisabled: action.payload.pagination.currentPage === action.payload.pagination.totalPages,
        },
      };
    case constants.UPDATE_WORKSPACE_SUBSCRIPTION:
      return {
        ...state,
        feed: getFeedWithNewFrequency(state.feed, action.payload),
        workspace: updateWorkspaceSubscription(state.workspace, action.payload.frequency, action.payload.isTracking),
      };
    case constants.DELETE_WORKSPACE:
      return {
        ...state,
        ui: { ...state.ui, isDeletingWorkspace: true },
      };
    case constants.DELETE_WORKSPACES_DONE:
      return {
        ...state,
        feed: getFeedWithoutDeletedItem(state.feed, action.payload),
        ui: { ...state.ui, isDeletingWorkspace: false },
      };
    case constants.DELETE_WORKSPACE_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isDeletingWorkspace: false },
      };
    case constants.FETCH_WORKSPACE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingWorkspace: true },
      };
    case constants.RECEIVE_WORKSPACE_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingWorkspace: false },
        workspace: action.payload,
      };
    case constants.WORKSPACE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, isNotFound: true },
      };
    case constants.RESET_WORKSPACE:
      return DEFAULT_STATE;
    default:
      return state;
  }
}

export default combineReducers({ workspaces, appliedSearchFilters, advancedSearch });
