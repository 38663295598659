import { isNil, isEmpty, map, toString, omitBy } from 'lodash';

import {
  POSITION_BUTTON_RECORD_TYPES,
  RECENT,
  TRACKED,
  DOCUMENT_TYPES,
} from 'components/Dashboard/common/FeedPage/utils/constants';
import { TRACKED_STATE_REGULATION } from 'components/Dashboard/utils/libProducts';
import { getValue } from 'utils/transformers/advancedSearch';
import { formatFullDate } from 'utils/date';

import {
  getItemTypes,
  formatReadItems,
  formatBooleanItem,
  getTrackedItemTypes,
  formatTrackedItems,
  formatSharedItems,
} from '../index';
import { transformRecentEvent } from './recentEvents';
import { transformTrackedItem } from './trackedEvents';

export function transformRecentDataRequest({
  searchValue,
  options,
  createdAt,
  perPage,
  page,
}) {
  const {
    date: { startDate, endDate } = {},
    keywords,
    itemType,
    state,
    priority,
    position,
    emailFrequency,
    trackedItems,
    sharedItems,
    savedItems,
    removedItems,
    readItems,
    tags,
  } = options || {};

  return {
    ...getValue('per_page', perPage),
    ...getValue('page', page),
    ...getValue('search_form', omitBy({
      ...getValue('lookup_keyword', searchValue),
      ...getValue('date_from', startDate),
      ...getValue('date_to', endDate),
      ...getValue('created_at_to', createdAt),
      ...getValue('subscription_tokens', keywords),
      ...getValue('document_types', getItemTypes(itemType)),
      ...getValue('state_alpha2s', map(state, 'value')),
      ...getValue('tag_ids', map(tags, 'value')),
      ...getValue('priorities', priority),
      ...getValue('user_positions', position),
      ...getValue('frequency', emailFrequency),
      ...getValue('saved_only', formatBooleanItem(savedItems)),
      ...getValue('removed_only', formatBooleanItem(removedItems)),
      ...formatTrackedItems(trackedItems),
      ...formatSharedItems(sharedItems),
      read_only: formatReadItems(readItems),
    }, isNil)),
  };
}

export function transformTrackedDataRequest({ searchValue, options, perPage, sortData, page }) {
  const {
    dateAdded: { startDate: addedStartDate, endDate: addedEndDate } = {},
    lastUpdate: { startDate: updatedStartDate, endDate: updatedEndDate } = {},
    itemType,
    state,
    priority,
    position,
    emailFrequency,
    savedItems,
    removedItems,
    status,
    tags,
    federalBillStatus,
    stateBillStatus,
  } = options || {};

  const { sort, direction } = sortData || {};

  return {
    ...getValue('per_page', perPage),
    ...getValue('page', page),
    ...getValue('sort', sort),
    ...getValue('direction', direction),
    ...getValue('search_form', {
      ...getValue('lookup_keyword', searchValue),
      ...getValue('last_update', {
        ...getValue('from', updatedStartDate),
        ...getValue('to', updatedEndDate),
      }),
      ...getValue('item_types', getTrackedItemTypes(itemType)),
      ...getValue('state_alpha2', map(state, 'value')),
      ...getValue('priorities', priority),
      ...getValue('user_positions', position),
      ...getValue('date_added', {
        ...getValue('from', addedStartDate),
        ...getValue('to', addedEndDate),
      }),
      ...getValue('tag_ids', map(tags, 'value')),
      ...getValue('frequency', emailFrequency),
      ...getValue('saved_only', formatBooleanItem(savedItems)),
      ...getValue('show_active_only', formatBooleanItem(status)),
      ...getValue('removed_only', formatBooleanItem(removedItems)),
      ...getValue('federal_bill_status_types', federalBillStatus),
      ...getValue('state_bill_status_types', stateBillStatus),
    }),
  };
}

function transformTracked({
  id: subscriptionId,
  item_type,
  item_data,
  is_disabled,
  document_type,
  subscriptions,
}) {
  const { id } = item_data || {};
  const positionRecordType = POSITION_BUTTON_RECORD_TYPES[item_type];

  return {
    ...transformTrackedItem({ item_type, item_data, subscriptions, is_disabled }),
    itemType: item_type,
    positionRecordType,
    documentType: document_type,
    recordId: toString(id),
    withPosition: !isEmpty(positionRecordType),
    subscriptionId,
  };
}

function transformSubscription(subscription) {
  const {
    token,
    frequency,
    keyword,
    subscribable_type,
    is_disabled,
    title,
    trackings_slug,
  } = subscription || {};

  return {
    token,
    frequency,
    keyword,
    subscribableType: subscribable_type,
    isDisabled: is_disabled,
    title,
    trackingsSlug: trackings_slug,
  };
}

function getPositionType(documentType, trackRecordType) {
  const type = trackRecordType === TRACKED_STATE_REGULATION
    ? TRACKED_STATE_REGULATION
    : documentType;

  return POSITION_BUTTON_RECORD_TYPES[type];
}

function getTrackRecordType({ isCommittee, trackingsSlug, searchId }) {
  if (isCommittee) {
    return trackingsSlug;
  }

  if (!isNil(searchId)) {
    return DOCUMENT_TYPES.NEWS_SEARCH;
  }

  return toString(trackingsSlug);
}

function transformRecent({
  event_data,
  event_type,
  created_at,
  subscription,
  token,
  is_removed,
  is_saved,
  is_read,
  priority,
}) {
  const {
    id,
    document_type: documentType,
    document_id: documentId,
    search_id: searchId,
  } = event_data || {};
  const transformedSubscription = transformSubscription(subscription);
  const { trackingsSlug, frequency, isDisabled } = transformedSubscription || {};
  const isCommittee = [
    DOCUMENT_TYPES.FEDERAL_COMMITTEE,
    DOCUMENT_TYPES.STATE_COMMITTEE,
  ].includes(documentType);
  /** Array for committee */
  const trackRecordType = getTrackRecordType({ isCommittee, trackingsSlug, searchId });
  const positionRecordType = getPositionType(documentType, trackRecordType);
  const createdAt = formatFullDate(created_at);

  return {
    ...transformRecentEvent({ event_type, event_data, subscription, createdAt }),
    isCommittee,
    id: token,
    isRead: is_read,
    isNewData: true,
    isRemoved: is_removed,
    isSaved: is_saved,
    priority,
    subscription: transformedSubscription,
    listSubscriptions: [],
    trackRecordType,
    positionRecordType,
    documentType,
    frequency,
    isDisabled,
    isLoadingTracking: true,
    recordId: toString(documentId || id),
    withPosition: !isEmpty(positionRecordType),
    withTrack: !isEmpty(trackRecordType),
    searchId,
    modelId: searchId,
  };
}

export function transformDataResponse({ data }, type) {
  return map(data, item => ({
    ...(type === TRACKED && transformTracked(item)),
    ...(type === RECENT && transformRecent(item)),
  }));
}
