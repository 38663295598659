import { map, isEmpty, isObject, filter, includes, get, size, omitBy } from 'lodash';

import { initialValues } from 'components/Dashboard/KeywordSearch/utils/constants';
import {
  STATE_BILLS_SLUG,
  STATE_REGULATIONS_SLUG,
  GOVERNOR_PRESS_RELEASES_SLUG,
  GOVERNOR_ORDERS_SLUG,
  LOCAL_DOCUMENTS_SLUG,
  SOCIAL_MEDIA_SLUG,
} from 'components/Dashboard/utils/libProducts';

function mapValue(values) {
  return map(values, item => (isObject(item) ? item.value : item));
}

function getStateBills(values, itemsToTrack) {
  const isStateBills = includes(itemsToTrack, STATE_BILLS_SLUG);
  const isStateRegulation = includes(itemsToTrack, STATE_REGULATIONS_SLUG);
  const isGovernorPressReleases = includes(itemsToTrack, GOVERNOR_PRESS_RELEASES_SLUG);
  const isGovernorOrders = includes(itemsToTrack, GOVERNOR_ORDERS_SLUG);
  const states = mapValue(values);

  return {
    ...(isStateBills && { state_bill_states: states }),
    ...(isStateRegulation && { state_regulation_document_states: states }),
    ...(isGovernorPressReleases && { doctrove_governor_press_release_states: states }),
    ...(isGovernorOrders && { doctrove_governor_order_states: states }),
  };
}

function getLocalDocuments(values, itemsToTrack) {
  const isLocalDocumentsIncluded = includes(itemsToTrack, LOCAL_DOCUMENTS_SLUG);

  if (!isLocalDocumentsIncluded) {
    return {};
  }

  const cities = filter(values, ['isCity', true]);

  return {
    town_intel_document_options: {
      states: mapValue(values),
      cities: mapValue(cities),
    },
  };
}

function getSocialMedia(values, itemsToTrack) {
  const isSocialMedia = includes(itemsToTrack, SOCIAL_MEDIA_SLUG);

  if (!isSocialMedia) {
    return {};
  }

  return { social_media_post_ids: mapValue(values) };
}

export function transformKeywordForBE(payload, isUpdate) {
  const {
    keywords,
    nickname,
    itemsToTrack,
    statesToTrack,
    localDocumentOptions,
    socialMediaToTrack,
    notificationFrequency,
    workspacesToSubscribe,
    withWorkspacesOption,
  } = payload || {};

  const subscribableData = {
    keyword: keywords,
    item_types: itemsToTrack,
  };

  const commonProps = {
    title: nickname,
    frequency: notificationFrequency,
    ...(withWorkspacesOption && !isUpdate && { feed_tokens: mapValue(workspacesToSubscribe) }),
  };

  return omitBy({
    ...commonProps,
    subscribable_data: {
      ...subscribableData,
      ...getStateBills(statesToTrack, itemsToTrack),
      ...getLocalDocuments(localDocumentOptions, itemsToTrack),
      ...getSocialMedia(socialMediaToTrack, itemsToTrack),
    },
  }, isEmpty);
}

export function transformKeywordForUI(payload, allStatesLength, defaultSubscription) {
  const { subscribable_data, frequency, title } = payload || {};
  const {
    keyword,
    model_slugs,
    state_bill_states,
    town_intel_document_options,
    social_media_post_ids,
    state_regulation_document_states,
    doctrove_governor_press_release_states,
    doctrove_governor_order_states,
  } = subscribable_data || {};

  const statesToTrack = state_bill_states
      || state_regulation_document_states
      || doctrove_governor_press_release_states
      || doctrove_governor_order_states
      || [];
  const localCities = get(town_intel_document_options, 'cities', []);
  const localStates = get(town_intel_document_options, 'states', []);
  const localCitiesLength = size(localStates);

  return {
    ...initialValues,
    keywords: keyword || '',
    nickname: title || '',
    itemsToTrack: model_slugs,
    statesToTrack,
    localCities,
    localDocumentOptions: [
      ...localStates,
      ...map(localCities, city => ({ ...city, isCity: true })),
    ],
    ...(localCitiesLength > 0 && { isAllLocalChecked: localCitiesLength === allStatesLength }),
    isAllSocialMediaSelected: isEmpty(social_media_post_ids),
    socialMediaToTrack: social_media_post_ids || [],
    notificationFrequency: frequency || defaultSubscription,
  };
}
