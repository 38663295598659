import {
  FETCH_CAMPAIGN,
  RECEIVE_CAMPAIGN,
  CAMPAIGN_NOT_FOUND,
  FETCH_STATISTICS,
  RECEIVE_STATISTICS_LOADING,
  RECEIVE_STATISTICS_ADVOCATES_COUNT,
  RECEIVE_STATISTICS_MESSAGES_COUNT,
  RECEIVE_STATISTICS_MESSAGES_SENT,
  RECEIVE_STATISTICS_TOP_STATES,
  RECEIVE_STATISTICS_EDITED_MESSAGES,
  RECEIVE_STATISTICS_STATE_RECIPIENTS,
  RECEIVE_STATISTICS_CUSTOM_RECIPIENTS,
  RECEIVE_STATISTICS_FEDERAL_RECIPIENTS,
  RECEIVE_STATISTICS_SENDERS,
  RECEIVE_STATISTICS_REGULATIONS,
  FETCHING_TABLE_STATISTICS,
  FETCHED_TABLE_STATISTICS,
  ADD_CHART_TO_PRINT,
  SET_DATE_RANGE,
  PER_PAGE,
} from '../constants';

import defaultState from './defaultState';

const receiveStatistics = (state, { name, payload }) => {
  const data = payload;
  if (data && data.pagination) {
    data.pagination.per_page = PER_PAGE;
  }

  return ({
    ...state,
    statistics: {
      ...state.statistics,
      [name]: data,
    },
  });
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case FETCHING_TABLE_STATISTICS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingTableStatistics: true,
        },
      };

    case FETCHED_TABLE_STATISTICS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingTableStatistics: false,
        },
      };

    case FETCH_STATISTICS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingStatistics: true,
        },
      };

    case RECEIVE_STATISTICS_MESSAGES_COUNT:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'messages_count',
      });

    case RECEIVE_STATISTICS_ADVOCATES_COUNT:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'advocates_count',
      });

    case RECEIVE_STATISTICS_MESSAGES_SENT:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'messages_sent',
      });

    case RECEIVE_STATISTICS_TOP_STATES:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'top_states',
      });

    case RECEIVE_STATISTICS_EDITED_MESSAGES:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'edited_messages',
      });

    case RECEIVE_STATISTICS_FEDERAL_RECIPIENTS:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'federal_recipients',
      });

    case RECEIVE_STATISTICS_STATE_RECIPIENTS:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'state_recipients',
      });

    case RECEIVE_STATISTICS_CUSTOM_RECIPIENTS:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'custom_recipients',
      });

    case RECEIVE_STATISTICS_SENDERS:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'senders',
      });

    case RECEIVE_STATISTICS_REGULATIONS:
      return receiveStatistics(state, {
        payload: action.payload,
        name: 'regulations',
      });

    case RECEIVE_STATISTICS_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingStatistics: false,
        },
      };

    case ADD_CHART_TO_PRINT:
      return {
        ...state,
        charts: {
          ...state.charts,
          [action.payload.chartId]: action.payload.chart,
        },
      };

    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };

    case FETCH_CAMPAIGN:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingCampaign: true,
        },
      };

    case RECEIVE_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
        ui: {
          ...state.ui,
          loadingCampaign: false,
        },
      };

    case CAMPAIGN_NOT_FOUND:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadingCampaign: false,
        },
      };

    default:
      return state;
  }
}
