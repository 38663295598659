export default function ({ state }) {
  return {
    ...state,
    ui: {
      ...state.ui,
      loading: false,
      noResultsFound: true,
    },
  };
}
