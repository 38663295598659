import { STOP_LOADING } from '../constants';

export function stopLoading(dispatch) {
  return () => dispatch({ type: STOP_LOADING });
}

export function receiveError(dispatch) {
  return (error) => {
    stopLoading(dispatch)();

    console.error(error);
    return Promise.reject(error);
  };
}
