import isEmpty from 'lodash/isEmpty';

import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { getLookupRequestParams } from 'utils/lookup';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import constants from '../constants';
import { transformBillsForUI, transformOptionsForBE } from '../utils';
import { fetchSubscriptions } from './subscriptions';
import { fetchPositions } from './positions';
import { fetchTags } from './tags';

export function fetchBills(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILLS });
    const url = '/api_web/state/bills/lookup';

    const { searchData = {}, clearAll, toResetSelectedItems, documentType } = payload;
    const dataPayload = { url, ...payload };
    const { isSearch, sortData, data } = getLookupRequestParams(dataPayload, transformOptionsForBE);
    const ajax = $.post(url, data);

    function onSuccess(response) {
      const currentPayload = (isEmpty(response) || isEmpty(response.data)) ? [] : response.data;
      const paginator = transformPaginator(response);
      const { current } = paginator;

      return dispatch({
        type: constants.RECEIVE_BILLS_DONE,
        payload: transformBillsForUI(currentPayload, current),
        paginator,
        toResetSelectedItems: isSearch || toResetSelectedItems,
        sort: transformSort(sortData),
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    function onFailure(error) {
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(payloadData => dispatch(fetchTags(payloadData, documentType)))
      .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
      .then(payloadData => dispatch(fetchPositions(payloadData, documentType)))
      .then(payloadData => dispatch(fetchSubscriptions(payloadData, documentType)))
      .then(saveAppliedFiltersToProps)
      .catch(onFailure);
  };
}

export function resetBills() {
  return dispatch => (
    dispatch({
      type: constants.RESET_BILLS,
    })
  );
}

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
