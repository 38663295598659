import { isNil, omitBy } from 'lodash';

import isNotFoundError from 'utils/http/isNotFoundError';
import { sortStafferByColumn as sortStaffer } from 'redux/staffers/common/actions';
import { fetchProfileTags } from 'redux/tags/actions';

import constants from './constants';
import { transformProfileForUI } from './transformers/profile';
import getOfficeStaffersBody from './utils/getOfficeStaffersBody';
import { transformOfficeStaffersForUI } from './transformers/officeStaffers';

export function fetchOfficeStaffers(payload = {}) {
  return (dispatch) => {
    const { stateOfficeId, page, perPage, sortData } = payload;

    dispatch({
      type: constants.FETCH_OFFICE_STAFFERS,
      payload: {
        sortData: sortData || {},
      },
    });

    if (isNil(stateOfficeId)) {
      return Promise.resolve(
        dispatch({
          type: constants.RECEIVE_OFFICE_STAFFERS_DONE,
          payload: {
            staffers: [],
            pagination: {},
          },
        })
      );
    }

    const url = '/api_web/state/staffers/lookup';
    const requestBody = getOfficeStaffersBody({ stateOfficeId, page, perPage, sortData });

    const ajax = $.post(url, requestBody);

    return Promise.resolve(ajax)
      .then(
        response => dispatch({
          type: constants.RECEIVE_OFFICE_STAFFERS_DONE,
          payload: transformOfficeStaffersForUI(response),
        })
      );
  };
}

function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function fetchStaffer(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFER });
    const { id } = payload;

    const url = `/api_web/state/staffers/${id}`;
    const ajax = $.get(url);

    const onSuccess = response => dispatch({
      type: constants.RECEIVE_STAFFER_DONE,
      payload: transformProfileForUI(response),
    });

    const onError = (error) => {
      isNotFoundError(error) && dispatch({ type: constants.PROFILE_NOT_FOUND });

      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(() => dispatch(fetchTags(payload)))
      .catch(onError);
  };
}

export function sortOfficeStaffers({ columnName, sortDirection, perPage }) {
  return (dispatch, getState) => {
    const { stafferData } = getState().state.stafferProfile;
    const { stateOfficeId } = stafferData;

    const sortData = omitBy({ sort: columnName, direction: sortDirection }, isNil);

    return dispatch(fetchOfficeStaffers({
      stateOfficeId,
      page: 1,
      perPage,
      sortData,
    }));
  };
}

export function sortStafferByColumn(stafferKey, columnName, sortDirection) {
  return (dispatch, getState) => {
    const { stafferData } = getState().state.stafferProfile;

    return dispatch(sortStaffer({
      stafferKey,
      columnName,
      sortDirection,
      stafferData,
      actionTypes: constants,
    }));
  };
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}
