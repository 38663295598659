import { isEmpty, omitBy, isNil } from 'lodash';

import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import constants from '../constants';
import { transformLegislatorsForUI, transformOptionsForBE } from '../utils';
import { fetchSubscriptions } from './subscriptions';
import { fetchTags } from './tags';

export function getLegislatorsData(payload) {
  const { searchData = {}, appliedFilters, clearAll, sort, direction, page } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const url = '/api_web/state/legislators/lookup';
  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData };

  return { url, data, sortData };
}

export function fetchLegislators(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LEGISLATORS });

    const { searchData = {}, clearAll, documentType, trackingType } = payload;
    const { url, data, sortData } = getLegislatorsData(payload);
    const ajax = $.post(url, data);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_LEGISLATORS_DONE,
        payload: transformLegislatorsForUI(response.data),
        paginator: transformPaginator(response),
        sort: transformSort(sortData),
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    function onFailure(error) {
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(((payloadData) => {
        dispatch(fetchSubscriptions(payloadData, trackingType));

        return payloadData;
      }))
      .then(payloadData => dispatch(fetchTags(payloadData, documentType)))
      .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
      .then(saveAppliedFiltersToProps())
      .catch(onFailure);
  };
}

export function resetLegislators() {
  return dispatch => (
    dispatch({
      type: constants.RESET_LEGISLATORS,
    })
  );
}

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
