import { FETCH_SMART_LISTS, RECEIVE_SMART_LISTS_DONE } from '../constants';
import { getSortParams, transformSmartListsForUI } from '../utils';

export function fetchSmartLists({ sortBy }) {
  return (dispatch) => {
    dispatch({ type: FETCH_SMART_LISTS });

    const url = '/api_web/stakeholder-management/smart_lists/lookup';
    const params = getSortParams(sortBy);

    const ajax = $.post(url, params);

    function onSuccess(response) {
      return dispatch({
        type: RECEIVE_SMART_LISTS_DONE,
        payload: transformSmartListsForUI(response && response.data),
        sortBy,
      });
    }

    return Promise.resolve(ajax)
      .then(onSuccess);
  };
}
