import { NONE_ID } from 'components/core/FrequencyOptions/constants';

const defaultState = {
  defaultSubscription: NONE_ID,
  committees: [],
  paginator: {},
  sort: {},
  ui: {
    isLoadingData: true,
    isLoadingTags: true,
    isInitialLoading: true,
    isLoadingMyFeedDocuments: true,
    isLoadingSubscriptions: false,
    isSavingSubscriptions: true,
    toResetSelectedItems: true,
    isSharingItems: false,
  },
};

export default defaultState;
