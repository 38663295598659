export const MONEYBALL_CANDIDATE_PROFILE_STRATEGY_SLUG = 'moneyball_candidate_profile';
const PREFIX = 'MONEYBALL_CANDIDATE_PROFILE';

const constants = {
  STRATEGY_SLUG: MONEYBALL_CANDIDATE_PROFILE_STRATEGY_SLUG,
  FETCH_CANDIDATE: `${PREFIX}/FETCH_CANDIDATE`,
  RECEIVE_CANDIDATE_DONE: `${PREFIX}/RECEIVE_CANDIDATE_DONE`,
  FETCH_CANDIDATE_CONTRIBUTIONS: `${PREFIX}/FETCH_CANDIDATE_CONTRIBUTIONS`,
  RECEIVE_CANDIDATE_CONTRIBUTIONS_DONE: `${PREFIX}/RECEIVE_CANDIDATE_CONTRIBUTIONS_DONE`,
  FETCH_CANDIDATE_RECEIPT: `${PREFIX}/FETCH_CANDIDATE_RECEIPT`,
  RECEIVE_CANDIDATE_RECEIPT_DONE: `${PREFIX}/RECEIVE_CANDIDATE_RECEIPT_DONE`,
  SET_ELECTION_CYCLES: `${PREFIX}/SET_ELECTION_CYCLES`,
  SELECT_ELECTION_CYCLE: `${PREFIX}/SELECT_ELECTION_CYCLE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
