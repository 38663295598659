import { omitBy, isNil } from 'lodash';

import { transformPaginator, transformSort } from 'utils/transformers/paginator';

import {
  transformPublicationsForUI,
  transformPublicationsForBE,
  transformTimelineForUI,
} from '../utils';
import constants, { PUBLICATIONS_URL } from '../constants';

export const PUBLICATIONS_PER_PAGE = 10;

function getPublicationsData(payload = {}, perPage) {
  const { sort, direction, page, grouped, customPerPage } = payload;
  const searchQuery = transformPublicationsForBE(payload);
  const searchForm = { search_form: searchQuery };

  const sortData = omitBy({ sort, direction, page, grouped }, isNil);
  const data = { ...searchForm, ...sortData, per_page: customPerPage || perPage };
  return { data, sortData };
}

function fetchPublicationsBase(payload = {}, actions, isTimeline = false) {
  let transformForUI = transformPublicationsForUI;
  const perPage = PUBLICATIONS_PER_PAGE;

  if (isTimeline) {
    transformForUI = transformTimelineForUI;
  }

  return (dispatch) => {
    dispatch({ type: actions.fetchStarted });

    const url = PUBLICATIONS_URL;
    const { data, sortData } = getPublicationsData(payload, perPage);

    const ajax = $.ajax({
      url,
      method: 'POST',
      data,
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: actions.fetchDone,
        payload: {
          ...transformForUI(response),
          paginator: transformPaginator(response, perPage),
          sort: transformSort(sortData),
        },
      }));
  };
}

export function fetchPublications(payload = {}) {
  const actions = {
    fetchStarted: constants.FETCH_PUBLICATIONS,
    fetchDone: constants.RECEIVE_PUBLICATIONS_DONE,
  };

  return fetchPublicationsBase(payload, actions);
}

export function fetchTimeline(payload = {}) {
  const actions = {
    fetchStarted: constants.FETCH_TIMELINE,
    fetchDone: constants.RECEIVE_TIMELINE_DONE,
  };

  return fetchPublicationsBase(payload, actions, true);
}
