import {
  SUBMIT_LOBBY_ALERTS,
  SUBMIT_LOBBY_ALERTS_DONE,
  SUBMIT_LOBBY_ALERTS_ERROR,
} from '../constants';

import defaultState from './defaultState';
import submitLobbyAlerts from './submitLobbyAlerts';
import submitLobbyAlertsDone from './submitLobbyAlertsDone';
import submitLobbyAlertsError from './submitLobbyAlertsError';

export default function (state = defaultState, action) {
  const actions = {
    [SUBMIT_LOBBY_ALERTS]: submitLobbyAlerts,
    [SUBMIT_LOBBY_ALERTS_DONE]: submitLobbyAlertsDone,
    [SUBMIT_LOBBY_ALERTS_ERROR]: submitLobbyAlertsError,
  };

  // eslint-disable-next-line no-prototype-builtins
  if (actions.hasOwnProperty(action.type)) {
    const {
      [action.type]: submitFunc,
    } = actions;

    return submitFunc({ action, state });
  }

  return state;
}
