import constants from './constants';

export const DEFAULT_STATE = {
  staffers: [],
  paginator: {},
  sort: {},
  ui: {
    isLoading: true,
  },
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_STAFFERS:
      return {
        ...state,
        paginator: state.paginator,
        ui: { isLoading: true },
      };
    case constants.RECEIVE_STAFFERS_DONE:
      return {
        ...state,
        ...action.payload,
        ui: { isLoading: false },
      };
    default:
      return state;
  }
}
