import moment from 'moment';
import { unionWith, isEqual } from 'lodash';

import { CALENDAR_DATE_FORMAT } from 'utils/constants/date';
import { extractAllEvents } from 'components/core/HearingCalendars/utils';

import constants from './constants';
import { groupResponseByDate } from './utils';

function getAjax(url, data, method = 'GET') {
  return $.ajax({
    method,
    url,
    data,
  });
}

function getEndDate(startDate, monthsAhead = 1) {
  return moment(startDate).add(monthsAhead, 'month').format(CALENDAR_DATE_FORMAT);
}

function filterResponseByCommittees(selectedCommittees, apiEvents) {
  return selectedCommittees && selectedCommittees.length > 0
    ? { data: apiEvents.filter(event => selectedCommittees.includes(event.committee?.id)) }
    : { data: apiEvents };
}

function filterResponseBySubcommittee(selectedSubcommittee, apiEvents) {
  return { data: apiEvents.filter(
    event => selectedSubcommittee === `${event.committee?.id}-${event.subcommittee_id}`
  ) };
}

export function fetchEvents(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.FETCH_EVENTS, payload });

    const { monthsAheadToFetch } = getState().federal.hearingCalendar;

    const {
      page = 1,
      start_date,
      end_date = getEndDate(start_date, monthsAheadToFetch),
    } = payload;

    const url = '/api_web/hearings';
    const data = {
      per_page: 10,
      page,
      start_date,
      end_date,
    };

    const ajax = getAjax(url, data);

    return Promise.resolve(ajax)
      .then((response) => {
        const { selectedCommittees } = getState().federal.hearingCalendar;
        const { isSubcommittee } = getState().federal.committeeProfile.committee;
        const filteredResponse = isSubcommittee
          ? filterResponseBySubcommittee(selectedCommittees[0], response.data)
          : filterResponseByCommittees(selectedCommittees, response.data);

        return dispatch({
          type: constants.FETCH_EVENTS_SUCCESS,
          apiEvents: response.data,
          payload: groupResponseByDate(filteredResponse),
        });
      });
  };
}

export function fetchCalendarData(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CALENDAR_DATA, payload });

    const { date = moment() } = payload;
    const url = '/api_web/hearings/calendar';
    const data = {
      start_date: moment(date).subtract(2, 'months').format(CALENDAR_DATE_FORMAT),
      end_date: moment(date).add(2, 'months').format(CALENDAR_DATE_FORMAT),
    };

    const ajax = getAjax(url, data);

    return Promise.resolve(ajax).then(
      response => dispatch({
        type: constants.FETCH_CALENDAR_DATA_SUCCESS,
        payload: response.data,
      })
    );
  };
}

export function onSelectCalendarDate(selectedDate) {
  return (dispatch) => {
    const payload = { start_date: selectedDate };

    dispatch({ type: constants.SELECT_CALENDAR_DATE, selectedDate });
    dispatch(fetchEvents(payload));
  };
}

export function onChangeCalendarMonth(selectedDate) {
  return (dispatch) => {
    const payload = { date: selectedDate };

    dispatch(fetchCalendarData(payload));
  };
}

export function onCheckAllEvents(isChecked) {
  return (dispatch, getState) => {
    const { events } = getState().federal.hearingCalendar;
    const checkedEvents = isChecked ? extractAllEvents(events) : [];

    dispatch({ type: constants.CHECK_EVENTS, checkedEvents });
  };
}

function onClearAllCheckedEvents(dispatch) {
  dispatch({
    type: constants.CHECK_EVENTS,
    checkedEvents: [],
  });
}

export function onClearAllEvents() {
  return dispatch => onClearAllCheckedEvents(dispatch);
}

export function onCheckSelectedEvent(selectedEvent, isChecked) {
  return (dispatch, getState) => {
    const { checkedEvents } = getState().federal.hearingCalendar;
    let filteredEvents = [];

    if (isChecked) {
      selectedEvent.isChecked = isChecked;
      filteredEvents = unionWith(checkedEvents, [selectedEvent], isEqual);
    } else {
      filteredEvents = checkedEvents.filter(event => event !== selectedEvent);
    }

    dispatch({
      type: constants.CHECK_EVENTS,
      checkedEvents: filteredEvents,
    });
  };
}

export function filterByCommittee(selectedItems) {
  const selectedCommittees = selectedItems.map(item => item.value);

  return (dispatch, getState) => {
    const { apiEvents } = getState().federal.hearingCalendar;
    const response = filterResponseByCommittees(selectedCommittees, apiEvents);

    onClearAllCheckedEvents(dispatch);

    dispatch({
      type: constants.FILTER_EVENTS,
      selectedCommittees,
      payload: groupResponseByDate(response),
    });
  };
}

export function setMonthsAheadToFetch(payload) {
  return {
    type: constants.SET_MONTHS_AHEAD,
    payload,
  };
}
