import {
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  UPDATE_TRANSACTOR,
  UPDATE_DATA,
  GET_LIST_START,
  GET_LIST_DONE,
  UPDATE_IS_TEMPLATE,
  RESET,
  CREATE_LIST_START,
  CREATE_LIST_DONE,
  CREATE_LIST_FAIL,
} from '../constants';

export const defaultState = {
  title: '',
  description: '',
  isTemplate: false,
  transactor: '',
  data: {},
  ui: {
    isLoading: false,
  },
};

function builder(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_IS_TEMPLATE:
      return {
        ...state,
        isTemplate: action.payload,
      };
    case UPDATE_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case UPDATE_TRANSACTOR:
      return {
        ...state,
        transactor: action.payload.transactor,
        data: action.payload.data,
      };
    case UPDATE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case GET_LIST_START:
      return {
        ...state,
        ui: {
          isLoading: true,
        },
      };
    case GET_LIST_DONE:
      return {
        ...state,
        title: action.payload.title,
        description: action.payload.description,
        isTemplate: action.payload.isTemplate,
        transactor: action.payload.transactor,
        data: action.payload.data,
        ui: {
          isLoading: false,
        },
      };
    case CREATE_LIST_START:
      return {
        ...state,
        ui: {
          isLoading: true,
        },
      };
    case CREATE_LIST_DONE:
    case CREATE_LIST_FAIL:
      return {
        ...state,
        ui: {
          isLoading: false,
        },
      };
    case RESET:
      return defaultState;
    default:
      return state;
  }
}

export default builder;
