export default function ({ action, state }) {
  return {
    ...state,
    donors: [],
    searchData: action.payload,
    ui: {
      ...state.ui,
      loading: true,
      noResultsFound: false,
    },
  };
}
