import { map, reduce } from 'lodash';

export function transformPositionsResponseForUI({ data }) {
  return reduce(data, (result, item) => {
    const { record_type, user_positions } = item || {};
    const formatData = map(user_positions, (position) => {
      const { record_id, position_type } = position || {};

      return {
        recordType: record_type,
        recordId: record_id,
        position: position_type,
      };
    }) || [];

    result.push(...formatData);

    return result;
  }, []);
}
