import { isEmpty, pickBy } from 'lodash';

import { formatDate } from 'utils/date';
import { camelizeKeys } from 'utils/object';
import { getAddress } from 'components/core/Profile/components/Cards/Positions/utils';

import transformAdvancedFiltersForBE from './transformAdvancedFiltersForBE';

export function transformPayloadForBE({ searchTerm, searchParams, page, perPage }) {
  const {
    states = [],
    tags = [],
    startDate,
    endDate,
    slugs,
    advancedFilters = {},
  } = searchParams;

  return {
    search: pickBy({
      terms: searchTerm,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      states: states.map(({ value }) => value),
      search_slugs: slugs,
      tag_ids: tags.map(({ value }) => value),
      page,
      advanced_filters: transformAdvancedFiltersForBE(advancedFilters),
      per_page: perPage,
    }),
  };
}

const transformChairmanData = (chairmanData) => {
  if (isEmpty(chairmanData)) return undefined;

  const { id, party_short, party_slug, universal_moniker } = chairmanData;

  return {
    id,
    partyShort: party_short,
    partySlug: party_slug,
    label: universal_moniker,
  };
};

const transformPersonTitle = (personTitle) => {
  if (isEmpty(personTitle)) return undefined;

  const { branch, role, title, organizations } = personTitle;

  return {
    title,
    role,
    organizations,
    additionalInfo: { branch },
  };
};

const transformPersonPhone = (personPhone) => {
  if (isEmpty(personPhone)) return undefined;

  const { area_code, phone_number } = personPhone;

  return {
    areaCode: area_code,
    phoneNumber: phone_number,
  };
};

const transformPersonOffice = personOffice => getAddress(camelizeKeys(personOffice));

const transformResultForUI = (result) => {
  const { options } = result;
  const {
    party_short,
    party_slug,
    display_state,
    state,
    photo_url,
    boss_name,
    works_for_label,
    sending_office,
    recipient_name,
    text,
    snippet,
    summary,
    document,
    synopsis,
    agency,
    agency_name,
    description,
    category,
    governor_name,
    organization,
    primary_phone,
    primary_email,
    offices = [],
    phones = [],
    agencies_titles,
    code_citation,
    code_citation_url,
    latest_publication_date,
    publication_date,
    latest_publication_url,
    boss_party_slug,
    boss_party,
    staffable_id,
    primary_address_district,
    chairman_data,
    posted_at,
    account_screen_name,
    social_media_lists,
    post_url,
    email,
    office,
    phone,
    full_name,
    person_party,
    person_email,
    person_title,
    person_office,
    person_phone,
    ...restOptions
  } = options || {};

  result.options = {
    ...restOptions,
    offices,
    phones,
    state: display_state || state,
    partySlug: party_slug,
    partyShort: party_short || person_party,
    photoUrl: photo_url,
    primaryAddressDistrict: primary_address_district,
    primaryPhone: primary_phone,
    primaryEmail: primary_email,
    bossName: boss_name || works_for_label,
    bossPartySlug: boss_party_slug || boss_party,
    bossId: staffable_id,
    organization,
    targetLabel: organization || recipient_name || sending_office || agency || agency_name || category || governor_name,
    text: snippet || text || summary || document || synopsis || description,
    agency: agencies_titles,
    codeCitation: code_citation,
    codeCitationUrl: code_citation_url,
    latestPublicationDate: latest_publication_date || publication_date,
    latestPublicationUrl: latest_publication_url,
    chairman: transformChairmanData(chairman_data),
    postedAt: posted_at,
    accountScreenName: account_screen_name,
    socialMediaLists: social_media_lists,
    postUrl: post_url,
    fullName: full_name,
    position: transformPersonTitle(person_title),
    email: email || person_email,
    office: office || transformPersonOffice(person_office),
    phone: phone || transformPersonPhone(person_phone),
  };

  return result;
};

const transformPaginationForUI = pagination => ({
  currentPage: pagination.current_page,
  perPage: pagination.per_page,
  totalPages: pagination.total_pages,
  totalCount: pagination.total_count,
});

export function transformResponseForUI(response) {
  const { data: results, pagination } = response;

  return {
    results: results.map(transformResultForUI),
    pagination: transformPaginationForUI(pagination),
  };
}
