import { map, isNil, isEmpty, omitBy } from 'lodash';
import moment from 'moment/moment';

import { CSV_ID } from 'utils/constants/export';
import { submitExportReport } from 'redux/exportReport/actions';
import { exportCSVBlob } from 'redux/exportReport/utils';
import { FULL_DATE_FORMAT } from 'utils/constants/date';

import { getExportInfo } from '../utils';
import constants from '../constants';

export function submitExport(payload = {}) {
  const { items, searchData, exportOption } = payload;
  const exportIds = map(items, 'id');
  const searchForm = !isEmpty(searchData) ? searchData : undefined;

  const data = {
    strategy_slug: constants.STRATEGY_SLUG_FOR_EXPORT,
    'strategy_inputs[ids]': exportIds,
    'strategy_inputs[file_extension]': exportOption || CSV_ID,
    ...omitBy(getExportInfo(searchForm), isNil),
  };

  function onSuccessCustom(responseData) {
    return exportCSVBlob(
      responseData,
      `Federal Legislators ${moment().format(FULL_DATE_FORMAT)}.csv`,
    );
  }

  return submitExportReport({ ...payload, data, onSuccessCustom });
}
