import { groupBy, map, mapValues, isEmpty, keyBy } from 'lodash';

const transformEachRecordForBE = (value, key) => ({
  record_type: key,
  record_ids: map(value, 'id'),
});

export const transformRecordsForBE = (records) => {
  const groupedValues = groupBy(records, 'recordType');

  return map(groupedValues, transformEachRecordForBE);
};

const transformSubscription = ({ subscription }) => {
  const { frequency, subscribable_data } = subscription || {};
  const { send_week_ahead_email } = subscribable_data || {};

  return ({
    ...subscription,
    sendWeekAheadEmail: send_week_ahead_email,
    isTracking: !isEmpty(frequency),
  });
};

const transformRecordTypeSubscriptions = ({ subscriptions }) => {
  const groupedSubscriptions = keyBy(subscriptions, 'record_id');

  return mapValues(groupedSubscriptions, transformSubscription);
};

export const transformSubscriptionsForUI = (subscriptions) => {
  const groupedSubscriptions = keyBy(subscriptions, 'record_type');

  return mapValues(groupedSubscriptions, transformRecordTypeSubscriptions);
};

const extractIdsFromRecords = records => map(records, 'id');

export const transformFetchedRecords = (records) => {
  const recordsObject = groupBy(records, 'recordType');

  return mapValues(recordsObject, extractIdsFromRecords);
};
