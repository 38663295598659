import { getLookupRequestParams } from 'utils/lookup';

import { transformGraphForUI, transformOptionsForBE } from '../utils';
import constants from '../constants';

export function fetchBillsGraphs(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILLS_GRAPH });

    const url = '/api_web/federal/bills/aggregations';
    const dataPayload = { url, ...payload };
    const { data } = getLookupRequestParams(dataPayload, transformOptionsForBE);
    const ajax = $.post(url, data);

    return Promise.resolve(ajax).then(response =>
      dispatch({
        type: constants.RECEIVE_BILLS_GRAPH_DONE,
        payload: transformGraphForUI(response),
      }));
  };
}
