import filterConstants from 'redux/appliedSearchFilters/constants';

import constants from '../../constants';

const resetRegulations = () => (dispatch) => {
  dispatch({ type: filterConstants.RESET_APPLIED_FILTERS });

  return dispatch({ type: constants.RESET_REGULATIONS });
};

export default resetRegulations;
