import { isNil, flattenDeep, isArray, map, toString } from 'lodash';

import { isJSON } from 'utils/string';
import {
  DOCUMENT_TYPES,
  responseTrackingTypes,
} from 'components/Dashboard/common/FeedPage/utils/constants';
import {
  STAFFER_OFFICE_TYPE,
  STATE_STAFFER_OFFICE_TYPE,
  KEYWORD_SECTION,
  BILLS_REGULATIONS_SECTION,
  COMMITTEE_SECTION,
  LEGISLATOR_SECTION,
  NEWSFEED_SECTION,
  WORKSPACE_SECTION,
  WORKSPACE_TYPE,
} from 'redux/dashboardV2/constants';
import {
  FEDERAL_BILL_ITEM_TYPE,
  FEDERAL_COMMITTEE_ITEM_TYPE,
  FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
  FEDERAL_REGULATION_ITEM_TYPE,
  KEYWORD_ITEM_TYPE,
  STATE_BILL_ITEM_TYPE,
  STATE_COMMITTEE_ITEM_TYPE,
  STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE,
  STATE_REGULATION_ITEM_TYPE,
  NEWSFEED_ITEM_TYPE,
  WORKSPACE_ITEM_TYPE,
} from 'utils/constants/itemTypes';

export function getSectionType(type, documentType) {
  const sections = {
    [DOCUMENT_TYPES.FEDERAL_BILL]: BILLS_REGULATIONS_SECTION,
    [DOCUMENT_TYPES.FEDERAL_COMMITTEE]: COMMITTEE_SECTION,
    [DOCUMENT_TYPES.FEDERAL_LEGISLATOR]: LEGISLATOR_SECTION,
    [DOCUMENT_TYPES.FEDERAL_REGISTER_REGULATION]: BILLS_REGULATIONS_SECTION,
    [DOCUMENT_TYPES.STATE_BILL]: BILLS_REGULATIONS_SECTION,
    [DOCUMENT_TYPES.STATE_LEGISLATOR]: LEGISLATOR_SECTION,
    [DOCUMENT_TYPES.STATE_REGULATION]: BILLS_REGULATIONS_SECTION,
    [DOCUMENT_TYPES.STATE_COMMITTEE]: COMMITTEE_SECTION,
    [DOCUMENT_TYPES.NEWS_SEARCH]: NEWSFEED_SECTION,
    keyword: KEYWORD_SECTION,
    workspace: WORKSPACE_SECTION,
  };

  return sections[documentType] || sections[type] || '';
}

const getTypeByModel = (modelType) => {
  const types = {
    Committee: STAFFER_OFFICE_TYPE,
    Legislator: responseTrackingTypes.FEDERAL_LEGISLATOR_STAFFER,
    'StateLegislation::Legislator': responseTrackingTypes.STATE_LEGISLATOR_STAFFER,
    'StateLegislation::Committee': STATE_STAFFER_OFFICE_TYPE,
  };

  return types[modelType];
};

export const getType = (subscribableType, modelType) => {
  const types = {
    [STAFFER_OFFICE_TYPE]: getTypeByModel(modelType),
    [STATE_STAFFER_OFFICE_TYPE]: getTypeByModel(modelType),
  };

  return types[subscribableType] || subscribableType;
};

function getModelTypeUrl(id, modelType) {
  const modelUrls = {
    Committee: `/federal/committees/${id}`,
    Legislator: `/federal/legislators/${id}`,
    'StateLegislation::Legislator': `/state/legislators/${id}`,
    'StateLegislation::Committee': `/state/committees/${id}`,
  };

  return modelUrls[modelType] || null;
}

export function getSectionUrl(id, subscribableType, modelType) {
  if (isNil(id) || isNil(subscribableType)) {
    return null;
  }

  const sectionUrls = {
    bill: `/federal/bills/${id}`,
    state_bill: `/state/bills/${id}`,
    federal_register_regulation: `/federal/regulations/${id}`,
    state_regulation: `/state/regulations/${id}`,
    committee: `/federal/committees/${id}`,
    [STAFFER_OFFICE_TYPE]: getModelTypeUrl(id, modelType),
    [STATE_STAFFER_OFFICE_TYPE]: getModelTypeUrl(id, modelType),
  };

  return sectionUrls[subscribableType];
}

function getModelTypeItem(modelType) {
  const { [modelType]: sectionType } = {
    Committee: FEDERAL_COMMITTEE_ITEM_TYPE,
    Legislator: FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    'StateLegislation::Legislator': STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    'StateLegislation::Committee': STATE_COMMITTEE_ITEM_TYPE,
  };

  return sectionType || null;
}

export function getItemType(type = '', modelType = '') {
  const { [type]: sectionType } = {
    keyword: KEYWORD_ITEM_TYPE,
    federal_bill: FEDERAL_BILL_ITEM_TYPE,
    bill: FEDERAL_BILL_ITEM_TYPE,
    federal_legislator_bill: FEDERAL_REGULATION_ITEM_TYPE,
    state_bill: STATE_BILL_ITEM_TYPE,
    federal_register_regulation: FEDERAL_REGULATION_ITEM_TYPE,
    state_regulation: STATE_REGULATION_ITEM_TYPE,
    [STAFFER_OFFICE_TYPE]: getModelTypeItem(modelType),
    [STATE_STAFFER_OFFICE_TYPE]: getModelTypeItem(modelType),
    committee: FEDERAL_COMMITTEE_ITEM_TYPE,
    news: NEWSFEED_ITEM_TYPE,
    [WORKSPACE_TYPE]: WORKSPACE_ITEM_TYPE,
  };

  return sectionType || '';
}

export function formatToken(token) {
  const items = isJSON(token) ? JSON.parse(token) : token;

  return isArray(items) ? map(items, toString) : toString(items);
}

export function formatTokens(tokens) {
  return flattenDeep(map(tokens, formatToken));
}

export function getSubscriptionData(payload) {
  const {
    frequency,
    isSaved,
    priority,
    modelId,
    token,
    sendWeekAheadEmail,
    withWeekAheadEmail,
  } = payload || {};

  return {
    frequency,
    priority,
    is_saved: isSaved,
    token: modelId || token,
    ...(withWeekAheadEmail && { send_week_ahead_email: sendWeekAheadEmail }),
  };
}
