import constants from '../constants';
import { transformSupportedStatesForUI } from '../utils';

export function fetchSupportedStates() {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_SUPPORTED_STATES });

    const url = '/api_web/state/staffers/states';
    const ajax = $.get(url);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_SUPPORTED_STATES_DONE,
        payload: transformSupportedStatesForUI(response),
      });
    }

    return Promise.resolve(ajax)
      .then(onSuccess);
  };
}

fetchSupportedStates();
