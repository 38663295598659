import isEmpty from 'lodash/isEmpty';

import { getValue } from 'utils/transformers/advancedSearch';
import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import { transformOptionsForBE, transformSearchesForUI } from '../utils';
import constants from '../constants';

/** Advanced search actions */
export function loadStaffersRecentSearch(params, stafferData) {
  const recentSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return dispatch => dispatch(loadRecentSearches(
    recentSearch,
    data => transformSearchesForUI(data, stafferData)),
  );
}

export function loadStaffersSavedSearches(params, stafferData) {
  const savedSearch = { ...params, strategy_slug: constants.STRATEGY_SLUG };

  return loadSavedSearches(
    savedSearch,
    data => transformSearchesForUI(data, stafferData),
  );
}

export function saveAdvancedSearchItem(data = {}, stafferData, isSaved = true) {
  const advancedSearchData = {
    id: data.id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: transformOptionsForBE(data),
      saved: isSaved,
    },
  };

  if (!isSaved) {
    return onSaveRecentItem(
      advancedSearchData,
      params => loadStaffersRecentSearch(params, stafferData),
    );
  }

  return onSaveItem(
    advancedSearchData,
    params => loadStaffersRecentSearch(params, stafferData),
  );
}

export function removeAdvancedSearchItem(id, stafferData = {}) {
  return onRemoveItem(
    id,
    params => loadStaffersSavedSearches(params, stafferData));
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, stafferData = {}, isClickedItem = false } = payload;

    if (!isClickedItem && !isEmpty(transformOptionsForBE(searchData))) {
      return dispatch(saveAdvancedSearchItem(searchData, stafferData, false));
    }

    return null;
  };
}
