import { isEmpty, omitBy, map } from 'lodash';

import { transformSelectValues } from 'utils/transformers/advancedSearch';

export function transformStaffersForUI(staffersData = {}) {
  const { data } = staffersData || {};

  return {
    staffers: map(data, (staffer = {}) => {
      const {
        alias_name,
        full_name,
        responsibilities,
        is_leadership,
        ...restProps
      } = staffer;
      return {
        ...restProps,
        name: alias_name || full_name,
        issues: responsibilities,
        isLeadership: is_leadership,
      };
    }),
  };
}

export function transformStaffersForBE(payload) {
  const { id, keywordSearch, stafferTitles, issuesArea, ...restProps } = payload || {};

  return {
    ...restProps,
    search_form: omitBy({
      legislator_ids: [id],
      lookup_keyword: keywordSearch,
      titles: transformSelectValues(stafferTitles),
      priorities: transformSelectValues(issuesArea),
    }, isEmpty),
  };
}
