import { NONE_ID } from 'components/core/FrequencyOptions/constants';

import constants from './constants';

export const DEFAULT_STATE = {
  profile: {
    legislator: {},
    keyDetails: {},
  },
  defaultSubscription: NONE_ID,
  trackings: [],
  tags: [],
  staffers: {
    allItems: [],
  },
  news: {
    allItems: [],
  },
  largestEmployers: {
    allItems: [],
  },
  legislatorBills: {
    allItems: [],
  },
  districtInfo: {
    id: '',
    name: '',
    cookPVI: {},
    education: [],
    mapLocation: {},
    householdIncome: {},
    genders: [],
    racialDemographics: [],
    industries: [],
  },
  ui: {
    isLoadingProfile: true,
    isLoadingStaffers: true,
    isLoadingNews: true,
    isLoadingDistrictInfo: true,
    isLoadingLargestEmployers: true,
    isLoadingLegislatorBills: true,
    isLoadingSubscription: true,
    isLoadingTags: true,
    isLoadingMyFeedDocuments: true,
  },
  sharings: [],
};

export default function profileReducers(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_PROFILE:
      return {
        ...state,
        profile: DEFAULT_STATE.profile,
        ui: { ...state.ui, isLoadingProfile: true },
      };
    case constants.RECEIVE_PROFILE_DONE:
      return {
        ...state,
        profile: action.payload,
        ui: { ...state.ui, isLoadingProfile: false },
      };
    case constants.FETCH_STAFFERS:
      return {
        ...state,
        staffers: {
          ...DEFAULT_STATE.staffers,
          paginator: state.staffers.paginator,
        },
        ui: { ...state.ui, isLoadingStaffers: true },
      };
    case constants.RECEIVE_STAFFERS_DONE:
      return {
        ...state,
        staffers: action.payload,
        ui: { ...state.ui, isLoadingStaffers: false },
      };
    case constants.FETCH_NEWS:
      return {
        ...state,
        news: DEFAULT_STATE.staffers,
        ui: { ...state.ui, isLoadingNews: true },
      };
    case constants.RECEIVE_NEWS_DONE:
      return {
        ...state,
        news: action.payload,
        ui: { ...state.ui, isLoadingNews: false },
      };
    case constants.FETCH_DISTRICT_INFO:
      return {
        ...state,
        districtInfo: DEFAULT_STATE.districtInfo,
        ui: { ...state.ui, isLoadingDistrictInfo: true },
      };
    case constants.RECEIVE_DISTRICT_INFO:
      return {
        ...state,
        districtInfo: action.payload,
        ui: { ...state.ui, isLoadingDistrictInfo: false },
      };
    case constants.RESET_DISTRICT_INFO:
      return {
        ...state,
        ui: { ...state.ui, isLoadingDistrictInfo: DEFAULT_STATE.ui.isLoadingDistrictInfo },
        districtInfo: DEFAULT_STATE.districtInfo,
      };
    case constants.FETCH_LARGEST_EMPLOYEE:
      return {
        ...state,
        largestEmployers: DEFAULT_STATE.largestEmployers,
        ui: { ...state.ui, isLoadingLargestEmployers: true },
      };
    case constants.RECEIVE_LARGEST_EMPLOYEE_DONE:
      return {
        ...state,
        largestEmployers: action.payload,
        ui: { ...state.ui, isLoadingLargestEmployers: false },
      };
    case constants.RESET_PROFILE:
      return {
        ...state,
        ...DEFAULT_STATE,
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscription: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        trackings: action.payload,
        ui: { ...state.ui, isLoadingSubscription: false },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...state,
        sharings: action.payload,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.UPDATE_PROFILE_MY_FEED:
      return {
        ...state,
        sharings: action.payload,
      };
    default:
      return state;
  }
}
