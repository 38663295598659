import { combineReducers } from 'redux';
import size from 'lodash/size';

import { PAC_PAGE_SIZE } from 'components/Moneyball/PACRankingPages/Lookup/utils';
import { DISBURSEMENTS } from 'components/Moneyball/utils/constants';

import constants from './constants';

export const defaultState = {
  error: false,
  filtersOptions: {},
  filters: {
    transaction: DISBURSEMENTS,
    sector: { label: 'All', value: undefined },
    industry: null,
    category: null,
    cycle: null,
    subtype: null,
  },
  data: {
    totals: null,
    graph: null,
    lookup: null,
  },
  ui: {
    isInfiniteDisabled: false,
    isFiltersLoading: false,
    isDataLoading: false,
    isLoading: false,
  },
};

function lookUp(state = defaultState, action) {
  switch (action.type) {
    case constants.DATA_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          totals: null,
          graph: null,
        },
        ui: {
          ...state.ui,
          isDataLoading: true,
        },
      };
    case constants.DATA_SUCCESS:
      return {
        ...state,
        error: action.error,
        data: {
          ...state.data,
          ...action.payload,
        },
        ui: {
          ...state.ui,
          isDataLoading: false,
        },
      };
    case constants.DATA_LOOKUP_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          lookup: action.isInfinite ? state.data.lookup : null,
        },
        ui: {
          ...state.ui,
          isLoading: !action.isInfinite,
        },
      };
    case constants.DATA_LOOKUP_SUCCESS:
      return {
        ...state,
        error: action.error,
        data: {
          ...state.data,
          lookup: action.isInfinite ? [...state.data.lookup, ...action.payload] : action.payload,
        },
        ui: {
          ...state.ui,
          isLoading: false,
          isInfiniteDisabled: size(action.payload) < PAC_PAGE_SIZE,
        },
      };
    case constants.FILTERS_REQUEST:
      return {
        ...state,
        filtersOptions: {},
        ui: {
          ...state.ui,
          isFiltersLoading: true,
        },
      };
    case constants.FILTERS_SUCCESS:
      return {
        ...state,
        error: action.error,
        filtersOptions: action.payload,
        ui: {
          ...state.ui,
          isFiltersLoading: false,
        },
      };
    case constants.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        ui: {
          ...state.ui,
          isLoading: true,
          isDataLoading: true,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  lookUp,
});
