import { size } from 'lodash';

function getPdfUrl(response) {
  const { pdf_url: paths } = response;

  return size(paths) > 0
    ? paths[0]
    : null;
}

export function transformSummaryForUI(response) {
  return {
    ...response,
    pdfUrl: getPdfUrl(response),
  };
}
