import moment from 'moment/moment';

import { TASK_FULL_DATE_FORMAT } from 'utils/constants/date';
import { formatTime, formatDateBy, formatDate } from 'utils/date';

import { transformTaskStatusForBE } from '../toBE';

const TASK_STATUS_TO_FE = {
  open: 'open',
  in_progress: 'inProgress',
  closed: 'closed',
};

export const transformTaskStatusForFeed = (status) => {
  const transformedStatus = TASK_STATUS_TO_FE[status];

  !transformedStatus && window.Sentry.captureMessage(`Unknown status ${status} received from BE.`);

  return transformedStatus || TASK_STATUS_TO_FE.open;
};

export const transformTaskForFeed = ({ assignee = {}, assigner = {}, ...task } = {}) => ({
  id: task.id,
  assignee: {
    id: assignee.id,
    fullName: assignee.full_name,
    photoUrl: assignee.profile_img_url,
  },
  assigner: {
    id: assigner.id,
    fullName: assigner.full_name,
    photoUrl: assigner.profile_img_url,
  },
  dueDateTime: task.due_datetime && formatDateBy(task.due_datetime, TASK_FULL_DATE_FORMAT),
  priority: task.priority,
  status: transformTaskStatusForFeed(task.status),
});

export const transformTaskForEvent = ({ assignee = {}, assigner = {}, ...task } = {}) => ({
  id: task.id,
  assignee: {
    id: assignee.id,
    full_name: assignee.fullName,
    profile_img_url: assignee.photoUrl,
  },
  assigner: {
    id: assigner.id,
    full_name: assigner.fullName,
    profile_img_url: assigner.photoUrl,
  },
  due_datetime: task.dueDateTime && moment(task.dueDateTime, TASK_FULL_DATE_FORMAT).format(),
  priority: task.priority,
  status: transformTaskStatusForBE(task.status),
});

export const transformTaskForForm = (task = { assignee: {} }) => ({
  assignee: {
    value: task.assignee.id,
    label: task.assignee.full_name,
  },
  status: transformTaskStatusForFeed(task.status),
  dueDate: task.due_datetime ? formatDate(moment(task.due_datetime)) : '',
  time: task.due_datetime ? formatTime(moment(task.due_datetime)) : '',
  priority: task.priority,
});
