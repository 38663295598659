import {
  uniq,
  reduce,
  map,
  includes,
  filter,
  forEach,
  values,
} from 'lodash';

import { pluralize } from 'utils/string';
import { DOCUMENTS } from 'components/core/Select/components/RichOption/constants.js';

import { RECORD_TYPES } from '../../recordTypes';

export const transformCardLinksForFeed = (links = {}) => {
  const recordTypes = uniq(map(links, 'record_type'));

  const transformedLinks = reduce(
    recordTypes,
    (acc, slug) => ({
      ...acc,
      [slug]: [],
    }),
    {}
  );

  forEach(links, (link = {}) => {
    const slug = link.record_type;

    transformedLinks[slug].push({
      label: link.record_title,
      token: link.record_token,
      value: RECORD_TYPES[slug].path(link),
      externalUrl: link.record_external_url,
      id: link.record_id,
    });
  });

  return map(recordTypes, (slug => ({
    label: pluralize(RECORD_TYPES[slug].label, RECORD_TYPES[slug].pluralLabel, transformedLinks[slug].length),
    type: slug,
    value: transformedLinks[slug],
  })));
};

export const transformCardLinksForEvent = (links = []) => reduce(links, (result, link) => [
  ...result,
  ...reduce(link.value, (cardLinks, value) => [
    ...cardLinks,
    {
      record_type: link.type,
      record_title: value.label,
      record_token: value.token,
      record_id: value.id,
      record_external_url: value.externalUrl,
    },
  ], []),
], []);

export const recordTypesByField = {
  people: ['federal_staffer', 'federal_legislator', 'state_legislator', 'colleague', 'stakeholder'],
  documents: values(DOCUMENTS),
  bills: ['federal_bill', 'state_bill'],
  regulations: ['state_regulation', 'federal_regulation'],
  committees: ['federal_committee', 'state_committee'],
  hillDayActivity: ['hill_day', 'hill_day_meeting', 'grassroots_form_submission'],
};

const filterLinks = (cardLinks, field) => map(
  filter(cardLinks, ({ record_type: recordType }) => includes(recordTypesByField[field], recordType)),
  ({ record_id, record_type }) => ({ record_id, record_type })
);

export const transformCardLinksForForm = (cardLinks = []) => ({
  people: filterLinks(cardLinks, 'people'),
  bills: filterLinks(cardLinks, 'bills'),
  regulations: filterLinks(cardLinks, 'regulations'),
  committees: filterLinks(cardLinks, 'committees'),
  documents: filterLinks(cardLinks, 'documents'),
  hillDayActivity: filterLinks(cardLinks, 'hillDayActivity'),
});
