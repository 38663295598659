const PREFIX = 'SOCIAL_MEDIA';

const constants = {
  FETCH_POSTS: `${PREFIX}/FETCH_POSTS`,
  RECEIVE_POSTS_DONE: `${PREFIX}/RECEIVE_POSTS_DONE`,
  RECEIVE_POSTS_FAILURE: `${PREFIX}/RECEIVE_POSTS_FAILURE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  SAVE_TAGS_ITEM: `${PREFIX}/SAVE_TAGS_ITEM`,
  UPDATE_TAG_ITEM: `${PREFIX}/UPDATE_TAG_ITEM`,
  UPDATE_TAG_ITEM_DONE: `${PREFIX}/UPDATE_TAG_ITEM_DONE`,
  BULK_UPDATE_DATA: `${PREFIX}/BULK_UPDATE_DATA`,
  BULK_SHARE_TO_MY_FEED: `${PREFIX}/BULK_SHARE_TO_MY_FEED`,
  BULK_SHARE_TO_MY_FEED_DONE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_DONE`,
  BULK_SHARE_TO_MY_FEED_FAILURE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_FAILURE`,
};

export const PER_PAGE = 100;

export default constants;
