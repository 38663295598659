import { getLookupRequestParams } from 'utils/lookup';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';

import constants from '../constants';
import { transformExecutivesForUI, transformOptionsForBE } from '../utils/transformers';
import { STATE_EXECUTIVES_URL } from '../utils/constants';

export * from './advancedSearch';
export * from './export';

export function fetchExecutives(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_EXECUTIVES });

    const { searchData = {}, clearAll, toResetSelectedItems } = payload;
    const { isSearch, data, sortData } = getLookupRequestParams(payload, transformOptionsForBE);

    const ajax = $.post(STATE_EXECUTIVES_URL, data);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_EXECUTIVES_DONE,
        payload: transformExecutivesForUI(response),
        paginator: transformPaginator(response),
        toResetSelectedItems: isSearch || toResetSelectedItems,
        sort: transformSort(sortData),
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(saveAppliedFiltersToProps);
  };
}

export function resetExecutives() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_EXECUTIVES });
  };
}
