import constants from './constants';

const defaultState = {
  watchlist: {
    state_leg_issue_areas: [],
  },
  selectedBill: {
    state: {},
    actions: [],
    sponsors: [],
    cosponsors: [],
    committees: [],
  },
  profile: {
    id: 0,
    lastAction: {},
    keyDetails: {},
  },
  cosponsors: {},
  officialReports: {},
  committeesAssignments: [],
  timeline: [],
  sponsorsAndCosponsors: {
    allItems: [],
    paginator: {},
    republican: {
      numCosponsors: 0,
      partyShort: 'R',
      partySlug: 'republican',
    },
    democratic: {
      numCosponsors: 0,
      partyShort: 'D',
      partySlug: 'democratic',
    },
  },
  ui: {
    isLoadingWatchlist: true,
    committeesStatus: 'loading',
    profileStatus: 'loading',
    timelineStatus: 'loading',
    cosponsorsStatus: 'loading',
    sponsorsStatus: 'loading',
    reportsStatus: 'loading',
  },
};

export default function stateLegWatchlist(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_WATCHLIST:
      return {
        ...state,
        watchlist: defaultState.watchlist,
        ui: { ...state.ui, isLoadingWatchlist: true },
      };
    case constants.RECEIVE_WATCHLIST:
      return {
        ...state,
        watchlist: action.payload,
        ui: { ...state.ui, isLoadingWatchlist: false },
      };
    case constants.SELECT_BILL:
      return {
        ...state,
        selectedBill: {
          ...state.selectedBill,
          ...action.payload.bill,
        },
      };
    case constants.DESELECT_BILL:
      return {
        ...state,
        selectedBill: defaultState.selectedBill,
      };
    case constants.FETCH_BILL:
      return {
        ...state,
        ui: { ...state.ui, profileStatus: 'loading' },
      };
    case constants.RECEIVE_BILL:
      return {
        ...state,
        profile: action.payload,
        ui: { ...state.ui, profileStatus: 'idle' },
      };
    case constants.FETCH_SPONSORS_AND_COSPONSORS:
      return {
        ...state,
        sponsorsAndCosponsors: {
          ...defaultState.sponsorsAndCosponsors,
        },
        ui: { ...state.ui, cosponsorsStatus: 'loading' },
      };
    case constants.RECEIVE_SPONSORS_AND_COSPONSORS:
      return {
        ...state,
        sponsorsAndCosponsors: action.payload,
        ui: { ...state.ui, cosponsorsStatus: 'idle' },
      };
    case constants.FETCH_BILL_TIMELINE:
      return {
        ...state,
        ui: { ...state.ui, timelineStatus: 'loading' },
      };
    case constants.RECEIVE_BILL_TIMELINE:
      return {
        ...state,
        timeline: action.payload,
        ui: { ...state.ui, timelineStatus: 'idle' },
      };
    case constants.FETCH_BILL_COMMITTEES:
      return {
        ...state,
        ui: { ...state.ui, committeesStatus: 'loading' },
      };
    case constants.RECEIVE_BILL_COMMITTEES:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, committeesStatus: 'idle' },
      };
    case constants.FETCH_BILL_REPORTS:
      return {
        ...state,
        ui: { ...state.ui, reportsStatus: 'loading' },
      };
    case constants.RECEIVE_BILL_REPORTS:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, reportsStatus: 'idle' },
      };
    case constants.FETCH_BILL_COSPONSORS:
      return {
        ...state,
        ui: { ...state.ui, cosponsorsStatus: 'loading' },
      };
    case constants.RECEIVE_BILL_COSPONSORS:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, cosponsorsStatus: 'idle' },
      };
    case constants.BILL_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, profileStatus: 'error' },
      };
    case constants.FETCH_SPONSORS_ERROR:
      return {
        ...state,
        ui: { ...state.ui, cosponsorsStatus: 'error' },
      };
    case constants.FETCH_TIMELINE_ERROR:
      return {
        ...state,
        ui: { ...state.ui, timelineStatus: 'error' },
      };
    case constants.FETCH_REPORTS_ERROR:
      return {
        ...state,
        ui: { ...state.ui, reportsStatus: 'error' },
      };
    case constants.FETCH_COMMITTEES_ERROR:
      return {
        ...state,
        ui: { ...state.ui, committeesStatus: 'error' },
      };
    default:
      return state;
  }
}
