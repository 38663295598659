const PREFIX = 'MONEYBALL_PAC_RANKING';

const constants = {
  DATA_REQUEST: `${PREFIX}/DATA_REQUEST`,
  DATA_SUCCESS: `${PREFIX}/DATA_SUCCESS`,
  DATA_LOOKUP_REQUEST: `${PREFIX}/DATA_LOOKUP_REQUEST`,
  DATA_LOOKUP_SUCCESS: `${PREFIX}/DATA_LOOKUP_SUCCESS`,
  FILTERS_REQUEST: `${PREFIX}/FILTERS_REQUEST`,
  FILTERS_SUCCESS: `${PREFIX}/FILTERS_SUCCESS`,
  UPDATE_FILTERS: `${PREFIX}/UPDATE_FILTERS`,
};

export default constants;
