import {
  createAction,
  ajaxRequestToPromise,
  handleSuccess,
  handleFailure,
} from 'redux/utils';

import constants from './constants';

export const loadServiceTermsStarted = createAction(constants.LOAD_SERVICE_TERMS_STARTED);
export const loadServiceTermsFail = createAction(constants.LOAD_SERVICE_TERMS_FAIL);
export const loadServiceTermsDone = createAction(constants.LOAD_SERVICE_TERMS_DONE);
export const acceptServiceTermsStarted = createAction(constants.ACCEPT_SERVICE_TERMS_STARTED);
export const acceptServiceTermsFail = createAction(constants.ACCEPT_SERVICE_TERMS_FAIL);
export const acceptServiceTermsDone = createAction(constants.ACCEPT_SERVICE_TERMS_DONE);

export const fetchServiceTerms = () => (dispatch) => {
  dispatch(loadServiceTermsStarted());

  const url = '/api_web/service_terms';

  return ajaxRequestToPromise($.get(url))
    .then(handleSuccess(dispatch, loadServiceTermsDone))
    .catch(handleFailure(dispatch, loadServiceTermsFail));
};

export const acceptServiceTerms = () => (dispatch, getState) => {
  dispatch(acceptServiceTermsStarted());

  const url = '/api_web/service_terms';

  const { serviceTermId } = getState().serviceTerms;
  const data = { acceptance: { service_term_id: serviceTermId } };

  return ajaxRequestToPromise($.post(url, data))
    .then(handleSuccess(dispatch, acceptServiceTermsDone))
    .catch(handleFailure(dispatch, acceptServiceTermsFail));
};
