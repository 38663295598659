const PREFIX = 'WORKSPACE_SETTINGS';

const constants = {
  FETCH_WORKSPACE: `${PREFIX}/FETCH_WORKSPACE`,
  RECEIVE_WORKSPACE_DONE: `${PREFIX}/RECEIVE_WORKSPACE_DONE`,
  WORKSPACE_NOT_FOUND: `${PREFIX}/WORKSPACE_NOT_FOUND`,
  UPDATE_WORKSPACE: `${PREFIX}/UPDATE_WORKSPACE`,
  UPDATE_WORKSPACE_DONE: `${PREFIX}/UPDATE_WORKSPACE_DONE`,
  NAME_ALREADY_TAKEN: `${PREFIX}/NAME_ALREADY_TAKEN`,
  UPDATE_WORKSPACE_SUBSCRIPTION: `${PREFIX}/UPDATE_WORKSPACE_SUBSCRIPTION`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  DELETE_SUBSCRIPTION: `${PREFIX}/DELETE_SUBSCRIPTION`,
  DELETE_SUBSCRIPTION_DONE: `${PREFIX}/DELETE_SUBSCRIPTION_DONE`,
  DELETE_SUBSCRIPTIONS: `${PREFIX}/DELETE_SUBSCRIPTIONS`,
  DELETE_SUBSCRIPTIONS_DONE: `${PREFIX}/DELETE_SUBSCRIPTIONS_DONE`,
  UPDATE_SUBSCRIPTION: `${PREFIX}/UPDATE_SUBSCRIPTION`,
  UPDATE_SUBSCRIPTION_DONE: `${PREFIX}/UPDATE_SUBSCRIPTION_DONE`,
  UPDATE_SUBSCRIPTIONS: `${PREFIX}/UPDATE_SUBSCRIPTIONS`,
  UPDATE_SUBSCRIPTIONS_DONE: `${PREFIX}/UPDATE_SUBSCRIPTIONS_DONE`,
  UPDATE_COMMITTEE_SUBSCRIPTION_DONE: `${PREFIX}/UPDATE_COMMITTEE_SUBSCRIPTION_DONE`,
  DELETE_COMMITTEE_SUBSCRIPTION_DONE: `${PREFIX}/DELETE_COMMITTEE_SUBSCRIPTION_DONE`,
  FETCH_DEFAULT_FREQUENCY: `${PREFIX}/FETCH_DEFAULT_FREQUENCY`,
  FETCH_DEFAULT_FREQUENCY_DONE: `${PREFIX}/FETCH_DEFAULT_FREQUENCY_DONE`,
  UPDATE_DEFAULT_FREQUENCY: `${PREFIX}/UPDATE_DEFAULT_FREQUENCY`,
  UPDATE_DEFAULT_FREQUENCY_DONE: `${PREFIX}/UPDATE_DEFAULT_FREQUENCY_DONE`,
  RESET_SUBSCRIPTIONS_SETTINGS: `${PREFIX}/RESET_SUBSCRIPTIONS_SETTINGS`,
  RESET_SUBSCRIPTIONS_SETTINGS_DONE: `${PREFIX}/RESET_SUBSCRIPTIONS_SETTINGS_DONE`,
};

export default constants;
