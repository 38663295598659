import { map, filter } from 'lodash';

import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';

import { updateItemSearches, transformSearches } from './utils';
import {
  API_URL,
  FETCH_SEARCHES,
  RECEIVE_SEARCHES,
  DELETE_SEARCHES_ITEM,
  RESET_SEARCHES_TIMERS,
  RECEIVE_SEARCHES_RESULT,
  REFRESH_SEARCHES_RESULTS,
  RECEIVE_SEARCHES_RESULTS,
  SET_ENRICHMENT,
  RESET,
} from './constants';

const fetchSearchesItemResults = ({ id, dispatch }) => {
  const url = `${API_URL}/smart_searches/${id}`;
  const ajax = $.get(url);

  return Promise.resolve(ajax)
    .then(response => dispatch({
      type: RECEIVE_SEARCHES_RESULT,
      payload: updateItemSearches(response),
    }));
};

const fetchSearchesAllResults = ({ ids, dispatch }) => {
  const url = `${API_URL}/smart_searches/jobs`;
  const ajax = $.ajax({
    url,
    type: 'POST',
    data: JSON.stringify({ job_ids: ids }),
    contentType: REQUEST_CONTENT_TYPE,
  });

  return Promise.resolve(ajax)
    .then(({ data }) => dispatch({
      type: RECEIVE_SEARCHES_RESULTS,
      payload: transformSearches(data),
    }));
};

const timers = {};

const pollingSearchesData = ({ dispatch, payload }) => {
  const ids = map(filter(payload, 'disabled'), 'id');

  const poolTimer = (id, ms) => {
    clearTimeout(timers[id]);

    fetchSearchesAllResults({ ids, dispatch });

    timers[id] = setTimeout(() => poolTimer(id, ms), ms);
  };

  poolTimer('lookup', 60000);
};

export const clearTimers = () => {
  Object.values(timers).forEach(id => clearTimeout(id));

  return {
    type: RESET_SEARCHES_TIMERS,
  };
};

export const fetchSearchesData = () => (dispatch) => {
  dispatch({ type: FETCH_SEARCHES });

  const ajax = $.get(`${API_URL}/smart_searches`);

  return Promise.resolve(ajax)
    .then(({ data }) => dispatch({
      type: RECEIVE_SEARCHES,
      payload: transformSearches(data),
    }))
    .then(({ payload }) => {
      pollingSearchesData({ payload, dispatch });
    });
};

export const deleteSearchesItem = id => (dispatch) => {
  const url = `${API_URL}/smart_searches/${id}`;

  const ajax = $.ajax({
    url,
    type: 'DELETE',
  });

  return Promise.resolve(ajax)
    .then(() => dispatch({
      type: DELETE_SEARCHES_ITEM,
      payload: { id },
    }));
};

export const refreshSearchesItem = id => (dispatch) => {
  const url = `${API_URL}/smart_searches/${id}/refresh`;

  const ajax = $.ajax({
    url,
    type: 'PUT',
  });

  return Promise.resolve(ajax)
    .then(() => {
      dispatch({
        type: REFRESH_SEARCHES_RESULTS,
      });

      fetchSearchesItemResults({ id, dispatch });
    });
};

export const enrichmentSearchesItem = id => (dispatch) => {
  const ajax = $.ajax({
    url: `${API_URL}/smart_searches/${id}/contact_enrichment`,
    type: 'POST',
  });

  const onSuccess = () => dispatch({
    type: SET_ENRICHMENT,
    payload: {
      value: true,
      id,
    },
  });

  const onFailure = () => dispatch({
    type: SET_ENRICHMENT,
    payload: {
      value: false,
      id,
    },
  });

  return Promise.resolve(ajax)
    .then(onSuccess)
    .catch(onFailure);
};

export function reset() {
  return (dispatch) => {
    dispatch({
      type: RESET,
    });
  };
}
