import { map, head, reject, upperFirst, omitBy, isEmpty, isNil } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';

import { formatTokens, getItemType, getSectionType, getSectionUrl, getType } from './utils';

export function transformSingleSubscriptionForUI(subscription, documentType, itemData) {
  const {
    token,
    frequency,
    is_disabled,
    is_saved,
    priority,
    required_inputs,
    subscribable_data,
    subscribable_type,
    title,
  } = subscription || {};
  const {
    keyword,
    model_id,
    send_week_ahead_email,
    model_type,
    model_slugs,
  } = required_inputs || subscribable_data || {};
  const { title: itemTitle, name } = itemData || {};
  const subscriptionTitle = title || itemTitle || name;

  return {
    token,
    frequency,
    priority,
    title: upperFirst(subscriptionTitle),
    keyword,
    modelId: model_id,
    modelType: model_type,
    modelSlugs: model_slugs,
    isSaved: Boolean(is_saved),
    type: getType(subscribable_type, model_type),
    sendWeekAheadEmail: Boolean(send_week_ahead_email),
    sectionType: getSectionType(subscribable_type, documentType),
    itemType: getItemType(subscribable_type, model_type),
    url: getSectionUrl(model_id, subscribable_type, model_type),
    isDisabled: is_disabled,
  };
}

export function rejectDisabled(subscriptions) {
  return reject(subscriptions, 'is_disabled');
}

export function transformSubscriptionForUI(item) {
  const { id, document_type: documentType, subscriptions, item_data } = item || {};
  const subscription = head(subscriptions);
  const tokens = map(subscriptions, 'token');
  const isCommittee = [
    DOCUMENT_TYPES.FEDERAL_COMMITTEE,
    DOCUMENT_TYPES.STATE_COMMITTEE,
  ].includes(documentType);

  return {
    itemId: id,
    documentType,
    isCommittee,
    ...transformSingleSubscriptionForUI(subscription, documentType, item_data),
    token: JSON.stringify(tokens),
    ...(isCommittee && {
      listSubscriptions: map(
        rejectDisabled(subscriptions),
        subscriptionItem => transformSingleSubscriptionForUI(subscriptionItem, documentType)
      ),
    }),
  };
}

export function transformNestedSubscriptionsForUI({ data: subscriptions } = {}) {
  return map(rejectDisabled(subscriptions), transformSubscriptionForUI);
}

export function transformSubscriptionsForUI({ data: subscriptions } = {}) {
  return map(rejectDisabled(subscriptions), transformSingleSubscriptionForUI);
}

export function transformSubscriptionForBE(subscription) {
  const {
    isSaved,
    priority,
    frequency,
    sendWeekAheadEmail,
    withWeekAheadEmail,
    keyword,
    modelSlugs,
  } = subscription || {};

  return omitBy({
    frequency,
    ...(isEmpty(keyword) && {
      priority,
      is_saved: isSaved,
      ...(withWeekAheadEmail && { send_week_ahead_email: sendWeekAheadEmail }),
    }),
    ...(!isEmpty(keyword) && {
      subscribable_data: { keyword, item_types: modelSlugs },
    }),
  }, isNil);
}

export function buildBulkUpdatePayloadForBE({
  tokens,
  frequency,
  sendWeekAheadEmail,
  priority,
  isSaved,
  isRead,
}) {
  return omitBy({
    frequency,
    tokens: formatTokens(tokens),
    send_week_ahead_email: sendWeekAheadEmail,
    priority,
    is_saved: isSaved,
    is_read: isRead,
  }, isNil);
}
