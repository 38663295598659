import isNotFoundError from 'utils/http/isNotFoundError';
import notifications from 'components/Local/ExecutiveProfile/utils/notifications';
import { fetchProfileTags } from 'redux/tags/actions';

import { transformLocalExecutive } from './utils/transformers';
import constants, { LOCAL_EXECUTIVE_SLUG } from './constants';

function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function fetchExecutiveProfile(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_EXECUTIVE });

    const { id } = payload;

    const params = {
      id,
      profile_slug: LOCAL_EXECUTIVE_SLUG,
    };

    const url = '/api_web/executive_profile';
    const ajax = $.get(url, params);

    const onSuccess = (person) => {
      dispatch({
        type: constants.RECEIVE_EXECUTIVE_DONE,
        payload: person,
      });
    };

    const onFailure = (error) => {
      notifications.fetchProfileFailed();

      isNotFoundError(error) && dispatch({ type: constants.PROFILE_NOT_FOUND });

      throw error;
    };

    return Promise.resolve(ajax)
      .then(transformLocalExecutive)
      .then(onSuccess)
      .then(() => dispatch(fetchTags(payload)))
      .catch(onFailure);
  };
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}
