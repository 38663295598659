import { omitBy, isEmpty, toString } from 'lodash';

import { TASK_CARD_TYPE } from 'components/Grm2/constants';
import { combineDateTime } from 'utils/date';

import { transformCardForBE } from './cards';

const TASK_STATUS_TO_BE = {
  open: 'open',
  inProgress: 'in_progress',
  closed: 'closed',
};

export const transformTaskStatusForBE = status => TASK_STATUS_TO_BE[status];

export const transformTaskCardForBE = ({ status, assignee, dueDate, time, priority, ...cardProps } = {}) => ({
  card: {
    ...transformCardForBE(cardProps).card,
    card_type: TASK_CARD_TYPE,
    task: omitBy({
      assignee_id: toString(assignee.value),
      due_datetime: dueDate && combineDateTime(dueDate, time),
      priority,
      status: transformTaskStatusForBE(status),
    }, isEmpty),
  },
});
