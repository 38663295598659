import {
  map,
  isNil,
  find,
  some,
  reduce,
  isEqual,
  chunk,
  forEach,
  includes,
  filter,
  isEmpty,
} from 'lodash';

import { fetchMyFeedDocuments } from 'redux/events/actions';
import ActivityEventsPublisher from 'components/Grm2/events/publisher';
import notifications from 'components/Grm2/notifications';
import { ACITIVITY_FOR_NAVIGATION_NAMESPACE } from 'components/Grm2/events/constants';

import { updateCardsLinkPreview } from './linksPreview';
import { fetchCustomFields } from './customFields';
import constants, { TASK_STATUSES, MY_FEED_CARD_LIMIT } from '../constants';
import {
  applyCardChanges,
  applyCardsChanges,
  getUpdatedCardsByTask,
  isCardInconsistent,
} from '../utils/card';
import {
  transformCardForFeed,
  transformFiltersForBE,
  transformPaginationForFeed,
} from '../transformers';
import { transformCardForEvent, transformCardsForEvent } from '../transformers/toFE';

export const selectCard = cardId => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;

    const selected = [
      ...state.selectedCardIds,
      cardId,
    ];

    dispatch({
      type: constants.SET_SELECTED,
      payload: selected,
    });
  }
);

export const selectCards = payload => ({
  type: constants.SELECT_CARDS,
  payload,
});

export const unselectCards = payload => ({
  type: constants.UNSELECT_CARDS,
  payload,
});

export const unselectCard = cardId => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;

    const selected = state.selectedCardIds.filter(id => cardId !== id);

    dispatch({
      type: constants.SET_SELECTED,
      payload: selected,
    });
  }
);

export const resetSelectedCards = payload => ({
  type: constants.RESET_SELECTED_CARDS,
  payload,
});

const dispatchMyFeedDocuments = (cards, dispatch) => (
  isEmpty(cards)
    ? dispatch({ type: constants.RECEIVE_MY_FEED_DOCUMENTS_DONE, payload: [] })
    : forEach(chunk(cards, MY_FEED_CARD_LIMIT), payload => (
      dispatch(fetchMyFeedDocuments({ payload }, constants))
    ))
);

export const fetchSingleCard = cardId => (
  (dispatch) => {
    dispatch({ type: constants.START_LOADING });
    const ajax = $.get(`/api_web/grm_v2/cards/${cardId}`);

    return Promise.all([ajax, dispatch(fetchCustomFields())])
      .then(([card, customFields]) => {
        const payload = [transformCardForFeed(card, customFields)];
        dispatch({ type: constants.SET_CARDS, payload });
        dispatch(updateCardsLinkPreview(payload));
        dispatch({
          type: constants.SET_PAGINATION,
          payload: {
            currentPage: 1,
            totalPages: 1,
            totalCount: 1,
            perPage: 10,
          },
        });
        dispatchMyFeedDocuments(payload, dispatch);
      })
      .catch(notifications.generalFailNotification)
      .finally(() => dispatch({ type: constants.STOP_LOADING }));
  }
);

const fetchCardsRequest = ({
  pagination,
  filters,
  timelineDefaultFilters,
  sort,
}) => {
  const url = '/api_web/grm_v2/cards/lookup';
  const method = 'POST';
  const data = {
    page: pagination.currentPage,
    per_page: pagination.perPage,
    search_form: transformFiltersForBE(filters, timelineDefaultFilters),
    ...sort,
  };

  return $.ajax({
    url,
    method,
    data: JSON.stringify(data),
    contentType: 'application/json',
  });
};

const fetchActivityCards = (dispatch, getState) => {
  const {
    pagination,
    filters,
    timelineDefaultFilters,
    sort,
  } = getState().grm2.grm;

  dispatch({ type: constants.START_LOADING });
  dispatch({ type: constants.SET_FILTERS, payload: filters });

  const ajax = fetchCardsRequest({
    pagination,
    filters,
    timelineDefaultFilters,
    sort,
  });

  const onSuccess = ([response, customFields]) => {
    const { data: cards = [], pagination: paginationData = {} } = response;
    const payload = map(cards, card => transformCardForFeed(card, customFields));

    dispatch({ type: constants.SET_CARDS, payload });
    dispatch(updateCardsLinkPreview(payload));
    dispatch({ type: constants.SET_PAGINATION, payload: transformPaginationForFeed(paginationData) });
    dispatch({ type: constants.RESET_CARD_STATUS });
    dispatchMyFeedDocuments(payload, dispatch);
  };

  return Promise.all([ajax, dispatch(fetchCustomFields())])
    .then(onSuccess)
    .catch(notifications.generalFailNotification)
    .finally(() => dispatch({ type: constants.STOP_LOADING }));
};

export const setIsTasksContext = () => ({ type: constants.SET_IS_TASKS_CONTEXT });

const fetchTasks = (dispatch, getState, options) => {
  const {
    pagination,
    filters,
    timelineDefaultFilters,
    sort,
  } = getState().grm2.grm;
  const { statuses = TASK_STATUSES } = options || {};

  dispatch({ type: constants.START_LOADING });
  dispatch({ type: constants.SET_FILTERS, payload: filters });

  const requests = map(statuses, status => (
    fetchCardsRequest({
      pagination,
      filters: { ...filters, statusTypes: [status] },
      timelineDefaultFilters,
      sort,
    })
  ));

  const onSuccess = ([customFields, ...responses]) => {
    const cards = reduce(responses, (result, { data = [] }) => [...result, ...data], []);

    const payload = map(cards, card => transformCardForFeed(card, customFields));
    dispatch({ type: constants.SET_CARDS, payload });
    dispatch(updateCardsLinkPreview(payload));
    dispatch({ type: constants.RESET_CARD_STATUS });
    dispatchMyFeedDocuments(payload, dispatch);
  };

  return Promise.all([dispatch(fetchCustomFields()), ...requests])
    .then(onSuccess)
    .catch(notifications.generalFailNotification)
    .finally(() => dispatch({ type: constants.STOP_LOADING }));
};

export const fetchCards = options => (
  (dispatch, getState) => (
    getState().grm2.grm.isTasksContext
      ? fetchTasks(dispatch, getState, options)
      : fetchActivityCards(dispatch, getState, options)
  )
);

export const updateCard = (card, isNew, shouldDispatchEvent = true) => (
  (dispatch, getState) => {
    const {
      pagination,
      filters,
      cards,
      customFields,
      namespace,
      isTasksContext,
    } = getState().grm2.grm;
    const isAddToCollection = isNew || isEqual(ACITIVITY_FOR_NAVIGATION_NAMESPACE, namespace);

    const updatedCard = transformCardForFeed(card, customFields);
    const isCardPresent = some(cards, { id: updatedCard.id });

    const { cardCreated, cardUpdated } = ActivityEventsPublisher(namespace);
    const eventToDispatch = isNew ? cardCreated : cardUpdated;
    shouldDispatchEvent && eventToDispatch({
      reduxCard: card,
      uiCard: updatedCard,
    });

    const dispatchCards = payload => dispatch({ type: constants.SET_CARDS, payload });

    const dispatchPagination = payload => dispatch({ type: constants.SET_PAGINATION, payload });

    function addNote() {
      const { perPage, totalCount } = pagination;

      // add a new note at the top of the feed,
      // and remove the last one if the total number of cards
      // exceeds the number of displayed items perPage.
      const cardsPayload = isTasksContext
        ? [updatedCard, ...cards]
        : [updatedCard, ...cards].slice(0, perPage);

      const paginationPayload = {
        ...pagination,
        totalCount: totalCount + 1,
      };

      dispatchCards(cardsPayload);
      dispatch(updateCardsLinkPreview([updatedCard], { timeOut: 5000 }));
      dispatchPagination(paginationPayload);
      dispatch({ type: constants.SET_HAS_NEW_CARD, payload: true });
    }

    if (isCardPresent) {
      const cardsPayload = applyCardChanges(cards, updatedCard);

      const oldCard = cards.find(({ id }) => id === card.id);
      shouldDispatchEvent && ActivityEventsPublisher(namespace).cardChanged({
        oldCard,
        newCard: updatedCard,
      });

      dispatchCards(cardsPayload);
      dispatch(updateCardsLinkPreview([updatedCard], { timeOut: 5000 }));
      isCardInconsistent({
        card: updatedCard,
        oldCard,
        filters,
      }) && dispatch({ type: constants.SET_HAS_EDITED_CARD, payload: true });
    } else if (isAddToCollection) {
      addNote();
    }
  }
);

export const updateCards = (updatedCards, shouldDispatchEvent = true) => (
  (dispatch, getState) => {
    const { cards, customFields, namespace } = getState().grm2.grm;
    const transformedCards = map(updatedCards, card => transformCardForFeed(card, customFields));
    const replaceCard = (card) => {
      const transformedCard = find(transformedCards, { id: card.id });

      return isNil(transformedCard)
        ? card
        : { ...card, ...transformedCard };
    };

    const payload = map(cards, replaceCard);

    shouldDispatchEvent && ActivityEventsPublisher(namespace).cardsUpdated({
      reduxCards: updatedCards,
      uiCards: transformedCards,
    });

    dispatch({ type: constants.SET_CARDS, payload });
  }
);

export const updateCommentsCount = (cardId, incrementValue, shouldDispatchEvent = true) => (
  (dispatch, getState) => {
    const { cards, namespace } = getState().grm2.grm;

    const updatedCard = find(cards, ['id', cardId]);
    updatedCard.commentsCount += incrementValue;

    const replaceCardCommentsCount = card => (
      card.id === cardId ? updatedCard : card
    );

    const payload = cards.map(replaceCardCommentsCount);

    shouldDispatchEvent && ActivityEventsPublisher(namespace).cardUpdated({
      reduxCard: transformCardForEvent(updatedCard).card,
      uiCard: updatedCard,
    });

    dispatch({ type: constants.SET_CARDS, payload });
  }
);

export const removeCardById = cardId => (
  (dispatch, getState) => {
    const { cards } = getState().grm2.grm;
    const filteredCards = cards.filter(({ id }) => id !== cardId);

    dispatch({
      type: constants.SET_CARDS,
      payload: filteredCards,
    });
  }
);

export function updateDataItem(payload, shouldDispatchEvent = true) {
  return (dispatch, getState) => {
    const { namespace } = getState().grm2.grm;

    shouldDispatchEvent && ActivityEventsPublisher(namespace).dataItemUpdated(payload);

    dispatch({
      type: constants.UPDATE_DATA_ITEM,
      payload,
    });
  };
}

export const updateCardById = (cardId, data, shouldDispatchEvent = true) => (
  (dispatch, getState) => {
    const { cards, namespace } = getState().grm2.grm;
    const card = find(cards, { id: cardId });

    const updatedCard = {
      ...card,
      ...data,
    };

    const payload = applyCardChanges(cards, updatedCard);

    const { cardUpdated } = ActivityEventsPublisher(namespace);

    shouldDispatchEvent && cardUpdated({
      reduxCard: transformCardForEvent(updatedCard).card,
      uiCard: updatedCard,
    });

    dispatch({
      type: constants.SET_CARDS,
      payload,
    });
  }
);

export const updateCardsById = (cardsId, data, shouldDispatchEvent = true) => (
  (dispatch, getState) => {
    const { cards, namespace } = getState().grm2.grm;

    const cardsToBeUpdated = filter(cards, ({ id }) => includes(cardsId, id));

    const updatedCards = map(cardsToBeUpdated, card => ({
      ...card,
      ...data,
    }));

    const payload = applyCardsChanges(cards, updatedCards);

    const { cardsUpdated } = ActivityEventsPublisher(namespace);
    shouldDispatchEvent && cardsUpdated({
      reduxCards: transformCardsForEvent(updatedCards),
      uiCards: updatedCards,
    });

    dispatch({
      type: constants.SET_CARDS,
      payload,
    });
  }
);

export const updateTasks = ({ data }) => (
  (dispatch, getState) => {
    const { cards, namespace } = getState().grm2.grm;

    const updatedCards = getUpdatedCardsByTask(cards, data);

    ActivityEventsPublisher(namespace).cardsUpdated({
      reduxCards: transformCardsForEvent(updatedCards),
      uiCards: updatedCards,
    });

    dispatch({ type: constants.SET_CARDS, payload: updatedCards });
  }
);
