import { DEFAULT_PER_PAGE } from '../constants';

const defaultState = {
  donors: [],
  pagination: {
    page: 1,
    count: 0,
    per_page: DEFAULT_PER_PAGE,
    total_pages: 0,
  },
  ui: {
    loading: false,
    noResultsFound: false,
  },
  searchData: {},
};

export default defaultState;
