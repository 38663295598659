const prefix = 'STATE_LEG_WATCHLIST';

const constants = {
  FETCH_WATCHLIST: `${prefix}/FETCH_WATCHLIST`,
  RECEIVE_WATCHLIST: `${prefix}/RECEIVE_WATCHLIST`,
  SELECT_BILL: `${prefix}/SELECT_BILL`,
  DESELECT_BILL: `${prefix}/DESELECT_BILL`,
  FETCH_BILL: `${prefix}/FETCH_BILL`,
  RECEIVE_BILL: `${prefix}/RECEIVE_BILL`,
  FETCH_SPONSORS_AND_COSPONSORS: `${prefix}/FETCH_SPONSORS_AND_COSPONSORS`,
  RECEIVE_SPONSORS_AND_COSPONSORS: `${prefix}/RECEIVE_SPONSORS_AND_COSPONSORS`,
  FETCH_BILL_TIMELINE: `${prefix}/FETCH_BILL_TIMELINE`,
  RECEIVE_BILL_TIMELINE: `${prefix}/RECEIVE_BILL_TIMELINE`,
  FETCH_BILL_COMMITTEES: `${prefix}/FETCH_BILL_COMMITTEES`,
  RECEIVE_BILL_COMMITTEES: `${prefix}/RECEIVE_BILL_COMMITTEES`,
  FETCH_BILL_REPORTS: `${prefix}/FETCH_BILL_REPORTS`,
  RECEIVE_BILL_REPORTS: `${prefix}/RECEIVE_BILL_REPORTS`,
  FETCH_BILL_COSPONSORS: `${prefix}/FETCH_BILL_COSPONSORS`,
  RECEIVE_BILL_COSPONSORS: `${prefix}/RECEIVE_BILL_COSPONSORS`,
  BILL_NOT_FOUND: `${prefix}/BILL_NOT_FOUND`,
  FETCH_TIMELINE_ERROR: `${prefix}/FETCH_TIMELINE_ERROR`,
  FETCH_SPONSORS_ERROR: `${prefix}/FETCH_SPONSORS_ERROR`,
  FETCH_COMMITTEES_ERROR: `${prefix}/FETCH_COMMITTEES_ERROR`,
  FETCH_REPORTS_ERROR: `${prefix}/FETCH_REPORTS_ERROR`,
  FETCH_COSPONSORS_ERROR: `${prefix}/FETCH_COSPONSORS_ERROR`,
};

export default constants;
