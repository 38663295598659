const PREFIX = 'FEDERAL_BILL_LOOKUP';

const constants = {
  STRATEGY_SLUG: 'rolodex_federal_agency_contact',
  STRATEGY_SLUG_FOR_EXPORT: 'rolodex_federal_agency_contacts',
  STRATEGY_SLUG_SINGLE_EXPORT: 'rolodex_federal_agency_contact',
  FETCH_AGENCY_STAFFERS: `${PREFIX}/FETCH_AGENCY_STAFFERS`,
  RECEIVE_AGENCY_STAFFERS_DONE: `${PREFIX}/RECEIVE_AGENCY_STAFFERS_DONE`,
  RESET_AGENCY_STAFFERS: `${PREFIX}/RESET_AGENCY_STAFFERS`,
  FETCH_AGENCY_STAFFERS_EMAILS: `${PREFIX}/FETCH_AGENCY_STAFFERS_EMAILS`,
  RECEIVE_AGENCY_STAFFERS_EMAILS_DONE: `${PREFIX}/RECEIVE_AGENCY_STAFFERS_EMAILS_DONE`,
};

export default constants;
