const keyword = 'DASHBOARD_HEARING_CALENDAR';

const constants = {
  FETCH_EVENTS: `${keyword}/FETCH_EVENTS`,
  FETCH_EVENTS_SUCCESS: `${keyword}/FETCH_EVENTS_SUCCESS`,
  FETCH_EVENTS_FAILURE: `${keyword}/FETCH_EVENTS_FAILURE`,
  CHECK_EVENTS: `${keyword}/CHECK_EVENTS`,
  FETCH_CALENDAR_DATA: `${keyword}/FETCH_CALENDAR_DATA`,
  FETCH_CALENDAR_DATA_SUCCESS: `${keyword}/FETCH_CALENDAR_DATA_SUCCESS`,
  SELECT_CALENDAR_DATE: `${keyword}/SELECT_CALENDAR_DATE`,
  FILTER_EVENTS: `${keyword}/FILTER_EVENTS`,
  SET_MONTHS_AHEAD: `${keyword}/SET_MONTHS_AHEAD`,
};

export default constants;
