const keyword = 'FEDERAL_PROFILE';

const constants = {
  FETCH_COMMITTEE: `${keyword}/FETCH_COMMITTEE`,
  RECEIVE_COMMITTEE_DONE: `${keyword}/RECEIVE_COMMITTEE_DONE`,
  RESET_COMMITTEE: `${keyword}/RESET_COMMITTEE`,
  FETCH_STAFFERS: `${keyword}/FETCH_STAFFERS`,
  RECEIVE_STAFFERS_DONE: `${keyword}/RECEIVE_STAFFERS_DONE`,
  FETCH_COMMITTEE_MEMBERS: `${keyword}/FETCH_COMMITTEE_MEMBERS`,
  RECEIVE_COMMITTEE_MEMBERS_DONE: `${keyword}/RECEIVE_COMMITTEE_MEMBERS_DONE`,
  FETCH_SUBCOMMITTEES: `${keyword}/FETCH_SUBCOMMITTEES`,
  RECEIVE_SUBCOMMITTEES_DONE: `${keyword}/RECEIVE_SUBCOMMITTEES_DONE`,
  LOAD_OVERVIEW: `${keyword}/LOAD_OVERVIEW`,
  LOAD_OVERVIEW_DONE: `${keyword}/LOAD_OVERVIEW_DONE`,
  FETCH_TAGS: `${keyword}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${keyword}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${keyword}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${keyword}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${keyword}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${keyword}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${keyword}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${keyword}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  UPDATE_PROFILE_MY_FEED: `${keyword}/UPDATE_PROFILE_MY_FEED`,
};

export default constants;
