import { LAST_SYNC_AT_FORMAT } from 'utils/constants/date';
import { formatDateBy } from 'utils/date';

import constants from '../constants';

export function fetchContributionsLastSync({ id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CONTRIBUTIONS_LAST_SYNC });

    const url = '/api_web/stakeholder-management/contributions/last_sync_at';
    const data = {
      search_form: {
        stakeholder_id: id,
      },
    };

    const ajax = $.ajax({
      url,
      method: 'GET',
      data,
    });

    function onSuccess(response) {
      return dispatch({
        type: constants.FETCH_CONTRIBUTIONS_LAST_SYNC_DONE,
        error: false,
        payload: {
          lastSyncAt: formatDateBy(response.last_sync_at, LAST_SYNC_AT_FORMAT),
        },
      });
    }

    function onFailure(error) {
      dispatch({
        type: constants.FETCH_CONTRIBUTIONS_LAST_SYNC_FAIL,
        error: true,
        payload: {
          lastSyncShort: null,
          lastSyncFull: null,
        },
      });

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}
