import { isEmpty } from 'lodash';

import { getValue, transformReceiptDisbursements } from 'redux/moneyball/utils/transformers';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import {
  fetchSearch,
  fetchSearchReceipt,
  fetchSearchKeyDetails,
  onSelectElectionCycle,
  onSetElectionCycles,
} from 'redux/moneyball/utils';
import {
  loadSavedSearches,
  loadRecentSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformVendor,
  transformDisbursements,
  transformSearchesForFE,
  transformOptionsForBE,
  transformOptionsSearchForBE,
} from './utils';
import constants from './constants';

export function initAppliedSearchFilters(emptyState, { searchType }) {
  return handleAppliedFilters({ ...emptyState, ...(searchType && { searchType }) });
}

export function setElectionCycles(cycles) {
  return onSetElectionCycles(cycles, constants.SET_ELECTION_CYCLES);
}

export function onSelectCycle(cycle) {
  return onSelectElectionCycle(cycle, constants.SELECT_ELECTION_CYCLE);
}

export function loadVendorSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadVendorRecentSearches() {
  return (dispatch) => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveVendorAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformOptionsSearchForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadVendorRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeVendorAdvancedSearch({ id }) {
  return onRemoveItem(id, loadVendorSavedSearches);
}

export function fetchVendorData(id, params) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_VENDOR });

    function getDefaultParams() {
      return {
        url: `${gon.moneyballApiV2}/api/v1/super_vendors/${id}`,
        data: params,
        type: 'GET',
      };
    }

    function getCombinedParams() {
      const { ids } = params;

      return {
        url: `${gon.moneyballApiV2}/api/v1/super_vendors/combine`,
        data: JSON.stringify({ super_vendor_ids: ids }),
        type: 'POST',
      };
    }

    const { url, type, data } = id !== 'combine' ? getDefaultParams() : getCombinedParams();

    return dispatch(
      fetchSearchKeyDetails({
        url,
        type,
        payload: data,
        contentType: REQUEST_CONTENT_TYPE,
        actionType: constants.RECEIVE_VENDOR_DONE,
        transformResponse: transformVendor,
      }),
    );
  };
}

export function searchVendorsReceipt({ id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_VENDOR_RECEIPT });

    return dispatch(
      fetchSearchReceipt({
        url: `${gon.moneyballApiV2}/api/v1/disbursements/${id}`,
        transformResponse: transformReceiptDisbursements,
        actionType: constants.RECEIVE_VENDOR_RECEIPT_DONE,
      }),
    );
  };
}

export function searchVendorDisbursements(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_VENDOR_DISBURSEMENTS });

    const { id, ids, ...restPayload } = payload;
    const formattedPayload = {
      ...restPayload,
      recipient: {
        super_vendors: {
          ids: id === 'combine' ? ids : [id],
        },
      },
    };

    return dispatch(
      fetchSearch({
        payload: formattedPayload,
        url: `${gon.moneyballApiV2}/api/v1/disbursements/lookup`,
        actionType: constants.RECEIVE_VENDOR_DISBURSEMENTS_DONE,
        transformResponsePayload: transformDisbursements,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformOptionsSearchForBE,
        saveRecentAdvancedSearch: saveVendorAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return (dispatch) => dispatch(resetAppliedFilters());
}
