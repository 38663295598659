import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { transformDocumentsForUI } from 'redux/local/lookup/actions/utils';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import constants from '../../constants';
import { fetchTags } from '../tags';
import getDocumentsData from './getDocumentsData';

const fetchDocuments = (payload = {}) => (dispatch) => {
  dispatch({ type: constants.FETCH_DOCUMENTS });

  const { searchData = {}, clearAll, toResetSelectedItems, documentType } = payload;
  const { isSearch, url, data, sortData } = getDocumentsData(payload);

  const ajax = $.post(url, data);

  function handleSuccess(response) {
    const currentPayload = response && response.data || [];
    const paginator = transformPaginator(response);
    const { current } = paginator;

    return dispatch({
      type: constants.RECEIVE_DOCUMENTS_DONE,
      payload: transformDocumentsForUI(currentPayload, current),
      paginator,
      toResetSelectedItems: isSearch || toResetSelectedItems,
      sort: transformSort(sortData),
    });
  }

  function saveAppliedFiltersToProps() {
    if (clearAll) {
      return dispatch(resetAppliedFilters());
    }

    return dispatch(handleAppliedFilters(searchData));
  }

  function onFailure(error) {
    throw error;
  }

  return Promise.resolve(ajax)
    .then(handleSuccess)
    .then(payloadData => dispatch(fetchTags(payloadData, documentType)))
    .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
    .then(saveAppliedFiltersToProps)
    .catch(onFailure);
};

export default fetchDocuments;
