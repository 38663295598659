import { isEmpty, map } from 'lodash';
import moment from 'moment/moment';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

export function transformTimeline(payload) {
  const { data } = payload || {};

  if (isEmpty(data)) {
    return [];
  }

  return map(data, ({ id, action, date }) => ({
    id,
    text: action,
    date: date && moment(date).format(GLOBAL_DATE_FORMAT),
  }));
}
