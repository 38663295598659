import { toNumber, isEmpty } from 'lodash';

import { getMyFeedToken } from 'components/Dashboard/utils';
import { BASE_FEEDS_URL } from 'redux/dashboardV2/constants';
import { sendFailureNotification } from 'utils/notification';

import constants, {
  BASE_NOTIFICATIONS_URL,
  FETCH_INTERVAL,
  REQUEST_TIME_LIMIT,
  NOTIFICATIONS_COUNT_KEY,
} from './constants';
import { transformResponseData } from './utils/transformers';

function fetchNotificationCount() {
  const url = `${BASE_NOTIFICATIONS_URL}/count`;
  const ajax = $.get(url);

  setTimeout(() => ajax && ajax.abort(), REQUEST_TIME_LIMIT);

  return Promise.resolve(ajax)
    .catch((error) => { throw error; });
}

let timer = null;

export function getNotifications(countResponse) {
  return (dispatch, getState) => {
    const { notifications: stateNotifications } = getState().notifications;
    const { count = 0 } = countResponse || {};
    const previousCount = toNumber(localStorage.getItem(NOTIFICATIONS_COUNT_KEY));
    const isUnchanged = previousCount === count && !isEmpty(stateNotifications);

    if (isUnchanged) {
      return Promise.resolve();
    }

    localStorage.setItem(NOTIFICATIONS_COUNT_KEY, count);

    function onSuccess(response) {
      const notifications = transformResponseData(response);

      dispatch({
        type: constants.FETCH_NOTIFICATIONS_DONE,
        payload: {
          notifications,
          totalNotifications: count,
        },
      });
    }

    if (count === 0) {
      return Promise.resolve({ data: [] }).then(onSuccess);
    }

    const url = `${BASE_NOTIFICATIONS_URL}/lookup`;
    const ajax = $.get(url);

    setTimeout(() => ajax && ajax.abort(), REQUEST_TIME_LIMIT);

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch((error) => { throw error; });
  };
}

export function fetchNotifications() {
  return (dispatch) => {
    function fetch() {
      fetchNotificationCount()
        .then(response => dispatch(getNotifications(response)));
    }

    fetch(); // initial fetch
    timer = setInterval(fetch, FETCH_INTERVAL);
  };
}

export function stopFetchNotifications() {
  return () => clearInterval(timer);
}

export function resetNotifications() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_NOTIFICATIONS });
  };
}

export function clearNotifications(id) {
  return (dispatch) => {
    dispatch({ type: constants.CLEAR_NOTIFICATIONS });

    const url = `${BASE_FEEDS_URL}/${getMyFeedToken()}/last_visit/notifications`;
    const ajax = $.post(url, { latest_event_token: id });

    function onFailure(error) {
      dispatch({ type: constants.CLEAR_NOTIFICATIONS_FAILURE });

      sendFailureNotification('Failed to clear notifications. Please, try again.');

      throw error;
    }

    Promise.resolve(ajax)
      .then(() => dispatch({ type: constants.CLEAR_NOTIFICATIONS_DONE }))
      .catch(onFailure);
  };
}

export function togglePanel() {
  return (dispatch) => {
    dispatch({ type: constants.TOGGLE_PANEL });
  };
}

export function resetPanel() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_PANEL });
  };
}
