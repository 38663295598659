import { isEmpty, filter, size, isNil, join } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import {
  FEDERAL_COMMITTEE_ITEM_TYPE,
  FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
  STATE_COMMITTEE_ITEM_TYPE,
  STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE,
} from 'utils/constants/itemTypes';
import { BASE_URL, EMPTY_VALUE } from 'utils/constants';

export function getPositionDescription({ userFullName, position, isNotification = false }) {
  if (isEmpty(userFullName) || isEmpty(position)) {
    return EMPTY_VALUE;
  }

  return isNotification
    ? `Position set to ${position} by ${userFullName}`
    : `${userFullName} changed position on this item to “${position}”`;
}

export function getHearingStatus(type) {
  return type === 'updated_hearing' ? 'Updated Hearing' : 'New Hearing';
}

export function getHearingNotificationTitle(type, recordType) {
  return type === 'updated_hearing'
    ? `${recordType} hearing update`
    : `${recordType} hearing announced`;
}

export function getTitleUrl(id, basePath, withDomain = false) {
  if (id) {
    const formatUrl = `${basePath}/${id}`;
    return withDomain ? `${BASE_URL}${formatUrl}` : formatUrl;
  }

  return null;
}

export function getTitle(first, second, withFormat = false, separator = ' ') {
  const secondWord = withFormat ? second && `(${second})` : second;
  const filteredItems = filter([first, secondWord], Boolean);

  return isEmpty(filteredItems) ? EMPTY_VALUE : join(filteredItems, separator);
}

export function displayDescription(values) {
  return size(filter(values, Boolean)) === size(values);
}

export function formatDescription(values) {
  return `${join(values, ' ')}.`;
}

export function getStateRegulationUrl(eventData, withDomain = false) {
  const { id, document_id, regulation_document_id, regulation_id } = eventData || {};

  if (!isNil(regulation_id)) {
    return getTitleUrl(regulation_id, '/state/regulations', withDomain);
  }

  const documentId = id || regulation_document_id || document_id;

  if (isNil(documentId)) {
    return null;
  }

  const url = `/state/regulations/document/view?document_id=${documentId}&slug=single_document`;

  return withDomain ? `${BASE_URL}${url}` : url;
}

export function getSocialMediaTitle(eventData) {
  const { social_network: socialNetwork, user } = eventData || {};
  const { name } = user || {};

  return join(filter([name, socialNetwork], Boolean), ' - ');
}

export function getStafferItemType(documentType) {
  const { [documentType]: itemType } = {
    [DOCUMENT_TYPES.FEDERAL_COMMITTEE]: FEDERAL_COMMITTEE_ITEM_TYPE,
    [DOCUMENT_TYPES.FEDERAL_LEGISLATOR]: FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    [DOCUMENT_TYPES.STATE_COMMITTEE]: STATE_COMMITTEE_ITEM_TYPE,
    [DOCUMENT_TYPES.STATE_LEGISLATOR]: STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE,
  };

  return itemType;
}
