import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getOfficeStaffer } from 'redux/staffers/common/utils';
import { CONGRESSIONAL_STAFFER_ITEM_TYPE } from 'utils/constants/itemTypes';

export function transformStaffMembers(data) {
  data.staff_members = data.staff_members.map(staffer => ({
    name: {
      value: `${staffer.first_name} ${staffer.last_name}`,
      url: staffer.url,
    },
    title: staffer.title,
    email: staffer.email,
  }));

  return data;
}

function addTitleToCommitteeMembers(activePositions = []) {
  return activePositions.map((activePosition) => {
    const { committee } = activePosition;

    if (isEmpty(committee)) {
      return activePosition;
    }

    const { chairman, rankingMember } = committee;

    if (!isEmpty(chairman)) {
      chairman.role = 'Chairman';
    }

    if (!isEmpty(rankingMember)) {
      rankingMember.role = 'Ranking member';
    }

    return {
      ...activePosition,
      committee: {
        ...activePosition.committee,
        chairman,
        rankingMember,
      },
    };
  });
}

function transformSubject(subject) {
  const {
    abbreviated_title,
    party_short,
    party_slug,
    ...restSubject
  } = subject || {};

  return pickBy({
    ...restSubject,
    abbreviatedTitle: abbreviated_title,
    partyShort: party_short,
    partySlug: party_slug,
  }, identity);
}

function transformAdditionalStaff(additionalStaff) {
  return additionalStaff.map((staffer) => {
    const {
      first_name,
      last_name,
      ...restStaffer
    } = staffer;

    return {
      ...restStaffer,
      firstName: first_name,
      lastName: last_name,
    };
  });
}

function transformActivePositions(activePositions) {
  return activePositions.map((activePosition) => {
    const {
      additional_staff,
      committee,
      legislator,
      start_date,
      ...restActivePosition
    } = activePosition || {};

    const {
      ranking_member,
      chairman,
      ...restComittee
    } = committee || {};

    return {
      ...restActivePosition,
      committee: {
        ...restComittee,
        rankingMember: transformSubject(ranking_member),
        chairman: transformSubject(chairman),
      },
      legislator: transformSubject(legislator),
      additionalStaff: transformAdditionalStaff(additional_staff),
      startDate: start_date,
    };
  });
}

export function transformStaffersData(data) {
  const {
    active_positions,
    organizational_positions,
    historical_positions,
    full_name,
    first_name,
    last_name,
    twitter_account,
    facebook_account,
    linkedin_account,
    gender_title,
    committee_staffer_type,
    ...restData
  } = data || {};

  return {
    ...restData,
    committeeStafferType: committee_staffer_type,
    activePositions: addTitleToCommitteeMembers(
      transformActivePositions(active_positions)
    ),
    historicalPositions: getOfficeStaffer(historical_positions),
    organizationalPositions: organizational_positions,
    fullName: full_name,
    firstName: first_name,
    lastName: last_name,
    twitterAccount: twitter_account,
    facebookAccount: facebook_account,
    linkedinAccount: linkedin_account,
    genderTitle: gender_title,
    documentType: DOCUMENT_TYPES.CONGRESSIONAL_STAFFER,
    itemType: CONGRESSIONAL_STAFFER_ITEM_TYPE,
  };
}
