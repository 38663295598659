export const LEGISLATOR_TYPE = 'legislator';
export const COMMITTEE_TYPE = 'committee';
export const OTHER_TYPE = 'other';

const getActionTypes = keyword => ({
  SORT_STAFFER_DATA: `${keyword}/SORT_STAFFER_DATA`,
  SORT_STAFFER_DATA_DONE: `${keyword}/SORT_STAFFER_DATA_DONE`,
});

export default getActionTypes;
