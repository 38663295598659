
const constants = {
  STOP_LOADING: 'ACTION_CENTER_MANAGER/STOP_LOADING',
  FETCH_CAMPAIGNS: 'ACTION_CENTER_MANAGER/FETCH_CAMPAIGNS',
  RECEIVE_CAMPAIGNS: 'ACTION_CENTER_MANAGER/RECEIVE_CAMPAIGNS',
  CHANGE_DISPLAY_ON_ACTION_CENTER: 'ACTION_CENTER_MANAGER/CHANGE_DISPLAY_ON_ACTION_CENTER',
  CHANGE_DISPLAY_ON_ACTION_CENTER_SUCCESS: 'ACTION_CENTER_MANAGER/CHANGE_DISPLAY_ON_ACTION_CENTER_SUCCESS',
  SET_EMBEDDABLE_STATUS: 'ACTION_CENTER_MANAGER/SET_EMBEDDABLE_STATUS',
  ACTION_CENTER_EMBEDDABLE_STATUS_CHANGED: 'ACTION_CENTER_MANAGER/ACTION_CENTER_EMBEDDABLE_STATUS_CHANGED',
  ACTION_CENTER_EMBEDDABLE_STATUS_CHANGE_FAILURE: 'ACTION_CENTER_MANAGER/ACTION_CENTER_EMBEDDABLE_STATUS_CHANGE_FAILURE',
};

/**
 * Sorts to put campaigns displayed at action center first
 * @param {array} arr
 */
function sort(arr) {
  const addedToActionCenter = [];
  const rest = arr.filter((item) => {
    if (item.display_on_action_center) {
      if (item.status === 'ready') {
        addedToActionCenter.unshift(item);
      } else {
        addedToActionCenter.push(item);
      }
      return false;
    }
    return true;
  });

  return addedToActionCenter.concat(rest);
}

export function setEmbeddableStatus(status) {
  return (dispatch) => {
    dispatch({ type: constants.SET_EMBEDDABLE_STATUS, payload: status });
  };
}

export function fetchCampaigns(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CAMPAIGNS, payload });

    // uses api_web_grassroots_campaigns_path
    const { page = 1 } = payload;
    const url = '/api_web/grassroots/campaigns';
    const method = 'GET';
    const data = {
      per_page: 100,
      page,
    };

    const ajax = $.ajax({
      method,
      url,
      data,
    });

    return Promise.resolve(ajax)
      .then(response =>
        dispatch({
          type: constants.RECEIVE_CAMPAIGNS,
          payload: {
            ...response,
            data: sort(response.data),
          },
        })
      )
      .catch(() => dispatch({ type: constants.STOP_LOADING }));
  };
}

export function changeCampaignActionCenterPresence(token, status) {
  return (dispatch) => {
    dispatch({ type: constants.CHANGE_DISPLAY_ON_ACTION_CENTER });

    // uses api_web_grassroots_campaigns_path
    const url = `/api_web/grassroots/campaigns/${token}`;
    const method = 'PUT';
    const data = { campaign: { display_on_action_center: status } };

    const ajax = $.ajax({
      method,
      url,
      data,
    });

    return Promise.resolve(ajax)
      .then(response =>
        dispatch({ type: constants.CHANGE_DISPLAY_ON_ACTION_CENTER_SUCCESS, payload: response })
      )
      .catch(() => dispatch({ type: constants.STOP_LOADING }));
  };
}

export function changeEmbeddableStatus(companyId, status) {
  return (dispatch) => {
    dispatch({ type: constants.CHANGE_DISPLAY_ON_ACTION_CENTER });

    const url = `/api_web/companies/${companyId}`;
    const method = 'PUT';
    const data = { company: { action_center_embeddable: status } };

    const ajax = $.ajax({
      method,
      url,

      data,
    });

    return Promise.resolve(ajax)
      .then(response =>
        dispatch({ type: constants.ACTION_CENTER_EMBEDDABLE_STATUS_CHANGED, payload: response })
      )
      .catch(() => dispatch({
        type: constants.ACTION_CENTER_EMBEDDABLE_STATUS_CHANGE_FAILURE,
        payload: !status,
      }));
  };
}

export const defaultState = {
  campaigns: [],
  actionCenterEmbeddable: false,
  searchForm: {},
  pagination: {
    total_pages: 0,
  },
  ui: {
    loading: false,
  },
};

function updateSingleCampaign(campaigns, campaign) {
  return campaigns.map((item) => {
    if (item.token === campaign.token) return campaign;
    return item;
  });
}

export default function (state = defaultState, action) {
  const {
    FETCH_CAMPAIGNS,
    RECEIVE_CAMPAIGNS,
    CHANGE_DISPLAY_ON_ACTION_CENTER,
    CHANGE_DISPLAY_ON_ACTION_CENTER_SUCCESS,
    STOP_LOADING,
    SET_EMBEDDABLE_STATUS,
    ACTION_CENTER_EMBEDDABLE_STATUS_CHANGED,
    ACTION_CENTER_EMBEDDABLE_STATUS_CHANGE_FAILURE,
  } = constants;

  switch (action.type) {
    case FETCH_CAMPAIGNS:
      return {
        ...state,
        campaigns: defaultState.campaigns,
        pagination: defaultState.pagination,
        searchForm: {
          ...state.searchForm,
          ...action.payload,
        },
        ui: {
          ...state.ui,
          loading: true,
        },
      };

    case CHANGE_DISPLAY_ON_ACTION_CENTER:
      return {
        ...state,
        ui: { ...state.ui, loading: true },
      };

    case RECEIVE_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload.data,
        pagination: action.payload.pagination,
        ui: { ...state.ui, loading: false },
      };

    case CHANGE_DISPLAY_ON_ACTION_CENTER_SUCCESS:
      return {
        ...state,
        campaigns: updateSingleCampaign(state.campaigns, action.payload),
        ui: { ...state.ui, loading: false },
      };

    case SET_EMBEDDABLE_STATUS:
      return {
        ...state,
        actionCenterEmbeddable: action.payload,
      };

    case ACTION_CENTER_EMBEDDABLE_STATUS_CHANGED:
      return {
        ...state,
        actionCenterEmbeddable: action.payload.action_center_embeddable,
        ui: { ...state.ui, loading: false },
      };

    case ACTION_CENTER_EMBEDDABLE_STATUS_CHANGE_FAILURE:
      return {
        ...state,
        actionCenterEmbeddable: action.payload,
        ui: { ...state.ui, loading: false },
      };

    case STOP_LOADING:
      return {
        ...state,
        ui: { ...state.ui, loading: false },
      };

    default:
      return state;
  }
}
