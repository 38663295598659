import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { PAGE_SIZE } from 'components/core/Profile/utils/constants';

import {
  getVotingRecordUrl,
  transformBillsAndVotesForBE,
  transformBillsAndVotesForUI,
} from './utils';
import constants from './constants';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchBillsAndVotes(payload = {}, customTransformers = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILLS });

    const {
      transformForBE = transformBillsAndVotesForBE,
      transformForUI = transformBillsAndVotesForUI,
    } = customTransformers;
    const billsData = transformForBE(payload);

    const url = getVotingRecordUrl(payload.slug);
    const ajax = $.post(url, { ...billsData, ...PER_PAGE });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_BILLS_DONE,
        payload: {
          ...transformForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(payload),
        },
      }));
  };
}
