import moment from 'moment/moment';
import { isEmpty, map } from 'lodash';

import { GLOBAL_DATE_FORMAT, DAY_YEAR_DATE_FORMAT } from 'utils/constants/date';
import { formatDateBy, getDate, format } from 'utils/date';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';

import {
  transformCardLinksForFeed,
  transformCardLinksForForm,
  transformCardLinksForEvent,
} from './cardLinks';
import {
  transformCustomFieldAnswersForFeed,
  transformCustomFieldAnswersForForm,
  transformCustomFieldAnswersForEvent,
} from './customFields';
import {
  transformTaskForFeed,
  transformTaskForEvent,
  transformTaskForForm,
} from './tasks';

export const transformCardForFeed = (card = { user: {} }, customFields = {}) => ({
  id: card.id,
  title: card.title,
  creationDate: formatDateBy(card.created_at, DAY_YEAR_DATE_FORMAT),
  fullCreationDate: getDate(card.created_at),
  eventDate: card.date && formatDateBy(card.date, GLOBAL_DATE_FORMAT),
  interactionType: card.interaction_type,
  description: card.description,
  tags: map(card.tags, ({ id: value, name: label }) => ({ value, label })),
  user: {
    id: card.user.id,
    name: card.user.full_name,
    photo: card.user.profile_img_url,
    email: card.user.email,
  },
  createdBy: card.user.full_name,
  links: transformCardLinksForFeed(card.card_links),
  customFieldsAnswers: transformCustomFieldAnswersForFeed(card.custom_fields_answers, customFields),
  cardType: card.card_type,
  commentsCount: card.comments_and_replies_count || 0,
  uploads: card.uploads,
  disabled: !!card.disabled,
  task: card.task ? transformTaskForFeed(card.task) : {},
  documentType: DOCUMENT_TYPES.GRM_V2_CARD,
});

// back-end returns all custom_fields_answers, for both enabled and disabled fields
// since customFields only include enabled fields,
// we use it to filter only enabled CF answers, before passing them to form
export const transformCardForForm = (card = {}, customFields = {}) => {
  const taskFields = !isEmpty(card.task) ? transformTaskForForm(card.task) : {};

  return ({
    id: card.id,
    title: card.title,
    description: card.description,
    eventDate: card.date,
    tags: map(card.tags, ({ id: value, name: label }) => ({ value, label })),
    customFieldsAnswers: transformCustomFieldAnswersForForm(card.custom_fields_answers, customFields),
    uploads: card.uploads,
    interactionType: card.interaction_type,
    card_type: card.card_type,
    ...transformCardLinksForForm(card.card_links),
    ...taskFields,
  });
};

export const transformCardForEvent = (card = { user: {} }) => ({
  card: {
    id: card.id,
    title: card.title,
    description: card.description,
    created_at: format(card.fullCreationDate),
    date: card.eventDate && format(moment(card.eventDate, GLOBAL_DATE_FORMAT)),
    tags: map(card.tags, ({ label, value }) => ({ id: value, name: label })),
    card_links: transformCardLinksForEvent(card.links),
    custom_fields_answers: transformCustomFieldAnswersForEvent(card.customFieldsAnswers),
    uploads: card.uploads,
    user: {
      id: card.user.id,
      email: card.user.email,
      full_name: card.user.name,
      profile_img_url: card.user.photo,
    },
    comments_and_replies_count: card.commentsCount,
    interaction_type: card.interactionType,
    card_type: card.cardType,
    disabled: !!card.disabled,
    task: !isEmpty(card.task) ? transformTaskForEvent(card.task) : null,
  },
});

export const transformCardsForEvent = cards => map(
  cards,
  card => transformCardForEvent(card).card
);
