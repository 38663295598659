export function getContactSaveRequestSettings(contact) {
  const data = JSON.stringify(contact);
  const { id } = contact;

  if (id) {
    return {
      url: `/api_web/company-subscription/contacts/${id}`,
      method: 'PUT',
      contentType: 'application/json',
      data,
    };
  }

  return {
    url: '/api_web/company-subscription/contacts',
    method: 'POST',
    contentType: 'application/json',
    data,
  };
}

export function getQuickLinkSaveRequestSettings(quickLink) {
  const data = JSON.stringify(quickLink);
  const { id } = quickLink;

  if (id) {
    return {
      url: `/api_web/company-subscription/quick_links/${id}`,
      method: 'PUT',
      contentType: 'application/json',
      data,
    };
  }

  return {
    url: '/api_web/company-subscription/quick_links',
    method: 'POST',
    contentType: 'application/json',
    data,
  };
}
