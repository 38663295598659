export const BILL_SLUG = 'bill';
export const API_BASE = '/api_web/federal/bills';

const keyword = 'FEDERAL_BILL_PROFILE';
const constants = {
  FETCH_PROFILE: `${keyword}/FETCH_PROFILE`,
  RECEIVE_PROFILE_DONE: `${keyword}/RECEIVE_PROFILE_DONE`,
  FETCH_SUBSCRIPTIONS: `${keyword}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${keyword}/RECEIVE_SUBSCRIPTIONS_DONE`,
  FETCH_COMMITTEES: `${keyword}/FETCH_COMMITTEES`,
  RECEIVE_COMMITTEES_DONE: `${keyword}/RECEIVE_COMMITTEES_DONE`,
  FETCH_COSPONSORS: `${keyword}/FETCH_COSPONSORS`,
  RECEIVE_COSPONSORS_DONE: `${keyword}/RECEIVE_COSPONSORS_DONE`,
  FETCH_TAGS: `${keyword}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${keyword}/RECEIVE_TAGS_DONE`,
  FETCH_REPORTS: `${keyword}/FETCH_REPORTS`,
  RECEIVE_REPORTS_DONE: `${keyword}/RECEIVE_REPORTS_DONE`,
  FETCH_BILLS_COMPARISON: `${keyword}/FETCH_BILLS_COMPARISON`,
  RECEIVE_BILLS_COMPARISON_DONE: `${keyword}/RECEIVE_BILLS_COMPARISON_DONE`,
  FETCH_BILL_TIMELINE: `${keyword}/FETCH_BILL_TIMELINE`,
  RECEIVE_BILL_TIMELINE_DONE: `${keyword}/RECEIVE_BILL_TIMELINE_DONE`,
  FETCH_ROLL_CALL_VOTES: `${keyword}/FETCH_ROLL_CALL_VOTES`,
  RECEIVE_ROLL_CALL_VOTES: `${keyword}/RECEIVE_ROLL_CALL_VOTES`,
  FETCH_AMENDMENTS: `${keyword}/FETCH_AMENDMENTS`,
  RECEIVE_AMENDMENTS: `${keyword}/RECEIVE_AMENDMENTS`,
  FETCH_LOBBYING_INFORMATION: `${keyword}/FETCH_LOBBYING_INFORMATION`,
  RECEIVE_LOBBYING_INFORMATION: `${keyword}/RECEIVE_LOBBYING_INFORMATION`,
  FETCH_ACTIVE_VOICES: `${keyword}/FETCH_ACTIVE_VOICES`,
  RECEIVE_ACTIVE_VOICES: `${keyword}/RECEIVE_ACTIVE_VOICES`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${keyword}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${keyword}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${keyword}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${keyword}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  UPDATE_PROFILE_MY_FEED: `${keyword}/UPDATE_PROFILE_MY_FEED`,
  FETCH_TIMELINE_ERROR: `${keyword}/FETCH_TIMELINE_ERROR`,
  FETCH_SPONSORS_ERROR: `${keyword}/FETCH_SPONSORS_ERROR`,
  BILL_NOT_FOUND: `${keyword}/BILL_NOT_FOUND`,
  FETCH_COMMITTEES_ERROR: `${keyword}/FETCH_COMMITTEES_ERROR`,
  FETCH_REPORTS_ERROR: `${keyword}/FETCH_REPORTS_ERROR`,
  FETCH_BILL_ERROR: `${keyword}/FETCH_BILL_ERROR`,
};

export default constants;
