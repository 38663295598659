import pickBy from 'lodash/pickBy';
import { DEFAULT_CUSTOM_COLORS } from 'components/grassroots-campaign-builder/constants';

import { FETCH_CUSTOM_COLORS, RECEIVE_CUSTOM_COLORS } from '../constants';

export function fetchCustomColors(campaign) {
  return async (dispatch) => {
    dispatch({ type: FETCH_CUSTOM_COLORS });

    const {
      primary_color,
      secondary_color,
    } = {
      ...DEFAULT_CUSTOM_COLORS,
      ...pickBy(campaign.company_config),
      ...pickBy(campaign),
    };

    const payload = {
      primary_color,
      secondary_color,
    };

    dispatch({ type: RECEIVE_CUSTOM_COLORS, payload });
  };
}
