export const fetchDocuments = ({ state }) => ({
  ...state,
  sort: {},
  ui: {
    ...state.ui,
    isLoadingData: true,
    toResetSelectedItems: false,
  },
});

export const receiveDocuments = ({ state, action }) => ({
  ...state,
  documents: action.payload,
  paginator: action.paginator,
  sort: action.sort,
  ui: {
    ...state.ui,
    isLoadingData: false,
    isInitialLoading: false,
    toResetSelectedItems: action.toResetSelectedItems,
  },
});
