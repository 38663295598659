import { map, isEmpty } from 'lodash';

import { BILL_SLUG } from '../constants';
import { transformLegislator } from './transformLegislator';

export function transformActiveVoicesForBE(payload = {}) {
  const { id } = payload;

  return {
    attributes: {
      bill_id: id,
    },
    model_slugs: [BILL_SLUG],
  };
}

function transformBill(bill = {}) {
  const { id, title } = bill;

  return {
    href: `/state/bills/${id}`,
    value: title,
  };
}

export function transformActiveVoicesForUI(response = {}) {
  const { ratings } = response;

  if (isEmpty(ratings)) {
    return { allItems: [] };
  }

  return {
    allItems: map(ratings, (rating) => {
      const { legislator, records = [] } = rating;

      return {
        legislator: transformLegislator(legislator),
        bills: map(records, transformBill),
      };
    }),
  };
}
