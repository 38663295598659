import { NONE_ID } from 'components/core/FrequencyOptions/constants';

import constants from './constants';

export const DEFAULT_STATE = {
  committee: {},
  defaultSubscription: NONE_ID,
  tags: [],
  details: {
    members: {
      republicans: [],
      democrats: [],
      others: [],
    },
    subcommittees: [],
  },
  staffers: {
    allItems: [],
  },
  ui: {
    isLoadingCommittee: true,
    isLoadingOverview: true,
    isLoadingCommitteeMembers: true,
    isLoadingSubcommittees: true,
    isLoadingStaffers: true,
    isLoadingTags: true,
    isLoadingSubscriptions: true,
    isLoadingMyFeedDocuments: true,
  },
  sharings: [],
  trackings: [],
};

export default function committeeProfileReducers(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.LOAD_OVERVIEW:
      return {
        ...state,
        ui: { ...state.ui, isLoadingOverview: true },
      };
    case constants.LOAD_OVERVIEW_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingOverview: false },
      };
    case constants.FETCH_COMMITTEE:
      return {
        ...state,
        committee: DEFAULT_STATE.committee,
        ui: { ...state.ui, isLoadingCommittee: true },
      };
    case constants.RECEIVE_COMMITTEE_DONE:
      return {
        ...state,
        committee: action.payload,
        ui: { ...state.ui, isLoadingCommittee: false },
      };
    case constants.FETCH_COMMITTEE_MEMBERS:
      return {
        ...state,
        details: {
          ...state.details,
          members: DEFAULT_STATE.details.members,
        },
        ui: { ...state.ui, isLoadingCommitteeMembers: true },
      };
    case constants.RECEIVE_COMMITTEE_MEMBERS_DONE:
      return {
        ...state,
        details: {
          ...state.details,
          members: action.payload,
        },
        ui: { ...state.ui, isLoadingCommitteeMembers: false },
      };
    case constants.FETCH_SUBCOMMITTEES:
      return {
        ...state,
        details: {
          ...state.details,
          subcommittees: DEFAULT_STATE.details.subcommittees,
        },
        ui: { ...state.ui, isLoadingSubcommittees: true },
      };
    case constants.RECEIVE_SUBCOMMITTEES_DONE:
      return {
        ...state,
        details: {
          ...state.details,
          subcommittees: action.payload,
        },
        ui: { ...state.ui, isLoadingSubcommittees: false },
      };
    case constants.FETCH_STAFFERS:
      return {
        ...state,
        staffers: {
          ...DEFAULT_STATE.staffers,
          paginator: state.staffers.paginator,
        },
        ui: { ...state.ui, isLoadingStaffers: true },
      };
    case constants.RECEIVE_STAFFERS_DONE:
      return {
        ...state,
        staffers: action.payload,
        ui: { ...state.ui, isLoadingStaffers: false },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        trackings: action.payload,
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...state,
        sharings: action.payload,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.UPDATE_PROFILE_MY_FEED:
      return {
        ...state,
        sharings: action.payload,
      };
    case constants.RESET_COMMITTEE:
      return {
        ...state,
        ...DEFAULT_STATE,
      };
    default:
      return state;
  }
}
