import { isNil, omitBy, isEmpty } from 'lodash';

import { formatGlobalDate } from 'utils/date';
import { getSelectedTypesForBE } from 'redux/state/regulations/utils';

function getData(data) {
  if (isEmpty(data)) {
    return [];
  }

  return data;
}

export function transformPublicationsForUI(publications = {}) {
  const { data } = publications;

  return {
    allItems: getData(data).map((item) => {
      const {
        url,
        display_document_type,
        published_at,
        highlights,
        action_type_title,
        text_snippet,
      } = item || {};
      const {
        action_type_title: highlightAction,
        'text_files.text': highlightSnippet,
      } = highlights || {};

      return {
        url,
        title: highlightAction || action_type_title,
        textSnippet: highlightSnippet || text_snippet,
        actionType: display_document_type,
        date: formatGlobalDate(published_at),
      };
    }),
  };
}

export function getTimelineTitle(title, count) {
  if (isNil(count) || count < 2) {
    return title;
  }

  return `${title} (${count})`;
}

export function transformTimelineForUI(publications = {}) {
  const { data, total_count } = publications;

  return {
    totalCount: total_count,
    allItems: getData(data).map((item) => {
      const { url, title, published_at, action_type_title, count } = item || {};

      return {
        href: url,
        text: title,
        title: getTimelineTitle(action_type_title, count),
        date: published_at,
      };
    }),
  };
}

export function transformPublicationsForBE(data = {}) {
  const {
    id,
    keywordSearch,
    rangeDate,
    selectedTypes,
    withRelatedRegulations,
  } = data;
  const { startDate, endDate } = rangeDate || {};

  return {
    regulation_id: id,
    ...omitBy({
      lookup_keyword: keywordSearch,
      publication_date_from: startDate,
      publication_date_to: endDate,
      with_related_regulations: withRelatedRegulations,
      document_types: getSelectedTypesForBE(selectedTypes || []),
    }, isNil),
  };
}
