import { isNil, compact } from 'lodash';

import {
  formatAmounts,
  formatSelectValueForFE,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
  sourceSelectValueForBE,
  formatTypesForSaveBE,
  formatReceivedTypesForBE,
  formatSelectTypeToValForFE,
  getCommitteeTypeFilterValue,
  getValue,
  getLabelForUI,
  getPartiesTypeValue,
  getPluralLabel,
} from 'redux/moneyball/utils/transformers';
import { getAmountValue } from 'redux/moneyball/utils';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import { formatMoney } from 'utils/formatters';

export function transformCandidates(candidates = []) {
  return candidates.map((candidateItem) => {
    const { candidate, total_amount_received } = candidateItem;
    const { id, state_code, district_number, ...restCandidateProps } = candidate || {};
    const district = (state_code && !isNil(district_number)) && [state_code, district_number].join('-');

    return ({
      ...restCandidateProps,
      id,
      district,
      state_code,
      amount_received: `$${formatMoney(total_amount_received)}`,
      url: `/moneyball_v2/candidates/${id}`,
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    amounts,
    stateCodes,
    partyTypes,
    sourceTypes,
    candidateNames,
    officeSoughTypes,
  } = options || data;

  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('amount_received', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('state_codes', formatSelectValueToArrForBE(stateCodes)),
    ...getValue('super_candidate_ids', formatSelectValueToArrForBE(candidateNames)),
    ...getValue('source_types', formatReceivedTypesForBE(sourceTypes)),
    ...getValue('party_types', partyTypes),
    ...getValue('office_sough_types', officeSoughTypes),
  };
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const { query } = search_params || data;
    const {
      amount_received,
      candidate_names,
      party_types,
      state_codes,
      source_types,
      office_sough_types,
    } = search_params || data;

    return {
      id,
      title: name || 'Applied filters:',
      filters: compact([
        getDisplayItem('Searched', query),
        getDisplayItem('Amounts', getAmountValue(amount_received)),
        getDisplayItem('Candidate name(s)', getLabelForUI(candidate_names)),
        getDisplayItem('Party(s)', getPartiesTypeValue(party_types)),
        getDisplayItem('Office sought', office_sough_types),
        getDisplayItem('State(s)', state_codes),
        getDisplayItem(getPluralLabel('Source type', source_types), getCommitteeTypeFilterValue(source_types)),
      ]),
      options: {
        ...getValue('searchValue', query),
        options: {
          ...getValue('amounts', formatAmounts(amount_received)),
          ...getValue('stateCodes', formatSelectValueForFE(state_codes)),
          ...getValue('officeSoughTypes', office_sough_types),
          ...getValue('candidateNames', candidate_names),
          ...getValue('partyTypes', party_types),
          ...getValue('sourceTypes', formatSelectTypeToValForFE(source_types)),
        },
      },
    };
  });
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformSearchesForBE(data = {}) {
  const baseOptions = transformOptionsForBE(data);
  const { options } = data;
  const {
    stateCodes,
    partyTypes,
    sourceTypes,
    candidateNames,
    officeSoughTypes,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('state_codes', formatSelectValueToObjForBE(stateCodes)),
    ...getValue('candidate_names', sourceSelectValueForBE(candidateNames)),
    ...getValue('source_types', formatTypesForSaveBE(sourceTypes)),
    ...getValue('office_sough_types', officeSoughTypes),
    ...getValue('party_types', partyTypes),
  };
}
