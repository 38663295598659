import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import { fetchMapBox } from 'components/map/helpers';
import { MAPBOX_TYPE_BY_MATCHER_SLUG } from 'components/GrassrootsPortal/constants';
import { CAMPAIGN_LEGISLATOR_TYPE, CUSTOM } from 'components/grassroots-campaign-builder/constants';

import { RECEIVE_LEGISLATORS, FETCH_LEGISLATORS } from '../../constants';

function fetchCoordinates(addresses) {
  return getLatLng(addresses[0]);
}

function fetchGeoId({ lng, lat }) {
  const coordinates = [lng, lat].join();

  return fetchMapBox(coordinates);
}

function linkLegislatorToMatcher(matcher) {
  return legislator => ({
    ...legislator,
    matcher,
  });
}

function receiveLegislators(dispatch) {
  return legislators => dispatch(
    {
      type: RECEIVE_LEGISLATORS,
      payload: legislators,
    }
  );
}

function getGeoId(mapboxResult, strategySlug, chamberSlug) {
  const type = MAPBOX_TYPE_BY_MATCHER_SLUG[strategySlug][chamberSlug];

  const targetFeature = mapboxResult.features
    .find(feature => feature.properties.type === type);

  if (!targetFeature || !targetFeature.properties.geoId) {
    return null;
  }

  return targetFeature.properties.geoId;
}

function fetchLegislatorsForOneChamber({ matcher, mapboxResult }) {
  return async (chamberSlug) => {
    const geoId = getGeoId(mapboxResult, matcher.strategy_slug, chamberSlug);

    if (!geoId) {
      return [];
    }

    const { recipients } = await $.ajax({
      url: `/api_web/portal/grassroots/matchers/${matcher.id}/recipients`,
      data: { geo_id: geoId },
    });

    return recipients
      .filter(recipient => recipient.chamber_slug === chamberSlug)
      .map(linkLegislatorToMatcher(matcher));
  };
}

function fetchLegislatorsForOneMatcher(mapboxResult) {
  return matcher => matcher.strategy_data.chamber_slugs
    .flatMap(fetchLegislatorsForOneChamber({ matcher, mapboxResult }));
}

function fetchLegislatorsForAllMatchers(campaign) {
  return (mapboxResult) => {
    const { matchers } = campaign;
    const legislatorsRequest = matchers
      .filter(({ strategy_slug }) => strategy_slug !== CUSTOM)
      .flatMap(fetchLegislatorsForOneMatcher(mapboxResult));

    return Promise.all(legislatorsRequest)
      .then(legislators => compact(flatten(legislators)));
  };
}

function validateMapboxResult(mapboxResult) {
  const validationError = {
    address: 'We could not find any legislators for this address',
  };

  if (mapboxResult.features.length === 0) {
    throw validationError;
  }

  return mapboxResult;
}

export function fetchLegislators(formSubmission, campaign) {
  return (dispatch) => {
    if (campaign.campaign_type !== CAMPAIGN_LEGISLATOR_TYPE) {
      return null;
    }

    dispatch({ type: FETCH_LEGISLATORS });

    return geocodeByAddress(formSubmission.address)
      .then(fetchCoordinates)
      .then(fetchGeoId)
      .then(validateMapboxResult)
      .then(fetchLegislatorsForAllMatchers(campaign))
      .then(receiveLegislators(dispatch));
  };
}
