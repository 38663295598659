import isEmpty from 'lodash/isEmpty';

import * as api from 'redux/dashboardV2/common/trackings/api';
import { getMyFeedToken } from 'components/Dashboard/utils';

import constants from './utils/constants';
import { transformRecordsForBE, transformSubscriptionsForUI, transformFetchedRecords } from './transformers';
import { isTrackableRecordType, isNotFetched } from './utils';

const { addNotification } = window.app.GlobalNotifier;

const onFailure = dispatch => () => {
  addNotification({
    message: 'Could not load tracking data. Please try again.',
    level: 'error',
  });

  dispatch({ type: constants.RECEIVE_SUBSCRIPTIONS_ERROR });
};

const onSuccess = dispatch => ({ data: responseData } = {}) => {
  const successPayload = transformSubscriptionsForUI(responseData);

  dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: successPayload,
  });
};

export function fetchSubscriptions({
  records,
  isTrackable = isTrackableRecordType,
  shouldBeFetched = isNotFetched,
}) {
  return (dispatch, getState) => {
    const { fetchedRecords } = getState().tracking;

    const trackableItems = records
      .filter(isTrackable)
      .filter(shouldBeFetched(fetchedRecords));

    if (isEmpty(trackableItems)) return Promise.resolve();

    dispatch({
      type: constants.FETCH_SUBSCRIPTIONS,
      payload: transformFetchedRecords(records),
    });

    const searchForm = transformRecordsForBE(trackableItems);

    return Promise.resolve(api.fetchTrackingsBulk(searchForm, getMyFeedToken()))
      .then(onSuccess(dispatch))
      .catch(onFailure(dispatch));
  };
}

export function updateSubscription({ recordType, recordId, ...newSubscriptionData }) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_SUBSCRIPTION,
      recordType,
      recordId,
      newSubscriptionData,
    });
  };
}
