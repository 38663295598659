import includes from 'lodash/includes';

import constants, { DEFAULT_PER_PAGE, STRATEGY_SLUGS_WITHOUT_LAST_SEARCH_ID } from './constants';
import { transformSavedSearchPaginator } from './utils';

const defaultTransformer = data => data;
const genericUrl = '/api_web/advanced_search/searches';

export function loadSavedSearches(
  params,
  transformer = defaultTransformer,
  url = genericUrl
) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_SAVED_SEARCHES_DATA });

    const data = {
      ...params,
      per_page: DEFAULT_PER_PAGE,
      saved: true,
    };

    const ajax = $.get(url, data);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_SAVED_SEARCHES_DATA_DONE,
        payload: transformer(response.data),
        paginator: transformSavedSearchPaginator(response.pagination),
      }));
  };
}

export function loadRecentSearches(
  params,
  transformer = defaultTransformer,
  url = genericUrl
) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_RECENT_SEARCHES_DATA });

    const data = {
      ...params,
      page: 1,
      per_page: DEFAULT_PER_PAGE,
      saved: false,
    };

    const ajax = $.get(url, data);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_RECENT_SEARCHES_DATA_DONE,
        payload: transformer(response.data),
      }));
  };
}

export function onSaveRecentItem(data, onSuccess, url = genericUrl) {
  return (dispatch) => {
    const { advanced_search } = data;
    const { strategy_slug } = advanced_search;
    const skipSaveLastSearchId = includes(STRATEGY_SLUGS_WITHOUT_LAST_SEARCH_ID, strategy_slug);

    !skipSaveLastSearchId && dispatch({ type: constants.SAVE_RECENT_SEARCH_ITEM });

    const updateLastSearchId = (response) => {
      if (skipSaveLastSearchId) return;

      dispatch({
        type: constants.SAVE_RECENT_SEARCH_ITEM_DONE,
        payload: response,
      });
    };

    const ajax = $.ajax({
      url,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
    });

    return Promise.resolve(ajax)
      .then(updateLastSearchId)
      .then(() => {
        if (typeof onSuccess === 'function') {
          dispatch(onSuccess());
        }
      });
  };
}

export function onSaveItem(data = {}, onSuccess, url = genericUrl) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_SEARCH_ITEM });

    const { id } = data;
    const finalUrl = `${url}/${id}`;
    const ajax = $.ajax({
      url: finalUrl,
      data,
      type: 'PUT',
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.SAVE_SEARCH_ITEM_DONE,
        payload: response,
      }))
      .then(() => {
        if (typeof onSuccess === 'function') {
          dispatch(onSuccess());
        }
      });
  };
}

export function onRemoveItem(id, onSuccess, url = genericUrl) {
  return (dispatch) => {
    dispatch({ type: constants.REMOVE_SEARCH_ITEM });

    const finalUrl = `${url}/${id}`;
    const ajax = $.ajax({
      url: finalUrl,
      type: 'DELETE',
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.REMOVE_SEARCH_ITEM_DONE,
        payload: response,
      }))
      .then(() => {
        if (typeof onSuccess === 'function') {
          dispatch(onSuccess());
        }
      });
  };
}

export function setLastSearchId(lastSearchId = undefined) {
  return (dispatch) => {
    dispatch({
      type: constants.SET_LAST_SEARCH_ID,
      payload: lastSearchId,
    });
  };
}
