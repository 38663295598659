import { snakeCase, startCase, camelCase, isEmpty, compact } from 'lodash';
import { getLabelFromItem, transformSelectValues } from 'utils/transformers/advancedSearch';
import { LOCATION_TYPES } from 'utils/constants/locationTypes';
import { getDisplayItem } from 'redux/advancedSearch/utils';

function transformKeysToCase(object, transformedObject, caseTransformer = startCase, transformer = data => data) {
  object && Object.keys(object).forEach(key => (
    Object.assign(transformedObject, {
      [caseTransformer(key)]: transformer(object[key]) || [],
    })
  ));
}

export function transformKeysToSnakeCase(object, transformedObject) {
  transformKeysToCase(object, transformedObject, snakeCase, transformSelectValues);
}

export function transformKeysToCamelCase(object = {}, transformedObject) {
  transformKeysToCase(object, transformedObject, camelCase);
}

export function transformObjectToArray(object, keyName) {
  const array = Object.keys(object).map((key) => {
    if (isEmpty(object[key])) {
      return null;
    }

    return {
      id: object[key],
      [keyName]: key,
    };
  });

  return compact(array);
}

export function transformObjectToCamelCase(object) {
  if (isEmpty(object)) {
    return [];
  }

  return Object.keys(object).map(
    key => ({
      ...getDisplayItem(startCase(key),
        getLabelFromItem(object[key] || [])),
    })
  );
}

export function transformToCamelCase(array) {
  if (isEmpty(array)) {
    return [];
  }

  return array.map(item => camelCase(item));
}

export function transformSelectLocationValues(items = []) {
  return items.map(({ label, value, filters }) => ({ label, value, ...filters }));
}

export function getLocationRadius(radius, items, type) {
  const withRadius = type === LOCATION_TYPES.cities || type === LOCATION_TYPES.zipCodes;

  if (isEmpty(items) || !withRadius) {
    return null;
  }

  return radius;
}

export function getLocationType(params = {}) {
  const { locations = [] } = params;
  const filter = locations.find(filters => Boolean(filters));

  if (isEmpty(filter)) {
    return LOCATION_TYPES.none;
  }

  if (filter.city) {
    return LOCATION_TYPES.cities;
  }

  if (filter.zip) {
    return LOCATION_TYPES.zipCodes;
  }

  if (filter.state_code) {
    return LOCATION_TYPES.states;
  }

  return LOCATION_TYPES.countries;
}

export function formatLocationFilters(filter) {
  const { label, value } = filter;

  return {
    label,
    value,
    filters: filter,
  };
}

export function getLocations(params = {}) {
  const { locations = [] } = params;

  return locations.map(formatLocationFilters);
}

export function getLocationLabel(data = []) {
  return data
    .map(({ city, state_code, zip, country }) => compact([city, state_code, zip, country]).join(', '))
    .join('; ');
}

export function getLocationLabelsForRecentSearches(params = {}) {
  const { primaryAddressOnly, locationRadius, locations } = params;

  const primaryAddressFlag = primaryAddressOnly ? ' (Primary)' : '';
  const locationRadiusFlag = Number(locationRadius) > 0 ? ` (Radius: ${locationRadius} mi)` : '';
  const formattedAddress = getLocationLabel(locations);

  if (isEmpty(formattedAddress)) {
    return null;
  }

  return `[${formattedAddress};]${primaryAddressFlag}${locationRadiusFlag}`;
}

export function getLabelFromBoolean(booleanValue) {
  if (booleanValue === true) {
    return 'Yes';
  }

  if (booleanValue === false) {
    return 'No';
  }

  return undefined;
}
