const keyword = 'STATE_AGENCY_STAFFER';

const constants = {
  FETCH_STAFFER: `${keyword}/FETCH_STAFFER`,
  RECEIVE_STAFFER_DONE: `${keyword}/RECEIVE_STAFFER_DONE`,
  PROFILE_NOT_FOUND: `${keyword}/PROFILE_NOT_FOUND`,
  FETCH_TAGS: `${keyword}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${keyword}/RECEIVE_TAGS_DONE`,
};

export default constants;
