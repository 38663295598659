import { createFileId } from 'components/core/FileUpload/utils';
import {
  FILES_ADDED,
  DELETE_FILE,
  EMPTY_STATE,
} from './constants';

const defaultState = {
  filesUploaded: [],
};

function parseFileObjectToList(files, filesUploaded) {
  const uploadedFileIds = filesUploaded.map(createFileId);
  const newFilesOnly = file => !uploadedFileIds.includes(createFileId(file));

  return files.filter(newFilesOnly);
}

function replaceFilesWithoutIds(payload, filesUploaded) {
  const similarFile = uploadedFile => payloadFile =>
    payloadFile.size === uploadedFile.size && payloadFile.name === uploadedFile.name;

  return filesUploaded.map((uploadedFile) => {
    if (uploadedFile.file_upload_id) {
      return uploadedFile;
    }

    const payloadFile = payload.find(similarFile(uploadedFile));
    return payloadFile || uploadedFile;
  });
}

export default function (state = defaultState, { type, payload }) {
  switch (type) {
    case FILES_ADDED:
      return {
        ...state,
        filesUploaded: [
          ...replaceFilesWithoutIds(payload, state.filesUploaded),
          ...parseFileObjectToList(payload, state.filesUploaded),
        ],
      };
    case EMPTY_STATE:
      return {
        ...state,
        filesUploaded: [],
      };
    case DELETE_FILE:
      return {
        ...state,
        filesUploaded: state.filesUploaded.filter((_, index) =>
          index !== payload
        ),
      };
    default:
      return state;
  }
}
