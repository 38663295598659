import isEmpty from 'lodash/isEmpty';

import { getValue } from 'redux/moneyball/utils/transformers';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { fetchSearch, fetchReport } from 'redux/moneyball/utils';
import {
  loadSavedSearches,
  loadRecentSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformCorporations,
  transformSearchesForFE,
  transformOptionsSearchForBE,
  transformOptionsForBE,
} from './utils';
import constants from './constants';

export function initAppliedSearchFilters(emptyState, { searchType }) {
  return handleAppliedFilters({ ...emptyState, ...(searchType && { searchType }) });
}

export function loadCorporationSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadCorporationRecentSearches() {
  return (dispatch) => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveCorporationAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformOptionsSearchForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadCorporationRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeCorporationAdvancedSearch(id) {
  return onRemoveItem(id, loadCorporationSavedSearches);
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map((item) => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/super_corporations/export`;
  const data = JSON.stringify({
    super_corporation_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'name',
      'url',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'total_amount_contributed',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function searchCorporations(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CORPORATIONS });

    return dispatch(
      fetchSearch({
        payload,
        url: `${gon.moneyballApiV2}/api/v1/super_corporations/lookup`,
        actionType: constants.RECEIVE_CORPORATIONS_DONE,
        transformResponsePayload: transformCorporations,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformOptionsSearchForBE,
        saveRecentAdvancedSearch: saveCorporationAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return (dispatch) => dispatch(resetAppliedFilters());
}
