import constants from './constants';

export const DEFAULT_STATE = {
  staffer: {},
  tags: [],
  ui: {
    isLoading: true,
    isLoadingTags: true,
    isProfileNotFound: false,
  },
};

export default function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_PROFILE:
      return {
        ...state,
        ui: { ...state.ui, isLoading: true },
      };
    case constants.RECEIVE_PROFILE_DONE:
      return {
        ...state,
        staffer: action.payload.person,
        ui: { ...state.ui, isLoading: false },
      };
    case constants.PROFILE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, isProfileNotFound: true },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    default:
      return state;
  }
}
