import { head } from 'lodash';

import * as api from 'redux/dashboardV2/feedPage/api';

import { isFirstPage } from '../utils';
import constants from '../constants';

export function setCreatedAt(dispatch, payload) {
  const { data, pagination } = payload || {};
  const { current_page: page } = pagination || {};

  if (isFirstPage({ page })) {
    const { created_at: createdAt } = head(data) || {};
    dispatch({
      type: constants.SET_CREATED_TO,
      payload: createdAt,
    });
  }
}

export function onUpdateLastVisit(feedToken, latestEventToken) {
  return (dispatch) => {
    const onSuccess = () => dispatch({ type: constants.SET_SEEN });

    const onFailure = (error) => {
      dispatch({ type: constants.SET_SEEN });
      throw error;
    };

    return Promise.resolve(api.updateSeen(feedToken, latestEventToken))
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function onResetLastVisit(dispatch) {
  dispatch({
    type: constants.SET_LAST_VISIT,
    payload: {},
  });
  dispatch({
    type: constants.SET_CREATED_TO,
    payload: '',
  });
}
