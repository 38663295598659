import moment from 'moment';
import { isEqual, unionWith, map, filter, includes, size, get } from 'lodash';

import { CALENDAR_DATE_FORMAT } from 'utils/constants/date';
import { extractAllEvents } from 'components/core/HearingCalendars/utils';

import constants from './constants';
import { groupResponseByDate } from './utils';

function getAjax(url, data, method = 'POST') {
  return $.ajax({
    method,
    url,
    data,
  });
}

function getEndDate(startDate, monthsAhead = 1) {
  return moment(startDate).add(monthsAhead, 'month').format(CALENDAR_DATE_FORMAT);
}

function filterResponseByCommittees(selectedCommittees, apiEvents) {
  return size(selectedCommittees) > 0
    ? { data: filter(apiEvents, event => includes(selectedCommittees, get(event.committee, 'id'))) }
    : { data: apiEvents };
}

function filterResponseByStates(selectedStates, apiEvents) {
  return size(selectedStates) > 0
    ? { data: filter(apiEvents, event => includes(selectedStates, event.state.alpha2)) }
    : { data: apiEvents };
}

export function fetchEvents(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.FETCH_EVENTS, payload });

    const { monthsAheadToFetch } = getState().state.hearingCalendar;

    const {
      page = 1,
      start_date,
      end_date = getEndDate(start_date, monthsAheadToFetch),
    } = payload;

    const url = '/api_web/state/committee_hearings/lookup';
    const data = {
      per_page: 10,
      page,
      start_date,
      end_date,
    };

    const ajax = getAjax(url, data);

    return Promise.resolve(ajax)
      .then((response) => {
        const { selectedCommittees, selectedStates } = getState().state.hearingCalendar;
        const filteredByCommittees = filterResponseByCommittees(selectedCommittees, response.data);
        const filteredByStates = filterResponseByStates(selectedStates, filteredByCommittees.data);

        return dispatch({
          type: constants.FETCH_EVENTS_SUCCESS,
          apiEvents: response.data,
          payload: groupResponseByDate(filteredByStates),
        });
      });
  };
}

export function onSelectCalendarDate(selectedDate) {
  return (dispatch) => {
    const payload = { start_date: selectedDate };

    dispatch({ type: constants.SELECT_CALENDAR_DATE, selectedDate });
    dispatch(fetchEvents(payload));
  };
}

export function onCheckAllEvents(isChecked) {
  return (dispatch, getState) => {
    const { events } = getState().state.hearingCalendar;
    const checkedEvents = isChecked ? extractAllEvents(events) : [];

    dispatch({ type: constants.CHECK_EVENTS, checkedEvents });
  };
}

function onClearAllCheckedEvents(dispatch) {
  dispatch({
    type: constants.CHECK_EVENTS,
    checkedEvents: [],
  });
}

export function onClearAllEvents() {
  return dispatch => onClearAllCheckedEvents(dispatch);
}

export function onCheckSelectedEvent(selectedEvent, isChecked) {
  return (dispatch, getState) => {
    const { checkedEvents } = getState().state.hearingCalendar;
    let filteredEvents = [];

    if (isChecked) {
      selectedEvent.isChecked = isChecked;
      filteredEvents = unionWith(checkedEvents, [selectedEvent], isEqual);
    } else {
      filteredEvents = checkedEvents.filter(event => event !== selectedEvent);
    }

    dispatch({
      type: constants.CHECK_EVENTS,
      checkedEvents: filteredEvents,
    });
  };
}

export function filterByCommittee(selectedItems) {
  const selectedCommittees = selectedItems.map(item => item.value);

  return (dispatch, getState) => {
    const { apiEvents } = getState().state.hearingCalendar;
    const response = filterResponseByCommittees(selectedCommittees, apiEvents);

    onClearAllCheckedEvents(dispatch);

    dispatch({
      type: constants.FILTER_EVENTS,
      selectedCommittees,
      payload: groupResponseByDate(response),
    });
  };
}

export function filterByState(selectedItems) {
  const selectedStates = map(selectedItems, 'value');

  return (dispatch, getState) => {
    const { apiEvents } = getState().state.hearingCalendar;
    const response = filterResponseByStates(selectedStates, apiEvents);

    onClearAllCheckedEvents(dispatch);

    dispatch({
      type: constants.FILTER_EVENTS,
      selectedStates,
      payload: groupResponseByDate(response),
    });
  };
}

export function setMonthsAheadToFetch(payload) {
  return {
    type: constants.SET_MONTHS_AHEAD,
    payload,
  };
}
