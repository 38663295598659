import constants from './constants';

const DEFAULT_STATE = {
  summary: {
    title: '',
    sharings: [],
  },
  ui: {
    isLoading: true,
    isExporting: false,
  },
};

function document(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.RECEIVE_SUMMARY_DONE:
      return {
        ...state,
        summary: {
          ...action.payload,
          sharings: state.summary.sharings,
          tags: state.summary.tags,
        },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        summary: {
          ...state.summary,
          tags: action.payload,
        },
      };
    case constants.RECEIVE_IS_SHARED_DONE:
      return {
        ...state,
        summary: {
          ...state.summary,
          sharings: action.payload,
        },
      };
    case constants.FETCH_DETAILS:
      return {
        ...state,
        ui: { ...state.ui, isLoading: true },
      };
    case constants.RECEIVE_DETAILS_DONE:
      return {
        ...state,
        ui: { ...state.ui, isLoading: false },
      };
    case constants.RECEIVE_DETAILS_ERROR:
      return {
        ...state,
        ui: { ...state.ui, isLoading: false },
      };
    case constants.EXPORT_DOCUMENT:
      return {
        ...state,
        ui: { ...state.ui, isExporting: true },
      };
    case constants.EXPORT_DOCUMENT_DONE:
      return {
        ...state,
        ui: { ...state.ui, isExporting: false },
      };
    default:
      return state;
  }
}

export default document;
