import { combineReducers } from 'redux';

import constants from './constants';

export const DEFAULT_STATE = {
  results: [],
  summaries: {},
  ui: {
    isLoading: false,
    isLoadingSummary: {},
  },
  pagination: {},
};

function lookup(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_RESULTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: !action.isInfiniteLoad,
          isLoadingSummary: action.isInfiniteLoad ? state.ui.isLoadingSummary : {},
        },
      };
    case constants.RECEIVE_RESULTS_DONE:
      return {
        ...state,
        results: action.isInfiniteLoad ? [...state.results, ...action.payload.results] : action.payload.results,
        pagination: action.payload.pagination,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case constants.RECEIVE_RESULTS_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case constants.FETCH_SUMMARY:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingSummary: {
            ...state.ui.isLoadingSummary,
            [action.recordType]: {
              ...state.ui.isLoadingSummary[action.recordType],
              [action.recordId]: true,
            },
          },
        },
      };
    case constants.RECEIVE_SUMMARY_DONE:
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [action.recordType]: {
            ...state.summaries[action.recordType],
            [action.recordId]: action.payload,
          },
        },
        ui: {
          ...state.ui,
          isLoadingSummary: {
            ...state.ui.isLoadingSummary,
            [action.recordType]: {
              ...state.ui.isLoadingSummary[action.recordType],
              [action.recordId]: false,
            },
          },
        },
      };
    case constants.RECEIVE_SUMMARY_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingSummary: {
            ...state.ui.isLoadingSummary,
            [action.recordType]: {
              ...state.ui.isLoadingSummary[action.recordType],
              [action.recordId]: false,
            },
          },
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  lookup,
});
