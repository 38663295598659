import { formatTokens } from 'redux/dashboardV2/common/subscriptions/utils';
import { buildBulkUpdatePayloadForBE } from 'redux/dashboardV2/common/subscriptions/transformers';
import { BASE_FEEDS_URL } from 'redux/dashboardV2/constants';
import { postJson, putJson } from 'utils/api';

export function fetchSeen(token) {
  const ajax = $.ajax({
    type: 'GET',
    url: `${BASE_FEEDS_URL}/${token}/last_visit/feed`,
  });

  return Promise.resolve(ajax);
}

export function updateSeen(feedToken, latestEventToken) {
  const ajax = $.ajax({
    type: 'POST',
    url: `${BASE_FEEDS_URL}/${feedToken}/last_visit/feed`,
    data: { latest_event_token: latestEventToken },
  });

  return Promise.resolve(ajax);
}

export function fetchKeywords(withRemoved, token) {
  const ajax = $.ajax({
    type: 'POST',
    data: {
      show_all: withRemoved,
    },
    url: `${BASE_FEEDS_URL}/${token}/keywords/lookup`,
  });

  return Promise.resolve(ajax);
}

export function fetchData({ url, data, method = 'POST' }) {
  const ajax = $.ajax({
    type: method,
    url,
    data,
  });

  return Promise.resolve(ajax);
}

export function updateEvent({ feedToken, id, data }) {
  const url = `${BASE_FEEDS_URL}/${feedToken}/events/${id}`;

  const ajax = putJson(url, data);

  return Promise.resolve(ajax);
}

export function removeEvent(feedToken, id) {
  const ajax = $.post(`${BASE_FEEDS_URL}/${feedToken}/events/${id}/disable`);

  return Promise.resolve(ajax);
}

export function undoEvent(feedToken, id) {
  const ajax = $.post(`${BASE_FEEDS_URL}/${feedToken}/events/${id}/enable`);

  return Promise.resolve(ajax);
}

export function undoSubscription(feedToken, id) {
  const ajax = $.post(`${BASE_FEEDS_URL}/${feedToken}/subscriptions/${id}/enable`);

  return Promise.resolve(ajax);
}

export function bulkRemoveEvents(feedToken, tokens) {
  const url = `${BASE_FEEDS_URL}/${feedToken}/events/bulk_disable`;
  const requestData = { tokens: formatTokens(tokens) };

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
}

export function bulkUpdateEvents({
  feedToken,
  tokens,
  frequency,
  sendWeekAheadEmail,
  priority,
  isSaved,
  isRead,
}) {
  const url = `${BASE_FEEDS_URL}/${feedToken}/events/bulk_update`;
  const requestData = buildBulkUpdatePayloadForBE({
    tokens,
    frequency,
    sendWeekAheadEmail,
    priority,
    isSaved,
    isRead,
  });

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
}
