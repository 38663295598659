const keyword = 'STAFFERS';

const constants = {
  FETCH_STAFFERS: `${keyword}/FETCH_STAFFERS`,
  RECEIVE_STAFFERS_DONE: `${keyword}/RECEIVE_STAFFERS_DONE`,
};

export const FEDERAL_LEGISLATOR_SLUG = 'federal_legislator';
export const STATE_LEGISLATOR_SLUG = 'state_legislator';
export const FEDERAL_COMMITTEE_SLUG = 'federal_committee';
export const STATE_EXECUTIVE_SLUG = 'state_executive';

export default constants;
