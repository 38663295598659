import moment from 'moment';

import {
  CAMPAIGN_LEGISLATOR_TYPE,
  CAMPAIGN_REGULATION_TYPE,
} from 'components/grassroots-campaign-builder/constants';
import { SORT_DIRECTIONS } from 'utils/constants';
import { CALENDAR_DATE_FORMAT } from 'utils/constants/date';

import {
  BASE_URL,
  FETCH_STATISTICS,
  RECEIVE_STATISTICS_LOADING,
  RECEIVE_STATISTICS_ADVOCATES_COUNT,
  RECEIVE_STATISTICS_MESSAGES_COUNT,
  RECEIVE_STATISTICS_MESSAGES_SENT,
  RECEIVE_STATISTICS_TOP_STATES,
  RECEIVE_STATISTICS_EDITED_MESSAGES,
  RECEIVE_STATISTICS_STATE_RECIPIENTS,
  RECEIVE_STATISTICS_FEDERAL_RECIPIENTS,
  RECEIVE_STATISTICS_CUSTOM_RECIPIENTS,
  RECEIVE_STATISTICS_SENDERS,
  RECEIVE_STATISTICS_REGULATIONS,
  FETCHING_TABLE_STATISTICS,
  FETCHED_TABLE_STATISTICS,
  SET_DATE_RANGE,
  PER_PAGE,
} from '../constants';

export function setDateRange(dateRange) {
  return {
    type: SET_DATE_RANGE,
    payload: dateRange,
  };
}

export function fetchStatisticsMessagesCount(campaign) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/actions/count`
      : `${BASE_URL}/actions/count`;

    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(results =>
        dispatch({
          type: RECEIVE_STATISTICS_MESSAGES_COUNT,
          payload: results,
        })
      );
  };
}

export function fetchStatisticsAdvocatesCount(campaign) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/senders/count`
      : `${BASE_URL}/senders/count`;

    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(results =>
        dispatch({
          type: RECEIVE_STATISTICS_ADVOCATES_COUNT,
          payload: results,
        })
      );
  };
}

const initialMessagesSentOptions = {
  starts_on: moment().subtract(1, 'months').format(CALENDAR_DATE_FORMAT),
  ends_on: moment().format(CALENDAR_DATE_FORMAT),
};

export function fetchStatisticsMessagesSent(campaign, options = initialMessagesSentOptions) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/actions`
      : `${BASE_URL}/actions`;

    const ajax = $.get(url, options);

    return Promise.resolve(ajax)
      .then(({ data }) =>
        dispatch({
          type: RECEIVE_STATISTICS_MESSAGES_SENT,
          payload: data,
        })
      );
  };
}

export function fetchStatisticsTopStates(campaign) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/actions/top_states`
      : `${BASE_URL}/actions/top_states`;

    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(({ data }) =>
        dispatch({
          type: RECEIVE_STATISTICS_TOP_STATES,
          payload: data,
        })
      );
  };
}

export function fetchStatisticsEditedMessages(campaign) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/actions/edited`
      : `${BASE_URL}/actions/edited`;

    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(({ data }) =>
        dispatch({
          type: RECEIVE_STATISTICS_EDITED_MESSAGES,
          payload: data,
        })
      );
  };
}

const initialSendersOptions = {
  page: 1,
  order_mode: SORT_DIRECTIONS.DESC,
  order_column: 'created_at',
};

export function fetchStatisticsSenders(campaign, options = initialSendersOptions) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/senders`
      : `${BASE_URL}/senders`;

    options = {
      ...options,
      per_page: PER_PAGE,
    };

    const ajax = $.get(url, options);

    dispatch({ type: FETCHING_TABLE_STATISTICS });

    return Promise.resolve(ajax)
      .then((data) => {
        dispatch({
          type: RECEIVE_STATISTICS_SENDERS,
          payload: data,
        });
      })
      .finally(() => dispatch({ type: FETCHED_TABLE_STATISTICS }));
  };
}

const initialFederalRecipientsOptions = {
  page: 1,
  order_mode: SORT_DIRECTIONS.DESC,
  order_column: 'last_send_date',
};

export function fetchStatisticsFederalRecipients(campaign, options = initialFederalRecipientsOptions) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/federal_recipients`
      : `${BASE_URL}/federal_recipients`;

    options = {
      ...options,
      per_page: PER_PAGE,
    };

    const ajax = $.get(url, options);

    dispatch({ type: FETCHING_TABLE_STATISTICS });

    return Promise.resolve(ajax)
      .then(data =>
        dispatch({
          type: RECEIVE_STATISTICS_FEDERAL_RECIPIENTS,
          payload: data,
        })
      )
      .finally(() => dispatch({ type: FETCHED_TABLE_STATISTICS }));
  };
}

const initialStateRecipientsOptions = {
  page: 1,
  order_mode: SORT_DIRECTIONS.DESC,
  order_column: 'last_send_date',
};

export function fetchStatisticsStateRecipients(campaign, options = initialStateRecipientsOptions) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/state_recipients`
      : `${BASE_URL}/state_recipients`;

    options = {
      ...options,
      per_page: PER_PAGE,
    };

    const ajax = $.get(url, options);

    dispatch({ type: FETCHING_TABLE_STATISTICS });

    return Promise.resolve(ajax)
      .then(data =>
        dispatch({
          type: RECEIVE_STATISTICS_STATE_RECIPIENTS,
          payload: data,
        })
      )
      .finally(() => dispatch({ type: FETCHED_TABLE_STATISTICS }));
  };
}

const initialRegulationsOptions = {
  page: 1,
  order_mode: SORT_DIRECTIONS.DESC,
  order_column: 'last_send_date',
};


const initialCustomRecipientsOptions = {
  page: 1,
  order_mode: SORT_DIRECTIONS.DESC,
  order_column: 'last_send_date',
};

export function fetchStatisticsCustomRecipients(campaign, options = initialCustomRecipientsOptions) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/custom_recipients`
      : `${BASE_URL}/custom_recipients`;

    options = {
      ...options,
      per_page: PER_PAGE,
    };

    const ajax = $.get(url, options);

    dispatch({ type: FETCHING_TABLE_STATISTICS });

    return Promise.resolve(ajax)
      .then(data =>
        dispatch({
          type: RECEIVE_STATISTICS_CUSTOM_RECIPIENTS,
          payload: data,
        })
      )
      .finally(() => dispatch({ type: FETCHED_TABLE_STATISTICS }));
  };
}

export function fetchStatisticsRegulations(campaign, options = initialRegulationsOptions) {
  return (dispatch) => {
    const url = campaign.token
      ? `${BASE_URL}/campaigns/${campaign.token}/regulations`
      : `${BASE_URL}/regulations`;

    options = {
      ...options,
      per_page: PER_PAGE,
    };

    const ajax = $.get(url, options);

    dispatch({ type: FETCHING_TABLE_STATISTICS });

    return Promise.resolve(ajax)
      .then(data =>
        dispatch({
          type: RECEIVE_STATISTICS_REGULATIONS,
          payload: data,
        })
      )
      .finally(() => dispatch({ type: FETCHED_TABLE_STATISTICS }));
  };
}

export function fetchStatistics(campaign = {}) {
  return (dispatch) => {
    dispatch({ type: FETCH_STATISTICS, campaign });

    const commonRequests = [
      fetchStatisticsMessagesCount(campaign),
      fetchStatisticsAdvocatesCount(campaign),
      fetchStatisticsMessagesSent(campaign),
      fetchStatisticsEditedMessages(campaign),
      fetchStatisticsTopStates(campaign),
    ];

    const regulationRequests = [
      fetchStatisticsSenders(campaign),
    ];

    const legislatorRequests = [
      fetchStatisticsStateRecipients(campaign),
      fetchStatisticsFederalRecipients(campaign),
      fetchStatisticsCustomRecipients(campaign),
      fetchStatisticsSenders(campaign),
    ];

    const generalRequests = [
      fetchStatisticsStateRecipients(campaign),
      fetchStatisticsFederalRecipients(campaign),
      fetchStatisticsCustomRecipients(campaign),
      fetchStatisticsRegulations(campaign),
      fetchStatisticsSenders(campaign),
    ];

    const campaignRequests = {
      [CAMPAIGN_REGULATION_TYPE]: regulationRequests,
      [CAMPAIGN_LEGISLATOR_TYPE]: legislatorRequests,
    }[campaign.campaign_type];

    const asyncRequests = [
      ...commonRequests,
      ...(campaignRequests || generalRequests),
    ].map(dispatch);

    return Promise.all(asyncRequests).then(() =>
      dispatch({
        type: RECEIVE_STATISTICS_LOADING,
      })
    );
  };
}
