import { isEmpty, omitBy } from 'lodash';

import { FEDERAL_LEGISLATOR_SLUG, STATE_LEGISLATOR_SLUG } from './constants';

export function getVotingRecordUrl(slug) {
  const votingRecordUrls = {
    [FEDERAL_LEGISLATOR_SLUG]: '/api_web/federal/bill_votes/lookup',
    [STATE_LEGISLATOR_SLUG]: '/api_web/state/bill_votes/lookup',
  };

  if (!votingRecordUrls[slug]) {
    throw Error('The voting record slug must be valid');
  }

  return votingRecordUrls[slug];
}

export function transformBillsAndVotesForBE(payload) {
  const { id, keywordSearch, congress, ...restProps } = payload;

  return {
    ...restProps,
    search_form: omitBy({
      congress,
      legislator_id: id,
      full_text: keywordSearch,
    }, isEmpty),
  };
}

export function transformBillsAndVotesForUI(votingData = {}) {
  const { data } = votingData;

  if (isEmpty(data)) {
    return { allItems: [] };
  }

  return {
    allItems: data.map((item = {}) => {
      const {
        id,
        bill_id,
        vote_date,
        bill_display_id,
        bill_display_title,
        bill_url,
        bill_introduced_at,
        vote_display_number,
        vote_result,
        vote_url,
        vote_question,
        legislator_votes = [],
      } = item;
      return {
        id,
        date: bill_introduced_at,
        billId: bill_id,
        billName: bill_display_id,
        billTitle: bill_display_title,
        billUrl: bill_url,
        voteType: legislator_votes && legislator_votes.map(legislator => legislator.vote).join(', '),
        voteDate: vote_date,
        voteNumber: vote_display_number,
        result: vote_result,
        voteQuestion: vote_question,
        voteNumberUrl: vote_url,
      };
    }),
  };
}
