import { PAGE_SIZE } from 'components/core/Profile/utils/constants';
import { FEDERAL_BILLS_TYPE } from 'components/state-leg-watchlist/constants';
import {
  transformSponsorsAndCosponsorsForUI,
  transformTimeline as transformStateTimeline,
  transformKeyDetails as transformStateKeyDetails,
} from 'redux/state/billProfile/utils';
import {
  transformCommitteesForUI,
  transformCosponsorsForUI,
  transformOfficialReportsForUI,
  transformTimeline as transformFederalTimeline,
  transformProfileForUI as transformFederalKeyDetails,
} from 'redux/federal/billProfile/utils';
import { transformPaginator } from 'utils/transformers/paginator';

import constants from './constants';

const PORTAL_URL = '/api_web/portal';

function watchlistUrl({ watchlistToken, isPortal }) {
  const suffix = `state_leg_watchlists/${watchlistToken}`;

  if (isPortal) {
    // uses api_web_portal_state_leg_watchlist_path
    return `${PORTAL_URL}/${suffix}`;
  }

  // uses api_web_state_leg_watchlist_path
  return `/api_web/${suffix}`;
}

function watchlistBillUrl({ watchlistToken, type, billId }) {
  const prefix = `${PORTAL_URL}/state_leg_watchlists/${watchlistToken}`;

  return `${prefix}/${type}_bills/${billId}`;
}

function transformTimeline(type, payload) {
  if (type === FEDERAL_BILLS_TYPE) {
    return transformFederalTimeline(payload);
  }

  return transformStateTimeline(payload);
}

function transformKeyDetails(type, payload) {
  if (type === FEDERAL_BILLS_TYPE) {
    return transformFederalKeyDetails(payload);
  }

  return transformStateKeyDetails(payload);
}

export function receiveWatchlist(payload = {}) {
  return { type: constants.RECEIVE_WATCHLIST, payload };
}

export function fetchWatchlist(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_WATCHLIST, payload });

    const url = watchlistUrl(payload);

    const ajax = $.ajax({ url });

    return Promise
      .resolve(ajax)
      .then(result => dispatch(receiveWatchlist(result)));
  };
}

export function selectBill(payload) {
  return { type: constants.SELECT_BILL, payload };
}

export function deselectBill(payload = {}) {
  return { type: constants.DESELECT_BILL, payload };
}

export function fetchBill(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILL, payload });

    const url = watchlistBillUrl(payload);

    const ajax = $.ajax({ url });

    const onFailure = (error) => {
      dispatch({ type: constants.BILL_NOT_FOUND });
      throw error;
    };

    return Promise
      .resolve(ajax)
      .then(result => dispatch({
        type: constants.RECEIVE_BILL,
        payload: transformKeyDetails(payload.type, result),
      }))
      .catch(onFailure);
  };
}

export function fetchSponsorsAndCosponsors(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_SPONSORS_AND_COSPONSORS });

    const url = `${watchlistBillUrl(payload)}/sponsors`;

    const { page = 1, perPage = PAGE_SIZE } = payload;
    const data = { page, per_page: perPage };

    const ajax = $.ajax({ url, data });

    const onFailure = (error) => {
      dispatch({ type: constants.FETCH_SPONSORS_ERROR });
      throw error;
    };

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_SPONSORS_AND_COSPONSORS,
        payload: {
          ...transformSponsorsAndCosponsorsForUI(response),
          paginator: transformPaginator(response, perPage),
        },
      }))
      .catch(onFailure);
  };
}

export function fetchTimeline(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILL_TIMELINE });

    const url = `${watchlistBillUrl(payload)}/actions`;

    const ajax = $.ajax({ url });

    const onFailure = (error) => {
      dispatch({ type: constants.FETCH_TIMELINE_ERROR });
      throw error;
    };

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_BILL_TIMELINE,
        payload: transformTimeline(payload.type, response),
      }))
      .catch(onFailure);
  };
}

export function fetchCommittees(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILL_COMMITTEES });

    const url = `${watchlistBillUrl(payload)}/committees`;

    const ajax = $.ajax({ url });

    const onFailure = (error) => {
      dispatch({ type: constants.FETCH_COMMITTEES_ERROR });
      throw error;
    };

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_BILL_COMMITTEES,
        payload: transformCommitteesForUI(response),
      }))
      .catch(onFailure);
  };
}

export function fetchReports(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILL_REPORTS });

    const url = `${watchlistBillUrl(payload)}/reports`;

    const ajax = $.ajax({ url });

    const onFailure = (error) => {
      dispatch({ type: constants.FETCH_REPORTS_ERROR });
      throw error;
    };

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_BILL_REPORTS,
        payload: transformOfficialReportsForUI(response),
      }))
      .catch(onFailure);
  };
}

export function fetchCosponsors(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILL_COSPONSORS });

    const url = `${watchlistBillUrl(payload)}/cosponsors`;

    const ajax = $.ajax({ url });

    const onFailure = (error) => {
      dispatch({ type: constants.FETCH_SPONSORS_ERROR });
      throw error;
    };

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_BILL_COSPONSORS,
        payload: transformCosponsorsForUI(response),
      }))
      .catch(onFailure);
  };
}
