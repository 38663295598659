import constants from './constants';

export const DEFAULT_STATE = {
  supportedStates: [],
  ui: {
    isLoadingSupportedStates: true,
  },
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_SUPPORTED_STATES:
      return {
        ...state,
        supportedStates: DEFAULT_STATE.supportedStates,
        ui: {
          ...state.ui,
          isLoadingSupportedStates: true,
        },
      };
    case constants.RECEIVE_SUPPORTED_STATES_DONE:
      return {
        ...state,
        supportedStates: action.payload,
        ui: {
          ...state.ui,
          isLoadingSupportedStates: false,
        },
      };
    default:
      return state;
  }
}
