import { isNil, omitBy } from 'lodash';

import { PAGE_SIZE } from 'components/core/Profile/utils/constants';
import { PER_PAGE } from 'redux/federal/profile/actions';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { fetchAjaxData } from 'redux/utils';
import { fetchProfileTags } from 'redux/tags/actions';
import { fetchDefaultSubscription, fetchProfileSubscriptions } from 'redux/subscriptions/actions';
import { fetchProfileDocument, updateProfileShare } from 'redux/events/actions';

import {
  transformProfileForUI,
  transformCommitteesForUI,
  transformCosponsorsForUI,
  transformOfficialReportsForUI,
  transformTextVersionsForUI,
  transformTimeline,
  transformRollCallVotesForUI,
  transformRollCallVotesForBE,
  transformAmendmentsForBE,
  transformAmendmentsForUI,
  transformActiveVoicesForBE,
  transformActiveVoicesForUI,
  transformLobbyingInformationForUI,
} from './utils';
import constants, { API_BASE } from './constants';

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}

export function fetchSubscriptions(payload) {
  return (dispatch) => {
    dispatch(fetchDefaultSubscription(constants));
    dispatch(fetchProfileSubscriptions({ payload }, constants));
  };
}

export function onUpdateTrackings({ trackings }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: trackings,
  });
}

export function fetchProfile(payload = {}) {
  const { id } = payload;
  const actions = {
    fetchStarted: constants.FETCH_PROFILE,
    fetchFinished: constants.RECEIVE_PROFILE_DONE,
    failure: constants.FETCH_BILL_ERROR,
    notFound: constants.BILL_NOT_FOUND,
  };

  const url = `${API_BASE}/${id}`;

  const onSuccess = () => (dispatch) => {
    dispatch(fetchTags(payload));
    dispatch(fetchProfileDocument(payload, constants));
    dispatch(fetchSubscriptions(payload));
  };

  return fetchAjaxData({
    transformItemForUI: transformProfileForUI,
    onSuccess,
    actions,
    url,
  });
}

export function fetchCommittees(payload = {}) {
  const { id } = payload;
  const actions = {
    fetchStarted: constants.FETCH_COMMITTEES,
    fetchFinished: constants.RECEIVE_COMMITTEES_DONE,
    failure: constants.FETCH_COMMITTEES_ERROR,
  };
  const url = `${API_BASE}/${id}/committees`;

  return fetchAjaxData({
    actions,
    url,
    transformItemForUI: transformCommitteesForUI,
  });
}

export function fetchCosponsors(payload = {}) {
  const { id } = payload;
  const actions = {
    fetchStarted: constants.FETCH_COSPONSORS,
    fetchFinished: constants.RECEIVE_COSPONSORS_DONE,
    failure: constants.FETCH_SPONSORS_ERROR,
  };
  const url = `${API_BASE}/${id}/cosponsors`;

  return fetchAjaxData({
    actions,
    url,
    transformItemForUI: transformCosponsorsForUI,
  });
}

export function fetchReports(payload = {}) {
  const { id } = payload;
  const actions = {
    fetchStarted: constants.FETCH_REPORTS,
    fetchFinished: constants.RECEIVE_REPORTS_DONE,
    failure: constants.FETCH_REPORTS_ERROR,
  };
  const url = `${API_BASE}/${id}/reports`;

  return fetchAjaxData({
    actions,
    url,
    transformItemForUI: transformOfficialReportsForUI,
  });
}

export function fetchBillsComparison(payload = {}) {
  const { id } = payload;
  const actions = {
    fetchStarted: constants.FETCH_BILLS_COMPARISON,
    fetchFinished: constants.RECEIVE_BILLS_COMPARISON_DONE,
  };
  const url = `${API_BASE}/${id}/text_versions`;

  return fetchAjaxData({
    actions,
    url,
    transformItemForUI: transformTextVersionsForUI,
  });
}

export function fetchTimeline(payload = {}) {
  const { id } = payload;
  const actions = {
    fetchStarted: constants.FETCH_BILL_TIMELINE,
    fetchFinished: constants.RECEIVE_BILL_TIMELINE_DONE,
    failure: constants.FETCH_TIMELINE_ERROR,
  };
  const url = `${API_BASE}/${id}/actions`;

  return fetchAjaxData({
    actions,
    url,
    transformItemForUI: transformTimeline,
  });
}

export function fetchRollCallVotes(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_ROLL_CALL_VOTES });
    const { sort, direction, page } = payload;

    const sortData = omitBy({ sort, direction, page }, isNil);
    const rollCallVotesData = transformRollCallVotesForBE(payload);

    const ajax = $.post('/api_web/federal/bill_votes/lookup', { ...rollCallVotesData, ...PER_PAGE });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_ROLL_CALL_VOTES,
        payload: {
          ...transformRollCallVotesForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(sortData),
        },
      }));
  };
}

export function fetchAmendments(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_AMENDMENTS });
    const { sort, direction, page } = payload;

    const sortData = omitBy({ sort, direction, page }, isNil);
    const amendmentsData = transformAmendmentsForBE(payload);

    const ajax = $.post('/api_web/federal/bill_amendments/lookup', { ...amendmentsData, ...PER_PAGE });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_AMENDMENTS,
        payload: {
          ...transformAmendmentsForUI(response),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(sortData),
        },
      }));
  };
}

export function fetchActiveVoices(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_ACTIVE_VOICES });
    const activeVoicesData = transformActiveVoicesForBE(payload);

    const ajax = $.post(
      '/api_web/royal_cosponsors_oracle/federal_legislation/ratings',
      { ...activeVoicesData, ...PER_PAGE },
    );

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_ACTIVE_VOICES,
        payload: { ...transformActiveVoicesForUI(response) },
      }));
  };
}

export function fetchLobbyingInformation(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LOBBYING_INFORMATION });
    const { id } = payload;
    const ajax = $.get(`${API_BASE}/${id}/lobby_contracts`);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_LOBBYING_INFORMATION,
        payload: transformLobbyingInformationForUI(response),
      }));
  };
}

export function updateProfileMyFeed() {
  return (dispatch) => {
    dispatch(updateProfileShare(constants));
  };
}
