import { NONE_ID } from 'components/core/FrequencyOptions/constants';

import constants from './constants';

export const DEFAULT_STATE = {
  profile: {
    lastAction: {},
    keyDetails: {},
  },
  defaultSubscription: NONE_ID,
  tags: [],
  textVersions: [],
  timeline: [],
  rollCallVotes: {
    allItems: [],
    paginator: {},
  },
  amendments: {
    allItems: [],
    paginator: {},
  },
  activeVoices: {
    allItems: [],
    paginator: {},
  },
  sponsorsAndCosponsors: {
    allItems: [],
    paginator: {},
    republican: {
      numCosponsors: 0,
      partyShort: 'R',
      partySlug: 'republican',
    },
    democratic: {
      numCosponsors: 0,
      partyShort: 'D',
      partySlug: 'democratic',
    },
  },
  ui: {
    isLoadingPosition: true,
    isLoadingAmendments: true,
    isLoadingRollCallVotes: true,
    isLoadingActiveVoices: true,
    isLoadingBillsComparison: true,
    isLoadingTags: true,
    isLoadingSubscription: true,
    isLoadingMyFeedDocuments: true,
    timelineStatus: 'loading',
    cosponsorsStatus: 'loading',
    profileStatus: 'loding',
  },
  sharings: [],
  trackings: [],
};

export default function billProfileReducers(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_PROFILE:
      return {
        ...state,
        profile: DEFAULT_STATE.profile,
        ui: { ...state.ui, profileStatus: 'loading' },
      };
    case constants.RECEIVE_PROFILE_DONE:
      return {
        ...state,
        profile: action.payload,
        ui: { ...state.ui, profileStatus: 'idle' },
      };
    case constants.PROFILE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, profileStatus: 'notFound' },
      };
    case constants.FETCH_BILL_TIMELINE:
      return {
        ...state,
        ui: { ...state.ui, timelineStatus: 'loading' },
      };
    case constants.RECEIVE_BILL_TIMELINE_DONE:
      return {
        ...state,
        timeline: action.payload,
        ui: { ...state.ui, timelineStatus: 'idle' },
      };
    case constants.FETCH_ROLL_CALL_VOTES:
      return {
        ...state,
        rollCallVotes: {
          ...DEFAULT_STATE.rollCallVotes,
          paginator: state.rollCallVotes.paginator,
        },
        ui: { ...state.ui, isLoadingRollCallVotes: true },
      };
    case constants.RECEIVE_ROLL_CALL_VOTES:
      return {
        ...state,
        rollCallVotes: action.payload,
        ui: { ...state.ui, isLoadingRollCallVotes: false },
      };
    case constants.FETCH_AMENDMENTS:
      return {
        ...state,
        amendments: {
          ...DEFAULT_STATE.amendments,
          paginator: state.amendments.paginator,
        },
        ui: { ...state.ui, isLoadingAmendments: true },
      };
    case constants.RECEIVE_AMENDMENTS:
      return {
        ...state,
        amendments: action.payload,
        ui: { ...state.ui, isLoadingAmendments: false },
      };
    case constants.FETCH_BILLS_COMPARISON:
      return {
        ...state,
        ui: { ...state.ui, isLoadingBillsComparison: true },
      };
    case constants.RECEIVE_BILLS_COMPARISON_DONE:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, isLoadingBillsComparison: false },
      };
    case constants.FETCH_ACTIVE_VOICES:
      return {
        ...state,
        activeVoices: {
          ...DEFAULT_STATE.activeVoices,
        },
        ui: { ...state.ui, isLoadingActiveVoices: true },
      };
    case constants.RECEIVE_ACTIVE_VOICES:
      return {
        ...state,
        activeVoices: action.payload,
        ui: { ...state.ui, isLoadingActiveVoices: false },
      };
    case constants.FETCH_SPONSORS_AND_COSPONSORS:
      return {
        ...state,
        sponsorsAndCosponsors: {
          ...DEFAULT_STATE.sponsorsAndCosponsors,
        },
        ui: { ...state.ui, cosponsorsStatus: 'loading' },
      };
    case constants.RECEIVE_SPONSORS_AND_COSPONSORS:
      return {
        ...state,
        sponsorsAndCosponsors: action.payload,
        ui: { ...state.ui, cosponsorsStatus: 'idle' },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscription: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        trackings: action.payload,
        ui: { ...state.ui, isLoadingSubscription: false },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...state,
        sharings: action.payload,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.UPDATE_PROFILE_MY_FEED:
      return {
        ...state,
        sharings: action.payload,
      };
    case constants.FETCH_PROFILE_ERROR:
      return {
        ...state,
        ui: { ...state.ui, profileStatus: 'error' },
      };
    case constants.FETCH_SPONSORS_ERROR:
      return {
        ...state,
        ui: { ...state.ui, cosponsorsStatus: 'error' },
      };
    case constants.FETCH_TIMELINE_ERROR:
      return {
        ...state,
        ui: { ...state.ui, timelineStatus: 'error' },
      };
    default:
      return state;
  }
}
