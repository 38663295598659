import omit from 'lodash/omit';

export function transformDataForUI(data) {
  return data.map(({ updated_at, ...restProps }) => ({
    updatedAt: updated_at,
    ...restProps,
  }));
}

export function transformDataForBE({
  pagination,
  sortData,
  query,
  id,
  districtId,
  stateId,
  includeStats = false,
}) {
  const { currentPage, pageSize } = pagination || {};
  const { column, direction } = sortData || {};
  const sortParams = column && { sort: { [column]: direction } };
  const requestPagination = pagination && {
    page: currentPage,
    per_page: pageSize,
  };

  return {
    search_form: {
      query,
      dataset_id: id,
      congressional_district_ids: [districtId],
      state_ids: [stateId],
      pagination: requestPagination,
      include_rows_stats: includeStats,
      ...sortParams,
    },
  };
}

export function transformDataForPDF(dataSets) {
  return dataSets.map(({
    dataset,
    data,
    id,
    pagination,
    stats,
    is_rows_aggregate,
  }) => ({
    id,
    name: dataset,
    totalRows: pagination.total_count,
    data: data.map(item => omit(item, 'updated_at')),
    stats,
    isRowsAggregate: is_rows_aggregate,
  }));
}

export function transformPagination({
  per_page,
  total_count,
  total_pages,
  current_page,
}) {
  return {
    pageSize: per_page,
    totalCount: total_count,
    totalPages: total_pages,
    currentPage: current_page,
  };
}
