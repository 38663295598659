import delay from 'lodash/delay';

import {
  UPDATE_IS_TEMPLATE,
  UPDATE_TITLE,
  UPDATE_DESCRIPTION,
  UPDATE_TRANSACTOR,
  UPDATE_DATA,
  GET_LIST_START,
  GET_LIST_DONE,
  CREATE_LIST_START,
  CREATE_LIST_DONE,
  CREATE_LIST_FAIL,
  RESET,
} from '../constants';
import {
  transformDataForFront,
  transformDataForBack,
} from '../utils';

export function updateIsTemplate(event) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_IS_TEMPLATE,
      payload: event.target.checked,
    });
  };
}

export function updateTitle({ payload }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TITLE,
      payload,
    });
  };
}

export function updateDescription({ payload }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DESCRIPTION,
      payload,
    });
  };
}

export function updateTransactor({ payload }) {
  return (dispatch, getState) => {
    const { transactor, data } = getState().moneyball.smartSearchBuilder.builder;

    dispatch({
      type: UPDATE_TRANSACTOR,
      payload: {
        transactor: payload,
        data: payload === transactor ? data : {
          transactor: payload,
          statement: {
            id: `statementgroup-${new Date().getTime()}`,
            type: 'statements_group',
            operator: {
              label: 'And',
              value: 'and',
            },
            statements: [],
          },
        },
      },
    });
  };
}

export function updateData({ payload }) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DATA,
      payload,
    });
  };
}

export function getList({ id }) {
  return (dispatch) => {
    dispatch({ type: GET_LIST_START });

    const ajax = $.ajax({
      url: `/api_web/stakeholder-management/smart_lists/${id}`,
      type: 'GET',
    });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: GET_LIST_DONE,
        payload: transformDataForFront(response),
      }));
  };
}

export function submitList({ id, payload, onSuccess }) {
  return (dispatch) => {
    dispatch({ type: CREATE_LIST_START });

    const type = id ? 'PUT' : 'POST';
    const urlSuffix = id ? `/${id}` : '';
    const data = JSON.stringify(
      transformDataForBack(payload)
    );
    const ajax = $.ajax({
      type,
      url: `/api_web/stakeholder-management/smart_lists${urlSuffix}`,
      contentType: 'application/json',
      data,
    });

    return Promise.resolve(ajax)
      // TODO: temp fix to the builder setimeout and elasticsearch load delay
      .then(response => new Promise(resolve => delay(() => resolve(response), 2000)))
      .then(response => dispatch({
        type: CREATE_LIST_DONE,
        payload: response,
      }))
      .then(({ payload: responsePayload }) => {
        if (typeof onSuccess === 'function') {
          onSuccess(responsePayload);
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_LIST_FAIL,
        });

        throw error;
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: RESET,
    });
  };
}
