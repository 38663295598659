import { CUSTOM } from 'components/grassroots-campaign-builder/constants';

import {
  FETCH_CUSTOM_RECIPIENTS,
  RECEIVE_CUSTOM_RECIPIENTS,
} from '../../constants';

function linkCustomRecipientToMatcher(matcher) {
  return customRecipient => ({
    ...customRecipient,
    matcher,
  });
}

function receiveCustomRecipients(dispatch) {
  return customRecipients => dispatch(
    {
      type: RECEIVE_CUSTOM_RECIPIENTS,
      payload: customRecipients,
    }
  );
}

export function fetchCustomRecipients(campaign) {
  return (dispatch) => {
    const matcher = campaign.matchers
      .find(({ strategy_slug }) => strategy_slug === CUSTOM);

    if (!matcher) {
      return null;
    }

    dispatch({ type: FETCH_CUSTOM_RECIPIENTS });

    const url = `/api_web/portal/grassroots/campaigns/${campaign.token}/custom_recipients`;
    const method = 'GET';

    const ajax = $.ajax({ url, method });

    return Promise.resolve(ajax)
      .then(({ custom_recipients: customRecipients }) => (
        customRecipients.map(linkCustomRecipientToMatcher(matcher))
      ))
      .then(receiveCustomRecipients(dispatch));
  };
}
