import { getSubscriptionData } from 'redux/dashboardV2/common/subscriptions/utils';
import {
  notifyWorkspaceCreateSuccess,
  notifyWorkspaceCreateFailure,
  notifyWorkspaceUpdateSuccess,
  notifyWorkspaceUpdateFailure,
  notifyWorkspaceDeleteSuccess,
  notifyWorkspaceDeleteFailure,
} from 'components/Dashboard/common/UserNotifications';
import { BASE_FEEDS_MANAGER_URL } from 'redux/dashboardV2/constants';
import { postJson, putJson } from 'utils/api';
import { camelizeKeys } from 'utils/object';

import {
  transformWorkspacesForUI,
  transformWorkspaceForUI,
  buildSearchFormForBE,
} from './transformers';

export function fetchWorkspaces(payload = {}) {
  const url = `${BASE_FEEDS_MANAGER_URL}/lookup`;
  const searchForm = buildSearchFormForBE(payload);
  const ajax = postJson(url, searchForm);

  const onSuccess = response => transformWorkspacesForUI(camelizeKeys(response));

  return Promise.resolve(ajax)
    .then(onSuccess);
}

export const fetchWorkspace = (token) => {
  const url = `${BASE_FEEDS_MANAGER_URL}/${token}`;
  const ajax = $.get(url);

  const onSuccess = response => transformWorkspaceForUI(camelizeKeys(response));

  return Promise.resolve(ajax)
    .then(onSuccess);
};

export function createWorkspace({ name, frequency } = {}) {
  const data = { name, frequency };
  const ajax = $.post(BASE_FEEDS_MANAGER_URL, data);

  const onSuccess = (response) => {
    const { token } = response || {};
    notifyWorkspaceCreateSuccess({ token, itemTitle: name });

    return transformWorkspaceForUI(camelizeKeys(response));
  };

  const onFailure = (error) => {
    notifyWorkspaceCreateFailure();

    throw error;
  };

  return Promise.resolve(ajax)
    .then(onSuccess)
    .catch(onFailure);
}

export const updateWorkspace = ({ token, initialName, name }) => {
  const url = `${BASE_FEEDS_MANAGER_URL}/${token}`;
  const ajax = putJson(url, { name });

  const onSuccess = () => notifyWorkspaceUpdateSuccess({ token, itemTitle: name });

  const onFailure = (error) => {
    notifyWorkspaceUpdateFailure({ itemTitle: initialName });

    throw error;
  };

  return Promise.resolve(ajax)
    .then(onSuccess)
    .catch(onFailure);
};

export function deleteWorkspace({ token, name }) {
  const url = `${BASE_FEEDS_MANAGER_URL}/${token}/disable`;
  const ajax = $.post(url);

  const onSuccess = () => notifyWorkspaceDeleteSuccess({ itemTitle: name });

  const onFailure = (error) => {
    notifyWorkspaceDeleteFailure({ itemTitle: name });

    throw error;
  };

  return Promise.resolve(ajax)
    .then(onSuccess)
    .catch(onFailure);
}

export function createSubscription(subscription = {}) {
  const { modelId } = subscription;

  const url = `${BASE_FEEDS_MANAGER_URL}/${modelId}/subscribe`;
  const data = getSubscriptionData(subscription);
  const ajax = postJson(url, data);

  return Promise.resolve(ajax);
}

export function updateSubscription(subscription = {}) {
  const { modelId } = subscription;

  const url = `${BASE_FEEDS_MANAGER_URL}/${modelId}`;
  const data = getSubscriptionData(subscription);
  const ajax = putJson(url, data);

  return Promise.resolve(ajax);
}

export function deleteSubscription(subscription) {
  const { modelId } = subscription;
  const url = `${BASE_FEEDS_MANAGER_URL}/${modelId}/unsubscribe`;
  const ajax = $.post(url);

  return Promise.resolve(ajax);
}

export function deleteSubscriptions(subscriptions) {
  const reqs = map(subscriptions, deleteSubscription);

  return Promise.all(reqs);
}
