const PREFIX = 'WORKSPACES';

const constants = {
  ADD_WORKSPACE: `${PREFIX}/ADD_WORKSPACE`,
  FETCH_WORKSPACES: `${PREFIX}/FETCH_WORKSPACES`,
  RECEIVE_WORKSPACES_DONE: `${PREFIX}/RECEIVE_WORKSPACES_DONE`,
  UPDATE_WORKSPACE_SUBSCRIPTION: `${PREFIX}/UPDATE_WORKSPACE_SUBSCRIPTION`,
  DELETE_WORKSPACE: `${PREFIX}/DELETE_WORKSPACE`,
  DELETE_WORKSPACE_FAILURE: `${PREFIX}/DELETE_WORKSPACE_FAILURE`,
  DELETE_WORKSPACES_DONE: `${PREFIX}/DELETE_WORKSPACES_DONE`,
  FETCH_WORKSPACE: `${PREFIX}/FETCH_WORKSPACE`,
  RECEIVE_WORKSPACE_DONE: `${PREFIX}/RECEIVE_WORKSPACE_DONE`,
  WORKSPACE_NOT_FOUND: `${PREFIX}/WORKSPACE_NOT_FOUND`,
  RESET_WORKSPACE: `${PREFIX}/RESET_WORKSPACE`,
};

export default constants;
