import { isEmpty, reduce, every, filter, map, forEach } from 'lodash';

export const customFieldIsEmpty = answer => isEmpty(answer) || every(answer, isEmpty);

export const transformCustomFieldAnswersForFeed = (answers = {}, customFields = {}) => map(
  filter(
    customFields,
    ({ token }) => !customFieldIsEmpty(answers[token])
  ),
  ({ name, token }) => ({ token, label: name, value: answers[token] })
);

export const transformCustomFieldAnswersForEvent = (answers = {}) => (
  reduce(answers, (result, { token, value }) => ({
    ...result,
    [token]: value,
  }), {})
);

export const transformCustomFieldsForFeed = (customFields = {}) => map(
  customFields,
  ({ field_type, editable, created_at, ...restProps }) => ({
    ...restProps,
    fieldType: field_type,
    createdAt: created_at,
    isEditable: editable,
    action: {
      enabled: restProps.enabled,
      token: restProps.token,
      editable,
    },
  })
);

export const transformCustomFieldAnswersForForm = (answers = {}, customFields = []) => {
  const enabledAnswers = {};
  forEach(customFields, ({ token }) => { enabledAnswers[token] = answers[token]; });
  return enabledAnswers;
};
