import { head, map, capitalize, orderBy } from 'lodash';

import { getTitleUrl } from 'redux/dashboardV2/feedPage/utils/transformers/utils';
import { TRACK_SUBSCRIPTION_FREQUENCIES } from 'redux/dashboardV2/constants';
import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import { formatGlobalDate } from 'utils/date';

export function transformSponsor(sponsor, baseUrl) {
  const { id, addressable_moniker, short_moniker, party_short, come_from, party_slug } = sponsor || {};

  return {
    id,
    url: getTitleUrl(id, baseUrl),
    addressableMoniker: short_moniker || addressable_moniker,
    partyShort: party_short,
    partySlug: party_slug,
    comeFrom: come_from,
  };
}

export function transformStaffer(staffer, baseUrl) {
  const { id, name, position, title } = staffer || {};

  return {
    name,
    position: position || title,
    url: getTitleUrl(id, baseUrl),
  };
}

export function transformLastAction(lastAction) {
  const { action, date } = lastAction || {};

  return {
    action,
    date: formatGlobalDate(date),
  };
}

export function transformSession(session) {
  const { name, start_date, end_date } = session || {};

  return {
    name,
    startDate: formatGlobalDate(start_date),
    endDate: formatGlobalDate(end_date),
  };
}

export function getListSubscription(subscriptions) {
  const mappedSubscriptions = map(subscriptions, ({
    isDisabled,
    is_disabled,
    subscribableType,
    subscribable_type,
    frequency,
    required_inputs,
    subscribableData,
    subscribable_data,
  }) => {
    const { model_id, send_week_ahead_email } = required_inputs || subscribableData || subscribable_data || {};
    const isRemoved = isDisabled || is_disabled || false;
    const currentFrequency = frequency || NONE_ID;

    return {
      modelId: model_id,
      type: subscribableType || subscribable_type,
      frequency: currentFrequency,
      isRemoved,
      sendWeekAheadEmail: send_week_ahead_email || false,
      label: TRACK_SUBSCRIPTION_FREQUENCIES[subscribableType || subscribable_type],
      content: isRemoved ? 'Not subscribed' : `Subscribed - ${capitalize(frequency)}`,
    };
  });

  return orderBy(mappedSubscriptions, ['label'], 'desc');
}

export function transformSubscriptions({ subscriptions, is_disabled }) {
  const { frequency, is_saved, priority } = head(subscriptions) || {};
  const tokens = map(subscriptions, 'token');
  const ids = map(subscriptions, 'id');

  return {
    id: JSON.stringify(tokens),
    ids,
    frequency,
    priority,
    isRemoved: is_disabled || false,
    isDisabled: is_disabled || false,
    isSaved: is_saved || false,
    listSubscriptions: getListSubscription(subscriptions),
  };
}
