import {
  transformDataForUI,
  transformDataForBE,
  transformDataForPDF,
  transformPagination,
} from './utils';
import constants from './constants';
import { defaultPagination } from './reducers';

function handleFecthData({ dispatch, sortData, pagination, query, id }) {
  const url = '/api_web/company-subscription/institutional-data/dataset_rows';
  const method = 'GET';
  const data = transformDataForBE({ sortData, pagination, query, id });

  const ajax = $.ajax({
    url,
    method,
    data,
  });

  function onSuccess({
    dataset,
    data: responseData,
    pagination: responsePagination,
  }) {
    dispatch({
      type: constants.RECEIVE_INDIVIDUAL_ASSET_SET_DONE,
      dataset,
      payload: transformDataForUI(responseData),
      pagination: transformPagination(responsePagination),
      sortData,
    });
  }

  function onFailure(error) {
    dispatch({
      type: constants.RECEIVE_INDIVIDUAL_ASSET_SET_FAIL,
    });

    throw error;
  }

  return Promise.resolve(ajax)
    .then(onSuccess)
    .catch(onFailure);
}

export function fetchIndividualAssetSets({ sortData = {}, pagination = defaultPagination, id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUAL_ASSET_SET });

    return handleFecthData({ dispatch, sortData, pagination, id });
  };
}

export function searchIndividualAssetSets({ query, id }) {
  return (dispatch, getState) => {
    dispatch({ type: constants.SEARCH_INDIVIDUAL_ASSET_SET });
    const { sortData, pagination } = getState().organization.individualAssetSet;

    return handleFecthData({ dispatch, sortData, pagination, query, id });
  };
}

export function resetIndividualAsset() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_INDIVIDUAL_ASSET_SET });
  };
}

export function fetchMultipleIndividualAssetSets({ ids, districtId, stateId, pagination }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_MULTIPLE_INDIVIDUAL_ASSET_SETS });

    const url = '/api_web/company-subscription/institutional-data/dataset_rows';
    const method = 'GET';

    function onSuccess(values) {
      const transformedData = transformDataForPDF(values);
      dispatch({
        type: constants.RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_DONE,
      });

      return transformedData;
    }

    function onFailure(error) {
      dispatch({
        type: constants.RECEIVE_MULTIPLE_INDIVIDUAL_ASSET_SETS_FAIL,
      });

      throw error;
    }

    const promisses = ids.map((id) => {
      const data = transformDataForBE({
        id,
        districtId,
        stateId,
        pagination,
        includeStats: true,
      });

      return $.ajax({
        url,
        method,
        data,
      });
    });

    return Promise.all(promisses)
      .then(onSuccess)
      .catch(onFailure);
  };
}
