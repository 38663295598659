import { SORT_DIRECTIONS } from 'utils/constants';

import {
  SAVE_CUSTOM_RECIPIENT,
  FAILED_SAVE_CUSTOM_RECIPIENT,
  DELETE_CUSTOM_RECIPIENTS,
  DELETED_CUSTOM_RECIPIENTS,
  FETCH_CUSTOM_RECIPIENTS,
  RECEIVE_CUSTOM_RECIPIENTS,
  RECEIVE_DELETED_CUSTOM_RECIPIENT,
  RECEIVE_PAGINATION,
} from '../constants';

export function saveCustomRecipient({ campaign, recipient }) {
  return (dispatch) => {
    dispatch({ type: SAVE_CUSTOM_RECIPIENT });

    let url = `/api_web/grassroots/campaigns/${campaign.token}/custom_recipients`;
    let method = 'POST';

    recipient.name = recipient.title;
    const data = { recipient };

    if (recipient.id) {
      url = `${url}/${recipient.id}`;
      method = 'PUT';
    }

    const ajax = $.ajax({
      url,
      method,
      data,
    });

    return Promise.resolve(ajax)
      .catch(({ responseJSON }) => {
        dispatch({ type: FAILED_SAVE_CUSTOM_RECIPIENT });
        throw responseJSON;
      });
  };
}

export function deleteCustomRecipient({ campaign, recipient }) {
  return (dispatch) => {
    const url = `/api_web/grassroots/campaigns/${campaign.token}/custom_recipients/${recipient.id}/disable`;
    const method = 'POST';

    const ajax = $.ajax({
      url,
      method,
    });

    return Promise.resolve(ajax)
      .then(result =>
        dispatch({ type: RECEIVE_DELETED_CUSTOM_RECIPIENT, payload: result })
      );
  };
}

export function deleteCustomRecipients({ campaign, recipients }) {
  return (dispatch) => {
    dispatch({ type: DELETE_CUSTOM_RECIPIENTS });

    const asyncRequests = recipients
      .map(recipient => deleteCustomRecipient({ campaign, recipient }))
      .map(dispatch);

    return Promise.all(asyncRequests)
      .then(() => dispatch({ type: DELETED_CUSTOM_RECIPIENTS }));
  };
}

const PER_PAGE = 10;

const defaultOptions = {
  page: 1,
  order_mode: SORT_DIRECTIONS.ASC,
  order_column: 'addressable_name',
  per_page: PER_PAGE,
};

const mapOrderColumn = (options) => {
  const orderColumn = {
    twitter_screen_name: 'twitter_url',
  }[options.order_column] || options.order_column;

  return {
    ...options,
    order_column: orderColumn,
  };
};

export function fetchCustomRecipients(campaignToken, options = {}) {
  return (dispatch) => {
    dispatch({ type: FETCH_CUSTOM_RECIPIENTS });

    const url = `/api_web/grassroots/campaigns/${campaignToken}/custom_recipients`;

    options = {
      ...defaultOptions,
      ...options,
    };

    options = mapOrderColumn(options);

    const ajax = $.get(url, options);

    return Promise.resolve(ajax)
      .then(({ data, pagination }) => {
        dispatch({ type: RECEIVE_CUSTOM_RECIPIENTS, payload: data });
        dispatch({ type: RECEIVE_PAGINATION, payload: { ...pagination, perPage: PER_PAGE } });
      });
  };
}
