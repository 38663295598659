import { combineReducers } from 'redux';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import constants from './constants';

export const defaultState = {
  receipt: {},
  receiptError: false,
  disbursements: [],
  disbursementsError: false,
  paginator: {},
  sort: {},
  ui: {
    isLoadingData: true,
    isInitialLoading: true,
    isReceiptLoading: true,
    isLoadingReport: false,
    toResetSelectedItems: true,
  },
};

function lookUp(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_DISBURSEMENTS:
      return {
        ...state,
        disbursementsError: false,
        sort: {},
        ui: {
          ...state.ui,
          isLoadingData: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_DISBURSEMENTS_DONE:
      return {
        ...state,
        disbursements: action.payload,
        disbursementsError: action.error,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isLoadingData: false,
          isInitialLoading: false,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.FETCH_DISBURSEMENTS_RECEIPT:
      return {
        ...state,
        receiptError: false,
        ui: {
          ...state.ui,
          isReceiptLoading: true,
        },
      };
    case constants.RECEIVE_DISBURSEMENTS_RECEIPT_DONE:
      return {
        ...state,
        receipt: action.payload,
        receiptError: action.error,
        ui: {
          ...state.ui,
          isReceiptLoading: false,
        },
      };
    case constants.SUBMIT_EXPORT_REPORT_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: true,
        },
      };
    case constants.SUBMIT_EXPORT_REPORT_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: false,
        },
      };
    default:
      return state;
  }
}


export default combineReducers({
  appliedSearchFilters,
  advancedSearch,
  lookUp,
});
