import { uniq, flatMap } from 'lodash';

import { getValue } from 'redux/moneyball/utils/transformers';

export function transformData(response = []) {
  return response.map(({ cluster, members = [] }) => {
    const primaryMember = members.find(({ is_primary }) => is_primary) || {};
    const primaryMemberSM = uniq(
      flatMap(members, ({ stakeholder_mgmt_companies }) => stakeholder_mgmt_companies)
    );

    const {
      name: primaryMemberName,
      employer: primaryMemberEmployer,
      occupation: primaryMemberOccupation,
      street_1: primaryMemberStreet1,
      state_code: primaryMemberStateCode,
      zip_code: primaryMemberZipCode,
      city: primaryMemberCity,
    } = primaryMember || {};

    const primaryMemberStateZip = [
      primaryMemberStateCode,
      primaryMemberZipCode,
    ].filter(Boolean).join(' ');

    const primaryMemberAddressFinal = [
      primaryMemberStreet1,
      primaryMemberCity,
      primaryMemberStateZip,
    ].filter(Boolean).join(', ');

    const {
      super_individuals_count,
      total_amount: clusterAmount,
      status_at,
      ...restCluster
    } = cluster || {};

    return {
      ...restCluster,
      name: primaryMemberName,
      nameClusterCounter: super_individuals_count,
      employer: primaryMemberEmployer,
      occupation: primaryMemberOccupation,
      address: primaryMemberAddressFinal,
      amount: clusterAmount,
      sm: primaryMemberSM.filter(Boolean).join(', '),
      status_at: moment(status_at).format('DD/MM/YYYY'),
      items: members.map(({
        id,
        total_amount: memberAmount,
        stakeholder_mgmt_companies: memberSM = [],
        street_1: memberStreet1,
        state_code: memberStateCode,
        zip_code: memberZipCode,
        city: memberCity,
        ...restMember
      }) => {
        const memberStateZip = [memberStateCode, memberZipCode].filter(Boolean).join(' ');
        const memberAddressFinal = [
          memberStreet1,
          memberCity,
          memberStateZip,
        ].filter(Boolean).join(', ');

        return ({
          ...restMember,
          id,
          url: `/moneyball_v2/individuals/${id}`,
          sm: memberSM.filter(Boolean).join(', '),
          address: memberAddressFinal,
          amount: memberAmount,
        });
      }),
    };
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue } = data;

  return {
    ...getValue('query', searchValue),
  };
}
