import isEmpty from 'lodash/isEmpty';
import { transformSort, transformPaginator } from 'redux/moneyball/utils';
import { getValue } from 'utils/transformers/advancedSearch';
import { isMoneyballContributionsEnabled } from 'components/core/Moneyball/utils';
import { hasRequiredInfo } from 'components/Stakeholder/Profile/components/Contributions/utils';

export function transformStakeholderToBE({
  firstName,
  lastName,
  middleName,
  facebookProfile,
  twitterName,
  partyCode,
  customFields,
  districts,
  ...restProps
}) {
  const {
    congressional,
    stateUpper,
    stateLower,
  } = districts || {};

  return {
    ...restProps,
    ...getValue('first_name', firstName),
    ...getValue('last_name', lastName),
    ...getValue('facebook_profile', facebookProfile),
    ...getValue('middle_name', middleName),
    ...getValue('party_code', partyCode),
    ...getValue('twitter_username', twitterName),
    ...getValue('custom_fields', customFields),
    ...(!isEmpty(districts) && {
      districts: {
        congressional,
        state_upper: stateUpper,
        state_lower: stateLower,
      },
    }),
  };
}

export function transformMoneyballSort(props) {
  return transformSort(props);
}

export function transformMoneyballPaginator(data) {
  return transformPaginator(data);
}

export function transformMatchingIndividuals(individuals) {
  return individuals.map(({ confidence, super_individual }) => ({
    id: super_individual.id,
    confidence,
  }));
}

export function isMoneyballPermitted(stakeholderProfile) {
  const { isMoneyballEnabled } = gon || { isMoneyballEnabled: false };

  return (isMoneyballEnabled && isMoneyballContributionsEnabled && hasRequiredInfo(stakeholderProfile));
}

export const transformMoneyballAddress = ({ street: street_1, city, state, zip }) => ({
  state_code: state || '',
  zip_code: zip || '',
  street_1,
  city,
});

export function getTransformedAddresses(addresses) {
  if (isEmpty(addresses)) {
    return [];
  }

  return addresses.map(transformMoneyballAddress);
}
