import { find, map, isEmpty, isEqual, intersection, reduce, omit } from 'lodash';

import { transformTaskForFeed } from '../transformers/toFE';
import { recordTypesByField } from '../transformers/toFE/cardLinks';
import initialState from '../initialState';

export const applyCardChanges = (cards, updatedCard) => {
  const replaceCard = cardToReplace => (
    cardToReplace.id === updatedCard.id
      ? { ...cardToReplace, ...updatedCard }
      : cardToReplace
  );

  return cards.map(replaceCard);
};

export const applyCardsChanges = (cards, updatedCards) => map(cards, (card) => {
  const cardToReplace = find(updatedCards, { id: card.id });

  return isEmpty(cardToReplace) ? card : ({ ...card, ...cardToReplace });
});

export const getUpdatedCardsByTask = (cards, tasks) => map(cards, (card) => {
  const { task } = card;

  const updatedTask = find(tasks, { id: task.id });

  if (isEmpty(updatedTask)) {
    return card;
  }

  return {
    ...card,
    task: transformTaskForFeed(updatedTask),
  };
});

const filterFieldMap = {
  ...recordTypesByField,
  activityTypes: ['cardType'],
  attachments: ['uploads'],
  creationDate: ['creationDate'],
  creator: ['createdBy'],
  eventDate: ['eventDate'],
  interactionTypes: ['interactionType'],
  keywordLookup: ['title', 'tags', 'description', 'customFieldsAnswers'],
  tags: ['tags'],
  dueDate: ['dueDateTime'],
  statusTypes: ['status'],
  priorityTypes: ['priority'],
  assignee: ['assignee'],
};

const CARD_FIELDS_TO_OMIT = ['task', 'links'];

// isCardInconsistent should be used only for edited cards,
// new cards trigger doesn't rely on filters, so it was moved elsewhere
export const isCardInconsistent = ({
  card = {},
  oldCard = {},
  filters = {},
}) => {
  const { filters: initialFilters } = initialState;

  const transformLinks = links => (
    reduce(links, (cardLinks, { type, value }) => ({
      ...cardLinks,
      [type]: value,
    }), {})
  );

  const transformedOldCard = {
    ...omit(oldCard, CARD_FIELDS_TO_OMIT),
    ...(oldCard.task || {}),
    ...transformLinks(oldCard.links),
  };
  const transformedCard = {
    ...omit(card, CARD_FIELDS_TO_OMIT),
    ...(card.task || {}),
    ...transformLinks(card.links),
  };

  const modifiedFields = reduce(transformedCard, (result, value, key) => (
    isEqual(value, transformedOldCard[key]) ? result : [
      ...result,
      key,
    ]
  ), []);

  const modifiedFilters = reduce(filters, (cardFields, value, key) => (
    isEqual(value, initialFilters[key])
      ? cardFields
      : [
        ...cardFields,
        ...(filterFieldMap[key] || []),
      ]
  ), []);

  return !isEmpty(intersection(modifiedFilters, modifiedFields));
};
