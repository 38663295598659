import { transformReceiptsForFE } from 'redux/moneyball/receiptPages/lookup/utils';
import { fetchContributionsLastSync } from './contributions_last_sync';

import constants from '../constants';
import {
  transformMoneyballSort,
  transformMoneyballPaginator,
} from '../utils';

const defaultFilters = {
  sort_column: 'contribution_date',
  sort_direction: 'desc',
};

export function fetchStakeholderContributions({ id }, filters = defaultFilters) {
  return (dispatch) => {
    dispatch(
      fetchContributionsLastSync({ id }),
      { type: constants.FETCH_STAKEHOLDER_CONTRIBUTIONS },
    );

    const url = '/api_web/stakeholder-management/contributions/lookup';
    const { page = 1, sort_column: sort, sort_direction: direction } = filters;
    const data = {
      search_form: {
        stakeholder_id: id,
        page,
        sort,
        direction,
      },
    };

    const ajax = $.ajax({
      url,
      method: 'GET',
      data,
    });

    function onSuccess(response) {
      return dispatch({
        type: constants.FETCH_STAKEHOLDER_CONTRIBUTIONS_DONE,
        error: false,
        payload: {
          items: transformReceiptsForFE(response.items),
          paginator: transformMoneyballPaginator(response),
          sort: transformMoneyballSort(filters),
        },
      });
    }

    function onFailure(error) {
      dispatch({
        type: constants.FETCH_STAKEHOLDER_CONTRIBUTIONS_FAIL,
        error: true,
        payload: {
          items: [],
          paginator: {},
          sort: {},
        },
      });

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}
