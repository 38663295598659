import { isEmpty, isNil, omitBy } from 'lodash';

/** District info section */
function checkEmptyObjValue(value, keyName) {
  if (isNil(value)) {
    return {};
  }

  return { [keyName]: value };
}

export function getCookPVIData(cookPVI) {
  const { rank, avg_value, raw_value, party_slug } = cookPVI || {};

  return {
    ...checkEmptyObjValue(rank, 'rank'),
    ...checkEmptyObjValue(avg_value, 'value'),
    ...checkEmptyObjValue(raw_value, 'rawValue'),
    ...checkEmptyObjValue(party_slug, 'partySlug'),
  };
}

export function getMapLocation(mapData) {
  const { kw_district_id, latitude, longitude } = mapData || {};

  if (isEmpty(mapData) || isNil(kw_district_id) || isNil(latitude) || isNil(longitude)) {
    return {};
  }

  return {
    kwDistrictId: kw_district_id,
    latitude,
    longitude,
    center: [`${longitude}`, `${latitude}`],
  };
}

export function getMedianHouseIncome(medianData = {}) {
  if (isEmpty(medianData)) {
    return {};
  }

  const { district_avg, us_avg, rank, rank_max } = medianData;

  if (isNil(district_avg) || isNil(us_avg)) {
    return {};
  }

  return {
    districtAverage: district_avg,
    usAverage: us_avg,
    rank,
    rankMax: rank_max,
  };
}

export function transformDistrictInfoForUI(districtInfoData = {}) {
  const {
    cook_pvi = {},
    district = {},
    education = [],
    gender_percentage = [],
    industry_info = [],
    median_household_income = {},
    racial_demographics = [],
  } = districtInfoData || {};

  const isIncomplete = isEmpty(districtInfoData) || (
    isEmpty(cook_pvi)
    && isEmpty(education)
    && isEmpty(gender_percentage)
    && isEmpty(industry_info)
    && isEmpty(median_household_income)
    && isEmpty(racial_demographics)
  );

  const { id, name, long_name } = district;

  return omitBy({
    id,
    name,
    longName: long_name,
    cookPVI: getCookPVIData(cook_pvi),
    education,
    racialDemographics: racial_demographics,
    genders: gender_percentage,
    industries: industry_info,
    mapLocation: getMapLocation(district),
    householdIncome: getMedianHouseIncome(median_household_income),
    isIncomplete,
  }, isNil);
}
