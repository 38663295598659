import {
  CAMPAIGN_REGULATION_TYPE,
  CAMPAIGN_LEGISLATOR_TYPE,
} from 'components/grassroots-campaign-builder/constants';

import { stopLoading } from '../utils';
import { fetchLegislators } from './fetchLegislators';
import { fetchCustomRecipients } from './fetchCustomRecipients';
import { fetchRegulation } from './fetchRegulation';

import { CREATE_RECIPIENTS } from '../../constants';

function createRecipients() {
  return { type: CREATE_RECIPIENTS };
}

function validateRecipientsAmount(recipients) {
  const validationError = {
    address: 'We\'re sorry, this campaign does not target any legislators from your district or state.',
  };

  if (!recipients.length) {
    throw validationError;
  }

  return recipients;
}

function loadLegislatorCampaignData({ formSubmission, campaign }) {
  return (dispatch, getState) => Promise.all([
    dispatch(fetchLegislators(formSubmission, campaign)),
    dispatch(fetchCustomRecipients(campaign)),
  ])
    .then(() => dispatch(createRecipients()))
    .then(() => validateRecipientsAmount(getState().grassrootsPortal.recipients));
}

function loadRegulationCampaignData({ campaign }) {
  return dispatch => Promise.all([
    dispatch(fetchRegulation(campaign)),
  ]);
}

export function loadStepTwoData(campaign) {
  return dispatch => (formSubmission) => {
    const loadFunction = {
      [CAMPAIGN_REGULATION_TYPE]: loadRegulationCampaignData,
      [CAMPAIGN_LEGISLATOR_TYPE]: loadLegislatorCampaignData,
    }[campaign.campaign_type];

    return dispatch(loadFunction({ formSubmission, campaign }))
      .finally(stopLoading(dispatch));
  };
}
