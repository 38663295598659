import { updateDataItemArrayPayload, updateDataItem } from 'utils/reducer';

import constants from './constants';

const DEFAULT_STATE = {
  selectedFeedKeywords: [],
  feed: [],
  pagination: {},
  sort: {},
  selectedCards: [],
  ui: {
    isLoadingFeed: false,
    isInitialLoading: true,
    isLoadingTags: false,
    isLoadingMyFeedDocuments: false,
    isSharingToMyFeed: false,
  },
};

const feedLookupKeyName = 'feed';

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_NEWS_FEED:
      return {
        ...state,
        ui: { ...state.ui, isLoadingFeed: true },
      };
    case constants.RECEIVE_NEWS_FEED_DONE:
      return {
        ...state,
        feed: action.shouldReset ? action.payload : [...state.feed, ...action.payload],
        pagination: action.pagination,
        sort: action.sort,
        ui: {
          ...state.ui,
          isLoadingFeed: false,
          isInitialLoading: false,
        },
      };
    case constants.RECEIVE_NEWS_FEED_FAILURE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingFeed: false,
          isInitialLoading: false,
        },
      };
    case constants.RESET_NEWS_FEED:
      return {
        ...state,
        feed: DEFAULT_STATE.feed,
        ui: { ...state.ui, isInitialLoading: false },
      };
    case constants.FETCH_FEED_KEYWORDS:
    case constants.RECEIVE_FEED_KEYWORDS_FAILURE:
    case constants.RESET_FEED_KEYWORDS:
      return {
        ...state,
        selectedFeedKeywords: [],
      };
    case constants.RECEIVE_FEED_KEYWORDS_DONE:
      return {
        ...state,
        selectedFeedKeywords: action.payload,
      };
    case constants.SELECT_CARDS:
      return {
        ...state,
        selectedCards: action.selectedCards,
      };
    case constants.LOAD_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, feedLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.RECEIVE_TAGS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.BULK_SAVE_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.BULK_SAVE_TAGS_DONE:
    case constants.BULK_SAVE_TAGS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.UPDATE_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.UPDATE_TAGS_DONE:
      return {
        ...updateDataItem(state, action, feedLookupKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.UPDATE_TAGS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.UPDATE_DATA_ITEM:
      return {
        ...updateDataItem(state, action, feedLookupKeyName),
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, feedLookupKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.SHARE_TO_MY_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSharingToMyFeed: true },
      };
    case constants.SHARE_TO_MY_FEED_DONE:
      return {
        ...updateDataItem(state, action, feedLookupKeyName),
        ui: { ...state.ui, isSharingToMyFeed: false },
      };
    case constants.SHARE_TO_MY_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSharingToMyFeed: false },
      };
    default:
      return state;
  }
}
