import { sumBy } from 'lodash';
import { formatPercentage } from '../common/utils';

export function transformTrendsParams({ id, selectedFeed, mention }) {
  const { id: feedId } = selectedFeed || {};
  const params = { field: 'sentiment' };

  if (id) {
    return { ...params, clusters: [id] };
  }

  if (mention) {
    return { ...params, entities_title_text: mention.text };
  }

  if (feedId) {
    return { config_id: feedId };
  }

  return params;
}

export function transformTrendsForUI(response) {
  const total = sumBy(response, 'count');
  const sentimentOverall = response.map((sentiment) => {
    const { value, count } = sentiment;

    return {
      type: value,
      percentage: formatPercentage(count / total),
    };
  });

  return { sentimentOverall };
}
