import { isNil, omitBy } from 'lodash';

import { PAGE_SIZE } from 'components/core/Profile/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { fetchLookupTags } from 'redux/tags/actions';
import { fetchLookupSubscriptions } from 'redux/subscriptions/actions';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import {
  getPublicRecordsUrl,
  transformPublicRecordsForUI,
  transformPublicRecordsForBE,
} from './utils';
import constants from './constants';

export const PER_PAGE = { per_page: PAGE_SIZE };

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchLookupTags(payload, null, constants));
}

export function fetchSubscriptions(payload = {}) {
  return dispatch => dispatch(fetchLookupSubscriptions(payload, null, constants));
}

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}

function getPublicRecordsData(payload = {}, customTransformers = {}) {
  const { sort, direction, page, slug, id } = payload;
  const { transformForBE = transformPublicRecordsForBE } = customTransformers;

  const searchQuery = transformForBE(payload);
  const searchForm = { search_form: searchQuery };

  const { url, method, strategySlug } = getPublicRecordsUrl(slug, id);
  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData, ...PER_PAGE };
  return { url, method, data, sortData, strategySlug };
}

export function fetchPublicRecords(payload = {}, customTransformers = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_PUBLIC_RECORDS });
    const { transformForUI = transformPublicRecordsForUI, transformRecords } = customTransformers;
    const { url, method, data, sortData, strategySlug } = getPublicRecordsData(payload, customTransformers);
    const currentData = { ...data, strategy_slug: strategySlug };

    const ajax = $.ajax({
      url,
      method: method || 'POST',
      data: currentData,
    });

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_PUBLIC_RECORDS_DONE,
        payload: {
          ...transformForUI({ response, transformRecords }),
          paginator: transformPaginator(response, PAGE_SIZE),
          sort: transformSort(sortData),
        },
      });
    }

    function onFailure(error) {
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(({ payload: { records } }) => dispatch(fetchTags({ payload: records })))
      .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
      .then(payloadData => dispatch(fetchSubscriptions(payloadData)))
      .catch(onFailure);
  };
}
