import { compact, isEmpty } from 'lodash';

import { getDisplayItem } from 'redux/advancedSearch/utils';
import {
  formatSelectValue, getLabelFromItem,
  getValue,
  transformSelectValues,
  mapBackendValues,
} from 'utils/transformers/advancedSearch';

import { transformOfficeForBE } from './transformers';

export function transformOptionsForBE(data = {}) {
  const { searchValue } = data;
  const {
    office,
    legislativeIssueArea,
    title,
    role,
    educations,
    officeLocationType,
    caucus,
    legislativeStaffersCommittee,
    party,
    chamber,
    stateDelegation,
    officeMembershipType,
    historicalStaffer,
  } = data.options || {};

  return {
    ...getValue('lookup_keyword', searchValue),
    ...getValue('office', transformOfficeForBE(office)),
    ...getValue('priorities', transformSelectValues(legislativeIssueArea)),
    ...getValue('titles', transformSelectValues(title)),
    ...getValue('roles', transformSelectValues(role)),
    ...getValue('educations', transformSelectValues(educations)),
    ...getValue('office_types', officeLocationType),
    ...getValue('include_historical', historicalStaffer),
    ...getValue('office_membership_types', officeMembershipType),
    ...getValue('legislator_caucus_ids', transformSelectValues(caucus)),
    ...getValue('legislator_committee_ids', transformSelectValues(legislativeStaffersCommittee)),
    ...getValue('parties', party),
    ...getValue('chambers', chamber),
    ...getValue('state_ids', transformSelectValues(stateDelegation)),
  };
}

export function getExportInfo(data = {}) {
  const { searchValue } = data;
  const {
    office,
    legislativeIssueArea,
    title,
    role,
    officeLocationType,
    caucus,
    legislativeStaffersCommittee,
    party,
    chamber,
    stateDelegation,
    educations,
    officeMembershipType,
    historicalStaffer,
  } = data.options || {};

  return {
    'strategy_inputs[lookup_keyword]': searchValue,
    'strategy_inputs[priorities]': transformSelectValues(legislativeIssueArea),
    'strategy_inputs[titles]': transformSelectValues(title),
    'strategy_inputs[roles]': transformSelectValues(role),
    'strategy_inputs[educations]': transformSelectValues(educations),
    'strategy_inputs[office_types]': officeLocationType,
    'strategy_inputs[office_membership_types]': officeMembershipType,
    'strategy_inputs[legislator_caucus_ids]': transformSelectValues(caucus),
    'strategy_inputs[legislator_committee_ids]': transformSelectValues(legislativeStaffersCommittee),
    'strategy_inputs[parties]': party,
    'strategy_inputs[chambers]': chamber,
    'strategy_inputs[state_ids]': transformSelectValues(stateDelegation),
    ...(historicalStaffer && { 'strategy_inputs[include_historical]': historicalStaffer }),
    ...transformOfficeForBE(office, true),
  };
}

function transformOptionsForUI(data = {}) {
  const { id } = data;
  const {
    lookup_keyword,
    priorities,
    titles,
    roles,
    office,
    office_types,
    office_membership_types,
    caucuses,
    legislator_committees,
    parties,
    chambers,
    states,
    educations,
    include_historical,
  } = data.search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', lookup_keyword),
      ...getValue('office', office),
      ...getValue('historicalStaffer', include_historical),
      ...getValue('legislativeIssueArea', formatSelectValue(priorities)),
      ...getValue('title', formatSelectValue(titles)),
      ...getValue('role', formatSelectValue(roles)),
      ...getValue('educations', formatSelectValue(educations)),
      ...getValue('officeLocationType', office_types),
      ...getValue('officeMembershipType', office_membership_types),
      ...getValue('caucus', caucuses),
      ...getValue('legislativeStaffersCommittee', legislator_committees),
      ...getValue('party', parties),
      ...getValue('chamber', chambers),
      ...getValue('stateDelegation', states),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}, stafferData = {}) {
  const { name } = data;
  const {
    lookup_keyword,
    priorities,
    titles,
    roles,
    office_membership_types,
    office_types,
    caucuses,
    legislator_committees,
    parties,
    office,
    chambers,
    states,
    educations,
    include_historical,
  } = data.search_params;

  const filters = [
    getDisplayItem('Searched', lookup_keyword),
    getDisplayItem('Serves', getLabelFromItem(office)),
    getDisplayItem('Legislative issue area', priorities),
    getDisplayItem('Title', titles),
    getDisplayItem('Role', roles),
    getDisplayItem('Include historical staffers', include_historical && 'Yes'),
    getDisplayItem('Educations', educations),
    getDisplayItem('Office location', mapBackendValues(office_types, stafferData.offices)),
    getDisplayItem('Office type', mapBackendValues(office_membership_types, stafferData.officeMembershipTypes)),
    getDisplayItem('Caucus', getLabelFromItem(caucuses)),
    getDisplayItem('Legislative staffers on committee', getLabelFromItem(legislator_committees)),
    getDisplayItem('Party', mapBackendValues(parties, stafferData.parties)),
    getDisplayItem('Chamber', mapBackendValues(chambers, stafferData.chambers)),
    getDisplayItem('Legislator state', getLabelFromItem(states)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

function getEducations(educations) {
  if (isEmpty(educations)) {
    return [];
  }

  return educations.map((education) => {
    const { school, end_date: endDate } = education || {};

    if (isEmpty(endDate)) {
      return school;
    }

    return `${school}, ${endDate}`;
  });
}

export function transformStaffersForUI(staffers = []) {
  return staffers.map(({
    id,
    alias_name,
    full_name,
    party,
    boss_name,
    boss_party,
    title,
    phone,
    email,
    chamber,
    location,
    role,
    office_type,
    office_membership_type,
    responsibilities,
    party_short,
    party_slug,
    boss_party_short,
    boss_party_slug,
    educations,
    ...restProps
  }) => ({
    ...restProps,
    id,
    name: alias_name || full_name,
    party,
    partyShort: party_short,
    partySlug: party_slug,
    bossPartyShort: boss_party_short,
    bossPartySlug: boss_party_slug,
    url: `/federal/congressional_staffers/${id}`,
    office: { name: boss_name, party: boss_party },
    title,
    role,
    phone,
    email,
    chamber,
    location,
    educations: getEducations(educations),
    officeLocationType: office_type,
    officeMembershipType: office_membership_type,
    legislativeIssueArea: responsibilities,
  }));
}

export function transformSearchesForUI(data, stafferData) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item, stafferData),
    ...transformOptionsForUI(item),
  }));
}
