const PREFIX = 'SERVICE_TERMS';

export default {
  LOAD_SERVICE_TERMS_STARTED: `${PREFIX}/LOAD_SERVICE_TERMS_STARTED`,
  LOAD_SERVICE_TERMS_FAIL: `${PREFIX}/LOAD_SERVICE_TERMS_FAIL`,
  LOAD_SERVICE_TERMS_DONE: `${PREFIX}/LOAD_SERVICE_TERMS_DONE`,
  ACCEPT_SERVICE_TERMS_STARTED: `${PREFIX}/ACCEPT_SERVICE_TERMS_STARTED`,
  ACCEPT_SERVICE_TERMS_FAIL: `${PREFIX}/ACCEPT_SERVICE_TERMS_FAIL`,
  ACCEPT_SERVICE_TERMS_DONE: `${PREFIX}/ACCEPT_SERVICE_TERMS_DONE`,
};
