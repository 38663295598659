import { PRODUCT_SLUGS } from 'components/core/CustomFields/utils/constants';
import notifications from 'components/Grm2/notifications';

import constants from '../constants';
import { transformCustomFieldsForFeed } from '../transformers';

export const fetchCustomFields = () => (
  (dispatch) => {
    const url = '/api_web/custom_fields/fields';
    const method = 'GET';
    const data = {
      search_form: {
        enabled: true,
        product_slugs: [PRODUCT_SLUGS.GRM],
      },
      per_page: 200,
    };

    dispatch({ type: constants.START_LOADING_CUSTOM_FIELDS });

    const ajax = $.ajax({ url, method, data });

    return Promise.resolve(ajax)
      .then(({ data: responseData = [] }) => {
        const transformedCustomFields = transformCustomFieldsForFeed(responseData);
        dispatch({ type: constants.SET_CUSTOM_FIELDS, payload: transformedCustomFields });

        return transformedCustomFields;
      })
      .catch(notifications.generalFailNotification)
      .finally(() => dispatch({ type: constants.STOP_LOADING_CUSTOM_FIELDS }));
  }
);
