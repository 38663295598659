import constants from '../constants';

const { addNotification } = window.app.GlobalNotifier;

const onError = (dispatch, recordType, recordId) => (error) => {
  const { status } = error;

  dispatch({
    type: constants.RECEIVE_SUMMARY_ERROR,
    recordType,
    recordId,
  });

  if (status === 404) {
    return;
  }

  addNotification({
    message: 'Could not load the summaries. Please try again.',
    level: 'error',
  });
};

const onSuccess = (dispatch, recordType, recordId) => ({ body }) => {
  dispatch({
    type: constants.RECEIVE_SUMMARY_DONE,
    payload: body,
    recordType,
    recordId,
  });
};

export function fetchSummary({ recordType, recordId }) {
  return (dispatch) => {
    dispatch({
      type: constants.FETCH_SUMMARY,
      recordType,
      recordId,
    });

    const ajax = $.get(`api_web/summaries/${recordType}/${recordId}`);

    Promise.resolve(ajax)
      .then(onSuccess(dispatch, recordType, recordId))
      .catch(onError(dispatch, recordType, recordId));
  };
}

export default fetchSummary;
