import moment from 'moment';
import { isEmpty, omitBy, isNil, capitalize, size } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getSlicedLegislators } from 'redux/bills/utils';
import { DAY_YEAR_DATE_FORMAT, GLOBAL_DATE_FORMAT } from 'utils/constants/date';
import { FEDERAL_BILL_ITEM_TYPE } from 'utils/constants/itemTypes';
import { getUrlWithBase } from 'utils/url';

function getSponsorUrl({ legislator_id, id }) {
  const legislatorId = legislator_id || id;

  return { url: `/federal/legislators/${legislatorId}` };
}

export function transformBill(bill = {}, index, page) {
  const {
    id,
    congress,
    cosponsors = [],
    description,
    display_bill_id,
    display_title,
    has_vote,
    introduced_at,
    last_action = { text: '', date: '' },
    sponsor = {},
    status_full,
    text_version_link,
    overview = '',
    tags,
    summary,
  } = omitBy(bill, isNil);

  const billUrl = !isEmpty(id) && `/federal/bills/${id}`;

  return {
    index,
    page,
    id,
    billId: display_bill_id,
    billTitle: display_title,
    billDescription: description,
    billUrl,
    introducedAt: moment(introduced_at).format(GLOBAL_DATE_FORMAT),
    lastAction: last_action && last_action.text,
    lastActionDate: last_action && last_action.date && moment(last_action.date).format(GLOBAL_DATE_FORMAT),
    sponsors: [{
      id: sponsor.id,
      name: sponsor.name,
      partyShort: sponsor.party_short,
      partySlug: sponsor.party_slug,
      url: sponsor.url,
    }],
    status: status_full,
    allCosponsorsSize: size(cosponsors),
    cosponsors: getSlicedLegislators(cosponsors, getSponsorUrl),
    govPredictLink: getUrlWithBase(billUrl),
    tags,
    congress,
    hasVote: capitalize(has_vote),
    billTextLink: text_version_link,
    snippet: overview,
    billSummary: summary && summary.body,
    billSummaryLastUpdated: summary && moment(summary.updated_at).format(DAY_YEAR_DATE_FORMAT),
    documentType: DOCUMENT_TYPES.FEDERAL_BILL,
    itemType: FEDERAL_BILL_ITEM_TYPE,
    recordId: id,
    withTrack: true,
  };
}
