import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { STATE_AGENCY_STAFFER_ITEM_TYPE } from 'utils/constants/itemTypes';
import { transformExecutive } from 'utils/transformers/profile/executive';

export function transformStaffer(staffer) {
  return {
    ...transformExecutive(staffer),
    documentType: DOCUMENT_TYPES.STATE_AGENCY_STAFFER,
    itemType: STATE_AGENCY_STAFFER_ITEM_TYPE,
  };
}
