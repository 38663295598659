import { combineReducers } from 'redux';

import advancedSearch from 'redux/advancedSearch/reducers';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import exportReport from 'redux/exportReport/reducers';
import customFields from 'redux/customFields/reducers';
import stakeholder from 'redux/stakeholder/common/reducers';
import tableColumns from 'components/Stakeholder/Lookup/utils/tableColumns';

import constants from './constants';

export const defaultState = {
  stakeholders: [],
  stakeholdersMap: {},
  stakeholderLists: [],
  districtStakeholdersMetadata: {
    totalCount: 0,
    pages: 0,
  },
  tableColumns,
  paginator: {},
  sort: {},
  emails: '',
  ui: {
    isInitialLoading: true,
    isLoadingData: true,
    isLoadingMapData: true,
    isLoadingLists: true,
    isLoadingAllEmails: false,
    isApplyingLists: false,
    isApplyingTags: false,
    toResetSelectedItems: true,
    isResetFilters: false,
    isLoadingDistrictStakeholdersMetadata: false,
  },
};

function stakeholderLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_STAKEHOLDERS:
      return {
        ...state,
        sort: defaultState.sort,
        ui: { ...state.ui, isLoadingData: true, toResetSelectedItems: false, isResetFilters: false },
      };
    case constants.FETCH_STAKEHOLDERS_MAP:
      return {
        ...state,
        stakeholdersMap: defaultState.stakeholdersMap,
        ui: { ...state.ui, isLoadingMapData: true, toResetSelectedItems: false, isResetFilters: false },
      };
    case constants.RECEIVE_STAKEHOLDERS_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingData: false,
          isInitialLoading: false,
        },
      };
    case constants.RECEIVE_STAKEHOLDERS_DONE:
      return {
        ...state,
        stakeholders: action.payload,
        paginator: action.paginator,
        tableColumns: action.tableColumns,
        sort: action.sort,
        ui: {
          ...state.ui,
          isLoadingData: false,
          isInitialLoading: false,
          toResetSelectedItems: action.toResetSelectedItems,
          isResetFilters: action.isResetFilters,
        },
      };
    case constants.RECEIVE_STAKEHOLDERS_MAP_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingMapData: false,
          isInitialLoading: false,
          toResetSelectedItems: false,
          isResetFilters: false,
        },
      };
    case constants.RECEIVE_STAKEHOLDERS_MAP_DONE:
      return {
        ...state,
        stakeholdersMap: action.payload,
        ui: {
          ...state.ui,
          isLoadingMapData: false,
          isInitialLoading: false,
          toResetSelectedItems: false,
          isResetFilters: false,
        },
      };
    case constants.FETCH_STAKEHOLDER_LISTS:
      return {
        ...state,
        stakeholderLists: [],
        ui: { ...state.ui, isLoadingLists: true },
      };
    case constants.RECEIVE_STAKEHOLDER_LISTS_DONE:
      return {
        ...state,
        stakeholderLists: action.payload,
        ui: { ...state.ui, isLoadingLists: false },
      };
    case constants.FETCH_STAKEHOLDER_EMAILS:
      return {
        ...state,
        emails: defaultState.emails,
        ui: { ...state.ui, isLoadingAllEmails: true },
      };
    case constants.SUBMIT_APPLIED_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isApplyingTags: true },
      };
    case constants.SUBMIT_STAKEHOLDER_LISTS:
      return {
        ...state,
        ui: { ...state.ui, isApplyingLists: true },
      };
    case constants.SUBMIT_APPLIED_TAGS_DONE:
      return {
        ...state,
        stakeholders: action.payload,
        ui: { ...state.ui, isApplyingTags: false },
      };
    case constants.SUBMIT_APPLIED_TAGS_FAIL:
      return {
        ...state,
        ui: { ...state.ui, isApplyingTags: false },
      };
    case constants.SUBMIT_STAKEHOLDER_LISTS_DONE:
      return {
        ...state,
        stakeholders: action.payload,
        ui: { ...state.ui, isApplyingLists: false },
      };
    case constants.SUBMIT_STAKEHOLDER_LISTS_FAIL:
      return {
        ...state,
        ui: { ...state.ui, isApplyingLists: false },
      };
    case constants.RECEIVE_STAKEHOLDER_EMAILS_DONE:
      return {
        ...state,
        emails: action.payload,
        ui: { ...state.ui, isLoadingAllEmails: false },
      };

    case constants.RECEIVE_DISTRICT_STAKEHOLDERS_METADATA:
      return {
        ...state,
        districtStakeholdersMetadata: action.payload,
        ui: {
          ...state.ui,
          isLoadingDistrictStakeholdersMetadata: false,
        },
      };

    case constants.FETCH_DISTRICT_STAKEHOLDERS_METADATA:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingDistrictStakeholdersMetadata: true,
        },
      };

    case constants.DISTRICT_STAKEHOLDERS_METADATA_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingDistrictStakeholdersMetadata: false,
        },
      };

    case constants.RESET_STATE:
      return defaultState;

    default:
      return state;
  }
}

export default combineReducers({
  stakeholder,
  stakeholderLookup,
  advancedSearch,
  appliedFilters: appliedSearchFilters,
  exportReport,
  customFields,
});
