import { isEmpty, omitBy, isNil } from 'lodash';

import { transformSelectValues } from 'utils/transformers/advancedSearch';

import { transformOptionsForBE } from './transformers';
import { LOCAL_EXECUTIVES_URL } from './constants';

export function getExportInfo(data = {}) {
  const { searchValue, options } = data;
  const {
    executives,
    organizations,
    parties,
    states,
    titles,
  } = options;

  return {
    'strategy_inputs[keyword]': searchValue || undefined,
    'strategy_inputs[person_ids]': transformSelectValues(executives),
    'strategy_inputs[states]': transformSelectValues(states),
    'strategy_inputs[organizations]': transformSelectValues(organizations),
    'strategy_inputs[titles]': transformSelectValues(titles),
    'strategy_inputs[parties]': parties,
  };
}

export function getExportSortInfo(data = {}) {
  const { direction, sort } = data;

  return {
    'strategy_inputs[order_column]': sort,
    'strategy_inputs[order_mode]': direction,
  };
}

export function getExecutivesData(payload) {
  const {
    searchData = {},
    appliedFilters,
    clearAll,
    sort,
    direction,
    page,
    perPage,
  } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const sortData = omitBy({ sort, direction, page, per_page: perPage }, isNil);
  const data = { ...searchForm, ...sortData };

  return { isSearch, url: LOCAL_EXECUTIVES_URL, data, sortData };
}
