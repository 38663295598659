const PREFIX = 'STATE_STAFFERS';

const constants = {
  STRATEGY_SLUG: 'state_legislation_staffers',
  STRATEGY_SLUG_FOR_EXPORT: 'state_legislation_staffers',
  FETCH_STAFFERS: `${PREFIX}/FETCH_STAFFERS`,
  RECEIVE_STAFFERS_DONE: `${PREFIX}/RECEIVE_STAFFERS_DONE`,
  FETCH_STAFFERS_EMAILS: `${PREFIX}/FETCH_STAFFERS_EMAILS`,
  RECEIVE_STAFFERS_EMAILS_DONE: `${PREFIX}/RECEIVE_STAFFERS_EMAILS_DONE`,
  RESET_STAFFERS: `${PREFIX}/RESET_STAFFERS`,
};

export default constants;
