import { transformToBE } from './utils';
import constants from './constants';

export function mergeStakeholders({ payload, handleFailure, handleSuccess } = {}) {
  return (dispatch) => {
    dispatch({ type: constants.MERGE_STAKEHOLDERS });

    const url = '/api_web/stakeholder-management/merge_stakeholders';
    const data = { merge: transformToBE(payload) };

    const ajax = $.post(url, data);

    function onSuccess() {
      dispatch({
        type: constants.MERGE_STAKEHOLDERS_DONE,
        payload,
      });

      const { id } = payload.stakeholder || {};
      handleSuccess(id);
    }

    function onFailure(error) {
      dispatch({ type: constants.MERGE_STAKEHOLDERS_FAIL });
      handleFailure(error);

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}
