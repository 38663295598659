import { isEmpty, sortedUniqBy, compact, startCase, get, map } from 'lodash';

import { DOCUMENT_TYPES, responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import getMappedValues from 'components/core/TagInput/getMappedValues';
import { getDisplayItem, mapBackendValues } from 'redux/advancedSearch/utils';
import {
  getLabelFromItem,
  getValue,
  getUniqueLabelFromItem,
  transformSelectValues,
  transformArraySelectValues,
} from 'utils/transformers/advancedSearch';
import { STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE } from 'utils/constants/itemTypes';

function transformOptionsForUI(data = {}) {
  const { id, search_params } = data;
  const {
    lookup_keyword,
    legislators,
    parties,
    committees,
    chambers,
    state_alpha2s,
    include_historical,
    only_first_term,
    tag_ids,
  } = search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', lookup_keyword),
      ...getValue('legislator', legislators),
      ...getValue('historicalLegislator', Boolean(include_historical)),
      ...getValue('firstTermLegislator', Boolean(only_first_term,)),
      ...getValue('committee', getMappedValues(committees)),
      ...getValue('party', parties),
      ...getValue('chamber', chambers),
      ...getValue('stateDelegation', state_alpha2s),
      ...getValue('tags', tag_ids),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}, legislatorsProps = {}) {
  const { name, search_params } = data;
  const {
    lookup_keyword,
    legislators,
    parties,
    committees,
    chambers,
    state_alpha2s,
    include_historical,
    only_first_term,
    tag_ids,
  } = search_params;

  const filters = [
    getDisplayItem('Searched', lookup_keyword),
    getDisplayItem('Legislator', getLabelFromItem(legislators)),
    getDisplayItem('Committee', getUniqueLabelFromItem(committees)),
    getDisplayItem('Party', mapBackendValues(parties, legislatorsProps.parties)),
    getDisplayItem('Chamber', mapBackendValues(chambers, legislatorsProps.chambers)),
    getDisplayItem('State delegation', getLabelFromItem(state_alpha2s)),
    getDisplayItem('Include historical legislators', include_historical && 'Yes'),
    getDisplayItem('Show first-term legislators', only_first_term && 'Yes'),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    historicalLegislator,
    firstTermLegislator,
    committee,
    party,
    chamber,
    stateDelegation,
    tags,
  } = options || {};

  return {
    ...getValue('lookup_keyword', searchValue),
    ...getValue('ids', transformSelectValues(legislator)),
    ...getValue('committee_ids', transformArraySelectValues(committee)),
    ...getValue('include_historical', historicalLegislator),
    ...getValue('only_first_term', firstTermLegislator),
    ...getValue('parties', party),
    ...getValue('chambers', chamber),
    ...getValue('state_alpha2s', transformSelectValues(stateDelegation)),
    ...getValue('tag_ids', transformSelectValues(tags)),
  };
}

export function transformSearchesForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    historicalLegislator,
    firstTermLegislator,
    committee,
    party,
    chamber,
    stateDelegation,
    tags,
  } = options || {};

  return {
    ...getValue('lookup_keyword', searchValue),
    ...getValue('ids', transformSelectValues(legislator)),
    ...getValue('committee_ids', transformArraySelectValues(committee)),
    ...getValue('include_historical', historicalLegislator),
    ...getValue('only_first_term', firstTermLegislator),
    ...getValue('parties', party),
    ...getValue('chambers', chamber),
    ...getValue('state_alpha2s', transformSelectValues(stateDelegation)),
    ...getValue('tag_ids', tags),
  };
}

export function transformSearchesForUI(data, legislatorsProps) {
  return map(data, item => ({
    ...getDisplayDataForRecentSearches(item, legislatorsProps),
    ...transformOptionsForUI(item),
  }));
}

function getTitle(data) {
  return data ? `(${startCase(data)})` : '';
}

function getCommittees(committeesData = []) {
  const sortedCommittees = sortedUniqBy(
    committeesData,
    committee => get(committee, 'committee.name')
  );

  return map(sortedCommittees, (committee = {}) => {
    if (isEmpty(committee) || isEmpty(committee.name)) {
      return null;
    }

    const { name = '', title } = committee;

    return `${name} ${getTitle(title)}`;
  });
}

export function transformLegislatorsForUI(legislators) {
  return map(legislators, ({
    id,
    name,
    chamber,
    district,
    party,
    party_short,
    party_slug,
    state,
    committee_memberships,
    email,
  }) => ({
    id,
    name,
    chamber,
    party,
    state: state.alpha2,
    district,
    partyShort: party_short,
    partySlug: party_slug,
    committee: getCommittees(committee_memberships),
    email,
    recordId: id,
    documentType: DOCUMENT_TYPES.STATE_LEGISLATOR,
    trackingType: responseTrackingTypes.STATE_LEGISLATOR_STAFFER,
    itemType: STATE_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    withTrack: true,
  }));
}

export function getExportInfo(data = {}) {
  const { searchValue } = data;
  const {
    legislator,
    committee,
    party,
    chamber,
    stateDelegation,
    historicalLegislator,
    firstTermLegislator,
    tags,
  } = data.options || {};

  return {
    'strategy_inputs[lookup_keyword]': searchValue || undefined,
    ...(!isEmpty(legislator) && { 'strategy_inputs[ids]': transformSelectValues(legislator) }),
    'strategy_inputs[parties]': party,
    'strategy_inputs[chambers]': chamber,
    'strategy_inputs[state_alpha2s]': transformSelectValues(stateDelegation),
    'strategy_inputs[committee_ids]': transformArraySelectValues(committee),
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    ...(historicalLegislator && { 'strategy_inputs[include_historical]': historicalLegislator }),
    ...(firstTermLegislator && { 'strategy_inputs[only_first_term]': firstTermLegislator }),
  };
}
