import { compact, size } from 'lodash';

import {
  getValue,
  getPluralLabel,
  getLabelForUI,
  getAmountsValueByType,
  getCommitteeTypeValue,
  getCommitteeTypeFilterValue,
  getPartiesTypeValue,
  formatAmountsValueByType,
  formatSelectValueForFE,
  formatTypesForSaveBE,
  formatReceivedTypesForBE,
  formatReceivedAmountsForBE,
  formatDisbursedAmountsForBE,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
  formatSelectTypeToValForFE,
  sourceSelectValueForBE,
} from 'redux/moneyball/utils/transformers';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import { getUniqueValues } from 'redux/moneyball/utils';

export function transformCommittees(committees = []) {
  return committees.map((committeeItem) => {
    const { committee, amounts } = committeeItem;
    const { id, name, committees: oldCommittees, ...restProps } = committee || {};
    const { received, donated } = amounts || {};

    const { value: nameValue, info: nameInfo } = getUniqueValues('name', name, oldCommittees);

    return ({
      ...restProps,
      id,
      name: nameValue,
      url: `/moneyball_v2/committees/${id}`,
      amount: received,
      amountDisbursed: donated,
      info: size(oldCommittees) > 1 && {
        name: nameInfo,
      },
    });
  });
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const {
      query,
      amount_donated,
      amount_received,
      address_state_codes,
      super_committee_ids,
      party_types,
      source_types,
      types,
    } = search_params || {};

    const { amountLabel, amountValue } = getAmountsValueByType(amount_donated, amount_received);

    return ({
      id,
      title: name || 'Applied filters:',
      filters: compact([
        getDisplayItem('Searched', query),
        getDisplayItem(amountLabel, amountValue),
        getDisplayItem('State(s)', address_state_codes),
        getDisplayItem('Committee Name(s)', getLabelForUI(super_committee_ids)),
        getDisplayItem('Committee Type(s)', getCommitteeTypeValue(types)),
        getDisplayItem('Party(s)', getPartiesTypeValue(party_types)),
        getDisplayItem(getPluralLabel('Source type', source_types), getCommitteeTypeFilterValue(source_types)),
      ]),
      options: {
        ...getValue('searchValue', query),
        options: {
          ...getValue('amounts', formatAmountsValueByType(amount_donated, amount_received)),
          ...getValue('states', formatSelectValueForFE(address_state_codes)),
          ...getValue('partyTypes', party_types),
          ...getValue('sourceTypes', formatSelectTypeToValForFE(source_types)),
          ...getValue('committeeNames', super_committee_ids),
          ...getValue('committeeTypes', types),
        },
      },
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    amounts,
    states,
    partyTypes,
    sourceTypes,
    committeeNames,
    committeeTypes,
  } = options || data;
  const { type, fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('amount_donated', {
      ...getValue('start', formatDisbursedAmountsForBE(type, fromValue)),
      ...getValue('end', formatDisbursedAmountsForBE(type, toValue)),
    }),
    ...getValue('amount_received', {
      ...getValue('start', formatReceivedAmountsForBE(type, fromValue)),
      ...getValue('end', formatReceivedAmountsForBE(type, toValue)),
    }),
    ...getValue('address_state_codes', formatSelectValueToArrForBE(states)),
    ...getValue('super_committee_ids', formatSelectValueToArrForBE(committeeNames)),
    ...getValue('source_types', formatReceivedTypesForBE(sourceTypes)),
    ...getValue('party_types', partyTypes),
    ...getValue('types', committeeTypes),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformOptionsSearchForBE(data = {}) {
  const { options } = data;
  const baseOptions = transformOptionsForBE(data);
  const {
    states,
    partyTypes,
    sourceTypes,
    committeeNames,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('source_types', formatTypesForSaveBE(sourceTypes)),
    ...getValue('address_state_codes', formatSelectValueToObjForBE(states)),
    ...getValue('super_committee_ids', sourceSelectValueForBE(committeeNames)),
    ...getValue('party_types', partyTypes),
  };
}
