import { isEmpty, map, reduce, groupBy, flatMap } from 'lodash';

import { getMyFeed } from 'components/Dashboard/utils';

export function transformSubscription(payload = {}) {
  const {
    id,
    token,
    frequency,
    is_saved,
    priority,
    title,
    subscribable_type,
    subscribable_data: subscribableData,
    is_disabled,
  } = payload;
  const { send_week_ahead_email } = subscribableData || {};

  return ({
    id,
    token,
    frequency,
    isSaved: is_saved,
    priority,
    title,
    subscribableType: subscribable_type,
    subscribableData,
    isDisabled: is_disabled,
    sendWeekAheadEmail: send_week_ahead_email,
    isTracking: !isEmpty(frequency),
  });
}

export const transformTracking = ({ feed, subscription }) => ({
  feed,
  subscription: transformSubscription(subscription),
});

export function transformSubscriptions(data = [], documentType) {
  const subscriptionsByRecord = groupBy(data, 'record_id');

  return reduce(subscriptionsByRecord, (acc, recordTrackings, key) => {
    acc.push({
      recordId: key,
      documentType,
      trackings: map(
        recordTrackings,
        transformTracking,
      ),
    });

    return acc;
  }, []);
}

export function reduceSubscriptions(data) {
  return reduce(data, (accumulator, item) => {
    const { record_type, subscriptions } = item;
    const subscription = transformSubscriptions(subscriptions, record_type);

    accumulator.push(...subscription);

    return accumulator;
  }, []);
}

export function transformCommitteeTrackings(data) {
  const trackingsByFeed = groupBy(data, 'feed.token');

  return reduce(trackingsByFeed, (acc, trackings) => {
    const [tracking] = trackings;

    acc.push({
      feed: tracking.feed,
      subscriptions: map(trackings, 'subscription'),
    });

    return acc;
  }, []);
}

export function transformCommitteeSubscriptions(data = [], documentType) {
  const allSubscriptions = reduceSubscriptions(data);

  return map(groupBy(allSubscriptions, 'recordId'), (value, recordId) => {
    const trackings = flatMap(value, 'trackings');

    return {
      recordId,
      documentType,
      trackings: transformCommitteeTrackings(trackings),
    };
  });
}

export function transformCommitteeProfileSubscriptions(data = []) {
  const allSubscriptions = reduceSubscriptions(data);
  const trackings = flatMap(allSubscriptions, 'trackings');

  return transformCommitteeTrackings(trackings);
}

export function transformSharedDocuments(data = [], documentType) {
  return map(data, ({ subscribable_data }) => {
    const { model_id: recordId } = subscribable_data || {};

    return {
      recordId,
      documentType,
      sharings: [getMyFeed()], // TODO: adjust once we have share to workspaces
    };
  });
}
