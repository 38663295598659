import {
  FETCH_CAMPAIGN,
  RECEIVE_CAMPAIGN,
  CAMPAIGN_NOT_FOUND,
} from '../constants';

export function fetchCampaign(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN, payload });

    const url = `/api_web/grassroots/campaigns/${payload.campaignToken}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(campaign =>
        dispatch({ type: RECEIVE_CAMPAIGN, payload: campaign })
      )
      .catch((error) => {
        dispatch({ type: CAMPAIGN_NOT_FOUND });
        throw error;
      });
  };
}
