import { omitBy, isNil, get, find, map, uniq, flatMap, sortBy } from 'lodash';

import {
  FACEBOOK,
  TWITTER,
  LINKEDIN,
  INSTAGRAM,
  YOUTUBE,
} from 'utils/socialMedia/names';

import {
  FACEBOOK_KEY,
  TWITTER_KEY,
  LINKEDIN_KEY,
  INSTAGRAM_KEY,
  YOUTUBE_KEY,
  EMAIL_KEY,
} from './constants';

const getUrl = (urls, socialMediaKey) => get(find(urls, (({ type }) => type === socialMediaKey)), 'url');

export const getOrganizations = positions => (
  uniq(flatMap(positions, ({ organizations }) => map(sortBy(organizations, 'level'), 'name')))
);

export function transformAgencyStaffer(agencyStaffer = {}) {
  const {
    id,
    full_name,
    positions,
    urls,
  } = omitBy(agencyStaffer, isNil);

  return {
    id,
    name: full_name,
    email: getUrl(urls, EMAIL_KEY),
    title: uniq(map(positions, (value => value.title))),
    socialMedia: [
      { name: FACEBOOK, url: getUrl(urls, FACEBOOK_KEY) },
      { name: TWITTER, url: getUrl(urls, TWITTER_KEY) },
      { name: LINKEDIN, url: getUrl(urls, LINKEDIN_KEY) },
      { name: INSTAGRAM, url: getUrl(urls, INSTAGRAM_KEY) },
      { name: YOUTUBE, url: getUrl(urls, YOUTUBE_KEY) },
    ].filter(value => value.url),
    organizations: getOrganizations(positions),
    url: `/federal/executives/${id}`,
  };
}
