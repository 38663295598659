import { combineReducers } from 'redux';

import advancedSearch from 'redux/advancedSearch/reducers';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import exportReport from 'redux/exportReport/reducers';

import constants from './constants';

const DEFAULT_STATE = {
  ui: {
    isLoadingData: true,
    toResetSelectedItems: true,
    isLoadingAllEmails: false,
  },
  executives: [],
  sort: {},
  paginator: {},
  emails: [],
};

function localExecutiveLookup(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_LOCAL_EXECUTIVES:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingData: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_LOCAL_EXECUTIVES_DONE:
      return {
        ...state,
        executives: action.payload,
        sort: action.sort,
        paginator: action.paginator,
        ui: {
          ...state.ui,
          isLoadingData: false,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.RESET_LOCAL_EXECUTIVES:
      return DEFAULT_STATE;
    case constants.FETCH_LOCAL_EXECUTIVES_EMAILS:
      return {
        ...state,
        emails: DEFAULT_STATE.emails,
        ui: { ...state.ui, isLoadingAllEmails: true },
      };
    case constants.RECEIVE_LOCAL_EXECUTIVES_EMAILS_DONE:
      return {
        ...state,
        emails: action.payload,
        ui: { ...state.ui, isLoadingAllEmails: false },
      };
    default:
      return state;
  }
}

export default combineReducers({
  localExecutiveLookup,
  advancedSearch,
  exportReport,
  appliedFilters: appliedSearchFilters,
});
