import { fetchDefaultSubscription, fetchProfileSubscriptions } from 'redux/subscriptions/actions';
import { fetchProfileDocument, updateProfileShare } from 'redux/events/actions';
import { fetchProfileTags } from 'redux/tags/actions';
import isNotFoundError from 'utils/http/isNotFoundError';

import { transformProfileForUI } from './utils';
import constants from './constants';

export function resetLegislatorData() {
  return dispatch => dispatch({ type: constants.RESET_LEGISLATOR_DATA });
}

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}

export function onUpdateTrackings({ trackings }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: trackings,
  });
}

export function fetchSubscriptions(payload) {
  return (dispatch) => {
    dispatch(fetchDefaultSubscription(constants));
    dispatch(fetchProfileSubscriptions({ payload }, constants));
  };
}

export function fetchLegislatorProfile(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LEGISLATOR });
    const { id, isKwProfile } = payload;

    const legislatorsPath = isKwProfile ? `kw/${id}` : id;
    const url = `/api_web/state/legislators/${legislatorsPath}`;
    const ajax = $.get(url);

    const onSuccess = (response) => {
      const profile = transformProfileForUI(response);

      dispatch({
        type: constants.RECEIVE_LEGISLATOR_DONE,
        payload: profile,
      });

      return { ...payload, id: profile.recordId };
    };

    const onFailure = (error) => {
      isNotFoundError(error) && dispatch({ type: constants.PROFILE_NOT_FOUND });

      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(async (profileData) => {
        await dispatch(fetchTags(profileData));
        await dispatch(fetchProfileDocument(profileData, constants));
        await dispatch(fetchSubscriptions(profileData));
      })
      .catch(onFailure);
  };
}

export function updateProfileMyFeed() {
  return (dispatch) => {
    dispatch(updateProfileShare(constants));
  };
}
