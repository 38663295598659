import { BASE_FEEDS_URL } from 'redux/dashboardV2/constants';
import { postJson } from 'utils/api';
import { getSubscriptionUrlWithId } from 'redux/dashboardV2/common/subscriptions/utils/url';
import { transformTrackItemsForBE } from 'redux/dashboardV2/trackItems/utils';
import { getMyFeedToken } from 'components/Dashboard/utils';

import { transformTrackedItemForBE } from './transformers';

export const fetchTrackedItem = (feedToken, subscription = {}) => {
  const url = getSubscriptionUrlWithId(feedToken, subscription);
  const ajax = $.get(url);

  return Promise.resolve(ajax);
};

export const fetchTrackedItems = (feedToken, searchForm) => {
  const url = `${BASE_FEEDS_URL}/${feedToken}/trackings/lookup`;
  const requestData = { search_form: searchForm };

  const ajax = $.post(url, requestData);

  return Promise.resolve(ajax);
};

export const fetchTrackingsBulk = (data, feedToken) => {
  const url = `${BASE_FEEDS_URL}/subscriptions/trackings/lookup`;
  const requestData = { search_form: data, feed_token: feedToken };

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};

export const createCommitteeTracking = ({ feedToken, hearingData, stafferData, isFederal }) => {
  const { modelId: tokenHearing } = hearingData || {};
  const { modelId: tokenStaffer } = stafferData || {};
  const id = tokenHearing || tokenStaffer;

  const hearingOutput = transformTrackedItemForBE(hearingData);
  const stafferOutput = transformTrackedItemForBE(stafferData);
  const committeeHearingData = hearingData && hearingOutput;
  const committeeStafferData = stafferData && stafferOutput;

  const trackingType = isFederal ? 'federal_committees' : 'state_committees';
  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/trackings/${trackingType}`;
  const requestData = {
    token: id,
    ...(isFederal && {
      federal_committee_hearing: committeeHearingData,
      federal_committee_staffer: committeeStafferData,
    }),
    ...(!isFederal && {
      state_committee_hearing: committeeHearingData,
      state_committee_staffer: committeeStafferData,
    }),
  };

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};

export const bulkTrackItems = (data, customFeedToken) => {
  const feedToken = customFeedToken || getMyFeedToken();

  const url = `${BASE_FEEDS_URL}/${feedToken}/subscriptions/trackings/bulk`;
  const requestData = transformTrackItemsForBE(data, feedToken);

  const ajax = postJson(url, requestData);

  return Promise.resolve(ajax);
};
