import { uniq, map, flatMap, get, find, compact, toLower, sortBy } from 'lodash';

import { PHONE_TYPE } from 'components/core/Profile/utils/constants';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import {
  getValue,
  transformSelectValues,
  formatSelectValue,
  getLabelFromItem,
} from 'utils/transformers/advancedSearch';
import { PARTY_OPTIONS } from 'utils/constants/parties';
import { joinCompact, getParenthesisItem } from 'utils/string';

import { SOCIAL_MEDIA_LIST } from './constants';

const getUrlOfType = (urls, type) => get(find(urls, url => (url.type === type)), 'url');

const getSocialMediaUrl = (urls, socialMedia) => {
  const url = getUrlOfType(urls, socialMedia.type);

  if (!url) return null;

  return { url, name: socialMedia.name };
};

export const getSocialMedia = urls => (
  compact(SOCIAL_MEDIA_LIST.map(socialMedia => getSocialMediaUrl(urls, socialMedia)))
);

export const getOrganizations = positions => (
  uniq(flatMap(positions, ({ organizations }) => map(sortBy(organizations, 'level'), 'name')))
);

export const getState = (positions) => {
  const stateCodePath = 'address.state.code';
  const positionWithState = find(positions, position => get(position, stateCodePath));

  return get(positionWithState, stateCodePath);
};

export const getBranch = executivePositions => executivePositions.map(position => get(position, 'additional_info.branch'));

export const getPhone = (positions) => {
  let phone;

  const formatPhone = () => (
    phone && joinCompact([getParenthesisItem(phone.area_code), phone.phone_number], ' ')
  );

  find(positions, ({ phones }) => {
    phone = find(phones, positionPhone => (
      toLower(positionPhone.type) === PHONE_TYPE
    ));

    return phone;
  });

  return formatPhone();
};

export const getAddressFromExecutivePositions = executivePositions => executivePositions
  .map(({ address }) => `${address.street}, ${address.city}, ${address.state.name}, ${address.zip_code}`);

export function transformSelectLocationValues(items = []) {
  return items.map(({ label, value, filters }) => ({ label, value, ...filters }));
}

export function transformExecutive(executive) {
  const {
    id,
    full_name: fullName,
    executive_positions: executivePositions,
    positions,
    urls,
    party,
  } = executive;

  return {
    id,
    name: fullName,
    url: `/local/executives/${id}`,
    title: uniq(map(positions, 'title')),
    branch: getBranch(executivePositions),
    phone: getPhone(positions),
    email: getUrlOfType(urls, 'Email'),
    socialMedia: getSocialMedia(urls),
    address: getAddressFromExecutivePositions(executivePositions),
    party,
  };
}

export function transformExecutivesForUI(response) {
  return map(response.data, transformExecutive);
}

export function transformOptionsForBE(data) {
  const { searchValue, options } = data;
  const {
    executives,
    organizations,
    parties,
    states,
    titles,
    branches,
    counties,
    cities,
    zipCodes,
    electionDates,
  } = options;

  return {
    ...getValue('keyword', searchValue),
    ...getValue('person_ids', transformSelectValues(executives)),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('counties', transformSelectValues(counties)),
    ...getValue('cities', transformSelectValues(cities)),
    ...getValue('zip_codes', transformSelectValues(zipCodes)),
    ...getValue('organizations', transformSelectValues(organizations)),
    ...getValue('titles', transformSelectValues(titles)),
    ...getValue('parties', parties),
    ...getValue('branches', transformSelectValues(branches)),
    ...getValue('election_dates', transformSelectValues(electionDates)),
  };
}

function getDisplayDataForRecentSearches(data) {
  const {
    keyword,
    executives,
    organizations,
    parties,
    titles,
    counties,
    states,
    cities,
    zip_codes: zipCodes,
    branches,
    election_dates: electionDates,
  } = data.search_params;

  const filters = [
    getDisplayItem('Searched', keyword),
    getDisplayItem('Executive name', getLabelFromItem(executives)),
    getDisplayItem('Organization', organizations),
    getDisplayItem('Title', titles),
    getDisplayItem('Party', map(parties, party => PARTY_OPTIONS[party])),
    getDisplayItem('County', getLabelFromItem(counties)),
    getDisplayItem('State', getLabelFromItem(states)),
    getDisplayItem('City', getLabelFromItem(cities)),
    getDisplayItem('Branch', getLabelFromItem(branches)),
    getDisplayItem('Zip Code', zipCodes),
    getDisplayItem('Elected on (year)', electionDates),
  ];

  return {
    title: data.name || 'Applied filters:',
    filters: compact(filters),
  };
}

function transformOptionsForUI(data = {}) {
  const {
    keyword,
    executives,
    parties,
    titles,
    branches,
    election_dates: electionDates,
    states,
    counties,
    cities,
    zipCodes,
  } = data.search_params;

  return {
    id: data.id,
    options: {
      ...getValue('searchValue', keyword),
      ...getValue('executives', executives),
      ...getValue('titles', formatSelectValue(titles)),
      ...getValue('parties', parties),
      ...getValue('branches', branches),
      ...getValue('electionDates', electionDates),
      ...getValue('states', states),
      ...getValue('counties', counties),
      ...getValue('cities', cities),
      ...getValue('zipCodes', zipCodes),
    },
  };
}

export const transformSearchesForUI = data => data.map(item => ({
  ...getDisplayDataForRecentSearches(item),
  ...transformOptionsForUI(item),
}));

export function transformOptionsForSave(data) {
  const { searchValue, options } = data;
  const {
    executives,
    titles,
    cities,
    zipCodes,
    counties,
    states,
    branches,
    parties,
    electionDates,
  } = options;

  return {
    ...getValue('keyword', searchValue),
    ...getValue('executives', executives),
    ...getValue('parties', parties),
    ...getValue('titles', transformSelectValues(titles)),
    ...getValue('cities', cities),
    ...getValue('states', states),
    ...getValue('branches', branches),
    ...getValue('zip_codes', transformSelectValues(zipCodes)),
    ...getValue('counties', counties),
    ...getValue('election_dates', transformSelectValues(electionDates)),
  };
}
