const PREFIX = 'FEDERAL_BILL_LOOKUP';

const constants = {
  STRATEGY_SLUG: 'federal_bill',
  STRATEGY_SLUG_FOR_EXPORT: 'federal_bills',
  STRATEGY_SLUG_SINGLE_EXPORT: 'federal_bill',
  FETCH_BILLS: `${PREFIX}/FETCH_BILLS`,
  RECEIVE_BILLS_DONE: `${PREFIX}/RECEIVE_BILLS_DONE`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  FETCH_BILLS_GRAPH: `${PREFIX}/FETCH_BILLS_GRAPH`,
  RECEIVE_BILLS_GRAPH_DONE: `${PREFIX}/RECEIVE_BILLS_GRAPH_DONE`,
  RESET_BILLS: `${PREFIX}/RESET_BILLS`,
  SET_BILLS: `${PREFIX}/SET_BILLS`,
  BULK_UPDATE_DATA: `${PREFIX}/BULK_UPDATE_DATA`,
  BULK_TRACK_DATA: `${PREFIX}/BULK_TRACK_DATA`,
  BULK_TRACK_DATA_DONE: `${PREFIX}/BULK_TRACK_DATA_DONE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  BULK_SHARE_TO_MY_FEED: `${PREFIX}/BULK_SHARE_TO_MY_FEED`,
  BULK_SHARE_TO_MY_FEED_DONE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_DONE`,
  BULK_SHARE_TO_MY_FEED_FAILURE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_FAILURE`,
  LOAD_POSITIONS: `${PREFIX}/LOAD_POSITIONS`,
};

export default constants;
