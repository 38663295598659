import compact from 'lodash/compact';

import {
  getValue,
  getRecipientType,
  getCommitteeTypeValue,
  getContributionDonorInfo,
  getDisbursedRecipientInfo,
  getElectionTypeFilterValue,
  getCommitteeTypeFilterValue,
  transformContributionSearchesForFront,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
  formatSelectTypeToValForFE,
  formatSelectValueForFE,
  sourceSelectValueForBE,
  formatReceivedTypesForBE,
  formatTypesForSaveBE,
  formatContributorType,
  getLabelForUI,
  formatAmounts,
  formatDates,
  getPluralLabel,
  formatZipCode,
} from 'redux/moneyball/utils/transformers';
import { getDateValue, getAmountValue } from 'redux/moneyball/utils';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import { formatGlobalDate } from 'utils/date';

export function transformCommittee(committee) {
  const { sector, industry, category, organization_type, super_committee } = committee || {};
  const { committees, ...restCommittee } = super_committee || committee || {};
  const formattedCommittees = committees && {
    committees: committees.map(
      ({ address_zip_code, ...restCommittees }) => ({
        ...restCommittees,
        address_zip_code: formatZipCode(address_zip_code),
      })
    ),
  };

  return {
    ...restCommittee,
    ...formattedCommittees,
    sector,
    industry,
    category,
    organization_type,
  };
}

export function transformContributions(contributions = []) {
  return contributions.map(({
    receipt,
    super_committee_donor: superCommDonor,
    super_individual_donor: superIndDonor,
    super_corporation_donor: superCorpDonor,
    super_committee_conduit,
    ...restProps
  }) => {
    const donorInfo = getContributionDonorInfo({ superCommDonor, superIndDonor, superCorpDonor });
    const { id: conduitId, name: conduitName } = super_committee_conduit || {};
    const { name, url } = donorInfo || {};
    const {
      is_pdf_available,
      filing_source_url,
      contribution_date,
      contribution_amount,
      source_type_full,
      election_type_full,
      donor_name,
      id,
    } = receipt || {};

    const donorNameProps = name ? { name, url } : { name: donor_name };

    return ({
      ...restProps,
      ...donorInfo,
      ...donorNameProps,
      id,
      contribution_date,
      source_type_full,
      election_type_full,
      conduitName,
      amount: contribution_amount,
      conduit: {
        conduitId,
        conduitUrl: `/moneyball_v2/committees/${conduitId}`,
      },
      receipts: {
        id,
        is_pdf_available,
        filing_source_url,
      },
    });
  });
}

export function transformDisbursements(disbursements = []) {
  return disbursements.map(({ disbursement, recipient, ...restProps }) => {
    const { id, is_pdf_available, date, amount, description } = disbursement || {};
    const { state_code, ...restRecipientProps } = recipient || {};
    const recipientInfo = getDisbursedRecipientInfo(restRecipientProps);

    return ({
      ...restProps,
      ...recipientInfo,
      id,
      date: formatGlobalDate(date),
      amount,
      description,
      state_code,
      receipts: {
        id,
        is_pdf_available,
      },
    });
  });
}

export function transformContributionSearchesForFE(search_params = {}) {
  const {
    query,
    contribution_date,
    contribution_amount,
    contributor_types,
    contributor,
    source_types,
    election_types,
  } = search_params;
  const { super_committees, super_individuals } = contributor || search_params;
  const { ids, types } = super_committees || search_params;
  const {
    names,
    employers,
    occupations,
    streets,
    cities,
    states,
    zip_codes,
  } = super_individuals || search_params;

  return transformContributionSearchesForFront({
    queryType: 'Contributions',
    contribution_amount,
    contribution_date,
    contributor_types,
    contributor,
    ids,
    types,
    names,
    employers,
    occupations,
    streets,
    cities,
    states,
    zip_codes,
    query,
    source_types,
    election_types,
  });
}

export function transformDisbursementSearchesForFE(search_params = {}) {
  const {
    query,
    spender,
    disbursement_date,
    disbursement_amount,
    recipient,
    source_types,
    election_types,
  } = search_params;
  const { super_committees, super_vendors, super_individuals } = recipient || search_params;
  const { ids, types } = super_committees || search_params;
  const { ids: spenderIds } = spender || search_params;

  const {
    ids: vendorIds,
    cities: vendorCities,
    state_codes: vendorStates,
    zip_codes: vendorZip,
  } = super_vendors || search_params;

  const {
    names: individualNames,
    streets: individualStreets,
    cities: individualCities,
    state_codes: individualStates,
    zip_codes: individualZip,
  } = super_individuals || search_params;

  return {
    filters: compact([
      getDisplayItem('Searched', query),
      getDisplayItem('Searched type', 'Disbursements'),
      getDisplayItem('Dates', getDateValue(disbursement_date)),
      getDisplayItem('Amounts', getAmountValue(disbursement_amount)),
      getDisplayItem('Spender(s)', spenderIds),
      getDisplayItem('Recipient Type', getRecipientType(recipient, true)),
      getDisplayItem('Committee name(s)', getLabelForUI(ids)),
      getDisplayItem('Committee type(s)', getCommitteeTypeValue(types)),
      getDisplayItem('Vendor Name(s)', vendorIds),
      getDisplayItem('Vendor Cities', vendorCities),
      getDisplayItem('Vendor State(s)', vendorStates),
      getDisplayItem('Vendor Zip code(s)', vendorZip),
      getDisplayItem('Individual Name(s)', individualNames),
      getDisplayItem('Individual Street name(s)', individualStreets),
      getDisplayItem('Individual Cities', individualCities),
      getDisplayItem('Individual State(s)', individualStates),
      getDisplayItem('Individual Zip code(s)', individualZip),
      getDisplayItem(getPluralLabel('Source type', source_types), getCommitteeTypeFilterValue(source_types)),
      getDisplayItem(getPluralLabel('Election type', source_types), getElectionTypeFilterValue(election_types)),
    ]),
    data: {
      ...getValue('searchValue', query),
      ...getValue('searchType', 'disbursements'),
      options: {
        ...getValue('dates', formatDates(disbursement_date)),
        ...getValue('amounts', formatAmounts(disbursement_amount)),
        ...getValue('spender', formatSelectValueForFE(spenderIds)),
        ...getValue('recipientType', getRecipientType(recipient)),
        ...getValue('recipientCommittee', ids),
        ...getValue('recipientCommitteeTypes', types),
        ...getValue('recipientIndividuals', formatSelectValueForFE(individualNames)),
        ...getValue('recipientStreet', formatSelectValueForFE(individualStreets)),
        ...getValue('recipientCity', formatSelectValueForFE(individualCities)),
        ...getValue('recipientStates', formatSelectValueForFE(individualStates)),
        ...getValue('recipientZip', formatSelectValueForFE(individualZip)),
        ...getValue('recipientVendors', formatSelectValueForFE(vendorIds)),
        ...getValue('recipientVendorsCity', formatSelectValueForFE(vendorCities)),
        ...getValue('recipientVendorsStates', formatSelectValueForFE(vendorStates)),
        ...getValue('recipientVendorsZip', formatSelectValueForFE(vendorZip)),
        ...getValue('sourceTypes', formatSelectTypeToValForFE(source_types)),
        ...getValue('electionTypes', formatSelectTypeToValForFE(election_types)),
      },
    },
  };
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const { disbursement_date, disbursement_amount, recipient } = search_params || {};
    const isDisbursement = !!(disbursement_date || disbursement_amount || recipient);

    const dataTransformedBySearchType = isDisbursement
      ? transformDisbursementSearchesForFE(search_params)
      : transformContributionSearchesForFE(search_params);

    return {
      id,
      title: name || 'Applied filters:',
      ...dataTransformedBySearchType,
    };
  });
}

export function transformContributionOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    dates,
    amounts,
    contributorType,
    contributorNonIndividual,
    contributorCommitteeTypes,
    contributorIndividuals,
    contributorEmployers,
    contributorOccupations,
    contributorStreet,
    contributorCity,
    contributorStates,
    contributorZip,
    sourceTypes,
    electionTypes,
  } = options || data;
  const { startDate, endDate } = dates || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('contribution_date', {
      ...getValue('start', startDate),
      ...getValue('end', endDate),
    }),
    ...getValue('contribution_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('contributor_types', formatContributorType(contributorType)),
    ...getValue('contributor', {
      ...getValue('super_committees', {
        ...getValue('ids', formatSelectValueToArrForBE(contributorNonIndividual)),
        ...getValue('types', contributorCommitteeTypes),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToArrForBE(contributorIndividuals)),
        ...getValue('employers', formatSelectValueToArrForBE(contributorEmployers)),
        ...getValue('occupations', formatSelectValueToArrForBE(contributorOccupations)),
        ...getValue('streets', formatSelectValueToArrForBE(contributorStreet)),
        ...getValue('cities', formatSelectValueToArrForBE(contributorCity)),
        ...getValue('states', formatSelectValueToArrForBE(contributorStates)),
        ...getValue('zip_codes', formatSelectValueToArrForBE(contributorZip)),
      }),
    }),
    ...getValue('source_types', formatReceivedTypesForBE(sourceTypes)),
    ...getValue('election_types', formatSelectValueToArrForBE(electionTypes)),
  };
}

export function transformDisbursementOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    dates,
    amounts,
    recipientCommittee,
    recipientCommitteeTypes,
    recipientIndividuals,
    recipientCity,
    recipientStates,
    recipientStreet,
    recipientVendors,
    recipientVendorsCity,
    recipientVendorsStates,
    recipientVendorsZip,
    recipientZip,
    electionTypes,
    sourceTypes,
  } = options || data;
  const { startDate, endDate } = dates || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('disbursement_date', {
      ...getValue('start', startDate),
      ...getValue('end', endDate),
    }),
    ...getValue('disbursement_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('recipient', {
      ...getValue('super_committees', {
        ...getValue('ids', formatSelectValueToArrForBE(recipientCommittee)),
        ...getValue('types', recipientCommitteeTypes),
      }),
      ...getValue('super_vendors', {
        ...getValue('ids', formatSelectValueToArrForBE(recipientVendors)),
        ...getValue('cities', formatSelectValueToArrForBE(recipientVendorsCity)),
        ...getValue('state_codes', formatSelectValueToArrForBE(recipientVendorsStates)),
        ...getValue('zip_codes', formatSelectValueToArrForBE(recipientVendorsZip)),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToArrForBE(recipientIndividuals)),
        ...getValue('streets', formatSelectValueToArrForBE(recipientStreet)),
        ...getValue('cities', formatSelectValueToArrForBE(recipientCity)),
        ...getValue('state_codes', formatSelectValueToArrForBE(recipientStates)),
        ...getValue('zip_codes', formatSelectValueToArrForBE(recipientZip)),
      }),
    }),
    ...getValue('source_types', formatReceivedTypesForBE(sourceTypes)),
    ...getValue('election_types', formatSelectValueToArrForBE(electionTypes)),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformContributionOptionsSearchForBE(data = {}) {
  const baseOptions = transformContributionOptionsForBE(data);
  const { options } = data;
  const {
    contributorNonIndividual,
    contributorCommitteeTypes,
    contributorIndividuals,
    contributorEmployers,
    contributorOccupations,
    contributorStreet,
    contributorCity,
    contributorStates,
    contributorZip,
    sourceTypes,
    electionTypes,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('contributor', {
      ...getValue('super_committees', {
        ...getValue('ids', sourceSelectValueForBE(contributorNonIndividual)),
        ...getValue('types', contributorCommitteeTypes),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToObjForBE(contributorIndividuals)),
        ...getValue('employers', formatSelectValueToObjForBE(contributorEmployers)),
        ...getValue('occupations', formatSelectValueToObjForBE(contributorOccupations)),
        ...getValue('streets', formatSelectValueToObjForBE(contributorStreet)),
        ...getValue('cities', formatSelectValueToObjForBE(contributorCity)),
        ...getValue('states', formatSelectValueToObjForBE(contributorStates)),
        ...getValue('zip_codes', formatSelectValueToObjForBE(contributorZip)),
      }),
    }),
    ...getValue('source_types', formatTypesForSaveBE(sourceTypes)),
    ...getValue('election_types', formatTypesForSaveBE(electionTypes)),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformDisbursementOptionsSearchForBE(data = {}) {
  const baseOptions = transformDisbursementOptionsForBE(data);
  const { options } = data;
  const {
    recipientCommittee,
    recipientCommitteeTypes,
    recipientIndividuals,
    recipientStreet,
    recipientCity,
    recipientStates,
    recipientVendors,
    recipientVendorsCity,
    recipientVendorsStates,
    recipientVendorsZip,
    recipientZip,
    electionTypes,
    sourceTypes,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('recipient', {
      ...getValue('super_committees', {
        ...getValue('ids', sourceSelectValueForBE(recipientCommittee)),
        ...getValue('types', recipientCommitteeTypes),
      }),
      ...getValue('super_vendors', {
        ...getValue('ids', formatSelectValueToObjForBE(recipientVendors)),
        ...getValue('cities', formatSelectValueToObjForBE(recipientVendorsCity)),
        ...getValue('state_codes', formatSelectValueToObjForBE(recipientVendorsStates)),
        ...getValue('zip_codes', formatSelectValueToObjForBE(recipientVendorsZip)),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToObjForBE(recipientIndividuals)),
        ...getValue('streets', formatSelectValueToObjForBE(recipientStreet)),
        ...getValue('cities', formatSelectValueToObjForBE(recipientCity)),
        ...getValue('state_codes', formatSelectValueToObjForBE(recipientStates)),
        ...getValue('zip_codes', formatSelectValueToObjForBE(recipientZip)),
      }),
    }),
    ...getValue('source_types', formatTypesForSaveBE(sourceTypes)),
    ...getValue('election_types', formatTypesForSaveBE(electionTypes)),
  };
}
