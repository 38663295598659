import { isNil, filter } from 'lodash';

import { FEDERAL_REGULATION_TYPES, responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { formatGlobalDate, formatMonthDayYear } from 'utils/date';
import { getBillStatusTypes } from 'utils/constants/bills';
import {
  FEDERAL_COMMITTEE_ITEM_TYPE,
  FEDERAL_BILL_ITEM_TYPE,
  FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
  FEDERAL_REGULATION_ITEM_TYPE,
} from 'utils/constants/itemTypes';

import { getTitleUrl } from '../utils';
import {
  transformLastAction,
  transformSponsor,
  transformStaffer,
  transformSubscriptions,
  transformSession,
} from './commonTransformers';

function transformBillAction({ item_data, createdAt, subscriptions, is_disabled }) {
  const {
    id,
    title,
    chamber,
    cosponsors,
    sponsor,
    status_step,
    introduced_on,
    official_source,
    last_action,
    description,
    session,
    bill_summary,
  } = item_data || {};

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    titleUrl: getTitleUrl(id, '/federal/bills'),
    category: FEDERAL_BILL_ITEM_TYPE,
    displayTitle: {
      cardTitle: title,
    },
    title,
    description,
    billSummary: bill_summary,
    billStatusStep: status_step,
    billStatusAllSteps: getBillStatusTypes(chamber, status_step),
    chamber,
    cosponsors,
    introducedOn: formatMonthDayYear(introduced_on),
    officialSource: official_source,
    sponsor: transformSponsor(sponsor, '/federal/legislators'),
    lastAction: transformLastAction(last_action),
    session: transformSession(session),
    createdAt,
  };
}

function transformRegulation({ item_data, subscriptions, is_disabled }) {
  const {
    id,
    title,
    agencies,
    document_type,
    publication_date,
    regulation_url,
    regulation_pdf_url,
    regulation_gov_url,
  } = item_data || {};
  const date = formatGlobalDate(publication_date);
  const agency = filter(agencies, Boolean).join(', ');

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    titleUrl: getTitleUrl(id, '/federal/regulations'),
    category: FEDERAL_REGULATION_ITEM_TYPE,
    title,
    agency,
    regulationDocumentType: FEDERAL_REGULATION_TYPES[document_type],
    latestPublication: date,
    officialSource: filter([
      {
        value: regulation_url,
        label: 'View on FederalRegister.gov',
      },
      {
        value: regulation_pdf_url,
        label: 'View in Printed Federal Regulation',
      },
      {
        value: regulation_gov_url,
        label: 'View Docket and Comments on Regulations.gov',
      },
    ], ({ value }) => !isNil(value)),
  };
}

function transformCommittee({ item_data, send_week_ahead_email, subscriptions, is_disabled }) {
  const {
    id,
    title,
    chair,
    party_breakdown,
    ranking_member,
    action_date,
    staffer,
  } = item_data || {};

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    isCommittee: true,
    titleUrl: getTitleUrl(id, '/federal/committees'),
    category: FEDERAL_COMMITTEE_ITEM_TYPE,
    title,
    chair: transformSponsor(chair, '/federal/legislators'),
    rankingMember: transformSponsor(ranking_member, '/federal/legislators'),
    partyBreakdown: party_breakdown,
    sendWeekAheadEmail: send_week_ahead_email,
    lastActionDate: formatMonthDayYear(action_date),
    staffer: transformStaffer(staffer, '/federal/congressional_staffers'),
  };
}

function transformLegislatorStaffer({ item_data, subscriptions, is_disabled }) {
  const {
    id,
    title,
    staffer,
    action_date,
  } = item_data || {};

  return {
    ...transformSubscriptions({ subscriptions, is_disabled }),
    category: FEDERAL_LEGISLATIVE_STAFFERS_ITEM_TYPE,
    titleUrl: getTitleUrl(id, '/federal/legislators'),
    title,
    officeHolder: transformSponsor(item_data, '/federal/legislators'),
    staffer: transformStaffer(staffer, '/federal/congressional_staffers'),
    lastActionDate: formatMonthDayYear(action_date),
  };
}

export const FEDERAL_TRACKED_TYPE = {
  [responseTrackingTypes.FEDERAL_BILL]: transformBillAction,
  [responseTrackingTypes.FEDERAL_REGULATION]: transformRegulation,
  [responseTrackingTypes.FEDERAL_COMMITTEE_HEARING]: transformCommittee,
  [responseTrackingTypes.FEDERAL_COMMITTEE_STAFFER]: transformCommittee,
  [responseTrackingTypes.FEDERAL_LEGISLATOR_STAFFER]: transformLegislatorStaffer,
};
