import isFunction from 'lodash/isFunction';

import { FEDERAL_RECENT_TYPE } from './federalTransformers';
import { STATE_RECENT_TYPE } from './stateTransformers';
import { KEYWORD_RECENT_TYPE } from './keywordTransformers';
import { GENERAL_RECENT_TYPE } from './generalTransformers';
import { NEWS_STORY_RECENT_TYPE } from './newsTransformers';

const ALL_ITEMS = {
  ...FEDERAL_RECENT_TYPE,
  ...STATE_RECENT_TYPE,
  ...KEYWORD_RECENT_TYPE,
  ...GENERAL_RECENT_TYPE,
  ...NEWS_STORY_RECENT_TYPE,
};

export function transformRecentEvent({ event_type, ...restProps }) {
  const allItemsFunction = ALL_ITEMS[event_type];

  return isFunction(allItemsFunction) ? allItemsFunction(restProps) : {};
}
