import { compact, isEmpty } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import getMappedValues from 'components/core/TagInput/getMappedValues';
import { getDisplayItem, mapBackendValues } from 'redux/advancedSearch/utils';
import { STATE_COMMITTEE_ITEM_TYPE } from 'utils/constants/itemTypes';

import {
  getLabelFromItem,
  getValue,
  transformSelectValues,
  getUniqueLabelFromItem,
  transformArraySelectValues,
} from 'utils/transformers/advancedSearch';

function transformOptionsForUI(data = {}) {
  const { id, search_params } = data;
  const {
    lookup_keyword,
    legislators,
    committees,
    chambers,
    states,
    show_historical,
    tag_ids,
  } = search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', lookup_keyword),
      ...getValue('legislator', legislators),
      ...getValue('committee', getMappedValues(committees)),
      ...getValue('chamber', chambers),
      ...getValue('state', states),
      ...getValue('historicalCommittees', Boolean(show_historical)),
      ...getValue('tags', tag_ids),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}, legislatorsProps = {}) {
  const { name, search_params } = data;
  const {
    lookup_keyword,
    legislators,
    committees,
    chambers,
    states,
    show_historical,
    tag_ids,
  } = search_params || {};

  const filters = [
    getDisplayItem('Searched', lookup_keyword),
    getDisplayItem('Legislator', getLabelFromItem(legislators)),
    getDisplayItem('Committee', getUniqueLabelFromItem(committees)),
    getDisplayItem('Chamber', mapBackendValues(chambers, legislatorsProps.chambers)),
    getDisplayItem('State', getLabelFromItem(states)),
    getDisplayItem('Include historical committees', show_historical && 'Yes'),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    committee,
    chamber,
    state,
    historicalCommittees,
    tags,
  } = options || {};

  return {
    ...getValue('chambers', chamber),
    ...getValue('ids', transformArraySelectValues(committee)),
    ...getValue('show_historical', historicalCommittees),
    ...getValue('lookup_keyword', searchValue),
    ...getValue('legislator_ids', transformSelectValues(legislator)),
    ...getValue('state_alpha2_ids', transformSelectValues(state)),
    ...getValue('tag_ids', transformSelectValues(tags)),
  };
}

export function transformSearchesForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    committee,
    chamber,
    state,
    historicalCommittees,
    tags,
  } = options || {};

  return {
    ...getValue('chambers', chamber),
    ...getValue('ids', transformArraySelectValues(committee)),
    ...getValue('show_historical', historicalCommittees),
    ...getValue('lookup_keyword', searchValue),
    ...getValue('legislator_ids', transformSelectValues(legislator)),
    ...getValue('state_alpha2_ids', transformSelectValues(state)),
    ...getValue('tag_ids', tags),
  };
}

export function transformLegislatorData(props) {
  if (isEmpty(props)) {
    return props;
  }

  const { id, party_slug, party_short, universal_moniker } = props || {};

  return {
    id,
    name: universal_moniker,
    partySlug: party_slug,
    partyShort: party_short,
  };
}

export function transformSearchesForUI(data, legislatorsProps) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item, legislatorsProps),
    ...transformOptionsForUI(item),
  }));
}

export function transformCommitteesForUI(committees = []) {
  return committees.map(({
    id,
    name,
    state,
    chairman,
    chamber_slug,
    chamber_name,
    terms,
  }) => ({
    id,
    name,
    state,
    terms,
    chamber: chamber_name || chamber_slug,
    chairman: transformLegislatorData(chairman),
    recordId: id,
    documentType: DOCUMENT_TYPES.STATE_COMMITTEE,
    itemType: STATE_COMMITTEE_ITEM_TYPE,
    category: STATE_COMMITTEE_ITEM_TYPE,
    isCommittee: true,
    withWeekAheadEmail: true,
    withTrack: true,
  }));
}

export function getExportInfo(data = {}) {
  const { searchValue } = data;
  const {
    legislator,
    committee,
    chamber,
    state,
    tags,
  } = data.options || {};

  return {
    'strategy_inputs[chambers]': chamber,
    ...(!isEmpty(committee) && { 'strategy_inputs[ids]': transformArraySelectValues(committee) }),
    'strategy_inputs[lookup_keyword]': searchValue,
    'strategy_inputs[legislator_ids]': transformSelectValues(legislator),
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    'strategy_inputs[state_alpha2_ids]': transformSelectValues(state),
  };
}
