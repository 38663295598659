import { fetchIsShared } from 'components/core/DocumentContainer/api';

import constants from '../constants';

export const onDocumentShared = sharings => (dispatch) => {
  dispatch({
    type: constants.RECEIVE_IS_SHARED_DONE,
    payload: sharings,
  });
};

export const fetchIsDocumentShared = (apiUrl, documentId) => dispatch => (
  fetchIsShared(apiUrl, documentId)
    .then(sharings => dispatch(onDocumentShared(sharings)))
);
