const PREFIX = 'NEWS_FEED';

const constants = {
  FETCH_NEWS_FEED: `${PREFIX}/FETCH_NEWS_FEED`,
  RECEIVE_NEWS_FEED_DONE: `${PREFIX}/RECEIVE_NEWS_FEED_DONE`,
  RECEIVE_NEWS_FEED_FAILURE: `${PREFIX}/RECEIVE_NEWS_FEED_FAILURE`,
  RESET_NEWS_FEED: `${PREFIX}/RESET_NEWS_FEED`,
  FETCH_FEED_KEYWORDS: `${PREFIX}/FETCH_FEED_KEYWORDS`,
  RECEIVE_FEED_KEYWORDS_DONE: `${PREFIX}/RECEIVE_FEED_KEYWORDS_DONE`,
  RECEIVE_FEED_KEYWORDS_FAILURE: `${PREFIX}/RECEIVE_FEED_KEYWORDS_FAILURE`,
  RESET_FEED_KEYWORDS: `${PREFIX}/RESET_FEED_KEYWORDS`,
  SELECT_CARDS: `${PREFIX}/SELECTED_CARDS`,
  BULK_SAVE_TAGS: `${PREFIX}/BULK_SAVE_TAGS`,
  BULK_SAVE_TAGS_DONE: `${PREFIX}/BULK_SAVE_TAGS_DONE`,
  BULK_SAVE_TAGS_FAILURE: `${PREFIX}/BULK_SAVE_TAGS_FAILURE`,
  LOAD_TAGS: `${PREFIX}/LOAD_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  RECEIVE_TAGS_FAILURE: `${PREFIX}/RECEIVE_TAGS_FAILURE`,
  UPDATE_TAGS: `${PREFIX}/UPDATE_TAGS`,
  UPDATE_TAGS_DONE: `${PREFIX}/UPDATE_TAGS_DONE`,
  UPDATE_TAGS_FAILURE: `${PREFIX}/UPDATE_TAGS_FAILURE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  SHARE_TO_MY_FEED: `${PREFIX}/SHARE_TO_MY_FEED`,
  SHARE_TO_MY_FEED_DONE: `${PREFIX}/SHARE_TO_MY_FEED_DONE`,
  SHARE_TO_MY_FEED_FAILURE: `${PREFIX}/SHARE_TO_MY_FEED_FAILURE`,
};

export const PER_PAGE = 100;
export const DEFAULT_SORT = 'relevance';

export const BASE_TAG_URL = '/api_web/news/tags';

export default constants;
