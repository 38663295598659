import delay from 'lodash/delay';

import constants from './constants';
import { fetchStakeholders } from '../Lookup/actions/data';
import { fetchStakeholderProfile } from '../Profile/actions/data';

// Temporary fix to elastic search delete and create issue
const afterChangeDelay = 2500;
const delayReponse = callback => response => delay(() => callback(response), afterChangeDelay);

export function deleteStakeholder(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_STAKEHOLDER });

    const { id, deleteNotifications, redirectToDirectory } = payload;

    const url = `/api_web/stakeholder-management/stakeholders/${id}`;

    function onSuccess(response) {
      dispatch({
        type: constants.DELETE_STAKEHOLDER_DONE,
        payload: response.data,
      });

      redirectToDirectory();
      deleteNotifications.getSuccess();
    }

    const ajax = $.ajax({ url, type: 'DELETE' });

    return Promise.resolve(ajax)
      .then(delayReponse(onSuccess))
      .catch((error) => {
        deleteNotifications.getFailed();
        throw error;
      });
  };
}

export function deleteStakeholders(payload = {}) {
  return (dispatch, getState) => {
    dispatch({ type: constants.DELETE_STAKEHOLDERS });

    const {
      ids,
      toggleOpen,
      onClear,
      deleteNotifications,
      paginator = {},
    } = payload;
    const { pageSize } = paginator;

    const requests = ids.map((id) => {
      const url = `/api_web/stakeholder-management/stakeholders/${id}`;
      const ajax = $.ajax({ url, type: 'DELETE' });

      return Promise.resolve(ajax);
    });

    function onSuccess(response) {
      dispatch({
        type: constants.DELETE_STAKEHOLDERS_DONE,
        payload: response.data,
      });

      const lookupAppliedFilters = getState().stakeholder.stakeholderLookup.appliedFilters || {};
      const { appliedFilters } = lookupAppliedFilters;

      deleteNotifications.getSuccess();
      toggleOpen();
      onClear();
      dispatch(fetchStakeholders({
        perPage: pageSize,
        searchData: appliedFilters,
      }));
    }

    return Promise.all(requests)
      .then(delayReponse(onSuccess))
      .catch((error) => {
        deleteNotifications.getFailed();
        throw error;
      });
  };
}

function submitStakeholder(payload = {}) {
  return (dispatch, getState) => {
    const {
      id,
      ajax,
      handleFailure,
      handleSuccess,
      actionTypes,
      isProfilePageUpdate,
      avoidDispatchFetch,
    } = payload;

    dispatch({ type: actionTypes.SUBMIT });

    const lookupAppliedFilters = getState().stakeholder.stakeholderLookup.appliedFilters || {};
    const { appliedFilters } = lookupAppliedFilters;

    function onSuccess() {
      dispatch({ type: actionTypes.SUBMIT_DONE });
      handleSuccess();

      if (avoidDispatchFetch) {
        return;
      }

      if (isProfilePageUpdate) {
        dispatch(fetchStakeholderProfile({ stakeholderId: id }));
      } else {
        dispatch(fetchStakeholders({ searchData: appliedFilters }));
      }
    }

    function onFailure(error) {
      dispatch({ type: actionTypes.SUBMIT_FAIL });
      handleFailure(error.responseJSON);

      throw error;
    }

    return Promise.resolve(ajax)
      .then(delayReponse(onSuccess))
      .catch(onFailure);
  };
}

export function updateStakeholder(payload = {}) {
  return (dispatch) => {
    const {
      stakeholderData,
      handleFailure,
      handleSuccess,
      ...restProps
    } = payload;

    const actionTypes = {
      SUBMIT: constants.UPDATE_STAKEHOLDER,
      SUBMIT_DONE: constants.UPDATE_STAKEHOLDER_DONE,
      SUBMIT_FAIL: constants.UPDATE_STAKEHOLDER_FAIL,
    };

    const url = `/api_web/stakeholder-management/stakeholders/${stakeholderData.id}`;
    const data = { stakeholder: stakeholderData };

    const ajax = $.ajax({
      url,
      method: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify(data),
    });

    dispatch(submitStakeholder({
      ajax,
      handleFailure,
      handleSuccess,
      actionTypes,
      id: stakeholderData.id,
      ...restProps,
    }));
  };
}

export function createStakeholder(payload = {}) {
  return (dispatch) => {
    const {
      stakeholderData,
      handleFailure,
      handleSuccess,
      ...restProps
    } = payload;

    const actionTypes = {
      SUBMIT: constants.CREATE_STAKEHOLDER,
      SUBMIT_DONE: constants.CREATE_STAKEHOLDER_DONE,
      SUBMIT_FAIL: constants.CREATE_STAKEHOLDER_FAIL,
    };
    const url = '/api_web/stakeholder-management/stakeholders';
    const data = { stakeholder: stakeholderData };
    const ajax = $.ajax({
      url,
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data),
    });

    dispatch(submitStakeholder({
      ajax,
      handleFailure,
      handleSuccess,
      actionTypes,
      ...restProps,
    }));
  };
}
