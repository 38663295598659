const keyword = 'MANAGE_COMPANY';

const constants = {
  FETCH_COMPANY_INFO: `${keyword}/FETCH_COMPANY_INFO`,
  RECEIVE_COMPANY_INFO_DONE: `${keyword}/RECEIVE_COMPANY_INFO_DONE`,
  UPDATE_COMPANY_INFO: `${keyword}/UPDATE_COMPANY_INFO`,
  UPDATE_COMPANY_INFO_DONE: `${keyword}/UPDATE_COMPANY_INFO_DONE`,
  UPDATE_COMPANY_INFO_ERROR: `${keyword}/UPDATE_COMPANY_INFO_ERROR`,
  RESET_API_TOKEN: `${keyword}/RESET_API_TOKEN`,
  RESET_API_TOKEN_DONE: `${keyword}/RESET_API_TOKEN_DONE`,
  DELETE_CONTACT: `${keyword}/DELETE_CONTACT`,
  DELETE_CONTACT_DONE: `${keyword}/DELETE_CONTACT_DONE`,
  DELETE_CONTACT_ERROR: `${keyword}/DELETE_CONTACT_ERROR`,
  SAVE_CONTACT: `${keyword}/SAVE_CONTACT`,
  CONTACT_SAVED: `${keyword}/CONTACT_SAVED`,
  SAVE_CONTACT_FAILED: `${keyword}/SAVE_CONTACT_FAILED`,
};

export default constants;
