import {
  SORT_BY_TYPES,
  FETCH_SMART_LISTS,
  RECEIVE_SMART_LISTS_DONE,
  DELETE_SMART_LIST,
  DELETE_SMART_LIST_DONE,
} from '../constants';

export const defaultState = {
  smartLists: [],
  sortBy: SORT_BY_TYPES.NEWEST_TO_OLDEST,
  ui: {
    isLoadingSmartLists: true,
    isDeletingSmartList: false,
  },
};

function lookup(state = defaultState, { type, payload, sortBy }) {
  switch (type) {
    case FETCH_SMART_LISTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingSmartLists: true,
        },
      };
    case RECEIVE_SMART_LISTS_DONE:
      return {
        ...state,
        smartLists: payload,
        sortBy: sortBy || defaultState.sortBy,
        ui: {
          ...state.ui,
          isLoadingSmartLists: false,
        },
      };
    case DELETE_SMART_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingSmartList: true,
        },
      };
    case DELETE_SMART_LIST_DONE:
      return {
        ...state,
        smartLists: state.smartLists.filter(({ id }) => id !== payload.id),
        ui: {
          ...state.ui,
          isDeletingSmartList: false,
        },
      };

    default:
      return state;
  }
}

export default lookup;
