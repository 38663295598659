import {
  FEDERAL_LEGISLATOR_SLUG,
  FEDERAL_COMMITTEE_SLUG,
  STATE_LEGISLATOR_SLUG,
  STATE_EXECUTIVE_SLUG,
} from './constants';

export function getStaffersUrl(slug, id) {
  const staffersUrls = {
    [FEDERAL_LEGISLATOR_SLUG]: {
      url: '/api_web/rolodex/staffers/lookup',
      method: 'POST',
    },
    [FEDERAL_COMMITTEE_SLUG]: {
      url: `/api_web/federal/committees/${id}/staffers`,
      method: 'GET',
    },
    [STATE_LEGISLATOR_SLUG]: {
      url: '/api_web/state/staffers/lookup',
      method: 'POST',
    },
    [STATE_EXECUTIVE_SLUG]: {
      url: '/api_web/state_agency_staffers',
      method: 'GET',
    },
  };

  if (!staffersUrls[slug]) {
    throw Error('The bill slug must be valid');
  }

  return staffersUrls[slug];
}
