import { combineReducers } from 'redux';

import lookup from './lookup/reducers';
import executiveProfile from './executiveProfile/reducers';
import localExecutiveLookup from './localExecutiveLookup/reducers';

export default combineReducers({
  lookup,
  executiveProfile,
  localExecutiveLookup,
});
