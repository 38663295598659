import { DEFAULT_PAGE_SIZE, DEFAULT_INITIAL_PAGE } from 'components/core/Paginator/constants';

import constants from './constants';

export const defaultPagination = {
  currentPage: DEFAULT_INITIAL_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const defaultState = {
  dataSets: [],
  pagination: defaultPagination,
  sortData: {},
  ui: {
    isLoading: true,
  },
};

function institutionalData(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_DATA_SETS:
      return {
        ...state,
        ui: { ...state.ui, isLoading: true },
      };
    case constants.RECEIVE_DATA_SETS_DONE:
      return {
        ...state,
        dataSets: action.payload,
        pagination: action.pagination,
        sortData: action.sortData,
        ui: { ...state.ui, isLoading: false },
      };
    case constants.RECEIVE_DATA_SETS_ERROR:
      return {
        ...state,
        ui: { ...state.ui, isLoading: false },
      };
    default:
      return state;
  }
}

export default institutionalData;
