import constants from './constants';

const DEFAULT_STATE = {
  clusterInfo: {},
  ui: {
    isLoadingClusterInfo: true,
  },
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_CLUSTER_INFO:
      return {
        ...state,
        ui: { ...state.ui, isLoadingClusterInfo: true },
      };
    case constants.RECEIVE_CLUSTER_INFO_DONE:
      return {
        ...state,
        clusterInfo: action.payload,
        ui: { ...state.ui, isLoadingClusterInfo: false },
      };
    case constants.RECEIVE_CLUSTER_INFO_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingClusterInfo: false },
      };
    default:
      return state;
  }
}
