import {
  getValue,
  getContributionRecipientInfo,
  formatSelectValueToArrForBE,
  formatReceivedTypesForBE,
  formatZipCode,
} from 'redux/moneyball/utils/transformers';

export function transformIndividual(individualItem = {}) {
  const {
    individuals,
    super_individual,
    super_individuals,
    ...restProps
  } = individualItem;

  const formattedSuperIndividual = super_individual && {
    super_individual: {
      ...super_individual,
      zip_code: formatZipCode(super_individual.zip_code),
    },
  };

  const formattedSuperIndividuals = super_individuals && {
    super_individuals: super_individuals.map(
      ({ super_individual: superIndividualItem, ...superIndividualsProps }) => ({
        ...superIndividualsProps,
        super_individual: {
          ...superIndividualItem,
          zip_code: formatZipCode(superIndividualItem.zip_code),
        },
      }),
    ),
  };

  const formattedIndividuals = individuals && {
    individuals: individuals.map(
      ({ individual, ...individualsProps }) => ({
        ...individualsProps,
        individual: {
          ...individual,
          zip_code: formatZipCode(individual.zip_code),
        },
      }),
    ),
  };

  return {
    ...restProps,
    ...formattedIndividuals,
    ...formattedSuperIndividual,
    ...formattedSuperIndividuals,
  };
}

export function transformContributions(contributions = []) {
  return contributions.map(({
    receipt,
    super_committee_recipient,
    super_committee_conduit,
    ...restProps
  }) => {
    const recipientInfo = getContributionRecipientInfo(super_committee_recipient);
    const { id: conduitId, name: conduitName } = super_committee_conduit || {};
    const {
      is_pdf_available,
      filing_source_url,
      contribution_date,
      contribution_amount,
      source_type_full,
      election_type_full,
      id,
    } = receipt || {};

    return ({
      ...restProps,
      ...recipientInfo,
      id,
      contribution_date,
      source_type_full,
      election_type_full,
      conduitName,
      amount: contribution_amount,
      conduit: {
        conduitId,
        conduitUrl: `/moneyball_v2/committees/${conduitId}`,
      },
      receipts: {
        id,
        is_pdf_available,
        filing_source_url,
      },
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    dates,
    amounts,
    recipientNames,
    committeeTypes,
    electionTypes,
    sourceTypes,
  } = options || data;
  const { startDate, endDate } = dates || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('contribution_date', {
      ...getValue('start', startDate),
      ...getValue('end', endDate),
    }),
    ...getValue('contribution_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('super_committee_recipients', {
      ...getValue('ids', formatSelectValueToArrForBE(recipientNames)),
      ...getValue('types', committeeTypes),
    }),
    ...getValue('source_types', formatReceivedTypesForBE(sourceTypes)),
    ...getValue('election_types', formatSelectValueToArrForBE(electionTypes)),
  };
}
