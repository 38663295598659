import { mergeSubscriptions } from './utils';
import constants from './utils/constants';

export const defaultState = {
  subscriptions: {},
  fetchedRecords: {},
  ui: {
    isLoading: false,
  },
};

function tracking(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        fetchedRecords: action.payload,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        subscriptions: mergeSubscriptions(state.subscriptions, action.payload),
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };
    case constants.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.recordType]: {
            ...state.subscriptions[action.recordType],
            [action.recordId]: {
              ...state.subscriptions[action.recordType][action.recordId],
              ...action.newSubscriptionData,
            },
          },
        },
      };
    default:
      return state;
  }
}

export default tracking;
