import moment from 'moment/moment';
import map from 'lodash/map';

import { GLOBAL_DATE_FORMAT } from 'utils/constants/date';

export function transformAmendmentsForUI(amendments = {}) {
  const { data = [] } = amendments;

  return {
    allItems: map(data, ({ introduced, ...restProps }) => ({
      ...restProps,
      proposed: introduced && moment(introduced).format(GLOBAL_DATE_FORMAT),
    })),
  };
}

export function transformAmendmentsForBE(payload) {
  const { id, ...restProps } = payload || {};

  return {
    ...restProps,
    search_form: {
      bill_id: id,
    },
  };
}
