import { getLookupRequestParams } from 'utils/lookup';

import { transformGraphForUI, transformOptionsForBE } from '../utils';
import constants from '../constants';

export function fetchBillsGraphs(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_BILLS_GRAPH });

    const url = '/api_web/state/bills/aggregations';
    const dataPayload = { url, ...payload };
    const { data } = getLookupRequestParams(dataPayload, transformOptionsForBE);
    const searchForm = { search_form: { ...data.search_form, aggregation: true } };
    const ajax = $.post(url, { ...data, ...searchForm });

    return Promise.resolve(ajax).then(response =>
      dispatch({
        type: constants.RECEIVE_BILLS_GRAPH_DONE,
        payload: transformGraphForUI(response),
      }));
  };
}
