import { CAMPAIGN_REGULATION_TYPE } from 'components/grassroots-campaign-builder/constants';

import {
  RECEIVE_REGULATION,
  FETCH_REGULATION,
} from '../../constants';

function receiveRegulation(dispatch) {
  return regulation => dispatch(
    {
      type: RECEIVE_REGULATION,
      payload: regulation,
    }
  );
}

export function fetchRegulation(campaign) {
  return (dispatch) => {
    if (campaign.campaign_type !== CAMPAIGN_REGULATION_TYPE) {
      return null;
    }

    dispatch({ type: FETCH_REGULATION });

    // Regulation campaigns only have one matcher
    const regulationId = campaign.matchers[0].strategy_data.regulation_id;

    const ajax = $.ajax({
      url: `/api_web/portal/grassroots/regulations/${regulationId}`,
      method: 'GET',
    });

    return Promise.resolve(ajax)
      .then(receiveRegulation(dispatch));
  };
}
