export const API_URL = '/api_web/state/regulations/profile';
export const PUBLICATIONS_URL = '/api_web/state/regulation_documents/lookup';

export const STRATEGY_SLUG = 'state_regulation';
export const STRATEGY_SLUG_FOR_EXPORT = 'state_regulations';

const PREFIX = 'STATE_REGULATION';
const constants = {
  FETCH_REGULATION: `${PREFIX}/FETCH_REGULATION`,
  RECEIVE_REGULATION_DONE: `${PREFIX}/RECEIVE_REGULATION`,
  FETCH_TIMELINE: `${PREFIX}/FETCH_TIMELINE`,
  RECEIVE_TIMELINE_DONE: `${PREFIX}/RECEIVE_TIMELINE_DONE`,
  UPSERT_SUMMARY_DONE: `${PREFIX}/UPSERT_SUMMARY_DONE`,
  FETCH_PUBLICATIONS: `${PREFIX}/FETCH_PUBLICATIONS`,
  RECEIVE_PUBLICATIONS_DONE: `${PREFIX}/RECEIVE_PUBLICATIONS_DONE`,
  RESET_REGULATION_DATA: `${PREFIX}/RESET_REGULATION_DATA`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  UPDATE_PROFILE_MY_FEED: `${PREFIX}/UPDATE_PROFILE_MY_FEED`,
};

export default constants;
