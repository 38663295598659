const PREFIX = 'LOCAL_EXECUTIVE_LOOKUP';

const CONSTANTS = {
  FETCH_LOCAL_EXECUTIVES: `${PREFIX}/FETCH_LOCAL_EXECUTIVES`,
  RECEIVE_LOCAL_EXECUTIVES_DONE: `${PREFIX}/RECEIVE_LOCAL_EXECUTIVES_DONE`,
  RESET_LOCAL_EXECUTIVES: `${PREFIX}/RESET_LOCAL_EXECUTIVES`,
  FETCH_LOCAL_EXECUTIVES_EMAILS: `${PREFIX}/FETCH_LOCAL_EXECUTIVES_EMAILS`,
  RECEIVE_LOCAL_EXECUTIVES_EMAILS_DONE: `${PREFIX}/RECEIVE_LOCAL_EXECUTIVES_EMAILS_DONE`,
};

export default CONSTANTS;
