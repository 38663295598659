import {
  RECEIVE_CAMPAIGN,
  FETCH_CAMPAIGN,
  SAVE_CUSTOM_MESSAGE,
  SAVE_CUSTOM_REGULATION_COMMENT,
  SET_PREVIEW_MODE,
  OPEN_MESSAGE_EDITOR,
  CLOSE_MESSAGE_EDITOR,
  COLLECT_STATISTIC,
} from '../constants';
import { stopLoading, receiveError } from './utils';

export { temporarySaveFormSubmission, saveFormSubmission, editFormSubmission } from './formSubmission';
export { fetchCustomColors } from './customColors';
export { loadStepTwoData } from './data';
export { saveMessages } from './messages';

function receiveCampaign(dispatch) {
  return payload => dispatch({ type: RECEIVE_CAMPAIGN, payload });
}

export function fetchCampaign(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_CAMPAIGN, payload });

    const url = `/api_web/portal/grassroots/campaigns/${payload.campaignToken}`;
    const method = 'GET';

    const ajax = $.ajax({ method, url });

    return Promise.resolve(ajax)
      .then(receiveCampaign(dispatch))
      .then(stopLoading(dispatch))
      .catch(receiveError(dispatch));
  };
}

export function saveCustomMessage(payload) {
  return { type: SAVE_CUSTOM_MESSAGE, payload };
}

export function saveCustomRegulationComment(payload) {
  return { type: SAVE_CUSTOM_REGULATION_COMMENT, payload };
}

export function openMessageEditor() {
  return { type: OPEN_MESSAGE_EDITOR };
}

export function closeMessageEditor() {
  return { type: CLOSE_MESSAGE_EDITOR };
}

export function setPreviewMode() {
  return { type: SET_PREVIEW_MODE };
}

export function collectStatistics(...payloads) {
  return (dispatch) => {
    dispatch({ type: COLLECT_STATISTIC, payloads });

    const url = '/api_web/portal/grassroots/statistics/actions';
    const method = 'POST';

    const requests = payloads.map(payload =>
      Promise.resolve(
        $.ajax({
          method,
          url,
          data: JSON.stringify(payload),
          contentType: 'application/json',
        })
      )
    );

    return Promise.all(requests);
  };
}
