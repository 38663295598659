import moment from 'moment';
import map from 'lodash/map';

import { CALENDAR_DATE_FORMAT } from 'utils/constants/date';

export function transformTimeSeries(timeSeries) {
  return map(timeSeries, ({ published_at: publishedAt, date, count }) => {
    const timestamp = publishedAt || date;

    return {
      date: moment(timestamp).format(CALENDAR_DATE_FORMAT),
      count,
    };
  });
}

export function transformTimeSeriesParams({ selectedFeedId, clusterId, mention, tagId }) {
  if (tagId) {
    return { tag_id: tagId };
  }

  if (clusterId) {
    return { clusters: [clusterId] };
  }

  if (mention) {
    return { entities_title_text: mention };
  }

  if (selectedFeedId) {
    return { config_id: selectedFeedId };
  }

  return {};
}
