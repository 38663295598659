import { isNull, omitBy } from 'lodash';

import { transformItemsToBE as transformEmails } from 'components/Stakeholder/MergeTool/components/MergeForm/components/MergeEmails/utils';
import { transformItemsToBE as transformAddresses } from 'components/Stakeholder/MergeTool/components/MergeForm/components/MergeAddresses/utils';
import { transformItemsToBE as transformPhones } from 'components/Stakeholder/MergeTool/components/MergeForm/components/MergePhones/utils';
import { transformItemsToBE as transformTags } from 'components/Stakeholder/MergeTool/components/MergeForm/components/MergeTags/utils';
import { transformItemsToBE as transformLists } from 'components/Stakeholder/MergeTool/components/MergeForm/components/MergeLists/utils';

export function transformToBE(payload = {}) {
  const { stakeholder, mergeRecords } = payload;
  const {
    firstName,
    lastName,
    middleName,
    facebookProfile,
    twitterName,
    partyCode,
    customFields,
    emails,
    addresses,
    phones,
    lists,
    tags,
    ...restStakeholderProps
  } = stakeholder || {};

  return {
    stakeholder: {
      ...restStakeholderProps,
      tags: transformTags(tags),
      lists: transformLists(lists),
      addresses: transformAddresses(addresses),
      emails: transformEmails(emails),
      phones: transformPhones(phones),
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      facebook_profile: facebookProfile,
      twitter_username: twitterName,
      party_code: partyCode,
      custom_fields: omitBy(customFields, isNull),
    },
    merge_records: mergeRecords,
  };
}
