import compact from 'lodash/compact';

import {
  getValue,
  formatAmounts,
  formatZipCode,
  formatSelectValueForFE,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
} from 'redux/moneyball/utils/transformers';
import { getAmountValue } from 'redux/moneyball/utils';
import { getDisplayItem } from 'redux/advancedSearch/utils';

export function transformCorporations(corporations = []) {
  return corporations.map((corporationItem) => {
    const { corporation, total_donated } = corporationItem;
    const { id, zip_code, state_code, ...restProps } = corporation;

    return ({
      ...restProps,
      id,
      url: `/moneyball_v2/corporations/${id}`,
      state: state_code,
      amount: total_donated,
      zip_code: formatZipCode(zip_code),
    });
  });
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const {
      query,
      contributed_amount,
      names,
      address_streets,
      address_cities,
      address_states,
      address_zipcodes,
    } = search_params || {};

    return ({
      id,
      title: name || 'Applied filters:',
      filters: compact([
        getDisplayItem('Searched', query),
        getDisplayItem('Amounts', getAmountValue(contributed_amount)),
        getDisplayItem('Corporation(s)', names),
        getDisplayItem('Street name(s)', address_streets),
        getDisplayItem('Cities', address_cities),
        getDisplayItem('State(s)', address_states),
        getDisplayItem('Zip code(s)', address_zipcodes),
      ]),
      options: {
        ...getValue('searchValue', query),
        options: {
          ...getValue('amounts', formatAmounts(contributed_amount)),
          ...getValue('corporation', formatSelectValueForFE(names)),
          ...getValue('street', formatSelectValueForFE(address_streets)),
          ...getValue('cities', formatSelectValueForFE(address_cities)),
          ...getValue('states', formatSelectValueForFE(address_states)),
          ...getValue('zipCodes', formatSelectValueForFE(address_zipcodes)),
        },
      },
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    amounts,
    corporation,
    street,
    cities,
    states,
    zipCodes,
  } = options || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('contributed_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('address_streets', formatSelectValueToArrForBE(street)),
    ...getValue('address_cities', formatSelectValueToArrForBE(cities)),
    ...getValue('address_states', formatSelectValueToArrForBE(states)),
    ...getValue('address_zip_codes', formatSelectValueToArrForBE(zipCodes)),
    ...getValue('super_corporation_ids', formatSelectValueToArrForBE(corporation)),
  };
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformOptionsSearchForBE(data = {}) {
  const baseOptions = transformOptionsForBE(data);
  const { options } = data;
  const {
    corporation,
    street,
    cities,
    states,
    zipCodes,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('names', formatSelectValueToObjForBE(corporation)),
    ...getValue('address_streets', formatSelectValueToObjForBE(street)),
    ...getValue('address_cities', formatSelectValueToObjForBE(cities)),
    ...getValue('address_states', formatSelectValueToObjForBE(states)),
    ...getValue('address_zipcodes', formatSelectValueToObjForBE(zipCodes)),
  };
}
