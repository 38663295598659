import { isEmpty, isNil, omitBy } from 'lodash';

import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';

import { transformStaffersForUI, transformOptionsForBE } from '../utils';
import constants from '../constants';

export function getStaffersData(payload) {
  const { searchData = {}, appliedFilters, clearAll, sort, direction, page } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const url = '/api_web/state/staffers/lookup';
  const sortData = omitBy({ sort, direction, page }, isNil);
  const data = { ...searchForm, ...sortData };

  return { isSearch, url, data, sortData };
}

export function fetchStaffers(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_STAFFERS });

    const { searchData = {}, clearAll } = payload;
    const { isSearch, url, data, sortData } = getStaffersData(payload);
    const ajax = $.post(url, data);

    function onSuccess(response) {
      const currentPayload = (isEmpty(response) || isEmpty(response.data)) ? [] : response.data;

      return dispatch({
        type: constants.RECEIVE_STAFFERS_DONE,
        payload: transformStaffersForUI(currentPayload),
        paginator: transformPaginator(response),
        toResetSelectedItems: isSearch,
        sort: transformSort(sortData),
      });
    }

    function saveAppliedFiltersToProps() {
      return clearAll
        ? dispatch(resetAppliedFilters())
        : dispatch(handleAppliedFilters(searchData));
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(saveAppliedFiltersToProps);
  };
}

export function resetStaffers() {
  return dispatch => (
    dispatch({
      type: constants.RESET_STAFFERS,
    })
  );
}
