import { isEmpty, isString } from 'lodash';
import moment from 'moment/moment';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { GLOBAL_DATE_FORMAT, MONTH_SHORT_DAY_YEAR_FORMAT } from 'utils/constants/date';
import { FEDERAL_BILL_ITEM_TYPE } from 'utils/constants/itemTypes';

import { transformLegislator } from './legislator';
import { transformHearings } from './hearings';

export function transformProfileForUI(payload) {
  const { data } = payload || {};
  const {
    id,
    chamber,
    introduced_at,
    display_title,
    display_bill_id,
    status_full,
    short_title,
    official_title,
    status_step,
    text_url,
    last_action,
    sponsor,
    committee_hearings,
    companion_bill,
    text_summary,
    congress,
  } = data || {};
  const { text, date } = last_action || {};
  const { url, display_id: companionBillId } = companion_bill ? companion_bill.related_bill : {};
  const companionBillUrl = !isEmpty(url) ? url : null;
  const lastAction = {
    date: date && moment(date).format(MONTH_SHORT_DAY_YEAR_FORMAT),
    description: text,
  };

  return {
    id,
    lastAction,
    documentType: DOCUMENT_TYPES.FEDERAL_BILL,
    itemType: FEDERAL_BILL_ITEM_TYPE,
    recordId: id,
    withTrack: true,
    keyDetails: {
      chamber,
      textSummary: text_summary,
      billName: display_title,
      shortTitle: short_title,
      billUrl: id && `/federal/bills/${id}`,
      officialTitle: official_title,
      displayBillId: display_bill_id,
      billStatusFull: status_full,
      billStatusStep: status_step,
      hearings: transformHearings(committee_hearings),
      billText: { href: text_url, value: 'View', isExternal: true },
      introducedAt: introduced_at && moment(introduced_at).format(MONTH_SHORT_DAY_YEAR_FORMAT),
      sponsor: transformLegislator({ ...sponsor, isSponsor: true }),
      companionBill: { href: companionBillUrl, value: companionBillId },
      lastAction,
      congress,
    },
  };
}

export function transformCommitteesForUI(payload) {
  const { data } = payload || {};
  const committees = data || [];

  return {
    committeesAssignments: committees.map(committee => ({
      value: committee.name,
      href: `/federal/committees/${committee.id}`,
    })),
  };
}

export function transformCosponsorsForUI(payload) {
  const { data } = payload || {};
  const {
    total_republican_cosponsors,
    total_democratic_cosponsors,
    total_independent_cosponsors,
    original_cosponsors,
    additional_cosponsors,
  } = data || {};

  return {
    cosponsors: {
      republican: {
        numCosponsors: total_republican_cosponsors,
        partyShort: 'R',
        partySlug: 'republican',
      },
      democratic: {
        numCosponsors: total_democratic_cosponsors,
        partyShort: 'D',
        partySlug: 'democratic',
      },
      independent: {
        numCosponsors: total_independent_cosponsors,
        partyShort: 'I',
        partySlug: 'independent',
      },
      originalCosponsors: original_cosponsors && original_cosponsors.map(
        item => transformLegislator({ ...item, isOriginalCosponsor: true })
      ),
      otherCosponsors: additional_cosponsors && additional_cosponsors.map(
        item => transformLegislator({ ...item, isCosponsor: true })
      ),
    },
  };
}

function transformReportList(reports, isExternal = true) {
  if (isEmpty(reports)) {
    return [];
  }

  if (isString(reports)) {
    return [{ href: reports, isExternal }];
  }

  return reports.map(({ title, url }) => ({ href: url, value: title, isExternal }));
}

function transformCRSReports(reports) {
  if (isEmpty(reports)) {
    return [];
  }

  return transformReportList(reports)
    .map(item => ({ ...item, value: 'View' }));
}

export function transformOfficialReportsForUI(payload) {
  const { data } = payload || {};
  const { sap_reports, crs_reports, committee_reports, cbo_reports } = data || {};

  return {
    officialReports: {
      sapReports: transformReportList(sap_reports),
      crsReports: transformCRSReports(crs_reports),
      committeeReports: transformReportList(committee_reports),
      cboCostEstimate: transformReportList(cbo_reports),
    },
  };
}

export function transformTimeline(payload) {
  const { data } = payload || {};

  if (isEmpty(data)) {
    return [];
  }

  function getItemTitle(item, index) {
    const { type_name = '' } = item;

    if (index === 0) {
      return 'Last Action';
    }

    return type_name;
  }

  return data.map((item, index) => ({
    ...item,
    title: getItemTitle(item, index),
    date: item && item.date && moment(item.date).format(GLOBAL_DATE_FORMAT),
  }));
}
