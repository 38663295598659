import isEmpty from 'lodash/isEmpty';

export function transformStaffersForUI(staffersData = {}) {
  const { data } = staffersData;

  if (isEmpty(data)) {
    return { allItems: [] };
  }

  return {
    allItems: data.map((staffer = {}) => {
      const { id, alias_name, full_name, title, responsibilities, email, phone, url } = staffer;
      return {
        id,
        name: alias_name || full_name,
        title,
        issues: responsibilities,
        email,
        phone,
        url,
      };
    }),
  };
}
