import isEqual from 'lodash/isEqual';

import { getValue } from 'utils/transformers/advancedSearch';
import {
  loadRecentSearches,
  loadSavedSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';
import emptyState from 'components/Local/Lookup/components/AdvancedOptions/utils/emptyState';

import { STRATEGY_SLUG } from '../constants';
import {
  transformSearchesForUI,
  transformSearchesForBE,
} from './utils';

/** Advanced search actions */
export function loadLocalRecentSearches(params, localData) {
  const recentSearch = { ...params, strategy_slug: STRATEGY_SLUG };

  return dispatch => dispatch(loadRecentSearches(
    recentSearch,
    data => transformSearchesForUI(data, localData)
  ));
}

export function loadLocalSavedSearches(params, localData) {
  const savedSearch = { ...params, strategy_slug: STRATEGY_SLUG };

  return loadSavedSearches(
    savedSearch,
    data => transformSearchesForUI(data, localData),
  );
}

export function saveAdvancedSearchItem(data = {}, localData, isSaved = true) {
  const advancedSearchData = {
    id: data.id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: STRATEGY_SLUG,
      search_params: transformSearchesForBE(data),
      saved: isSaved,
    },
  };

  if (!isSaved) {
    return onSaveRecentItem(
      advancedSearchData,
      params => loadLocalRecentSearches(params, localData),
    );
  }

  return onSaveItem(
    advancedSearchData,
    params => loadLocalRecentSearches(params, localData),
  );
}

export function removeAdvancedSearchItem(id, localData = {}) {
  return onRemoveItem(
    id,
    params => loadLocalSavedSearches(params, localData)
  );
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const { searchData = {}, localData = {}, isClickedItem = false } = payload;
    const { searchValue, options } = searchData;
    const { searchValue: emptySearch, options: emptyOptions } = emptyState;
    const isSearchEmpty = isEqual(searchValue, emptySearch) && isEqual(options, emptyOptions);

    if (!isClickedItem && !isSearchEmpty) {
      return dispatch(saveAdvancedSearchItem(searchData, localData, false));
    }

    return null;
  };
}
