const PREFIX = 'MONEYBALL_RECEIPT_LOOKUP';

const constants = {
  STRATEGY_SLUG: 'moneyball_receipt_lookup',
  FETCH_RECEIPTS: `${PREFIX}/FETCH_RECEIPTS`,
  RECEIVE_RECEIPTS_DONE: `${PREFIX}/RECEIVE_RECEIPTS_DONE`,
  FETCH_RECEIPT: `${PREFIX}/FETCH_RECEIPT`,
  RECEIVE_RECEIPT_DONE: `${PREFIX}/RECEIVE_RECEIPT_DONE`,
  SUBMIT_EXPORT_REPORT_LOADING: `${PREFIX}/SUBMIT_EXPORT_REPORT_LOADING`,
  SUBMIT_EXPORT_REPORT_DONE: `${PREFIX}/SUBMIT_EXPORT_REPORT_DONE`,
};

export default constants;
