import flatten from 'lodash/flatten';

import {
  deleteEventItem,
  requestDateFormat,
  convertDateTimeZone,
} from 'components/hill-day-calendar/components/event-calendar/components/schedule/components/helpers';

import {
  SAVE_EVENT,
  FETCH_EVENT,
  RECEIVE_EVENT,
  DELETE_EVENT_ITEM,
} from './hill-day-calendar-event-builder';

const constants = {
  FETCH_FLY_IN: 'FLY_IN_CALENDAR/FETCH_FLY_IN',
  CLEAR_EVENTS: 'FLY_IN_CALENDAR/CLEAR_EVENTS',
  CLEAR_EVENT_ITEM: 'FLY_IN_CALENDAR/CLEAR_EVENT_ITEM',
  FETCH_EVENTS: 'FLY_IN_CALENDAR/FETCH_EVENTS',
  FETCH_EVENT_ITEM: 'FLY_IN_CALENDAR/FETCH_EVENT_ITEM',
  RECEIVE_EVENTS: 'FLY_IN_CALENDAR/RECEIVE_EVENTS',
  RECEIVE_FLY_IN: 'FLY_IN_CALENDAR/RECEIVE_FLY_IN',
  RECEIVE_EVENT_ITEM: 'FLY_IN_CALENDAR/RECEIVE_EVENT_ITEM',
};

const baseAPIUrl = '/api_web/hill_day/hill_days';

export function clearEvent(payload) {
  return (dispatch) => {
    dispatch({ type: constants.CLEAR_EVENT_ITEM, payload });
  };
}

export function fetchEvent(payload) {
  return (dispatch) => {
    dispatch({ type: FETCH_EVENT, payload });
    dispatch({ type: constants.FETCH_EVENT_ITEM, payload });

    const { flyInToken, eventToken } = payload;

    const url = `${baseAPIUrl}/${flyInToken}/events/${eventToken}`;
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax).then((result) => {
      dispatch({ type: RECEIVE_EVENT, payload: result });
      dispatch({ type: constants.RECEIVE_EVENT_ITEM, payload: result });
    });
  };
}

export function fetchEvents(payload) {
  return (dispatch) => {
    const { flyInToken, searchForm, page = 1 } = payload;

    if (page === 1) {
      dispatch({ type: constants.CLEAR_EVENTS, payload });
    }

    dispatch({ type: constants.FETCH_EVENTS });

    // uses api_web_hill_day_hill_day_events_path
    const url = `${baseAPIUrl}/${flyInToken}/events`;
    const method = 'GET';
    const data = {
      page,
      search_form: searchForm,
    };

    const ajax = $.ajax({
      method,
      url,
      data,
    });

    return Promise.resolve(ajax).then(
      result => dispatch({ type: constants.RECEIVE_EVENTS, payload: result })
    );
  };
}

export function fetchFlyIn(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_FLY_IN, payload });

    // uses api_web_hill_day_hill_day_path
    const url = `${baseAPIUrl}/${payload.flyInToken}`;
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax).then(
      result => dispatch({ type: constants.RECEIVE_FLY_IN, payload: result })
    );
  };
}

export const defaultState = {
  events: [],
  flyIn: {},
  ui: {
    loading: true,
    initialLoading: true,
    loadingEventItem: true,
    isLoadingEvents: true,
  },
  eventItem: {
    state: {},
    people: [],
    legislators: [],
    attendees: [],
    staffers: [],
  },
  pagination: {},
};

const stateClearEvents = state => ({
  ...state,
  events: [],
  ui: { ...state.ui, loading: true },
});

const stateClearEventItem = state => ({
  ...state,
  eventItem: defaultState.eventItem,
  ui: { ...state.ui, loadingEventItem: false },
});

const stateFetchEvents = state => ({
  ...state,
  ui: { ...state.ui, isLoadingEvents: true },
});

const stateReceiveEvents = (state, action) => ({
  ...state,
  events: [
    ...state.events,
    ...action.payload.data,
  ],
  pagination: action.payload.pagination,
  ui: {
    ...state.ui,
    loading: false,
    initialLoading: false,
    isLoadingEvents: false,
  },
});

const stateFetchFlyIn = state => ({
  ...state,
  flyIn: {},
  ui: { ...state.ui, loading: true },
});

const stateFetchEventItem = state => ({
  ...state,
  eventItem: state.eventItem,
  ui: { ...state.ui, loadingEventItem: true },
});

const stateReceiveFlyIn = (state, action) => ({
  ...state,
  flyIn: action.payload,
  ui: { ...state.ui, loading: false },
});

const stateReceiveEventItem = (state, action) => ({
  ...state,
  eventItem: action.payload,
  ui: { ...state.ui, loadingEventItem: false },
});

const stateDeleteEvent = (state, eventToken) => ({
  ...state,
  events: deleteEventItem(state.events, eventToken),
});

const stateSaveEventItem = (state, action) => {
  let { events } = state;
  const event = action.payload;
  const eventTokens = flatten(events.map(e => e.events.map(ev => ev.token))).sort();

  // ======================================= //
  // Edit meeting
  if (eventTokens.includes(event.token)) {
    events = deleteEventItem(events, event.token);
  }

  const date = convertDateTimeZone(event.starts_at, requestDateFormat);
  const groupIndex = events.findIndex(g => g.date === date);

  // ======================================= //
  // Add a new meeting to an existing date
  if (groupIndex >= 0) {
    events[groupIndex] = {
      ...events[groupIndex],
      events: [
        ...events[groupIndex].events,
        event,
      ],
    };

    const group = events[groupIndex];

    return {
      ...state,
      events: events.map(g => (g.date === date ? group : g)),
    };
  }

  // ======================================= //
  // Add a new meeting to a new date
  const newGroup = {
    date,
    events: [{
      ...defaultState.eventItem,
      ...event,
    }],
  };

  return {
    ...state,
    events: [
      ...events,
      newGroup,
    ],
  };
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.CLEAR_EVENTS:
      return stateClearEvents(state);
    case constants.CLEAR_EVENT_ITEM:
      return stateClearEventItem(state);
    case constants.FETCH_EVENTS:
      return stateFetchEvents(state);
    case constants.RECEIVE_EVENTS:
      return stateReceiveEvents(state, action);
    case constants.FETCH_FLY_IN:
      return stateFetchFlyIn(state);
    case constants.RECEIVE_FLY_IN:
      return stateReceiveFlyIn(state, action);
    case constants.FETCH_EVENT_ITEM:
      return stateFetchEventItem(state);
    case constants.RECEIVE_EVENT_ITEM:
      return stateReceiveEventItem(state, action);
    case SAVE_EVENT:
      return stateSaveEventItem(state, action);
    case DELETE_EVENT_ITEM:
      return stateDeleteEvent(state, action.payload.eventToken);
    default:
      return state;
  }
}
