import { map, groupBy, keys } from 'lodash';

import { POST_REQUEST } from 'utils/constants/api';
import { BASE_URL } from 'redux/dashboardV2/constants';
import { getMyFeed } from 'components/Dashboard/utils';

import {
  transformDocumentIdsForBE,
  transformMyFeedDocuments,
  transformDocumentsForProfile,
} from './utils/transformers';

export function fetchDocuments(documentType, documentIds) {
  const url = `${BASE_URL}/my_feed/subscriptions/check/${documentType}`;
  const ajax = $.ajax({
    url,
    method: POST_REQUEST,
    data: JSON.stringify({ document_ids: transformDocumentIdsForBE(documentIds) }),
    contentType: 'application/json',
  });

  return Promise.resolve(ajax);
}

export function fetchMyFeedDocuments(
  { payload: payloadData } = {},
  actionTypes = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_MY_FEED_DOCUMENTS });

    const payloadGroups = groupBy(payloadData, 'documentType');
    const documentTypes = keys(payloadGroups);

    function onSuccess(response) {
      dispatch({
        type: actionTypes.RECEIVE_MY_FEED_DOCUMENTS_DONE,
        payload: transformMyFeedDocuments(response, documentTypes),
      });

      return { payload: payloadData };
    }

    function onFailure(error) {
      dispatch({ type: actionTypes.RECEIVE_MY_FEED_DOCUMENTS_FAILURE });
      throw error;
    }

    const requests = map(documentTypes, (documentType) => {
      const documentIds = map(payloadGroups[documentType], 'id');

      return fetchDocuments(documentType, documentIds);
    });

    return Promise.all(requests)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchProfileDocument(payload = {}, actionTypes = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_MY_FEED_DOCUMENTS });

    const { documentType, id } = payload;

    function onSuccess(response) {
      dispatch({
        type: actionTypes.RECEIVE_MY_FEED_DOCUMENTS_DONE,
        payload: transformDocumentsForProfile(response, id),
      });
    }

    function onFailure(error) {
      dispatch({ type: actionTypes.RECEIVE_MY_FEED_DOCUMENTS_FAILURE });
      throw error;
    }

    return fetchDocuments(documentType, [id])
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updateProfileShare(actionTypes = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_PROFILE_MY_FEED, payload: [getMyFeed()] });
  };
}
