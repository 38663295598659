const constants = {
  REGISTER_SELECTOR: 'SELECT_ALL/REGISTER_SELECTOR',
  UPDATE_SELECTOR: 'SELECT_ALL/UPDATE_SELECTOR',
  UPDATE_ALL_SELECTORS: 'SELECT_ALL/UPDATE_ALL_SELECTORS',
};


export function registerSelector(payload) {
  return { type: constants.REGISTER_SELECTOR, payload };
}

export function updateSelector(payload) {
  return { type: constants.UPDATE_SELECTOR, payload };
}

export function updateAllSelectors(payload) {
  return { type: constants.UPDATE_ALL_SELECTORS, payload };
}


export const defaultState = {
  selectors: {},
};


export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.REGISTER_SELECTOR:
      return {
        ...state,
        selectors: {
          ...state.selectors,
          [action.payload.key]: action.payload.value,
        },
      };
    case constants.UPDATE_SELECTOR:
      return {
        ...state,
        selectors: {
          ...state.selectors,
          [action.payload.key]: action.payload.value,
        },
      };
    case constants.UPDATE_ALL_SELECTORS: {
      const { value } = action.payload;
      const selectors = {};

      Object.keys(state.selectors)
        .forEach((key) => {
          selectors[key] = value;
        });

      return {
        ...state,
        selectors,
      };
    }
    default:
      return state;
  }
}
