const PREFIX = 'STAKEHOLDER_MANAGEMENT';

const constants = {
  STRATEGY_SLUG: 'stakeholder_mgmt_stakeholder',
  STRATEGY_SLUG_FOR_EXPORT: 'stakeholder_mgmt_stakeholder',
  FETCH_STAKEHOLDERS: `${PREFIX}/FETCH_STAKEHOLDERS`,
  RECEIVE_STAKEHOLDERS_DONE: `${PREFIX}/RECEIVE_STAKEHOLDERS_DONE`,
  RECEIVE_STAKEHOLDERS_FAIL: `${PREFIX}/RECEIVE_STAKEHOLDERS_FAIL`,
  FETCH_STAKEHOLDERS_MAP: `${PREFIX}/FETCH_STAKEHOLDERS_MAP`,
  RECEIVE_STAKEHOLDERS_MAP_DONE: `${PREFIX}/RECEIVE_STAKEHOLDERS_MAP_DONE`,
  RECEIVE_STAKEHOLDERS_MAP_FAIL: `${PREFIX}/RECEIVE_STAKEHOLDERS_MAP_FAIL`,
  FETCH_STAKEHOLDER_LISTS: `${PREFIX}/FETCH_STAKEHOLDER_LISTS`,
  RECEIVE_STAKEHOLDER_LISTS_DONE: `${PREFIX}/RECEIVE_STAKEHOLDER_LISTS_DONE`,
  SUBMIT_STAKEHOLDER_LISTS: `${PREFIX}/SUBMIT_STAKEHOLDER_LISTS`,
  SUBMIT_STAKEHOLDER_LISTS_DONE: `${PREFIX}/SUBMIT_STAKEHOLDER_LISTS_DONE`,
  SUBMIT_STAKEHOLDER_LISTS_FAIL: `${PREFIX}/SUBMIT_STAKEHOLDER_LISTS_FAIL`,
  SUBMIT_APPLIED_TAGS: `${PREFIX}/SUBMIT_APPLIED_TAGS`,
  SUBMIT_APPLIED_TAGS_DONE: `${PREFIX}/SUBMIT_APPLIED_TAGS_DONE`,
  SUBMIT_APPLIED_TAGS_FAIL: `${PREFIX}/SUBMIT_APPLIED_TAGS_FAIL`,
  FETCH_STAKEHOLDER_EMAILS: `${PREFIX}/FETCH_STAKEHOLDER_EMAILS`,
  RECEIVE_STAKEHOLDER_EMAILS_DONE: `${PREFIX}/RECEIVE_STAKEHOLDER_EMAILS_DONE`,
  FETCH_DISTRICT_STAKEHOLDERS_METADATA: `${PREFIX}/FETCH_DISTRICT_STAKEHOLDERS_METADATA`,
  RECEIVE_DISTRICT_STAKEHOLDERS_METADATA: `${PREFIX}/RECEIVE_DISTRICT_STAKEHOLDERS_METADATA`,
  DISTRICT_STAKEHOLDERS_METADATA_FAIL: `${PREFIX}/DISTRICT_STAKEHOLDERS_METADATA_FAIL`,
  RESET_STATE: `${PREFIX}/RESET_STATE`,
};

export default constants;
