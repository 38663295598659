import { combineReducers } from 'redux';
import { isEmpty, size, map } from 'lodash';

import { EXPORT_LIMIT } from 'components/Dashboard/common/FeedPage/utils/constants';
import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import defaultSubscription from '../common/defaultSubscription/reducers';

import {
  undoEvent,
  loadUndoEvent,
  removeEvent,
  setIsOldData,
  loadPositions,
  loadTags,
  updateTags,
  updateDataItem,
  loadSubscriptions,
  updateTagItemLoading,
  loadAllPositionsDone,
  loadEvent,
  loadingBulkRemoveEvents,
  bulkRemoveItems,
  loadingBulkUpdateItems,
  bulkUpdateItems,
  updateEvent,
  updateEventKeyword,
  updateTrackCommitteeInit,
  updateTrackCommittee,
  updateLinksPreview,
  loadLinksPreview,
} from './utils/reducersUtils';
import constants from './constants';

const defaultState = {
  type: '',
  error: false,
  seen: {},
  lastVisit: {},
  keywords: [],
  createdAt: '',
  data: [],
  paginator: {},
  sort: {},
  ui: {
    isLoadingKeywords: true,
    isInitialSeenUpdated: false,
    isInfiniteDisabled: true,
    isLoading: true,
    isInitialLoad: true,
    isLoadingNextPage: false,
  },
};

function myFeed(state = defaultState, action) {
  switch (action.type) {
    case constants.SET_SEEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          isInitialSeenUpdated: true,
        },
      };
    case constants.SET_LAST_VISIT:
      return {
        ...state,
        lastVisit: action.payload,
      };
    case constants.SET_CREATED_TO:
      return {
        ...state,
        createdAt: action.payload,
      };
    case constants.LOAD_KEYWORDS_INIT:
      return {
        ...state,
        keywords: [],
        ui: {
          ...state.ui,
          isLoadingKeywords: true,
        },
      };
    case constants.LOAD_KEYWORDS_DONE:
      return {
        ...state,
        keywords: action.payload,
        ui: {
          ...state.ui,
          isLoadingKeywords: false,
        },
      };
    case constants.LOAD_DATA_INIT:
      return {
        ...state,
        type: action.payload,
        data: action.isInfinite ? state.data : [],
        ui: {
          ...state.ui,
          isLoading: !action.isInfinite,
          isLoadingNextPage: action.isInfinite,
        },
      };
    case constants.LOAD_DATA_DONE:
      return {
        ...state,
        error: action.error,
        data: action.isInfinite ? [...setIsOldData(state.data), ...action.payload] : action.payload,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isInfiniteDisabled: size(action.payload) < EXPORT_LIMIT,
          isInitialLoad: false,
          isLoadingNextPage: false,
        },
      };
    case constants.UPDATE_EVENT_INIT:
      return loadEvent(state, action.payload);
    case constants.LOADING_DATA_FINISH:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: false,
          isInitialLoad: false,
        },
      };
    case constants.UPDATE_EVENT:
      return updateEvent(state, action.payload);
    case constants.UPDATE_EVENT_KEYWORD:
      return updateEventKeyword(state, action.payload);
    case constants.REMOVE_EVENT:
      return removeEvent(state, action.payload);
    case constants.LOAD_UNDO_EVENT:
      return loadUndoEvent(state);
    case constants.UNDO_EVENT:
      return undoEvent(state, action);
    case constants.LOAD_POSITIONS_INIT:
      return {
        ...state,
        data: state.data.map(item => ({
          ...item,
          ...((item.withPosition && item.isNewData) && {
            isLoadingPosition: true,
          }),
        })),
      };
    case constants.LOAD_POSITIONS_DONE:
      return loadPositions(state, action);
    case constants.LOAD_POSITIONS_FINISHED:
      return loadAllPositionsDone(state, action);
    case constants.LOAD_TAGS:
      return loadTags(state, action);
    case constants.LOAD_TAGS_DONE:
      return updateTags(state, action);
    case constants.LOAD_RECENT_ITEM_SUBSCRIPTION:
      return {
        ...state,
        data: map(state.data, (item) => {
          const { frequency, recordId, sendWeekAheadEmail } = action.payload;

          if (recordId === item.recordId) {
            return { ...item, sendWeekAheadEmail, frequency, isTracking: !isEmpty(frequency) };
          }

          return item;
        }),
      };
    case constants.LOAD_ITEMS_SUBSCRIPTIONS_INIT:
      return {
        ...state,
        data: state.data.map(item => ({
          ...item,
          ...((item.withTrack && item.isNewData) && {
            isLoadingTracking: true,
          }),
        })),
      };
    case constants.LOAD_ITEMS_SUBSCRIPTIONS_FINISHED:
      return loadSubscriptions(state, action);
    case constants.UPDATE_DATA_ITEM:
      return updateDataItem(state, action);
    case constants.UPDATE_TAG_ITEM:
      return updateTagItemLoading(state, action);
    case constants.UPDATE_TAG_ITEM_DONE:
      return updateDataItem(state, action);
    case constants.BULK_REMOVE_EVENTS:
      return loadingBulkRemoveEvents(state);
    case constants.BULK_REMOVE_EVENTS_DONE:
      return bulkRemoveItems(state, action);
    case constants.BULK_UPDATE_EVENTS:
      return loadingBulkUpdateItems(state, action);
    case constants.BULK_UPDATE_EVENTS_DONE:
      return bulkUpdateItems(state, action);
    case constants.UPDATE_TRACK_COMMITTEE_INIT:
      return updateTrackCommitteeInit(state, action.payload);
    case constants.UPDATE_TRACK_COMMITTEE:
      return updateTrackCommittee(state, action.payload);
    case constants.SET_CARDS_LINK_PREVIEW_INIT:
      return loadLinksPreview(state, action.payload);
    case constants.SET_CARDS_LINK_PREVIEW_DONE:
      return updateLinksPreview(state, action.payload);
    case constants.RESET:
      return defaultState;
    default:
      return state;
  }
}

export default combineReducers({
  appliedSearchFilters,
  advancedSearch,
  defaultSubscription,
  myFeed,
});
