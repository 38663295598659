import isEmpty from 'lodash/isEmpty';

export function transformLobbyingInformationForUI(rollCallVotesData = {}) {
  const { data = [] } = rollCallVotesData;

  if (isEmpty(data)) {
    return { allItems: [] };
  }

  return {
    allItems: data.map((item) => {
      const { contract_url, client, registrant } = item || {};

      return {
        client,
        firm: registrant,
        contract: {
          url: contract_url,
          name: 'View',
        },
      };
    }),
  };
}
