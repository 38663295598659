import constants from './constants';

export const defaultState = {
  savedSearches: [],
  recentSearches: [],
  savedPaginator: {},
  lastSearchId: undefined,
  ui: {
    isSavedSearchesLoading: false,
    isRecentSearchesLoading: false,
    isSavingSearches: false,
    isRemovingItem: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_SAVED_SEARCHES_DATA:
      return {
        ...state,
        savedSearches: [],
        savedPaginator: {},
        ui: { ...state.ui, isSavedSearchesLoading: true },
      };
    case constants.RECEIVE_SAVED_SEARCHES_DATA_DONE:
      return {
        ...state,
        savedSearches: action.payload || [],
        savedPaginator: action.paginator,
        ui: { ...state.ui, isSavedSearchesLoading: false },
      };
    case constants.FETCH_RECENT_SEARCHES_DATA:
      return {
        ...state,
        recentSearches: [],
        ui: { ...state.ui, isRecentSearchesLoading: true },
      };
    case constants.RECEIVE_RECENT_SEARCHES_DATA_DONE:
      return {
        ...state,
        recentSearches: action.payload || [],
        ui: { ...state.ui, isRecentSearchesLoading: false },
      };
    case constants.SAVE_SEARCH_ITEM:
      return {
        ...state,
        ui: { ...state.ui, isSavingSearches: true },
      };
    case constants.SAVE_SEARCH_ITEM_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSavingSearches: false },
      };
    case constants.SAVE_RECENT_SEARCH_ITEM:
      return {
        ...state,
        ui: { ...state.ui, isSavingSearches: true },
      };
    case constants.SAVE_RECENT_SEARCH_ITEM_DONE:
      return {
        ...state,
        lastSearchId: action.payload.id,
        ui: { ...state.ui, isSavingSearches: false },
      };
    case constants.REMOVE_SEARCH_ITEM:
      return {
        ...state,
        ui: { ...state.ui, isRemovingItem: true },
      };
    case constants.REMOVE_SEARCH_ITEM_DONE:
      return {
        ...state,
        ui: { ...state.ui, isRemovingItem: false },
      };
    case constants.SET_LAST_SEARCH_ID:
      return {
        ...state,
        lastSearchId: action.payload,
      };
    default:
      return state;
  }
}
