import map from 'lodash/map';

export function transformKeywordsResponse({ data = [] }) {
  return map(data, ({ title, subscribable_data, ...restProps }) => {
    const { keyword } = subscribable_data || {};

    return ({
      ...restProps,
      subscribable_data,
      title: title || keyword,
    });
  });
}
