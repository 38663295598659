import isNotFoundError from 'utils/http/isNotFoundError';
import { error as errorNotification } from 'components/core/Notification';
import { fetchProfileTags } from 'redux/tags/actions';

import { fetchAgencyStaffer } from './api';
import constants from './constants';
import { transformPersonForUI } from './transformers';

function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export const fetchProfile = payload => (dispatch) => {
  const { id } = payload || {};
  dispatch({ type: constants.FETCH_PROFILE });

  const onSuccess = (person) => {
    dispatch({
      type: constants.RECEIVE_PROFILE_DONE,
      payload: { person },
    });
  };

  const notifyError = errorNotification('Could not load executive profile. Please try again.');

  const onError = (error) => {
    notifyError();

    isNotFoundError(error) && dispatch({ type: constants.PROFILE_NOT_FOUND });

    throw error;
  };

  fetchAgencyStaffer(id)
    .then(transformPersonForUI)
    .then(onSuccess)
    .then(() => dispatch(fetchTags(payload)))
    .catch(onError);
};

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}
