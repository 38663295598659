import { combineReducers } from 'redux';

import exportReport from 'redux/exportReport/reducers';

import constants from './constants';

const DEFAULT_STATE = {
  documentView: {},
  regulation: {
    tags: [],
    sharings: [],
  },
  ui: {
    isLoadingRegulation: true,
    isLoadingDocument: true,
  },
};

function document(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_REGULATION_DETAILS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingRegulation: true },
      };
    case constants.RECEIVE_REGULATION_DETAILS_DONE:
      return {
        ...state,
        regulation: {
          ...action.payload,
          sharings: state.regulation.sharings,
          tags: state.regulation.tags,
        },
        ui: { ...state.ui, isLoadingRegulation: false },
      };
    case constants.FETCH_DOCUMENT:
      return {
        ...state,
        ui: { ...state.ui, isLoadingDocument: true },
      };
    case constants.RECEIVE_DOCUMENT_DONE:
      return {
        ...state,
        documentView: action.payload,
        ui: { ...state.ui, isLoadingDocument: false },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        regulation: {
          ...state.regulation,
          tags: action.payload,
        },
      };
    case constants.RECEIVE_IS_SHARED_DONE:
      return {
        ...state,
        regulation: {
          ...state.regulation,
          sharings: action.payload,
        },
      };
    default:
      return state;
  }
}

export default combineReducers({
  document,
  exportReport,
});
