export const FETCH_CAMPAIGN = 'GRASSROOTS_TAKE_ACTION/FETCH_CAMPAIGN';
export const RECEIVE_CAMPAIGN = 'GRASSROOTS_TAKE_ACTION/RECEIVE_CAMPAIGN';
export const STOP_LOADING = 'GRASSROOTS_TAKE_ACTION/STOP_LOADING';

export const TEMPORARY_SAVE_FORM_SUBMISSION = 'GRASSROOTS_TAKE_ACTION/TEMPORARY_SAVE_FORM_SUBMISSION';
export const EDIT_FORM_SUBMISSION = 'GRASSROOTS_TAKE_ACTION/EDIT_FORM_SUBMISSION';
export const SAVE_FORM_SUBMISSION = 'GRASSROOTS_TAKE_ACTION/SAVE_FORM_SUBMISSION';
export const RECEIVE_FORM_SUBMISSION = 'GRASSROOTS_TAKE_ACTION/RECEIVE_FORM_SUBMISSION';

export const FETCH_LEGISLATORS = 'GRASSROOTS_TAKE_ACTION/FETCH_LEGISLATORS';
export const RECEIVE_LEGISLATORS = 'GRASSROOTS_TAKE_ACTION/RECEIVE_LEGISLATORS';

export const FETCH_REGULATION = 'GRASSROOTS_TAKE_ACTION/FETCH_REGULATION';
export const RECEIVE_REGULATION = 'GRASSROOTS_TAKE_ACTION/RECEIVE_REGULATION';

export const SAVE_MESSAGES = 'GRASSROOTS_TAKE_ACTION/SAVE_MESSAGES';
export const SET_PREVIEW_MODE = 'GRASSROOTS_TAKE_ACTION/SET_PREVIEW_MODE';

export const SAVE_CUSTOM_MESSAGE = 'GRASSROOTS_TAKE_ACTION/SAVE_CUSTOM_MESSAGE';
export const SAVE_CUSTOM_REGULATION_COMMENT = 'GRASSROOTS_TAKE_ACTION/SAVE_CUSTOM_REGULATION_COMMENT';

export const OPEN_MESSAGE_EDITOR = 'GRASSROOTS_TAKE_ACTION/OPEN_MESSAGE_EDITOR';
export const CLOSE_MESSAGE_EDITOR = 'GRASSROOTS_TAKE_ACTION/CLOSE_MESSAGE_EDITOR';

export const COLLECT_STATISTIC = 'GRASSROOTS_TAKE_ACTION/COLLECT_STATISTIC';

export const FETCH_CUSTOM_COLORS = 'GRASSROOTS_TAKE_ACTION/FETCH_CUSTOM_COLORS';
export const RECEIVE_CUSTOM_COLORS = 'GRASSROOTS_TAKE_ACTION/RECEIVE_CUSTOM_COLORS';

export const FETCH_CUSTOM_RECIPIENTS = 'GRASSROOTS_TAKE_ACTION/FETCH_CUSTOM_RECIPIENTS';
export const RECEIVE_CUSTOM_RECIPIENTS = 'GRASSROOTS_TAKE_ACTION/RECEIVE_CUSTOM_RECIPIENTS';
export const CREATE_RECIPIENTS = 'GRASSROOTS_TAKE_ACTION/CREATE_RECIPIENTS';
