import { isEmpty } from 'lodash';

import { getValue, transformReceiptContributions } from 'redux/moneyball/utils/transformers';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { currentDate } from 'utils/date';
import {
  slugify,
  fetchReport,
  fetchSearch,
  fetchSearchReceipt,
  fetchSearchKeyDetails,
  onSetElectionCycles,
  onSelectElectionCycle,
} from 'redux/moneyball/utils';
import {
  loadSavedSearches,
  loadRecentSearches,
  onRemoveItem,
  onSaveItem,
  onSaveRecentItem,
} from 'redux/advancedSearch/actions';

import {
  transformCandidate,
  transformContributions,
  transformSearchesForFE,
  transformOptionsForBE,
  transformOptionsSearchForBE,
} from './utils';
import constants from './constants';

export function setElectionCycles(cycles) {
  return onSetElectionCycles(cycles, constants.SET_ELECTION_CYCLES);
}

export function onSelectCycle(cycle) {
  return onSelectElectionCycle(cycle, constants.SELECT_ELECTION_CYCLE);
}

export function loadCandidateSavedSearches(params) {
  return loadSavedSearches(
    { ...params, strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  );
}

export function loadCandidateRecentSearches() {
  return dispatch => dispatch(loadRecentSearches(
    { strategy_slug: constants.STRATEGY_SLUG },
    transformSearchesForFE,
  ));
}

export function saveCandidateAdvancedSearch(item) {
  const { id = null, data = {} } = item;
  const searchParams = !isEmpty(data) ? transformOptionsSearchForBE(data) : item;
  const advancedSearchData = {
    id,
    advanced_search: {
      ...getValue('name', data.name),
      strategy_slug: constants.STRATEGY_SLUG,
      search_params: searchParams,
      saved: !!id,
    },
  };

  return id
    ? onSaveItem(advancedSearchData, loadCandidateRecentSearches)
    : onSaveRecentItem(advancedSearchData);
}

export function removeCandidateAdvancedSearch({ id }) {
  return onRemoveItem(id, loadCandidateSavedSearches);
}

export function submitExport({ items, exportOption, exportNotifications, toggleExportModal }) {
  const exportIds = items && items.map(item => item.id);
  const url = `${gon.moneyballApiV2}/api/v1/receipts/export`;
  const data = JSON.stringify({
    receipt_ids: exportIds,
    format_type: exportOption,
    dimensions: [
      'contributor_name',
      'contributor_url',
      'contributor_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'amount',
      'receipt_date',
      'election_type',
      'conduit',
      'recipient_name',
      'recipient_url',
      'source_type',
      'filing_url',
      'contributor_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({ url, data, contentType: REQUEST_CONTENT_TYPE })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        toggleExportModal && toggleExportModal();
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function submitExportLookup({ id, title, exportOption, appliedFilters, exportNotifications }) {
  const exportLookupFilters = transformOptionsForBE(appliedFilters);
  const url = `${gon.moneyballApiV2}/api/v1/receipts/export`;
  const data = JSON.stringify({
    lookup: {
      ...exportLookupFilters,
      super_candidate_recipients: {
        ids: [id],
      },
    },
    format_type: exportOption,
    dimensions: [
      'contributor_name',
      'contributor_url',
      'contributor_type',
      'party',
      'street_1',
      'street_2',
      'city',
      'state_code',
      'zip_code',
      'county',
      'district',
      'occupation',
      'employer',
      'amount',
      'receipt_date',
      'election_type',
      'conduit',
      'recipient_name',
      'recipient_url',
      'source_type',
      'filing_url',
      'contributor_source_id',
      'recipient_source_id',
    ],
  });

  return (dispatch) => {
    dispatch({
      type: constants.SUBMIT_EXPORT_REPORT_LOADING,
    });

    fetchReport({
      url,
      data,
      contentType: REQUEST_CONTENT_TYPE,
      customFileName: `${slugify([title, currentDate(), 'receipts'].join('-'))}.${exportOption}`,
    })
      .then(exportNotifications.getSuccess)
      .catch(exportNotifications.getFailed)
      .finally(() => {
        dispatch({
          type: constants.SUBMIT_EXPORT_REPORT_DONE,
        });
      });
  };
}

export function fetchCandidateData(id, params) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CANDIDATE });

    return dispatch(
      fetchSearchKeyDetails({
        payload: params,
        url: `${gon.moneyballApiV2}/api/v1/super_candidates/${id}`,
        actionType: constants.RECEIVE_CANDIDATE_DONE,
        transformResponse: transformCandidate,
      }),
    );
  };
}

export function searchCandidatesReceipt({ id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CANDIDATE_RECEIPT });

    return dispatch(
      fetchSearchReceipt({
        url: `${gon.moneyballApiV2}/api/v1/receipts/${id}`,
        transformResponse: transformReceiptContributions,
        actionType: constants.RECEIVE_CANDIDATE_RECEIPT_DONE,
      }),
    );
  };
}

export function searchCandidatesContributions(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CANDIDATE_CONTRIBUTIONS });

    const { id, ...restPayload } = payload;
    const formattedPayload = {
      ...restPayload,
      super_candidate_recipients: {
        ids: [id],
      },
    };

    return dispatch(
      fetchSearch({
        payload: formattedPayload,
        url: `${gon.moneyballApiV2}/api/v1/receipts/lookup`,
        actionType: constants.RECEIVE_CANDIDATE_CONTRIBUTIONS_DONE,
        transformResponsePayload: transformContributions,
        transformSearchToQuery: transformOptionsForBE,
        transformAdvancedSearchToQuery: transformOptionsSearchForBE,
        saveRecentAdvancedSearch: saveCandidateAdvancedSearch,
      }),
    );
  };
}

export function reset() {
  return dispatch => dispatch(resetAppliedFilters());
}
