import Notifications from 'components/Moneyball/utils/Notifications';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { fetchSearch } from 'redux/moneyball/utils';

import { transformData, transformOptionsForBE } from './utils';
import constants from './constants';

export function initAppliedSearchFilters(emptyState, { searchType, ...restAppliedFilters }) {
  return handleAppliedFilters({
    ...emptyState,
    ...restAppliedFilters,
    ...(searchType && { searchType }),
  });
}

export function searchData(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_DATA_REQUEST });

    const formattedPayload = {
      direction: 'desc',
      sort: 'weight',
      ...payload,
    };

    return dispatch(
      fetchSearch({
        payload: formattedPayload,
        url: `${gon.moneyballApiV2}/api/v1/clusterbuck/first_queue/lookup`,
        actionType: constants.FETCH_DATA_SUCCESS,
        transformResponsePayload: transformData,
        transformSearchToQuery: transformOptionsForBE,
      }),
    );
  };
}

export function deleteData({ reviewer, ids, afterDeleteAction }) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_DATA_REQUEST });

    const formattedPayload = JSON.stringify({
      reviewer_name: reviewer,
      cluster_ids: ids,
    });

    const onSuccess = () => {
      dispatch({
        type: constants.DELETE_DATA_SUCCESS,
        payload: ids,
      });

      afterDeleteAction && afterDeleteAction();
    };

    const onFailure = (error) => {
      dispatch({
        type: constants.DELETE_DATA_SUCCESS,
        payload: [],
      });

      Notifications.defaultSubmitDataFailed();
      throw error;
    };

    const ajax = $.ajax({
      data: formattedPayload,
      url: `${gon.moneyballApiV2}/api/v1/clusterbuck/first_queue/dont_cluster`,
      contentType: REQUEST_CONTENT_TYPE,
      type: 'POST',
    });

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}
