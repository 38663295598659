import { find, map, toString } from 'lodash';

export function loadTags(state) {
  const { data } = state || {};

  return {
    ...state,
    data: map(data, item => ({
      ...item,
      isLoadingTags: true,
    })),
  };
}

function findTags({ tagItem, recordId, documentType }) {
  const { recordId: tagRecordId, recordType } = tagItem || {};

  return toString(recordId) === toString(tagRecordId)
        && recordType === documentType;
}

function mergeDataWithTags(data, tags) {
  return map(data, (item) => {
    const { recordId, documentType } = item || {};
    const foundTag = find(tags, tagItem => findTags({ tagItem, recordId, documentType })) || {};

    return { ...item, ...foundTag, isLoadingTags: false };
  });
}

export function updateTags(state, action) {
  const { data } = state || {};

  return {
    ...state,
    data: mergeDataWithTags(data, action.payload),
  };
}
