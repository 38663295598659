import { concat, difference, intersection, isEmpty } from 'lodash';

import { REGULATION_EQUIVALENT_KEYS } from 'components/State/Regulations/utils/constants';

export function getSelectedTypesForBE(selectedTypes) {
  const regulationsKey = REGULATION_EQUIVALENT_KEYS.toString();
  const hasRegulation = selectedTypes && selectedTypes.includes(regulationsKey);

  if (hasRegulation) {
    return concat(
      selectedTypes.filter(type => type !== regulationsKey),
      REGULATION_EQUIVALENT_KEYS,
    );
  }

  return selectedTypes;
}

export function getSelectedTypesForUI(selectedTypes) {
  if (isEmpty(selectedTypes)) {
    return [];
  }

  const regulationsType = intersection(REGULATION_EQUIVALENT_KEYS, selectedTypes);

  if (!isEmpty(regulationsType)) {
    return [
      ...difference(selectedTypes, REGULATION_EQUIVALENT_KEYS),
      REGULATION_EQUIVALENT_KEYS.toString(),
    ];
  }

  return selectedTypes;
}
