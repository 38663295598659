/**
 * Create redux actions
 *
 * @export
 * @param {string} type - action type
 * @param {string} callback - callback action
 * @returns {function} redux action
 */
export function createAction(type, callback) {
  return (payload = {}) => {
    callback && callback();
    return {
      type,
      payload,
    };
  };
}

/**
 * Parse ajax calls to Promise
 *
 * @export
 * @param {request} ajaxRequest - jQuery ajax Request
 * @returns {Promise}
 */
export function ajaxRequestToPromise(ajaxRequest) {
  return new Promise((resolve, reject) => ajaxRequest
    .done(resolve)
    .fail(reject));
}

/**
 * Helper function to handle side effects' success
 *
 * @param {function} dispatch - Redux dispatch function
 * @param {function} successAction - success redux action
 * @param {function} [reloadAction] - reload redux action
 * @returns {function} success handler
 */
export function handleSuccess(dispatch, successAction, reloadAction) {
  return (result) => {
    successAction && dispatch(successAction(result));
    reloadAction && dispatch(reloadAction(result));
  };
}

/**
 * Helper function to handle side effects' failures
 *
 * @param {function} dispatch - Redux dispatch function
 * @param {function} failureAction - failure redux action
 * @param {string} [errorKey] - Error key to the throned
 * @returns {function} failure handler
 */
export function handleFailure(dispatch, failureAction, errorKey) {
  return (error) => {
    failureAction && dispatch(failureAction());
    throw !errorKey
      ? error
      : error[errorKey];
  };
}

/**
 * Simple ajax fetcher
 *
 * @param {object} actions - Start and finish fetching functions
 * @param {function} transformItemForUI - Function that transforms response object
 * @param {function} onSuccess - Custom on success
 * @param {function} onFailure
 * @param {string} method - Request method type
 * @param {object} ajaxData - Parameters passed to ajax
 * @returns {function} - Dispatch function
 */
export function fetchAjaxData({
  actions,
  transformItemForUI,
  onSuccess,
  onFailure,
  method = 'GET',
  ...ajaxData
}) {
  return (dispatch) => {
    dispatch({ type: actions.fetchStarted });
    const ajax = $.ajax({ ...ajaxData, method });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: actions.fetchFinished,
        payload: transformItemForUI(response),
      }))
      .then(payload => onSuccess && dispatch(onSuccess(payload)))
      .catch((error) => {
        if (actions.notFound && error.status === 404) {
          dispatch({ type: actions.notFound });
          throw error;
        }

        if (actions.failure) {
          dispatch({ type: actions.failure });
          throw error;
        }
        onFailure && dispatch(onFailure(error));
      });
  };
}
