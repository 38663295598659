import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import Notifications from 'components/Moneyball/utils/Notifications';

import constants from './constants';
import { fetchLookupData } from './api';
import {
  transformDataRequest,
  transformDataResponse,
  transformDataLookupResponse,
  transformFiltersResponse,
} from './utils';

function fetchFilterIndustries() {
  const ajax = $.get(`${gon.moneyballApiV2}/api/v1/industries`);

  return Promise.resolve(ajax);
}

function fetchFilterOrganizationTypes() {
  const ajax = $.get(`${gon.moneyballApiV2}/api/v1/organization_types`);

  return Promise.resolve(ajax);
}

export function fetchFilters() {
  return (dispatch) => {
    dispatch({ type: constants.FILTERS_REQUEST });

    const onSuccess = response => dispatch({
      type: constants.FILTERS_SUCCESS,
      payload: transformFiltersResponse(response),
      error: false,
    });

    const onFailure = (error) => {
      dispatch({
        type: constants.FILTERS_SUCCESS,
        payload: {},
        error: true,
      });

      Notifications.defaultFetchDataFailed();
      throw error;
    };

    return Promise.all([dispatch(fetchFilterIndustries), dispatch(fetchFilterOrganizationTypes)])
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function updateFilters(payload) {
  return (dispatch) => {
    dispatch({
      type: constants.UPDATE_FILTERS,
      payload,
    });
  };
}

function fetchTotals(transaction, formattedFilters) {
  const ajax = $.ajax({
    type: 'POST',
    url: `${gon.moneyballApiV2}/api/v1/pac_ranking/totals`,
    contentType: REQUEST_CONTENT_TYPE,
    data: formattedFilters,
  });

  return Promise.resolve(ajax);
}

function fetchGraph(transaction, formattedFilters) {
  const endpoints = {
    disbursements: `${gon.moneyballApiV2}/api/v1/pac_ranking/graph/disbursements`,
    receipts: `${gon.moneyballApiV2}/api/v1/pac_ranking/graph/receipts`,
  };

  const ajax = $.ajax({
    type: 'POST',
    url: endpoints[transaction],
    contentType: REQUEST_CONTENT_TYPE,
    data: formattedFilters,
  });

  return Promise.resolve(ajax);
}

export function fetchData({ filters: { transaction, ...restFilters } }) {
  return (dispatch) => {
    dispatch({ type: constants.DATA_REQUEST });

    const formattedFilters = transformDataRequest(restFilters);
    const resolvers = [
      dispatch(() => fetchTotals(transaction, formattedFilters)),
      dispatch(() => fetchGraph(transaction, formattedFilters)),
    ];

    const onSuccess = response => dispatch({
      type: constants.DATA_SUCCESS,
      payload: transformDataResponse(response),
      error: false,
    });

    const onFailure = (error) => {
      dispatch({
        type: constants.DATA_SUCCESS,
        payload: {
          totals: null,
          graph: null,
        },
        error: true,
      });

      Notifications.defaultFetchDataFailed();
      throw error;
    };

    return Promise.all(resolvers)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchDataLookup({
  isInfinite,
  searchData,
  filters: {
    transaction,
    ...restFilters
  },
}) {
  return (dispatch) => {
    dispatch({ type: constants.DATA_LOOKUP_REQUEST, isInfinite });

    const onSuccess = response => dispatch({
      type: constants.DATA_LOOKUP_SUCCESS,
      payload: transformDataLookupResponse(response),
      error: false,
      isInfinite,
    });

    const onFailure = (error) => {
      dispatch({
        type: constants.DATA_LOOKUP_SUCCESS,
        payload: null,
        error: true,
      });

      Notifications.defaultFetchDataFailed();
      throw error;
    };

    return fetchLookupData({ filters: { ...restFilters, ...searchData }, transaction })
      .then(onSuccess)
      .catch(onFailure);
  };
}
