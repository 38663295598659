import { transformCompanyInfoForUI } from '../transformers';
import constants from '../constants';

export function resetApiToken(companyInfo) {
  return (dispatch) => {
    dispatch({ type: constants.RESET_API_TOKEN });

    const { companyId, apiToken } = companyInfo;

    const url = '/api_web/company-subscription/institutional-data/reset_api_token';
    const method = 'POST';
    const data = {
      company_id: companyId,
      api_token: apiToken,
    };

    const ajax = $.ajax({ url, method, data });

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RESET_API_TOKEN_DONE,
        payload: transformCompanyInfoForUI(response),
      }));
  };
}
