import {
  API_URL,
  FETCH_DONORS,
  RECEIVE_DONORS,
  DONORS_NOT_FOUND,
  DEFAULT_PER_PAGE,
} from '../constants';

export function fetchDonors({ page = 1, ...params }) {
  return (dispatch) => {
    dispatch({ type: FETCH_DONORS, payload: params });

    const ajax = $.get(API_URL, {
      ...params,
      page,
      per_page: DEFAULT_PER_PAGE,
    });

    return Promise.resolve(ajax)
      .then(response =>
        dispatch({ type: RECEIVE_DONORS, payload: response })
      )
      .catch((error) => {
        dispatch({ type: DONORS_NOT_FOUND });
        throw error;
      });
  };
}
