import isEmpty from 'lodash/isEmpty';

import { notifyLoadPageFailure } from 'components/Dashboard/common/UserNotifications';
import { isMyFeed } from 'components/Dashboard/utils';
import { handleUpdateAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { onSaveRecentItem } from 'redux/advancedSearch/actions';
import * as workspacesApi from 'redux/dashboardV2/common/workspaces/api';
import { transformOptionsForBE } from 'redux/dashboardV2/common/workspaces/transformers';
import isNotFoundError from 'utils/http/isNotFoundError';

import constants from './constants';

export { setLastSearchId } from 'redux/advancedSearch/actions';

export function addWorkspace(workspace) {
  return (dispatch) => {
    dispatch({ type: constants.ADD_WORKSPACE, payload: workspace });
  };
}

export function fetchWorkspaces({ searchData = {}, isInfinite = false }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_WORKSPACES, payload: { isInfinite } });

    const onSuccess = ({ feed, pagination }) => {
      const type = constants.RECEIVE_WORKSPACES_DONE;
      dispatch({ type, payload: { feed, pagination, isInfinite } });
      dispatch(handleUpdateAppliedFilters(searchData));
    };

    const onFailure = (error) => {
      notifyLoadPageFailure({});
      throw error;
    };

    workspacesApi.fetchWorkspaces(searchData)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function saveInRecentSearch(payload = {}) {
  return (dispatch) => {
    const data = transformOptionsForBE(payload);

    if (isEmpty(data)) {
      return null;
    }

    const advancedSearchData = {
      advanced_search: {
        strategy_slug: 'workspaces', // TODO: adjust strategy
        search_params: data,
        saved: false,
      },
    };

    return dispatch(onSaveRecentItem(advancedSearchData));
  };
}

export function updateSubscription({ token, frequency, isTracking = true }) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_WORKSPACE_SUBSCRIPTION, payload: { token, frequency, isTracking } });
  };
}

export function deleteWorkspace(workspace) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_WORKSPACE });

    const onSuccess = () => {
      dispatch({ type: constants.DELETE_WORKSPACES_DONE, payload: workspace.token });
    };

    const onFailure = (error) => {
      dispatch({ type: constants.DELETE_WORKSPACE_FAILURE });

      throw error;
    };

    workspacesApi.deleteWorkspace(workspace)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function fetchWorkspace(token) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_WORKSPACE });

    const onSuccess = (response) => {
      dispatch({ type: constants.RECEIVE_WORKSPACE_DONE, payload: response });
    };

    const onFailure = (error) => {
      isNotFoundError(error) && dispatch({ type: constants.WORKSPACE_NOT_FOUND });
      !isNotFoundError(error) && notifyLoadPageFailure({
        itemTitle: isMyFeed(token) ? 'My feed' : null,
      });

      throw error;
    };

    workspacesApi.fetchWorkspace(token)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function resetWorkspace() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_WORKSPACE });
    dispatch(resetAppliedFilters());
  };
}
