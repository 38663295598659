import constants from './constants';

export const defaultState = {
  totalNotifications: 0,
  notifications: [],
  ui: {
    isClearingNotifications: false,
  },
  isNotificationsPanelOpen: false,
};

export default function notifications(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_NOTIFICATIONS_DONE:
      return {
        ...state,
        ...action.payload,
      };
    case constants.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        ui: { ...state.ui, isClearingNotifications: true },
      };
    case constants.CLEAR_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isClearingNotifications: false },
      };
    case constants.RESET_NOTIFICATIONS:
      return defaultState;
    case constants.CLEAR_NOTIFICATIONS_DONE:
      return {
        ...defaultState,
        isNotificationsPanelOpen: state.isNotificationsPanelOpen,
      };
    case constants.TOGGLE_PANEL:
      return { ...state, isNotificationsPanelOpen: !state.isNotificationsPanelOpen };
    case constants.RESET_PANEL:
      return { ...state, isNotificationsPanelOpen: false };
    default:
      return state;
  }
}
