import { geocodeByAddress } from 'react-places-autocomplete';

import { getAddressPartsFromGeoResponse } from 'components/core/AddressAutocomplete/utils';

import {
  TEMPORARY_SAVE_FORM_SUBMISSION,
  RECEIVE_FORM_SUBMISSION,
  EDIT_FORM_SUBMISSION,
  SAVE_FORM_SUBMISSION,
} from '../constants';
import { receiveError } from './utils';

function validateAddressParts(addressParts) {
  const { zipcode, address_1 } = addressParts;
  const validationError = {
    address: 'Please enter full address.',
  };

  if (!zipcode || !address_1) {
    throw validationError;
  }

  return addressParts;
}

function fullAddressToFormSubmissionAddressParts(address) {
  return geocodeByAddress(address)
    .then(getAddressPartsFromGeoResponse)
    .then(validateAddressParts);
}

function validateFormSubmission(formSubmission, campaign) {
  const url = `/api_web/portal/grassroots/campaigns/${campaign.token}/form_submissions/validate`;
  const method = 'POST';
  const data = { form_submission: formSubmission };

  const ajax = $.ajax({ method, url, data });

  return Promise.resolve(ajax)
    .then(() => formSubmission)
    .catch(({ responseJSON }) => {
      throw responseJSON;
    });
}

export function temporarySaveFormSubmission(payload) {
  return (dispatch, getState) => {
    const { campaign } = payload;

    return fullAddressToFormSubmissionAddressParts(payload.formSubmission.address)
      .then(addressParts => ({
        ...payload.formSubmission,
        ...addressParts,
      }))
      .then(formSubmission => validateFormSubmission(formSubmission, campaign))
      .then(formSubmission => (
        dispatch({ type: TEMPORARY_SAVE_FORM_SUBMISSION, payload: formSubmission })
      ))
      .then(() => getState().grassrootsPortal.formSubmission);
  };
}

export function saveFormSubmission(payload) {
  return (dispatch, getState) => {
    dispatch({ type: SAVE_FORM_SUBMISSION, payload });

    const { formSubmission, campaign } = payload;

    const url = `/api_web/portal/grassroots/campaigns/${campaign.token}/form_submissions`;
    const method = 'POST';
    const data = { form_submission: formSubmission };

    const ajax = $.ajax({ url, method, data });

    return Promise.resolve(ajax)
      .then((response) => {
        dispatch({ type: RECEIVE_FORM_SUBMISSION, payload: response });
      })
      .then(() => getState().grassrootsPortal.formSubmission)
      .catch(receiveError(dispatch));
  };
}

export function editFormSubmission(formSubmission) {
  return {
    type: EDIT_FORM_SUBMISSION,
    payload: formSubmission,
  };
}
