export const RECORD_TYPES = {
  federal_staffer: {
    label: 'Federal Staffer',
    pluralLabel: 'Federal Staffers',
    path: ({ record_id }) => `/federal/congressional_staffers/${record_id}`,
  },
  federal_legislator: {
    label: 'Federal Legislator',
    pluralLabel: 'Federal Legislators',
    path: ({ record_id }) => `/federal/legislators/${record_id}`,
  },
  federal_bill: {
    label: 'Federal Bill',
    pluralLabel: 'Federal Bills',
    path: ({ record_id }) => `/federal/bills/${record_id}`,
  },
  federal_committee: {
    label: 'Federal Committee',
    pluralLabel: 'Federal Committees',
    path: ({ record_id }) => `/federal/committees/${record_id}`,
  },
  state_bill: {
    label: 'State Bill',
    pluralLabel: 'State Bills',
    path: ({ record_id }) => `/state/bills/${record_id}`,
  },
  state_staffer: {
    label: 'State Staffer',
    pluralLabel: 'State Staffers',
    path: ({ record_id }) => `/state/staffers/${record_id}`,
  },
  state_legislator: {
    label: 'State Legislator',
    pluralLabel: 'State Legislators',
    path: ({ record_id }) => `/state/legislators/${record_id}`,
  },
  state_committee: {
    label: 'State Committee',
    pluralLabel: 'State Committees',
    path: ({ record_id }) => `/state/committees/${record_id}`,
  },
  state_regulation: {
    label: 'State Regulation',
    pluralLabel: 'State Regulations',
    path: ({ record_id }) => `/state/regulations/${record_id}`,
  },
  federal_regulation: {
    label: 'Federal Regulation',
    pluralLabel: 'Federal Regulations',
    path: ({ record_id }) => `/federal/regulations/${record_id}`,
  },
  colleague: {
    label: 'Colleague',
    pluralLabel: 'Colleagues',
    path: () => {},
  },
  stakeholder: {
    label: 'Stakeholder',
    pluralLabel: 'Stakeholders',
    path: ({ record_id }) => `/stakeholder_management/stakeholder/${record_id}`,
  },
  grassroots_campaign: {
    label: 'Grassroots Campaign',
    pluralLabel: 'Grassroots Campaigns',
    path: ({ record_token }) => `/grassroots/reports/${record_token}`,
  },
  hill_day: {
    label: 'Hill Day',
    pluralLabel: 'Hill Days',
    path: ({ record_token }) => `/hill_day/hill_days/${record_token}`,
  },
  hill_day_meeting: {
    label: 'Hill Day Meeting',
    pluralLabel: 'Hill Day Meetings',
    path: ({ record_token }) => `/hill_day/events/${record_token}`,
  },
  agency_press_release: {
    label: 'Agency press release',
    pluralLabel: 'Agency press releases',
    path: ({ record_external_url }) => record_external_url,
  },
  cbo_cost_estimate: {
    label: 'CBO cost estimate',
    pluralLabel: 'CBO cost estimates',
    path: ({ record_id }) => `/federal/cbo_reports/document/view/${record_id}`,
  },
  committee_report: {
    label: 'Committee report',
    pluralLabel: 'Committee reports',
    path: ({ record_id }) => `/federal/committee_reports/document/view/${record_id}`,
  },
  congressional_press_release: {
    label: 'Congressional press release',
    pluralLabel: 'Congressional press releases',
    path: ({ record_external_url }) => record_external_url,
  },
  congressional_record_item: {
    label: 'Congressional record item',
    pluralLabel: 'Congressional record items',
    path: ({ record_id }) => `/federal/congressional_record/document/view/${record_id}`,
  },
  contract: {
    label: 'Contract',
    pluralLabel: 'Contracts',
    path: ({ record_id }) => `federal/contracts/${record_id}`,
  },
  crs_report: {
    label: 'CRS report',
    pluralLabel: 'CRS reports',
    path: ({ record_id }) => `/federal/crs_reports/document/view/${record_id}`,
  },
  dear_colleague_letter: {
    label: 'Dear Colleague letter',
    pluralLabel: 'Dear Colleague letters',
    path: ({ record_id }) => `/federal/dear_colleague_letters/${record_id}`,
  },
  executive_order: {
    label: 'Executive order',
    pluralLabel: 'Executive orders',
    path: ({ record_id }) => `/federal/executive_orders/document/view/${record_id}`,
  },
  gao_report: {
    label: 'GAO report',
    pluralLabel: 'GAO reports',
    path: ({ record_id }) => `/federal/gao_reports/document/view/${record_id}`,
  },
  grant: {
    label: 'Grant',
    pluralLabel: 'Grants',
    path: ({ record_id }) => `/federal/grants/${record_id}`,
  },
  live_hearing_transcript: {
    label: 'Live hearing transcript',
    pluralLabel: 'Live hearing transcripts',
    path: ({ record_external_url }) => record_external_url,
  },
  official_hearing_transcript: {
    label: 'Official hearing transcript',
    pluralLabel: 'Official hearing transcripts',
    path: ({ record_id }) => `/federal/official_hearing_transcripts/document/view/${record_id}`,
  },
  sap_report: {
    label: 'SAP report',
    pluralLabel: 'SAP reports',
    path: ({ record_id }) => `/federal/sap_reports/document/view/${record_id}`,
  },
  state_of_the_union_address: {
    label: 'State of the Union addresse',
    pluralLabel: 'State of the Union addresses',
    path: ({ record_id }) => `/federal/state_of_the_union_addresses/document/view/${record_id}`,
  },
  treaty_document: {
    label: 'Treaty document',
    pluralLabel: 'Treaty documents',
    path: ({ record_id }) => `/federal/treaties/document/view/${record_id}`,
  },
  third_party_report: {
    label: 'Third party report',
    pluralLabel: 'Third party reports',
    path: ({ record_id }) => `federal/third_party_reports/document/view/${record_id}`,
  },
  white_house_press_briefing: {
    label: 'White House press briefing',
    pluralLabel: 'White House press briefings',
    path: ({ record_id }) => `federal/white_house_press_briefings/document/view/${record_id}`,
  },
  governor_order: {
    label: 'Governor order',
    pluralLabel: 'Governor orders',
    path: ({ record_external_url }) => record_external_url,
  },
  governor_press_release: {
    label: 'Governor press release',
    pluralLabel: 'Governor press releases',
    path: ({ record_external_url }) => record_external_url,
  },
  local_executive: {
    label: 'Local executive',
    pluralLabel: 'Local executives',
    path: ({ record_id }) => `/local/executives/${record_id}`,
  },
  federal_executive: {
    label: 'Federal executive',
    pluralLabel: 'Federal executives',
    path: ({ record_id }) => `/federal/executives/${record_id}`,
  },
  state_executive: {
    label: 'State executive',
    pluralLabel: 'State executives',
    path: ({ record_id }) => `/state/executives/${record_id}`,
  },
  state_agency_staffer: {
    label: 'State agency staffer',
    pluralLabel: 'State agency staffers',
    path: ({ record_id }) => `/state/agency_staffers/${record_id}`,
  },
};
