import { initialValues } from 'components/Dashboard/KeywordSearch/utils/constants';
import { BASE_FEEDS_URL } from 'redux/dashboardV2/constants';

import constants from './constants';
import { transformKeywordForBE, transformKeywordForUI } from './utils';

export function fetchKeyword(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_KEYWORD, payload });

    const { id, allStatesLength, defaultSubscription, feedToken } = payload || {};

    const url = `${BASE_FEEDS_URL}/${feedToken}/keywords/${id}`;
    const ajax = $.get(url);

    return Promise.resolve(ajax)
      .then(response => dispatch({
        type: constants.RECEIVE_KEYWORD_SUCCESS,
        payload: transformKeywordForUI(response, allStatesLength, defaultSubscription),
      }))
      .catch((error) => {
        dispatch({
          type: constants.RECEIVE_KEYWORD_FAILED,
          payload: { ...initialValues, notificationFrequency: defaultSubscription },
        });
        throw error;
      });
  };
}

export function saveKeyword(data) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_KEYWORD });

    const { id, payload, handleSuccess, handleFailure, isUpdate = false, feedToken } = data || {};
    const requestData = transformKeywordForBE(payload, isUpdate);

    const baseUrl = `${BASE_FEEDS_URL}/${feedToken}/keywords`;
    const createData = { url: baseUrl, method: 'POST' };
    const updateData = { url: `${baseUrl}/${id}`, method: 'PUT' };
    const ajaxData = isUpdate ? updateData : createData;

    const ajax = $.ajax({
      ...ajaxData,
      data: JSON.stringify(requestData),
      contentType: 'application/json',
    });

    return Promise.resolve(ajax)
      .then(() => {
        handleSuccess();
        dispatch({ type: constants.SAVE_KEYWORD_DONE });
      })
      .catch((error) => {
        handleFailure(error);
        throw error;
      });
  };
}

export function resetKeywordForm(isLoadingKeyword = true) {
  return dispatch => (
    dispatch({
      type: constants.RESET_KEYWORD_FORM,
      isLoadingKeyword,
    })
  );
}
