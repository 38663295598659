const constants = {
  FETCH_INITIAL_VALUES: 'GRASSROOTS_SETTINGS/FETCH_INITIAL_VALUES',
  STOP_LOADING: 'GRASSROOTS_SETTINGS/STOP_LOADING',

  FETCH_SETTINGS: 'GRASSROOTS_SETTINGS/FETCH_SETTINGS',
  RECEIVE_SETTINGS: 'GRASSROOTS_SETTINGS/RECEIVE_SETTINGS',

  FETCH_VERIFIED_DOMAINS: 'GRASSROOTS_SETTINGS/FETCH_VERIFIED_DOMAINS',
  RECEIVE_VERIFIED_DOMAINS: 'GRASSROOTS_SETTINGS/RECEIVE_VERIFIED_DOMAINS',

  SAVE_SETTINGS: 'GRASSROOTS_SETTINGS/SAVE_SETTINGS',
};


function fetchSettings(dispatch) {
  // uses api_web_grassroots_company_config_path
  const url = '/api_web/grassroots/company_config';
  const method = 'GET';

  const ajax = $.ajax({
    method,
    url,
  });

  return Promise.resolve(ajax)
    .then(settings =>
      dispatch({ type: constants.RECEIVE_SETTINGS, payload: settings })
    );
}

function fetchVerifiedDomains(dispatch) {
  // uses api_web_verified_domains_path
  const url = '/api_web/verified_domains';
  const method = 'GET';

  const ajax = $.ajax({
    method,
    url,
  });

  return Promise.resolve(ajax)
    .then(response =>
      dispatch({ type: constants.RECEIVE_VERIFIED_DOMAINS, payload: response })
    );
}

export function fetchInitialValues(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INITIAL_VALUES, payload });

    const asyncRequests = [
      fetchSettings(dispatch),
      fetchVerifiedDomains(dispatch),
    ];

    return Promise.all(asyncRequests)
      .then(() => dispatch({ type: constants.STOP_LOADING }))
      .catch((error) => {
        dispatch({ type: constants.STOP_LOADING });

        throw error;
      });
  };
}


export function saveSettings(payload) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_SETTINGS, payload });

    const { settings } = payload;

    // uses api_web_grassroots_company_config_path
    const url = '/api_web/grassroots/company_config';
    const method = 'PUT';
    const data = JSON.stringify({ company_config: settings });
    const contentType = 'application/json';

    const ajax = $.ajax({
      method,
      url,
      data,
      contentType,
    });

    return Promise.resolve(ajax);
  };
}


export const defaultState = {
  settings: {
    custom_email_enabled: false,
  },
  verifiedDomains: [],
  ui: {
    loading: false,
  },
};


export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_INITIAL_VALUES:
      return {
        ...state,
        settings: defaultState.settings,
        verifiedDomains: defaultState.verifiedDomains,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case constants.RECEIVE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case constants.RECEIVE_VERIFIED_DOMAINS:
      return {
        ...state,
        verifiedDomains: action.payload.data,
      };
    case constants.STOP_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    default:
      return state;
  }
}
