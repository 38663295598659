import { map, compact, isEmpty, uniq } from 'lodash';

import { transformRequestData, transformPosts } from './transformers';
import { updatePostsReplies } from '.';

export function fetchPosts(searchData) {
  const url = '/api_web/social_media/posts/lookup';
  const ajax = $.post(url, transformRequestData(searchData));

  return Promise.resolve(ajax);
}

export function fetchReplies(posts) {
  const ids = uniq(compact(map(posts, ({ replyTo }) => {
    const { tweetId } = replyTo || {};

    return tweetId;
  })));

  if (isEmpty(ids)) {
    return Promise.resolve(posts);
  }

  function onSuccess(response) {
    const { posts: replies } = response || {};

    return updatePostsReplies(posts, transformPosts(replies));
  }

  return fetchPosts({ ids })
    .then(onSuccess);
}
