import moment from 'moment';
import { CALENDAR_DATE_FORMAT } from 'utils/constants/date';

const defaultState = {
  statistics: {
    advocates_count: null,
    messages_count: null,
    messages_sent: null,
    top_states: null,
    edited_messages: null,
    messages_by_parties: {
      republican: 15,
      democratic: 25,
      other: 8,
    },
    messages_by_chambers: {
      congress: 25,
      senate: 15,
    },
    senders: {},
    federal_recipients: {},
    state_recipients: {},
    custom_recipients: {},
    regulations: {},
  },
  dateRange: {
    startsOn: moment().subtract(1, 'months').format(CALENDAR_DATE_FORMAT),
    endsOn: moment().format(CALENDAR_DATE_FORMAT),
  },
  charts: {},
  ui: {
    loadingCampaign: true,
    loadingStatistics: true,
    loadingTableStatistics: true,
  },
};

export default defaultState;
