import { compact, map } from 'lodash';

import {
  getDisplayItem,
  getDisplayRangeItem,
} from 'redux/advancedSearch/utils';
import {
  transformSelectValues,
  getLabelFromItem,
  getValue,
} from 'utils/transformers/advancedSearch';
import { formatDate, formatGlobalDate } from 'utils/date';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import valueOrEmpty, { valueOrNull } from 'utils/forms/valueOrEmpty';

function transformOptionsForUI(data = {}) {
  const { id, search_params } = data;
  const {
    keyword,
    cities,
    states,
    city_population_gteq,
    city_population_lteq,
    date_gteq,
    date_lteq,
    document_types,
    locale_types,
    tag_ids,
  } = search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', keyword),
      ...getValue('states', states),
      ...getValue('locales', cities),
      ...getValue('date', { start: valueOrNull(date_gteq), end: valueOrNull(date_lteq) }),
      ...getValue('population', { start: valueOrEmpty(city_population_gteq), end: valueOrEmpty(city_population_lteq) }),
      ...getValue('documentFormats', document_types),
      ...getValue('localeTypes', locale_types),
      ...getValue('tags', tag_ids),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}) {
  const { name, search_params } = data;
  const {
    keyword,
    cities,
    states,
    city_population_gteq,
    city_population_lteq,
    date_gteq,
    date_lteq,
    document_types,
    locale_types,
    tag_ids,
  } = search_params || {};

  const filters = [
    getDisplayItem('Searched', keyword),
    getDisplayItem('Locales', getLabelFromItem(cities)),
    getDisplayItem('States', getLabelFromItem(states)),
    getDisplayRangeItem('Population', city_population_gteq, city_population_lteq),
    getDisplayRangeItem('Date', formatGlobalDate(date_gteq), formatGlobalDate(date_lteq)),
    getDisplayItem('Document formats', document_types),
    getDisplayItem('Locale type', locale_types),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    locales,
    states,
    population,
    date,
    documentFormats,
    localeTypes,
    tags,
  } = options || {};

  const { start: populationGTE, end: populationLTE } = population || {};
  const { start: dateGTE, end: dateLTE } = date || {};

  return {
    ...getValue('keyword', searchValue),
    ...getValue('cities', transformSelectValues(locales)),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('city_population_gteq', populationGTE),
    ...getValue('city_population_lteq', populationLTE),
    ...getValue('date_gteq', formatDate(dateGTE)),
    ...getValue('date_lteq', formatDate(dateLTE)),
    ...getValue('document_types', documentFormats),
    ...getValue('locale_types', localeTypes),
    ...getValue('tag_ids', transformSelectValues(tags)),
  };
}

export function transformSearchesForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    locales,
    states,
    population,
    date,
    documentFormats,
    localeTypes,
    tags,
  } = options || {};

  const { start: populationGTE, end: populationLTE } = population || {};
  const { start: dateGTE, end: dateLTE } = date || {};

  return {
    ...getValue('keyword', searchValue),
    ...getValue('cities', transformSelectValues(locales)),
    ...getValue('states', transformSelectValues(states)),
    ...getValue('city_population_gteq', populationGTE),
    ...getValue('city_population_lteq', populationLTE),
    ...getValue('date_gteq', formatDate(dateGTE)),
    ...getValue('date_lteq', formatDate(dateLTE)),
    ...getValue('document_types', documentFormats),
    ...getValue('locale_types', localeTypes),
    ...getValue('tag_ids', tags),
  };
}

export function transformSearchesForUI(data, localProps) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item, localProps),
    ...transformOptionsForUI(item),
  }));
}

export function transformDocumentsForUI(documents) {
  return map(documents, ({
    id,
    date,
    state,
    city,
    meeting_date,
    publication_date,
    publication_date_estimated,
    ...restProps
  }) => ({
    ...restProps,
    id,
    recordId: id,
    itemType: 'Local document',
    documentType: DOCUMENT_TYPES.TOWN_INTEL_DOCUMENT,
    publicationDate: formatGlobalDate(publication_date),
    meetingDate: formatGlobalDate(meeting_date),
    isEstimatedDate: publication_date_estimated,
    state: state.alpha2 || state.name,
    locale: city,
  }));
}

export function getExportInfo(data = {}) {
  const {
    locales,
    states,
    population,
    date,
    documentFormats,
    localeTypes,
    tags,
  } = data.options || {};

  const { start: populationGTE, end: populationLTE } = population || {};
  const { start: dateGTE, end: dateLTE } = date || {};

  return {
    'strategy_inputs[keyword]': data.searchValue,
    'strategy_inputs[cities]': transformSelectValues(locales),
    'strategy_inputs[states]': transformSelectValues(states),
    'strategy_inputs[city_population_gteq]': populationGTE,
    'strategy_inputs[city_population_lteq]': populationLTE,
    'strategy_inputs[date_gteq]': formatDate(dateGTE),
    'strategy_inputs[date_lteq]': formatDate(dateLTE),
    'strategy_inputs[document_types]': documentFormats,
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    'strategy_inputs[locale_types]': localeTypes,
  };
}
