export default function ({ action, state }) {
  const errors = {};
  const { responseJSON } = action.payload;

  Object.keys(responseJSON).forEach((name) => {
    const [errorMessage] = responseJSON[name];
    errors[name] = errorMessage;
  });

  return {
    ...state,
    errors,
    ui: {
      ...state.ui,
      loading: false,
    },
  };
}
