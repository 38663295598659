import { isEqual, toNumber, isEmpty } from 'lodash';

import { onSaveRecentItem, setLastSearchId } from 'redux/advancedSearch/actions';
import { loadAdvancedSearch } from 'redux/advancedSearch/utils';

import initialState from '../initialState';
import constants, { ADVANCED_SEARCH_SLUG } from '../constants';
import { transformFiltersForBE, transformFiltersFromAdvancedSearch } from '../transformers';
import { fetchCards } from './cards';

export const setPage = (page, options = {}) => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;
    const { avoidDispatchFetch } = options;

    const pagination = {
      ...state.pagination,
      currentPage: page,
    };

    dispatch({ type: constants.SET_PAGINATION, payload: pagination });
    !avoidDispatchFetch && dispatch(fetchCards());
  }
);

export const setPerPage = perPage => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;

    const pagination = {
      ...state.pagination,
      perPage,
    };

    dispatch({ type: constants.SET_PAGINATION, payload: pagination });
  }
);

export const setAdvancedSearchFilters = searchId => (
  (dispatch) => {
    const { filters: initialFilters } = initialState;

    dispatch(setLastSearchId(searchId));

    return loadAdvancedSearch(searchId).then(({ search_params: filters }) => (
      dispatch({
        type: constants.SET_FILTERS,
        payload: {
          ...initialFilters,
          ...transformFiltersFromAdvancedSearch(filters),
        },
      })
    ));
  }
);

export const setSearchId = () => (
  (dispatch, getState) => {
    const { filters } = getState().grm2.grm;
    const { filters: initialFilters } = initialState;

    if (isEqual(filters, initialFilters)) {
      return dispatch(setLastSearchId());
    }

    const advancedSearchData = {
      advanced_search: {
        strategy_slug: ADVANCED_SEARCH_SLUG,
        search_params: transformFiltersForBE(filters),
        saved: false,
      },
    };

    return dispatch(onSaveRecentItem(advancedSearchData));
  }
);

export const setAdvancedSearch = ({ page, searchId }) => (
  (dispatch) => {
    dispatch({ type: constants.SET_ADVANCED_SEARCH });

    const actions = [
      !isEmpty(searchId) && dispatch(setAdvancedSearchFilters(toNumber(searchId))),
      !isEmpty(page) && dispatch(setPage(toNumber(page), { avoidDispatchFetch: true })),
    ];

    return Promise.all(actions);
  }
);

export const setFilter = (name, value) => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;
    const { withAdvancedSearch } = state;

    const filters = {
      ...state.filters,
      [name]: value,
    };

    const pagination = {
      ...state.pagination,
      currentPage: 1,
    };

    dispatch({ type: constants.SET_FILTERS, payload: filters });
    dispatch({ type: constants.SET_PAGINATION, payload: pagination });
    dispatch(fetchCards());

    withAdvancedSearch && dispatch(setSearchId());
  }
);

export const setFilters = (newFilters, options = {}) => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;
    const { withAdvancedSearch } = state;
    const { avoidDispatchFetch } = options;

    const filters = {
      ...state.filters,
      ...newFilters,
    };

    const pagination = {
      ...state.pagination,
      currentPage: 1,
    };

    dispatch({ type: constants.SET_FILTERS, payload: filters });
    dispatch({ type: constants.SET_PAGINATION, payload: pagination });

    withAdvancedSearch && dispatch(setSearchId());

    if (!avoidDispatchFetch) return dispatch(fetchCards());

    return Promise.resolve();
  }
);

export const resetFilters = (defaultFilters = {}) => (
  (dispatch, getState) => {
    const state = getState().grm2.grm;
    const { withAdvancedSearch } = state;

    const { filters } = initialState;

    const payload = {
      ...filters,
      ...defaultFilters,
    };

    const pagination = {
      ...state.pagination,
      currentPage: 1,
    };

    dispatch({ type: constants.SET_FILTERS, payload });
    dispatch({ type: constants.SET_PAGINATION, payload: pagination });
    dispatch(fetchCards());

    withAdvancedSearch && dispatch(setSearchId());
  }
);
