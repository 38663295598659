import { loadingUpdateItem, updateDataItem, updateDataItemArrayPayload } from 'utils/reducer';
import { NONE_ID } from 'components/core/FrequencyOptions/constants';

import constants from './constants';

export const DEFAULT_STATE = {
  defaultSubscription: NONE_ID,
  allItems: [],
  paginator: {},
  sort: {},
  ui: {
    isLoading: true,
    isLoadingTags: true,
    isLoadingSubscriptions: true,
    isLoadingMyFeedDocuments: true,
  },
};

const billsKeyName = 'allItems';

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_BILLS:
      return {
        ...state,
        paginator: state.paginator,
        ui: { ...state.ui, isLoading: true },
      };
    case constants.RECEIVE_BILLS_DONE:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, isLoading: false },
      };
    case constants.SET_BILLS:
      return {
        ...state,
        ...action.payload,
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, billsKeyName),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, billsKeyName),
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, billsKeyName),
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, billsKeyName),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    default:
      return state;
  }
}
