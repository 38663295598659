const constants = {
  ADD_DOCUMENT: 'REPORT/ADD_DOCUMENT',
  DELETE_DOCUMENT: 'REPORT/DELETE_DOCUMENT',
  TOGGLE_ALL_SELECTED: 'REPORT/TOGGLE_ALL_SELECTED',
};

export function addDocument(payload) {
  return {
    type: constants.ADD_DOCUMENT,
    payload,
  };
}

export function deleteDocument(payload = {}) {
  return {
    type: constants.DELETE_DOCUMENT,
    payload,
  };
}

export function toggleAllSelected() {
  return { type: constants.TOGGLE_ALL_SELECTED };
}

const defaultState = {
  documents: [],
  allSelected: false,
};

function documents(stateDocuments = [], action) {
  switch (action.type) {
    case constants.ADD_DOCUMENT:
      return [
        ...stateDocuments,
        action.payload.document,
      ];
    case constants.DELETE_DOCUMENT:
      return stateDocuments.filter(
        document => document.id !== action.payload.id
      );
    default:
      return stateDocuments;
  }
}

export default function Report(state = defaultState, action) {
  switch (action.type) {
    case constants.ADD_DOCUMENT:
      return {
        ...state,
        documents: documents(state.documents, action),
      };
    case constants.DELETE_DOCUMENT:
      return {
        ...state,
        documents: documents(state.documents, action),
      };
    case constants.TOGGLE_ALL_SELECTED:
      return {
        ...state,
        allSelected: !state.allSelected,
      };
    default:
      return state;
  }
}
