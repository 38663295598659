import { transformReceiptContributions } from 'redux/moneyball/utils/transformers';
import { REQUEST_CONTENT_TYPE } from 'components/Moneyball/utils/constants';
import Notifications from 'components/Moneyball/utils/Notifications';
import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import {
  fetchSearch,
  fetchSearchReceipt,
  fetchSearchKeyDetails,
  onSelectElectionCycle,
  onSetElectionCycles,
} from 'redux/moneyball/utils';

import { transformIndividual, transformContributions, transformOptionsForBE } from './utils';
import constants from './constants';

export function setElectionCycles(cycles) {
  return onSetElectionCycles(cycles, constants.SET_ELECTION_CYCLES);
}

export function onSelectCycle(cycle) {
  return onSelectElectionCycle(cycle, constants.SELECT_ELECTION_CYCLE);
}

export function fetchIndividual(id, params) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUAL });

    function getDefaultParams() {
      return {
        url: `${gon.moneyballApiV2}/api/v1/super_individuals/${id}`,
        data: params,
        type: 'GET',
      };
    }

    function getCombinedParams() {
      const { cluster, ids } = params;

      return {
        url: `${gon.moneyballApiV2}/api/v1/clusterbuck/first_queue/clusters/${cluster}/preview`,
        data: JSON.stringify({ super_individual_ids: ids }),
        type: 'POST',
      };
    }

    const { url, type, data } = id !== 'combine' ? getDefaultParams() : getCombinedParams();

    return dispatch(
      fetchSearchKeyDetails({
        url,
        type,
        payload: data,
        contentType: REQUEST_CONTENT_TYPE,
        actionType: constants.RECEIVE_INDIVIDUAL_DONE,
        transformResponse: transformIndividual,
      }),
    );
  };
}

export function searchIndividualContributionsReceipt({ id }) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUAL_RECEIPT });

    return dispatch(
      fetchSearchReceipt({
        url: `${gon.moneyballApiV2}/api/v1/receipts/${id}`,
        transformResponse: transformReceiptContributions,
        actionType: constants.RECEIVE_INDIVIDUAL_RECEIPT_DONE,
      }),
    );
  };
}

export function searchIndividualContributions(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_INDIVIDUAL_CONTRIBUTIONS });

    const { id, ids, ...restPayload } = payload;
    const formattedPayload = {
      ...restPayload,
      contributor: {
        super_individuals: {
          ids: id === 'combine' ? ids : [id],
        },
      },
    };

    return dispatch(
      fetchSearch({
        payload: formattedPayload,
        url: `${gon.moneyballApiV2}/api/v1/receipts/lookup`,
        actionType: constants.RECEIVE_INDIVIDUAL_CONTRIBUTIONS_DONE,
        transformResponsePayload: transformContributions,
        transformSearchToQuery: transformOptionsForBE,
      }),
    );
  };
}

export function mergeData({ reviewer, cluster, ids, afterMergeAction }) {
  return (dispatch) => {
    dispatch({ type: constants.MERGE_DATA_REQUEST });

    const formattedPayload = JSON.stringify({
      reviewer_name: reviewer,
      super_individual_ids: ids,
    });

    const onSuccess = () => {
      dispatch({
        type: constants.MERGE_DATA_SUCCESS,
      });

      afterMergeAction && afterMergeAction();
    };

    const onFailure = (error) => {
      dispatch({
        type: constants.MERGE_DATA_SUCCESS,
      });

      Notifications.defaultSubmitDataFailed();
      throw error;
    };

    const ajax = $.ajax({
      data: formattedPayload,
      url: `${gon.moneyballApiV2}/api/v1/clusterbuck/first_queue/clusters/${cluster}/merge`,
      contentType: REQUEST_CONTENT_TYPE,
      type: 'POST',
    });

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function reset() {
  return dispatch => dispatch(resetAppliedFilters());
}
