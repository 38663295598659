import { compact } from 'lodash';

import {
  formatDates,
  formatAmounts,
  formatSelectValueForFE,
  formatSelectValueToArrForBE,
  formatSelectValueToObjForBE,
  getDisbursedRecipientInfo,
  getCommitteeTypeValue,
  sourceSelectValueForBE,
  getLabelForUI,
  getRecipientType,
  getValue,
} from 'redux/moneyball/utils/transformers';
import { getDateValue, getAmountValue } from 'redux/moneyball/utils';
import { getDisplayItem } from 'redux/advancedSearch/utils';
import { formatGlobalDate } from 'utils/date';

export function transformDisbursements(disbursements = []) {
  return disbursements.map((disbursementItem) => {
    const { disbursement, spender, recipient } = disbursementItem;
    const { id, is_pdf_available, date, spender_name, ...restDisbursementProps } = disbursement || {};
    const { id: spenderId, name: spenderName } = spender || {};
    const { state_code, ...restRecipientProps } = recipient || {};
    const recipientInfo = getDisbursedRecipientInfo(restRecipientProps);

    return ({
      ...restDisbursementProps,
      ...recipientInfo,
      state_code,
      id,
      name: spenderName || spender_name,
      url: spenderName && `/moneyball_v2/committees/${spenderId}`,
      date: formatGlobalDate(date),
      receipts: {
        id,
        is_pdf_available,
      },
    });
  });
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    amounts,
    dates,
    spender,
    recipientCommittee,
    recipientCommitteeTypes,
    recipientIndividuals,
    recipientCity,
    recipientStates,
    recipientStreet,
    recipientVendors,
    recipientVendorsCity,
    recipientVendorsStates,
    recipientVendorsZip,
    recipientZip,
  } = options || data;
  const { startDate, endDate } = dates || data;
  const { fromValue, toValue } = amounts || data;

  return {
    ...getValue('query', searchValue),
    ...getValue('disbursement_date', {
      ...getValue('start', startDate),
      ...getValue('end', endDate),
    }),
    ...getValue('disbursement_amount', {
      ...getValue('start', fromValue),
      ...getValue('end', toValue),
    }),
    ...getValue('spender', {
      ...getValue('ids', formatSelectValueToArrForBE(spender)),
    }),
    ...getValue('recipient', {
      ...getValue('super_committees', {
        ...getValue('ids', formatSelectValueToArrForBE(recipientCommittee)),
        ...getValue('types', recipientCommitteeTypes),
      }),
      ...getValue('super_vendors', {
        ...getValue('ids', formatSelectValueToArrForBE(recipientVendors)),
        ...getValue('cities', formatSelectValueToArrForBE(recipientVendorsCity)),
        ...getValue('state_codes', formatSelectValueToArrForBE(recipientVendorsStates)),
        ...getValue('zip_codes', formatSelectValueToArrForBE(recipientVendorsZip)),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToArrForBE(recipientIndividuals)),
        ...getValue('streets', formatSelectValueToArrForBE(recipientStreet)),
        ...getValue('cities', formatSelectValueToArrForBE(recipientCity)),
        ...getValue('state_codes', formatSelectValueToArrForBE(recipientStates)),
        ...getValue('zip_codes', formatSelectValueToArrForBE(recipientZip)),
      }),
    }),
  };
}

export function transformSearchesForFE(data = []) {
  return data.map((item) => {
    const { id, name, search_params } = item || {};
    const { query, spender, disbursement_date, disbursement_amount, recipient } = search_params || data;
    const { super_committees, super_vendors, super_individuals } = recipient || data;
    const { ids, types } = super_committees || data;
    const { ids: spenderIds } = spender || {};

    const {
      ids: vendorIds,
      cities: vendorCities,
      state_codes: vendorStates,
      zip_codes: vendorZip,
    } = super_vendors || data;

    const {
      names: individualNames,
      streets: individualStreets,
      cities: individualCities,
      state_codes: individualStates,
      zip_codes: individualZip,
    } = super_individuals || data;

    return {
      id,
      title: name || 'Applied filters:',
      filters: compact([
        getDisplayItem('Searched', query),
        getDisplayItem('Dates', getDateValue(disbursement_date)),
        getDisplayItem('Amounts', getAmountValue(disbursement_amount)),
        getDisplayItem('Spender(s)', spenderIds),
        getDisplayItem('Recipient Type', getRecipientType(recipient, true)),
        getDisplayItem('Committee name(s)', getLabelForUI(ids)),
        getDisplayItem('Committee type(s)', getCommitteeTypeValue(types)),
        getDisplayItem('Vendor Name(s)', vendorIds),
        getDisplayItem('Vendor Cities', vendorCities),
        getDisplayItem('Vendor State(s)', vendorStates),
        getDisplayItem('Vendor Zip code(s)', vendorZip),
        getDisplayItem('Individual Name(s)', individualNames),
        getDisplayItem('Individual Street name(s)', individualStreets),
        getDisplayItem('Individual Cities', individualCities),
        getDisplayItem('Individual State(s)', individualStates),
        getDisplayItem('Individual Zip code(s)', individualZip),
      ]),
      options: {
        ...getValue('searchValue', query),
        options: {
          ...getValue('dates', formatDates(disbursement_date)),
          ...getValue('amounts', formatAmounts(disbursement_amount)),
          ...getValue('spender', formatSelectValueForFE(spenderIds)),
          ...getValue('recipientType', getRecipientType(recipient)),
          ...getValue('recipientCommittee', ids),
          ...getValue('recipientCommitteeTypes', types),
          ...getValue('recipientIndividuals', formatSelectValueForFE(individualNames)),
          ...getValue('recipientStreet', formatSelectValueForFE(individualStreets)),
          ...getValue('recipientCity', formatSelectValueForFE(individualCities)),
          ...getValue('recipientStates', formatSelectValueForFE(individualStates)),
          ...getValue('recipientZip', formatSelectValueForFE(individualZip)),
          ...getValue('recipientVendors', formatSelectValueForFE(vendorIds)),
          ...getValue('recipientVendorsCity', formatSelectValueForFE(vendorCities)),
          ...getValue('recipientVendorsStates', formatSelectValueForFE(vendorStates)),
          ...getValue('recipientVendorsZip', formatSelectValueForFE(vendorZip)),
        },
      },
    };
  });
}

/**
 * Required for recent/saved searches
 * @param data
 * @returns {{}}
 */
export function transformSearchesForBE(data = {}) {
  const baseOptions = transformOptionsForBE(data);
  const { options } = data;
  const {
    spender,
    recipientCommittee,
    recipientCommitteeTypes,
    recipientIndividuals,
    recipientStreet,
    recipientCity,
    recipientStates,
    recipientVendors,
    recipientVendorsCity,
    recipientVendorsStates,
    recipientVendorsZip,
    recipientZip,
  } = options || data;

  return {
    ...baseOptions,
    ...getValue('spender', {
      ...getValue('ids', formatSelectValueToObjForBE(spender)),
    }),
    ...getValue('recipient', {
      ...getValue('super_committees', {
        ...getValue('ids', sourceSelectValueForBE(recipientCommittee)),
        ...getValue('types', recipientCommitteeTypes),
      }),
      ...getValue('super_vendors', {
        ...getValue('ids', formatSelectValueToObjForBE(recipientVendors)),
        ...getValue('cities', formatSelectValueToObjForBE(recipientVendorsCity)),
        ...getValue('state_codes', formatSelectValueToObjForBE(recipientVendorsStates)),
        ...getValue('zip_codes', formatSelectValueToObjForBE(recipientVendorsZip)),
      }),
      ...getValue('super_individuals', {
        ...getValue('names', formatSelectValueToObjForBE(recipientIndividuals)),
        ...getValue('streets', formatSelectValueToObjForBE(recipientStreet)),
        ...getValue('cities', formatSelectValueToObjForBE(recipientCity)),
        ...getValue('state_codes', formatSelectValueToObjForBE(recipientStates)),
        ...getValue('zip_codes', formatSelectValueToObjForBE(recipientZip)),
      }),
    }),
  };
}
