import { combineReducers } from 'redux';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';

import constants from './constants';

export const defaultState = {
  committees: [],
  committeesError: false,
  paginator: {},
  sort: {},
  ui: {
    isLoadingData: true,
    isInitialLoading: true,
    isLoadingReport: false,
    toResetSelectedItems: true,
  },
};

function lookUp(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_COMMITTEES:
      return {
        ...state,
        committeesError: false,
        sort: {},
        ui: {
          ...state.ui,
          isLoadingData: true,
          toResetSelectedItems: false,
        },
      };
    case constants.RECEIVE_COMMITTEES_DONE:
      return {
        ...state,
        committees: action.payload,
        committeesError: action.error,
        paginator: action.paginator,
        sort: action.sort,
        ui: {
          ...state.ui,
          isInitialLoading: false,
          isLoadingData: false,
          toResetSelectedItems: action.toResetSelectedItems,
        },
      };
    case constants.SUBMIT_EXPORT_REPORT_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: true,
        },
      };
    case constants.SUBMIT_EXPORT_REPORT_DONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingReport: false,
        },
      };
    default:
      return state;
  }
}


export default combineReducers({
  appliedSearchFilters,
  advancedSearch,
  lookUp,
});
