const PREFIX = 'DISMISSIBLE_ALERT';

const constants = {
  FETCH_ALERT: `${PREFIX}/FETCH_ALERT`,
  FETCH_ALERT_DONE: `${PREFIX}/FETCH_ALERT_DONE`,
  FETCH_ALERT_FAILURE: `${PREFIX}/FETCH_ALERT_FAILURE`,
  DISMISS_ALERT: `${PREFIX}/DISMISS_ALERT`,
  DISMISS_ALERT_DONE: `${PREFIX}/DISMISS_ALERT_DONE`,
  DISMISS_ALERT_FAILURE: `${PREFIX}/DISMISS_ALERT_FAILURE`,
  RESET_ALERT: `${PREFIX}/RESET_ALERT`,
};

export default constants;
