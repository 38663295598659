import includes from 'lodash/includes';

import { responseEventTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { formatString } from 'components/Dashboard/common/FeedPage/utils';
import { getSource } from 'redux/dashboardV2/feedPage/utils';
import { transformPost } from 'redux/socialMedia/feed/utils/transformers';
import {
  AGENCY_PRESS_RELEASES_ITEM_TYPE,
  FEDERAL_CONTRACT_ITEM_TYPE,
  FEDERAL_GRANT_ITEM_TYPE,
  COMMITTEE_REPORTS_ITEM_TYPE,
  CONGRESSIONAL_PRESS_RELEASES_ITEM_TYPE,
  FEDERAL_BILL_ITEM_TYPE,
  DOCTROVE_HEARING_TRANSCRIPTS_ITEM_TYPE,
  OFFICIAL_HEARING_TRANSCRIPTS_ITEM_TYPE,
  STATE_BILL_ITEM_TYPE,
  STATE_REGULATION_ITEM_TYPE,
  SOCIAL_MEDIA_POST_ITEM_TYPE,
} from 'utils/constants/itemTypes';

import {
  getTitle,
  getTitleUrl,
  getSocialMediaTitle,
  getStateRegulationUrl,
} from '../utils';
import { TWEET_NODE } from './descriptionNodeTypes';

const KEYWORD_ACTION_TYPE = 'Keyword match';

function transformKeyword({
  isNotification = false,
  createdAt,
  title,
  event_data,
  subscription,
  descriptionNode,
  feed,
}, itemType) {
  const { keyword, token: subscriptionToken, title: subscriptionTitle } = subscription || {};
  const {
    url: titleUrl,
    title: eventTitle,
    highlighted_text,
    recipient_name,
    committee_name,
    agency_name,
    document_type: documentType,
  } = event_data || {};
  const { token: feedToken } = feed || {};

  function getActionType() {
    if (includes([
      COMMITTEE_REPORTS_ITEM_TYPE,
      OFFICIAL_HEARING_TRANSCRIPTS_ITEM_TYPE,
      DOCTROVE_HEARING_TRANSCRIPTS_ITEM_TYPE,
    ], itemType)) {
      return committee_name;
    }

    if (includes([
      AGENCY_PRESS_RELEASES_ITEM_TYPE,
      CONGRESSIONAL_PRESS_RELEASES_ITEM_TYPE,
    ], itemType)) {
      return recipient_name;
    }

    if (includes([FEDERAL_CONTRACT_ITEM_TYPE, FEDERAL_GRANT_ITEM_TYPE], itemType)) {
      return agency_name;
    }

    return null;
  }

  const displayTitle = eventTitle || title;

  if (isNotification) {
    return {
      title: KEYWORD_ACTION_TYPE,
      description: displayTitle,
      itemType,
      itemTitle: subscriptionTitle,
      url: titleUrl,
      subscriptionToken,
      isKeywordMatch: true,
      feedToken,
    };
  }

  return {
    actionType: getActionType(),
    itemType,
    title: displayTitle,
    titleUrl,
    createdAt,
    descriptionNode,
    description: highlighted_text,
    source: [
      { label: 'Keyword search', content: formatString(subscriptionTitle || keyword) },
      ...getSource({ documentType, title: displayTitle, titleUrl }),
    ],
  };
}

function transformFederalBill({
  isNotification = false,
  createdAt,
  event_data: {
    id,
    display_id,
    bill_display_title,
    highlighted_text,
    document_type: documentType,
  } = {},
  subscription: {
    keyword,
    token: subscriptionToken,
    title: subscriptionTitle,
  } = {},
  feed = {},
}) {
  const itemType = FEDERAL_BILL_ITEM_TYPE;
  const title = getTitle(display_id, bill_display_title, false, ' - ');
  const titleUrl = getTitleUrl(id, '/federal/bills');

  if (isNotification) {
    return {
      title: KEYWORD_ACTION_TYPE,
      description: display_id,
      itemType,
      itemTitle: subscriptionTitle,
      url: titleUrl,
      subscriptionToken,
      isKeywordMatch: true,
      feedToken: feed.token,
    };
  }

  return {
    itemType,
    title,
    titleUrl,
    createdAt,
    description: highlighted_text,
    source: [
      { label: 'Keyword search', content: formatString(subscriptionTitle || keyword) },
      ...getSource({ documentType, title, titleUrl }),
    ],
  };
}

function transformGovernorOrder({ event_data, ...restProps }) {
  const { state_alpha2: stateAlpha2 } = event_data || {};
  const title = getTitle(stateAlpha2, 'Governor\'s order');

  return transformKeyword({ ...restProps, event_data, title }, title);
}

function transformGovernorPressRelease({ event_data, ...restProps }) {
  const { state_alpha2: stateAlpha2 } = event_data || {};
  const title = getTitle(stateAlpha2, 'Governor press release');

  return transformKeyword({ ...restProps, event_data, title }, title);
}

function transformSocialMedia({ event_data, ...restProps }) {
  return transformKeyword(
    {
      ...restProps,
      event_data,
      title: getSocialMediaTitle(event_data),
      descriptionNode: {
        type: TWEET_NODE,
        data: transformPost(event_data),
      },
    },
    SOCIAL_MEDIA_POST_ITEM_TYPE,
  );
}

function transformStateBill({
  isNotification = false,
  createdAt,
  event_data: {
    id,
    display_id,
    bill_title,
    state_alpha2,
    highlighted_text,
    document_type: documentType,
  } = {},
  subscription: {
    keyword,
    token: subscriptionToken,
    title: subscriptionTitle,
  } = {},
  feed,
}) {
  const itemType = getTitle(state_alpha2, STATE_BILL_ITEM_TYPE);
  const title = getTitle(display_id, bill_title, false, ' - ');
  const titleUrl = getTitleUrl(id, '/state/bills');

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: KEYWORD_ACTION_TYPE,
      description: title,
      itemType,
      itemTitle: subscriptionTitle,
      url: titleUrl,
      subscriptionToken,
      isKeywordMatch: true,
      feedToken,
    };
  }

  return {
    itemType,
    title,
    titleUrl,
    createdAt,
    description: highlighted_text,
    source: [
      { label: 'Keyword search', content: formatString(subscriptionTitle || keyword) },
      ...getSource({ documentType, title, titleUrl }),
    ],
  };
}

function transformStateRegulation({
  isNotification = false,
  createdAt,
  event_data,
  subscription: {
    keyword,
    token: subscriptionToken,
    title: subscriptionTitle,
  } = {},
  feed,
}) {
  const { title, state_alpha2, highlighted_text, document_type: documentType } = event_data || {};
  const itemType = getTitle(state_alpha2, STATE_REGULATION_ITEM_TYPE);
  const titleUrl = getStateRegulationUrl(event_data);

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: KEYWORD_ACTION_TYPE,
      description: title,
      itemType,
      itemTitle: subscriptionTitle,
      url: titleUrl,
      subscriptionToken,
      isKeywordMatch: true,
      feedToken,
    };
  }

  return {
    itemType,
    title,
    titleUrl,
    createdAt,
    description: highlighted_text,
    source: [
      { label: 'Keyword search', content: formatString(subscriptionTitle || keyword) },
      ...getSource({ documentType, title, titleUrl }),
    ],
  };
}

function transformTownIntel({ event_data, createdAt, subscription, feed, isNotification = false }) {
  const { keyword, token: subscriptionToken, title: subscriptionTitle } = subscription || {};
  const { url, title, city, state_alpha2, highlighted_text } = event_data || {};
  const cityStateTitle = getTitle(city, state_alpha2, false, ', ');
  const itemType = getTitle(cityStateTitle, 'Publication');

  const { token: feedToken } = feed || {};

  if (isNotification) {
    return {
      title: KEYWORD_ACTION_TYPE,
      description: title,
      itemType,
      itemTitle: subscriptionTitle,
      url,
      subscriptionToken,
      isKeywordMatch: true,
      feedToken,
    };
  }

  return {
    titleUrl: url,
    itemType,
    title,
    createdAt,
    description: highlighted_text,
    source: [{ label: 'Keyword search', content: formatString(subscriptionTitle || keyword) }],
  };
}

export const KEYWORD_RECENT_TYPE = {
  [responseEventTypes.KEYWORD_FEDERAL_BILL]: transformFederalBill,
  [responseEventTypes.KEYWORD_DOCTROVE_GOVERNOR_ORDER]: transformGovernorOrder,
  [responseEventTypes.KEYWORD_DOCTROVE_GOVERNOR_PRESS_RELEASE]: transformGovernorPressRelease,
  [responseEventTypes.KEYWORD_SOCIAL_MEDIA_POST]: transformSocialMedia,
  [responseEventTypes.KEYWORD_STATE_BILL]: transformStateBill,
  [responseEventTypes.KEYWORD_STATE_REGULATION]: transformStateRegulation,
  [responseEventTypes.KEYWORD_TOWN_INTEL_DOCUMENT]: transformTownIntel,
  // Common
  [responseEventTypes.KEYWORD_CONGRESSIONAL_RECORD]: props => transformKeyword(props, 'Congressional record item'),
  [responseEventTypes.KEYWORD_DOCTROVE_AGENCY_PRESS_RELEASE]: props => transformKeyword(props, 'Agency press release'),
  [responseEventTypes.KEYWORD_DOCTROVE_CBO]: props => transformKeyword(props, 'CBO report'),
  [responseEventTypes.KEYWORD_DOCTROVE_COMMITTEE_REPORT]: props => transformKeyword(props, 'Committee report'),
  [responseEventTypes.KEYWORD_DOCTROVE_CRS]: props => transformKeyword(props, 'CRS report'),
  [responseEventTypes.KEYWORD_DOCTROVE_DEAR_COLLEAGUE_LETTER]: props => transformKeyword(props, 'Dear colleague letter'),
  [responseEventTypes.KEYWORD_DOCTROVE_EXECUTIVE_ORDER]: props => transformKeyword(props, 'Executive order'),
  [responseEventTypes.KEYWORD_DOCTROVE_FBO]: props => transformKeyword(props, 'Federal contract'),
  [responseEventTypes.KEYWORD_DOCTROVE_GAO]: props => transformKeyword(props, 'GAO report'),
  [responseEventTypes.KEYWORD_DOCTROVE_GRANT]: props => transformKeyword(props, 'Federal grant document'),
  [responseEventTypes.KEYWORD_DOCTROVE_HEARING_TRANSCRIPT]: props => transformKeyword(props, 'Official hearing transcript'),
  [responseEventTypes.KEYWORD_DOCTROVE_LIVE_HEARING_TRANSCRIPT]: props => transformKeyword(props, 'Live hearing transcript'),
  [responseEventTypes.KEYWORD_DOCTROVE_PRESS_BRIEFING]: props => transformKeyword(props, 'White House press briefing'),
  [responseEventTypes.KEYWORD_DOCTROVE_PRESS_RELEASE]: props => transformKeyword(props, 'Congressional press release'),
  [responseEventTypes.KEYWORD_DOCTROVE_SAP]: props => transformKeyword(props, 'SAP Report'),
  [responseEventTypes.KEYWORD_DOCTROVE_THIRD_PARTY_REPORT]: props => transformKeyword(props, 'Third party report'),
  [responseEventTypes.KEYWORD_DOCTROVE_TREATY]: props => transformKeyword(props, 'Treaty document'),
  [responseEventTypes.KEYWORD_DOCTROVE_UNION_STATEMENT]: props => transformKeyword(props, 'State of the Union address'),
  [responseEventTypes.KEYWORD_FEDERAL_REGULATION]: props => transformKeyword(props, 'Federal regulation'),
};
