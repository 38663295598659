import { queryStringify } from 'utils/query-string';
import { GOV_REPORTS_URL } from 'utils/reports/govReports';

import constants from './constants';

export function getExportPayloadData({
  url,
  data,
  type = 'POST',
  toggleExportModal = null,
  exportNotifications = null,
  ...restProps
}) {
  const finalUrl = url || GOV_REPORTS_URL;

  return {
    ...restProps,
    url: finalUrl,
    data,
    type,
    toggleExportModal,
    exportNotifications,
  };
}

export function submitExportReport(payload = {}, ajaxProps = {}) {
  return (dispatch) => {
    dispatch({ type: constants.SUBMIT_EXPORT_REPORT_LOADING, payload });
    const {
      url,
      data,
      type,
      contentType,
      toggleExportModal,
      exportNotifications,
      onSuccessCustom,
    } = getExportPayloadData(payload);

    const ajax = $.ajax({
      url,
      data,
      type,
      contentType,
      ...ajaxProps,
    });

    function onSuccess(response) {
      onSuccessCustom
        ? onSuccessCustom(response)
        : window.open(`${url}?${queryStringify(data)}`, '_blank');

      exportNotifications && exportNotifications.getSuccess();
      toggleExportModal && toggleExportModal();
    }

    function onFailure(error) {
      exportNotifications && exportNotifications.getFailed();
      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => dispatch({ type: constants.SUBMIT_EXPORT_REPORT_DONE }));
  };
}
