import { map, partial } from 'lodash';

import { responseTrackingTypes } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getCommitteeAction } from 'redux/dashboardV2/feedPage/actions/trackCommittee';
import { transformFetchRecordIdsForBE } from 'redux/tags/utils';
import {
  fetchLookupCommitteeSubscriptions,
  updateCommitteeSubscriptions,
  bulkShareLookupToMyFeed,
  bulkTrackLookupItems,
} from 'redux/subscriptions/actions';
import { getMyFeedToken } from 'components/Dashboard/utils';

import constants from '../constants';

export function fetchSubscriptions({ payload: payloadData }, defaultDocumentType) {
  return (dispatch) => {
    const data = transformFetchRecordIdsForBE([
      ...map(payloadData, ({ id }) => ({
        documentType: responseTrackingTypes.STATE_COMMITTEE_HEARING,
        id,
      })),
      ...map(payloadData, ({ id }) => ({
        documentType: responseTrackingTypes.STATE_COMMITTEE_STAFFER,
        id,
      })),
    ]);

    return dispatch(fetchLookupCommitteeSubscriptions({
      data,
      defaultDocumentType,
      actionTypes: constants,
      feedToken: getMyFeedToken(),
    }));
  };
}

export function updateSubscriptions(payload) {
  return dispatch => dispatch(
    updateCommitteeSubscriptions({
      action: partial(getCommitteeAction, partial.placeholder, false),
      actionTypes: constants,
      payload,
    })
  );
}

export function bulkTrackItems(payload) {
  return dispatch => dispatch(
    bulkTrackLookupItems(
      payload,
      constants,
      fetchSubscriptions,
    )
  );
}

export function bulkShareToMyFeed(payload) {
  return dispatch => dispatch(bulkShareLookupToMyFeed(payload, constants));
}
