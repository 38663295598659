import { map, keys, groupBy } from 'lodash';

const SAST_LABELS = {
  same_as: 'Same as',
  crossfiled: 'Cross-filed with',
  enabling_for: 'Enabling for ',
  enabled_by: 'Enabled by',
  carry_over: 'Carried over from',
};

function getLabel(sastType) {
  return SAST_LABELS[sastType] || sastType;
}

function transformSastBillName(name) {
  if (name.slice(-1) === '.') {
    return name.slice(0, -1);
  }
  return name;
}

function transfromSastBill(bill) {
  return {
    value: transformSastBillName(bill.sast_bill_name),
    href: `/state/bills/${bill.sast_bill_id}`,
  };
}

export function transformSasts(bills) {
  const billsBySastType = groupBy(bills, 'sast_type');

  return map(keys(billsBySastType), sastType => ({
    id: sastType,
    label: getLabel(sastType),
    bills: map(billsBySastType[sastType], transfromSastBill),
  }));
}
