import { isEmpty, isUndefined, values } from 'lodash';

import {
  fetchLinksPreview,
  getCardsUrlToPreview,
  getUpdatedLinksPreview,
  removeCardsLinkPreview,
  transformPreviewDataToFE,
} from 'components/core/LinkPreview';

import constants from '../constants';

export const updateCardsLinkPreview = (updatedCards, options = {}) => (
  (dispatch, getState) => {
    const { cardsLinkPreview } = getState().grm2.grm;
    const { timeOut } = options;

    const cardsLink = getCardsUrlToPreview(updatedCards);

    if (isEmpty(cardsLink)) {
      const updatedCardsLinkPreview = removeCardsLinkPreview({ cardsLinkPreview, updatedCards });

      dispatch({ type: constants.SET_CARDS_LINK_PREVIEW, payload: updatedCardsLinkPreview });

      return;
    }

    const urls = values(cardsLink);
    const repeatFetch = !isUndefined(timeOut);

    const handleFetchLinksPreview = () => {
      fetchLinksPreview(urls).then(({ data }) => {
        const linksPreview = transformPreviewDataToFE(data);

        const updatedCardsLinkPreview = getUpdatedLinksPreview(cardsLinkPreview, { cardsLink, linksPreview, repeatFetch });

        dispatch({ type: constants.SET_CARDS_LINK_PREVIEW, payload: updatedCardsLinkPreview });
      }).finally(() => {
        !repeatFetch && dispatch({ type: constants.STOP_LOADING_LINKS_PREVIEW });
      });
    };

    dispatch({ type: constants.START_LOADING_LINKS_PREVIEW });
    handleFetchLinksPreview();

    repeatFetch && setTimeout(() => {
      handleFetchLinksPreview();
      dispatch({ type: constants.STOP_LOADING_LINKS_PREVIEW });
    }, timeOut);
  }
);
