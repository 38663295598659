const PREFIX = 'REGULATIONS';

export const API_URL = '/api_web/state/regulations/lookup';
export const STRATEGY_SLUG = 'state_legislation_regulation';
export const STRATEGY_SLUG_KEYWORD = 'state_regulation_document';
export const STRATEGY_SLUG_FOR_EXPORT = 'state_regulations';
export const STRATEGY_SLUG_BULK_EXPORT = 'state_legislation_regulations';
export const STRATEGY_SLUG_ONE_PAGE_REPORT = 'state_legislation_onepage_report_regulation';

const constants = {
  FETCH_REGULATIONS: `${PREFIX}/FETCH_REGULATIONS`,
  RECEIVE_REGULATIONS: `${PREFIX}/RECEIVE_REGULATIONS`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  RESET_REGULATIONS: `${PREFIX}/RESET_REGULATIONS`,
  SET_REGULATIONS: `${PREFIX}/SET_REGULATIONS`,
  BULK_UPDATE_DATA: `${PREFIX}/BULK_UPDATE_DATA`,
  BULK_TRACK_DATA: `${PREFIX}/BULK_TRACK_DATA`,
  BULK_TRACK_DATA_DONE: `${PREFIX}/BULK_TRACK_DATA_DONE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
  BULK_SHARE_TO_MY_FEED: `${PREFIX}/BULK_SHARE_TO_MY_FEED`,
  BULK_SHARE_TO_MY_FEED_DONE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_DONE`,
  BULK_SHARE_TO_MY_FEED_FAILURE: `${PREFIX}/BULK_SHARE_TO_MY_FEED_FAILURE`,
  LOAD_POSITIONS: `${PREFIX}/LOAD_POSITIONS`,
};

export default constants;
