import { fetchProfileTags } from 'redux/tags/actions';
import { fetchDefaultSubscription, fetchProfileSubscriptions } from 'redux/subscriptions/actions';
import { fetchProfileDocument, updateProfileShare } from 'redux/events/actions';

import { transformProfileForUI } from '../utils';
import constants from '../constants';

export function fetchTags(payload = {}) {
  return dispatch => dispatch(fetchProfileTags(payload, constants));
}

export function fetchSubscriptions(payload) {
  return (dispatch) => {
    dispatch(fetchDefaultSubscription(constants));
    dispatch(fetchProfileSubscriptions({ payload }, constants));
  };
}

export function onUpdateTags({ tags }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_TAGS_DONE,
    payload: tags,
  });
}

export function onUpdateTrackings({ trackings }) {
  return dispatch => dispatch({
    type: constants.RECEIVE_SUBSCRIPTIONS_DONE,
    payload: trackings,
  });
}

export function fetchRegulationProfile(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_REGULATION });
    const { id } = payload;
    const url = `/api_web/state/regulations/${id}`;
    const ajax = $.get(url);

    const onSuccess = response => dispatch({
      type: constants.RECEIVE_REGULATION_DONE,
      payload: transformProfileForUI(response),
    });

    const onFailure = (error) => {
      throw error;
    };

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(() => dispatch(fetchTags(payload)))
      .then(() => dispatch(fetchSubscriptions(payload)))
      .then(() => dispatch(fetchProfileDocument(payload, constants)))
      .catch(onFailure);
  };
}

export function resetRegulationData() {
  return dispatch => dispatch({ type: constants.RESET_REGULATION_DATA });
}

export function updateProfileMyFeed() {
  return (dispatch) => {
    dispatch(updateProfileShare(constants));
  };
}
