import isEmpty from 'lodash/isEmpty';

import { setAppliedFilters, updateAppliedFilters } from './actions';

export function handleAppliedFilters(searchData = {}) {
  return (dispatch) => {
    if (isEmpty(searchData)) return;

    dispatch(setAppliedFilters(searchData));
  };
}

export function handleUpdateAppliedFilters(searchData = {}) {
  return (dispatch) => {
    if (isEmpty(searchData)) return;

    dispatch(updateAppliedFilters(searchData));
  };
}

export function hasAppliedFilters(appliedFilters) {
  return !isEmpty(appliedFilters) &&
    (!isEmpty(appliedFilters.options) || !isEmpty(appliedFilters.searchValue));
}
