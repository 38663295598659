import { NONE_ID } from 'components/core/FrequencyOptions/constants';

import constants from './constants';

const defaultState = {
  defaultSubscription: NONE_ID,
  ui: {
    isLoadingDefaultSubscription: true,
    isUpdatingDefaultSubscription: false,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_DEFAULT_SUBSCRIPTION:
      return {
        ...state,
        ui: { ...state.ui, isLoadingDefaultSubscription: true },
      };
    case constants.LOAD_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
        ui: { ...state.ui, isLoadingDefaultSubscription: false },
      };
    case constants.LOAD_DEFAULT_SUBSCRIPTION_FAILED:
      return {
        ...state,
        ui: { ...state.ui, isLoadingDefaultSubscription: false },
      };
    case constants.UPDATE_DEFAULT_SUBSCRIPTION:
      return {
        ...state,
        ui: { ...state.ui, isUpdatingDefaultSubscription: true },
      };
    case constants.UPDATE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
        ui: { ...state.ui, isUpdatingDefaultSubscription: false },
      };
    case constants.UPDATE_DEFAULT_SUBSCRIPTION_FAILED:
      return {
        ...state,
        ui: { ...state.ui, isUpdatingDefaultSubscription: false },
      };
    default:
      return state;
  }
}
