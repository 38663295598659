import { resetAppliedFilters } from 'redux/appliedSearchFilters/actions';
import { handleAppliedFilters } from 'redux/appliedSearchFilters/utils';
import { transformPaginator, transformSort } from 'utils/transformers/paginator';
import { fetchMyFeedDocuments } from 'redux/events/actions';

import constants from '../../constants';
import { fetchSubscriptions } from '../subscriptions';
import { transformCommitteesForUI } from '../utils';
import { fetchTags } from '../tags';
import getCommitteesData from './getCommitteesData';

const fetchCommittees = (payload = {}) => (dispatch) => {
  dispatch({ type: constants.FETCH_COMMITTEES });

  const { searchData = {}, clearAll, documentType } = payload;
  const { isSearch, url, data, sortData } = getCommitteesData(payload);

  const ajax = $.post(url, data);

  const handleSuccess = (response) => {
    const currentPayload = response && response.data || [];

    return dispatch({
      type: constants.RECEIVE_COMMITTEES,
      payload: transformCommitteesForUI(currentPayload),
      paginator: transformPaginator(response),
      toResetSelectedItems: isSearch,
      sort: transformSort(sortData),
    });
  };

  const saveAppliedFiltersToProps = () => {
    if (clearAll) {
      return dispatch(resetAppliedFilters());
    }

    return dispatch(handleAppliedFilters(searchData));
  };

  function onFailure(error) {
    throw error;
  }

  return Promise.resolve(ajax)
    .then(handleSuccess)
    .then(payloadData => dispatch(fetchTags(payloadData, documentType)))
    .then(payloadData => dispatch(fetchMyFeedDocuments(payloadData, constants)))
    .then(payloadData => dispatch(fetchSubscriptions(payloadData, documentType)))
    .then(saveAppliedFiltersToProps)
    .catch(onFailure);
};

export default fetchCommittees;
