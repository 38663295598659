import { isEmpty, isNil, omitBy } from 'lodash';

import { transformOptionsForBE } from 'redux/state/regulations/lookup/actions/utils';
import { DEFAULT_SORT, PER_PAGE } from 'components/State/Regulations/Lookup/utils/constants';
import { API_URL } from 'redux/state/regulations/lookup/constants';

function getRegulationsData(payload) {
  const { searchData = {}, appliedFilters, clearAll, sort, direction, page, perPage } = payload;

  const isSearch = !(isEmpty(searchData) && !clearAll);
  const searchQuery = transformOptionsForBE(isSearch ? searchData : appliedFilters);
  const searchForm = !isEmpty(searchQuery) && { search_form: searchQuery };

  const sortData = omitBy({ sort, direction }, isNil);
  const currentSort = isEmpty(sortData) ? DEFAULT_SORT : sortData;
  const data = { ...searchForm, ...currentSort, page, per_page: perPage || PER_PAGE };

  return {
    url: API_URL,
    data,
    sortData: currentSort,
    isSearch,
  };
}

export default getRegulationsData;
