import { filter, map, isEmpty } from 'lodash';

import positionsApi from 'redux/dashboardV2/common/positions/api';

import { transformPositionsResponseForUI } from './utils/transformers';

export function fetchLookupPositions(
  { payload: payloadData } = {},
  defaultDocumentType,
  actionTypes = {},
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.LOAD_POSITIONS, isLoadingPositions: true });

    const filterByDocumentType = ({ documentType }) => documentType === defaultDocumentType;
    const filteredPayload = filter(payloadData, filterByDocumentType);
    const recordIds = map(filteredPayload, 'id');

    const formattedPayload = [{
      record_type: defaultDocumentType,
      record_ids: recordIds,
    }];

    const onSuccess = (response) => {
      dispatch({
        type: actionTypes.LOAD_POSITIONS,
        isLoadingPositions: false,
        payload: transformPositionsResponseForUI(response),
      });

      return { payload: payloadData };
    };

    const onFailure = (error) => {
      dispatch({
        type: actionTypes.LOAD_POSITIONS,
        isLoadingPositions: false,
        payload: [],
      });

      throw error;
    };

    if (isEmpty(recordIds)) {
      return onSuccess({ data: [] });
    }

    return positionsApi.fetchAllPositions({ search_form: formattedPayload })
      .then(onSuccess)
      .catch(onFailure);
  };
}
