import { chunk, isEmpty } from 'lodash';

import {
  DEFAULT_SORT_KEY,
} from 'components/DashboardTrackingCenter/components/TheadMapping/utils';
import SORT_DIRECTIONS from 'utils/constants/sortDirections';

const constants = {
  FETCH_EVENTS: 'DASHBOARD_TRACKING_CENTER/FETCH_EVENTS',
  RECEIVE_EVENTS: 'DASHBOARD_TRACKING_CENTER/RECEIVE_EVENTS',
  FETCH_REPORT_ITEMS: 'DASHBOARD_TRACKING_CENTER/FETCH_REPORT_ITEMS',
  RECEIVE_REPORT_ITEMS: 'DASHBOARD_TRACKING_CENTER/RECEIVE_REPORT_ITEMS',
};

function getDefaultSortData(sortData, subscribableSlug) {
  if (subscribableSlug === 'federal_register_regulation') {
    return {};
  }

  if (isEmpty(sortData)) {
    return {
      sort: DEFAULT_SORT_KEY,
      direction: SORT_DIRECTIONS.DESC,
    };
  }

  return sortData;
}

export function fetchEvents(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_EVENTS, payload });

    // uses tracking_center_api_web_royal_events_events_path
    const url = '/api_web/royal_events/events/tracking_center';
    const method = 'GET';
    const { page = 1, subscribableSlug, sortData = {}, ...searchForm } = payload || {};
    const { sort, direction } = getDefaultSortData(sortData, subscribableSlug);

    const data = {
      ...searchForm,
      sort_column: sort,
      sort_direction: direction,
      subscribable_slug: subscribableSlug,
      per_page: 50,
      page,
    };

    const ajax = $.ajax({
      method,
      url,
      data,
    });

    function onSuccess(result) {
      dispatch({
        type: constants.RECEIVE_EVENTS,
        payload: { ...result, type: subscribableSlug },
        sortData: { sort, direction },
      });
    }

    return Promise.resolve(ajax)
      .then(onSuccess);
  };
}

export function fetchReportItems(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_REPORT_ITEMS, payload });

    const { url } = payload;
    const method = 'GET';

    const idsChunks = chunk(payload.ids, 100);

    idsChunks.forEach((ids) => {
      const data = { ids };

      const ajax = $.ajax({
        method,
        url,
        data,
      });

      function onSuccess(result) {
        dispatch({ type: constants.RECEIVE_REPORT_ITEMS, payload: result });
      }

      return Promise.resolve(ajax)
        .then(onSuccess);
    });
  };
}

const defaultState = {
  events: [],
  reportItems: [],
  reportItemIds: [],
  searchForm: {},
  sortData: { sort: '', direction: '' },
  pagination: {
    total_pages: 0,
  },
  ui: {
    loadingEvents: false,
  },
};

function getAggregationValue(aggregation) {
  const { bill_ids: bills, regulation_ids: regulations } = aggregation || {};

  if (isEmpty(bills) && isEmpty(regulations)) {
    return [];
  }

  if (isEmpty(regulations)) {
    return bills;
  }

  return regulations;
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_EVENTS:
      return {
        ...state,
        events: [],
        reportItemIds: [],
        searchForm: action.payload,
        sortData: action.sortData,
        ui: { ...state.ui, loadingEvents: true },
      };
    case constants.RECEIVE_EVENTS:
      return {
        ...state,
        events: action.payload.data,
        type: action.payload.type,
        reportItemIds: getAggregationValue(action.payload.aggregation),
        pagination: action.payload.pagination,
        sortData: action.sortData,
        ui: { ...state.ui, loadingEvents: false },
      };
    case constants.FETCH_REPORT_ITEMS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingReportItems: true },
        reportItems: [],
      };
    case constants.RECEIVE_REPORT_ITEMS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingReportItems: false },
        reportItems: [
          ...state.reportItems,
          ...action.payload.data,
        ],
      };
    default:
      return state;
  }
}
