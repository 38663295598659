import constants from 'redux/dashboardV2/feedPage/constants';
import * as subscriptionsApi from 'redux/dashboardV2/common/subscriptions/api';
import * as feedsApi from 'redux/dashboardV2/feedPage/api';

export function bulkRemoveItems(payload) {
  return (dispatch) => {
    dispatch({ type: constants.BULK_REMOVE_EVENTS });

    const { isRecentTab, selectedIds, feedToken } = payload || {};

    const apiAjax = isRecentTab
      ? feedsApi.bulkRemoveEvents(feedToken, selectedIds)
      : subscriptionsApi.deleteSubscriptions({ feedToken, tokens: selectedIds });

    return apiAjax
      .then(() => dispatch({ type: constants.BULK_REMOVE_EVENTS_DONE, tokens: selectedIds }))
      .catch((error) => {
        dispatch({ type: constants.BULK_REMOVE_EVENTS_DONE });
        throw error;
      });
  };
}

export function bulkUpdateItems(payload) {
  return (dispatch) => {
    const {
      isLoadingPriority,
      isLoadingRead,
      isLoadingUnread,
      isLoadingSaving,
      selectedIds,
      priority,
      isRead,
      isSaved,
      isRecentTab,
      feedToken,
    } = payload || {};

    dispatch({
      type: constants.BULK_UPDATE_EVENTS,
      isLoadingPriority,
      isLoadingSaving,
      isLoadingRead,
      isLoadingUnread,
    });

    const data = {
      feedToken,
      tokens: selectedIds,
      isSaved,
      isRead,
      priority,
    };

    const apiAjax = isRecentTab
      ? feedsApi.bulkUpdateEvents
      : subscriptionsApi.updateSubscriptions;

    return apiAjax(data)
      .then(() => dispatch({
        type: constants.BULK_UPDATE_EVENTS_DONE,
        tokens: selectedIds,
        isSaved,
        isRead,
        priority,
      }))
      .catch((error) => {
        dispatch({ type: constants.BULK_UPDATE_EVENTS_DONE });
        throw error;
      });
  };
}
