import { combineReducers } from 'redux';

import { NONE_ID } from 'components/core/FrequencyOptions/constants';
import supportedStates from 'redux/state/supportedStates/reducers';

import constants from './constants';

const DEFAULT_STATE = {
  defaultSubscription: NONE_ID,
  keyDetails: {},
  profile: {},
  tags: [],
  committeeAssignments: [],
  ui: {
    isLoadingProfile: true,
    isLoadingTags: true,
    isLoadingSubscription: true,
    isLoadingMyFeedDocuments: true,
    isProfileNotFound: false,
  },
  sharings: [],
  trackings: [],
};

function legislatorProfile(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_LEGISLATOR:
      return {
        ...state,
        ui: { ...state.ui, isLoadingProfile: true },
      };
    case constants.RECEIVE_LEGISLATOR_DONE:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, isLoadingProfile: false },
      };
    case constants.PROFILE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, isProfileNotFound: true },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...state,
        sharings: action.payload,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.UPDATE_PROFILE_MY_FEED:
      return {
        ...state,
        sharings: action.payload,
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscription: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        trackings: action.payload,
        ui: { ...state.ui, isLoadingSubscription: false },
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.RESET_LEGISLATOR_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
}

export default combineReducers({
  legislatorProfile,
  supportedStates,
});
