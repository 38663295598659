import constants from '../constants';
import { getContactSaveRequestSettings } from '../utils';

const { addNotification } = window.app.GlobalNotifier;

const onDeleteSuccess = (dispatch, contactId) => () => {
  dispatch({
    type: constants.DELETE_CONTACT_DONE,
    contactId,
  });

  addNotification({
    message: 'Key contact deleted.',
    level: 'success',
  });
};

const onDeleteError = dispatch => () => {
  dispatch({ type: constants.DELETE_CONTACT_ERROR });

  addNotification({
    message: 'Failed to delete key contact.',
    level: 'error',
  });
};

export function deleteContact({ contactId }) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_CONTACT });

    const url = `/api_web/company-subscription/contacts/${contactId}`;
    const method = 'DELETE';

    const ajax = $.ajax({ url, method });

    return Promise.resolve(ajax)
      .then(onDeleteSuccess(dispatch, contactId))
      .catch(onDeleteError(dispatch));
  };
}

const onSaveSuccess = dispatch => (response) => {
  dispatch({
    type: constants.CONTACT_SAVED,
    payload: response,
  });

  addNotification({
    message: 'Key contact saved.',
    level: 'success',
  });
};

const onSaveError = dispatch => () => {
  dispatch({ type: constants.SAVE_CONTACT_FAILED });

  addNotification({
    message: 'Could not save the contact. Please try again.',
    level: 'success',
  });
};

export function saveContact(contact) {
  return (dispatch) => {
    dispatch({ type: constants.SAVE_CONTACT });

    const settings = getContactSaveRequestSettings(contact);

    return Promise.resolve($.ajax(settings))
      .then(onSaveSuccess(dispatch))
      .catch(onSaveError);
  };
}
