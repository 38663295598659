import constants from './constants';
import { receiveSavedItem } from './transformers';

export const DEFAULT_STATE = {
  companyInfo: {},
  ui: {
    isLoading: false,
    isSavingContact: false,
    isResetingToken: false,
    isDeletingContact: false,
    isSavingCompany: false,
  },
};

export default function manageOrganization(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_COMPANY_INFO:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoading: true,
        },
      };

    case constants.UPDATE_COMPANY_INFO:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingCompany: true,
        },
      };

    case constants.UPDATE_COMPANY_INFO_DONE:
      return {
        ...state,
        companyInfo: action.payload,
        ui: {
          ...state.ui,
          isSavingCompany: false,
        },
      };

    case constants.RECEIVE_COMPANY_INFO_DONE:
      return {
        ...state,
        companyInfo: action.payload,
        ui: {
          ...state.ui,
          isLoading: false,
        },
      };

    case constants.UPDATE_COMPANY_INFO_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingCompany: false,
        },
      };

    case constants.SAVE_CONTACT:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingContact: true,
        },
      };

    case constants.CONTACT_SAVED:
      return {
        ...state,
        companyInfo: receiveSavedItem({
          companyInfo: state.companyInfo,
          savedItem: action.payload,
          itemType: 'contacts',
        }),
        ui: {
          ...state.ui,
          isSavingContact: false,
        },
      };

    case constants.SAVE_CONTACT_FAILED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingContact: false,
        },
      };

    case constants.RESET_API_TOKEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          isResetingToken: true,
        },
      };

    case constants.RESET_API_TOKEN_DONE:
      return {
        ...state,
        companyInfo: action.payload,
        ui: {
          ...state.ui,
          isResetingToken: false,
        },
      };

    case constants.DELETE_CONTACT:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingContact: true,
        },
      };

    case constants.DELETE_CONTACT_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletingContact: false,
        },
      };

    case constants.DELETE_CONTACT_DONE:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          contacts: state.companyInfo.contacts.filter(
            ({ id }) => id !== action.contactId
          ),
        },
        ui: {
          ...state.ui,
          isDeletingContact: false,
        },
      };

    default:
      return state;
  }
}
