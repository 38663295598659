import { isEmpty, compact } from 'lodash';

import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { getDisplayItem, mapBackendValues } from 'redux/advancedSearch/utils';
import { FEDERAL_COMMITTEE_ITEM_TYPE } from 'utils/constants/itemTypes';
import {
  getLabelFromItem,
  getValue,
  transformSelectValues,
} from 'utils/transformers/advancedSearch';

function transformOptionsForUI(data = {}) {
  const { id, search_params } = data;
  const {
    lookup_keyword,
    legislators,
    committees,
    chambers,
    show_historical,
    show_only_tracked_items,
    tag_ids,
  } = search_params || {};

  return {
    id,
    options: {
      ...getValue('searchValue', lookup_keyword),
      ...getValue('legislator', legislators),
      ...getValue('committee', committees),
      ...getValue('chamber', chambers),
      ...getValue('historicalCommittees', Boolean(show_historical)),
      ...getValue('showOnlyTrackedItems', Boolean(show_only_tracked_items)),
      ...getValue('tags', tag_ids),
    },
  };
}

function getDisplayDataForRecentSearches(data = {}, legislatorsProps = {}) {
  const { name, search_params } = data;
  const {
    legislators,
    committees,
    chambers,
    lookup_keyword,
    show_historical,
    show_only_tracked_items,
    tag_ids,
  } = search_params;

  const filters = [
    getDisplayItem('Searched', lookup_keyword),
    getDisplayItem('Legislator', getLabelFromItem(legislators)),
    getDisplayItem('Committee', getLabelFromItem(committees)),
    getDisplayItem('Chamber', mapBackendValues(chambers, legislatorsProps.chambers)),
    getDisplayItem('Include historical committees', show_historical && 'Yes'),
    getDisplayItem('Show only federal committees I subscribe to', show_only_tracked_items && 'Yes'),
    getDisplayItem('Tags', getLabelFromItem(tag_ids)),
  ];

  return {
    title: name || 'Applied filters:',
    filters: compact(filters),
  };
}

export function transformOptionsForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    committee,
    chamber,
    historicalCommittees,
    showOnlyTrackedItems,
    tags,
  } = options || {};

  return {
    ...getValue('lookup_keyword', searchValue),
    ...getValue('ids', transformSelectValues(committee)),
    ...getValue('legislator_ids', transformSelectValues(legislator)),
    ...getValue('chambers', chamber),
    ...getValue('show_historical', historicalCommittees),
    ...getValue('show_only_tracked_items', showOnlyTrackedItems),
    ...getValue('tag_ids', transformSelectValues(tags)),
  };
}

export function transformSearchesForBE(data = {}) {
  const { searchValue, options } = data;
  const {
    legislator,
    committee,
    chamber,
    historicalCommittees,
    showOnlyTrackedItems,
    tags,
  } = options || {};

  return {
    ...getValue('lookup_keyword', searchValue),
    ...getValue('ids', transformSelectValues(committee)),
    ...getValue('legislator_ids', transformSelectValues(legislator)),
    ...getValue('chambers', chamber),
    ...getValue('show_historical', historicalCommittees),
    ...getValue('show_only_tracked_items', showOnlyTrackedItems),
    ...getValue('tag_ids', tags),
  };
}

export function transformSearchesForUI(data, legislatorsProps) {
  return data.map(item => ({
    ...getDisplayDataForRecentSearches(item, legislatorsProps),
    ...transformOptionsForUI(item),
  }));
}

export function transformCommitteesForUI(committees = []) {
  return committees.map(({
    chairman,
    chamber,
    id,
    name,
    ranking_member,
    subcommittees,
  }) => ({
    chairman,
    chamber,
    id,
    name,
    rankingMember: ranking_member,
    subcommittees,
    recordId: id,
    documentType: DOCUMENT_TYPES.FEDERAL_COMMITTEE,
    itemType: FEDERAL_COMMITTEE_ITEM_TYPE,
    isCommittee: true,
    withWeekAheadEmail: true,
    withTrack: true,
  }));
}

export function getExportInfo(data = {}) {
  const { searchValue } = data;
  const {
    legislator,
    committee,
    chamber,
    tags,
    showOnlyTrackedItems,
  } = data.options || {};

  return {
    'strategy_inputs[lookup_keyword]': searchValue || undefined,
    ...(!isEmpty(committee) && { 'strategy_inputs[ids]': transformSelectValues(committee) }),
    'strategy_inputs[legislator_ids]': transformSelectValues(legislator),
    'strategy_inputs[chambers]': chamber,
    'strategy_inputs[tag_ids]': transformSelectValues(tags),
    'strategy_inputs[show_only_tracked_items]': Boolean(showOnlyTrackedItems),
  };
}
