import { combineReducers } from 'redux';

import appliedSearchFilters from 'redux/appliedSearchFilters/reducers';
import advancedSearch from 'redux/advancedSearch/reducers';
import exportReport from 'redux/exportReport/reducers';
import { updateDataItemArrayPayload, updateDataItem } from 'utils/reducer';

import constants from '../constants';
import { loadingUpdateItem, fetchCommittees, receiveCommittees } from './utils/reducersUtils';
import defaultState from './defaultState';

const committeeLookupKeyName = 'committees';

function committeesLookup(state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_COMMITTEES:
      return fetchCommittees({ state, action });
    case constants.RECEIVE_COMMITTEES:
      return receiveCommittees({ state, action });
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, constants.COMMITTEE_LOOKUP_NAME),
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.BULK_UPDATE_DATA:
      return loadingUpdateItem(state, action);
    case constants.UPDATE_DATA_ITEM:
      return {
        ...loadingUpdateItem(state, action),
        ...updateDataItem(state, action, constants.COMMITTEE_LOOKUP_NAME),
      };
    case constants.BULK_SHARE_TO_MY_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: true },
      };
    case constants.BULK_SHARE_TO_MY_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.BULK_SHARE_TO_MY_FEED_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, constants.COMMITTEE_LOOKUP_NAME),
        ui: { ...state.ui, isSharingItems: false },
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.BULK_TRACK_DATA:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: true },
      };
    case constants.BULK_TRACK_DATA_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSavingSubscriptions: false },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, committeeLookupKeyName),
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...updateDataItemArrayPayload(state, action, constants.COMMITTEE_LOOKUP_NAME),
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RESET_COMMITTEES:
      return defaultState;
    default:
      return state;
  }
}

export default combineReducers({
  committeesLookup,
  advancedSearch,
  appliedFilters: appliedSearchFilters,
  exportReport,
});
