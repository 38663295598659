import {
  FETCH_DONORS,
  RECEIVE_DONORS,
  DONORS_NOT_FOUND,
} from '../constants';

import defaultState from './defaultState';
import fetchDonors from './fetchDonors';
import receiveDonors from './receiveDonors';
import notFoundDonors from './notFoundDonors';

export default function (state = defaultState, action) {
  const actions = {
    [FETCH_DONORS]: fetchDonors,
    [RECEIVE_DONORS]: receiveDonors,
    [DONORS_NOT_FOUND]: notFoundDonors,
  };

  // eslint-disable-next-line no-prototype-builtins
  if (actions.hasOwnProperty(action.type)) {
    const {
      [action.type]: submitFunc,
    } = actions;

    return submitFunc({ action, state });
  }

  return state;
}
