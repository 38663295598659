import constants from './constants';

export const DEFAULT_STATE = {
  stafferData: {},
  officeStaffers: [],
  officeStaffersPagination: {},
  officeStaffersSortData: {},
  tags: [],
  ui: {
    isLoadingStaffer: true,
    isLoadingOfficeStaffers: true,
    isLoadingTags: true,
    isProfileNotFound: false,
  },
};

export default function stafferProfile(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_STAFFER:
      return {
        ...state,
        ui: { ...state.ui, isLoadingStaffer: true },
      };
    case constants.RECEIVE_STAFFER_DONE:
      return {
        ...state,
        stafferData: action.payload,
        ui: { ...state.ui, isLoadingStaffer: false },
      };
    case constants.PROFILE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, isProfileNotFound: true },
      };
    case constants.FETCH_OFFICE_STAFFERS:
      return {
        ...state,
        officeStaffersSortData: action.payload.sortData,
        ui: { ...state.ui, isLoadingOfficeStaffers: true },
      };
    case constants.RECEIVE_OFFICE_STAFFERS_DONE:
      return {
        ...state,
        officeStaffers: action.payload.officeStaffers,
        officeStaffersPagination: action.payload.pagination,
        ui: { ...state.ui, isLoadingOfficeStaffers: false },
      };
    case constants.SORT_STAFFER_DATA:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadingStaffer: true,
          isLoadingOfficeStaffers: true,
        },
      };
    case constants.SORT_STAFFER_DATA_DONE:
      return {
        ...state,
        stafferData: action.stafferData,
        ui: {
          ...state.ui,
          isLoadingStaffer: false,
          isLoadingOfficeStaffers: false,
        },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    default:
      return state;
  }
}
