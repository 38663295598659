const PREFIX = 'BILLS';

const constants = {
  SET_BILLS: `${PREFIX}/SET_BILLS`,
  FETCH_BILLS: `${PREFIX}/FETCH_BILLS`,
  RECEIVE_BILLS_DONE: `${PREFIX}/RECEIVE_BILLS_DONE`,
  FETCH_TAGS: `${PREFIX}/FETCH_TAGS`,
  RECEIVE_TAGS_DONE: `${PREFIX}/RECEIVE_TAGS_DONE`,
  FETCH_SUBSCRIPTIONS: `${PREFIX}/FETCH_SUBSCRIPTIONS`,
  RECEIVE_SUBSCRIPTIONS_DONE: `${PREFIX}/RECEIVE_SUBSCRIPTIONS_DONE`,
  RECEIVE_DEFAULT_SUBSCRIPTION_DONE: `${PREFIX}/RECEIVE_DEFAULT_SUBSCRIPTION_DONE`,
  UPDATE_DATA_ITEM: `${PREFIX}/UPDATE_DATA_ITEM`,
  FETCH_MY_FEED_DOCUMENTS: `${PREFIX}/FETCH_MY_FEED_DOCUMENTS`,
  RECEIVE_MY_FEED_DOCUMENTS_DONE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_DONE`,
  RECEIVE_MY_FEED_DOCUMENTS_FAILURE: `${PREFIX}/RECEIVE_MY_FEED_DOCUMENTS_FAILURE`,
};

// TODO: This is duplicated from public_records
export const FEDERAL_LEGISLATOR_SLUG = 'federal_legislator';
export const STATE_LEGISLATOR_SLUG = 'state_legislator';
export const FEDERAL_COMMITTEE_SLUG = 'federal_committee';
export const STATE_COMMITTEE_SLUG = 'committee';
export const FEDERAL_BILL_SIMILAR_BILLS_SLUG = 'federal_bill_similar_bills';
export const STATE_BILL_SIMILAR_BILLS_SLUG = 'state_bill_similar_bills';

export default constants;
