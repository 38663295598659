export const SAVE_EVENT = 'FLY_IN_CALENDAR_EVENT_BUILDER/SAVE_EVENT';
export const FETCH_EVENT = 'FLY_IN_CALENDAR_EVENT_BUILDER/FETCH_EVENT';
export const RECEIVE_EVENT = 'FLY_IN_CALENDAR_EVENT_BUILDER/RECEIVE_EVENT';
export const DELETE_EVENT_ITEM = 'FLY_IN_CALENDAR/DELETE_EVENT_ITEM';

export const constants = {
  SAVE_EVENT,
  FETCH_EVENT,
  RECEIVE_EVENT,
  DELETE_EVENT_ITEM,
  FETCH_LEGISLATOR_OFFICE_ADDRESS: 'FLY_IN_CALENDAR_EVENT_BUILDER/FETCH_LEGISLATOR_OFFICE_ADDRESS',
  RECEIVE_LEGISLATOR_OFFICE_ADDRESS: 'FLY_IN_CALENDAR_EVENT_BUILDER/RECEIVE_LEGISLATOR_OFFICE_ADDRESS',
};

const baseAPIUrl = '/api_web/hill_day/hill_days';

function receiveEvent(payload) {
  return { type: constants.RECEIVE_EVENT, payload };
}

function saveEventDispatch(payload) {
  return { type: constants.SAVE_EVENT, payload };
}

export function saveEvent(payload) {
  return (dispatch) => {
    const { flyInToken, eventToken, event } = payload;

    // uses api_web_hill_day_hill_day_events_path
    // uses api_web_hill_day_hill_day_event_path
    let url = `${baseAPIUrl}/${flyInToken}/events`;
    let method = 'POST';

    if (eventToken) {
      url = `${url}/${eventToken}`;
      method = 'PUT';
    }

    const ajax = $.ajax({
      method,
      url,
      data: { event },
    });

    return Promise.resolve(ajax).then(result =>
      dispatch(saveEventDispatch(result))
    );
  };
}

export function clearEvent(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_EVENT, payload });
  };
}

export function createEvent(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_EVENT, payload });

    const { flyInToken } = payload;

    const url = `${baseAPIUrl}/${flyInToken}/events/new`;
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax).then(result =>
      dispatch(receiveEvent(result))
    );
  };
}

export function fetchLegislatorOfficeAddress(payload) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_LEGISLATOR_OFFICE_ADDRESS, payload });

    const { legislatorId } = payload;

    // uses api_web_legislator_office_address_path
    const url = `/api_web/legislators/${legislatorId}/office_address`;
    const method = 'GET';

    const ajax = $.ajax({
      method,
      url,
    });

    return Promise.resolve(ajax).then(result =>
      dispatch({
        type: constants.RECEIVE_LEGISLATOR_OFFICE_ADDRESS,
        payload: result,
      })
    );
  };
}

export function clearEventLocation() {
  const payload = {
    location_name: '',
    city: '',
    address_1: '',
    address_2: '',
    state_token: 'DC',
    zipcode: '',
    url: '',
  };

  return (dispatch) => {
    dispatch({
      type: constants.RECEIVE_LEGISLATOR_OFFICE_ADDRESS,
      payload,
    });
  };
}

export function deleteEvent(payload) {
  return (dispatch) => {
    dispatch({ type: constants.DELETE_EVENT_ITEM, payload });

    const { flyInToken, eventToken, sendNotifications } = payload;
    // uses api_web_hill_day_hill_day_event_path
    const url = `${baseAPIUrl}/${flyInToken}/events/${eventToken}`;
    const method = 'DELETE';
    const data = {
      event: {
        send_notifications: sendNotifications,
      },
    };

    const ajax = $.ajax({
      method,
      url,
      data,
    });

    return Promise.resolve(ajax);
  };
}

export const defaultState = {
  event: {
    state: {},
    people: [],
    legislators: [],
    attendees: [],
    staffers: [],
  },
  ui: {
    loading: false,
  },
};

const stateFetchEvent = state => ({
  ...state,
  event: defaultState.event,
  ui: { ...state.ui, loading: true },
});

const stateReceiveEvent = (state, action) => ({
  ...state,
  event: action.payload,
  ui: { ...state.ui, loading: false },
});

const stateReceiveCustomData = (state, action) => ({
  ...state,
  event: {
    ...state.event,
    ...action.payload,
  },
});

export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_EVENT:
      return stateFetchEvent(defaultState);
    case constants.RECEIVE_EVENT:
      return stateReceiveEvent(state, action);
    case constants.RECEIVE_LEGISLATOR_OFFICE_ADDRESS:
      return stateReceiveCustomData(state, action);
    default:
      return state;
  }
}
