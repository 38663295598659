import { reject, map, includes } from 'lodash';

import { updateWorkspaceSubscription } from 'redux/dashboardV2/workspaces/utils';

import constants from './constants';
import {
  updateAllSubscriptions,
  updateCommitteeSubscription,
  updateSubscriptions,
  updateWorkspace,
} from './utils';

const DEFAULT_STATE = {
  workspace: {},
  subscriptions: [],
  defaultFrequency: null,
  ui: {
    isLoading: true,
    isLoadingSubscriptions: true,
    isNotFound: false,
    isDeletingSubscriptions: false,
    isUpdatingSubscriptions: false,
    isLoadingDefaultFrequency: true,
    isUpdatingDefaultFrequency: false,
  },
};

function workspaceSettings(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_WORKSPACE:
      return {
        ...state,
        ui: { ...state.ui, isLoading: true },
      };
    case constants.RECEIVE_WORKSPACE_DONE:
      return {
        ...state,
        workspace: action.payload,
        ui: { ...state.ui, isLoading: false },
      };
    case constants.WORKSPACE_NOT_FOUND:
      return {
        ...state,
        ui: { ...state.ui, isNotFound: true },
      };
    case constants.UPDATE_WORKSPACE:
      return {
        ...state,
        ui: { ...state.ui, isUpdating: true, isNameAlreadyTaken: false },
      };
    case constants.UPDATE_WORKSPACE_DONE:
      return {
        ...state,
        workspace: updateWorkspace(state.workspace, action.payload),
        ui: { ...state.ui, isUpdating: false },
      };
    case constants.NAME_ALREADY_TAKEN:
      return {
        ...state,
        ui: { ...state.ui, isUpdating: false, isNameAlreadyTaken: true },
      };
    case constants.UPDATE_WORKSPACE_SUBSCRIPTION:
      return {
        ...state,
        workspace: updateWorkspaceSubscription(state.workspace, action.payload.frequency, action.payload.isTracking),
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscriptions: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        subscriptions: action.payload,
        ui: { ...state.ui, isLoadingSubscriptions: false },
      };
    case constants.DELETE_SUBSCRIPTION:
    case constants.DELETE_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isDeletingSubscriptions: true },
      };
    case constants.DELETE_SUBSCRIPTION_DONE:
      return {
        ...state,
        subscriptions: reject(state.subscriptions, { token: action.payload }),
        ui: { ...state.ui, isDeletingSubscriptions: false },
      };
    case constants.DELETE_COMMITTEE_SUBSCRIPTION_DONE:
      return {
        ...state,
        subscriptions: updateCommitteeSubscription(state.subscriptions, action.payload),
        ui: { ...state.ui, isDeletingSubscriptions: false },
      };
    case constants.UPDATE_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isUpdatingSubscriptions: true },
      };
    case constants.UPDATE_SUBSCRIPTION_DONE:
      return {
        ...state,
        subscriptions: map(state.subscriptions, subscription => (
          subscription.token === action.payload.token ? action.payload : subscription
        )),
        ui: { ...state.ui, isUpdatingSubscriptions: false },
      };
    case constants.UPDATE_COMMITTEE_SUBSCRIPTION_DONE:
      return {
        ...state,
        subscriptions: updateCommitteeSubscription(state.subscriptions, action.payload),
        ui: { ...state.ui, isUpdatingSubscriptions: false },
      };
    case constants.UPDATE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        subscriptions: updateSubscriptions(state.subscriptions, action.payload),
        ui: { ...state.ui, isUpdatingSubscriptions: false },
      };
    case constants.DELETE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        subscriptions: reject(state.subscriptions, ({ token }) => includes(action.payload, token)),
        ui: { ...state.ui, isDeletingSubscriptions: false },
      };
    case constants.FETCH_DEFAULT_FREQUENCY:
      return {
        ...state,
        ui: { ...state.ui, isLoadingDefaultFrequency: true },
      };
    case constants.FETCH_DEFAULT_FREQUENCY_DONE:
      return {
        ...state,
        defaultFrequency: action.payload,
        ui: { ...state.ui, isLoadingDefaultFrequency: false },
      };
    case constants.UPDATE_DEFAULT_FREQUENCY:
      return {
        ...state,
        ui: { ...state.ui, isUpdatingDefaultFrequency: true },
      };
    case constants.UPDATE_DEFAULT_FREQUENCY_DONE:
      return {
        ...state,
        defaultFrequency: action.payload,
        ui: { ...state.ui, isUpdatingDefaultFrequency: false },
      };
    case constants.RESET_SUBSCRIPTIONS_SETTINGS:
      return {
        ...state,
        ui: { ...state.ui, isUpdatingSubscriptions: true },
      };
    case constants.RESET_SUBSCRIPTIONS_SETTINGS_DONE:
      return {
        ...state,
        subscriptions: updateAllSubscriptions(state.subscriptions, state.defaultFrequency),
        ui: { ...state.ui, isUpdatingSubscriptions: false },
      };
    default:
      return state;
  }
}

export default workspaceSettings;
