import { isEmpty, isArray, reject, get, over, partial } from 'lodash';

import notifications from 'redux/dashboardV2/feedPage/notifications';
import * as trackingApi from 'redux/dashboardV2/common/trackings/api';
import * as subscriptionsApi from 'redux/dashboardV2/common/subscriptions/api';
import constants from 'redux/dashboardV2/feedPage/constants';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { notifySubscriptionChange } from 'components/Dashboard/common/UserNotifications';

import { getListSubscription } from '../utils/transformers/trackedEvents/commonTransformers';

export function getCommitteeAction({
  feedToken,
  isStaffer,
  isHearing,
  isPresentStaffers,
  isPresentHearings,
  hearingData,
  stafferData,
}, isFederal) {
  const hearingPath = isFederal ? 'federal_committee_hearing' : 'state_committee_hearing';
  const stafferPath = isFederal ? 'federal_committee_staffer' : 'state_committee_staffer';
  const getHearing = payload => get(payload, `data.${hearingPath}`);
  const getStaffer = payload => get(payload, `data.${stafferPath}`);

  if (isHearing && isStaffer) {
    return [
      trackingApi.createCommitteeTracking({ feedToken, hearingData, stafferData, isFederal })
        .then(over(getHearing, getStaffer)),
    ];
  }

  if (isStaffer) {
    return [
      isPresentHearings && subscriptionsApi.deleteSubscription(feedToken, hearingData),
      trackingApi.createCommitteeTracking({ feedToken, stafferData, isFederal })
        .then(getStaffer),
    ];
  }

  if (isHearing) {
    return [
      trackingApi.createCommitteeTracking({ feedToken, hearingData, isFederal })
        .then(getHearing),
      isPresentStaffers && subscriptionsApi.deleteSubscription(feedToken, stafferData),
    ];
  }

  return [];
}

export const getSubscriptionsData = (responseData = []) => {
  const [hearings, staffers] = responseData;

  if (isArray(hearings)) {
    return hearings;
  }

  return [hearings, staffers];
};

export function onSuccessTrackCommittee({ responseData, dispatchTrackCommittee }) {
  const [hearingsData, staffersData] = getSubscriptionsData(responseData);

  return dispatchTrackCommittee(hearingsData, staffersData);
}

export function onUpdateTrackCommittee({ documentType, itemType, ...payload }) {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_TRACK_COMMITTEE_INIT, payload });

    const dispatchTrackCommittee = (hearingData, stafferData) => {
      const subscriptionsReducer = getListSubscription(reject([hearingData, stafferData], isEmpty));

      dispatch({
        type: constants.UPDATE_TRACK_COMMITTEE,
        payload: { ...payload, updateSubscriptions: subscriptionsReducer },
      });
    };

    const onSuccess = (responseData) => {
      onSuccessTrackCommittee({
        responseData,
        dispatchTrackCommittee,
      });
      notifySubscriptionChange({ itemType });
    };

    const onFailure = (error) => {
      dispatch({ type: constants.UPDATE_TRACK_COMMITTEE_INIT, payload });
      notifications.defaultSubmitDataFailed();

      throw error;
    };

    const { [documentType]: action } = {
      [DOCUMENT_TYPES.FEDERAL_COMMITTEE]: partial(getCommitteeAction, partial.placeholder, true),
      [DOCUMENT_TYPES.STATE_COMMITTEE]: partial(getCommitteeAction, partial.placeholder, false),
    };

    return Promise.all(action(payload))
      .then(onSuccess)
      .catch(onFailure);
  };
}
