const constants = {
  STOP_LOADING: 'GRASSROOTS_CAMPAIGNS/STOP_LOADING',

  FETCH_CAMPAIGNS: 'GRASSROOTS_CAMPAIGNS/FETCH_CAMPAIGNS',
  RECEIVE_CAMPAIGNS: 'GRASSROOTS_CAMPAIGNS/RECEIVE_CAMPAIGNS',
};


export function fetchCampaigns(payload = {}) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_CAMPAIGNS, payload });

    // uses api_web_grassroots_campaigns_path
    const { page = 1 } = payload;
    const url = '/api_web/grassroots/campaigns';
    const method = 'GET';
    const data = {
      per_page: 50,
      page,
    };

    const ajax = $.ajax({
      method,
      url,
      data,
    });

    return Promise.resolve(ajax)
      .then(response =>
        dispatch({ type: constants.RECEIVE_CAMPAIGNS, payload: response })
      )
      .catch(() => {
        dispatch({ type: constants.STOP_LOADING });
      });
  };
}


export const defaultState = {
  campaigns: [],
  searchForm: {},
  pagination: {
    total_pages: 0,
  },
  ui: {
    loading: false,
  },
};


export default function (state = defaultState, action) {
  switch (action.type) {
    case constants.FETCH_CAMPAIGNS:
      return {
        ...state,
        campaigns: defaultState.campaigns,
        pagination: defaultState.pagination,
        searchForm: {
          ...state.searchForm,
          ...action.payload,
        },
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case constants.RECEIVE_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload.data,
        pagination: action.payload.pagination,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case constants.STOP_LOADING:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    default:
      return state;
  }
}
