import { isNil, omitBy, reject, overSome } from 'lodash';
import { DOCUMENT_TYPES } from 'components/Dashboard/common/FeedPage/utils/constants';
import { FEDERAL_COMMITTEE_ITEM_TYPE } from 'utils/constants/itemTypes';

export function transformProfileForUI(profileData = {}) {
  const {
    display_name,
    subcommittee,
    parent_committee,
    ...restProps
  } = profileData;

  return omitBy({
    ...restProps,
    documentType: DOCUMENT_TYPES.FEDERAL_COMMITTEE,
    itemType: FEDERAL_COMMITTEE_ITEM_TYPE,
    displayName: display_name,
    isSubcommittee: subcommittee,
    parentCommittee: parent_committee,
  }, isNil);
}

export function transformCommitteeMembersForUI(members = []) {
  const transformMember = ({
    universal_moniker,
    party_short,
    party_slug,
    ...restProps
  }) => ({
    ...restProps,
    name: universal_moniker,
    partyShort: party_short,
    partySlug: party_slug,
  });

  const byParty = party => member => member.party_slug === party;
  const byRepublicanParty = byParty('republican');
  const byDemocraticParty = byParty('democratic');
  const byOtherParty = overSome(byRepublicanParty, byDemocraticParty);

  return {
    republicans: members.filter(byRepublicanParty).map(transformMember),
    democrats: members.filter(byDemocraticParty).map(transformMember),
    others: reject(members, byOtherParty).map(transformMember),
  };
}

export function transformSubcommitteesForUI(profileData = {}) {
  return profileData;
}
