import constants from './constants';

const DEFAULT_STATE = {
  generalFeeds: [],
  personalFeeds: [],
  ui: {
    isDeletingFeed: false,
    isLoadingPersonalFeeds: true,
    isLoadingGeneralFeeds: true,
    isSubscribing: false,
  },
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_GENERAL_FEEDS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingGeneralFeeds: true },
      };
    case constants.FETCH_PERSONAL_FEEDS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingPersonalFeeds: true },
      };
    case constants.RECEIVE_GENERAL_FEEDS_DONE:
      return {
        ...state,
        generalFeeds: action.payload,
        ui: { ...state.ui, isLoadingGeneralFeeds: false },
      };
    case constants.RECEIVE_GENERAL_FEEDS_FAILURE:
      return {
        ...state,
        generalFeeds: DEFAULT_STATE.generalFeeds,
        ui: { ...state.ui, isLoadingGeneralFeeds: false },
      };
    case constants.RECEIVE_PERSONAL_FEEDS_DONE:
      return {
        ...state,
        personalFeeds: action.payload,
        ui: { ...state.ui, isLoadingPersonalFeeds: false },
      };
    case constants.RECEIVE_PERSONAL_FEEDS_FAILURE:
      return {
        ...state,
        personalFeeds: DEFAULT_STATE.personalFeeds,
        ui: { ...state.ui, isLoadingPersonalFeeds: false },
      };
    case constants.DELETE_FEED:
      return {
        ...state,
        ui: { ...state.ui, isDeletingFeed: true },
      };
    case constants.DELETE_FEED_DONE:
      return {
        ...state,
        personalFeeds: state.personalFeeds.filter(({ id }) => id !== action.payload.id),
        ui: { ...state.ui, isDeletingFeed: false },
      };
    case constants.DELETE_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isDeletingFeed: false },
      };
    case constants.CREATE_FEED_DONE:
      return {
        ...state,
        personalFeeds: [
          action.payload,
          ...state.personalFeeds,
        ],
      };
    case constants.UPDATE_FEED_DONE:
      return {
        ...state,
        personalFeeds: state.personalFeeds.map(
          item => (item.id === action.payload.id
            ? { ...item, ...action.payload }
            : item)
        ),
      };
    case constants.SUBSCRIBE_TO_FEED:
      return {
        ...state,
        ui: { ...state.ui, isSubscribing: true },
      };
    case constants.SUBSCRIBE_TO_FEED_DONE:
      return {
        ...state,
        ui: { ...state.ui, isSubscribing: false },
        personalFeeds: state.personalFeeds.map(
          feed => (feed.id === action.payload.id
            ? { ...feed, ...action.payload }
            : feed
          )
        ),
      };
    case constants.SUBSCRIBE_TO_FEED_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isSubscribing: false },
      };
    case constants.CREATE_FEED_FAILURE:
    case constants.UPDATE_FEED_FAILURE:
    default:
      return state;
  }
}
