import { NONE_ID } from 'components/core/FrequencyOptions/constants';

import constants from './constants';

const DEFAULT_STATE = {
  keyDetails: {},
  profile: {},
  defaultSubscription: NONE_ID,
  tags: [],
  timeline: {
    allItems: [],
    paginator: {},
  },
  publications: {
    allItems: [],
    paginator: {},
  },
  relevantLinks: [],
  ui: {
    isLoadingPublications: true,
    isLoadingTimeline: true,
    isInitialLoadingTimeline: true,
    isLoadingProfile: true,
    isLoadingTags: true,
    isLoadingSubscription: true,
    isLoadingMyFeedDocuments: true,
  },
  sharings: [],
  trackings: [],
};

export default function regulationsProfileReducers(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case constants.FETCH_REGULATION:
      return {
        ...state,
        ui: { ...state.ui, isLoadingProfile: true },
      };
    case constants.RECEIVE_REGULATION_DONE:
      return {
        ...state,
        ...action.payload,
        ui: { ...state.ui, isLoadingProfile: false },
      };
    case constants.FETCH_TIMELINE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTimeline: true },
      };
    case constants.RECEIVE_TIMELINE_DONE:
      return {
        ...state,
        timeline: {
          ...action.payload,
          allItems: action.payload.allItems,
        },
        ui: { ...state.ui, isInitialLoadingTimeline: false, isLoadingTimeline: false },
      };
    case constants.FETCH_PUBLICATIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingPublications: true },
      };
    case constants.RECEIVE_PUBLICATIONS_DONE:
      return {
        ...state,
        publications: {
          ...action.payload,
        },
        ui: { ...state.ui, isLoadingPublications: false },
      };
    case constants.UPSERT_SUMMARY_DONE:
      return {
        ...state,
        profile: {
          ...state.profile,
          summary: action.payload,
        },
      };
    case constants.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingSubscription: true },
      };
    case constants.RECEIVE_SUBSCRIPTIONS_DONE:
      return {
        ...state,
        trackings: action.payload,
        ui: { ...state.ui, isLoadingSubscription: false },
      };
    case constants.FETCH_TAGS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingTags: true },
      };
    case constants.RECEIVE_TAGS_DONE:
      return {
        ...state,
        tags: action.payload,
        ui: { ...state.ui, isLoadingTags: false },
      };
    case constants.RECEIVE_DEFAULT_SUBSCRIPTION_DONE:
      return {
        ...state,
        defaultSubscription: action.payload,
      };
    case constants.RESET_REGULATION_DATA:
      return DEFAULT_STATE;
    case constants.FETCH_MY_FEED_DOCUMENTS:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: true },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_DONE:
      return {
        ...state,
        sharings: action.payload,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.RECEIVE_MY_FEED_DOCUMENTS_FAILURE:
      return {
        ...state,
        ui: { ...state.ui, isLoadingMyFeedDocuments: false },
      };
    case constants.UPDATE_PROFILE_MY_FEED:
      return {
        ...state,
        sharings: action.payload,
      };
    default:
      return state;
  }
}
