import { unionWith, isEqual, omitBy, isNil, map, size } from 'lodash';

import { GET_REQUEST } from 'utils/constants';
import { transformSort } from 'utils/transformers/paginator';

import constants from '../constants';
import {
  transformNewsFeedForUI,
  transformNewsParams,
  transformPaginator,
  getFeedKeywords,
  handleFailureNotification,
} from '../utils';
import { fetchMyFeedDocuments } from './events';
import { fetchTags } from './tags';

export { shareToMyFeed } from './events';
export * from './tags';

export function fetchNews(payload = {}, customTransformers = {}) {
  return (dispatch) => {
    const {
      sortData,
      cursor,
      resetFeed = false,
      shouldGroupCluster,
      url = '/api_web/news/stories',
      method = GET_REQUEST,
    } = payload;
    const {
      transformForUI = transformNewsFeedForUI,
      transformParams = transformNewsParams,
    } = customTransformers;

    resetFeed && dispatch({ type: constants.RESET_NEWS_FEED });
    dispatch({ type: constants.FETCH_NEWS_FEED });

    const data = transformParams(payload);

    const ajax = $.ajax({ url, method, data });

    function onSuccess(response) {
      const sort = omitBy(sortData, isNil);
      const { data: responseData = [], pagination } = response;

      return dispatch({
        type: constants.RECEIVE_NEWS_FEED_DONE,
        payload: transformForUI(responseData, shouldGroupCluster),
        pagination: transformPaginator(pagination, cursor, size(responseData)),
        sort: transformSort(sort),
        shouldReset: resetFeed,
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_NEWS_FEED_FAILURE });

      handleFailureNotification(error);

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .then(({ payload: payloadData }) => {
        const storyIds = map(payloadData, 'id');

        dispatch(fetchTags(storyIds));
        dispatch(fetchMyFeedDocuments(storyIds));
      })
      .catch(onFailure);
  };
}

export function resetStoryFeed() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_NEWS_FEED });
  };
}

export function fetchFeedKeywords(id) {
  return (dispatch) => {
    dispatch({ type: constants.FETCH_FEED_KEYWORDS });

    const url = `/api_web/news/searches/${id}`;
    const ajax = $.get(url);

    function onSuccess(response) {
      return dispatch({
        type: constants.RECEIVE_FEED_KEYWORDS_DONE,
        payload: getFeedKeywords(response),
      });
    }

    function onFailure(error) {
      dispatch({ type: constants.RECEIVE_FEED_KEYWORDS_FAILURE });

      handleFailureNotification(error);

      throw error;
    }

    return Promise.resolve(ajax)
      .then(onSuccess)
      .catch(onFailure);
  };
}

export function resetFeedKeywords() {
  return (dispatch) => {
    dispatch({ type: constants.RESET_FEED_KEYWORDS });
  };
}

export function onSelectAllCards(isChecked, cards) {
  return (dispatch) => {
    const selectedCards = isChecked ? [] : cards;

    dispatch({ type: constants.SELECT_CARDS, selectedCards });
  };
}

export function resetSelectedCards() {
  return dispatch => dispatch({ type: constants.SELECT_CARDS, selectedCards: [] });
}

export function onSelectCard(selectedCard, isChecked) {
  return (dispatch, getState) => {
    const { selectedCards } = getState().news.newsFeed;
    let filteredCards = [];

    if (isChecked) {
      filteredCards = selectedCards.filter(({ id }) => id !== selectedCard.id);
    } else {
      filteredCards = unionWith(selectedCards, [selectedCard], isEqual);
    }

    dispatch({
      type: constants.SELECT_CARDS,
      selectedCards: filteredCards,
    });
  };
}

export function updateDataItem(payload) {
  return dispatch => dispatch({
    type: constants.UPDATE_DATA_ITEM,
    payload,
  });
}
